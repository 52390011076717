<button mat-icon-button
        [class]="styleClass"
        (click)="emitAction($event)"
        [disabled]="disabled || showSpinner">
  <mat-icon *ngIf="!showSpinner; else spinner">{{ icon }}</mat-icon>
</button>

<ng-template #spinner>
  <mat-icon color="accent" class="spinning-icon"> autorenew</mat-icon>
</ng-template>
