import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Track} from '../modelinterfaces/track.model';
import {EnvironmentHelper} from './environment-helper';
import {TranslateService} from "@ngx-translate/core";
import dayjs from "dayjs";
import WKTFormatter from 'ol/format/WKT';
import Geometry from "ol/geom/Geometry";
import {Circle} from "ol/geom";
import {fromCircle} from "ol/geom/Polygon";

@Injectable({
  providedIn: 'root'
})

export class TrackService {

  private readonly wktFormatter = new WKTFormatter();

  constructor(private http: HttpClient,
              private environmentHelper: EnvironmentHelper,
              private translateService: TranslateService) {
  }

  public getByUnitId(unitId: number, startTime: string, finishTime: string, sensorIds: number[] = []): Observable<Track> {
    const url = `${this.environmentHelper.urlV2}/track/unit/${unitId}`;
    let params = new HttpParams();
    params = params.set('startTime', startTime);
    params = params.set('finishTime', finishTime);
    params = params.set('lang', this.translateService.currentLang);
    if (sensorIds.length > 0) {
      params = params.set('sensorIds', sensorIds.join(','));
    }
    return this.http.get<Track>(url, {params: params}).pipe(
      map(obj => Track.valueOf(obj))
    );
  }

  public getTracksIntersectGeometry(unitIds: number[], startTime: dayjs.Dayjs, finishTime: dayjs.Dayjs, geometry: Geometry): Observable<Track[]> {

    if (geometry instanceof Circle) {
      geometry = fromCircle(geometry);
    }

    const sourceProjection = "EPSG:3857";
    const targetProjection = "EPSG:4326";

    geometry = geometry.transform(sourceProjection, targetProjection);

    const request = {
      unitIds: unitIds,
      startTime: startTime.toISOString(),
      finishTime: finishTime.toISOString(),
      wktGeometry: this.wktFormatter.writeGeometry(geometry)
    }

    const url = `${this.environmentHelper.urlV2}/track/geometry-intersect`;
    let params = new HttpParams()
      .set('lang', this.translateService.currentLang);

    return this.http.post<Track[]>(url, request,{params: params}).pipe(
      map(tracks => tracks.map(track => Track.valueOf(track)))
    );
  }

  public getMultiTrackPreview(unitIds: number[], startTime: dayjs.Dayjs, finishTime: dayjs.Dayjs){
    const request = {
      unitIds: unitIds,
      startTime: startTime.toISOString(),
      finishTime: finishTime.toISOString(),
    }

    const url = `${this.environmentHelper.urlV2}/track/multi-preview`;
    let params = new HttpParams()
      .set('lang', this.translateService.currentLang);

    return this.http.post<Track[]>(url, request,{params: params}).pipe(
      map(tracks => tracks.map(track => Track.valueOf(track)))
    );
  }
}
