import OlMap from "ol/Map";
import {Injectable} from "@angular/core";
import {AppMessageService} from "../../../../../../../../common-module/src/lib/app-services/app-message.service";
import {BehaviorSubject, Observable} from "rxjs";
import {GeometryDrawMapHandler} from "./geometry-draw.map-handler";
import {TracksMapHandler} from "./tracks.map-handler";
import {UiSpinnerService} from "../../../../../../../../common-module/src/lib/app-services/ui-spinner.service";
import {Track} from "../../../../../../../../common-module/src/lib/modelinterfaces/track.model";
import GeometryType from "ol/geom/GeometryType";
import {Geometry} from "ol/geom";
import {TrackLayerService} from "../../../../../shared/services/track-layer.service";
import {TrackService} from "../../../../../../../../common-module/src/lib/services/track.service";
import {CenterMapUtil} from "../../../../../shared/map-utils/center-map.util";
import {AppColor} from "../../../../../../../../common-module/src/lib/app-enums/app-color";
import {filter, finalize, first, switchMap, tap} from "rxjs/operators";
import {
  UnitAdditionalDataService
} from "../../../../../../../../common-module/src/lib/services/unit-additional-data.service";

export enum MultiTrackToolState {
  START_DRAWING = 'start-drawing',
  END_DRAWING = 'end-drawing',
  TRACKS = 'tracks'
}

@Injectable({
  providedIn: 'root'
})
export class MultiTrackToolService {

  private _state = new BehaviorSubject<MultiTrackToolState>(null);

  private _tracks = new BehaviorSubject<Track[]>([]);

  private _geometryHandler: GeometryDrawMapHandler;
  private _tracksMapHandler: TracksMapHandler;
  private _map: OlMap = null;

  constructor(private appMessageService: AppMessageService,
              private spinnerService: UiSpinnerService,
              private trackLayerService: TrackLayerService,
              private unitDataService: UnitAdditionalDataService,
              private trackService: TrackService) {
  }

  public get state$(): Observable<MultiTrackToolState> {
    return this._state.asObservable();
  }

  public get tracks$(): Observable<Track[]> {
    return this._tracks.asObservable();
  }

  public get externalTrackSelect$(): Observable<Track> {
    return this._tracksMapHandler.clickedOnMap;
  }

  public init(map: OlMap) {
    this._map = map;
    this._geometryHandler = new GeometryDrawMapHandler(map, this.appMessageService);
    this._tracksMapHandler = new TracksMapHandler(map);
  }

  public startDraw(geometryTypeElement: GeometryType) {
    this._geometryHandler.startDraw(geometryTypeElement)
      .pipe(
        filter(value => value !== null), // Пропускаем только ненулевые значения
        first() // Завершаем поток после первого подходящего значения
      )
      .subscribe(() => {
        this._state.next(MultiTrackToolState.END_DRAWING);
      });
    this._state.next(MultiTrackToolState.START_DRAWING)
  }

  public selectTrack(track: Track) {
    if (!track) {
      this.unSelectTrackInternal();
      return;
    }
    if (track.storage) {
      this.selectTrackInternal(track);
    } else {
      this.spinnerService.show();

      this.unitDataService.getSensors(track.getUnitId()).pipe(
        first(),
        switchMap(sensors =>
          this.trackService.getByUnitId(
            track.getUnitId(),
            track.buildOptions.startTime,
            track.buildOptions.finishTime,
            sensors.map(s => s.id)
          )
        ),
        tap(t => {
          track.storage = t.storage;
          this.selectTrackInternal(track);
        }),
        finalize(() => this.spinnerService.stop())
      ).subscribe();
    }
  }

  private unSelectTrackInternal() {
    this.trackLayerService.changeCurrentTrack(null);
    this._tracksMapHandler.setOpacityAllLayers(1);
  }

  private selectTrackInternal(track: Track) {
    this.trackLayerService.changeCurrentTrack(track);
    this._tracksMapHandler.setOpacityAllLayers(0.4);
    setTimeout(() => CenterMapUtil.center(track.storage.boundBox.getExtent(), this._map, {padding: {right: 460}}), 200)
  }

  public toggleVisibilityForTrack(track: Track, visible: boolean) {
    this._tracksMapHandler.toggleVisibilityForTrack(track, visible);
  }

  public getTrackColor(unitId: number): AppColor {
    return this._tracksMapHandler.getTrackColor(unitId);
  }

  public hideSearchArea() {
    this._geometryHandler.hide()
  }

  public showSearchArea() {
    this._geometryHandler.show()
  }

  public centerGeometry() {
    this._geometryHandler.centerOnGeometry()
  }

  public get geometry(): Geometry {
    return this._geometryHandler.geometry;
  }

  public setTracks(tracks: Track[]) {
    this._tracks.next(tracks);
    this._tracksMapHandler.clear()
    this._tracksMapHandler.showTracks(tracks);
    this._state.next(MultiTrackToolState.TRACKS);
    this._tracksMapHandler.centerAll()
  }


  public clear() {
    this._state.next(null);
    this.trackLayerService.changeCurrentTrack(null);
    this._geometryHandler.clear();
    this._tracksMapHandler.clear();
  }

  selectFullTrack(trackSample: Track) {
    this.spinnerService.show();
    let buildOptions = trackSample.buildOptions;
    this.trackService.getByUnitId(buildOptions.unit.id, buildOptions.startTime, buildOptions.finishTime, trackSample.storage.sensors.map(s => s.id))
      .subscribe(track => {
          trackSample.trips = track.trips;
          trackSample.parkings = track.parkings;
          trackSample.storage = track.storage;
          trackSample.mileage = track.mileage;
          this.selectTrackInternal(trackSample);
        },
        () => {
        },
        () => this.spinnerService.stop())
  }
}
