<app-dialog-save title="Сертификат"
                 (cancel)="onCancel()"
                 (save)="onSubmit()"
                 [disableSave]="!form.dirty || form.invalid || showSpinner"
                 [saveButtonShowSpinner]="showSpinner">
  <div class="dialog-content">
    <form [formGroup]="form">
      <mat-slide-toggle #toggle class="icon-toggle" formControlName="isCertified">
      <span class="margin-left-8">
        @if (toggle.checked) {
          Трекер сертифицирован
        } @else {
          Трекер не сертифицирован
        }
      </span>
      </mat-slide-toggle>

      <div class="margin-top-24 width-100-percent" *ngIf="toggle.checked">
        <mat-form-field [style.width]="'100%'">
          <mat-label>Дата истечение сертификата</mat-label>
          <mat-hint>ДД.ММ.ГГГГ</mat-hint>
          <input formControlName="expirationDate" matInput [matDatepicker]="picker">
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        @if (form.controls['expirationDate'].value) {
          <div class="margin-top-24">
            <app-certificate-expire [certificateExpire]="selectedExpirationDate"></app-certificate-expire>
          </div>
        }
      </div>
    </form>

  </div>

</app-dialog-save>
