import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ViewLanguageService} from '../../app-services/view-language.service';
import {TimeZone} from '../../modelinterfaces/time-zone.model';
import {User} from '../../modelinterfaces/user.model';
import {UserService} from '../../services/user.service';
import {
  BaseUnsubscribeComponent
} from "../../../../../user-portal/src/app/shared/components/base-unsubscribe.component";
import {finalize, takeUntil} from "rxjs/operators";
import {forkJoin, Observable} from "rxjs";
import {AuthUserService} from "../../app-services/auth-user.service";
import {AppDialogModule} from "../../dialog/dialog/app-dialog.module";
import {SelectLanguageComponent} from "../../app-components/select-language/select-language.component";
import {TranslateModule} from "@ngx-translate/core";
import {SelectTimezoneComponent} from "./select-timezone/select-timezone.component";
import {
  DialogActionsCancelSaveComponent
} from "../../dialog/dialog-save/dialog-actions-cancel-save/dialog-actions-cancel-save.component";

@Component({
  selector: 'app-settings-dialog',
  standalone: true,
  imports: [
    AppDialogModule,
    SelectLanguageComponent,
    TranslateModule,
    SelectTimezoneComponent,
    DialogActionsCancelSaveComponent
  ],
  templateUrl: './settings-dialog.component.html',
  styleUrls: ['./settings-dialog.component.scss', '../../app-styles/dialog-common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SettingsDialogComponent extends BaseUnsubscribeComponent implements OnInit, OnDestroy {

  changeTracker: ChangeTracker;
  showSpinner = false;

  isAdminPortal = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { user: User },
    private dialogRef: MatDialogRef<SettingsDialogComponent>,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private authUserService: AuthUserService,
    private viewLanguageService: ViewLanguageService) {
    super();
  }

  ngOnInit(): void {
    this.isAdminPortal = this.authUserService.isAdmin;
    const interfaceLanguage = this.viewLanguageService.get();
    this.changeTracker = new ChangeTracker(interfaceLanguage, this.data.user);
  }

  onInterfaceLanguageChange(lang: string) {
    this.changeTracker.interfaceLanguage = lang;
  }

  onAddressLanguageChange(lang: string) {
    this.changeTracker.addressLanguage = lang;
  }

  onTimezoneChange(tz: TimeZone) {
    this.changeTracker.timezone = tz;
  }

  public onSubmit(): void {
    if (!this.changeTracker.hasChanges) {
      this.closeDialog();
      return;
    }
    this.updateInterfaceLanguage();
    this.updateAddressLanguageAndTimezone();
  }

  private updateInterfaceLanguage(): void {
    if (this.changeTracker.isInterfaceAddressChanged()) {
      this.viewLanguageService.set(this.changeTracker.interfaceLanguage);
    }
  }

  private updateAddressLanguageAndTimezone(): void {
    const tasks = this.createTasks();
    if (tasks.length > 0) {
      this.showSpinner = true;
      forkJoin(tasks)
        .pipe(takeUntil(this.destroy),
          finalize(() => {
            this.showSpinner = false;
            this.cdr.detectChanges();
            this.authUserService.update();
          }))
        .subscribe({
          complete: () => this.closeDialog()
        });
    } else {
      this.closeDialog();
    }
  }

  private createTasks(): Observable<any>[] {
    const tasks = [];
    if (this.changeTracker.isAddressLanguageChanged()) {
      const changeLanguageTask = this.userService.changeLanguageByName(this.data.user.id, this.changeTracker.addressLanguage);
      tasks.push(changeLanguageTask);
    }
    if (this.changeTracker.isTimezoneChanged()) {
      const changeTimezoneTask = this.userService.changeTimeZone(this.data.user.id, this.changeTracker.timezone);
      tasks.push(changeTimezoneTask);
    }
    return tasks;
  }

  closeDialog(): void {
    if (this.changeTracker.isInterfaceAddressChanged()) {
      window.location.reload();
    }
    this.dialogRef.close();
  }
}


class ChangeTracker {
  source: Data;
  changing: Data;

  hasChanges = false;

  constructor(interfaceLanguage: string,
              user: User) {
    this.source = new Data(interfaceLanguage, user.language.name, user.timeZone);
    this.changing = new Data(interfaceLanguage, user.language.name, user.timeZone);
  }

  get interfaceLanguage(): string {
    return this.changing.interfaceLanguage;
  }

  get addressLanguage(): string {
    return this.changing.addressLanguage;
  }

  get timezone(): TimeZone {
    return this.changing.timezone;
  }

  set interfaceLanguage(lang: string) {
    this.changing.interfaceLanguage = lang;
    this.checkChanges();
  }

  set addressLanguage(lang: string) {
    this.changing.addressLanguage = lang;
    this.checkChanges();
  }

  set timezone(tz: TimeZone) {
    this.changing.timezone = tz;
    this.checkChanges();
  }

  isInterfaceAddressChanged(): boolean {
    return this.source.interfaceLanguage !== this.changing.interfaceLanguage;
  }

  isAddressLanguageChanged(): boolean {
    return this.source.addressLanguage !== this.changing.addressLanguage;
  }

  isTimezoneChanged(): boolean {
    return this.source.timezone.id !== this.changing.timezone.id;
  }

  private checkChanges() {
    this.hasChanges = this.isAddressLanguageChanged() || this.isInterfaceAddressChanged() || this.isTimezoneChanged();
  }
}

class Data {
  constructor(public interfaceLanguage: string,
              public addressLanguage: string,
              public timezone: TimeZone) {
  }
}
