import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MapDetails } from '../modelinterfaces/map-details.model';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class MapDetailsService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getTrackEventCenter(unitId: number,
                             time: string,
                             intervalSeconds: number,
                             startTime: string = null,
                             finishTime: string = null): Observable<MapDetails> {
    const url = `${this.environmentHelper.urlV2}/mapDetails/trackEventCenter`;
    let params = new HttpParams();
    params = params.set('unitId', unitId);
    params = params.set('time', time);
    if (intervalSeconds) {
      params = params.set('intervalSeconds', intervalSeconds);
    }
    if (startTime) {
      params = params.set('startTime', startTime);
    }
    if (finishTime) {
      params = params.set('finishTime', finishTime);
    }

    return this.http.get<MapDetails>(url, {params: params}).pipe(
      map(obj => MapDetails.valueOf(obj))
    );
  }
}
