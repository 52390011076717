import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppMessageService } from '../../../../../../common-module/src/lib/app-services/app-message.service';
import { User } from '../../../../../../common-module/src/lib/modelinterfaces/user.model';
import { UserService } from '../../../../../../common-module/src/lib/services/user.service';
import { AppRegexp } from '../../../../../../common-module/src/lib/utils/app-regexp';
import { Password } from '../../../shared/constants/enums/password';

export interface IsEqualPassword {
  resolver: (value?: any) => void;
  value: string;
}

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss', '../../../../../../common-module/src/lib/app-styles/dialog-common.scss']
})

export class ChangePasswordDialogComponent implements OnInit {

  public readonly PASSWORD_MIN_LENGTH = Password.MIN_LENGTH;

  public hideOldPassword = true;
  public hideNewPassword = true;
  public hideConfirmPassword = true;
  public showSpinner = false;
  public userPasswordForm: UntypedFormGroup;

  private isEqualPasswordSource = new BehaviorSubject<IsEqualPassword>(null);
  private isEqualPassword$: Observable<IsEqualPassword> = this.isEqualPasswordSource.asObservable();
  private subscription: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { user: User },
              private appUserMessageService: AppMessageService,
              private dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
              private userService: UserService) {
  }

  get formControl(): { [p: string]: AbstractControl } {
    return this.userPasswordForm.controls;
  }

  get formValue(): any {
    return this.userPasswordForm.value;
  }

  ngOnInit(): void {
    this.userPasswordForm = new UntypedFormGroup(
      {
        'old-password': new UntypedFormControl(null, [Validators.required], this.checkPasswordAsync.bind(this)),
        'new-password-first': new UntypedFormControl(null, [Validators.required,
          Validators.pattern(AppRegexp.AT_LEAST_ONE_DIGIT_UPPERCASE_LOWERCASE), Validators.minLength(this.PASSWORD_MIN_LENGTH)]),
        'new-password-second': new UntypedFormControl(null, [Validators.required,
          Validators.pattern(AppRegexp.AT_LEAST_ONE_DIGIT_UPPERCASE_LOWERCASE), Validators.minLength(this.PASSWORD_MIN_LENGTH)])
      },
    );
    this.userPasswordForm.controls['new-password-second'].setValidators(
      this.notEqualPassword.bind(this, this.formControl['new-password-first'])
    );

    this.subscription = this.isEqualPassword$
      .pipe(
        debounceTime(700)
      )
      .subscribe(r => {
        if (r !== null) {
          this.showSpinner = true;
          this.userService.checkPassword(this.data.user.id, r.value)
            .subscribe(
              isEqual => {
                this.showSpinner = false;
                isEqual ? r.resolver(null) : r.resolver({notEqual: true});
              },
              () => {
                this.showSpinner = false;
              });
        }
      });
  }

  private checkPasswordAsync(control: UntypedFormControl): Promise<any> {
    return new Promise((resolve) => {
        this.isEqualPasswordSource.next({resolver: resolve, value: control.value});
      }
    );
  }

  private notEqualPassword(sourceControlForCheck: UntypedFormControl, control: UntypedFormControl): { [key: string]: boolean } {
    if (control.value !== sourceControlForCheck.value) {
      return {notEqual: true};
    }
    return null;
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onSubmit(): void {
    this.userService.changePassword(this.data.user.id, this.formValue['old-password'], this.formValue['new-password-second']).subscribe(
      () => {
        this.appUserMessageService.openSnackBar('message.info.changes-saved');
        this.dialogRef.close();
      },
      error => {
        this.dialogRef.close();
        throw error;
      });
  }
}
