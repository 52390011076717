import {DateTimeFormatter, Instant, LocalDate, LocalDateTime, LocalTime, ZonedDateTime} from "@js-joda/core";
import {Dayjs} from "dayjs";
import {TimeZoneUtil} from "./time-zone-util";

export class LocalDateTimeUtil {

  private static readonly formatterCache: Map<string, DateTimeFormatter> = new Map();

  public static ofMoment(m: Dayjs): LocalDateTime {
    const date = m.toDate();
    return LocalDateTime.of(
      LocalDate.of(date.getFullYear(), date.getMonth() + 1, date.getDate()),
      LocalTime.of(date.getHours(), date.getMinutes(), date.getSeconds())
    )
  }

  public static toLocalDatetime(instant: Instant): LocalDateTime {
    return ZonedDateTime.ofInstant(instant, TimeZoneUtil.BROWSER_ZONE_ID)
      .toLocalDateTime()
  }

  public static toFormattedLocalDatetime(instant: Instant, pattern: string = 'dd.MM.yyyy HH:mm:ss'): string {
    let formatter = this.formatterCache.get(pattern);
    if (!formatter) {
      formatter = DateTimeFormatter.ofPattern(pattern);
      this.formatterCache.set(pattern, formatter);
    }
    return this.toLocalDatetime(instant).format(formatter);
  }

  public static now(): LocalDateTime {
    return LocalDateTime.now(TimeZoneUtil.BROWSER_ZONE_ID);
  }
}
