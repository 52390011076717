export class NotificationType {

  constructor(public type: string,
              public translated: string) {
  }

  public static valueOf(i: NotificationType): NotificationType {
    return i ? new NotificationType(i.type, i.translated) : null;
  }

  public static valuesOf(list: NotificationType[]): NotificationType[] {
    return list ? list.map(o => NotificationType.valueOf(o)) : [];
  }
}
