import {BehaviorSubject} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class FooterButtonsService {

  private isOpenSideNavSource = new BehaviorSubject<boolean>(true);
  public currentIsOpenSideNav$ = this.isOpenSideNavSource.asObservable();

  public changeIsOpenSideNav(isOpen: boolean): void {
    this.isOpenSideNavSource.next(isOpen);
  }

  public toggleIsOpenSideNav(): void {
    this.isOpenSideNavSource.next(!this.isOpenSideNavSource.value);
  }
}
