import { RawData } from './raw-data.model';
import { SensorLastValue } from './sensor-last-value.model';
import { Sensor } from './sensor.model';
import { UnitOfMeasure } from './unit-of-measure.model';

export class SensorSource extends SensorLastValue {

  constructor(
    public readonly id: number,
    public name: string,
    public type: string,
    public calibrationTable: RawData[],
    public unitOfMeasure: UnitOfMeasure,
    public options: object,
    public visible: boolean,
    public nameTranslated: string,
    public typeTranslated: string,
    public value: string,
    public formatted: string,
    public formulaRaw: string,
    public conditionRaw: string) {
    super(id, name, type, calibrationTable, unitOfMeasure, options, visible,
      nameTranslated, typeTranslated, value, formulaRaw, conditionRaw);
  }

  public static valueOf(i: SensorSource): SensorSource {
    return i ? new SensorSource(i.id, i.name, i.type, i.calibrationTable, i.unitOfMeasure,
      i.options, i.visible, i.nameTranslated, i.typeTranslated, i.value, i.formatted, i.formulaRaw, i.conditionRaw) : null;
  }

  public static valuesOf(list: SensorSource[]): SensorSource[] {
    return list ? list.map(o => SensorSource.valueOf(o)) : [];
  }

  public static createFromSensor(sensor: Sensor): SensorSource {
    return sensor ? new SensorSource(sensor.id, sensor.name, sensor.type, sensor.calibrationTable, sensor.unitOfMeasure,
      sensor.options, sensor.visible, sensor.nameTranslated, sensor.typeTranslated, null, null,
      sensor.formulaRaw, sensor.conditionRaw) : null;
  }
}
