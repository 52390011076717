import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalStyleClass } from '../../../../../common-module/src/lib/app-enums/global-style-class.enum';
import { Tab } from '../../../../../common-module/src/lib/app-enums/tab';
import { AppMessageService } from '../../../../../common-module/src/lib/app-services/app-message.service';
import { Geofence } from '../../../../../common-module/src/lib/modelinterfaces/geofence.model';
import { GeofenceService } from '../../../../../common-module/src/lib/services/geofence.service';
import { NotificationSourceService } from '../../../../../common-module/src/lib/services/notification-source.service';
import {
  DeleteGeofenceDialogComponent
} from '../../system/main/map/geofence-manager/geofence-delete-dialog/delete-geofence-dialog.component';
import { GeofenceManagerModeService, GeofenceMode } from '../../system/main/map/geofence-manager/geofence-manager-mode.service';

export interface SelectedGeofenceIds {
  [Tab.MAP]: Set<number>;
  [Tab.REPORT]: Set<number>;
  [Tab.NOTIFICATION]: Set<number>;
}

@Injectable({providedIn: 'root'})

export class GeofenceListService {

  private currentGeofencesSource = new BehaviorSubject<Geofence[]>([]);
  public currentGeofences$: Observable<Geofence[]> = this.currentGeofencesSource.asObservable();

  private selectedGeofenceSource = new BehaviorSubject<Geofence>(null);
  public selectedGeofence$: Observable<Geofence> = this.selectedGeofenceSource.asObservable();

  private _selectedGeofenceIds: SelectedGeofenceIds = {
    [Tab.MAP]: new Set<number>(),
    [Tab.REPORT]: new Set<number>(),
    [Tab.NOTIFICATION]: new Set<number>(),
  };

  private loadingSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this.loadingSource.asObservable();

  private inited = false;

  constructor(private appUserMessageService: AppMessageService,
              private dialog: MatDialog,
              private geofenceManagerModeService: GeofenceManagerModeService,
              private geofenceService: GeofenceService,
              private notificationSourceService: NotificationSourceService,
              private router: Router,
              private translateService: TranslateService) {
  }

  get selectedGeofenceIds(): SelectedGeofenceIds {
    return this._selectedGeofenceIds;
  }

  public changeSelectedGeofence(g: Geofence): void {
    this.selectedGeofenceSource.next(g);
  }

  public init(): void {
    if (this.inited) {
      return;
    }
    this.loadingSource.next(true);
    this.geofenceService.getList().subscribe(list => {
        this.currentGeofencesSource.next(list);
        this.loadingSource.next(false);
        if (this.router.parseUrl(this.router.url).root.children.primary?.segments[0]?.toString() as Tab === Tab.MAP) {
          this.geofenceManagerModeService.changeState(GeofenceMode.LIST);
        }
        this.inited = true;
      },
      error => {
        this.loadingSource.next(false);
        throw error;
      });
  }

  public getInstantList(): Geofence[] {
    return this.currentGeofencesSource.getValue();
  }

  public getSelectedList(tab: Tab): Geofence[] {
    return this.currentGeofencesSource.getValue().filter(geofence => this.selectedGeofenceIds[tab].has(geofence.id));
  }

  public addSelectedGeofenceId(id: number, tab: Tab): void {
    this._selectedGeofenceIds[tab].add(id);
  }

  public removeSelectedGeofenceId(id: number, tab: Tab): void {
    this._selectedGeofenceIds[tab].delete(id);
  }

  public changeList(geofence: Geofence): void {
    const geofences = this.getInstantList();
    const index = geofences.findIndex(g => g.id === geofence.id);
    if (index >= 0) {
      geofences[index] = geofence;
    } else {
      geofences.unshift(geofence);
    }
    this.currentGeofencesSource.next(geofences);
  }

  public deleteWithCheck(geofence: Geofence): void {
    this.notificationSourceService.getRulesByDeletedGeofence(geofence.id, this.translateService.currentLang)
      .subscribe(notificationList => {
          if (notificationList.length > 0) {
            const dialogRef = this.dialog.open(DeleteGeofenceDialogComponent, {
              panelClass: GlobalStyleClass.DIALOG_SIZE_LIMIT,
              data: {
                geofence: geofence,
                ruleList: notificationList
              }
            });
            dialogRef.afterClosed().subscribe(res => {
              if (res) {
                this.delete(geofence);
              }
            });
          } else {
            this.delete(geofence);
          }
        },
        () => this.appUserMessageService.openSnackBar('message.error.error-while-deleting', true)
      );
  }

  private delete(geofence: Geofence): void {
    this.geofenceService.delete(geofence.id)
      .subscribe(
        () => {
          const geofences = this.getInstantList();
          const index = geofences.findIndex(g => g.id === geofence.id);
          geofences.splice(index, 1);
          this.currentGeofencesSource.next(geofences);
          this.changeSelectedGeofence(null);
        }
      );
  }

  public clearSelectedGeofences(tab: Tab): void {
    this._selectedGeofenceIds[tab].clear();
  }

  public destroy(): void {
    this.currentGeofencesSource.next([]);
    this._selectedGeofenceIds[Tab.MAP] = new Set<number>();
    this._selectedGeofenceIds[Tab.REPORT] = new Set<number>();
    this._selectedGeofenceIds[Tab.NOTIFICATION] = new Set<number>();
    this.selectedGeofenceSource.next(null);
    this.inited = false;
  }
}
