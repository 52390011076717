import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {AppMessageService} from '../../../../../../common-module/src/lib/app-services/app-message.service';
import {UiSpinnerService} from '../../../../../../common-module/src/lib/app-services/ui-spinner.service';
import {TelegramChat} from '../../../../../../common-module/src/lib/modelinterfaces/telegram-chat.model';
import {TelegramService} from '../../../../../../common-module/src/lib/services/telegram.service';

@Component({
  selector: 'app-informing-dialog',
  templateUrl: './informing-dialog.component.html',
  styleUrls: ['./informing-dialog.component.scss', '../../../../../../common-module/src/lib/app-styles/dialog-common.scss']
})

export class InformingDialogComponent implements OnInit {

  public telegramChatList: TelegramChat[];

  constructor(private appMessageService: AppMessageService,
              private dialogRef: MatDialogRef<InformingDialogComponent>,
              private telegramService: TelegramService,
              private uiSpinnerService: UiSpinnerService) {
  }

  ngOnInit(): void {
    this.getChatList();
  }

  private getChatList(): void {
    this.uiSpinnerService.show();
    this.telegramService.getList().subscribe(list => {
        this.telegramChatList = list.sort((a, b) => {
          if (a.activated && !b.activated) {
            return 1;
          } else if (!a.activated && b.activated) {
            return -1;
          } else {
            return b.telegramUser.firstName.localeCompare(a.telegramUser.firstName);
          }
        });
        this.uiSpinnerService.stop();
      }
    );
  }

  public onActivateChat(idChat: number): void {
    this.uiSpinnerService.show();
    this.telegramService.activateChat(idChat, true).subscribe(() => {
        this.appMessageService.openSnackBar('message.info.changes-saved', false);
        this.uiSpinnerService.stop();
        this.getChatList();
      }
    );
  }

  public onDeleteChat(idChat: number): void {
    this.uiSpinnerService.show();
    this.telegramService.deleteChat(idChat).subscribe(() => {
        this.appMessageService.openSnackBar('message.info.changes-saved', false);
        this.uiSpinnerService.stop();
        this.getChatList();
      }
    );
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}
