import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MileageClassifierScannedDialogComponent} from "./mileage-classifier-scanned-dialog.component";
import {
  MileageClassifierScannedMapComponent
} from "./mileage-classifier-scanned-map/mileage-classifier-scanned-map.component";
import {AppDialogModule} from "../../../../../../../../common-module/src/lib/dialog/dialog/app-dialog.module";
import {OlMapModule} from "../../../../../shared/aur-map-creator/map/component/ol-map/ol-map.module";
import {
  MileageClassifierScannedMapFactory
} from "./mileage-classifier-scanned-map/mileage-classifier-scanned-map.factory";



@NgModule({
  declarations: [
    MileageClassifierScannedDialogComponent,
    MileageClassifierScannedMapComponent,
  ],
  imports: [
    CommonModule,
    AppDialogModule,
    OlMapModule
  ],
  exports: [
    MileageClassifierScannedMapComponent
  ],
  providers: [
    MileageClassifierScannedMapFactory
  ]
})
export class MileageClassifierScannedDialogModule { }
