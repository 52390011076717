import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Mode } from '../modelinterfaces/mode.model';
import { HttpParamsUtil } from '../utils/http-params-util';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class MechanismModeService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public update(mode: Mode, lang: string): Observable<Mode> {
    const url = `${this.environmentHelper.urlV2}/mechanismMode/${mode.id}`;
    return this.http.put<Mode>(url, mode, {params: HttpParamsUtil.createByLang(lang)}).pipe(
      map(obj => Mode.valueOf(obj))
    );
  }

  public changeFuelRate(modeId: number, fuelRate: number, lang: string): Observable<Mode> {
    const url = `${this.environmentHelper.urlV2}/mechanismMode/${modeId}/fuelRate`;
    return this.http.patch<Mode>(url, {fuelRate: fuelRate}, {params: HttpParamsUtil.createByLang(lang)}).pipe(
      map(obj => Mode.valueOf(obj))
    );
  }

  public updateEngineHoursRate(modeId: number, rate: number, lang: string): Observable<Mode> {
    const url = `${this.environmentHelper.urlV2}/mechanismMode/${modeId}/engineHoursRate`;
    return this.http.patch<Mode>(url, {engineHoursRate: rate}, {params: HttpParamsUtil.createByLang(lang)}).pipe(
      map(obj => Mode.valueOf(obj))
    );
  }
}
