import { NotificationActionCommand } from './notification-action-command.model';
import { TelegramChat } from './telegram-chat.model';

export class Interaction {

  constructor(public telegramChats: TelegramChat[],
              public actionCommand: NotificationActionCommand,
              public pushEnabled: boolean) {
  }

  public static valueOf(o: Interaction): Interaction {
    return o ?
      new Interaction(TelegramChat.valuesOf(o.telegramChats), NotificationActionCommand.valueOf(o.actionCommand), o.pushEnabled) : null;
  }
}
