import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AppCommonModule } from '../../../../../../common-module/src/lib/app-common.module';
import { SharedModule } from '../../../shared/shared.module';
import { EditVehicleModule } from '../vehicle/edit-vehicle.module';
import { AddressCardComponent } from './cards/address-card/address-card.component';
import { DeviceStatusCardComponent } from './cards/device-status-card/device-status-card.component';
import { DriverCardComponent } from './cards/driver-card/driver-card.component';
import { ExpireInfoCardComponent } from './cards/expire-info-card/expire-info-card.component';
import { InfoCardComponent } from './cards/info-card/info-card.component';
import { SensorsCardComponent } from './cards/sensors-card/sensors-card.component';
import { SendCommandDialogComponent } from "./cards/unit-info-card/send-command-dialog/send-command-dialog.component";
import { UnitInfoCardComponent } from './cards/unit-info-card/unit-info-card.component';
import { UnitStatusCardComponent } from './cards/unit-status-card/unit-status-card.component';
import { VehicleCardComponent } from './cards/vehicle-card/vehicle-card.component';
import { UnitInfoCarouselComponent } from './unit-info-carousel.component';
import {SecToStringDurationPipe} from "../../../../../../common-module/src/lib/pipes/sec-to-string-duration.pipe";
import {AppDialogModule} from "../../../../../../common-module/src/lib/dialog/dialog/app-dialog.module";
import {TranslateModule} from "@ngx-translate/core";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {SensorValuePipe} from "../../../../../../common-module/src/lib/pipes/sensor-value.pipe";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatChipsModule} from "@angular/material/chips";
import {MatTableModule} from "@angular/material/table";
import {
  TimestampTimezoneConvertPipe
} from "../../../../../../common-module/src/lib/pipes/timestamp-timezone-convert.pipe";
import {MatPaginatorModule} from "@angular/material/paginator";
import {ExpireIconComponent} from "../../../shared/components/expire-icon/expire-icon.component";
import {CurrentDriverComponent} from "../../../shared/components/current-driver/current-driver.component";
import {
  CertificateExpireComponent
} from "../../../../../../common-module/src/lib/app-components/certificate-expire/certificate-expire.component";

@NgModule({
  declarations: [
    UnitInfoCarouselComponent,
    AddressCardComponent,
    DeviceStatusCardComponent,
    DriverCardComponent,
    ExpireInfoCardComponent,
    InfoCardComponent,
    SensorsCardComponent,
    UnitInfoCardComponent,
    UnitStatusCardComponent,
    VehicleCardComponent,
    SendCommandDialogComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    EditVehicleModule,
    SharedModule,
    SecToStringDurationPipe,
    AppDialogModule,
    TranslateModule,
    MatIconModule,
    MatProgressSpinnerModule,
    SensorValuePipe,
    MatFormFieldModule,
    MatTooltipModule,
    MatIconButton,
    MatChipsModule,
    MatTableModule,
    TimestampTimezoneConvertPipe,
    MatPaginatorModule,
    ExpireIconComponent,
    CurrentDriverComponent,
    MatButton,
    CertificateExpireComponent
  ],
  providers: [],
  bootstrap: [],
  exports: [
    UnitInfoCarouselComponent
  ]
})

export class UnitInfoCarouselModule {
}
