<app-dialog [title]="'term.unsaved-data-on-page' | translate">

  <ng-container appDialogActions>
    <app-button-cancel (cancel)="onCancel()"></app-button-cancel>

    <app-button-minor (action)="onNotSave()"
                             [text]="'action.not-save' | translate">
    </app-button-minor>

    <app-button-save (save)="onSubmit()"
                            [disabled]="!data.isValid">
    </app-button-save>
  </ng-container>

</app-dialog>
