import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef, Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {MatAccordion} from '@angular/material/expansion';
import {MatDialog} from '@angular/material/dialog';
import {fromEvent, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, takeUntil} from 'rxjs/operators';
import {UnitShort} from '../../../../../common-module/src/lib/modelinterfaces/unit-short.model';

import {SelectedService} from '../../shared/services/selected.service';
import {UnitGroupsService} from '../../shared/services/unit-groups.service';
import {
  UnitGroupView,
  UserUnitGroups
} from '../../../../../common-module/src/lib/modelinterfaces/user-unit-groups.model';
import {UnitGroup} from '../../../../../common-module/src/lib/modelinterfaces/unit-group.model';
import {EditGroupDialogComponent} from './edit-group-dialog/edit-group-dialog.component';
import {UnitFilterService} from '../../shared/services/unit-filter.service';
import {LocalStorageKey} from '../../../../../common-module/src/lib/app-enums/local-storage-key';
import {FilterUsersDialogComponent} from './filter-users-dialog/filter-users-dialog.component';
import {Tab} from '../../../../../common-module/src/lib/app-enums/tab';
import {UnitsFilter, UnitsOrder} from '../../shared/constants/enums/units-filter';
import {PublicLinkDialogComponent} from './public-link-dialog/public-link-dialog.component';
import {GlobalStyleClass} from '../../../../../common-module/src/lib/app-enums/global-style-class.enum';
import {NotificationManagerService} from '../../shared/services/notification-manager.service';


@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SideNavComponent implements OnInit, AfterViewInit, OnDestroy {

  public readonly FILTER_LIST: UnitsFilter[] = Object.values(UnitsFilter);
  public readonly ORDER_LIST: UnitsOrder[] = Object.values(UnitsOrder);

  @Input()
  public showNotificationPanel = true;

  @ViewChild('inputTag', {static: false}) public inputElem: ElementRef;
  @ViewChild('userAccordion', {static: true}) userAccordion: MatAccordion;

  public currentFilter$: Observable<UnitsFilter>;
  public filteredUnits$: Observable<UnitShort[]>;
  public filteringUnitBySelectedUsers$: Observable<string[]>;
  public filteredUnitGroups: UserUnitGroups[] = [];
  public isSplitByUsers = false;
  public openTab: Tab;
  public searchState = false;
  public searchStr: string;
  public selectedUnitIds: Set<number> = new Set<number>();
  public selectedUserIds$: Observable<number[]>;
  public tabName = Tab;
  public unitsFilter = UnitsFilter;
  public unitGroups: UserUnitGroups[];
  public userNameList: string[] = [];
  public unitGroupViews = new Map<number, UnitGroupView>;

  private componentDestroyed = new Subject<boolean>();

  constructor(private cdr: ChangeDetectorRef,
              private dialog: MatDialog,
              private notificationManagerService: NotificationManagerService,
              private selectedService: SelectedService,
              private router: Router,
              private unitFilterService: UnitFilterService,
              private unitGroupsService: UnitGroupsService) {
    this.filteringUnitBySelectedUsers$ = this.unitFilterService.filteringUnitBySelectedUsers$;
  }

  ngOnInit(): void {
    this.openTab = this.filterUnitsByTab(this.router.parseUrl(this.router.url).root.children.primary?.segments[0]?.toString() as Tab);
    this.selectedUserIds$ = this.selectedService.selectedUserIds$;
    this.filteredUnits$ = this.unitFilterService.filteredUnits$;
    this.currentFilter$ = this.unitFilterService.currentFilter$;
    this.isSplitByUsers = this.openTab === Tab.PUBLIC ? false : !!window.localStorage.getItem(LocalStorageKey.UNITS_SPLIT_BY_USERS);
    this.router.events
      .pipe(
        takeUntil(this.componentDestroyed),
        filter(event => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url)
      )
      .subscribe(v => {
        this.openTab = this.filterUnitsByTab(v.split('/')[1] as Tab);
      });
    this.loadUnits();
  }

  private loadUnits(): void {
    this.unitGroupsService.unitGroups$
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(groups => {
        this.unitGroups = groups?.grouped || [];
        this.updateUnitGroupView();
        this.userNameList = this.getUserNameList(this.unitGroups);
        if (this.userNameList.length > 0) {
          this.unitFilterService.changeFilteringSelectedUsers(this.userNameList);
        }
        if (this.unitGroups.length === 1) {
          this.userAccordion.openAll();
        }
        this.cdr.detectChanges();
      });
    this.unitFilterService.filteringUnitBySelectedUsers$
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(userNames => {
        this.filteredUnitGroups = this.unitGroups.filter(el => userNames.includes(el.name));
        this.cdr.detectChanges();
      });
    this.selectedService.selectedUnitIdsSet$
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(set => {
        this.selectedUnitIds = set;
      });
  }

  private updateUnitGroupView(): void {
    const unitGroupView = new Map<number, UnitGroupView>();
    this.unitGroups.forEach(g => g.getUnitGroupViews().forEach((v, k) => unitGroupView.set(k, v)));
    this.unitGroupViews = unitGroupView;
  }

  ngAfterViewInit(): void {
    fromEvent(this.inputElem.nativeElement, 'keyup')
      .pipe(
        debounceTime(700),
        map((event: Event) => (<HTMLInputElement>event.target).value),
        distinctUntilChanged(),
      )
      .subscribe(value => {
        this.unitFilterService.changeSearchStr(value);
        this.searchStr = value;
        this.searchStr ? this.userAccordion.openAll() : this.userAccordion.closeAll();
      });
  }

  private getUserNameList(groups: UserUnitGroups[]): string[] {
    return groups.map(el => el.name);
  }

  private filterUnitsByTab(tab: Tab): Tab {
    if (tab !== Tab.VIDEO && this.openTab === Tab.VIDEO) {
      this.onChangeFilterSetting(UnitsFilter.NONE);
    }
    if (tab === Tab.VIDEO) {
      this.onChangeFilterSetting(UnitsFilter.VIDEO);
    }
    return tab;
  }

  public toggleIsSplitByUsers(): void {
    this.isSplitByUsers = !this.isSplitByUsers;
    window.localStorage.setItem(LocalStorageKey.UNITS_SPLIT_BY_USERS, this.isSplitByUsers ? this.isSplitByUsers.toString() : '');
  }

  public onAddGroupDialog(user: UserUnitGroups): void {
    const dialogRef = this.dialog.open(EditGroupDialogComponent, {
      height: '850px',
      autoFocus: false,
      data: {
        user: user,
        allUnits: this.unitGroupsService.getUnitListByUser(user)
      }
    });
    dialogRef.afterClosed().subscribe((r) => {
      if (r?.addGroup) {
        const index = this.getIndexCurrentUser(user.id);
        this.unitGroups[index].groups = this.unitGroups[index].addGroup(r.addGroup);
      }
      this.updateUnitGroupView();
      this.cdr.detectChanges();
    });
  }

  private getIndexCurrentUser(userId: number): number {
    return this.unitGroups.findIndex(el => el.id === userId);
  }

  public onEditGroupDialog(user: UserUnitGroups, group: UnitGroup): void {
    const dialogRef = this.dialog.open(EditGroupDialogComponent, {
      height: '850px',
      autoFocus: false,
      data: {
        user: user,
        allUnits: this.unitGroupsService.getUnitListByUser(user),
        group: group
      }
    });
    dialogRef.afterClosed().subscribe((r) => {
      const index = this.getIndexCurrentUser(user.id);
      if (r?.deleteGroupId) {
        this.unitGroups[index].groups = this.unitGroups[index].deleteGroup(r.deleteGroupId);
      }
      if (r?.editGroup) {
        this.unitGroups[index].groups = this.unitGroups[index].editGroup(r.editGroup);
      }
      this.updateUnitGroupView();
      this.cdr.detectChanges();
    });
  }

  public asUserUnitGroups(user: UserUnitGroups): UserUnitGroups {
    return user;
  }

  public onOpenPublicAccessDialog(): void {
    this.dialog.open(PublicLinkDialogComponent, {
      panelClass: ['size-for-date-picker-dialog', GlobalStyleClass.DIALOG_SIZE_LIMIT],
      autoFocus: false
    });
  }

  public onChangeFilterSetting(value: UnitsFilter): void {
    this.unitFilterService.changeCurrentFilter(value);
    value !== UnitsFilter.NONE ? this.userAccordion.openAll() : this.userAccordion.closeAll();
  }

  public onOpenFilterUsersDialog(): void {
    this.dialog.open(FilterUsersDialogComponent, {
      width: '350px',
      autoFocus: false,
      data: {
        userNameList: this.userNameList
      }
    });
  }

  public toggleSearchState(): void {
    this.searchState = !this.searchState;
    if (this.searchState) {
      setTimeout(() => this.inputElem.nativeElement.focus());
    } else {
      this.unitFilterService.changeSearchStr('');
      this.inputElem.nativeElement.value = '';
      this.searchStr = '';
      this.userAccordion.closeAll();
    }
  }

  public getSelectedUnitIdListByUser(user: UserUnitGroups, list: Set<number>): number[] {
    const listByUser = [];
    list.forEach(unitId => {
      if (user.owned.some(el => el.id === unitId) || user.shared.some(el => el.id === unitId)) {
        listByUser.push(unitId);
      }
    });
    return listByUser;
  }

  public onChangeStateNotificationPanel(isOpened: boolean = true): void {
    this.notificationManagerService.isReceiveLastNotifications = isOpened;
    if (isOpened) {
      this.notificationManagerService.getLastNotifications();
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
  }

  onChangeOrderSetting(setting: UnitsOrder) {
    this.unitFilterService.changeCurrentOrder(setting);
  }
}
