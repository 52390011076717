import { GpsOdometerSettings } from './gps-odometer-settings.model';

export class GpsOdometer {

  constructor(
    public corrected: number,
    public gpsOdometerSettings: GpsOdometerSettings,
    public original: number) {
  }

  public static valueOf(i: GpsOdometer): GpsOdometer {
    return i ? new GpsOdometer(i.corrected, GpsOdometerSettings.valueOf(i.gpsOdometerSettings), i.original) : null;
  }
}
