import { Language } from './language.model';
import { TimeZone } from './time-zone.model';
import { UnitShort } from './unit-short.model';
import { UserShort } from './user-short.model';

export class BuildInfoTableData {

  constructor(public reportName: string,
              public grouping: string,
              public units: UnitShort[],
              public startTime: string,
              public finishTime: string,
              public displayColumns: string[],
              public lastUnitUpdateTime: Map<number, string>,
              public tz: TimeZone,
              public language: Language,
              public creator: UserShort,
              public created: string,
              public groupId: string,
              public united: boolean) {
    this.lastUnitUpdateTime = new Map();
    Object.keys(lastUnitUpdateTime).forEach(key => {
      this.lastUnitUpdateTime.set(Number(key), lastUnitUpdateTime[key]);
    });
  }

  public static valueOf(i: BuildInfoTableData): BuildInfoTableData {
    return i ? new BuildInfoTableData(i.reportName, i.grouping, i.units, i.startTime, i.finishTime, i.displayColumns,
      i.lastUnitUpdateTime, TimeZone.valueOf(i.tz), Language.valueOf(i.language), UserShort.valueOf(i.creator),
      i.created, i.groupId, i.united) : null;
  }
}
