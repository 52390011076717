import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SensorChartExt } from '../modelinterfaces/sensor-chart-ext.model';
import { EnvironmentHelper } from './environment-helper';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})

export class SensorChartExtService {

  constructor(private http: HttpClient,
              private environmentHelper: EnvironmentHelper,
              private translateService: TranslateService) {
  }

  public getByUnitId(unitId: number,
                     startTime: string,
                     finishTime: string,
                     sensorIds: number[],
                     paramNames: string[],
                     virtualNames: string[]): Observable<SensorChartExt> {
    const url = `${this.environmentHelper.urlV2}/sensorChartExt`;
    let params = new HttpParams();
    params = params.set('unitId', unitId);
    params = params.set('startTime', startTime);
    params = params.set('finishTime', finishTime);
    if (sensorIds) {
      params = params.set('sensorIds', sensorIds.join(','));
    }
    if (paramNames) {
      params = params.set('paramNames', paramNames.join(','));
    }
    if (virtualNames) {
      params = params.set('virtual', virtualNames.join(','));
    }
    params = params.set('lang', this.translateService.currentLang);
    return this.http.get<SensorChartExt>(url, {params: params}).pipe(
      map(obj => SensorChartExt.valueOf(obj))
    );
  }
}
