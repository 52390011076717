export class ReportOptions {

  constructor(public name: string,
              public nameTranslated: string,
              public availableGrouping: string[],
              public hasGeofences: boolean) {
  }

  public static valueOf(i: ReportOptions): ReportOptions {
    return i ? new ReportOptions(i.name, i.nameTranslated, i.availableGrouping, i.hasGeofences) : null;
  }

  public static valuesOf(list: ReportOptions[]): ReportOptions[] {
    return list ? list.map(o => ReportOptions.valueOf(o)) : [];
  }
}
