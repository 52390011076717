import {Logistic} from "../../../../../../../common-module/src/lib/modelinterfaces/logistic.model";
import Warehouse = Logistic.Warehouse;
import {WarehouseMapUtil} from "../../../map-utils/warehouse-map-util";
import {AppColor} from "../../../../../../../common-module/src/lib/app-enums/app-color";
import OlMap from "ol/Map";
import VectorLayer from "ol/layer/Vector";
import {BehaviorSubject} from "rxjs";
import {FeatureLike} from "ol/Feature";
import {PopupMapConfig, PopupMap} from "../../../aur-map-creator/map/wrapper/popup.map";

export interface WarehouseMapConfig extends PopupMapConfig {
  warehouses?: Warehouse[];
}

export class WarehousesMap extends PopupMap {

  public $clickedWarehouse = new BehaviorSubject(null);
  protected warehousesLayer: VectorLayer;

  constructor(map: OlMap, config?: WarehouseMapConfig) {
    super(map, config);
    this.setWarehouses(config?.warehouses ?? []);
    this.registerClickEventHandler();
  }

  private registerClickEventHandler(): void {
    this.map.on('click', this.handleMapClick.bind(this));
  }

  private handleMapClick(e: any): void {
    this.map.forEachFeatureAtPixel(e.pixel, (f: FeatureLike) => {
      const warehouse = this.getWarehouse(f);
      if (!warehouse) return false;
      this.$clickedWarehouse.next(warehouse);
      return true;
    });
  }

  protected getWarehouse(f: FeatureLike): Warehouse {
    const value = f.get('value');
    return value instanceof Warehouse ? value : null;
  }

  public setWarehouses(warehouses: Warehouse[]) {
    this.clear();
    if (!warehouses) {
      return;
    }
    this.show(warehouses);
    if (this.isAutoCenterWarehouse()) {
      this.centerOn(this.warehousesLayer);
    }
  }

  protected isAutoCenterWarehouse(): boolean {
    return true;
  }

  private show(warehouses: Warehouse[]) {
    this.warehousesLayer = WarehouseMapUtil.createLayer(this.map, warehouses, AppColor.PRIMARY);
  }

  private clear() {
    this.removeLayers(this.warehousesLayer);
  }
}
