import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActionAbstractButtonComponent} from "../../../common/concrete-button/action-abstract-button.component";

@Component({
  selector: 'app-icon-button-show-hide',
  templateUrl: './icon-button-show-hide.component.html',
})
export class IconButtonShowHideComponent extends ActionAbstractButtonComponent {
  @Input() visibility = true;
  @Output() visibilityChange = new EventEmitter<boolean>();

  override onAction(event: MouseEvent): void {
    this.visibilityChange.emit(this.visibility);
  }
}
