export class LastMotionState {

  constructor(public secondsAgo: number,
              public change: string,
              public state: string) {
  }

  public static valueOf(i: LastMotionState): LastMotionState {
    return i ? new LastMotionState(i.secondsAgo, i.change, i.state) : null;
  }
}
