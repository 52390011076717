import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UnitStatus} from "../../../../../../../common-module/src/lib/modelinterfaces/enums/unit-status";
import {ConnectionStatus} from "../../../../../../../common-module/src/lib/modelinterfaces/enums/connection-status";
import {UnitShort} from "../../../../../../../common-module/src/lib/modelinterfaces/unit-short.model";
import {UnitState} from "../../../../../../../common-module/src/lib/modelinterfaces/unit-state.model";
import {UnitStateConst} from "../../../../shared/utils/unit-state.const";
import {NoticeUtil} from "../../../../../../../common-module/src/lib/utils/notice-util";

@Component({
  selector: 'app-unit-status-icon',
  templateUrl: './unit-status-icon.component.html',
  styleUrls: ['./unit-status-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitStatusIconComponent {
  readonly unitStatusEnum = UnitStatus;
  readonly connectionStatusEnum = ConnectionStatus;

  readonly STOP_SPEED = UnitStateConst.STOP_SPEED;
  readonly OFFLINE_EXCEEDED_TIME = 3600 * 24 * 30;

  unitStatus: UnitStatus = UnitStatus.ACTIVE;

  connectionStatus: ConnectionStatus = ConnectionStatus.NO_DATA;
  secondsAgo = 0;
  speed = 0;
  course = 0;
  time: string = ''

  unreadNotificationCount = 0;
  unreadNotificationCountView: string;

  @Input() timeZone = 'UTC';

  @Input() highlight = false;

  @Input() set unit(unit: UnitShort) {
    this.unitStatus = unit.status;
  }

  @Input() set unitState(unitState: UnitState) {
    this.connectionStatus = ConnectionStatus[unitState.connectionState.status as keyof typeof ConnectionStatus];
    this.secondsAgo = unitState.connectionState.secondsAgo;
    this.speed = unitState.gpsState?.speed || 0;
    this.course = unitState.gpsState?.course || 0;
    this.time = unitState.connectionState.time;
  }

  @Input() set activeNotificationCount(count: number) {
    this.unreadNotificationCount = count;
    this.unreadNotificationCountView = NoticeUtil.transformCount(this.unreadNotificationCount);
  }

}
