<form *ngIf="searchControl" class="search-address-control">
  <mat-form-field [matTooltip]="searchControl?.value?.name">
    <mat-label>{{label || 'Поиск по адресу'}}</mat-label>
    <input type="text"
           [placeholder]="'Город, улица, номер дома'"
           matInput
           [formControl]="searchControl"
           [matAutocomplete]="auto">

        <mat-icon matSuffix *ngIf="pending">
          <mat-spinner diameter="20"></mat-spinner>
        </mat-icon>

    <mat-autocomplete #auto="matAutocomplete"
                      autoActiveFirstOption
                      [displayWith]="displayFn"
                      (optionSelected)="onSelectAutocompleteAddress($event.option.value)">
      <mat-option class="address-option"
        *ngFor="let feature of ($searchAddressResult | async) || []" [value]="feature">
        {{feature.name}}
      </mat-option>
    </mat-autocomplete>

  </mat-form-field>
</form>
