<div *ngIf="(driver$ | async) || !isCarousel" class="image-box">
  <img [src]="(driver$ | async)?.avatarUrl || DEFAULT_AVATAR" alt="driver">

  <button mat-icon-button class="edit-button" (click)="editDriver()">
    <mat-icon>edit</mat-icon>
  </button>
</div>

<div class="driver">
  <div *ngIf="(driver$ | async) || !isCarousel" class="info">
    <div class="item-info">
      <span class="title">{{ 'term.name' | translate }}: </span>
      <span>{{ (driver$ | async)?.getFullName() }}</span>
    </div>

    <div class="item-info">
      <span class="title">{{ 'term.telephone' | translate }}: </span>
      <span>{{ (driver$ | async)?.phoneNumber }}</span>
    </div>
  </div>

  <div class="dialog-box-buttons">
    <button *ngIf="driver$ | async" mat-button class="button" (click)="removeDriver()">
      {{ 'action.remove' | translate }}
    </button>

    <button mat-button class="app-button primary" (click)="assignDriver()">{{ 'action.assign' | translate }}</button>
  </div>
</div>
