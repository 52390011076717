import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {UnitShort} from "../../../../../../common-module/src/lib/modelinterfaces/unit-short.model";
import {MatListOption, MatSelectionList} from "@angular/material/list";
import {BaseUnsubscribeComponent} from "../base-unsubscribe.component";
import {SelectionModel} from "@angular/cdk/collections";
import {takeUntil} from "rxjs/operators";
import {SearchComponent} from "../../../../../../common-module/src/lib/app-components/search/search.component";
import {MatCheckbox} from "@angular/material/checkbox";
import {TranslateModule} from "@ngx-translate/core";
import {MatTooltip} from "@angular/material/tooltip";
import {NgForOf} from "@angular/common";

export class ValueFilterWrapper<T> {
  public isSelected: boolean;

  constructor(public value: T) {
    this.isSelected = false;
  }
}

@Component({
  selector: 'app-unit-list-simple',
  standalone: true,
  imports: [
    SearchComponent,
    MatCheckbox,
    TranslateModule,
    MatSelectionList,
    MatListOption,
    MatTooltip,
    NgForOf,
  ],
  templateUrl: './unit-list-simple.component.html',
  styleUrls: ['./unit-list-simple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitListSimpleComponent extends BaseUnsubscribeComponent {

  @ViewChild('unitSelectionList', {static: true}) unitSelectionList: MatSelectionList;

  @Input() multiple = true;
  @Input() height: string;
  @Output() change = new EventEmitter<UnitShort[]>();

  all: ValueFilterWrapper<UnitShort>[];
  filtered: ValueFilterWrapper<UnitShort>[] = [];
  readonly selectionModel: SelectionModel<UnitShort> = new SelectionModel<UnitShort>(this.multiple);

  constructor(private cdr: ChangeDetectorRef) {
    super();
    this.selectionModel.changed.pipe(takeUntil(this.destroy))
      .subscribe(changeModel => this.change.emit(changeModel.source.selected));
  }


  @Input() set units(units: UnitShort[]) {
    this.all = units.map(unit => new ValueFilterWrapper<UnitShort>(unit));
    this.filtered = this.all;
  }

  @Input() set selected(selectedUnits: UnitShort[]) {
    if (selectedUnits) {
      const selectedIds = new Set(selectedUnits.map(unit => unit.id));
      this.filtered.forEach(w => {
        if (selectedIds.has(w.value.id)) {
          w.isSelected = true;
          this.selectionModel.select(w.value);
        } else {
          w.isSelected = false;
          this.selectionModel.deselect(w.value);
        }
      })
      this.cdr.detectChanges();
    }
  }

  onSearchChange(search: string) {
    if (!search) {
      this.filtered = this.all;
      return;
    }
    this.filtered = this.all.filter(wrapper => wrapper.value.name.toLowerCase().includes(search.toLowerCase()));
  }

  toggleAllSelections(checked: boolean) {
    if (checked) {
      this.unitSelectionList.selectAll();
      this.selectionModel.select(...this.all.map(w => w.value));
    } else {
      this.unitSelectionList.deselectAll();
      this.selectionModel.deselect(...this.selectionModel.selected)
    }
  }

  toggleSelection(wrapper: ValueFilterWrapper<UnitShort>) {
    wrapper.isSelected = !wrapper.isSelected;
    if (wrapper.isSelected) {
      this.selectionModel.select(wrapper.value);
    } else {
      this.selectionModel.deselect(wrapper.value);
    }
    this.cdr.detectChanges();
  }
}
