import {
  OlCircleStyleFactory,
  OlCircleStyleOpts
} from "../../../../../aur-map-creator/feature/core/ol/style/ol-circle-style.factory";
import {OlIconFactory, OlIconOpts} from "../../../../../aur-map-creator/feature/core/ol/ol-icon.factory";
import Style from "ol/style/Style";
import {AppColor} from "../../../../../../../../../common-module/src/lib/app-enums/app-color";
import {OlFillFactory} from "../../../../../aur-map-creator/feature/core/ol/ol-fill.factory";
import {OlStrokeFactory} from "../../../../../aur-map-creator/feature/core/ol/ol-stroke.factory";
import {
  OlTextStyleFactory,
  OlTextStyleOpts
} from "../../../../../aur-map-creator/feature/core/ol/style/ol-text-style.factory";
import {OlStyleFactory} from "../../../../../aur-map-creator/feature/core/ol/style/ol-style.factory";
import {StyleFactory} from "../../../../../aur-map-creator/feature/core/app/style.factory";


export interface OlStepStyleOpts {
  // 12
  radius?: number;

  // white
  fill?: AppColor;

  // white
  strokeColor?: AppColor;

  // 0
  strokeWidth?: number;

  iconUrl?: string;

  // [24, 24]
  iconSize?: [number, number];

  // primary
  iconColor?: AppColor;

  // ''
  labelText?: string;

  // white
  labelColor?: AppColor;

  // primary
  labelBackground?: AppColor;

  //undefined
  zIndex?: number;
}


export class StepStyleFactory extends StyleFactory<OlStepStyleOpts> {

  public static readonly DEFAULT_RADIUS = 12;

  protected override doCreate(opts: OlStepStyleOpts): Style[] {
    const circleStyle = this.createCircleStyle(opts);
    const iconStyle = this.createIconStyle(opts);
    const labelStyle = this.createLabelStyle(opts);
    return [circleStyle, iconStyle, labelStyle]
  }

  private createIconStyle(opts: OlStepStyleOpts): Style {
    const iconOpts = this.createIconOpts(opts);
    const icon = OlIconFactory.create(iconOpts);
    return OlStyleFactory.create({
      image: icon,
      zIndex: opts.zIndex
    })
  }

  private createCircleStyle(opts: OlStepStyleOpts): Style {
    const circleOpts = this.createCircleOpts(opts);
    const circle = OlCircleStyleFactory.create(circleOpts);
    return OlStyleFactory.create({
      image: circle,
      zIndex: opts.zIndex
    })
  }

  private createCircleOpts(opts: OlStepStyleOpts): OlCircleStyleOpts {
    return {
      radius: opts.radius ?? StepStyleFactory.DEFAULT_RADIUS,
      fill: OlFillFactory.create({
          color: opts.fill ?? AppColor.WHITE
        }
      ),
      stroke: OlStrokeFactory.create({
          color: opts.strokeColor ?? AppColor.WHITE,
          width: opts.strokeWidth ?? 0
        }
      )
    }
  }

  private createIconOpts(opts: OlStepStyleOpts): OlIconOpts {
    return {
      src: opts.iconUrl,
      size: opts.iconSize ?? [24, 24],
      color: opts.iconColor ?? AppColor.PRIMARY,
    }
  }

  public createLabelStyle(opts: OlStepStyleOpts): Style {
    if (!opts.labelText) {
      return null;
    }
    const labelOpts = this.createLabelOpts(opts);
    const label = OlTextStyleFactory.create(labelOpts);
    return OlStyleFactory.create({
      text: label,
      zIndex: opts.zIndex
    })
  }

  private createLabelOpts(opts: OlStepStyleOpts): OlTextStyleOpts {
    return {
      text: opts.labelText,
      offsetY: 18,
      fill: OlFillFactory.create({color: opts.labelColor ?? AppColor.WHITE}),
      backgroundFill: OlFillFactory.create({color: opts.labelBackground ?? AppColor.PRIMARY}),
      padding: [2, 2, 2, 2]
    }
  }
}
