import {NgModule} from '@angular/core';


@NgModule({
  declarations: [

  ],
  imports: [
  ],
  providers: [],
  bootstrap: [],
  exports: [
  ]
})

export class SharedModule {
}
