import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import OlMap from 'ol/Map';
import {Layer} from 'ol/layer';
import Point from 'ol/geom/Point';

import {OlCoordinate} from '../map-utils/OlCoordinate';
import {OlFeature} from '../map-utils/OlFeature';
import {SensorData} from '../../../../../common-module/src/lib/modelinterfaces/sensor-data.model';
import {ZIndexLayer} from '../constants/z-index';
import {LayerName} from '../constants/enums/layer-name';
import {SensorName} from '../../../../../common-module/src/lib/modelinterfaces/enums/sensor-name';
import {OlVectorLayer} from '../map-utils/OlVectorLayer';
import {CenterMapUtil} from '../map-utils/center-map.util';
import {LayerUtil} from '../map-utils/layer.util';
import {SensorDataStorage} from '../../../../../common-module/src/lib/modelinterfaces/sensor-data-storage.model';

export interface SensorDataSpeedAndDate {
  speed: number;
  date: string;
  index: number;
}

@Injectable({
  providedIn: 'root'
})

export class MapAnimationTripService {

  private readonly ANIMATION_INTERVAL = 300;

  private _animateIntervalId: ReturnType<typeof setInterval>;
  private animationLayer: Layer;
  private _currentSensorDataOnAnimationSource = new BehaviorSubject<SensorDataSpeedAndDate>({speed: 0, date: '', index: 0});
  private _currentSensorDataOnAnimation$: Observable<SensorDataSpeedAndDate> = this._currentSensorDataOnAnimationSource.asObservable();
  private map: OlMap;
  private _pauseAnimation = false;
  private _showAnimation = false;

  get currentSensorDataOnAnimation$(): Observable<SensorDataSpeedAndDate> {
    return this._currentSensorDataOnAnimation$;
  }

  get showAnimation(): boolean {
    return this._showAnimation;
  }

  get pauseAnimation(): boolean {
    return this._pauseAnimation;
  }

  public animateTrip(map: OlMap, sensorDataStorage: SensorDataStorage, speedAnimation: number, isTogglePause = false): void {
    this.map = map;
    isTogglePause ? this._pauseAnimation = !this._pauseAnimation : this._pauseAnimation = false;
    this._showAnimation = true;
    if (this._animateIntervalId) {
      clearInterval(this._animateIntervalId);
    }
    if (this._pauseAnimation) {
      return;
    }
    if (this.animationLayer) {
      LayerUtil.clear(this.map, LayerName.ANIMATION);
    }

    const speedSensorId = sensorDataStorage.getSensorIdByName(SensorName.SPEED);
    const sensorDataList: SensorData[] = sensorDataStorage.sensorDataList;

    let index = this._currentSensorDataOnAnimationSource.getValue().index;
    const animationMarker = OlFeature.createAnimationMarker(OlCoordinate.createFromSensorData(sensorDataList[index]));
    this.animationLayer = OlVectorLayer.createVectorLayerWithFeatures(animationMarker, LayerName.ANIMATION);
    this.animationLayer.setZIndex(ZIndexLayer.ANIMATION_MARKER);
    this.map.addLayer(this.animationLayer);

    const animation = () => {
      if (index >= sensorDataList.length) {
        clearInterval(this._animateIntervalId);
        this._pauseAnimation = true;
        index = 0;
        this._currentSensorDataOnAnimationSource.next(
          {
            speed: 0,
            date: this._currentSensorDataOnAnimationSource.getValue().date,
            index: 0
          });
        return;
      }
      animationMarker.set('geometry', new Point(OlCoordinate.createFromSensorData(sensorDataList[index])));
      const currentUnitSpeed = sensorDataList[index].sensorRecords.get(speedSensorId);
      this._currentSensorDataOnAnimationSource.next({speed: currentUnitSpeed, date: sensorDataList[index].time, index: index});
      CenterMapUtil.center(sensorDataList[index].getGpsCoordinate(), this.map, {zoom: this.map.getView().getZoom()});
      index = index + speedAnimation;
    };

    this.map.once('postcompose', () => {
      this._animateIntervalId = setInterval(animation, this.ANIMATION_INTERVAL);
    });
  }

  public removeAnimationTrip(): void {
    this._showAnimation = false;
    clearInterval(this._animateIntervalId);
    LayerUtil.clear(this.map, LayerName.ANIMATION);
    this._currentSensorDataOnAnimationSource.next({speed: 0, date: '', index: 0});
  }
}
