import {CriterionType} from "../../../../../../../common-module/src/lib/modelinterfaces/enums/criterion-type";

export class EcoDrivingUtils {

  private static readonly ECO_DRIVING_FULL_SUPPORT_TYPES: string = JSON.stringify(
    [CriterionType.ACCELERATION, CriterionType.BRAKE, CriterionType.TURN, CriterionType.CUSTOM]
      .sort()).toLowerCase();
  private static readonly ECO_DRIVING_FULL_SUPPORT_TYPES_2: string = JSON.stringify(
    [CriterionType.ACCELERATION, CriterionType.BRAKE, CriterionType.BUMP, CriterionType.TURN, CriterionType.CUSTOM]
      .sort()).toLowerCase();


  public static isEcoDrivingFullSupported(types: CriterionType[]): boolean {
    const s: string = JSON.stringify(types.sort()).toLocaleLowerCase();
    return EcoDrivingUtils.ECO_DRIVING_FULL_SUPPORT_TYPES === s
    || EcoDrivingUtils.ECO_DRIVING_FULL_SUPPORT_TYPES_2 === s
  }
}
