import {Injectable} from '@angular/core';
import {Layer} from 'ol/layer';
import OlMap from 'ol/Map';

import {UnitShort} from '../../../../../common-module/src/lib/modelinterfaces/unit-short.model';
import {LayerName} from '../constants/enums/layer-name';
import {ZIndexLayer} from '../constants/z-index';
import {LayerUtil} from '../map-utils/layer.util';
import {OlAnimatedClusterLayer} from '../map-utils/OlAnimatedClusterLayer';
import {UnitIconBuilder} from '../map-utils/unit-icon-builder';
import {UnitStateStorage} from '../../../../../common-module/src/lib/modelinterfaces/unit-state-storage.model';
import {CenterMapUtil} from "../map-utils/center-map.util";
import {MapSettings} from "../constants/enums/map-settings";

@Injectable({
  providedIn: 'root'
})

export class UnitLayerService {

  private _unitsLayer: Layer;

  isFirstUpdate = true;

  get unitsLayer(): Layer {
    return this._unitsLayer;
  }

  /**
   * show units on map. receive number array of units id to show on map. if unitsId=null show all units.
   */
  public refresh(map: OlMap, unitsState: UnitStateStorage, units: UnitShort[] = null): void {
    if (!unitsState) {
      this._unitsLayer = null;
      return;
    }
    LayerUtil.clear(map, LayerName.UNITS);
    if (units?.length > 0) {
      const unitsLayer = this.createUnitsVectorClusterLayer(map, unitsState, units);
      this._unitsLayer = unitsLayer;
      map.addLayer(unitsLayer);
      return;
    }
    this._unitsLayer = null;
  }

  private createUnitsVectorClusterLayer(map: OlMap, unitsState: UnitStateStorage, units: UnitShort[]): Layer {
    const unitsIcons = [];
    units.forEach(unit => {
      if (unitsState.isLastCoordinateValid(unit.id)) {
        unitsIcons.push(UnitIconBuilder.build(unit, unitsState.getState(unit.id)));
      }
    });
    const layer: Layer = OlAnimatedClusterLayer.buildUnitsCluster(unitsIcons, LayerName.UNITS);
    layer.setZIndex(ZIndexLayer.CLUSTER);
    if (this.isFirstUpdate) {
      CenterMapUtil.center(unitsState.getCoordinateList(), map);
      this.isFirstUpdate = false;
    }
    return layer;
  }
}
