import {Component, Input, EventEmitter, Output} from '@angular/core';
import {AppColor} from '../../../../../../common-module/src/lib/app-enums/app-color';
import {NgForOf} from "@angular/common";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-color-palette',
  standalone: true,
  templateUrl: './color-palette.component.html',
  imports: [
    NgForOf,
    MatButton
  ],
  styleUrls: ['./color-palette.component.scss']
})

export class ColorPaletteComponent {

  @Input() currentColor: AppColor;
  @Input() collectionColors: AppColor[];
  @Output() changed = new EventEmitter<AppColor>();

  public onChangeColor(color: AppColor): void {
    this.changed.emit(color);
  }
}
