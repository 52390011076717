<div class="filter-button">

  <button [matTooltip]="(tooltip || viewValue)? (tooltip || '' + '\n' + (viewValue || '')): undefined"
          [matTooltipClass]="'big-multiline-tooltip'"
          [ngClass]="{'app-button primary overflow align-left': viewValue, 'accent-border': isOpened}"
          matTooltipShowDelay="500"
          mat-button
          [matMenuTriggerFor]="menu"
          #menuTrigger="matMenuTrigger"
          (menuClosed)="onMenuClosed()"
          (menuOpened)="onMenuOpen()">

    <div class="flex flex-justify-space-between">

      <div *ngIf="name; else filterIcon">{{ name }}</div>
      <ng-template #filterIcon>
        <mat-icon *ngIf="!viewValue">filter_alt</mat-icon>
      </ng-template>

      <ng-container *ngIf="viewValue; else expandIcon">
        <span *ngIf="name">: </span>
        <span class="view-value">{{ viewValue | truncate:viewValueTruncate }}</span>
      </ng-container>

      <ng-template #expandIcon>
        <mat-icon>expand_more</mat-icon>
      </ng-template>
    </div>

  </button>

  <button mat-button
          class="clear icon-clear-margin app-button primary"
          *ngIf="viewValue"
          (click)="$event.stopPropagation(); clear.emit()">
    <mat-icon>close</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <ng-template matMenuContent>
      <div class="mat-menu-content">

        <ng-content></ng-content>

        <div class="flex flex-justify-center" *ngIf="showApplyButton">
          <button class="apply-button app-button primary"
                  mat-button
                  [disabled]="disableApply"
                  (click)="apply.emit(); menuTrigger.closeMenu()">
            {{ 'action.apply' | translate }}
          </button>
        </div>
      </div>
    </ng-template>
  </mat-menu>
</div>
