import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from "rxjs";

import {
  ViewBreakpointService,
  ViewSize
} from '../../../../../../../../common-module/src/lib/app-services/view-breakpoint.service';
import {
  UnitAdditionalData
} from '../../../../../../../../common-module/src/lib/modelinterfaces/unit-additional-data.model';
import {
  EditUnitViewDialogComponent
} from '../../../vehicle/unit-view-tab/edit-unit-view-dialog/edit-unit-view-dialog.component';
import {SendCommandDialogComponent} from "./send-command-dialog/send-command-dialog.component";
import {
  EditCertificateDialogOpener
} from "../../../../../../../../common-module/src/lib/app-components/edit-certificate-dialog/edit-certificate-dialog.opener";
import {
  EditCertificateDialogData
} from "../../../../../../../../common-module/src/lib/app-components/edit-certificate-dialog/edit-certificate-dialog.component";
import {UnitDataService} from "../../../../../shared/services/unit-data.service";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-unit-info-card',
  templateUrl: './unit-info-card.component.html',
  styleUrls: ['../carousel-card-styles.component.scss']
})

export class UnitInfoCardComponent {

  @Input() width: number;
  @Input() unitData: UnitAdditionalData;

  public size$: Observable<ViewSize> = this.viewBreakpointService.size$;

  constructor(private dialog: MatDialog,
              private viewBreakpointService: ViewBreakpointService,
              private unitDataService: UnitDataService,
              private editCertificateOpener: EditCertificateDialogOpener,
              private cdr: ChangeDetectorRef) {
  }

  public onEdit(): void {
    this.dialog.open(EditUnitViewDialogComponent, {
      width: '350px',
      autoFocus: false,
      data: {
        unit: this.unitData.unit
      }
    });
  }

  public onSendCommand(): void {
    this.dialog.open(SendCommandDialogComponent, {
      autoFocus: false,
      height: '650px',
      data: {
        unit: this.unitData.unit
      }
    });
  }

  onEditCertificate() {
    const data = {
      unit: this.unitData.unit,
      deleteDisable: true
    } as EditCertificateDialogData;
    this.editCertificateOpener.open({data}).pipe(take(1))
      .subscribe(result => {
      this.unitDataService.changeUnit(result.data)
    });
  }
}
