import {NoticeUtil} from '../utils/notice-util';

export class NotificationUnread {

  constructor(public unitId: number,
              public unreadCount: number) {
  }

  public static valueOf(i: NotificationUnread): NotificationUnread {
    return i ? new NotificationUnread(i.unitId, i.unreadCount) : null;
  }

  public getUnitCount(): string {
    return NoticeUtil.transformCount(this.unreadCount);
  }

  public getUnitCountNumb(): number {
    return this.unreadCount;
  }
}
