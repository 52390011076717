export enum UnitsFilter {
  NONE = 'all',
  ONLINE = 'online',
  OFFLINE = 'offline',
  MECHANISM = 'with-mechanisms',
  FFS = 'with-ffs',
  FLS = 'with-fls',
  VIDEO = 'video',
  ECO_DRIVING_CRITERIA = "with-eco-driving-criteria"
}

export enum UnitsOrder {
  NEW_DESC = 'NEW_DESC',
  NEW_ASC = 'NEW_ASC',
  BY_NAME_ASC = 'BY_NAME_ASC',
  BY_NAME_DESC = 'BY_NAME_DESC',
  BY_SECONDS_AGO_ASC = 'BY_SECONDS_AGO_ASC',
  BY_SECONDS_AGO_DESC = 'BY_SECONDS_AGO_DESC',
  BY_DRIVER_NAME_ASC = 'BY_DRIVER_NAME_ASC',
  BY_DRIVER_NAME_DESC = 'BY_DRIVER_NAME_DESC',
  BY_GROUPS = 'BY_GROUPS'
}
