import {BaseMap, BaseMapConfig} from "../../../../../../shared/aur-map-creator/map/wrapper/base.map";
import Map from "ol/Map";
import {GeoJSON} from "geojson";

export class MileageClassifierScannedMap extends BaseMap {

  constructor(map: Map, config: BaseMapConfig) {
    super(map, config);
  }

}
