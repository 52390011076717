import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';

import { AppCommonModule } from '../../../../../../common-module/src/lib/app-common.module';
import { SharedModule } from '../../../shared/shared.module';
import { EngineHoursFormComponent } from './engine-hours-tab/engine-hours-form.component';
import { EditUnitViewDialogComponent } from './unit-view-tab/edit-unit-view-dialog/edit-unit-view-dialog.component';
import { NormConsumptionFormsComponent } from "./norm-consumption-tab/norm-consumption-forms.component";
import { OdometerFormComponent } from "./odometer-tab/odometer-form.component";
import { EditVehicleDialogComponent } from './vehicle-info-tab/edit-vehicle-dialog/edit-vehicle-dialog.component';
import { VehicleFormComponent } from "./vehicle-info-tab/vehicle-form.component";
import { UnitViewFormComponent } from './unit-view-tab/unit-view-form.component';
import { TripFormComponent } from './trip-tab/trip-form.component';
import { EcoDrivingTabComponent } from './eco-driving-tab/eco-driving-tab.component';
import { DeleteCriterionDialogComponent } from './eco-driving-tab/delete-criterion-dialog/delete-criterion-dialog.component';
import {
  DialogActionsCancelSaveComponent
} from "../../../../../../common-module/src/lib/dialog/dialog-save/dialog-actions-cancel-save/dialog-actions-cancel-save.component";
import {
  DialogDeleteComponent
} from "../../../../../../common-module/src/lib/dialog/dialog-delete/dialog-delete.component";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {TranslateModule} from "@ngx-translate/core";
import {
  MileageClassifierScannedMapComponent
} from "./norm-consumption-tab/mileage-classifier-scanned-dialog/mileage-classifier-scanned-map/mileage-classifier-scanned-map.component";
import {MatDialogContent} from "@angular/material/dialog";
import {MatDividerModule} from "@angular/material/divider";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatIconModule} from "@angular/material/icon";
import {ExpireIconComponent} from "../../../shared/components/expire-icon/expire-icon.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSelectModule} from "@angular/material/select";
import {MatButtonModule} from "@angular/material/button";
import {MatTableModule} from "@angular/material/table";
import {SensorValuePipe} from "../../../../../../common-module/src/lib/pipes/sensor-value.pipe";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {
  ColorPickerInputComponent
} from "../../../shared/components/inputs/color-picker-input/color-picker-input.component";
import {MatSliderModule} from "@angular/material/slider";
import {
  HelperInfoComponent
} from "../../../../../../common-module/src/lib/app-components/helper-info/helper-info.component";
import {AppDialogModule} from "../../../../../../common-module/src/lib/dialog/dialog/app-dialog.module";
import {
  MileageClassifierScannedDialogComponent
} from "./norm-consumption-tab/mileage-classifier-scanned-dialog/mileage-classifier-scanned-dialog.component";
import {
  MileageClassifierScannedDialogModule
} from "./norm-consumption-tab/mileage-classifier-scanned-dialog/mileage-classifier-scanned-dialog.module";

@NgModule({
  declarations: [
    EditVehicleDialogComponent,
    EditUnitViewDialogComponent,
    VehicleFormComponent,
    NormConsumptionFormsComponent,
    OdometerFormComponent,
    UnitViewFormComponent,
    TripFormComponent,
    EcoDrivingTabComponent,
    EngineHoursFormComponent,
    DeleteCriterionDialogComponent,
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    SharedModule,
    SensorValuePipe,
    AppDialogModule,
    DialogActionsCancelSaveComponent,
    DialogDeleteComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    TranslateModule,
    HelperInfoComponent,
    MatDividerModule,
    MatDatepickerModule,
    MatInputModule,
    MatTooltipModule,
    MatIconModule,
    ExpireIconComponent,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatDialogContent,
    MatButtonModule,
    MatTableModule,
    MatCheckboxModule,
    ColorPickerInputComponent,
    MatSliderModule,
    MileageClassifierScannedDialogModule
  ],
  providers: [{provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher}],
  bootstrap: [],
  exports: [
    EditVehicleDialogComponent,
    EditUnitViewDialogComponent,
    VehicleFormComponent,
    NormConsumptionFormsComponent,
    OdometerFormComponent,
    UnitViewFormComponent,
    TripFormComponent,
    EcoDrivingTabComponent,
    EngineHoursFormComponent
  ]
})

export class EditVehicleModule {
}
