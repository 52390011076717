import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MapDialogComponent} from './map-dialog.component';
import { AppCommonModule } from 'projects/common-module/src/lib/app-common.module';
import {MapToolsModule} from '../map/map-tools.module';
import {SharedModule} from '../../../shared/shared.module';

@NgModule({
  declarations: [
    MapDialogComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    MapToolsModule,
    SharedModule
  ],
  providers: [],
  bootstrap: [],
  exports: []
})

export class MapDialogModule {
}
