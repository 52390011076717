<cmn-common-filter [name]="name"
                   [tooltip]="tooltip"
                   [showApplyButton]="false"
                   [viewValue]="viewValue"
                   (clear)="onClear()">

  <mat-radio-group class="radio-buttons" [ngModel]="selectedFilter">

    <mat-radio-button *ngFor="let item of itemList"
                      class="radio-button"
                      [value]="item"
                      (click)="applyChanges(item)">
      {{item.translate}}
    </mat-radio-button>

  </mat-radio-group>

</cmn-common-filter>

