import Feature, {FeatureLike} from "ol/Feature";
import {Track} from "../../../../../../../../common-module/src/lib/modelinterfaces/track.model";
import Style from "ol/style/Style";
import {Stroke} from "ol/style";
import {AppColor} from "../../../../../../../../common-module/src/lib/app-enums/app-color";
import {TimestampUtil} from "../../../../../../../../common-module/src/lib/pipes/timestamp-timezone-convert.pipe";
import {Geometry} from "ol/geom";

export interface TrackFeatureOpts {
  color?: AppColor,
  width?: number,
}

export class TrackFeatureWrapper {
  private static readonly TRACK_VALUE_OPT_NAME = 'track';

  private static readonly DEFAULT_COLOR = AppColor.PRIMARY;
  private static readonly DEFAULT_WIDTH = 5;

  private readonly _feature: Feature;
  private readonly _styleOpts: Required<TrackFeatureOpts>;

  public get feature(): Feature {
    return this._feature;
  }

  constructor(public readonly track: Track, opts: TrackFeatureOpts) {
    this.validateTrack(track);

    const { color = TrackFeatureWrapper.DEFAULT_COLOR, width = TrackFeatureWrapper.DEFAULT_WIDTH } = opts;
    this._styleOpts = { color, width };

    const geometry = track.trips[0].getLineGeometry();
    this._feature = this.createFeature(geometry);
  }

  private createFeature(geometry: Geometry): Feature {
    const lineFeature = new Feature({ geometry });

    const originalStyle = this.originalStyle();
    lineFeature.setStyle(originalStyle);
    lineFeature.setProperties({
      originalStyle,
      hoverStyle: this.hoverStyle(originalStyle),
      description: this.generatePopupContent(),
    });

    lineFeature.set(TrackFeatureWrapper.TRACK_VALUE_OPT_NAME, this.track);

    return lineFeature;
  }

  private validateTrack(track: Track): void {
    if (track.trips.length !== 1) {
      throw Error(`Track has ${track.trips.length} trips, but expected 1`);
    }
  }

  private originalStyle(): Style {
    const stroke = new Stroke({
      color: this._styleOpts.color,
      width: this._styleOpts.width,
    });

    return new Style({
      stroke: stroke,
    });
  }

  private hoverStyle(originalStyle: Style | Style[]): Style[] {
    const shadowStyle = new Style({
      stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0.3)',
        width: this._styleOpts.width + 8,
      }),
      zIndex: -1,
    });

    return Array.isArray(originalStyle)
      ? [shadowStyle, ...originalStyle]
      : [shadowStyle, originalStyle];
  }

  private generatePopupContent(): string {
    return `
      <p><b>${this.track.buildOptions.unit.name}</b></p>
      <p>${this.formatTime(this.track.buildOptions.startTime)} - ${this.formatTime(this.track.buildOptions.finishTime)}</p>
      <p>${this.track.mileage.corrected.toFixed(2)} км</p>
    `;
  }

  private formatTime(time: string): string {
    return TimestampUtil.transform(time, 'browser', 'DD.MM HH:mm:ss');
  }


  public static getValue(feature: FeatureLike): Track | undefined {
    return feature.get(TrackFeatureWrapper.TRACK_VALUE_OPT_NAME);
  }
}
