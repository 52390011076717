import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable, Subject, timer} from 'rxjs';
import {catchError, map, take, takeUntil} from 'rxjs/operators';

import {NotificationCount} from '../../../../../common-module/src/lib/modelinterfaces/notification-count.model';
import {NotificationService} from '../../../../../common-module/src/lib/services/notification.service';
import {Notification} from '../../../../../common-module/src/lib/modelinterfaces/notification.model';
import {NotificationFilters} from '../../../../../common-module/src/lib/modelinterfaces/notification-filters.model';
import {NotificationWithNew} from '../../../../../common-module/src/lib/modelinterfaces/notification-with-new.model';
import {NotificationDialogComponent} from '../../system/main/notification/notification-dialog/notification-dialog.component';
import {GlobalStyleClass} from '../../../../../common-module/src/lib/app-enums/global-style-class.enum';
import {AppMessageService} from '../../../../../common-module/src/lib/app-services/app-message.service';
import {MapToolsService} from './map-tools.service';

@Injectable({
  providedIn: 'root'
})

export class NotificationManagerService {

  public readonly UNIT_ID_PARAM = 'unitId';
  public readonly NOTIFICATION_ID_PARAM = 'notificationId';

  private readonly PERIOD_TIMER = 121000;

  public isReceiveLastNotifications = false;
  public notificationsOnPage: Notification[] = [];

  private _noticeCountSource = new BehaviorSubject<NotificationCount>(null);
  private _noticeCount$ = this._noticeCountSource.asObservable();

  private _lastNotificationsSource = new BehaviorSubject<NotificationWithNew[]>(null);
  private _lastNotifications$ = this._lastNotificationsSource.asObservable();

  private _readNotificationIdSource = new BehaviorSubject<number>(null);
  private _readNotificationId$ = this._readNotificationIdSource.asObservable();

  private _loadingNoticesSource = new BehaviorSubject<boolean>(false);
  private _loadingNotices$ = this._loadingNoticesSource.asObservable();

  private _readNotificationIdListSource = new BehaviorSubject<number[]>([]);
  private _readNotificationIdList$ = this._readNotificationIdListSource.asObservable();

  private filterParams: NotificationFilters = new NotificationFilters();

  private subscriptions = new Subject<boolean>();

  constructor(private activatedRoute: ActivatedRoute,
              private appMessageService: AppMessageService,
              private dialog: MatDialog,
              private mapToolsService: MapToolsService,
              private notificationService: NotificationService,
              private router: Router,
              private translateService: TranslateService) {
  }

  get noticeCount$(): Observable<NotificationCount> {
    return this._noticeCount$;
  }

  get lastNotifications$(): Observable<NotificationWithNew[]> {
    return this._lastNotifications$;
  }

  get loadingNotices$(): Observable<boolean> {
    return this._loadingNotices$;
  }

  get readNotificationId$(): Observable<number> {
    return this._readNotificationId$;
  }

  get readNotificationIdList$(): Observable<number[]> {
    return this._readNotificationIdList$;
  }

  public init(): void {
    this.filterParams.status.paramValue = 'eq#false';
    this.filterParams.status.value = 'false';
    timer(0, this.PERIOD_TIMER).pipe(takeUntil(this.subscriptions)).subscribe(() => {
      this.getCounts();
      this.getLastNotifications();
    });
    this.activateNotificationDialog();
  }

  public getCounts(): void {
    this.notificationService.getCounts().subscribe(
      data => {
        this._noticeCountSource.next(data);
      },
      error => {
        throw error;
      }
    );
  }

  public getLastNotifications(): void {
    if (!this.isReceiveLastNotifications) {
      return;
    }
    this._loadingNoticesSource.next(true);
    this.notificationService.getPage(null, this.translateService.currentLang, 0, 5,
      `-id`, this.filterParams)
      .pipe(
        take(1),
        map(page => page.content)
      )
      .subscribe(
        content => {
          this._lastNotificationsSource.next(this.createNotificationWithNew(content));
          this._loadingNoticesSource.next(false);
        },
        error => {
          this._loadingNoticesSource.next(false);
          throw error;
        }
      );
  }

  private createNotificationWithNew(notifications: Notification[]): NotificationWithNew[] {
    const notificationsWithNew = [];
    notifications.forEach(el => {
      if (!(this._lastNotificationsSource.getValue()?.length > 0) ||
        this._lastNotificationsSource.getValue()?.some(notice => notice.notification.id >= el.id)) {
        notificationsWithNew.push(new NotificationWithNew(el, false));
      } else {
        notificationsWithNew.push(new NotificationWithNew(el, true));
      }
    });
    return notificationsWithNew;
  }

  private activateNotificationDialog(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.subscriptions))
      .subscribe(params => {
        if (params[this.UNIT_ID_PARAM] && params[this.NOTIFICATION_ID_PARAM]) {
          this.getCurrentNotificationById(params[this.NOTIFICATION_ID_PARAM]);
        }
      });
  }

  private getCurrentNotificationById(notificationId: number): void {
    const currentNotification = this.notificationsOnPage.find(notification => notification?.id === notificationId);
    if (currentNotification) {
      this.openNotificationDialog(currentNotification);
    } else {
      this.notificationService.getById(notificationId, this.translateService.currentLang)
        .subscribe(notification => {
            if (notification) {
              this.openNotificationDialog(notification);
            }
          });
    }
  }

  private openNotificationDialog(notification: Notification): void {
    this.mapToolsService.saveZoom(); // for show Map in Tab.MAP with current zoom
    const dialogRef = this.dialog.open(NotificationDialogComponent, {
      panelClass: ['notification-dialog', GlobalStyleClass.DIALOG_SIZE_LIMIT],
      autoFocus: false,
      data: notification
    });
    dialogRef.afterClosed().subscribe(() => {
      this.readNotification([notification.id]);
      this.mapToolsService.restoreMapZoom();
      this.router.navigate([], {relativeTo: this.activatedRoute}).then();
    });
  }

  public openNotificationDetails(notificationId: number, lastSelectedUnitId: number = 0): void {
    const queryParams: Params = {
      [this.UNIT_ID_PARAM]: lastSelectedUnitId,
      [this.NOTIFICATION_ID_PARAM]: notificationId
    };
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }).then();
  }

  public readNotification(notificationIds: number[], $event: Event = null): void {
    if ($event) {
      $event.stopPropagation();
    }
    this._readNotificationIdSource.next(notificationIds[0]);

    this.notificationService.setNotificationRead(notificationIds)
      .pipe(
        catchError((error) => {
          this.appMessageService.openSnackBar('message.error.error-while-saving', true);
          this._readNotificationIdSource.next(null);
          this._readNotificationIdListSource.next(null);
          throw new Error(error);
        }),
      )
      .subscribe((list) => {
        this._readNotificationIdSource.next(null);
        if (list) {
          this.getLastNotifications();
          this.getCounts();
        }
        this._readNotificationIdListSource.next(list);
      });
  }

  public destroy(): void {
    this.subscriptions.next(null);
    this.subscriptions.complete();
    this.notificationsOnPage = [];
    this._noticeCountSource.next(null);
    this._lastNotificationsSource.next(null);
    this._readNotificationIdSource.next(null);
    this._loadingNoticesSource.next(false);
    this._readNotificationIdListSource.next([]);
    this.filterParams = new NotificationFilters();
  }
}
