import {Injectable} from "@angular/core";
import {EditCertificateDialogComponent, EditCertificateDialogData} from "./edit-certificate-dialog.component";
import {Unit} from "../../modelinterfaces/unit.model";
import {
  DialogOpener
} from "../../../../../user-portal/src/app/shared/components/dialogs/base-dialog/base-dialog.opener";
import {MatDialog} from "@angular/material/dialog";

@Injectable({providedIn: 'root'})
export class EditCertificateDialogOpener extends DialogOpener.Outputable<EditCertificateDialogData, Unit> {
  constructor(matDialog: MatDialog) {
    super(matDialog, EditCertificateDialogComponent)
  }
}
