<div class="menu-options">
  <mat-selection-list #list (selectionChange)="onChangeType(list.selectedOptions.selected)">
    <mat-list-option
      *ngFor="let type of [reportType.DAYS, reportType.STATISTIC]"
      [checkboxPosition]="'before'"
      [value]="type"
      [selected]="isSelected(type)"
      [disabled]="!isAvailable(type)"
      (click)="$event.stopPropagation()"
      class="list-option"
    >
      <span *ngIf="type === reportType.DAYS">{{ 'action.group-by-days' | translate }}</span>
      <span *ngIf="type === reportType.STATISTIC">{{ 'term.statistic' | translate }}</span>
    </mat-list-option>
  </mat-selection-list>

  <mat-slide-toggle class="slide-toggle"
                    (click)="$event.stopPropagation()"
                    (change)="onToggleUniteTables($event)"
                    [checked]="lastSelectedOptions.uniteTables">
    <div class="text-like-mdc-list margin-left-16">
      {{ 'action.unite-tables' | translate }}
    </div>
  </mat-slide-toggle>

  <mat-slide-toggle *ngIf="hasGeofences"
                    class="slide-toggle"
                    (click)="$event.stopPropagation()"
                    (change)="onToggleSelectGeofences($event)"
                    [checked]="isSelectedGeofences">
    <div class="text-like-mdc-list margin-left-16">
      {{ 'action.select-geofences' | translate }}
    </div>
  </mat-slide-toggle>

  <app-select-geofences *ngIf="hasGeofences && isSelectedGeofences"
                        (click)="$event.stopPropagation()"></app-select-geofences>

  <mat-divider class="width-100-percent"></mat-divider>

  <div class="text-like-mdc-list flex flex-v-center flex-gap8 pointer width-100-percent">

    <button mat-button class="width-100-percent margin-top-8" (click)="openReportConstructorDialog()">
      <div class="flex flex-v-center flex-gap8 text-like-mdc-list flex-justify-start">
        <mat-icon class="text-primary-color">handyman</mat-icon>
        Конструктор
      </div>
    </button>

  </div>
</div>
