import {Directive, HostBinding, HostListener} from '@angular/core';
import {ViewBreakpointService} from '../../../../../common-module/src/lib/app-services/view-breakpoint.service';

@Directive({
  selector: '[appHover]'
})
export class HoverDirective {

  constructor(private appViewBreakpointService: ViewBreakpointService) {
  }

  @HostBinding('class.hovered') isHovered = false;

  @HostListener('mouseenter') onMouseEnter(): void {
    if (this.appViewBreakpointService.currentSize.more('m')) {
      this.isHovered = true;
    }
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    this.isHovered = false;
  }
}
