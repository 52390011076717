import {Dayjs} from "dayjs";

export class Disable29February {

  public static filter = (date: Dayjs | null): boolean => {
    if (!date) {
      return false;
    }
    return !(date.date() === 29 && date.month() === 1);
  }
}
