import { UnitShort } from './unit-short.model';
import { UserShort } from './user-short.model';

export class PublicAccess {

  constructor(public id: number,
              public expirationDate: string,
              public user: UserShort,
              public units: UnitShort[],
              public url: string) {
  }

  public static valueOf(i: PublicAccess): PublicAccess {
    return i ? new PublicAccess(i.id, i.expirationDate, UserShort.valueOf(i.user), i.units, i.url) : null;
  }

  public static valuesOf(list: PublicAccess[]): PublicAccess[] {
    return list ? list.map(o => PublicAccess.valueOf(o)) : [];
  }
}
