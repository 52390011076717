import {Feature} from "ol";
import {Geometry} from "ol/geom";
import {StyleFactory, StyleOpts} from "../style.factory";

/**
 * The `AbstractFeatureWrapper` class provides a base class for feature wrappers.
 * It encapsulates an OpenLayers `Feature` object, providing utility methods
 * for setting and getting properties. This class is designed to be extended
 * by more specific feature types.
 *
 * @template T The geometry type that extends `Geometry`, indicating the type of geometry
 *             the feature will contain.
 */
export abstract class AbstractFeatureWrapper<T extends Geometry, OPTS extends StyleOpts> {

  private readonly _optSource: OPTS;

  constructor(public feature: Feature<T>,
              private _styleFactory: StyleFactory<OPTS>,
              sourceOpts: OPTS) {
    this._optSource = this.copy(sourceOpts)
    this.applyStyle(sourceOpts);
  }

  public getStyleFactory() {
    return this._styleFactory;
  }

  /**
   * Sets a property on the encapsulated `Feature` object.
   *
   * @param {string} key The property key.
   * @param {any} value The value to set for the given property key.
   */
  public setProperty(key: string, value: any): void {
    this.feature.set(key, value);
  }

  /**
   * Retrieves a property from the encapsulated `Feature` object.
   *
   * @param {string} key The property key whose value is to be retrieved.
   * @return {any} The value of the specified property.
   */
  public getProperty(key: string): any {
    return this.feature.get(key);
  }

  public getSourceOpts(): OPTS {
    return this.copy(this._optSource);
  }

  private copy(opts: OPTS): OPTS {
    return JSON.parse(JSON.stringify(opts));
  }

  public applyStyle(opts: OPTS): void {
    const styles = this.getStyleFactory().create(opts).filter(s => s)
    this.feature.setStyle(styles);
    this.feature.changed();
  }

  public resetStyle(): void {
    this.applyStyle(this.getSourceOpts());
  }
}

