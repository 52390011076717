import OlMap from "ol/Map";
import {
  RangeValue,
  SensorRanges
} from "../../system/main/toolbar/track-options/track-sensor-options-dialog/track-sensor-options-dialog.component";
import {LayerUtil} from "../map-utils/layer.util";
import {LayerName} from "../constants/enums/layer-name";
import {Track} from "../../../../../common-module/src/lib/modelinterfaces/track.model";
import VectorLayer from "ol/layer/Vector";
import Feature from "ol/Feature";
import {SensorData} from "../../../../../common-module/src/lib/modelinterfaces/sensor-data.model";
import {OlFeature} from "../map-utils/OlFeature";
import {OlVectorLayer} from "../map-utils/OlVectorLayer";
import {Injectable} from "@angular/core";
import {Sensor} from "../../../../../common-module/src/lib/modelinterfaces/sensor.model";

interface Range {
  min: number;
  max: number;
}

class SpecificSensorRanges {
  sensor: Sensor;
  ranges: RangeValue[];
}

class RangeTreeMap {
  constructor(private readonly ranges: RangeValue[] = []) {
    this.ranges.sort((a, b) => a.min - b.min);
  }

  // Поиск диапазона, в который входит значение
  getColor(value: number): string | null {
    let left = 0;
    let right = this.ranges.length - 1;

    // Бинарный поиск по диапазонам
    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      const currentRange = this.ranges[mid];

      if (value >= currentRange.min && value < currentRange.max) {
        return this.ranges[mid].color; // Возвращаем цвет, если значение в диапазоне
      } else if (value < currentRange.min) {
        right = mid - 1;
      } else {
        left = mid + 1;
      }
    }

    return null; // Значение не входит ни в один диапазон
  }
}

@Injectable({
  providedIn: 'root'
})
export class TrackLayerSensorRangesService {

  layerName = new Map<number, LayerName>([
      [0, LayerName.SENSOR_RANGES_1],
      [1, LayerName.SENSOR_RANGES_2],
    ]
  )

  public showSensorRanges(map: OlMap, track: Track, sensorRanges: SensorRanges[]) {
    if (sensorRanges.length === 0) {
      LayerUtil.clear(map, LayerName.SENSOR_RANGES_1, LayerName.SENSOR_RANGES_2,);
    }
    this.createLayer(track, sensorRanges).forEach(layer => map.addLayer(layer))
  }

  public createLayer(track: Track, sensorRanges: SensorRanges[]): VectorLayer[] {
    const result = [];

    // replace sensorRange на SensorRange из Track
    const newRanges = sensorRanges.map(sensorRange => {
      let newSensor = track.storage.sensors.find(s => s.name === sensorRange.sensorName);
      return {
        sensor: newSensor,
        ranges: sensorRange.ranges
      } as SpecificSensorRanges
    }).filter(range => !!range.sensor);

    newRanges.forEach((sensorRange, index) => {
      const features = this.createFeatures(track.storage.sensorDataList, sensorRange, 4 + (index * 5));
      const layerName = this.layerName.get(index);
      if (layerName) {
        const layer = OlVectorLayer.createVectorLayerWithFeatures(features, layerName);
        layer.setZIndex(1000 - index);
        result.push(layer);
      } else {
        console.log('Error not find layer name by index: ', index)
      }
    });
    return result;
  }

  private createFeatures(sensorDataset: SensorData[], sensorRanges: SpecificSensorRanges, width: number): Feature[] {
    let map = new RangeTreeMap(sensorRanges.ranges);
    const result = [];
    for (let i = 0; i < sensorDataset.length - 1; i++) {
      const line = this.createFeature(sensorRanges.sensor, map, sensorDataset[i], sensorDataset[i + 1], width)
      if (line) {
        result.push(line);
      }
    }
    return result;
  }

  private createFeature(sensor: Sensor, rangeMap: RangeTreeMap, a: SensorData, b: SensorData, width: number): Feature | null {
    const colorA = rangeMap.getColor(a.sensorRecords.get(sensor.id));
    const colorB = rangeMap.getColor(b.sensorRecords.get(sensor.id));
    if (colorA && colorB && colorA === colorB) {
      let feature = OlFeature.createLine(a.getGpsCoordinate(), b.getGpsCoordinate(), colorA, width);
      feature.set('description', `${sensor.nameTranslated}: ${a.sensorRecords.get(sensor.id).toFixed(2)}  `)
      return feature;

    }
    return null;
  }
}
