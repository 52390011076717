<div [style.width.px]="width" class="card"
     [ngClass]="{'card_le_l': (viewBreakpointService.size$ | async).less('l')}"
>
  <h4>{{ 'term.automobile' | translate }}</h4>

  <p>
    <span class="title">{{ 'unit.model' | translate }}: </span>
    <span class="text">{{ unitData.vehicle ? unitData.vehicle.model : ''}}</span>
  </p>
  <p>
    <span class="title">{{ 'unit.plate-number' | translate }}: </span>
    <span class="text">{{ unitData.vehicle ? unitData.vehicle.stateNumber : ''}}</span>
  </p>
  <p>
    <span class="title">{{ 'term.type' | translate }}: </span>
    <span class="text">
      {{ unitData?.vehicle?.type?.nameTranslated ? unitData.vehicle.type.nameTranslated : ''}}
    </span>
  </p>
  <p>
    <span class="title">{{ 'unit.VIN' | translate }}: </span>
    <span class="text">{{ unitData.vehicle ? unitData.vehicle.vin : ''}}</span>
  </p>
  <p>
    <span class="title">{{ 'unit.chassis-number' | translate }}: </span>
    <span class="text">{{ unitData.vehicle ? unitData.vehicle.chassisNumber : ''}}</span>
  </p>
</div>
