import {GpsCoordinateUtil} from "../utils/gps-coordinate.util";

export class GpsCoordinate {

  constructor(public latitude: number,
              public longitude: number) {
  }

  public toString(precission: number = 6, separator: string = " "): string {
    return GpsCoordinateUtil.toString(this, precission, separator);
  }


  /**
   * reverse operation of toString
   * parse GpsCoordinate from latitude longitude string
   * @param c latitude longitude
   * @param separator
   * @return GpsCoordinate or null if coordinate was not found
   */
  public static parse(c: string | GpsCoordinate, separator = /\s*,\s*|\s+/): GpsCoordinate {
    if (!c) {
      return null
    }
    if (c instanceof GpsCoordinate) {
      return c;
    }
    const tokens = c.trim().replace(separator, " ").split(" ");
    if (tokens.length >= 2) {
      return new GpsCoordinate(Number(tokens[0]), Number(tokens[1]));
    }
    return null;
  }

  public static valueOf(o: GpsCoordinate): GpsCoordinate {
    return o ? new GpsCoordinate(o.latitude, o.longitude) : null;
  }

  public static valuesOf(list: GpsCoordinate[]): GpsCoordinate[] {
    return list ? list.map(o => GpsCoordinate.valueOf(o)) : [];
  }

  public static fromStrings(latitude: string, longitude: string): GpsCoordinate {
    return new GpsCoordinate(Number(latitude), Number(longitude));
  }

  public equals(other: GpsCoordinate): boolean {
    return this.latitude === other.latitude && this.longitude === other.longitude;
  }
}
