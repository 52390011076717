import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppCommonModule} from '../../../../../common-module/src/lib/app-common.module';
import {SideNavComponent} from './side-nav.component';
import {UserExpandableComponent} from './user-expandable/user-expandable.component';
import {UnitListComponent} from './unit-list/unit-list.component';
import {FilterUsersDialogComponent} from './filter-users-dialog/filter-users-dialog.component';
import {EditGroupDialogComponent} from './edit-group-dialog/edit-group-dialog.component';
import {PublicLinkDialogComponent} from './public-link-dialog/public-link-dialog.component';
import {SharedModule} from '../../shared/shared.module';
import {NotificationTableComponent} from './notification-table/notification-table.component';
import {UnitStatusIconComponent} from './unit-list/unit-status-icon/unit-status-icon.component';
import {ShortUnitStateInfoComponent} from './unit-list/short-unit-state-info/short-unit-state-info.component';
import {UnitListOptionComponent} from './unit-list/unit-list-option/unit-list-option.component';
import {DriverWithAvatarComponent} from "../../shared/components/driver-with-avatar/driver-with-avatar.component";
import {AppDialogModule} from "../../../../../common-module/src/lib/dialog/dialog/app-dialog.module";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {TranslateModule} from "@ngx-translate/core";
import {TimestampTimezoneConvertPipe} from "../../../../../common-module/src/lib/pipes/timestamp-timezone-convert.pipe";
import {MatListModule} from "@angular/material/list";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {TimeFromNowTextPipe} from "../../../../../common-module/src/lib/pipes/time-from-now-text.pipe";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatMenuModule} from "@angular/material/menu";
import {AppButtonModule} from "../../../../../common-module/src/lib/buttons/app-button.module";
import {
  DateRangePickerComponent
} from "../../../../../common-module/src/lib/app-components/date-range-picker/date-range-picker.component";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatTableModule} from "@angular/material/table";
import {UnitListSimpleComponent} from "../../shared/components/unit-list-simple/unit-list-simple.component";
import {
  ColorPickerInputComponent
} from "../../shared/components/inputs/color-picker-input/color-picker-input.component";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {NgxDaterangepickerMd} from "ngx-daterangepicker-material-no-utc";

@NgModule({
  declarations: [
    SideNavComponent,
    UserExpandableComponent,
    UnitListComponent,
    FilterUsersDialogComponent,
    EditGroupDialogComponent,
    PublicLinkDialogComponent,
    NotificationTableComponent,
    UnitStatusIconComponent,
    ShortUnitStateInfoComponent,
    UnitListOptionComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    SharedModule,
    DriverWithAvatarComponent,
    AppDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    MatListModule,
    FormsModule,
    MatCheckboxModule,
    TimeFromNowTextPipe,
    TimestampTimezoneConvertPipe,
    MatFormFieldModule,
    MatMenuModule,
    AppButtonModule,
    DateRangePickerComponent,
    MatProgressSpinner,
    MatTableModule,
    UnitListSimpleComponent,
    ColorPickerInputComponent,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    NgxDaterangepickerMd
  ],
  providers: [],
  bootstrap: [],
  exports: [
    SideNavComponent
  ]
})

export class SideNavModule {
}
