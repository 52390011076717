import {reduce} from "rxjs/operators";

export class MileageNormConsumption {

  constructor(public id: number,
              public unitId: number,
              public winterStartDate: string,
              public winterEndDate: string,
              public summerConsumptionMileageUrban: number,
              public summerConsumptionMileageCountry: number,
              public summerConsumptionIdle: number,
              public winterConsumptionMileageUrban: number,
              public winterConsumptionMileageCountry: number,
              public winterConsumptionIdle: number) {
  }

  public getSummerConsumptionMileageUrbanLiters(scale: number = 1) {
    return this.mlToLiters(this.summerConsumptionMileageUrban, scale);
  }

  public getSummerConsumptionMileageCountryLiters(scale: number = 1) {
    return this.mlToLiters(this.summerConsumptionMileageCountry, scale);
  }

  public getWinterConsumptionMileageUrbanLiters(scale: number = 1) {
    return this.mlToLiters(this.winterConsumptionMileageUrban, scale);
  }

  public getWinterConsumptionMileageCountryLiters(scale: number = 1) {
    return this.mlToLiters(this.winterConsumptionMileageCountry, scale);
  }

  private mlToLiters(mlValue: number, scale: number = 1) {
    return (mlValue / 1000).toFixed(scale);

  }

  public static valueOf(i: MileageNormConsumption): MileageNormConsumption {
    return i ? new MileageNormConsumption(
      i.id, i.unitId, i.winterStartDate, i.winterEndDate,
      i.summerConsumptionMileageUrban, i.summerConsumptionMileageCountry, i.summerConsumptionIdle,
      i.winterConsumptionMileageUrban, i.winterConsumptionMileageCountry, i.winterConsumptionIdle
    ) : null;
  }
}
