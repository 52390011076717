import {ChangeDetectionStrategy, Component, forwardRef, Input} from '@angular/core';
import {
  AbstractControlValueAccessor
} from "../../../../system/logistic/common/components/inputs/abstract-input/abstract-control-value.accessor";
import {MatFormFieldAppearance} from "@angular/material/form-field";
import {NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {AppColor} from "../../../../../../../common-module/src/lib/app-enums/app-color";

enum Control {
  COLOR = 'COLOR'
}

@Component({
  selector: 'app-color-picker-input',
  templateUrl: './color-picker-input.component.html',
  styleUrls: ['./color-picker-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerInputComponent),
      multi: true
    }
  ]
})
export class ColorPickerInputComponent extends AbstractControlValueAccessor<string> {

  readonly controls = Control;
  collectionColors: AppColor[] = Object.values(AppColor);

  selectedColor: AppColor;

  @Input() subscriptSizing: 'dynamic' | 'fixed' = 'fixed';
  @Input() nullColor = false;
  @Input() label: string;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() previewIcon = 'label';

  constructor() {
    super(
      new UntypedFormGroup({
        [Control.COLOR]: new UntypedFormControl()
      }));
  }

  protected convertFormToValue(): string {
    return this.selectedColor;
  }

  protected convertValueToForm(value: string): { [p: string]: any } {
    return {[Control.COLOR]: value};
  }

  onChangeColor(color: AppColor) {
    this.selectedColor = color;
    this.form.controls[Control.COLOR].setValue(this.selectedColor);
  }

  clear() {
    this.onChangeColor(null);
  }
}
