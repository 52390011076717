import {Options} from "ol/style/Fill";
import {Fill} from "ol/style";

export interface OlFillOpts extends Options {
}

export class OlFillFactory {
  public static create(opts: OlFillOpts): Fill {
    return new Fill(opts)
  }
}
