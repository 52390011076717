import { Geofence } from './geofence.model';
import { PointMarker } from './point-marker.model';
import { Track } from './track.model';

export class MapDetails {

  constructor(public track: Track,
              public points: PointMarker[],
              public geofences: Geofence[]) {
  }

  public static valueOf(i: MapDetails): MapDetails {
    return i ? new MapDetails(Track.valueOf(i.track), PointMarker.valuesOf(i.points), Geofence.valuesOf(i.geofences)) : null;
  }
}
