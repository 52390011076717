import {WarehouseMapConfig, WarehousesMap} from "../warehouse-map/warehouses.map";
import {Logistic} from "../../../../../../../common-module/src/lib/modelinterfaces/logistic.model";
import VectorLayer from "ol/layer/Vector";
import {Feature} from "ol";
import {OlStyle} from "../../../map-utils/OlStyle";
import {AppColor} from "../../../../../../../common-module/src/lib/app-enums/app-color";
import {BehaviorSubject} from "rxjs";
import OlMap from "ol/Map";
import {JobMapUtils} from "../../../../system/logistic/job-map-utils";
import {CenterMapUtil} from "../../../map-utils/center-map.util";
import {FeatureLike} from "ol/Feature";
import {OlAnimation} from "../../../map-utils/OlAnimation";
import {GpsCoordinate} from "../../../../../../../common-module/src/lib/modelinterfaces/gps-coordinate.model";
import {OlFeatureProps} from "../../../map-utils/OlFeatureProps";

export interface JobsOverviewMapConfig extends WarehouseMapConfig {
  jobs: Logistic.Job[];
}

/**
 * `JobsOverviewMap` is a subclass of `WarehousesMap` that specializes in representing logistic jobs
 * on a map. It provides functionalities for highlighting selected jobs
 *
 * The map can visualize the details of individual jobs, highlight them when selected,
 * and de-highlight them when another job is chosen or the same job is re-selected.
 *
 * Subscribers can listen to job selection events through the `$clickedJob` BehaviorSubject,
 * which emits values when a job on the map is clicked.
 *
 * @see WarehousesMap for more details on the base functionalities.
 */
export class JobsOverviewMap extends WarehousesMap {
  private readonly jobsLayer: VectorLayer;

  /**
   * Represents a mapping between an order's name and its corresponding feature (LineString)
   * on the map. This map is used to quickly access and manipulate the visual representation
   * of orders on the map based on their names.
   *
   * The key is the order name and the value is the LineString feature associated with that order.
   */
  private readonly orderArrow = new Map<string, Feature>();
  private readonly selectedArrowStyle = OlStyle.createForRoute(AppColor.RED);
  private readonly usualArrowStyle = OlStyle.createForRoute(AppColor.PRIMARY);

  /**
   * A BehaviorSubject representing the job that was most recently clicked on the map.
   * Emits values when a job is clicked, allowing subscribers to react to job selection events.
   *
   * The initial value is `null`, indicating that no job has been clicked yet.
   *
   * @example
   * map.$clickedJob.subscribe(job => {
   *   if (job) {
   *     console.log('Job clicked:', job.order.name);
   *   } else {
   *     console.log('No job clicked Or clicked on before job clicked');
   *   }
   * });
   */
  public $clickedJob = new BehaviorSubject(null);
  private lastHighlightedJob = null;

  public constructor(map: OlMap, config: JobsOverviewMapConfig) {
    super(map, config);
    this.jobsLayer = this.initializeJobsLayer(config.jobs);
    this.registerJobClickEventHandler();
  }

  private initializeJobsLayer(jobs: Logistic.Job[]): VectorLayer {
    const layer = JobMapUtils.createLayer(this.map, jobs, this.orderArrow, AppColor.PRIMARY);
    CenterMapUtil.center(layer.getSource(), this.map);
    return layer;
  }

  private registerJobClickEventHandler(): void {
    this.map.on('click', this.handleJobClick.bind(this));
  }

  private handleJobClick(e: any): void {
    this.map.forEachFeatureAtPixel(e.pixel, (f: FeatureLike) => {
      const job = this.getJob(f);
      if (job) {
        let selectedJob = this.selectJob(job);
        this.$clickedJob.next(selectedJob);
        return true;
      }
      return false;
    });
  }

  /**
   * Selects and highlights a given job on the map. If the provided job is already
   * highlighted or if no job is provided, it de-highlights the current highlighted job.
   *
   * @param job - The Logistic.Job instance to be selected and highlighted on the map.
   * @returns The last highlighted job after performing the selection operation.
   */
  public selectJob(job: Logistic.Job): Logistic.Job {
    if (!job || this.lastHighlightedJob === job) {
      this.doDehighlight();
    } else {
      if (this.lastHighlightedJob) {
        this.doDehighlight();
      }
      this.doHighlight(job);
    }
    return this.lastHighlightedJob;
  }

  private doDehighlight() {
    if (!this.lastHighlightedJob) return;
    let arrow = this.orderArrow.get(this.lastHighlightedJob.order.name);
    arrow.setStyle(this.usualArrowStyle);
    this.lastHighlightedJob = null;
  }

  private doHighlight(job: Logistic.Job) {
    this.setMapCenterAndFlash(job);
    this.setStyleForOrder(job, this.selectedArrowStyle);
    this.lastHighlightedJob = job;
  }

  private setMapCenterAndFlash(job: Logistic.Job) {
    const coordinates = this.getJobCoordinates(job);
    CenterMapUtil.center(coordinates, this.map);
    OlAnimation.flashWithoutCentering(this.map, coordinates, this.jobsLayer);
  }

  private setStyleForOrder(job: Logistic.Job, style: any) {
    const arrow = this.orderArrow.get(job.order.name);
    arrow.setStyle(style);
  }

  private getJobCoordinates(job: Logistic.Job): GpsCoordinate[] {
    const coordinates = [];
    if (job.isShipment()) {
      coordinates.push(job.toShipment().pickup.getLocation().coordinate);
    }
    coordinates.push(job.delivery.getLocation().coordinate);
    return coordinates;
  }

  private getJob(f: FeatureLike): Logistic.Job {
    const value = OlFeatureProps.get(f);
    return value instanceof Logistic.Job ? value : null;
  }
}
