import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ChronoUnit, LocalDate} from "@js-joda/core";
import {MatTooltip} from "@angular/material/tooltip";
import {MomentPipe} from "../../pipes/dateFormat.pipe";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-certificate-expire',
  standalone: true,
  imports: [
    MatIcon,
    MomentPipe,
    MatTooltip,
  ],
  templateUrl: './certificate-expire.component.html',
  styleUrl: './certificate-expire.component.scss'
})
export class CertificateExpireComponent implements OnInit, OnChanges {

  @Input() certificateExpire: LocalDate = null;
  @Input() shortView = false;
  private validDays = 0;
  expireMessage = 'нет данных';
  expireType: 'ok' | 'expiry-soon' | 'expired' = 'ok';

  ngOnInit() {
    this.prepareData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['certificateExpire']) {
      this.prepareData();
    }
  }

  private prepareData(): void {

    if (this.certificateExpire) {
      const today = LocalDate.now();
      this.validDays = today.until(this.certificateExpire, ChronoUnit.DAYS);
    } else {
      this.validDays = 0;
    }

    const dayLabel = this.getDaysLabel(this.validDays)
    if (this.validDays > 30) {
      this.expireType = 'ok';
      this.expireMessage = `Сертификат действителен еще ${this.validDays} ${dayLabel}.`
    } else if (this.validDays >= 0 && this.validDays <= 30) {
      this.expireType = 'expiry-soon';
      this.expireMessage = `Сертификат скоро истекает осталось ${this.validDays} ${dayLabel}.`
    } else {
      this.expireType = 'expired';
      this.expireMessage = `Сертификат просрочен на ${Math.abs(this.validDays)} ${dayLabel}.`
    }
  }

  private getDaysLabel(days: number): string {
    if (days % 10 === 1 && days % 100 !== 11) return 'день';
    if ([2, 3, 4].includes(days % 10) && ![12, 13, 14].includes(days % 100)) return 'дня';
    return 'дней';
  }

}
