export abstract class HHMMSS {

  public static toString(seconds: number): string {
    const date = new Date(0);
    date.setSeconds(seconds);
    return date.toISOString().substring(11, 19);
  }

  public static toSeconds(hhmmss: string): number {
    const a = hhmmss.split(':');
    return (+a[0]) * 3600 + (+a[1]) * 60 + (+a[2]);
  }


}
