import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {
  ViewBreakpointService,
  ViewSize
} from '../../../../../../../common-module/src/lib/app-services/view-breakpoint.service';
import {ReportOptions} from '../../../../../../../common-module/src/lib/modelinterfaces/report-options.model';
import {ToolbarService} from '../../../../shared/services/toolbar.service';
import {LocalStorageKey} from '../../../../../../../common-module/src/lib/app-enums/local-storage-key';
import {ReportType} from '../../../../../../../common-module/src/lib/modelinterfaces/enums/report-type';
import {Observable} from 'rxjs';


export interface ReportBuildOptions {
  reportName: string;
  type: ReportType;
  uniteTables: boolean;
}

@Component({
  selector: 'app-report-options',
  templateUrl: './report-options.component.html',
  styleUrls: ['./report-options.component.scss']
})

export class ReportOptionsComponent implements OnInit {

  @Output() private changeReportBuildOptions = new EventEmitter<ReportBuildOptions>();

  public availableTypes: string[] = [];
  public hasGeofences = false;
  public menuOpened = false;
  public reportOptions: ReportOptions[] = [];
  public reportNames: string[] = [];
  public showSpinner = false;
  public size$: Observable<ViewSize>;

  constructor(private toolbarService: ToolbarService,
              private viewBreakpointService: ViewBreakpointService) {
  }

  ngOnInit(): void {
    this.size$ = this.viewBreakpointService.size$;
    this.toolbarService.reportOptionsSource.subscribe(list => {
      this.init(list);
      this.reportNames = this.getReportNames();
      this.updateLastSelectedReportOptions(this.getLastSelectedReportOptions());
    })
  }

  private init(list: ReportOptions[]): void {
    this.reportOptions = list;
    this.reportNames = this.getReportNames();
    this.updateLastSelectedReportOptions(this.getLastSelectedReportOptions());
  }

  private getReportNames(): string[] {
    return this.reportNames = this.reportOptions.map(el => el.nameTranslated);
  }

  private updateLastSelectedReportOptions(options: ReportBuildOptions) {
    this.availableTypes = this.getAvailableTypes(options.reportName);
    this.hasGeofences = this.getHasGeofences(options.reportName);
    if (!options.type) {
      options.type = ReportType.NONE;
    }

    // save in localStorage selected type though can unavailable for selected report
    window.localStorage.setItem(LocalStorageKey.REPORT_OPTIONS, JSON.stringify(options));

    // for build set ReportType.NONE if selected type unavailable for selected report
    if (this.availableTypes.length === 0) {
      options.type = ReportType.NONE;
    }
    this.changeReportBuildOptions.emit(options);
  }

  public getLastSelectedReportOptions(): ReportBuildOptions {
    const options: ReportBuildOptions = JSON.parse(window.localStorage.getItem(LocalStorageKey.REPORT_OPTIONS));
    if (options) {
      return options;
    } else {
      // default
      return <ReportBuildOptions>{
        reportName: this.reportOptions ? this.reportOptions[0]?.name : '',
        type: this.reportOptions ? this.reportOptions[0]?.availableGrouping[0] : '',
        uniteTables: false
      };
    }
  }

  public getReportNameByNameTranslated(name: string): string {
    return this.reportOptions.find(el => el.nameTranslated === name).name;
  }

  private getAvailableTypes(reportName: string): string[] {
    const selectedReportOptions = this.reportOptions.find(el => el.name === reportName);
    return selectedReportOptions.availableGrouping.filter(el => el !== ReportType.NONE);
  }

  private getHasGeofences(reportName: string): boolean {
    return this.reportOptions.find(el => el.name === reportName).hasGeofences;
  }

  public getNameTranslatedByReportName(name: string): string {
    return this.reportOptions ? this.reportOptions.find(el => el.name === name)?.nameTranslated : '';
  }

  public onChangeReport(reportName: string): void {
    const options = this.getLastSelectedReportOptions();
    options.reportName = this.getReportNameByNameTranslated(reportName);
    this.updateLastSelectedReportOptions(options);
  }

  public receiveChangedOptions(options: ReportBuildOptions): void {
    this.updateLastSelectedReportOptions(options);
  }
}
