export class CompositeFeatureWrapper<W> {

  constructor(private _wrappers: W[]) {
    this.setWrappers(_wrappers);
  }

  public getWrappers(): W[] {
    return this._wrappers;
  }

  public setWrappers(wrappers: W[]): void {
    this._wrappers = wrappers.filter(w => w);
  }

}
