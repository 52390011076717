<div class="select-all" *ngIf="unitIdSet.size; else noUnitMessage">
  <label for="selectAllCheckbox">
    {{ selectedUnitIds.size }} / {{ units?.length || 0 }}
    {{ 'action.select-all' | translate }}
  </label>
  <mat-checkbox id="selectAllCheckbox" [labelPosition]="'after'"
                [checked]="isSelectAll$| async"
                [indeterminate]="selectedUnitIds.size > 0 && selectedUnitIds.size <  units.length"
                (change)="helperSelection($event)">
  </mat-checkbox>
</div>

<ng-template #noUnitMessage>
  <p class="no-unit-message"> Нет объектов для отображения </p>
</ng-template>

<mat-selection-list [ngModel]="selectedUnitIdList$ | async"
                    *ngIf="(unitsState$ | async)"
                    #unitSelectionList
                    class="unit-list">

  <div>
    <mat-list-option #listOption
                     class="list-option"
                     *ngFor="let unit of units; trackBy: trackById"
                     [value]="unit.id"
                     (mouseenter)="hoveredUnit = unit"
                     (mouseleave)="hoveredUnit = null"
                     (click)="checkListOption(listOption, $event)">

      <app-unit-list-option [timeZone]="timeZone$|async"
                            [unit]="unit"
                            [unitState]="(unitsState$ | async).get(unit.id)"
                            [unreadNotificationCount]="(noticeDataCount$ | async)?.get(unit.id)?.getUnitCountNumb()"
                            [highlight]="hoveredUnit?.id === unit.id || selectedUnitIds.has(unit.id)"
                            [unitGroupView]="unitGroupViews.get(unit.id)">
      </app-unit-list-option>

    </mat-list-option>
  </div>
</mat-selection-list>
