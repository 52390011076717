
export class AuthRequest {
  constructor(
    public username: string,
    public password: string,
  ) {}

  public static valueOf(obj: AuthRequest): AuthRequest {
    return obj ? new AuthRequest(obj.username, obj.password) : null;
  }
}
