import OlMap from "ol/Map";
import VectorLayer from "ol/layer/Vector";
import {LayerUtil} from "../../../map-utils/layer.util";
import {GpsCoordinate} from "../../../../../../../common-module/src/lib/modelinterfaces/gps-coordinate.model";
import {SensorData} from "../../../../../../../common-module/src/lib/modelinterfaces/sensor-data.model";
import {BoundBoxed} from "../../../../../../../common-module/src/lib/modelinterfaces/bound-boxed.model";
import VectorSource from "ol/source/Vector";
import Feature from "ol/Feature";
import {CenterMapUtil, MapCenterOpt} from "../../../map-utils/center-map.util";

export interface BaseMapConfig {
  mapDivId?: string;
}

export abstract class BaseMap {

  public static readonly DEFAULT_MAP_DIV_ID = 'map';

  public readonly mapDivId: string;

  protected constructor(public map: OlMap, config?: BaseMapConfig) {
    this.mapDivId = BaseMap.getMapDivId(config);
  }

  public static getMapDivId(config?: BaseMapConfig) {
    return config?.mapDivId ?? BaseMap.DEFAULT_MAP_DIV_ID;
  }

  protected removeLayers(...layers: VectorLayer[]) {
    LayerUtil.remove(this.map, ...layers);
  }

  protected centerOn(obj: GpsCoordinate | GpsCoordinate[] | SensorData[] | BoundBoxed | VectorSource | VectorSource[] | VectorLayer | VectorLayer[] | Feature,
                     opt?: MapCenterOpt) {
    CenterMapUtil.center(obj, this.map, opt)
  }

  public updateSize(): void {
    this.map.updateSize();
  }
}
