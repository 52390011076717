import {AfterViewInit, Component} from '@angular/core';
import {AbstractMapComponent} from "../../../../../../shared/maps/map/abstract-map.component";
import {MileageClassifierScannedMap} from "./mileage-classifier-scanned.map";
import {MileageClassifierScannedMapFactory} from "./mileage-classifier-scanned-map.factory";
import {
  MileageClassifierService
} from "../../../../../../../../../common-module/src/lib/services/mileage-classifier.service";
import VectorSource from "ol/source/Vector";
import GeoJSON from 'ol/format/GeoJSON.js';
import VectorLayer from "ol/layer/Vector";
import {CenterMapUtil} from "../../../../../../shared/map-utils/center-map.util";

@Component({
  selector: 'app-mileage-classifier-scanned-map',
  templateUrl: './mileage-classifier-scanned-map.component.html',
  styleUrl: './mileage-classifier-scanned-map.component.scss'
})
export class MileageClassifierScannedMapComponent extends AbstractMapComponent implements AfterViewInit {

  map: MileageClassifierScannedMap;

  constructor(protected mapFactory: MileageClassifierScannedMapFactory, private classifierService: MileageClassifierService) {
    super();
  }

  ngAfterViewInit(): void {
    this.map = this.mapFactory.create({mapDivId: this.mapDivId, popupDivId: this.popupDivId});
    this.map.updateSize();

    this.classifierService.list().subscribe(geoJsonObj => {
      const vectorSource = new VectorSource({
        features: new GeoJSON().readFeatures(geoJsonObj, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857'
        })
      });

      const vectorLayer = new VectorLayer({
        source: vectorSource
      });

      this.map.map.addLayer(vectorLayer);

      CenterMapUtil.center(vectorSource, this.map.map);
    })
  }
}
