class Node<T> {
  public value: T;
  public next: Node<T> | null;

  constructor(value: T, next = null) {
    this.value = value;
    this.next = next;
  }
}

export class LimitedList<T> {

  private head: Node<T> | null;
  private count: number;

  constructor() {
    this.head = null;
    this.count = 0;
  }

  public append(value: T): void {
    const newNode = new Node(value);
    this.count++;
    if (!this.head) {
      this.head = newNode;
      return;
    }

    let current = this.head;
      while (current.next) {
        current = current.next;
      }
      current.next = newNode;

    if (this.count > 10) {
      this.deleteFirstNode();
    }
    return;
  }

  private deleteFirstNode(): void {
    const deletedHead = this.head;
    this.head = null;
    this.head = deletedHead.next;
    this.count--;
  }

  public toArray(): T[] {
    const nodes = [];
    let currentNode = this.head;

    while (currentNode) {
      nodes.push(currentNode);
      currentNode = currentNode.next;
    }

    return nodes.map(el => el.value);
  }
}
