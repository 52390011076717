<app-dialog [title]="'Настроить отчет: ' + reportSettings.nameTranslated">

  <ng-container appDialogContent>
    <div class="margin-top-8">
      <h4>Перетащите колонки, чтобы изменить их порядок или сделать их видимыми/скрытыми</h4>
    </div>
    <div class="main-container">

      <div class="drag-drop-list-container">
        <h5>Отображаемые колонки</h5>

        <div cdkDropList
             #todoList="cdkDropList"
             [cdkDropListData]="visibleColumns"
             [cdkDropListConnectedTo]="[doneList]"
             (cdkDropListDropped)="drop($event)"
             class="drag-drop-list">
          @for (item of visibleColumns; track item) {
            <div class="drag-drop-box" cdkDrag>
              {{ item.nameTranslated }}
            </div>
          }
        </div>
        @if (visibleColumns.length === 0) {
          <div>
            <p class="app-font text-red">Не может быть пустым</p>
          </div>
        }
      </div>

      <div class="drag-drop-list-container">
        <h5>Скрытые колонки</h5>

        <div cdkDropList
             #doneList="cdkDropList"
             [cdkDropListData]="unVisibleColumns"
             [cdkDropListConnectedTo]="[todoList]"
             (cdkDropListDropped)="drop($event)"
             class="drag-drop-list">
          @for (item of unVisibleColumns; track item) {
            <div class="drag-drop-box" cdkDrag>
              {{ item.nameTranslated }}
            </div>
          }
        </div>
      </div>

    </div>

  </ng-container>

  <app-dialog-actions-cancel-save appDialogActions
                                  (save)="onSave()"
                                  [saveDisable]="visibleColumns.length === 0"
                                  [showSpinner]="savingSpinner">
  </app-dialog-actions-cancel-save>

</app-dialog>
