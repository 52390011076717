import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnitGroupStorage } from '../modelinterfaces/unit-group-storage.model';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class UnitGroupStorageService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getData(): Observable<UnitGroupStorage> {
    const url = `${this.environmentHelper.urlV2}/unitGroupStorage`;
    return this.http.get<UnitGroupStorage>(url).pipe(
      map(obj => UnitGroupStorage.valueOf(obj))
    );
  }

  public getDataFilterBy(filter: string): Observable<number[]> {
    const url = `${this.environmentHelper.urlV2}/unitGroupStorage/filterBy`;
    let params = new HttpParams();
    params = params.set('filter', filter);
    return this.http.get<number[]>(url, {params: params});
  }
}
