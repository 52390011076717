export class CommandTemplateShort {

  constructor(public id: number,
              public name: string,
              public text: string,
              public timeout: number) {
  }

  public static valueOf(o: CommandTemplateShort): CommandTemplateShort {
    return o ? new CommandTemplateShort(o.id, o.name, o.text, o.timeout) : null;
  }

  public static valuesOf(list: CommandTemplateShort[]): CommandTemplateShort[] {
    return list ? list.map(o => CommandTemplateShort.valueOf(o)) : [];
  }
}
