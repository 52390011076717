import {Coordinate} from 'ol/coordinate';
import {fromLonLat, transform} from 'ol/proj';
import {GpsCoordinate} from '../../../../../common-module/src/lib/modelinterfaces/gps-coordinate.model';
import {SensorData} from '../../../../../common-module/src/lib/modelinterfaces/sensor-data.model';

export class OlCoordinate {

  public static create(longitude: number, latitude: number): Coordinate {
    return fromLonLat([longitude, latitude]);
  }

  public static createFromGpsCoordinate(gpsCoordinate: GpsCoordinate): Coordinate {
    return fromLonLat([gpsCoordinate.longitude, gpsCoordinate.latitude]);
  }

  public static createFromSensorData(sensorData: SensorData): Coordinate {
    return fromLonLat([sensorData.longitude, sensorData.latitude]);
  }

  public static transform(olcoordinates, sourceEPSG = 'EPSG:3857', destEPSG = 'EPSG:4326'): GpsCoordinate {
    const lonlat = transform(olcoordinates, sourceEPSG, destEPSG);
    return new GpsCoordinate(lonlat[1], lonlat[0])
  }
}
