import {NgModule} from '@angular/core';
import {DayjsUtil} from "./dayjs.util";
import {provideEnvironmentNgxMask} from "ngx-mask";
import {NgxDaterangepickerMd} from "ngx-daterangepicker-material-no-utc";
import {CapitalizePipe} from "./pipes/capitalize.pipe";

@NgModule({
  declarations: [
  ],
  imports: [
    NgxDaterangepickerMd.forRoot({
      applyLabel: 'Ok',
      format: 'DD.MM.YYYY HH:mm',
      firstDay: 1,
      daysOfWeek: DayjsUtil.weekdaysMin(),
      monthNames: DayjsUtil.monthsShort()
    }),
  ],
  providers: [
    provideEnvironmentNgxMask(),
    CapitalizePipe
  ],
  bootstrap: [],
  exports: [
  ]
})

export class AppCommonModule {
}
