import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';

import {GpsCoordinate} from "../modelinterfaces/gps-coordinate.model";
import {map} from "rxjs/operators";
import {EnvironmentHelper} from "./environment-helper";
import {ReverseGeocodedAddress} from "../modelinterfaces/geolocated-address.model";
import {GeocodedAddress} from "../modelinterfaces/geocoded-address.model";

export enum AddressFormat {
  FULL = 'FULL', SHORT = 'SHORT'
}

@Injectable({
  providedIn: 'root'
})

export class GeocoderService {

  constructor(private http: HttpClient,
              private environmentHelper: EnvironmentHelper) {
  }

  public geocode(coordinate: GpsCoordinate, format: 'FULL' | 'SHORT' = 'SHORT'): Observable<GeocodedAddress> {
    const url = `${this.environmentHelper.urlV2}/geocoder/geocode`;
    const params = new HttpParams()
      .set('latitude', coordinate.latitude)
      .set('longitude', coordinate.longitude)
      .set('format', format)
      .set('lang', 'ru');
    return this.http.get<GeocodedAddress>(url, {params})
      .pipe(map(value => GeocodedAddress.valueOf(value)));
  }


  public reverse(query: string, format: 'FULL' | 'SHORT' = 'SHORT'): Observable<ReverseGeocodedAddress[]> {
    let url = `${this.environmentHelper.urlV2}/geocoder/reverse`;
    const params = new HttpParams()
      .set('query', query)
      .set('format', format)
    return this.http.get<ReverseGeocodedAddress[]>(url, {params})
      .pipe(map(g => ReverseGeocodedAddress.valuesOf(g)));
  }
}
