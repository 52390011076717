import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatListOption, MatSelectionList} from '@angular/material/list';
import {DateRangeI} from 'projects/common-module/src/lib/app-interfaces/date-range-i';
import {Observable, of, timer} from 'rxjs';
import {map, take} from 'rxjs/operators';

import {
  ViewBreakpointService,
  ViewSize
} from '../../../../../../../common-module/src/lib/app-services/view-breakpoint.service';
import {DvrFileStatus} from '../../../../../../../common-module/src/lib/modelinterfaces/enums/dvr-file-status';
import {DvrFileType} from '../../../../../../../common-module/src/lib/modelinterfaces/enums/dvr-file-type';
import {FilterOperation} from '../../../../../../../common-module/src/lib/modelinterfaces/enums/filter-operation';
import {UnitShort} from '../../../../../../../common-module/src/lib/modelinterfaces/unit-short.model';
import {SpecsFilterBuilder} from '../../../../../../../common-module/src/lib/utils/SpecsFilterBuilder';
import {Camera} from '../../../../../../../common-module/src/lib/modelinterfaces/camera.model';
import {ContentType} from '../../../../../../../common-module/src/lib/modelinterfaces/content-type.model';
import {DvrFilters} from '../../../../../../../common-module/src/lib/app-models/dvr-filters.models';
import {SelectedService} from '../../../../shared/services/selected.service';
import {ToolbarService} from "../../../../shared/services/toolbar.service";
import {VideoService} from '../../../../shared/services/video.service';
import {DayjsUtil} from "../../../../../../../common-module/src/lib/dayjs.util";
import {Dayjs} from "dayjs";

interface FilterList {
  list: Observable<any[]>;
  keyItemParam: string;
  keyItemName: string;
  name: string;
  param: string;
}

interface DateRangeISOString {
  startDate: string,
  endDate: string
}

@Component({
  selector: 'app-filter-video',
  templateUrl: './filter-video.component.html',
  styleUrls: ['./filter-video.component.scss', '../../../../../../../common-module/src/lib/app-styles/dialog-common.scss']
})

export class FilterVideoComponent implements OnChanges, OnInit {

  public readonly DAY_LIMIT = 1;

  @Input() isResetAll: boolean;
  @Input() isTrackOptions = false;

  @Output() filterChange = new EventEmitter<DvrFilters>();

  public cameraList$: Observable<Camera[]>;
  public contentTypes$: Observable<ContentType[]>;
  public dateTimeRange: DateRangeI;
  public daysRangeISOString: DateRangeISOString[] = [];
  public minDate: Dayjs;
  public maxDate: Dayjs;
  public lastSelectedUnit$: Observable<UnitShort>;
  public filters: DvrFilters;
  public filterList: FilterList[];
  public size$: Observable<ViewSize>;

  constructor(private selectedService: SelectedService,
              private toolbarService: ToolbarService,
              private videoService: VideoService,
              private viewBreakpointService: ViewBreakpointService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isResetAll'] && this.isResetAll) {
      this.filters = new DvrFilters();
      this.createFilterList();
      this.onSubmit();
    }
    if (changes['isTrackOptions'] && this.isTrackOptions) {
      this.videoService.getCameraList(this.selectedService.getInstantUnit().id);
      this.videoService.getContentTypes();
      this.filters = new DvrFilters();
      this.calculateDaysRange();
    }
  }

  ngOnInit(): void {
    this.getCurrentFilters();
    this.createFilterList();
    this.cameraList$ = this.videoService.cameraList$;
    this.contentTypes$ = this.videoService.contentTypeList$;
    this.lastSelectedUnit$ = this.selectedService.lastSelectedUnit$;
    this.size$ = this.viewBreakpointService.size$;
  }

  private calculateDaysRange(): void {
    const trackDateTimeRange = this.toolbarService.getDateTimeRange();
    this.minDate = this.toolbarService.getDateTimeRange().startDate;
    this.maxDate = this.toolbarService.getDateTimeRange().endDate;
    const days = trackDateTimeRange.endDate.diff(trackDateTimeRange.startDate, 'days');
    for (let i = 0; i < days + 1; i++) {
      if (i === 0) {
        this.daysRangeISOString.push(
          {
            startDate: trackDateTimeRange.startDate.toISOString(),
            endDate: DayjsUtil.instant(trackDateTimeRange.startDate).endOf('days').toISOString()
          }
        );
        this.dateTimeRange = {
          startDate: trackDateTimeRange.startDate,
          endDate: DayjsUtil.instant(trackDateTimeRange.startDate).endOf('days')
        };
        this.onSubmit();
        continue;
      }
      const newDay = DayjsUtil.instant(trackDateTimeRange.startDate).add(i, 'day').startOf('day');
      this.daysRangeISOString.push(
        {
          startDate: newDay.toISOString(),
          endDate: DayjsUtil.instant(newDay).endOf('day').toISOString()
        }
      );
    }
  }

  private getCurrentFilters(): void {
    if (!this.isTrackOptions) {
      of(this.videoService.getInstantFilters()).pipe(take(1)).subscribe(list => this.filters = list);
    }
    if (this.isTrackOptions && this.toolbarService.instantTrackVideoPointsFilterParams()) {
      this.filters = this.toolbarService.instantTrackVideoPointsFilterParams();
    }
    if (this.filters?.time?.value) {
      this.dateTimeRange = this.filters.time.value;
    }
  }

  private createFilterList(): void {
    this.filterList = [
      {
        list: this.videoService.cameraList$,
        keyItemParam: 'id',
        keyItemName: 'name',
        name: 'video.cameras',
        param: this.filters.cameraIds.param
      }, {
        list: this.videoService.contentTypeList$,
        keyItemParam: 'contentType',
        keyItemName: 'translated',
        name: 'term.type',
        param: this.filters.contentType.param
      }, {
        list: of(Object.values(DvrFileType)),
        keyItemParam: null,
        keyItemName: null,
        name: 'video.content',
        param: this.filters.fileType.param
      }, {
        list: of(Object.values(DvrFileStatus)),
        keyItemParam: null,
        keyItemName: null,
        name: 'term.status',
        param: this.filters.status.param
      }
    ];
  }

  public changeDateTimeRange(timeRange: DateRangeI): void {
    if (timeRange?.startDate) {
      this.filters.time.value = `${timeRange.startDate.toISOString()},${timeRange.endDate.toISOString()}`;
    } else {
      this.filters.time.value = null;
    }
    this.dateTimeRange = timeRange;
    this.onSubmit();
  }

  public onChangeFilterParam(selected: MatListOption[], filterParam: string): void {
    this.filters[this.foundKey(this.filters, filterParam)].value = selected.map(el => el.value).join(',');
  }

  public clearFilter(filterParam: string): void {
    this.filters[this.foundKey(this.filters, filterParam)].value = null;
    this.onSubmit();
  }

  private foundKey(currentObject: object, param: string): string {
    return Object.keys(currentObject).find(key => currentObject[key].param === param);
  }

  public comparisonId = (o: any, v: any): boolean => {
    if (!o || !v) {
      return false;
    }
    return o.id === v.id;
  };

  public isSelectedList(selected: MatSelectionList): Observable<boolean> {
    return timer(100).pipe(map(() => selected.selectedOptions.selected.length > 0));
  }

  public isSelected(option: string | number, filterParam: string): boolean {
    return this.filters[this.foundKey(this.filters, filterParam)]?.value?.includes(option.toString());
  }

  public onSubmit(): void {
    if (this.dateTimeRange?.startDate) {
      this.filters.time.paramValue =
        SpecsFilterBuilder.build(
          FilterOperation.BETWEEN,
          this.dateTimeRange.startDate.toISOString(),
          this.dateTimeRange.endDate.toISOString()
        );
      this.filters.time.value = this.dateTimeRange;
    } else {
      this.filters.time.paramValue = null;
      this.filters.time.value = null;
    }
    this.filters.cameraIds.paramValue = SpecsFilterBuilder.build(FilterOperation.IN, this.filters.cameraIds.value);
    this.filters.contentType.paramValue = SpecsFilterBuilder.build(FilterOperation.IN, this.filters.contentType.value);
    this.filters.fileType.paramValue = SpecsFilterBuilder.build(FilterOperation.IN, this.filters.fileType.value);
    this.filters.status.paramValue = SpecsFilterBuilder.build(FilterOperation.IN, this.filters.status.value);
    Object.keys(this.filters).forEach(key => {
      if (this.filters[key].value?.length === 0) {
        this.filters[key].value = null;
      }
    });
    if (this.viewBreakpointService.currentSize.more('m') && !this.isTrackOptions) {
      this.videoService.changeFilters(this.filters);
      return;
    }
    this.filterChange.emit(this.filters);
  }

  public asFilter(filter: FilterList): FilterList {
    return filter;
  }
}
