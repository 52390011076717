import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParamMap } from "@angular/router";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DvrFile } from '../modelinterfaces/dvr-file.model';
import { FilterOperation } from '../modelinterfaces/enums/filter-operation';
import { Page } from '../modelinterfaces/page.model';
import { HttpParamsUtil } from '../utils/http-params-util';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class DvrFileService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getPage(unitId: number,
                 lang: string,
                 paramsMap: ParamMap): Observable<Page<DvrFile>> {
    const url = `${this.environmentHelper.urlDvr}/file/page`;
    const params = this.createParams(unitId, lang, paramsMap);
    return this.http.get<Page<DvrFile>>(url, {params: params}).pipe(
      map(page => DvrFile.pageableValueOf(page))
    );
  }

  private createParams(unitId: number,
                       lang: string,
                       paramsMap: ParamMap): HttpParams {
    let params = HttpParamsUtil.createByLang(lang);
    if (unitId) {
      params = params.set('unitIdFilter', FilterOperation.EQUALS + unitId.toString());
    }
    paramsMap.keys.forEach(key => {
      params = params.set(key, paramsMap.get(key));
    });
    return params;
  }

  public requestFileFromTracker(cameraId: number, fileName: string, lang: string): Observable<DvrFile> {
    const url = `${this.environmentHelper.urlDvr}/file/camera/${cameraId}/fileName/${fileName}`;
    return this.http.post<DvrFile>(url, null, {params: HttpParamsUtil.createByLang(lang)}).pipe(
      map(obj => DvrFile.valueOf(obj))
    );
  }

  public getFileByNameAndCameraId(cameraId: number, fileName: string, lang: string): Observable<DvrFile> {
    const url = `${this.environmentHelper.urlDvr}/file/camera/${cameraId}/fileName/${fileName}`;
    return this.http.get<DvrFile>(url, {params: HttpParamsUtil.createByLang(lang)}).pipe(
      map(obj => DvrFile.valueOf(obj))
    );
  }
}
