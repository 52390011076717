import {Logistic} from "../../../../../../../../../common-module/src/lib/modelinterfaces/logistic.model";
import {UrlImage} from "../../../../../constants/url-image";
import {OlStepStyleOpts, StepStyleFactory} from "./step-style.factory";
import {OlFeaturePointFactory} from "../../../../../aur-map-creator/feature/core/ol/feature/ol-feature-point.factory";
import {StepFeatureWrapper} from "./step-feature.wrapper";

export enum StepTypeEnum {
  PICKUP_ADDRESS = 'pickup',
  DELIVERY_ADDRESS = 'delivery',
  JOB = 'job',
  WAREHOUSE = 'warehouse'
}

export class StepFeatureWrapperFactory {

  private styleFactory: StepStyleFactory = new StepStyleFactory();

  private static readonly STEP_IMAGE_URL = new Map([
    [StepTypeEnum.DELIVERY_ADDRESS, UrlImage.DELIVERY_POINT],
    [StepTypeEnum.PICKUP_ADDRESS, UrlImage.PICKUP_POINT],
    [StepTypeEnum.JOB, UrlImage.JOB_POINT],
    [StepTypeEnum.WAREHOUSE, UrlImage.WAREHOUSE_POINT]
  ]);

  public create(step: Logistic.Step, job: Logistic.Job, opts: OlStepStyleOpts): StepFeatureWrapper {
    this.prepareOpts(opts, step, job);
    const olFeature = OlFeaturePointFactory.create(step.getCoordinate());
    return new StepFeatureWrapper(olFeature, this.styleFactory, opts, job, step);
  }

  protected prepareOpts(opts: OlStepStyleOpts, step: Logistic.Step, job: Logistic.Job) {
    opts.iconUrl = this.getStepImage(step, job).toString();
  }

  private getStepImage(step: Logistic.Step, job: Logistic.Job): UrlImage {
    const stepType = this.getStepType(step, job);
    return StepFeatureWrapperFactory.STEP_IMAGE_URL.get(stepType) || UrlImage.PLACE_POINT;
  }

  private getStepType(step: Logistic.Step, job: Logistic.Job): StepTypeEnum {
    if (job.isJob()) {
      return StepTypeEnum.JOB;
    }
    if (step.isStepWarehouse()) {
      return StepTypeEnum.WAREHOUSE;
    }
    if (job.getPickupOptional() === step) {
      return StepTypeEnum.PICKUP_ADDRESS;
    }
    return StepTypeEnum.DELIVERY_ADDRESS;
  }

}


