import { Component } from '@angular/core';

@Component({
  selector: 'app-logistic-logo-title',
  templateUrl: './logistic-logo-title.component.html',
  styleUrl: './logistic-logo-title.component.scss'
})
export class LogisticLogoTitleComponent {

}
