<app-dialog [title]="'action.change-password' | translate">

  <ng-container appDialogContent>
    <form [formGroup]="userPasswordForm">
      <div class="form-container">

        <mat-form-field class="margin-top-8" subscriptSizing="dynamic">
          <mat-icon *ngIf="showSpinner" class="show-spinner">
            <mat-spinner diameter="20"></mat-spinner>
          </mat-icon>

          <mat-label>{{ 'term.old-password' | translate }}</mat-label>
          <input matInput
                 type="password"
                 formControlName="old-password"
                 [type]="hideOldPassword ? 'password' : 'text'">

          <mat-icon class="icon-visibility"
                    matSuffix
                    (click)="$event.stopPropagation(); hideOldPassword = !hideOldPassword">
            {{ hideOldPassword ? 'visibility_off' : 'visibility' }}
          </mat-icon>
          <mat-error *ngIf="formControl['old-password'].errors?.required">
            {{ 'message.error.required-field' | translate }}
          </mat-error>
          <mat-error *ngIf="formControl['old-password'].errors?.notEqual">
            {{ 'message.error.incorrect-password' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="margin-top-24" subscriptSizing="dynamic">
          <mat-label>{{ 'term.new-password' | translate }}</mat-label>
          <input type="password"
                 matInput
                 formControlName="new-password-first"
                 [type]="hideNewPassword ? 'password' : 'text'">
          <mat-icon class="icon-visibility" matSuffix
                    (click)="$event.stopPropagation(); hideNewPassword = !hideNewPassword">
            {{ hideNewPassword ? 'visibility_off' : 'visibility' }}
          </mat-icon>
          <mat-error *ngIf="formControl['new-password-first'].errors?.required">
            {{ 'message.error.required-field' | translate }}
          </mat-error>
          <mat-error *ngIf="formControl['new-password-first'].errors?.pattern">
            {{ 'message.error.must-contain-at-least' | translate }}
          </mat-error>
          <mat-error *ngIf="formControl['new-password-first'].errors?.minlength">
            {{ 'message.error.min-length-symbols' | translate: {min: PASSWORD_MIN_LENGTH} }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="margin-top-12" subscriptSizing="dynamic">
          <mat-label>{{ 'action.confirm-password' | translate }}</mat-label>
          <input matInput
                 type="password"
                 formControlName="new-password-second"
                 [type]="hideConfirmPassword ? 'password' : 'text'">
          <mat-icon class="icon-visibility" matSuffix
                    (click)="$event.stopPropagation(); hideConfirmPassword = !hideConfirmPassword">
            {{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}
          </mat-icon>
          <mat-error *ngIf="formControl['new-password-second'].errors?.required">
            {{ 'message.error.required-field' | translate }}
          </mat-error>
          <mat-error *ngIf="formControl['new-password-second'].errors?.pattern">
            {{ 'message.error.must-contain-at-least' | translate }}
          </mat-error>
          <mat-error *ngIf="formControl['new-password-second'].errors?.minlength">
            {{ 'message.error.min-length-symbols' | translate: {min: PASSWORD_MIN_LENGTH} }}
          </mat-error>
          <mat-error *ngIf="formControl['new-password-second'].errors?.notEqual">
            {{ 'message.error.passwords-not-match' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>

  </ng-container>

  <ng-container appDialogActions>

    <app-dialog-actions-cancel-save (save)="onSubmit()"
                                    [saveDisable]="!userPasswordForm?.valid || showSpinner || formValue['new-password-first'] !== formValue['new-password-second']"
                                    [showSpinner]="showSpinner">
    </app-dialog-actions-cancel-save>

  </ng-container>

</app-dialog>



