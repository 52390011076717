<table mat-table
       [dataSource]="(dataSource$ | async)"
       class="table-without-header"
       [ngClass]="{light: (loading$ | async)}">

  <ng-container matColumnDef="{{ displayedColumns[0] }}">
    <td mat-cell class="read-column" *matCellDef="let element">
      <app-icon-button-orange icon="fiber_manual_record"
                              (action)="readNotification(asNotification(element).id, $event)"
                              [disabled]="asNotification(element).id === (readNotificationId$ | async) || (loading$ | async)"
                              [matTooltip]="'action.mark-read' | translate"
                              [matTooltipPosition]="'right'"
                              [showSpinner]="asNotification(element).id === (readNotificationId$ | async)">
      </app-icon-button-orange>
    </td>
  </ng-container>

  <ng-container matColumnDef="{{ displayedColumns[1] }}">
    <td mat-cell *matCellDef="let element">
      <ng-container>{{ asNotification(element).unit.name }}</ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="{{ displayedColumns[2] }}">
    <td mat-cell
        *matCellDef="let element"
        class="message-column"
        matTooltip="{{ asNotification(element).unit.name }}
                    {{ asNotification(element).source.message }}
                    {{ asNotification(element).startTime | utcDateTz: (timeZone$ | async) }} -
                    {{ asNotification(element).finishTime ?
                    (asNotification(element).finishTime | utcDateTz: (timeZone$ | async)) : '...' }}"
        matTooltipClass="line-break-tooltip">
      {{ asNotification(element).source.message }}
    </td>
  </ng-container>

  <ng-container matColumnDef="{{ displayedColumns[3] }}">
    <td mat-cell
        *matCellDef="let element"
        class="time-column">
      {{ asNotification(element).startTime | utcDateTz: (timeZone$ | async) : 'HH:mm:ss' }}
    </td>
  </ng-container>

  <tr mat-row
      *matRowDef="let row; columns: displayedColumns;"
      class="highlight-row"
      [ngClass]="{'active-notification': asNotification(row).status === notificationStatus.ACTIVE,
      'new-notification': isNotificationNew(row)}"
      (click)="openNotificationDetails(asNotification(row))">
  </tr>
</table>

<div *ngIf="(dataSource$ | async)?.data?.length === 0 && !(loading$ | async)" class="warning-message">
  {{ 'message.error.nothing-found' | translate }}
</div>

<mat-icon class="table-spinner" *ngIf="loading$ | async">
  <mat-spinner diameter="50"></mat-spinner>
</mat-icon>
