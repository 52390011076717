import Overlay from 'ol/Overlay';
import OlMap from "ol/Map";
import {Point} from "ol/geom";
import {Feature} from "ol";

export class OlDescriptionOverlay {
  public static FEATURE_PROPERTY = "description"

  /**
   * после этого нужно вызвать
   *  this.map.on('pointermove', (e) => {
   *       this.descriptionPopup.setPosition(undefined); // hide popup if not on Feature
   *       this.map.forEachFeatureAtPixel(e.pixel, f => {
   *          // код отображения popup
   *       });
   *     });
   * @param map
   * @param elementId
   * @param offset
   */
  public static initPopup(map: OlMap, elementId: string, offset: [number, number]): Overlay {
    const overlay = this.createPopup(document.getElementById(elementId), offset);
    map.addOverlay(overlay);
    return overlay;
  }

  /**
   * Example initialization in html layout
   * <div id="example-map" class="map">
   *       <div id="description-popup" class="popup">
   *         <div id="description-popup-content"></div>
   *       </div>
   * </div>
   *
   * Add a hover message to a OlFeature if it has a property named {@param propertyName}
   *
   * @param map Overlay will be added to this map
   * @param popupName element id where the popup will be inserted
   * @param propertyName feature property name get text for popup
   * @param offset
   *
   * @return tuned overlay
   */
  public static initFeaturePopup(map: OlMap, popupId: string, offset: [number, number] = [-30, 10]): Overlay {
    const popup = OlDescriptionOverlay.createPopup(document.getElementById(popupId), offset);
    map.addOverlay(popup);
    map.on('pointermove', (e) => {
      popup.setPosition(undefined); // hide popup if not on Feature
      map.forEachFeatureAtPixel(e.pixel, f => {
        if (f.get(this.FEATURE_PROPERTY)) {
          document.getElementById(popupId).innerHTML = `<p> ${f.get(this.FEATURE_PROPERTY)} </p>`;
          if (f.getGeometry() instanceof Point) {
            popup.setPosition((<Point>f.getGeometry()).getCoordinates());
          } else {
            popup.setPosition(e.coordinate);
          }
        }
      });
    });
    return popup;
  }

  public static setDescription(feature: Feature, text: string) {
    feature.set(this.FEATURE_PROPERTY, text);
  }

  public static createPopup(elem: HTMLElement, offset: [number, number]): Overlay {
    return new Overlay({
      element: elem,
      offset: offset,
      autoPan: true,
      autoPanAnimation: {duration: 250},
      autoPanMargin: 1
    });
  }
}
