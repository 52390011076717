<div [style.width.px]="width" class="card"
     [ngClass]="{'card_le_l': (viewBreakpointService.size$ | async).less('l')}">
  <h4>
    <span>{{ 'unit.driver' | translate }}</span>
  </h4>

  <p>
    <app-current-driver [unitId]="unitData?.unit.id" [isCarousel]="true"></app-current-driver>
  </p>
</div>
