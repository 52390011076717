import {Logistic} from "../../../../../../../common-module/src/lib/modelinterfaces/logistic.model";
import Amount = Logistic.Amount;

export class LogisticAmountUtil {
  public static toString(amount: Logistic.Amount, unknownSymbol: string = '?'): string {
    if (!amount || !this.hasAny(amount)) {
      return unknownSymbol;
    }
    let result = '';
    if (amount.weight > 0) {
      result += this.getWeight(amount, '');
    }
    if (amount.volume > 0) {
      result += ` ${this.getVolume(amount, '')}`;
    }
    if (this.isPresent(amount)) {
      result += ` ${this.getSize(amount.length)}x`;
      result += `${this.getSize(amount.width)}x`;
      result += `${this.getSize(amount.height)}`;
    }
    return result;
  }

  public static getWeight(amount: Amount, elseStr: string = '?') {
    return amount.weight > 0 ? `${amount.weight} кг` : elseStr;
  }

  public static getVolume(amount: Amount, elseStr: string = '?') {
    return amount.volume > 0 ? `${amount.volume} м\u00B3` : elseStr;
  }

  public static getDimension(dimension: number, elseStr: string = '?'): string {
    return dimension > 0 ? `${dimension} м` : `${elseStr}`
  }

  private static getSize(dimension: number, elseStr: string = '?') {
    return dimension > 0 ? dimension : elseStr;
  }

  private static isPresent(capacity: Amount): boolean {
    return capacity.width > 0 || capacity.length > 0 || capacity.height > 0;
  }

  private static hasAny(capacity: Amount): boolean {
    return this.isPresent(capacity) || capacity.weight > 0 || capacity.volume > 0;
  }

  public static tooltip(capacity: Amount) {
    if (!capacity || !this.hasAny(capacity)) {
      return 'Нет данных';
    }
    let result = '';
    if (capacity.weight > 0) {
      result = `(Грузоподъемность) кг`;
    }
    if (capacity.volume > 0) {
      result = `(Объем) м\u00B3`;
    }
    if (this.isPresent(capacity)) {
      result += ` (Длина)x`;
      result += `(Ширина)x`;
      result += `(Высота)`;
    }
    return result;
  }
}
