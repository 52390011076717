import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import OlMap from 'ol/Map';
import Feature from 'ol/Feature';
import {Layer} from 'ol/layer';
import Point from 'ol/geom/Point';
import {TranslateService} from '@ngx-translate/core';
import { Geofence } from '../../../../../../../../common-module/src/lib/modelinterfaces/geofence.model';
import { GpsCoordinate } from '../../../../../../../../common-module/src/lib/modelinterfaces/gps-coordinate.model';

import {NotificationDetails} from '../../../../../../../../common-module/src/lib/modelinterfaces/notification-details.model';
import {OpenLayerMapService} from '../../../../../shared/services/open-layer-map.service';
import {MapSettings} from '../../../../../shared/constants/enums/map-settings';
import {TileLayerService} from '../../../../../shared/services/tile-layer.service';
import {DrawGeofenceService} from '../../../../../shared/services/draw-geofence.service';
import {OlFeature} from '../../../../../shared/map-utils/OlFeature';
import {UrlImage} from '../../../../../shared/constants/url-image';
import {OlCoordinate} from '../../../../../shared/map-utils/OlCoordinate';
import {OlVectorLayer} from '../../../../../shared/map-utils/OlVectorLayer';
import {LayerName} from '../../../../../shared/constants/enums/layer-name';
import {CenterMapUtil} from '../../../../../shared/map-utils/center-map.util';
import {PointMarker} from '../../../../../../../../common-module/src/lib/modelinterfaces/point-marker.model';
import {Track} from '../../../../../../../../common-module/src/lib/modelinterfaces/track.model';
import {OlTrackBuildService} from '../../../../../shared/services/ol-track-build.service';
import {AuthUserService} from '../../../../../../../../common-module/src/lib/app-services/auth-user.service';
import {DurationConverter} from '../../../../../../../../common-module/src/lib/utils/duration-converter';
import {TrackLayerService} from '../../../../../shared/services/track-layer.service';
import {Orientation} from '../../../../../shared/constants/enums/orientation';
import {PrintToPdfService} from '../../../../../shared/services/print-to-pdf.service';
import {ViewBreakpointService, ViewSize} from '../../../../../../../../common-module/src/lib/app-services/view-breakpoint.service';
import {Observable} from 'rxjs';
import { Tab } from 'projects/common-module/src/lib/app-enums/tab';

@Component({
  selector: 'app-notification-details',
  templateUrl: './notification-details.component.html',
  styleUrls: ['./notification-details.component.scss']
})

export class NotificationDetailsComponent implements OnChanges {

  private readonly DIALOG_ELEMENT_ID = 'notification-details';
  private readonly DIALOG_FILE_NAME = this.translateService.instant('term.notification');

  @Input() notificationDetails: NotificationDetails;
  @Input() notificationDuration: string;
  @Input() timeZone: string;

  public map: OlMap;
  public mileage: number;
  public parkingsDuration: string;
  public size$: Observable<ViewSize>;
  public tripsDuration: string;

  constructor(private authUserService: AuthUserService,
              private drawGeofenceService: DrawGeofenceService,
              private olTrackBuildService: OlTrackBuildService,
              private openLayerMapService: OpenLayerMapService,
              private printService: PrintToPdfService,
              private tileLayerService: TileLayerService,
              private trackLayerService: TrackLayerService,
              private translateService: TranslateService,
              private viewBreakpointService: ViewBreakpointService) {
    this.size$ = this.viewBreakpointService.size$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['notificationDetails'] && this.notificationDetails) {
      this.createMap();
      this.calculateTrackParameters(this.notificationDetails?.mapDetails?.track);
    }
  }

  private createMap(): void {
    const layer: Layer[] = this.tileLayerService.buildCurrentSelectedTile();
    this.map = this.openLayerMapService.create(layer, 'map-notification', MapSettings.DIALOG_ZOOM);
    if (this.notificationDetails.mapDetails.geofences.length > 0) {
      this.createGeofences(this.notificationDetails.mapDetails.geofences);
    }
    if (this.notificationDetails.mapDetails.points.length > 0) {
      this.createPoints(this.notificationDetails.mapDetails.points);
    }
    if (this.notificationDetails.mapDetails.track) {
      this.createTrack(this.notificationDetails.mapDetails.track);
    }
  }

  private createGeofences(geofences: Geofence[]): void {
    this.drawGeofenceService.init(this.map, Tab.NOTIFICATION);
    geofences.forEach(geofence => this.drawGeofenceService.show(geofence, this.map, Tab.NOTIFICATION));
  }

  private createPoints(points: PointMarker[]): void {
    const markers: Feature[] = [];
    points.forEach(point => {
      const marker = OlFeature.createMarker(UrlImage.POSITION, new Point(OlCoordinate.createFromGpsCoordinate(point.gpsCoordinate)));
      markers.push(marker);
    });
    const layerPoint = OlVectorLayer.createVectorLayerWithFeatures(markers, LayerName.POINTS);
    this.map.addLayer(layerPoint);
    CenterMapUtil.center(points.map(point => point.gpsCoordinate), this.map);
  }

  private createTrack(track: Track): void {
    const trackLayers = this.olTrackBuildService.buildTrackVectorLayer(
      track,
      LayerName.TRACK_MAP_DIALOG,
      {
        isStartFinish: true,
        isShowParkings: true,
        isShowTrips: true,
        isShowPoints: false,
        maxSpeed: 0,
        minDurationOverSpeed: 0,
        sensorRanges: []
      },
      this.authUserService.getInstantTimeZone()
    );
    for (const l of trackLayers) {
      this.map.addLayer(l);
    }
    if (track.trips.length > 0) {
      CenterMapUtil.center(track, this.map);
      return;
    }

    if (track.parkings) {
      CenterMapUtil.center(track.parkings.map(parking => parking.getCoordinate()), this.map);
    }
  }

  private calculateTrackParameters(track: Track): void {
    if (!track) {
      return;
    }
    let tripsDurationSum = 0;
    this.mileage = this.notificationDetails.mapDetails.track.mileage.corrected;
    this.notificationDetails.mapDetails.track.trips.forEach(trip => {
      tripsDurationSum += trip.duration;
    });
    this.tripsDuration = DurationConverter.secondsToStringDuration(tripsDurationSum, this.translateService);

    let parkingsDurationSum = 0;
    this.notificationDetails.mapDetails.track.parkings.forEach(parking => {
      parkingsDurationSum += parking.duration;
    });
    this.parkingsDuration = DurationConverter.secondsToStringDuration(parkingsDurationSum, this.translateService);
  }

  public onReceivePrintOrientation(orientation: Orientation): void {
    this.printService.printDialog(this.DIALOG_ELEMENT_ID, this.map, this.notificationDetails?.mapDetails?.track, orientation, 2,
      this.DIALOG_FILE_NAME);
  }

  public receiveSelectedCoordinate(gpsCoordinate: GpsCoordinate): void {
    this.trackLayerService.highlightPosition(gpsCoordinate, this.map);
  }
}
