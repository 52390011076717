export class GeofenceLight {

  constructor(public id: number,
              public name: string) {
  }

  public static valueOf(o: GeofenceLight): GeofenceLight {
    return o ? new GeofenceLight(o.id, o.name) : null;
  }

  public static valuesOf(list: GeofenceLight[]): GeofenceLight[] {
    return list ? list.map(o => GeofenceLight.valueOf(o)) : [];
  }
}
