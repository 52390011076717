import {inject, NgModule} from '@angular/core';
import {NoPreloading, ResolveFn, RouterModule, Routes} from '@angular/router';
import {SystemComponent} from './system/system.component';
import {Tab} from '../../../common-module/src/lib/app-enums/tab';
import {ReportSettings} from "../../../common-module/src/lib/modelinterfaces/report-settings.model";
import {Observable} from "rxjs";
import {ReportSettingsStorageService} from "./shared/services/report-settings-storage.service";

export const initReportSettingsStorageResolver: ResolveFn<ReportSettings[]> = (): Observable<ReportSettings[]> => {
  const dataService = inject(ReportSettingsStorageService);
  return dataService.init();
};

const routes: Routes = [
  {path: Tab.LOGIN, loadChildren: () => import('./auth/auth.module').then(module => module.AuthModule)},
  {
    path: Tab.TELEGRAM,
    loadChildren: () => import('./telegram-activation/telegram.module').then(module => module.TelegramModule)
  },
  {
    path: Tab.LOGISTIC,
    loadChildren: () => import('./system/logistic/logistic.module').then(module => module.LogisticModule)
  },
  {
    path: '',
    component: SystemComponent,
    resolve: {
      reportSettings: initReportSettingsStorageResolver
    },
    children: [
      {
        path: '',
        redirectTo: Tab.MAP,
        pathMatch: 'full'
      },
      {path: Tab.MAP, loadChildren: () => import('./system/main/map/map.module').then(module => module.MapModule)},
      {
        path: Tab.ECO_DRIVING,
        loadChildren: () => import('./system/main/eco-driving/eco-driving.module').then(module => module.EcoDrivingModule)
      },
      {
        path: Tab.DRIVERS,
        loadChildren: () => import('./system/main/drivers/drivers.module').then(module => module.DriversModule)
      },
      {
        path: Tab.SENSORS,
        loadChildren: () => import('./system/main/sensors/sensors.module').then(module => module.SensorsModule)
      },
      {
        path: Tab.REPORT,
        loadChildren: () => import('./system/main/report/report.module').then(module => module.ReportModule)
      },
      {
        path: Tab.CHART,
        loadChildren: () => import('./system/main/chart/chart.module').then(module => module.ChartModule)
      },
      {
        path: Tab.VEHICLE,
        loadChildren: () => import('./system/main/vehicle/vehicle.module').then(module => module.VehicleModule)
      },
      {
        path: Tab.NOTIFICATION,
        loadChildren: () => import('./system/main/notification/notification.module').then(module => module.NotificationModule)
      },
      {
        path: Tab.VIDEO,
        loadChildren: () => import('./system/main/video/video.module').then(module => module.VideoModule)
      },
      {
        path: Tab.COMMAND_TEMPLATES,
        loadChildren: () => import('./system/main/command-templates/command-templates.module').then(module => module.CommandTemplatesModule)
      },
      {
        path: Tab.PUBLIC,
        loadChildren: () => import('./system/public/public.module').then(module => module.PublicModule)
      },
    ]
  },
  {path: '**', redirectTo: Tab.MAP}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      preloadingStrategy: NoPreloading
    }
  )],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
