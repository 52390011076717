<app-dialog [title]="'titles.select-sensors-to-generate-chart' | translate">

  <ng-container appDialogActions>
    <app-button-cancel></app-button-cancel>
    <app-button-build [showSpinner]="showSpinner"
                      [disabled]="selectedSensorIdList.length === 0 && selectedParamNameList.length === 0 && selectedVirtualNameList.length === 0"
                      (build)="onSubmit()">
    </app-button-build>
  </ng-container>

  <ng-container appDialogContent>

    <div class="app-form-container">
      <mat-accordion *ngIf="data">
        <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">

          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-checkbox class="title-checkbox"
                            [checked]="sensorStorage?.sensors.length === selectedSensorIdList.length && selectedSensorIdList.length > 0"
                            [indeterminate]="selectedSensorIdList.length > 0 && sensorStorage?.sensors.length > selectedSensorIdList.length"
                            (change)="onToggleSelectAllSensors($event)"
                            (click)="$event.stopPropagation()">
              </mat-checkbox>
            </mat-panel-title>

            <mat-panel-description>{{ 'term.sensors' | translate }}</mat-panel-description>

          </mat-expansion-panel-header>

          <div class="selection-list">
            <mat-selection-list (selectionChange)="onChangeSelectedSensorIdList($event)"
                                [compareWith]="comparisonId">
              <mat-list-option *ngFor="let sensor of sensorStorage?.sensors"
                               class="list-option"
                               [value]="sensor"
                               [selected]="isSelectedSensor(sensor.id)">
                <p>
                  <span>{{ sensor.nameTranslated }}:&nbsp;</span>
                  <span class="sensor-text">
                {{ sensor.value | sensorValue: 1 }}&nbsp;{{ sensor.unitOfMeasure.nameTranslated }}
              </span>
                </p>
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion>
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-checkbox
                class="title-checkbox"
                [checked]="sensorStorage?.parameters.length === selectedParamNameList.length && selectedParamNameList.length > 0"
                [indeterminate]="selectedParamNameList.length > 0 && sensorStorage?.parameters.length > selectedParamNameList.length"
                (change)="onToggleSelectAllParams($event)"
                (click)="$event.stopPropagation()"
              ></mat-checkbox>
            </mat-panel-title>
            <mat-panel-description>{{ 'term.parameters' | translate }}</mat-panel-description>
          </mat-expansion-panel-header>

          <div class="selection-list">
            <mat-selection-list (selectionChange)="onChangeSelectedParamNameList($event)"
                                [compareWith]="comparisonId">
              <mat-list-option *ngFor="let parameter of sensorStorage?.parameters"
                               class="list-option"
                               [value]="parameter"
                               [selected]="isSelectedParameter(selectedParamNameList, parameter.name)">
                <p>
                  <span>{{ parameter.nameTranslated }}:&nbsp;</span>
                  <span class="sensor-text">
                {{ parameter.value | sensorValue: 1 }}&nbsp;{{ parameter.unitOfMeasure.nameTranslated }}
              </span>
                </p>
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion>
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-checkbox class="title-checkbox"
                            [checked]="sensorStorage?.virtual.length === selectedVirtualNameList.length && selectedVirtualNameList.length > 0"
                            [indeterminate]="selectedVirtualNameList.length > 0 && sensorStorage?.virtual.length > selectedVirtualNameList.length"
                            (change)="onToggleSelectAllVirtual($event)"
                            (click)="$event.stopPropagation()">
              </mat-checkbox>
            </mat-panel-title>
            <mat-panel-description>{{ 'term.virtual-parameters' | translate }}</mat-panel-description>
          </mat-expansion-panel-header>

          <div class="selection-list">
            <mat-selection-list (selectionChange)="onChangeSelectedVirtualNameList($event)"
                                [compareWith]="comparisonId">
              <mat-list-option *ngFor="let parameter of sensorStorage?.virtual"
                               class="list-option"
                               [value]="parameter"
                               [selected]="isSelectedParameter(selectedVirtualNameList, parameter.name)">
                <p>
                  <span>{{ parameter.nameTranslated }}:&nbsp;</span>
                  <span class="sensor-text">
                {{ parameter.value | sensorValue: 1 }}&nbsp;{{ parameter.unitOfMeasure.nameTranslated }}
              </span>
                </p>

              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </ng-container>


</app-dialog>
