import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalStyleClass } from '../../../../../common-module/src/lib/app-enums/global-style-class.enum';
import { AppMessageService } from '../../../../../common-module/src/lib/app-services/app-message.service';
import { UiSpinnerService } from '../../../../../common-module/src/lib/app-services/ui-spinner.service';
import { DriverView } from '../../../../../common-module/src/lib/modelinterfaces/driver-view.model';
import { Driver } from '../../../../../common-module/src/lib/modelinterfaces/driver.model';
import { DriverService } from '../../../../../common-module/src/lib/services/driver.service';
import { UnitService } from '../../../../../common-module/src/lib/services/unit.service';
import { DriverDialogComponent } from '../../system/main/drivers/driver-dialog/driver-dialog.component';
import { RemoveDriverDialogComponent } from '../../system/main/drivers/remove-driver-dialog/remove-driver-dialog.component';
import { DriverState } from '../constants/enums/driver-state.enum';
import { UrlImage } from '../constants/url-image';
import { SelectedService } from './selected.service';
import { UnitStateService } from './unit-state.service';

@Injectable({
  providedIn: 'root'
})

export class DriverManagementService {

  public historySelectedDriver: Driver;
  public tempAvatar: string;
  public tempDriver: Driver;

  private avatarListSource = new BehaviorSubject<string[]>([]);
  private currentDriverSource = new BehaviorSubject<Driver>(null);
  private driverListSource = new BehaviorSubject<DriverView[]>([]);
  private driverDialogRef: MatDialogRef<DriverDialogComponent>;
  private fireHistoryListSource = new BehaviorSubject<boolean>(false);
  private stateList: DriverState[] = [];
  private stateSource = new BehaviorSubject(DriverState.DEFAULT);

  constructor(private appMessageService: AppMessageService,
              private dialog: MatDialog,
              private driverService: DriverService,
              private selectedService: SelectedService,
              private uiSpinnerService: UiSpinnerService,
              private unitService: UnitService,
              private unitStateService: UnitStateService) {
  }

  get currentDriver$(): Observable<Driver> {
    return this.currentDriverSource.asObservable();
  }

  get avatarList$(): Observable<string[]> {
    return this.avatarListSource.asObservable();
  }

  get driverList$(): Observable<DriverView[]> {
    return this.driverListSource.asObservable();
  }

  get fireHistoryList$(): Observable<boolean> {
    return this.fireHistoryListSource.asObservable();
  }

  get state$(): Observable<DriverState> {
    return this.stateSource.asObservable();
  }

  public instantDriver(): Driver {
    return this.currentDriverSource.getValue();
  }

  public changeCurrentDriver(driver: Driver, isUpdateStateImmediately = false): void {
    this.currentDriverSource.next(driver);
    if (isUpdateStateImmediately) {
      this.unitStateService.getData();
    }
  }

  public changeDriverFromUnitState(unitId: number): void {
    this.currentDriverSource.next(this.unitStateService.getInstant().getDriver(unitId));
  }

  public editDriver(): void {
    this.instantDriver() ? this.changeState(DriverState.EDIT) : this.changeState(DriverState.ADD);
  }

  private openDriverDialog(): void {
    if (this.driverDialogRef?.getState() === MatDialogState.OPEN) {
      return;
    }
    this.driverDialogRef = this.dialog.open(DriverDialogComponent, {
      panelClass: [GlobalStyleClass.DIALOG_SIZE_LIMIT]
    });
  }

  public removeDriver(): void {
    const dialogRef = this.dialog.open(RemoveDriverDialogComponent, {
      panelClass: [GlobalStyleClass.DIALOG_SIZE_LIMIT],
      data: {
        driver: this.instantDriver(),
        unit: this.selectedService.getInstantUnit()
      }
    });
    dialogRef.afterClosed().subscribe(r => {
      if (!r) {
        return;
      }
      this.uiSpinnerService.show();
      const unitId = this.selectedService.getInstantUnit().id;
      this.unitService.clearDriver(unitId).subscribe(() => {
        this.uiSpinnerService.stop();
        this.appMessageService.openSnackBar('message.info.unassigned');
        this.changeCurrentDriver(null);
        this.unitStateService.changeDriverInState(unitId, this.instantDriver());
        this.changeFireHistory(true);
      });
    });
  }

  public assignDriver(driver: Driver, oldUnitId: number): void {
    this.uiSpinnerService.show();
    this.unitService.assignDriver(this.selectedService.getInstantUnit().id, driver.id).subscribe(unit => {
      if (oldUnitId) {
        this.unitStateService.changeDriverInState(oldUnitId, null);
      }
      this.changeCurrentDriver(unit?.driver, true);
      this.uiSpinnerService.stop();
      this.appMessageService.openSnackBar('message.info.assigned');
      this.changeState(DriverState.DEFAULT);
      this.changeFireHistory(true);
    });
  }

  public openAssignDriverTable(): void {
    this.changeState(DriverState.ASSIGN);
  }

  public changeState(state: DriverState = null): void {
    if (!state && this.instantState() === DriverState.AVATAR) {
      state = this.getPreviousState();
    }
    if (!state && (this.instantState() === DriverState.ADD || this.instantState() === DriverState.EDIT)) {
      state = this.getStateBeforeAddOrEdit();
    }
    if (state !== DriverState.DEFAULT) {
      this.openDriverDialog();
    }
    this.stateList.push(state);
    this.stateSource.next(state);
    if (state === DriverState.LIST || state === DriverState.DEFAULT) {
      this.tempAvatar = null;
      this.tempDriver = null;
    }
    if (state === DriverState.DEFAULT) {
      this.stateList = [DriverState.DEFAULT];
      this.driverDialogRef.close();
    }
  }

  public getPreviousState(): DriverState {
    if (this.stateList.length < 2) {
      return null;
    }
    return this.stateList[this.stateList.length - 2];
  }

  public getStateBeforeAddOrEdit(): DriverState {
    const excludeInSearchStates = [DriverState.ADD, DriverState.EDIT, DriverState.AVATAR];
    const stateLength = this.stateList.length;
    for (let i = stateLength - 1; i >= 0; i--) {
      if (!excludeInSearchStates.includes(this.stateList[i])) {
        return this.stateList[i];
      }
    }
    return DriverState.DEFAULT;
  }

  public instantState(): DriverState {
    return this.stateSource.getValue();
  }

  public getAvatarList(): void {
    if (this.avatarListSource.getValue().length > 0) {
      this.changeState(DriverState.AVATAR);
      return;
    }
    this.uiSpinnerService.show();
    this.driverService.getAvatarList().subscribe(avatarList => {
      this.uiSpinnerService.stop();
      if (avatarList?.length > 0) {
        this.avatarListSource.next(avatarList);
        this.changeState(DriverState.AVATAR);
      } else {
        this.appMessageService.openSnackBar('message.error.nothing-found');
      }
    });
  }

  public getDriverList(): void {
    this.uiSpinnerService.show();
    this.driverService.getList().subscribe(list => {
      this.uiSpinnerService.stop();
      this.changeDriverList(list);
    });
  }

  public changeDriverList(list: DriverView[]): void {
    this.driverListSource.next(list || []);
  }

  public changeFireHistory(isFire: boolean): void {
    this.fireHistoryListSource.next(isFire);
  }

  public openHistory(driver: DriverView): void {
    this.historySelectedDriver = driver;
    this.changeState(DriverState.HISTORY);
  }
}
