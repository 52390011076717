<div class="flex flex-v-center"
     [ngClass]="{'hover-color-orange': value.archived}"
     [matTooltip]="value.archived?'В архиве': undefined">
  <span style="{{textStyle}}">{{ value.getName() }}</span>
  <mat-icon
    class="text-orange"
    *ngIf="value.archived">
    all_inbox
  </mat-icon>
</div>
