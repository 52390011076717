import {TileLayerService} from "../../../../services/tile-layer.service";
import {OpenLayerMapService} from "../../../../services/open-layer-map.service";
import OlMap from "ol/Map";
import {Layer} from "ol/layer";
import {MapSettings} from "../../../../constants/enums/map-settings";
import {BaseMap, BaseMapConfig} from "../base.map";

export abstract class AbstractMapFactory<CONFIG extends BaseMapConfig, MAP extends BaseMap> {
  constructor(private tileLayerService: TileLayerService,
              private openLayerMapService: OpenLayerMapService) {
  }

  /**
   * @param target div id for map  <div id="target"></div>
   */
  private createOlMapInternal(target: string = 'map'): OlMap {
    const layer: Layer[] = this.tileLayerService.buildCurrentSelectedTile();
    let map = this.openLayerMapService.create(layer, target, MapSettings.DIALOG_ZOOM);
    map.getViewport().style.position = 'absolute'; // for Chrome 49
    return map;
  }

  protected createOlMap(config?: BaseMapConfig): OlMap {
    const mapDivId = BaseMap.getMapDivId(config);
    return this.createOlMapInternal(mapDivId);
  }

  public abstract create(config: CONFIG): MAP;
}
