<mat-card appearance="outlined" class="card-background mat-elevation-z0"
          [ngClass]="{'card-background_le_l': (size$ | async).less('l')}">

  <app-date-range-picker [dateTimeRange]="dateTimeRange$ | async"
                         (dateTimeRangeChange)="changeDateTimeRange($event)">
  </app-date-range-picker>

  <ng-container *ngIf="(size$ | async).more('m')" [ngTemplateOutlet]="buildButton"></ng-container>
  <ng-template #buildButton>
    <button [ngClass]="{'build-button_le_l': (size$ | async).less('l')}"
            class="buttons build-button"
            [disabled]="disableBuildButton"
            (click)="onBuild($event)">
      <span *ngIf="!disableBuildButton">
          <span [ngClass]="{'text-build_m_m': (size$ | async).more('m')}">
            {{ 'action.build' | translate }}
          </span>
          <span class="text-option" *ngIf="(size$ | async).more('m')">
            {{ 'term.' + menuLastOption | translate }}
          </span>
      </span>
      <mat-icon *ngIf="disableBuildButton">
        <mat-spinner diameter="20"></mat-spinner>
      </mat-icon>
    </button>
  </ng-template>

  <button *ngIf="(size$ | async).more('m'); else toggleButtons"
          class="buttons menu-button"
          [matMenuTriggerFor]="menu"
          [disabled]="disableBuildButton"
          matTooltip="{{ 'message.tooltip.select-to-build' | translate }}">
    <mat-icon class="menu-icon">expand_more</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let menuOption of menuOptions" (click)="onMenu(menuOption)">
      <span>{{ 'term.' + menuOption.toLowerCase() | translate }}</span>
    </button>
  </mat-menu>

  <ng-template #toggleButtons>
    <mat-button-toggle-group *ngIf="(size$ | async).less('l')" class='buttons-group_le_l'
                             [hideSingleSelectionIndicator]="true">
      <mat-button-toggle
        [checked]="menuLastOption === menuOption"
        *ngFor="let menuOption of menuOptions" (click)="onMenu(menuOption)">
        {{ 'term.' + menuOption.toLowerCase() | translate }}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </ng-template>

  <app-report-options *ngIf="menuLastOption === toolbarState.REPORT"
                      (changeReportBuildOptions)="selectedReportBuildOptions = $event">
  </app-report-options>

  <app-track-options *ngIf="menuLastOption === toolbarState.TRACK"
                     class="track-options"
                     [ngClass]="{'track-height_le_l': (size$ | async).less('l')}">
  </app-track-options>

<!--  <div class="wrapper-build-button_le_l" *ngIf="(size$ | async).less('l')">-->
<!--    <ng-container [ngTemplateOutlet]="buildButton"></ng-container>-->
<!--  </div>-->
</mat-card>
