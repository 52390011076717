import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AppMessageService} from '../../../../../../../common-module/src/lib/app-services/app-message.service';
import {UiSpinnerService} from '../../../../../../../common-module/src/lib/app-services/ui-spinner.service';
import {TripSettings} from '../../../../../../../common-module/src/lib/modelinterfaces/trip-settings.model';
import {UnitService} from '../../../../../../../common-module/src/lib/services/unit.service';
import {UnitDataService} from '../../../../shared/services/unit-data.service';
import {VehicleManagerService} from '../../../../shared/services/vehicle-manager.service';
import {Unit} from "../../../../../../../common-module/src/lib/modelinterfaces/unit.model";

enum Control {
  MIN_PARKING_TIME = 'min-parking-time',
  MIN_SPEED = 'min-movement-speed',
  MIN_TRIP_TIME = 'min-trip-time',
  MIN_DISTANCE = 'min-trip-distance',
  MAX_DURATION = 'max-messages-duration'
}

@Component({
  selector: 'app-trip-form',
  templateUrl: './trip-form.component.html',
  styleUrls: ['./trip-form.component.scss', '../../../../../../../common-module/src/lib/app-styles/dialog-common.scss']
})

export class TripFormComponent implements OnChanges, OnInit, OnDestroy {

  private readonly DEFAULT_MIN_PARKING_SEC = 300;
  private readonly DEFAULT_MAX_MESSAGES_DURATION_SEC = 300;
  private readonly MIN_VALUE_MIN_PARKING_SEC = 60;
  private readonly LIMIT = 32000;

  @Input() settings: TripSettings;
  @Input() unit: Unit;

  public control = Control;
  public editForm: UntypedFormGroup;

  private destroyed = new Subject<boolean>();

  constructor(private appMessageService: AppMessageService,
              private uiSpinnerService: UiSpinnerService,
              private unitService: UnitService,
              private unitDataService: UnitDataService,
              private vehicleManagerService: VehicleManagerService) {
  }

  get formControl(): { [p: string]: AbstractControl } {
    return this.editForm.controls;
  }

  get formValue(): any {
    return this.editForm.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['settings'] && this.settings) {
      this.buildForm();
    }
  }

  ngOnInit(): void {
    this.vehicleManagerService.isCancelForms$
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        isCancel => {
          if (isCancel && this.vehicleManagerService.instantTabSelectedIndex() === 4) {
            this.buildForm();
          }
        }
      )

    this.vehicleManagerService.isSubmitForms$
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        isSubmit => {
          if (isSubmit && this.vehicleManagerService.instantTabSelectedIndex() === 4) {
            this.onSubmit();
          }
        }
      )
  }

  private buildForm(): void {

    this.editForm = new UntypedFormGroup({
      [Control.MIN_PARKING_TIME]: new UntypedFormControl(
        this.settings.minParkingSeconds ? this.settings.minParkingSeconds : this.DEFAULT_MIN_PARKING_SEC,
        [Validators.required, Validators.min(this.MIN_VALUE_MIN_PARKING_SEC), Validators.max(this.LIMIT)]),

      [Control.MIN_SPEED]: new UntypedFormControl(this.settings.minTripDetectionSpeed,
        [Validators.required, Validators.min(0), Validators.max(this.LIMIT)]),

      [Control.MIN_TRIP_TIME]: new UntypedFormControl(this.settings.minTripSeconds,
        [Validators.required, Validators.min(0), Validators.max(this.LIMIT)]),

      [Control.MIN_DISTANCE]: new UntypedFormControl(this.settings.minTripMeters,
        [Validators.required, Validators.min(0), Validators.max(this.LIMIT)]),

      [Control.MAX_DURATION]: new UntypedFormControl(
        this.settings.maxTripMessageTimeout ? this.settings.maxTripMessageTimeout : this.DEFAULT_MAX_MESSAGES_DURATION_SEC,
        [Validators.required, Validators.min(this.DEFAULT_MAX_MESSAGES_DURATION_SEC), Validators.max(this.LIMIT)]),
    });

    let disabled = !!this.unit?.trackerCertificationExpiry;
    if (disabled) {
      this.formControl[Control.MIN_SPEED].disable();
      this.formControl[Control.MIN_TRIP_TIME].disable();
      this.formControl[Control.MIN_DISTANCE].disable();
      this.formControl[Control.MAX_DURATION].disable();
    }

    this.editForm.valueChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.vehicleManagerService.changeForms([this.editForm]);
      });
    this.vehicleManagerService.changeForms([this.editForm]);
  }

  public onSubmit(): void {
    const tripSettings = new TripSettings(
      this.formValue[Control.MAX_DURATION],
      this.formValue[Control.MIN_PARKING_TIME],
      this.formValue[Control.MIN_SPEED],
      this.formValue[Control.MIN_DISTANCE],
      this.formValue[Control.MIN_TRIP_TIME],
      this.settings?.urbanThresholdSpeed ?? 75
    );
    this.uiSpinnerService.show();
    this.unitService.changeTripSettings(this.unit.id, tripSettings)
      .subscribe(
        unit => {
          this.unitDataService.changeUnit(unit);
          this.appMessageService.openSnackBar('message.info.changes-saved');
          this.editForm.markAsPristine();
          this.vehicleManagerService.changeForms([this.editForm]);
          this.uiSpinnerService.stop();
        }
      );
  }

  public onSetDefaultValues(): void {
    this.formControl[Control.MIN_PARKING_TIME].setValue(this.DEFAULT_MIN_PARKING_SEC);
    this.formControl[Control.MIN_SPEED].setValue(0);
    this.formControl[Control.MIN_TRIP_TIME].setValue(0);
    this.formControl[Control.MIN_DISTANCE].setValue(0);
    this.formControl[Control.MAX_DURATION].setValue(this.DEFAULT_MAX_MESSAGES_DURATION_SEC);
    this.editForm.markAsDirty();
    this.vehicleManagerService.changeForms([this.editForm]);
  }

  ngOnDestroy(): void {
    this.destroyed.next(null);
    this.destroyed.complete();
  }
}
