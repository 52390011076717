import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DriverView} from '../modelinterfaces/driver-view.model';
import {Driver} from '../modelinterfaces/driver.model';
import {EnvironmentHelper} from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class DriverService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getById(driverId: number): Observable<DriverView> {
    const url = `${this.environmentHelper.urlV2}/driver/${driverId}`;
    return this.http.get<DriverView>(url).pipe(
      map(obj => DriverView.valueOf(obj))
    );
  }

  public update(driver: Driver): Observable<DriverView> {
    const url = `${this.environmentHelper.urlV2}/driver/${driver.id}`;
    return this.http.put<DriverView>(url, driver).pipe(
      map(obj => DriverView.valueOf(obj))
    );
  }

  public save(driver: Driver): Observable<DriverView> {
    const url = `${this.environmentHelper.urlV2}/driver`;
    return this.http.post<DriverView>(url, driver).pipe(
      map(obj => DriverView.valueOf(obj))
    );
  }

  public delete(driverId: number): Observable<void> {
    const url = `${this.environmentHelper.urlV2}/driver/${driverId}`;
    return this.http.delete<void>(url);
  }

  public getList(): Observable<DriverView[]> {
    const url = `${this.environmentHelper.urlV2}/driver`;
    return this.http.get<DriverView[]>(url).pipe(
      map(obj => DriverView.valuesOf(obj))
    );
  }

  public existByPhone(phoneNumber: string): Observable<boolean> {
    const url = `${this.environmentHelper.urlV2}/driver/existBy`;
    let params = new HttpParams();
    params = params.set('phoneNumber', phoneNumber.toString());
    return this.http.get<boolean>(url, {params: params});
  }

  /**
   * @param names [firstName, lastName, middleName]
   */
  public existByFullName(names: [string, string, string]): Observable<boolean> {
    const url = `${this.environmentHelper.urlV2}/driver/existBy`;
    let params = new HttpParams();
    params = params.set('names', `${names[1] || ''},${names[0] || ''},${names[2] || ''}`);
    return this.http.get<boolean>(url, {params: params});
  }

  public getAvatarList(): Observable<string[]> {
    const url = `${this.environmentHelper.urlV2}/driver/avatar`;
    return this.http.get<string[]>(url);
  }

  public getAvatarRandom(): Observable<string> {
    const url = `${this.environmentHelper.urlV2}/driver/avatar/random`;
    return this.http.get<{ avatarUrl: string }>(url).pipe(map(resp => resp.avatarUrl));
  }
}
