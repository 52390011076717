import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DvrFilters } from '../app-models/dvr-filters.models';
import { DvrFileShort } from '../modelinterfaces/dvr-file-short.model';
import { FilterOperation } from "../modelinterfaces/enums/filter-operation";
import { SpecsFilterBuilder } from "../utils/SpecsFilterBuilder";
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class DvrFileShortService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getList(unitId: number, filters: DvrFilters): Observable<DvrFileShort[]> {
    const url = `${this.environmentHelper.urlDvr}/fileShort`;
    let params = new HttpParams();
    params = params.set('unitIdFilter', SpecsFilterBuilder.build(FilterOperation.EQUALS, unitId));
    Object.keys(filters).forEach(key => {
      if (filters[key].value) {
        params = params.set(filters[key].param, filters[key].paramValue);
      }
    });
    return this.http.get<DvrFileShort[]>(url, {params: params}).pipe(
      map(list => DvrFileShort.valuesOf(list))
    );
  }
}
