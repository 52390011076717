import { ParameterSource } from './parameter-source.model';
import { SensorSource } from './sensor-source.model';

export class SensorCalculatorSource {

  constructor(public parameterToken: string,
              public sensors: SensorSource[],
              public virtual: ParameterSource[],
              public parameters: ParameterSource[],
              public optional: ParameterSource[]) {
  }

  public static valueOf(o: SensorCalculatorSource): SensorCalculatorSource {
    return o ? new SensorCalculatorSource(o.parameterToken, SensorSource.valuesOf(o.sensors), ParameterSource.valuesOf(o.virtual),
      ParameterSource.valuesOf(o.parameters), ParameterSource.valuesOf(o.optional)) : null;
  }
}
