export class CalcOperations {

  public static readonly sensorValue = 'x';

  public static readonly numbers = ['7', '8', '9', '4', '5', '6', '1', '2', '3', '0', '.'];

  public static readonly point = '.';

  public static readonly matOperations = ['+', '-', '*', '/'];

  public static readonly bracketOpen = '(';

  public static readonly bracketClose = ')';

  public static readonly conditions = ['>', '>=', '<', '<=', '=', '!='];

  public static readonly logicOperations = ['&', '|'];

  public static readonly startSensorToken = '{';

  public static readonly endSensorToken = '}';
}
