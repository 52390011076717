import {AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {ViewBreakpointService, ViewSize} from '../../../../../../common-module/src/lib/app-services/view-breakpoint.service';
import { UnitShort } from '../../../../../../common-module/src/lib/modelinterfaces/unit-short.model';
import {UnitDataService} from '../../../shared/services/unit-data.service';
import {UnitAdditionalData} from '../../../../../../common-module/src/lib/modelinterfaces/unit-additional-data.model';
import {SelectedService} from '../../../shared/services/selected.service';

@Component({
  selector: 'app-unit-info-carousel',
  templateUrl: './unit-info-carousel.component.html',
  styleUrls: ['./unit-info-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class UnitInfoCarouselComponent implements OnInit, AfterViewChecked {

  @ViewChild('mainContainer', {static: false}) mainContainer: ElementRef;

  public cardSettings = [
    {isVisible: false, width: 250},
    {isVisible: false, width: 250},
    {isVisible: false, width: 250},
    {isVisible: false, width: 250},
    {isVisible: false, width: 250},
    {isVisible: false, width: 250},
    {isVisible: false, width: 250},
    {isVisible: false, width: 250},
    {isVisible: false, width: 250}];

  public isHovered: boolean;
  public firstCard: number;
  public lastCard: number;
  public lastSelectedUnit$: Observable<UnitShort>;
  public size$: Observable<ViewSize>;
  public unitData$: Observable<UnitAdditionalData>;

  private currentMainContainerWidth = 0;
  private showButtonsTimeout;

  constructor(private cdr: ChangeDetectorRef,
              private selectedService: SelectedService,
              private unitDataService: UnitDataService,
              private viewBreakpointService: ViewBreakpointService) {
  }

  ngOnInit(): void {
    this.lastSelectedUnit$ = this.selectedService.lastSelectedUnit$;
    this.size$ = this.viewBreakpointService.size$;
    this.unitData$ = this.unitDataService.unitData$;
    this.initCards();
  }

  private initCards() {
    let size = 0;
    this.firstCard = 0;
    for (let i = 0; i < this.cardSettings.length; i++) {
      const value = this.cardSettings[i];
      value.isVisible = ((size += value.width) <= this.currentMainContainerWidth + 200);
    }
    this.lastCard = this.cardSettings.length - 1;
    for (let i = this.firstCard; i < this.cardSettings.length; i++) {
      if (!this.cardSettings[i].isVisible) {
        this.lastCard = i - 1;
        break;
      }
    }
    this.cdr.detectChanges();
  }

  ngAfterViewChecked(): void {
    if (this.mainContainer) {
      const width = this.mainContainer.nativeElement.offsetWidth;
      if (width !== this.currentMainContainerWidth) {
        this.currentMainContainerWidth = width;
        this.onContainerChangeSize();
      }
    }
  }

  private onContainerChangeSize(): void {
    this.initCards();
  }

  public showButtons(show: boolean): void {
    if (show) {
      clearTimeout(this.showButtonsTimeout);
      this.isHovered = show;
    } else {
      this.showButtonsTimeout = setTimeout(() => this.isHovered = show, 3000);
    }
  }

  public onWheel($event: WheelEvent): void {
    if ($event.deltaY > 0) {
      this.forward();
    } else {
      this.back();
    }
  }

  public forward(): void {
    if (this.lastCard === this.cardSettings.length - 1) {
      return;
    }
    this.cardSettings[this.firstCard++].isVisible = false;
    this.cardSettings[++this.lastCard].isVisible = true;
  }

  public back(): void {
    if (this.firstCard === 0) {
      return;
    }
    this.cardSettings[--this.firstCard].isVisible = true;
    this.cardSettings[this.lastCard--].isVisible = false;
  }
}
