export class VehicleType {

  constructor(public id: number,
              public name: string,
              public nameTranslated: string) {
  }

  public static valueOf(i: VehicleType): VehicleType {
    return i ? new VehicleType(i.id, i.name, i.nameTranslated) : null;
  }

  public static valuesOf(list: VehicleType[]): VehicleType[] {
    return list ? list.map(o => VehicleType.valueOf(o)) : [];
  }
}
