import {AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Logistic} from "../../../../../../../common-module/src/lib/modelinterfaces/logistic.model";
import {WarehousesMap} from "./warehouses.map";
import {takeUntil} from "rxjs/operators";
import {AbstractMapComponent} from "../abstract-map.component";
import Warehouse = Logistic.Warehouse;
import {WarehouseMapFactory} from "../../../aur-map-creator/map/wrapper/map-wrapper-factory/impl/warehouse-map.factory";

@Component({
  selector: 'app-warehouse-map',
  templateUrl: './warehouse-map.component.html',
  styleUrls: ['./warehouse-map.component.scss']
})
export class WarehouseMapComponent extends AbstractMapComponent implements AfterViewInit, OnChanges {

  @Input() warehouses: Warehouse[] = [];

  @Output() warehouseClick = new EventEmitter<Warehouse>();

  map: WarehousesMap;

  constructor(protected warehouseMapFactory: WarehouseMapFactory) {
    super();
  }

  ngAfterViewInit(): void {
    this.map = this.warehouseMapFactory.create({mapDivId: this.mapDivId, popupDivId: this.popupDivId});
    this.handleWarehouseClicks();
    this.map.updateSize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.warehouses && this.map) {
      this.map.setWarehouses(this.warehouses);
    }
  }

  private handleWarehouseClicks() {
    this.map.$clickedWarehouse.pipe(takeUntil(this.destroy)).subscribe(w => {
      if(w) this.warehouseClick.emit(w);
    });
  }
}
