import Style from "ol/style/Style";

export interface StyleOpts {
}

export abstract class StyleFactory<OPTS extends StyleOpts> {

  private readonly styleCache = new Map<string, Style[]>();

  public create(opts: OPTS): Style[] {
    const key = JSON.stringify(opts);
    if (this.styleCache.has(key)) {
      return this.styleCache.get(key);
    }
    const styles = this.doCreate(opts);
    this.styleCache.set(key, styles);
    return styles;
  }

  protected abstract doCreate(opts: OPTS): Style[];
}
