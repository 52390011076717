import { RawData } from './raw-data.model';
import { Sensor } from './sensor.model';
import { UnitOfMeasure } from './unit-of-measure.model';

export class SensorLastValue extends Sensor {

  constructor(
    public readonly id: number,
    public name: string,
    public type: string,
    public calibrationTable: RawData[],
    public unitOfMeasure: UnitOfMeasure,
    public options: object,
    public visible: boolean,
    public nameTranslated: string,
    public typeTranslated: string,
    public value: string,
    public formulaRaw: string,
    public conditionRaw: string) {
    super(id, name, type, calibrationTable, unitOfMeasure, options, visible, nameTranslated, typeTranslated, formulaRaw, conditionRaw);
  }

  public static valueOf(i: SensorLastValue): SensorLastValue {
    return i ? new SensorLastValue(i.id, i.name, i.type, i.calibrationTable, i.unitOfMeasure,
      i.options, i.visible, i.nameTranslated, i.typeTranslated, i.value, i.formulaRaw, i.conditionRaw) : null;
  }

  public static valuesOf(list: SensorLastValue[]): SensorLastValue[] {
    return list ? list.map(o => SensorLastValue.valueOf(o)) : [];
  }
}
