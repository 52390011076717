export enum MapSettings {
  DEFAULT_LNG = 27.56,
  DEFAULT_LAT = 53.9,
  DEFAULT_ZOOM = 7,
  DIALOG_ZOOM = 11,
  SELECTED_UNIT_ZOOM = 12,
  MIN_ZOOM = 2,
  MAX_ZOOM = 18,
  TRACK_INTERVAL = 1200
}
