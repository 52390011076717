import { MapDetails } from './map-details.model';
import { Notification } from './notification.model';
import { SensorChart } from './sensor-chart.model';

export class NotificationDetails {

  constructor(public notification: Notification,
              public mapDetails: MapDetails,
              public sensorChart: SensorChart) {
  }

  public static valueOf(i: NotificationDetails): NotificationDetails {
    return i ? new NotificationDetails(Notification.valueOf(i.notification), MapDetails.valueOf(i.mapDetails),
      SensorChart.valueOf(i.sensorChart)) : null;
  }
}
