import {Component, OnInit} from '@angular/core';
import {ViewLanguageService} from "../../app-services/view-language.service";
import {SelectLanguageComponent} from "../select-language/select-language.component";

/**
 * Отображает Select для выбора языка, а также применяет сделанные изменения на уровне интерфейса
 */
@Component({
  selector: 'cmn-change-language',
  standalone: true,
  templateUrl: './change-language.component.html',
  imports: [
    SelectLanguageComponent
  ],
  styleUrls: ['./change-language.component.scss']
})
export class ChangeLanguageComponent implements OnInit {

  current: string;

  constructor(private viewLanguageService: ViewLanguageService) {
  }

  ngOnInit() {
    this.current = this.viewLanguageService.get();
  }

  onLanguageChange(lang: string) {
    if (this.viewLanguageService.set(lang)) {
      window.location.reload();
    }
  }
}
