import {DragDropModule} from '@angular/cdk/drag-drop';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgApiService, VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgStreamingModule} from '@videogular/ngx-videogular/streaming';
import {AppCommonModule} from '../../../../../../common-module/src/lib/app-common.module';
import {SharedModule} from "../../../shared/shared.module";
import {FilterVideoDialogComponent} from "./filter-video-dialog/filter-video-dialog.component";
import {FilterVideoComponent} from "./filter-video/filter-video.component";
import {VideoPlayerComponent} from './video-player/video-player.component';
import {MatIconButton} from "@angular/material/button";
import {FileSizePipe} from "../../../../../../common-module/src/lib/pipes/file-size.pipe";
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {MatTooltipModule} from "@angular/material/tooltip";
import {CapitalizePipe} from "../../../../../../common-module/src/lib/pipes/capitalize.pipe";
import {
  TimestampTimezoneConvertPipe
} from "../../../../../../common-module/src/lib/pipes/timestamp-timezone-convert.pipe";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatListModule} from "@angular/material/list";
import {FiltersModule} from "../../../../../../common-module/src/lib/app-components/filters/filters.module";
import {
  DateRangePickerComponent
} from "../../../../../../common-module/src/lib/app-components/date-range-picker/date-range-picker.component";

@NgModule({
  declarations: [
    VideoPlayerComponent,
    FilterVideoDialogComponent,
    FilterVideoComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    SharedModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    VgStreamingModule,
    DragDropModule,
    MatIconButton,
    FileSizePipe,
    MatIconModule,
    TranslateModule,
    MatTooltipModule,
    CapitalizePipe,
    TimestampTimezoneConvertPipe,
    MatProgressSpinnerModule,
    MatListModule,
    FiltersModule,
    DateRangePickerComponent
  ],
  providers: [
    VgApiService,
  ],
  bootstrap: [],
  exports: [
    VideoPlayerComponent,
    FilterVideoDialogComponent,
    FilterVideoComponent
  ]
})

export class VideoSharedModule {
}
