<app-dialog [title]="'titles.highlighting-sensor-values' | translate ">

  <ng-container appDialogContent>
    <div class="box-form">
      <form [formGroup]="formHighlightArea" *ngIf="formHighlightArea">
        <div [formArrayName]="FORM_ARRAY_NAME" class="form-element">
          <div *ngFor="let control of formArray.controls; let i = index"
               [formGroupName]="i">
            <div class="sensor-group">
              <mat-form-field *ngIf="!control.get(controls.FROM).disabled">
                <mat-label>{{ 'term.sensor' | translate }}</mat-label>
                <mat-select [formControlName]="controls.SENSOR_NAME">
                  <mat-option *ngFor="let sensor of data.sensors" [value]="sensor">
                    {{ sensor }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="control.get(controls.SENSOR_NAME).errors?.required">
                  {{ 'message.error.can-not-be-empty' | translate }}
                </mat-error>
              </mat-form-field>

              <mat-form-field *ngIf="control.get(controls.FROM).disabled">
                <mat-label>{{ 'term.sensor' | translate }}</mat-label>
                <mat-select [formControlName]="controls.DISABLED_SENSOR_NAME">
                  <mat-option [value]="control.get(controls.DISABLED_SENSOR_NAME).value">
                    {{ control.get(controls.DISABLED_SENSOR_NAME).value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{ 'preposition.from-a' | translate }}</mat-label>
                <input matInput
                       type="number"
                       [formControlName]="controls.FROM"/>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ 'preposition.to' | translate }}</mat-label>
                <input matInput
                       type="number"
                       [formControlName]="controls.TO"/>
              </mat-form-field>

              <button mat-icon-button
                      class="delete-button"
                      (click)="onDelete(i)"
                      matTooltip="{{ 'action.delete' | translate }}">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
          <div class="dialog-box-buttons left">
            <button class="button major"
                    mat-icon-button
                    (click)="onAdd()"
                    matTooltip="{{ 'action.add' | translate }}">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
      </form>
    </div>
  </ng-container>

  <ng-container appDialogActions>
    <app-dialog-actions-cancel-save (save)="onSubmit()"
                                    [saveDisable]="!formHighlightArea.valid || !formHighlightArea.dirty">
    </app-dialog-actions-cancel-save>
  </ng-container>

</app-dialog>




