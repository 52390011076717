import {DragDropModule} from '@angular/cdk/drag-drop';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgApiService, VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgStreamingModule} from '@videogular/ngx-videogular/streaming';
import {AppCommonModule} from '../../../../../../common-module/src/lib/app-common.module';
import {SharedModule} from "../../../shared/shared.module";
import {FilterVideoDialogComponent} from "./filter-video-dialog/filter-video-dialog.component";
import {FilterVideoComponent} from "./filter-video/filter-video.component";
import {VideoPlayerComponent} from './video-player/video-player.component';

@NgModule({
  declarations: [
    VideoPlayerComponent,
    FilterVideoDialogComponent,
    FilterVideoComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    SharedModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    VgStreamingModule,
    DragDropModule,
  ],
  providers: [
    VgApiService,
  ],
  bootstrap: [],
  exports: [
    VideoPlayerComponent,
    FilterVideoDialogComponent,
    FilterVideoComponent
  ]
})

export class VideoSharedModule {
}
