import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectionListChange } from '@angular/material/list';
import { Subscription } from 'rxjs';
import { AppMessageService } from '../../../../../../common-module/src/lib/app-services/app-message.service';
import { DeviceService } from '../../../../../../common-module/src/lib/app-services/device.service';
import { UiSpinnerService } from '../../../../../../common-module/src/lib/app-services/ui-spinner.service';
import { DeviceModel } from '../../../../../../common-module/src/lib/modelinterfaces/device-model.model';
import { DeviceModelService } from "../../../../../../common-module/src/lib/services/device-model.service";

@Component({
  selector: 'app-model-expansion-list',
  templateUrl: './model-expansion-list.component.html',
  styleUrls: ['./model-expansion-list.component.scss']
})

export class ModelExpansionListComponent implements OnChanges, OnInit, OnDestroy {

  @Input() filteredModelIdList: number[] = [];
  @Input() userId: number = null;

  @Output() selectedModelIdListChange = new EventEmitter<number[]>();

  public modelBrandMap: Map<string, DeviceModel[]> = new Map();
  public brandNameList: string[] = [];
  public selectedModelIdList: number[] = [];

  private subscription: Subscription;

  constructor(private appMessageService: AppMessageService,
              private deviceService: DeviceService,
              private deviceModelService: DeviceModelService,
              private uiSpinnerService: UiSpinnerService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filteredModelIdList'] && this.filteredModelIdList) {
      this.selectedModelIdList = this.filteredModelIdList;
    }
  }

  ngOnInit(): void {
    if (this.deviceService.instantModels()) {
      this.modelBrandMap = DeviceModel.distributeModelsByBrands(this.deviceService.instantModels());
      this.setBrandNameList(this.modelBrandMap);
    } else {
      this.uiSpinnerService.show();
      this.deviceService.initLoadModels(this.userId);
      this.subscription = this.deviceService.models$.subscribe(models => {
          this.modelBrandMap = DeviceModel.distributeModelsByBrands(models);
          this.setBrandNameList(this.modelBrandMap);
          this.uiSpinnerService.stop();
        }
      );
    }
  }

  private setBrandNameList(modelBrandMap: Map<string, DeviceModel[]>): void {
    this.brandNameList = Array.from(modelBrandMap.keys());
  }

  public isSelectedModels(modelList: DeviceModel[]): boolean {
    return modelList.some(model => this.isSelectedModel(model.id));
  }

  public countSelectedModels(modelList: DeviceModel[], isSomeSelect: boolean = false): boolean {
    const filteredSelectedModelIdList =
      this.selectedModelIdList.filter(modelId => modelList.some(model => model.id === modelId));
    return isSomeSelect ?
      filteredSelectedModelIdList.length > 0 && modelList.length !== filteredSelectedModelIdList.length :
      modelList.length === filteredSelectedModelIdList.length;
  }

  public onToggleSelectAllModelsByBrand(checkbox: MatCheckboxChange, modelList: DeviceModel[]): void {
    const modelIdListByBrand = modelList.map(model => model.id);
    const filteredSelectedModelIdList = this.selectedModelIdList.filter(modelId => !modelIdListByBrand.includes(modelId));
    checkbox.checked ?
      this.changeSelectedModelIdList(filteredSelectedModelIdList.concat(modelIdListByBrand)) :
      this.changeSelectedModelIdList(filteredSelectedModelIdList);
  }

  private changeSelectedModelIdList(list: number[]): void {
    this.selectedModelIdList = list;
    this.selectedModelIdListChange.next(list);
  }

  public comparisonId = (option: any, value: any): boolean => {
    if (!option || !value) {
      return false;
    }
    return option.id === value.id;
  };

  public onChangeSelectedModelIdList(selection: MatSelectionListChange): void {
    selection.options[0].selected ? this.selectedModelIdList.push((selection.options[0].value as DeviceModel).id) :
      this.changeSelectedModelIdList(this.selectedModelIdList.filter(id => id !== (selection.options[0].value as DeviceModel).id));
  }

  public isSelectedModel(modelId: number): boolean {
    return this.selectedModelIdList.includes(modelId);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
