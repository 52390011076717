import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {DialogData} from "./dialog-data.model";
import {DialogResult, DialogResultData} from "./dialog-result.model";
import {ComponentType} from "@angular/cdk/overlay";

export namespace DialogOpener {

  export class Base<INPUT> {

    constructor(protected matDialog: MatDialog,
                protected dialogComponent: ComponentType<{ data: INPUT }>) {
    }

    public open(data: DialogData<INPUT>): Observable<DialogResult> {
      return this.matDialog
        .open(this.dialogComponent, this.config(data))
        .afterClosed();
    }

    protected config(data: DialogData<INPUT>): MatDialogConfig {
      return {disableClose: true, data: data.data}
    }
  }

  export class Outputable<INPUT, OUTPUT> extends Base<INPUT> {

    constructor(protected matDialog: MatDialog,
                protected dialogComponent: ComponentType<{ data: INPUT }>) {
      super(matDialog, dialogComponent);
    }

    public open(data: DialogData<INPUT>): Observable<DialogResultData<OUTPUT>> {
      return this.matDialog
        .open(this.dialogComponent, this.config(data))
        .afterClosed();
    }
  }
}
