import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Geofence } from '../modelinterfaces/geofence.model';
import { HttpParamsUtil } from '../utils/http-params-util';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class GeofenceService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getList(): Observable<Geofence[]> {
    const url = `${this.environmentHelper.urlV2}/geofence`;
    return this.http.get<Geofence[]>(url).pipe(
      map(list => Geofence.valuesOf(list))
    );
  }

  public update(g: Geofence): Observable<Geofence> {
    const url = `${this.environmentHelper.urlV2}/geofence/${g.id}`;
    return this.http.put<Geofence>(url, g).pipe(
      map(obj => Geofence.valueOf(obj))
    );
  }

  public save(g: Geofence): Observable<Geofence> {
    const url = `${this.environmentHelper.urlV2}/geofence`;
    return this.http.post<Geofence>(url, g).pipe(
      map(obj => Geofence.valueOf(obj))
    );
  }

  public isExist(name: string): Observable<boolean> {
    const url = `${this.environmentHelper.urlV2}/geofence/existBy`;
    return this.http.get<boolean>(url, {params: HttpParamsUtil.createByName(name)});
  }

  public delete(id: number): Observable<{}> {
    const url = `${this.environmentHelper.urlV2}/geofence/${id}`;
    return this.http.delete<{}>(url);
  }
}
