import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Feature from 'ol/Feature';
import {LayerName} from '../constants/enums/layer-name';
import {ZIndexLayer} from '../constants/z-index';
import {OlStyle} from './OlStyle';
import OlMap from "ol/Map";
import {extend, Extent} from "ol/extent";

export class OlVectorLayer {

  public static createVectorLayerWithFeatures(feature: Feature | Feature[], layerName: LayerName | string): VectorLayer {
    const featureList = feature instanceof Array ? feature : [feature];
    const vectorLayer = new VectorLayer({
      source: new VectorSource({features: featureList})
    });
    vectorLayer.set('name', layerName);
    return vectorLayer;
  }

  public static createLayerOnMap(map: OlMap, layerName: LayerName | string, feature?: Feature | Feature[]) {
    const layer = this.createVectorLayerWithFeatures(feature ? feature : [], layerName);
    map.addLayer(layer);
    return layer;
  }

  public static createMeasuringVectorLayer(source: VectorSource): VectorLayer {
    const vectorLayer = new VectorLayer({
      source: source,
      style: OlStyle.createForMeasuringLayer,
      zIndex: ZIndexLayer.MEASURE
    });
    vectorLayer.set('name', LayerName.MEASURING);
    return vectorLayer;
  }
}
