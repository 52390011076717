<app-dialog [title]="'term.public-links' | translate">

  <ng-container appDialogContent>

    <p *ngIf="!dataSource && !loading" class="message">
      <span class="text">{{ 'message.info.create-public-link' | translate }}</span>
      <mat-icon class="icon">share</mat-icon>
    </p>

    <div class="box-table" *ngIf="dataSource">
      <table mat-table [dataSource]="dataSource" [multiTemplateDataRows]="true">

        <ng-container [matColumnDef]="displayedColumns[0]">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let settings"
              (click)="toggleShowUnits(settings.id)"
              matTooltip="{{ 'term.unit-list' | translate }}">
            <mat-icon *ngIf="settings.id !== currentId">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="settings.id === currentId">keyboard_arrow_up</mat-icon>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="displayedColumns[1]">
          <th mat-header-cell *matHeaderCellDef>{{ displayedColumns[1] | translate }}</th>
          <td [ngClass]="{'no-pointer': isExpiredLink(asSettings(settings).expirationDate)}"
              class="link"
              mat-cell
              *matCellDef="let settings"
              (click)="onFollowLink(settings)">
            {{ asSettings(settings).url }}
          </td>
        </ng-container>

        <ng-container [matColumnDef]="displayedColumns[2]">
          <th mat-header-cell *matHeaderCellDef>{{ displayedColumns[2] | translate }}</th>
          <td class="no-pointer" mat-cell *matCellDef="let settings">
            {{ asSettings(settings).expirationDate | utcDateTz: (authUser$ | async)?.timeZone.name }}
          </td>
        </ng-container>

        <ng-container [matColumnDef]="displayedColumns[3]">
          <th mat-header-cell *matHeaderCellDef>{{ displayedColumns[3] | translate }}</th>
          <td class="no-pointer" mat-cell *matCellDef="let settings">
            {{ asSettings(settings).user.name }}
          </td>
        </ng-container>

        <ng-container [matColumnDef]="displayedColumns[4]">
          <th mat-header-cell *matHeaderCellDef></th>
          <td class="no-pointer" mat-cell *matCellDef="let settings">
            <button mat-icon-button
                    class="app-icon-button primary"
                    (click)="copyToClipboard(asSettings(settings).url)"
                    matTooltip="{{ 'action.copy' | translate }}">
              <mat-icon>content_paste</mat-icon>
            </button>
            <button mat-icon-button
                    class="app-icon-button red"
                    (click)="onDeleteLink(asSettings(settings).id)"
                    matTooltip="{{ 'action.delete' | translate }}">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let settings" [attr.colspan]="displayedColumns.length">
            <div class="detail-info"
                 [@detailExpand]="settings.id === currentId ? 'expanded' : 'collapsed'">
              <mat-list class="list">
                <mat-list-item class="item" *ngFor="let unit of asSettings(settings).units">
                  {{ unit.name }}
                </mat-list-item>
              </mat-list>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            class="row"
            [ngClass]="{'expired-link': isExpiredLink(asSettings(row).expirationDate)}"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
      </table>
    </div>

  </ng-container>


</app-dialog>


