export class GpsOdometerSettings {

  constructor(public mileageFactor: number,
              public mileageOffset: number) {
  }

  public static valueOf(i: GpsOdometerSettings): GpsOdometerSettings {
    return i ? new GpsOdometerSettings(i.mileageFactor, i.mileageOffset) : null;
  }
}
