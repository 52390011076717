<app-icon-button-primary [matMenuTriggerFor]="menuPrint"
                         icon="print"
                         [showSpinner]="showSpinner">
</app-icon-button-primary>

<mat-menu #menuPrint="matMenu">
  <button mat-menu-item class="icon-button" (click)="print(orientation.PORTRAIT)">
    <mat-icon>insert_drive_file</mat-icon>
  </button>

  <button mat-menu-item class="icon-button" (click)="print(orientation.LANDSCAPE)">
    <mat-icon>note</mat-icon>
  </button>
</mat-menu>
