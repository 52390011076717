import {Feature} from "ol";
import LineString from "ol/geom/LineString";
import {AbstractFeatureWrapper} from "../core/app/feature-wrapper/abstract.feature-wrapper";
import {ArrowStyleFactory, OlArrowOpts} from "./arrow-style.factory";

export class ArrowFeatureWrapper extends AbstractFeatureWrapper<any, OlArrowOpts> {

  constructor(feature: Feature<LineString>,
              styleFactory: ArrowStyleFactory,
              sourceOpts: OlArrowOpts) {
    super(feature, styleFactory, sourceOpts);
  }
}
