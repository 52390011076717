<app-dialog [title]="!data || data.sensors.length === 0? 'Выберите датчик': 'Выберите диапазон'">

  <ng-container appDialogContent>

    <div>
      <form [formGroup]="form">
        <div formArrayName="sensors">
          <!-- Перебор сенсоров -->
          <div *ngFor="let sensor of getSensors().controls; let sensorIndex = index" [formGroupName]="sensorIndex"
               class="sensors-container">

            <mat-form-field class="select-sensor" subscriptSizing="dynamic">
              <mat-label>Выберите датчик</mat-label>
              <mat-select [formControlName]="controls.SENSOR" [compareWith]="comparisonId">
                <mat-option *ngFor="let sensorOption of data.sensors" [value]="sensorOption">
                  <app-sensor-last-value-item [sensorLastValue]="sensorOption">
                  </app-sensor-last-value-item>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Диапазоны для каждого сенсора -->
            <div formArrayName="ranges" class="ranges-container">
              <div *ngFor="let range of getRangesForSensor(sensorIndex).controls; let rangeIndex = index"
                   [formGroupName]="rangeIndex">

                <div class="range-item">
                  <div>
                    {{ rangeIndex + 1 }}.
                  </div>

                  <div class="palette-item">
                    <app-color-picker-input [label]="'Цвет диапазона'"
                                            formControlName="color"
                                            subscriptSizing="dynamic">
                    </app-color-picker-input>
                  </div>

                  <mat-form-field subscriptSizing="dynamic">
                    <mat-label>Начало диапазона</mat-label>
                    <input matInput type="number" formControlName="min">
                  </mat-form-field>

                  <mat-form-field subscriptSizing="dynamic">
                    <mat-label>Конец диапазона</mat-label>
                    <input matInput type="number" formControlName="max">
                  </mat-form-field>

                  <app-icon-button-clear (clear)="resetRow(sensorIndex, rangeIndex)"
                                         [matTooltip]="'action.clear' | translate">
                  </app-icon-button-clear>
                </div>
              </div>
              <div class="error-ranges">
                <p>
                  @if (getRangesForSensor(sensorIndex).hasError('rangesOverlap')) {
                    Диапазоны не должны пересекаться!
                  }
                </p>
              </div>
            </div>
            <mat-divider></mat-divider>
          </div>
        </div>
      </form>

    </div>
  </ng-container>

  <ng-container appDialogActions>
    <app-button-cancel>
    </app-button-cancel>
    @if (data.sensors.length > 0) {
      <app-button-primary [text]="'message.tooltip.show' | translate"
                          (action)="onSubmit()"
                          [disabled]="form.invalid">
      </app-button-primary>
    }
  </ng-container>
</app-dialog>
