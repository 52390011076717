<div class="wrapper-time-ranges">
  <div class="day">
    <div class="time-header empty-cell"></div>

    <div class="time-header" *ngFor="let cell of timeTable.rows[0]">{{ cell.timeFrom | timeByHalfHour }}</div>
  </div>

  <div class="day" *ngFor="let day of timeTable.rows">
    <div class="day-header">{{ timeTable.getDayName(day[0]) | capitalize }}</div>

    <div *ngFor="let cell of day"
         class="cell"
         matTooltip="{{ cell.timeFrom | timeByHalfHour }}"
         [ngClass]="{'cell-selected': cell.isSelected}"
         (pointerdown)="onPointerDown($event, cell)"
         (pointerover)="onPointerOver($event, cell)"
         (pointerup)="onPointerUp($event)"
    >
    </div>
  </div>
</div>
