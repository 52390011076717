import {Component} from '@angular/core';
import {SelectedService} from "../../../shared/services/selected.service";
import {AppDialogModule} from "../../../../../../common-module/src/lib/dialog/dialog/app-dialog.module";
import {AsyncPipe} from "@angular/common";
import {UnitInfoCarouselModule} from "../../main/unit-info-carousel/unit-info-carousel.module";

@Component({
  selector: 'app-unit-info-carousel-dialog',
  standalone: true,
  imports: [
    AppDialogModule,
    AsyncPipe,
    UnitInfoCarouselModule,

  ],
  templateUrl: './unit-info-carousel-dialog.component.html',
  styleUrl: './unit-info-carousel-dialog.component.scss'
})
export class UnitInfoCarouselDialogComponent  {

  constructor(public selectedService: SelectedService) {
  }


}
