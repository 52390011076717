<div class="box" (mouseenter)="showButtons(true)" (mouseleave)="showButtons(false)">
  <div *ngIf="(size$ | async).more('m')">
    <button class="arrows"
            [disabled]="!firstCard"
            (click)="back()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <button class="arrows arrow-right"
            [disabled]="lastCard === cardSettings.length - 1"
            (click)="forward()">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
    <div class="points"
         *ngIf="isHovered && (lastCard - firstCard) < cardSettings.length - 1"
         (wheel)="onWheel($event)">
      <span *ngFor="let item of cardSettings">
      <mat-icon [ngClass]="{'visible-point': item.isVisible}">lens</mat-icon>
    </span>
    </div>
  </div>
  <div class="container"
       #mainContainer
       [ngClass]="{'first-card': firstCard && (size$ | async).more('m'),
                   'container_le_l': (size$ | async).less('l')}">
    <div class="loading" *ngIf="!(unitData$ | async)?.unit; else cards">
      <span class="title">{{ (lastSelectedUnit$ | async).name }} </span>
      <span class="text">{{ 'term.loading' | translate }}</span>
      <span class="text">{{ '... ' }} </span>
      <mat-icon class="spinner">
        <mat-spinner diameter="20"></mat-spinner>
      </mat-icon>
    </div>

    <ng-template #cards>
      <app-unit-info-card
        *ngIf="cardSettings[0].isVisible || (size$ | async).less('l')"
        [width]="cardSettings[0].width"
        [unitData]="(unitData$ | async)"
      ></app-unit-info-card>

      <app-address-card
        *ngIf="cardSettings[1].isVisible || (size$ | async).less('l')"
        [width]="cardSettings[1].width"
        [unitData]="(unitData$ | async)"
      ></app-address-card>

      <app-device-status-card
        *ngIf="cardSettings[2].isVisible || (size$ | async).less('l')"
        [width]="cardSettings[2].width"
        [unitData]="(unitData$ | async)"
      ></app-device-status-card>

      <app-unit-status-card
        *ngIf="cardSettings[3].isVisible || (size$ | async).less('l')"
        [width]="cardSettings[3].width"
        [unitData]="(unitData$ | async)"
      ></app-unit-status-card>

      <app-vehicle-card
        *ngIf="cardSettings[4].isVisible || (size$ | async).less('l')"
        [width]="cardSettings[4].width"
        [unitData]="(unitData$ | async)"
      ></app-vehicle-card>

      <app-expire-info-card
        *ngIf="cardSettings[5].isVisible || (size$ | async).less('l')"
        [width]="cardSettings[5].width"
        [unitData]="(unitData$ | async)"
      ></app-expire-info-card>

      <app-sensors-card
        *ngIf="cardSettings[6].isVisible || (size$ | async).less('l')"
        [width]="cardSettings[6].width"
        [unitData]="(unitData$ | async)"
      ></app-sensors-card>

      <app-driver-card
        *ngIf="cardSettings[7].isVisible || (size$ | async).less('l')"
        [width]="cardSettings[7].width"
        [unitData]="(unitData$ | async)"
      ></app-driver-card>

      <app-info-card
        *ngIf="cardSettings[8].isVisible || (size$ | async).less('l')"
        [width]="cardSettings[8].width"
      ></app-info-card>
    </ng-template>
  </div>
</div>
