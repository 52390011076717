import {Component, OnInit} from '@angular/core';
import {SelectedService} from "../../../shared/services/selected.service";

@Component({
  selector: 'app-unit-info-carousel-dialog',
  templateUrl: './unit-info-carousel-dialog.component.html',
  styleUrl: './unit-info-carousel-dialog.component.scss'
})
export class UnitInfoCarouselDialogComponent  {

  constructor(public selectedService: SelectedService) {
  }


}
