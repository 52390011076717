import {ReportSettingsService} from "../../../../../common-module/src/lib/services/report-settings.service";
import { ReportSettings} from "../../../../../common-module/src/lib/modelinterfaces/report-settings.model";
import {Observable} from "rxjs";
import {take, tap} from "rxjs/operators";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ReportSettingsStorageService {

  private storage = new Map<string, ReportSettings>();

  constructor(private service: ReportSettingsService) {
  }

  public init(): Observable<ReportSettings[]> {
    return this.service.getAllReportSettings()
      .pipe(
        tap(settings => settings.forEach(s => {
          this.storage.set(s.name, s)
        })),
        tap(settings => console.log('INITED: ', this.storage)),
        take(1)
      );
  }

  public get(reportName: string): ReportSettings {
    return this.storage.get(reportName)
  }

  public save(reportSettings: ReportSettings): Observable<ReportSettings> {
    return this.service.updateReportSettings(reportSettings)
      .pipe(tap(settings => this.storage.set(settings.name, settings)));
  }

  public getDisplayColumns(reportName: string): string[] {
    return this.get(reportName).columns
      .filter(s => s.visible)
      .map(s => s.name);
  }
}
