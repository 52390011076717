import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {Observable, of} from "rxjs";
import {AddressFormat, GeocoderService} from "../../../../../../common-module/src/lib/services/geocoder.service";
import {UntypedFormControl} from "@angular/forms";
import {debounceTime, filter, map, switchMap, tap} from "rxjs/operators";
import {
  Accuracy,
  ReverseGeocodedAddress
} from "../../../../../../common-module/src/lib/modelinterfaces/geolocated-address.model";
import {Address} from "../../../../../../common-module/src/lib/modelinterfaces/address.model";
import {AccuracyAddress} from "../../../../../../common-module/src/lib/modelinterfaces/accuracy-address.model";

/**
 * The AddressCoordinateSearchComponent offers a reverse geocoding input mechanism.
 * Users can type an address, and the component provides auto-completed suggestions based on the input.
 */
@Component({
  selector: 'app-address-coordinate-search',
  templateUrl: './address-coordinate-search.component.html',
  styleUrls: ['./address-coordinate-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressCoordinateSearchComponent implements OnInit, OnChanges {

  /** Current value of the address to be displayed. */
  @Input() value: Address;

  /** Label to be displayed above the search input. */
  @Input() label: string;

  /** Format in which addresses should be displayed: 'FULL' or 'SHORT'. */
  @Input() addressFormat: 'FULL' | 'SHORT' = 'SHORT';

  /** Event emitted when the search input value changes. */
  @Output() inputChange = new EventEmitter<string>();

  /**
   * Event emitted when an address is selected from the autocomplete suggestions.
   * If the autocomplete closes without a selection, it returns the last known value
   * with an accuracy set to 'CHANGING' and the name set to the current input value.
   */
  @Output() addressChange = new EventEmitter<ReverseGeocodedAddress>();

  /** Indicates if a search request is pending. */
  pending = false;

  /** Observable containing the list of address suggestions based on user input. */
  $searchAddressResult: Observable<ReverseGeocodedAddress[]>;
  searchControl: UntypedFormControl = new UntypedFormControl();

  constructor(private readonly addressService: GeocoderService,
              private readonly cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.setupSearchControlListeners();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      // current value как Event когда пользователь закрывеат autocomplete без выбора отдельного адреса,
      // в этом случае я создаю ReverseGeocodingAddress из последнего значения с точностью CHANGING
      if (!(changes.value.currentValue instanceof Event)) {
        this.setSearchControlValue();
      } else {
        this.restorePreviousValueAndNotify(changes.value.previousValue);
      }
    }
  }

  private setupSearchControlListeners() {
    this.searchControl.valueChanges.pipe(
      debounceTime(500),
      map(v => {
        if (v instanceof AccuracyAddress) return v.name; else return v;
      }),
      filter(value => typeof value === 'string' && value.length >= 3),
      tap(() => this.pending = true),
      tap(value => this.inputChange.emit(value)),
      switchMap(value => this.addressService.reverse(value, AddressFormat.FULL))
    ).subscribe(
      results => {
        this.pending = false;
        this.$searchAddressResult = of(results);
        this.cdr.detectChanges();
      },
      () => this.pending = false
    );
  }

  /**
   * Restores the previous value of the search input and notifies the parent component.
   * @param prevAddress Previous address value before changes.
   */
  private restorePreviousValueAndNotify(prevAddress: Address) {
    this.value = prevAddress;
    this.value.name = this.searchControl.value;
    this.setSearchControlValue();
    this.onSelectAutocompleteAddress(this.manuallyCreateReverseGeocodedAddress());
  }

  /**
   * Creates a new ReverseGeocodedAddress instance with the current input values.
   */
  private manuallyCreateReverseGeocodedAddress() {
    return new ReverseGeocodedAddress(this.value.latitude, this.value.longitude, this.value.name, Accuracy.CHANGING, this.value.name);
  }

  private setSearchControlValue() {
    this.searchControl.setValue(this.value);
  }

  public onSelectAutocompleteAddress(address: ReverseGeocodedAddress) {
    this.addressChange.emit(address);
  }

  /**
   * Display function for the autocomplete suggestions.
   * @param value Address suggestion.
   * @returns Name of the suggested address.
   */
  displayFn(value: ReverseGeocodedAddress): string {
    return value?.name;
  }
}
