import Cluster from 'ol/source/Cluster';
import VectorSource from 'ol/source/Vector';
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster';
import Style from 'ol/style/Style';
import Feature from 'ol/Feature';
import Collection from 'ol/Collection';
import {OlStyle} from './OlStyle';
import OlMap from "ol/Map";

export class OlAnimatedClusterLayer {

  public static buildUnitsCluster(features: Feature[], layerName: string): AnimatedCluster {
    return new AnimatedCluster({
      name: layerName,
      source: this.getClusterSource(features),
      style: this.getUnitsClusterStyle,
      animationDuration: 80,
    });
  }

  public static createSolutionStageLayer(map: OlMap, layerName: string, features: Feature[]): AnimatedCluster {
    const layer = this.buildSolutionStage(features, layerName);
    map.addLayer(layer);
    return layer;
  }

  public static buildSolutionStage(features: Feature[], layerName: string): AnimatedCluster {
    return new AnimatedCluster({
      name: layerName,
      source: this.getClusterSource(features),
      style: this.getSolutionStageStyle,
      animationDuration: 80,
    });
  }

  private static getSolutionStageStyle(feature): Style[] {
    const size = feature.get('features').length;
    if (size === 1) {
      return feature.values_.features[0].style_;
    }
    return [OlStyle.createForSolutionStageCluster(size)];
  }

  public static buildParkingCluster(features: Feature[], layerName: string): AnimatedCluster {
    return new AnimatedCluster({
      name: layerName,
      source: this.getClusterSource(features),
      style: this.getParkingClusterStyle,
      animationDuration: 80
    });
  }

  public static buildVideoCluster(features: Feature[], layerName: string): AnimatedCluster {
    return new AnimatedCluster({
      name: layerName,
      source: this.getClusterSource(features),
      style: this.getVideoClusterStyle,
      animationDuration: 80
    });
  }

  private static getClusterSource(features: Feature[] | Collection<Feature>): Cluster {
    return new Cluster({
      distance: 30,
      source: new VectorSource({
        features: features
      })
    });
  }

  private static getUnitsClusterStyle(feature): Style[] {
    const size = feature.get('features').length;
    if (size === 1) {
      return feature.values_.features[0].style_;
    }
    return [OlStyle.createForUnitsCluster(size)];
  }

  private static getParkingClusterStyle(feature): Style[] {
    const size = feature.get('features').length;
    if (size === 1) {
      return feature.values_.features[0].style_;
    }
    return [OlStyle.createForParkingCluster(size)];
  }

  private static getVideoClusterStyle(feature): Style[] {
    const size = feature.get('features').length;
    if (size === 1) {
      return feature.values_.features[0].style_;
    }
    return [OlStyle.createForVideoCluster(size)];
  }
}
