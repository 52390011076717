import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['../../../../../../../common-module/src/lib/app-styles/dialog-common.scss']
})

export class ConfirmDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { isValid: boolean },
              private dialogRef: MatDialogRef<ConfirmDialogComponent>) {
  }

  ngOnInit(): void {
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onNotSave(): void {
    this.dialogRef.close({'isSave': false});
  }

  public onSubmit(): void {
    this.dialogRef.close({'isSave': true});
  }
}
