import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import LineString from 'ol/geom/LineString';
import {fromLonLat} from 'ol/proj';
import Style from 'ol/style/Style';
import Point from 'ol/geom/Point';
import {Layer} from 'ol/layer';
import VectorLayer from 'ol/layer/Vector';
import Feature from 'ol/Feature';
import {Coordinate} from 'ol/coordinate';
import {Geometry} from 'ol/geom';
import { DvrFileStatus } from "../../../../../common-module/src/lib/modelinterfaces/enums/dvr-file-status";
import { Sensor } from '../../../../../common-module/src/lib/modelinterfaces/sensor.model';
import {OlCoordinate} from '../map-utils/OlCoordinate';
import {OlFeature} from '../map-utils/OlFeature';
import {OlAnimatedClusterLayer} from '../map-utils/OlAnimatedClusterLayer';
import {OlVectorLayer} from '../map-utils/OlVectorLayer';
import {DurationConverter} from '../../../../../common-module/src/lib/utils/duration-converter';
import {DateConverter} from '../../../../../common-module/src/lib/utils/date-converter';
import {SensorDataListWithTripIndex, Track} from '../../../../../common-module/src/lib/modelinterfaces/track.model';
import {Parking} from '../../../../../common-module/src/lib/modelinterfaces/parking.model';
import {Trip} from '../../../../../common-module/src/lib/modelinterfaces/trip.model';
import {SensorData} from '../../../../../common-module/src/lib/modelinterfaces/sensor-data.model';
import {ZIndexLayer} from '../constants/z-index';
import {UrlImage} from '../constants/url-image';
import {AppColor} from '../../../../../common-module/src/lib/app-enums/app-color';
import {LayerName} from '../constants/enums/layer-name';
import {SensorUtils} from '../../../../../common-module/src/lib/utils/sensor-utils';
import {TrackOptions} from './track-layer.service';
import {Source} from 'ol/source';
import {SensorName} from '../../../../../common-module/src/lib/modelinterfaces/enums/sensor-name';
import {DvrFileShort} from '../../../../../common-module/src/lib/modelinterfaces/dvr-file-short.model';
import {OlStyle} from '../map-utils/OlStyle';
import {DayjsUtil} from "../../../../../common-module/src/lib/dayjs.util";

interface OverSpeedSensorData {
  sensorDataList: SensorData[];
  speedRange: number[];
}

@Injectable({
  providedIn: 'root'
})

export class OlTrackBuildService {

  private readonly TRIP_COLORS = [AppColor.PRIMARY, AppColor.PURPLE, AppColor.TRACK_GREEN, AppColor.GRAY, AppColor.ACCENT, AppColor.MAUVE,
    AppColor.BLACK, AppColor.BROWN];

  constructor(private translateService: TranslateService) {
  }

  public buildTrackVectorLayer(track: Track, layerName: LayerName, trackOptions: TrackOptions, tz: string): Layer<Source>[] {
    let startTrackMarker;
    let finishTrackMarker;
    if (trackOptions.isStartFinish && track.storage.sensorDataList.length > 0) {
      startTrackMarker = OlFeature.createMarker(UrlImage.TRACK_START,
        new Point(OlCoordinate.createFromGpsCoordinate(track.storage.getFirstGpsCoordinate())));
      finishTrackMarker = OlFeature.createMarker(UrlImage.TRACK_FINISH,
        new Point(OlCoordinate.createFromGpsCoordinate(track.storage.getLastGpsCoordinate())));
    }

    let parkingClusterLayer: Layer;
    if (trackOptions.isShowParkings) {
      parkingClusterLayer = OlAnimatedClusterLayer.buildParkingCluster(
        this.createParkingFeatures(track.parkings, UrlImage.TRACK_PARKING, tz), layerName);
      parkingClusterLayer.setZIndex(ZIndexLayer.PARKING_ICONS);
    }

    let routes = [];
    if (trackOptions.isShowTrips && track.storage.sensorDataList.length > 0 && track.trips.length > 0) {
      let description = this.buildTripDescription(track.trips[0], 1, tz);
      routes = routes.concat(this.createRouteFeatureWithNextColor(
        track.getSensorDataListForTrip(track.trips[0]), description, 0));
      // offset
      for (let i = 1; i < track.trips.length; i++) {
        description = this.buildTripDescription(track.trips[i], i + 1, tz);
        routes = routes.concat(this.createRouteFeatureWithNextColor(
          track.getSensorDataListForTrip(track.trips[i], track.trips[i - 1].finishDatetime), description, i));
      }
    } else {
      routes = routes.concat(this.createRouteFeature({sensorDataList: track.storage.sensorDataList, startTrip: 0}, AppColor.PRIMARY));
    }

    let routeLayer: Layer;
    if (trackOptions.isStartFinish && track.storage.sensorDataList.length > 0) {
      routeLayer = OlVectorLayer.createVectorLayerWithFeatures([startTrackMarker, finishTrackMarker].concat(routes), layerName);
    } else {
      routeLayer = OlVectorLayer.createVectorLayerWithFeatures(routes, layerName);
    }
    routeLayer.setZIndex(ZIndexLayer.TRACK);
    const result = [];
    if (routeLayer) {
      result.push(routeLayer);
    }
    if (parkingClusterLayer) {
      result.push(parkingClusterLayer);
    }
    return result;
  }

  /** build layer with one trip and specified color */
  public buildTrackVectorLayerSpecifyTripWithColor(
    track: Track,
    layerName: LayerName,
    tripIndex: number,
    color: AppColor,
    tz: string
  ): Layer {
    let routes = [];
    const trip = track.trips[tripIndex];
    const description = this.buildTripDescription(trip, tripIndex + 1, tz);

    if (tripIndex === 0) {
      routes = routes.concat(this.createRouteFeature(track.getSensorDataListForTrip(track.trips[tripIndex]), color, description));
    } else {
      routes = routes.concat(this.createRouteFeature(
        track.getSensorDataListForTrip(track.trips[tripIndex], track.trips[tripIndex - 1].finishDatetime), color, description));
    }
    const l: Layer = OlVectorLayer.createVectorLayerWithFeatures(routes, layerName);
    l.setZIndex(ZIndexLayer.TRACK_SELECTED_TRIP);
    return l;
  }

  public buildTripBySelectedVideo(
    track: Track,
    layerName: LayerName,
    startTime: string,
    finishTime: string,
    color: AppColor
  ): Layer {
    const startIndex = track.storage.searchIndexByDatetime(startTime);
    const finishIndex = track.storage.searchIndexByDatetime(finishTime);
    const route = this.createRouteFeature(
      {sensorDataList: track.storage.getPartOfSensorDataList(startIndex, finishIndex), startTrip: 0}, color
    );
    const l: Layer = OlVectorLayer.createVectorLayerWithFeatures(route, layerName);
    l.setZIndex(ZIndexLayer.TRACK_SELECTED_TRIP);
    return l;
  }

  public buildTrackPointVectorLayer(track: Track, layerName: LayerName): VectorLayer {
    const route = this.buildLineStringFromSensorData({sensorDataList: track.storage.sensorDataList, startTrip: 0});
    return OlVectorLayer.createVectorLayerWithFeatures(OlFeature.createTrackPoints(route), layerName);
  }

  public buildTrackVideoVectorLayer(track: Track, fileList: DvrFileShort[], layerName: LayerName, timeZone: string): VectorLayer {
    const coords = track.storage.sensorDataList.map(sensorData => OlCoordinate.createFromSensorData(sensorData));
    const timeList = track.storage.sensorDataList.map(sensorData => sensorData.time);
    const layerVideoPoints =
      OlAnimatedClusterLayer.buildVideoCluster(this.createVideoPoints(coords, timeList, fileList, timeZone), layerName);
    layerVideoPoints.setZIndex(ZIndexLayer.TRACK_MARKER);
    return layerVideoPoints;
  }

  public buildTrackCheckPointsVectorLayer(track: Track, coordsRoute: Array<number[]>, indexes: [number, number]): Layer {
    const layerCheckPoints = OlVectorLayer.createVectorLayerWithFeatures(
      OlFeature.createCheckPoints(coordsRoute, indexes),
      LayerName.CHECK_POINTS
    );
    layerCheckPoints.setZIndex(ZIndexLayer.TRACK_CHECK_POINTS);
    return layerCheckPoints;
  }

  public buildTrackVectorLayerForSpecifiedSpeed(track: Track, layerName: LayerName, color: AppColor, speedRange: number[],
                                                minDuration: number = 0, tz: string): Layer {
    const sensorDatas: OverSpeedSensorData[] = [];
    const sensorId = track.storage.getSensorIdByName(SensorName.SPEED);
    for (let i = 0; i < track.storage.sensorDataList.length; i++) {
      const sensorDataArray: SensorData[] = [];
      let maxSpeed = 0;
      let minSpeed = 5000;
      while (i < track.storage.sensorDataList.length && track.storage.sensorDataList[i].sensorRecords.get(sensorId) >= speedRange[0]
      && track.storage.sensorDataList[i].sensorRecords.get(sensorId) <= speedRange[1]) {
        const s = track.storage.sensorDataList[i].sensorRecords.get(sensorId);
        maxSpeed = s > maxSpeed ? s : maxSpeed;
        minSpeed = s < minSpeed ? s : minSpeed;
        sensorDataArray.push(track.storage.sensorDataList[i++]);
      }
      if (sensorDataArray.length >= 1 &&
        DurationConverter.stringDatetimeToSecondsDuration(
          [sensorDataArray[0].time, sensorDataArray[sensorDataArray.length - 1].time]) >= minDuration) {
        sensorDatas.push({sensorDataList: sensorDataArray, speedRange: [minSpeed, maxSpeed]});
      }
    }
    let routes = [];
    for (const d of sensorDatas) {
      routes = routes.concat(
        this.createRouteFeature({sensorDataList: d.sensorDataList, startTrip: 0}, color,
          this.buildSpeedDescription(speedRange, d.speedRange, [d.sensorDataList[0].time,
            d.sensorDataList[d.sensorDataList.length - 1].time], tz)
        ));
    }
    const layer: Layer = OlVectorLayer.createVectorLayerWithFeatures(routes, layerName);
    layer.setZIndex(ZIndexLayer.TRACK_MAX_SPEED);
    return layer;
  }


  private createRouteFeatureWithNextColor(
    sensorData: SensorDataListWithTripIndex,
    description: string = '',
    tripIndex: number
  ): Feature[] {
    return this.createRouteFeature(sensorData, this.TRIP_COLORS[tripIndex % this.TRIP_COLORS.length], description);
  }

  private createRouteFeature(sensorData: SensorDataListWithTripIndex, color: AppColor, description: string = ''): Feature[] {
    const route: LineString = this.buildLineStringFromSensorData(sensorData);
    return OlFeature.createRoute(route, color, description);
  }

  private buildLineStringFromSensorData(sensorData: SensorDataListWithTripIndex): LineString {
    const coordinates = [];
    let startTrip = sensorData.startTrip;
    for (const data of sensorData.sensorDataList) {
      coordinates.push(fromLonLat([data.longitude, data.latitude, startTrip]));
      startTrip++;
    }
    return new LineString(coordinates);
  }

  private createParkingFeatures(parkings: Parking[], parkingImageUrl: string, tz) {
    const parkingIconStyle: Style = OlStyle.createForIcon(parkingImageUrl);
    const parkingFeatures = [];
    parkings.forEach((parking, i) => {
      if (parking.duration > 0) {
        const description = this.buildParkingDescription(parking, i + 1, tz);
        const point = new Point(OlCoordinate.createFromGpsCoordinate(parking.getCoordinate()));
        parkingFeatures.push(OlFeature.createIcon(
          point,
          DurationConverter.secondsToStringDuration(parking.duration, this.translateService
          ),
          parkingIconStyle, null, 0, description));
      }
    });
    return parkingFeatures;
  }

  private createVideoPoints(coords: Coordinate[], time: string[], fileList: DvrFileShort[], timeZone: string): Feature[] {
    const featurePoints = [];
    let j = 0;
    for (let i = 0; i < time.length; i++) {
      while (j < fileList.length && DayjsUtil.instant(fileList[j].time).isBefore(DayjsUtil.instant(time[i]))) {
        featurePoints.push(this.addFeatureVideoPoint(coords[i], fileList[j], timeZone));
        j++;
      }
    }
    while (j < fileList.length) {
      featurePoints.push(this.addFeatureVideoPoint(coords[coords.length - 1], fileList[j], timeZone));
      j++;
    }
    return featurePoints;
  }

  private addFeatureVideoPoint(coords: Coordinate, file: DvrFileShort, timeZone: string): Feature<Geometry> {
    return OlFeature.createVideoPoint(
      coords,
      file.name,
      file.cameraId,
      this.buildVideoDescription(file, timeZone),
      this.getUrlImageByContent(file.isVideo),
      file.status === DvrFileStatus.READY
    );
  }

  private getUrlImageByContent(isVideo: boolean): string {
    return isVideo ? UrlImage.VIDEO : UrlImage.IMAGE;
  }

  private buildSpeedDescription(speedRange: number[], actualSpeedRange: number[], dateTimes: string[], tz: string): string {
    const header = speedRange[1] === 5000 ?
      `${this.translate('message.tooltip.exceeding-of-target-speed')}` :
      `${this.translate('message.tooltip.speed-in-a-chosen-interval')}`;
    return `<h3 class="description">${header}</h3>
            <p class="description">
                <b>${this.translate('preposition.from-a')}:</b>
                <span> ${DateConverter.utcDateTimeToTzDateString(dateTimes[0], tz)}</span>
            </p>
            <p class="description">
                <b>${this.translate('preposition.to')}:</b>
                <span> ${DateConverter.utcDateTimeToTzDateString(dateTimes[1], tz)}</span>
            </p>
            <p class="description">
                <b>${this.translate('term.duration')}:</b>
                <span> ${DurationConverter.stringDatetimeToStringDuration(dateTimes, this.translateService)}</span>
            </p>
            <p class="description">
                <b>${this.translate('term.speed-change')}:</b>
                <span> ${actualSpeedRange[0]} - ${actualSpeedRange[1]} ${this.translate('uom.km-h')}</span>
            </p>`;
  }

  private buildParkingDescription(parking: Parking, parkingIndex: number, tz: string): string {
    return `<h3 class="description">${this.translate('term.parking')} #${parkingIndex}</h3>
            <p class="description">
                <b>${this.translate('preposition.from-a')}:</b>
                <span> ${DateConverter.utcDateTimeToTzDateString(parking.startDatetime, tz)}</span>
            </p>
            <p class="description">
                <b>${this.translate('preposition.to')}:</b>
                <span> ${DateConverter.utcDateTimeToTzDateString(parking.finishDatetime, tz)}</span>
            </p>
            <p class="description">
                <b>${this.translate('term.duration')}:</b>
                <span> ${DurationConverter.secondsToStringDuration(parking.duration, this.translateService)}</span>
            </p>
            <p class="description">
                <b>${this.translate('term.location')}:</b>
                <span> ${parking.address.name}</span>
            </p>`;
  }

  private buildTripDescription(trip: Trip, tripIndex: number, tz: string): string {
    return `<h3 class="description">${this.translate('term.trip')} #${tripIndex}</h3>
            <p class="description">
                <b>${this.translate('preposition.from-a')}:</b>
                <span> ${DateConverter.utcDateTimeToTzDateString(trip.startDatetime, tz)}</span>
            </p>
            <p class="description">
                <b>${this.translate('preposition.to')}:</b>
                <span> ${DateConverter.utcDateTimeToTzDateString(trip.finishDatetime, tz)}</span>
            </p>
            <p class="description">
                <b>${this.translate('term.duration')}:</b>
                <span> ${DurationConverter.secondsToStringDuration(trip.duration, this.translateService)}</span>
            </p>
            <p class="description">
                <b>${this.translate('term.mileage')}:</b>
                <span> ${trip.getMileageInKm()} ${this.translate('uom.km')}</span>
            </p>
            <p class="description">
                <b>${this.translate('term.start-address')}:</b>
                <span> ${trip.startAddress.name}</span>
            </p>
            <p class="description">
                <b>${this.translate('term.finish-address')}:</b>
                <span> ${trip.finishAddress.name}</span>
            </p>`;
  }

  private buildVideoDescription(fileDescription: DvrFileShort, tz: string): string {
    return `<h3 class="description">${fileDescription.isVideo ? this.translate('video.video') : this.translate('video.image')}</h3>
            <p class="description">
                <b>${this.translate('term.name')}:</b>
                <span> ${fileDescription.name}</span>
            </p>
            <p class="description">
                <b>${this.translate('term.time')}:</b>
                <span> ${DateConverter.utcDateTimeToTzDateString(fileDescription.time, tz)}</span>
            </p>`;
  }

  public buildPointerMovePointDescription(
    track: Track,
    sensorDataMap: Map<number, SensorData>,
    sensors: Sensor[],
    index: number,
    tz: string
  ): string {
    let layout = '';
    const time =
      `<p class="description">
         <b>${this.translate('term.time')}:</b>
         <span>${DateConverter.utcDateTimeToTzDateString(track.storage.sensorDataList[index].time, tz)}</span>
       </p>`;
    const messageId = track.storage.sensorDataList[index].messageId;
    let sensorList: Sensor[] = track.storage.sensors;
    if (sensorDataMap.has(messageId)) {
      sensorList = sensors;
    }
    sensorList.forEach(el => {
      layout = layout +
        `<p class="description">
            <b>${el.nameTranslated}:</b>
            <span>
             ${this.getSensorValue(el, track.storage.sensorDataList[index], sensorDataMap, messageId)} ${el.unitOfMeasure.nameTranslated}
            </span>
      </p>`;
    });
    return time + layout;
  }

  private getSensorValue(el: Sensor, sensorDataFromTrack: SensorData, sensorDataMap: Map<number, SensorData>, messageId: number): string {
    if (sensorDataMap.has(messageId)) {
      return SensorUtils.toValue(sensorDataMap.get(messageId).sensorRecords.get(el.id), 2);
    }
    return SensorUtils.toValue(sensorDataFromTrack.sensorRecords.get(el.id), 2);
  }

  private translate(s: string): string {
    return this.translateService.instant(s);
  }
}
