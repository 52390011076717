<mat-accordion *ngIf="modelBrandMap.size > 0">
  <mat-expansion-panel
    class="mat-elevation-z0"
    *ngFor="let brandName of brandNameList"
    [expanded]="isSelectedModels(modelBrandMap.get(brandName))"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-checkbox
          class="title-checkbox"
          [checked]="countSelectedModels(modelBrandMap.get(brandName))"
          [indeterminate]="countSelectedModels(modelBrandMap.get(brandName), true)"
          (change)="onToggleSelectAllModelsByBrand($event, modelBrandMap.get(brandName))"
          (click)="$event.stopPropagation()"
        ></mat-checkbox>
      </mat-panel-title>
      <mat-panel-description>{{ brandName }}</mat-panel-description>
    </mat-expansion-panel-header>

    <div class="selection-list">
      <mat-selection-list
        (selectionChange)="onChangeSelectedModelIdList($event)"
        [compareWith]="comparisonId"
      >
        <mat-list-option *ngFor="let model of modelBrandMap.get(brandName)"
                         class="list-option"
                         [value]="model"
                         [selected]="isSelectedModel(model.id)"
        >
          {{ model.name }}
        </mat-list-option>
      </mat-selection-list>
    </div>
  </mat-expansion-panel>
</mat-accordion>
