import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';


import {AppVersionComponent} from './app-components/app-version/app-version.component';
import {ChartSettingsDialogComponent} from './app-components/chart-settings-dialog/chart-settings-dialog.component';
import {DatePickerHeaderComponent} from './app-components/date-picker-header/date-picker-header.component';
import {DateRangePickerComponent} from './app-components/date-range-picker/date-range-picker.component';
import {HelperInfoComponent} from './app-components/helper-info/helper-info.component';
import {SnackBarComponent} from './app-components/snack-bar/snack-bar.component';
import {MaterialModule} from './material.module';
import {CapitalizePipe} from './pipes/capitalize.pipe';
import {FileSizePipe} from './pipes/file-size.pipe';
import {MetersToKilometersPipe} from './pipes/meters-to-kilometers.pipe';
import {MomentTimezoneConvertPipe} from './pipes/moment-timezone-convert.pipe';
import {SecToStringDurationPipe} from './pipes/sec-to-string-duration.pipe';
import {SensorValuePipe} from './pipes/sensor-value.pipe';
import {TimeByHalfHourPipe} from './pipes/time-by-half-hour.pipe';
import {TimeFromNowTextPipe} from './pipes/time-from-now-text.pipe';
import {TimestampTimezoneConvertPipe} from './pipes/timestamp-timezone-convert.pipe';
import {NgxAurMatTableModule} from "ngx-aur-mat-table";
import {ChangeLanguageComponent} from "./app-components/change-language/change-language.component";
import {SelectLanguageComponent} from "./app-components/select-language/select-language.component";
import {SettingsDialogComponent} from "./app-dialog/settings-dialog/settings-dialog.component";
import {SelectTimezoneComponent} from "./app-dialog/settings-dialog/select-timezone/select-timezone.component";
import {ArchivableNameComponent} from "./app-components/archivable-name/archivable-name.component";
import {OpenButtonComponent} from "./app-components/filters/common-filter/open-button/open-button.component";
import {DialogComponent} from "./dialog/dialog/dialog.component";
import {DialogContentDirective} from "./dialog/dialog/dialog-content.directive";
import {DialogActionsDirective} from "./dialog/dialog/dialog-actions.directive";
import {DialogDeleteComponent} from "./dialog/dialog-delete/dialog-delete.component";
import {
  DialogActionsCancelSaveComponent
} from "./dialog/dialog-save/dialog-actions-cancel-save/dialog-actions-cancel-save.component";
import {DialogSaveComponent} from "./dialog/dialog-save/dialog-save.component";
import {AppButtonModule} from "./buttons/app-button.module";
import {HeaderTitleModule} from "./header-title/header-title.module";
import {DayjsUtil} from "./dayjs.util";
import {LogoTitleComponent} from "./app-components/logo-header/logo-title.component";
import {UploadFileInputComponent} from "./app-input/attach-file-input/upload-file-input.component";
import {DatePickerComponent} from "./app-components/date-picker/date-picker.component";
import {NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask} from "ngx-mask";
import {NgxDaterangepickerMd} from "ngx-daterangepicker-material-no-utc";
import {FiltersModule} from "./app-components/filters/filters.module";
import {SearchComponent} from "./app-components/search/search.component";
import {TruncatePipe} from "./pipes/truncate.pipe";
import {AurDialogModule, AurDialogStickyHeaderDirective} from "ngx-aur-mat-dialog";

@NgModule({
  declarations: [
    CapitalizePipe,
    FileSizePipe,
    MetersToKilometersPipe,
    SecToStringDurationPipe,
    TimeFromNowTextPipe,
    TimestampTimezoneConvertPipe,
    MomentTimezoneConvertPipe,
    TimeByHalfHourPipe,
    AppVersionComponent,
    DatePickerHeaderComponent,
    DateRangePickerComponent,
    ChartSettingsDialogComponent,
    SnackBarComponent,
    HelperInfoComponent,
    ChangeLanguageComponent,
    SelectLanguageComponent,
    SelectTimezoneComponent,
    SettingsDialogComponent,
    ArchivableNameComponent,
    OpenButtonComponent,
    DialogComponent,
    DialogContentDirective,
    DialogActionsDirective,
    DialogActionsCancelSaveComponent,
    DialogDeleteComponent,
    DialogSaveComponent,
    LogoTitleComponent,
    UploadFileInputComponent,
    DatePickerComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    FormsModule,
    AppButtonModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    FiltersModule,
    SearchComponent,
    TruncatePipe,
    NgxDaterangepickerMd.forRoot({
      applyLabel: 'Ok',
      format: 'DD.MM.YYYY HH:mm',
      firstDay: 1,
      daysOfWeek: DayjsUtil.weekdaysMin(),
      monthNames: DayjsUtil.monthsShort()
    }),
    NgxAurMatTableModule,
    AurDialogModule,
    AurDialogStickyHeaderDirective
  ],
  providers: [
    CapitalizePipe,
    provideEnvironmentNgxMask()
  ],
  bootstrap: [],
  exports: [
    CapitalizePipe,
    FileSizePipe,
    MetersToKilometersPipe,
    SecToStringDurationPipe,
    TimeFromNowTextPipe,
    TimestampTimezoneConvertPipe,
    MomentTimezoneConvertPipe,
    TimeByHalfHourPipe,
    ChartSettingsDialogComponent,
    DateRangePickerComponent,
    HelperInfoComponent,
    SnackBarComponent,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxAurMatTableModule,
    ChangeLanguageComponent,
    SelectLanguageComponent,
    ArchivableNameComponent,
    DialogComponent,
    DialogContentDirective,
    DialogActionsCancelSaveComponent,
    DialogActionsDirective,
    DialogDeleteComponent,
    DialogSaveComponent,
    AppButtonModule,
    HeaderTitleModule,
    LogoTitleComponent,
    UploadFileInputComponent,
    DatePickerComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    FiltersModule,
    SearchComponent,
    TruncatePipe
  ]
})

export class AppCommonModule {
}
