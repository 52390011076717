import {Component, Inject} from '@angular/core';
import {DialogSaveComponent} from "../../dialog/dialog-save/dialog-save.component";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {MatFormField} from "@angular/material/form-field";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {CertificateExpireComponent} from "../certificate-expire/certificate-expire.component";
import {Unit} from "../../modelinterfaces/unit.model";
import {Instant, LocalDate} from "@js-joda/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AppMessageService} from "../../app-services/app-message.service";
import {UnitService} from "../../services/unit.service";
import {
  DialogComponent
} from "../../../../../user-portal/src/app/shared/components/dialogs/base-dialog/base-dialog.component";
import {finalize, takeUntil} from "rxjs/operators";
import {CommonModule} from "@angular/common";

export interface EditCertificateDialogData {
  unit: Unit;
  deleteDisable: boolean;
}

@Component({
  selector: 'app-edit-certificate-dialog',
  standalone: true,
  imports: [
    DialogSaveComponent,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MatSlideToggle,
    MatFormField,
    MatDatepickerModule,
    MatInputModule,
    CertificateExpireComponent

  ],
  templateUrl: './edit-certificate-dialog.component.html',
  styleUrl: './edit-certificate-dialog.component.scss'
})
export class EditCertificateDialogComponent extends DialogComponent.Outputable<EditCertificateDialogData, Unit> {
  form: FormGroup;
  showSpinner = false;
  selectedExpirationDate: LocalDate = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditCertificateDialogData,
              private appMessageService: AppMessageService,
              public dialogRef: MatDialogRef<EditCertificateDialogComponent>,
              private unitService: UnitService,
              private fb: FormBuilder) {
    super(dialogRef, data);
    this.initForm();
  }

  private initForm() {
    this.selectedExpirationDate = this.data.unit.trackerCertificationExpiry;
    this.form = this.fb.group({
      isCertified: new FormControl({
        value: !!this.data.unit?.trackerCertificationExpiry,
        disabled: this.data.deleteDisable
      },),
      expirationDate: new FormControl(this.toDate(this.selectedExpirationDate), this.data.deleteDisable ? Validators.required : [])
    });

    this.setupValueChanges();
  }

  private setupValueChanges() {
    this.form.get('isCertified')?.valueChanges.pipe(takeUntil(this.destroy))
      .subscribe(isCertified => this.updateExpirationValidators(isCertified));

    this.form.get('expirationDate')?.valueChanges.pipe(takeUntil(this.destroy))
      .subscribe(expiration => this.selectedExpirationDate = this.toLocalDate(expiration));
  }

  private updateExpirationValidators(isCertified: boolean) {
    const expirationDateControl = this.form.get('expirationDate');
    if (isCertified) {
      expirationDateControl?.setValidators([Validators.required]);
    } else {
      expirationDateControl?.clearValidators();
    }
    expirationDateControl?.updateValueAndValidity();
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.form.invalid) return;

    this.showSpinner = true;
    const actualExpire = this.form.get('isCertified').value ? this.selectedExpirationDate : null;

    this.unitService.changeTrackerCertificationExpiry(this.data.unit.id, actualExpire)
      .pipe(finalize(() => this.showSpinner = false))
      .subscribe(unit => {
        this.appMessageService.openSnackBar('message.info.changes-saved');
        this.closeOnSave(unit);
      });
  }

  private toLocalDate(date: Date): LocalDate {
    if (!date) {
      return null;
    }
    const instant = Instant.ofEpochMilli(<number>date.valueOf());
    return LocalDate.ofInstant(instant);
  }

  private toDate(local: LocalDate): Date {
    if (!local) return null;
    return new Date(local.year(), local.monthValue() - 1, local.dayOfMonth());
  }
}
