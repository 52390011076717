import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header.component';
import {ChangePasswordDialogComponent} from './change-password-dialog/change-password-dialog.component';
import {AppCommonModule} from '../../../../../common-module/src/lib/app-common.module';
import {PublicLinksDialogComponent} from './public-links-dialog/public-links-dialog.component';
import {InformingDialogComponent} from './informing-dialog/informing-dialog.component';
import {SharedModule} from '../../shared/shared.module';
import {ErrorStateMatcher, ShowOnDirtyErrorStateMatcher} from '@angular/material/core';

@NgModule({
  declarations: [
    HeaderComponent,
    ChangePasswordDialogComponent,
    PublicLinksDialogComponent,
    InformingDialogComponent
  ],
    imports: [
        CommonModule,
        AppCommonModule,
        SharedModule,
    ],
  providers: [{provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher}],
  bootstrap: [],
  exports: [
    HeaderComponent
  ]
})

export class HeaderModule {
}
