import { Component } from '@angular/core';
import {AppDialogModule} from "../../../../../../common-module/src/lib/dialog/dialog/app-dialog.module";
import {ToolbarModule} from "../../main/toolbar/toolbar.module";
import {AppButtonModule} from "../../../../../../common-module/src/lib/buttons/app-button.module";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-toolbar-dialog',
  standalone: true,
  imports: [
    AppDialogModule,
    ToolbarModule,
    AppButtonModule,
    TranslateModule
  ],
  templateUrl: './toolbar-dialog.component.html',
  styleUrl: './toolbar-dialog.component.scss'
})
export class ToolbarDialogComponent {

}
