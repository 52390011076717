export class MetaData {

  constructor(public columnType: string,
              public uom: string,
              public columnNameLocale: string) {
  }

  public static valueOf(i: MetaData): MetaData {
    return i ? new MetaData(i.columnType, i.uom, i.columnNameLocale) : null;
  }
}

