import { Pipe, PipeTransform } from '@angular/core';
import { Geofence } from '../../../../../common-module/src/lib/modelinterfaces/geofence.model';

@Pipe({
  name: 'geofenceFilter',
  standalone: true
})
export class GeofenceFilterPipe implements PipeTransform {

  transform(geofences: Geofence[], search: string = ''): Geofence[] {
    if (!search.trim()) {
      return geofences;
    }
    search = search.toLowerCase();
    return geofences.filter(g => g.name.toLowerCase().includes(search));
  }
}
