import {Injectable} from "@angular/core";
import {DialogOpener} from "../../../../../shared/components/dialogs/base-dialog/base-dialog.opener";
import {MatDialog} from "@angular/material/dialog";
import {
  SensorRanges,
  TrackSensorOptionsDialogComponent,
  TrackSensorOptionsDialogDate
} from "./track-sensor-options-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class TrackSensorOptionsDialogOpener extends DialogOpener.Outputable<TrackSensorOptionsDialogDate, SensorRanges[]> {
  constructor(matDialog: MatDialog) {
    super(matDialog, TrackSensorOptionsDialogComponent)
  }
}
