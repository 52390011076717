import {AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {JobsOverviewMap} from "./jobs-overview.map";
import {takeUntil} from "rxjs/operators";
import {WarehouseMapComponent} from "../warehouse-map/warehouse-map.component";
import {
  JobOverviewMapFactory
} from "../../../aur-map-creator/map/wrapper/map-wrapper-factory/impl/job-overview-map.factory";
import {Logistic} from "../../../../../../../common-module/src/lib/modelinterfaces/logistic.model";

@Component({
  selector: 'app-jobs-overview-map',
  templateUrl: './jobs-overview-map.component.html',
  styleUrls: ['./jobs-overview-map.component.scss']
})
export class JobsOverviewMapComponent extends WarehouseMapComponent implements AfterViewInit, OnChanges {

  map: JobsOverviewMap;

  @Input() jobs: Logistic.Job[] = [];
  @Input() selectJob: Logistic.Job;

  @Output() selectJobChange = new EventEmitter<Logistic.Job>();

  constructor(protected mapFactory: JobOverviewMapFactory) {
    super(mapFactory);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectJob && this.map) {
      this.map.selectJob(this.selectJob);
    }
  }

  ngAfterViewInit(): void {
    this.map = this.mapFactory.create({
      mapDivId: this.mapDivId,
      popupDivId: this.popupDivId,
      warehouses: this.warehouses,
      jobs: this.jobs
    });
    this.map.$clickedJob.pipe(takeUntil(this.destroy))
      .subscribe(j => {
        setTimeout(() => this.selectJobChange.emit(j))
      })
    this.map.updateSize();
  }

}
