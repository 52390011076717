import { BuildOptions } from './build-options.model';
import { SensorDataStorage } from './sensor-data-storage.model';

export class SensorChart {

  constructor(public buildOptions: BuildOptions,
              public storage: SensorDataStorage) {
  }

  public static valueOf(i: SensorChart): SensorChart {
    return i ? new SensorChart(BuildOptions.valueOf(i.buildOptions), SensorDataStorage.valueOf(i.storage)) : null;
  }
}
