<form [formGroup]="editForm" *ngIf="editForm">
  <div class="form-container vehicle">
    <div class="wrapper-field">
      <mat-form-field>
        <mat-label>{{ 'unit.trip-settings.' + control.MIN_PARKING_TIME | translate }}, {{ 'uom.sec' | translate }}
        </mat-label>
        <input matInput type="number" [formControlName]="control.MIN_PARKING_TIME">
        <mat-error *ngIf="formControl[control.MIN_PARKING_TIME].errors?.required">
          {{ 'message.error.required-field' | translate }}
        </mat-error>
        <mat-error *ngIf="formControl[control.MIN_PARKING_TIME].errors?.min">
          {{ 'message.error.min-value' | translate: {min: formControl[control.MIN_PARKING_TIME].errors.min.min} }}
        </mat-error>
        <mat-error *ngIf="formControl[control.MIN_PARKING_TIME].errors?.max">
          {{ 'message.error.max-value' | translate: {max: formControl[control.MIN_PARKING_TIME].errors.max.max} }}
        </mat-error>
      </mat-form-field>

      <app-helper-info [info]="'unit.trip-settings.info.time-determinate-parking' | translate"></app-helper-info>
    </div>

    <div class="wrapper-field">
      <mat-form-field>
        <mat-label>{{ 'unit.trip-settings.' + control.MIN_SPEED | translate }}, {{ 'uom.km-h' | translate }}</mat-label>
        <input matInput type="number" [formControlName]="control.MIN_SPEED">
        <mat-error *ngIf="formControl[control.MIN_SPEED].errors?.required">
          {{ 'message.error.required-field' | translate }}
        </mat-error>
        <mat-error *ngIf="formControl[control.MIN_SPEED].errors?.min">
          {{ 'message.error.min-value' | translate: {min: formControl[control.MIN_SPEED].errors.min.min} }}
        </mat-error>
        <mat-error *ngIf="formControl[control.MIN_SPEED].errors?.max">
          {{ 'message.error.max-value' | translate: {max: formControl[control.MIN_SPEED].errors.max.max} }}
        </mat-error>
      </mat-form-field>

      <app-helper-info [info]="'unit.trip-settings.info.speed-determinate-stopped' | translate"></app-helper-info>
    </div>

    <div class="wrapper-field">
      <mat-form-field>
        <mat-label>{{ 'unit.trip-settings.' + control.MIN_TRIP_TIME | translate }}, {{ 'uom.sec' | translate }}
        </mat-label>
        <input matInput type="number" [formControlName]="control.MIN_TRIP_TIME">
        <mat-error *ngIf="formControl[control.MIN_TRIP_TIME].errors?.required">
          {{ 'message.error.required-field' | translate }}
        </mat-error>
        <mat-error *ngIf="formControl[control.MIN_TRIP_TIME].errors?.min">
          {{ 'message.error.min-value' | translate: {min: formControl[control.MIN_TRIP_TIME].errors.min.min} }}
        </mat-error>
        <mat-error *ngIf="formControl[control.MIN_TRIP_TIME].errors?.max">
          {{ 'message.error.max-value' | translate: {max: formControl[control.MIN_TRIP_TIME].errors.max.max} }}
        </mat-error>
      </mat-form-field>

      <app-helper-info [info]="'unit.trip-settings.info.duration-trip-ignored' | translate"></app-helper-info>
    </div>

    <div class="wrapper-field">
      <mat-form-field>
        <mat-label>{{ 'unit.trip-settings.' + control.MIN_DISTANCE | translate }}, {{ 'uom.m' | translate }}</mat-label>
        <input matInput type="number" [formControlName]="control.MIN_DISTANCE">
        <mat-error *ngIf="formControl[control.MIN_DISTANCE].errors?.required">
          {{ 'message.error.required-field' | translate }}
        </mat-error>
        <mat-error *ngIf="formControl[control.MIN_DISTANCE].errors?.min">
          {{ 'message.error.min-value' | translate: {min: formControl[control.MIN_DISTANCE].errors.min.min} }}
        </mat-error>
        <mat-error *ngIf="formControl[control.MIN_DISTANCE].errors?.max">
          {{ 'message.error.max-value' | translate: {max: formControl[control.MIN_DISTANCE].errors.max.max} }}
        </mat-error>
      </mat-form-field>

      <app-helper-info [info]="'unit.trip-settings.info.mileage-trip-ignored' | translate"></app-helper-info>
    </div>

    <div class="wrapper-field">
      <mat-form-field>
        <mat-label>{{ 'unit.trip-settings.' + control.MAX_DURATION | translate }}, {{ 'uom.sec' | translate }}
        </mat-label>
        <input matInput type="number" [formControlName]="control.MAX_DURATION">
        <mat-error *ngIf="formControl[control.MAX_DURATION].errors?.required">
          {{ 'message.error.required-field' | translate }}
        </mat-error>
        <mat-error *ngIf="formControl[control.MAX_DURATION].errors?.min">
          {{ 'message.error.min-value' | translate: {min: formControl[control.MAX_DURATION].errors.min.min} }}
        </mat-error>
        <mat-error *ngIf="formControl[control.MAX_DURATION].errors?.max">
          {{ 'message.error.max-value' | translate: {max: formControl[control.MAX_DURATION].errors.max.max} }}
        </mat-error>
      </mat-form-field>

      <app-helper-info [info]="'unit.trip-settings.info.time-trip-will-end' | translate"></app-helper-info>
    </div>

    <div>
      <span class="return-default"
            (click)="onSetDefaultValues()">{{ 'action.return-default-values' | translate }}</span>
    </div>
  </div>
</form>

<ng-content></ng-content> <!-- project actionButtons -->
