import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";
import { AppMessageService } from '../../../../../../../common-module/src/lib/app-services/app-message.service';
import { UiSpinnerService } from "../../../../../../../common-module/src/lib/app-services/ui-spinner.service";
import { Mechanism } from '../../../../../../../common-module/src/lib/modelinterfaces/mechanism.model';
import { MechanismModeService } from "../../../../../../../common-module/src/lib/services/mechanism-mode.service";
import { MechanismService } from "../../../../../../../common-module/src/lib/services/mechanism.service";
import { VehicleManagerService } from "../../../../shared/services/vehicle-manager.service";

@Component({
  selector: 'app-engine-hours-form',
  templateUrl: './engine-hours-form.component.html',
  styleUrls: ['../../../../../../../common-module/src/lib/app-styles/dialog-common.scss'],
})

export class EngineHoursFormComponent implements OnChanges, OnInit, OnDestroy {

  private readonly DEFAULT_FACTOR = 1;

  @Input() unitId: number;

  public dailyNormForm: UntypedFormGroup;
  public factorsForm: UntypedFormGroup;
  public isNoMechanisms = false;
  public mechanism: Mechanism;

  private destroyed = new Subject<boolean>();

  constructor(private appMessageService: AppMessageService,
              private cdr: ChangeDetectorRef,
              private mechanismService: MechanismService,
              private mechanismModeService: MechanismModeService,
              private translateService: TranslateService,
              private uiSpinnerService: UiSpinnerService,
              private vehicleManagerService: VehicleManagerService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['unitId'] && this.unitId) {
      this.isNoMechanisms = false;
      this.mechanism = null;
      this.dailyNormForm = null;
      this.factorsForm = null;
      this.getMechanism();
    }
  }

  private getMechanism(): void {
    this.uiSpinnerService.show();
    this.mechanismService.getList(this.unitId, this.translateService.currentLang)
      .subscribe(
        list => {
          this.mechanism = list[0];
          if (this.mechanism) {
            this.buildNormForm();
            this.buildFactorsForm();
          } else {
            this.isNoMechanisms = true;
            this.cdr.detectChanges();
          }
          this.uiSpinnerService.stop();
        }
      );
  }

  ngOnInit(): void {
    this.vehicleManagerService.isCancelForms$
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        isCancel => {
          if (isCancel && this.vehicleManagerService.instantTabSelectedIndex() === 6 && !this.isNoMechanisms) {
            this.buildNormForm();
            this.buildFactorsForm();
          }
        }
      )

    this.vehicleManagerService.isSubmitForms$
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        isSubmit => {
          if (isSubmit && this.vehicleManagerService.instantTabSelectedIndex() === 6) {
            this.onSubmit();
          }
        }
      )
  }

  private buildNormForm(): void {
    this.dailyNormForm = new UntypedFormGroup({
      norm: new UntypedFormControl(this.mechanism.engineHoursNorm, [Validators.required, Validators.min(0)]
      )
    });

    this.dailyNormForm.valueChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.changeGlobalForms();
      });
    this.changeGlobalForms();
  }

  private buildFactorsForm(): void {
    if (this.mechanism?.modes?.length > 0) {
      this.factorsForm = new UntypedFormGroup({
        factors: new UntypedFormArray(this.mechanism.modes.map(mode => {
            return new UntypedFormControl(mode.engineHoursRate >= 0? mode.engineHoursRate: this.DEFAULT_FACTOR, [Validators.min(0)])
          })
        )
      });
      this.factorsForm.valueChanges
        .pipe(takeUntil(this.destroyed))
        .subscribe(() => {
          this.changeGlobalForms();
        });
      this.changeGlobalForms();
    }
  }

  private changeGlobalForms(): void {
    this.vehicleManagerService.changeForms([this.dailyNormForm, this.factorsForm]);
  }

  public getFactorControls(): AbstractControl[] {
    return (this.factorsForm.get('factors') as UntypedFormArray).controls;
  }

  public onCancel(): void {
    this.buildNormForm();
    this.buildFactorsForm();
  }

  public onSubmit(): void {
    this.submitNorm();
    this.submitFactors();
  }

  private submitNorm(): void {
    if (!this.dailyNormForm || !this.dailyNormForm.dirty || !this.dailyNormForm.valid) {
      return;
    }
    this.uiSpinnerService.show();
    this.mechanismService.updateEngineHoursNorm(this.mechanism.id, this.dailyNormForm.value['norm'], this.translateService.currentLang).subscribe(
      () => {
        this.appMessageService.openSnackBar('message.info.changes-saved');
        this.dailyNormForm.markAsPristine();
        this.changeGlobalForms();
        this.uiSpinnerService.stop();
      }
    );
  }

  private submitFactors(): void {
    if (!this.factorsForm || !this.factorsForm.dirty || !this.factorsForm.valid) {
      return;
    }
    this.uiSpinnerService.show();
    this.getFactorControls().forEach(
      (control, index) => {
        if (control.dirty && control.valid) {
          this.mechanismModeService.updateEngineHoursRate(this.mechanism.modes[index].id, control.value, this.translateService.currentLang)
            .subscribe(
              mode => {
                this.mechanism.modes = this.mechanism.modes.map(item => {
                  if (item.id === mode.id) {
                    return mode;
                  }
                  return item;
                });
                this.saveModesChanges(this.getFactorControls().length, index);
              }
            )
        } else {
          this.saveModesChanges(this.getFactorControls().length, index);
        }
      }
    )
  }

  private saveModesChanges(modeControlsLength: number, index: number): void {
    if (modeControlsLength - 1 === index) {
      this.factorsForm.markAsPristine();
      this.changeGlobalForms();
      this.appMessageService.openSnackBar(`message.info.changes-saved`);
      this.uiSpinnerService.stop();
    }
  }

  ngOnDestroy(): void {
    this.destroyed.next(null);
    this.destroyed.complete();
  }
}
