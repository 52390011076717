import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';

import {Notification} from '../../../../../../common-module/src/lib/modelinterfaces/notification.model';
import {NotificationManagerService} from '../../../shared/services/notification-manager.service';
import {AuthUserService} from '../../../../../../common-module/src/lib/app-services/auth-user.service';
import {NotificationStatus} from '../../../../../../common-module/src/lib/modelinterfaces/enums/notification-status';
import {NotificationWithNew} from '../../../../../../common-module/src/lib/modelinterfaces/notification-with-new.model';

@Component({
  selector: 'app-notification-table',
  templateUrl: './notification-table.component.html',
  styleUrls: ['./notification-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class NotificationTableComponent implements OnInit {

  public readonly displayedColumns = ['read', 'unitName', 'message', 'time'];

  public dataSource$: BehaviorSubject<MatTableDataSource<NotificationWithNew>> = new BehaviorSubject(null);
  public loading$: Observable<boolean>;
  public notificationStatus = NotificationStatus;
  public timeZone$: Observable<string>;
  public readNotificationId$: Observable<number>;

  private subscription: Subscription;

  constructor(private authUserService: AuthUserService,
              private notificationManagerService: NotificationManagerService) {
  }

  ngOnInit(): void {
    this.timeZone$ = this.authUserService.currentUser$.pipe(
      filter(value => !!value),
      map(user => user.getUserTimeZone())
    );

    this.loading$ = this.notificationManagerService.loadingNotices$;
    this.readNotificationId$ = this.notificationManagerService.readNotificationId$;
    this.subscription = this.notificationManagerService.lastNotifications$.subscribe(
      noticeList => this.dataSource$.next(new MatTableDataSource(noticeList)));
  }

  public openNotificationDetails(notification: Notification): void {
    this.notificationManagerService.openNotificationDetails(notification?.id);
  }

  public readNotification(notificationId: number, $event: Event): void {
    this.notificationManagerService.readNotification([notificationId], $event);
  }

  public isNotificationNew(value: NotificationWithNew): boolean {
    return value.isNew;
  }

  public asNotification(value: NotificationWithNew): Notification {
    return value.notification;
  }
}
