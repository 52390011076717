import {Point} from "ol/geom";
import {Logistic} from "../../../../../../../../../common-module/src/lib/modelinterfaces/logistic.model";
import {Feature} from "ol";
import {
  AbstractJobFeatureWrapper,
  JobFeatureHighlightOpts,
  JobFeatureSelectOpts
} from "../abstract-job-feature.wrapper";
import {OlStepStyleOpts, StepStyleFactory} from "./step-style.factory";
import {FeatureLike} from "ol/Feature";
import {AppColor} from "../../../../../../../../../common-module/src/lib/app-enums/app-color";

export class StepFeatureWrapper extends AbstractJobFeatureWrapper<Point, StepStyleFactory, OlStepStyleOpts> {

  private static PROPERTY_NAME_STEP = 'step-value';

  constructor(feature: Feature<Point>,
              styleFactory: StepStyleFactory,
              opts: OlStepStyleOpts,
              value: Logistic.Job,
              public step: Logistic.Step) {
    super(feature, styleFactory, opts, value);
    this.setProperty(StepFeatureWrapper.PROPERTY_NAME_STEP, step);
  }

  public static getStepFromFeature(feature: FeatureLike): Logistic.Step {
    return feature.get(StepFeatureWrapper.PROPERTY_NAME_STEP);
  }

  public static hasStepFromFeature(feature: FeatureLike): boolean {
    return feature.get(StepFeatureWrapper.PROPERTY_NAME_STEP);
  }

  protected override createSelectStyleOpts(opts: JobFeatureSelectOpts): OlStepStyleOpts {
    const styleOpts = this.getSourceOpts();
    styleOpts.fill = opts.fill;
    styleOpts.iconColor = opts.iconColor;
    return styleOpts;
  }

  protected createHighlightStyleOpts(opts: JobFeatureHighlightOpts): OlStepStyleOpts {
    const styleOpts = this.getSourceOpts();
    styleOpts.fill = opts.fill;
    styleOpts.strokeColor = AppColor.ORANGE;
    styleOpts.iconColor = AppColor.WHITE;
    styleOpts.zIndex = opts.zIndex;
    return styleOpts;
  }
}
