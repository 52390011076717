import {Component, Input} from '@angular/core';
import {
  SensorLastValue
} from "../../../../../../../../../common-module/src/lib/modelinterfaces/sensor-last-value.model";
import {SensorValuePipe} from "../../../../../../../../../common-module/src/lib/pipes/sensor-value.pipe";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-sensor-last-value-item',
  standalone: true,
  imports: [SensorValuePipe, MatTooltip],
  templateUrl: './sensor-last-value-item.component.html',
  styleUrl: './sensor-last-value-item.component.scss'
})
export class SensorLastValueItemComponent {
  @Input() sensorLastValue: SensorLastValue;


}
