import {Pipe, PipeTransform} from '@angular/core';
import {DayjsUtil} from "../dayjs.util";

export class TimestampUtil {
  public static transform(datetime: string, tzName: string | 'browser', format: string = 'DD.MM.YYYY HH:mm:ss'): string {
    if (tzName === 'browser') {
      tzName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    if (!DayjsUtil.instant(datetime).isValid()) {
      return '';
    }
    return DayjsUtil.utc(datetime).tz(tzName)?.format(format);
  }
}

@Pipe({
  name: 'utcDateTz',
  standalone: true
})

export class TimestampTimezoneConvertPipe implements PipeTransform {

  transform(datetime: string, tzName: string | 'browser', format: string = 'DD.MM.YYYY HH:mm:ss'): string {
    return TimestampUtil.transform(datetime, tzName, format);
  }
}
