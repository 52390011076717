<button mat-button
        [class]="styleClass"
        (click)="action.emit($event)"
        [disabled]="disabled || showSpinner">
  <div class="flex flex-v-center flex-gap4">

    <ng-content></ng-content>

    {{ text }}

    <app-button-spinner *ngIf="showSpinner">
    </app-button-spinner>
  </div>

</button>
