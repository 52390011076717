import {FullName} from "./full-name.model";

export class Driver {

  constructor(public id: number,
              public name: FullName,
              public phoneNumber: string,
              public avatarUrl: string) {
  }

  public static valueOf(o: Driver): Driver {
    return o ? new Driver(o.id, FullName.valueOf(o.name), o.phoneNumber, o.avatarUrl) : null;
  }

  public getFullName(): string {
    return this.name.getFull();
  }

  public getShortName(): string {
    return this.name.getShort();
  }
}
