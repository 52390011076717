import { Component } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {QrCodeModule} from "ng-qrcode";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-telegram-message',
  standalone: true,
  templateUrl: './telegram-message.component.html',
  imports: [
    QrCodeModule,
    TranslateModule
  ],
  styleUrls: ['./telegram-message.component.scss']
})

export class TelegramMessageComponent {

  public readonly TELEGRAM_URL = environment.TELEGRAM_URL;
  public readonly QRCODE_SIZE = 256;
}
