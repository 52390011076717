import { ImportLog } from './import-log.model';

export class ImportResult {

  constructor(public fileName: string,
              public isSuccess: boolean,
              public logs: ImportLog[]) {
  }

  public static valueOf(i: ImportResult): ImportResult {
    return i ? new ImportResult(i.fileName, i.isSuccess, ImportLog.valuesOf(i.logs)) : null;
  }

  public static valuesOf(list: ImportResult[]): ImportResult[] {
    return list ? list.map(o => ImportResult.valueOf(o)) : [];
  }
}
