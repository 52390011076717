import { TelegramUser } from './telegram-user.model';
import { UserShort } from './user-short.model';

export class TelegramChat {

  constructor(public id: number,
              public user: UserShort,
              public telegramUser: TelegramUser,
              public created: string,
              public activated: boolean) {
  }

  public static valueOf(o: TelegramChat): TelegramChat {
    return o ? new TelegramChat(o.id, UserShort.valueOf(o.user), TelegramUser.valueOf(o.telegramUser), o.created, o.activated) : null;
  }

  public static valuesOf(list: TelegramChat[]): TelegramChat[] {
    return list ? list.map(o => TelegramChat.valueOf(o)) : [];
  }
}
