import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AppColor} from '../../../../../../common-module/src/lib/app-enums/app-color';
import {AppMessageService} from '../../../../../../common-module/src/lib/app-services/app-message.service';
import {UnitShort} from '../../../../../../common-module/src/lib/modelinterfaces/unit-short.model';
import {UnitGroupService} from '../../../../../../common-module/src/lib/services/unit-group.service';
import {UnitGroup} from '../../../../../../common-module/src/lib/modelinterfaces/unit-group.model';
import {UserUnitGroups} from '../../../../../../common-module/src/lib/modelinterfaces/user-unit-groups.model';
import {AppRegexp} from "../../../../../../common-module/src/lib/utils/app-regexp";

export interface GroupDialogData {
  user: UserUnitGroups;
  allUnits: UnitShort[];
  group: UnitGroup;
}

enum Control {
  COLOR = 'COLOR',
  NAME = 'NAME'
}

@Component({
  selector: 'app-edit-group-dialog',
  templateUrl: './edit-group-dialog.component.html',
  styleUrls: ['./edit-group-dialog.component.scss', '../../../../../../common-module/src/lib/app-styles/dialog-common.scss']
})

export class EditGroupDialogComponent implements OnInit {

  readonly controls = Control;

  public readonly MAX_LENGTH_NAME_GROUP = 20;

  public collectionColors: Array<AppColor> = Object.values(AppColor);
  public iconColor: AppColor;
  public form: UntypedFormGroup = new UntypedFormGroup({
    [Control.COLOR]: new UntypedFormControl(this.data.group ? this.data.group.color : AppColor.RUBY, Validators.required),
    [Control.NAME]: new UntypedFormControl(this.data.group ? this.data.group.name : null,
      [Validators.required, Validators.pattern(AppRegexp.TEXT), Validators.maxLength(this.MAX_LENGTH_NAME_GROUP)], this.checkIsUsedName.bind(this)
    )
  });

  selectedUnits: UnitShort[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GroupDialogData,
    private dialogRef: MatDialogRef<EditGroupDialogComponent>,
    private unitGroupService: UnitGroupService,
    private appUserMessageService: AppMessageService) {
  }

  ngOnInit(): void {
  }

  private checkIsUsedName(formControl: UntypedFormControl): Promise<any> {
    if (this.data.user.groups.some(el => (el.name === formControl.value && this.data?.group?.name !== formControl.value))) {
      return new Promise(resolve => resolve({nameIsUsed: true}));
    }
    return new Promise(resolve => resolve(null));
  }

  public onDelete(): void {
    this.unitGroupService.delete(this.data.group.id).subscribe(() => {
        this.dialogRef.close({deleteGroupId: this.data.group.id});
        this.appUserMessageService.openSnackBar('message.info.changes-saved');
      }
    );
  }

  public onAddGroup(): void {
    if (!this.checkHasUnitInGroup()) {
      return;
    }
    const newGroup = new UnitGroup(
      null,
      this.form.value[Control.NAME],
      this.form.value[Control.COLOR],
      this.selectedUnits
    );
    this.unitGroupService.save(this.data.user.id, newGroup).subscribe((group) => {
        this.dialogRef.close({addGroup: group});
        this.appUserMessageService.openSnackBar('message.info.changes-saved');
      }
    );
  }

  private checkHasUnitInGroup(): boolean {
    if (this.selectedUnits.length > 0) {
      return true;
    } else {
      this.appUserMessageService.openSnackBar('message.error.select-unit-for-group', true);
      return false;
    }
  }

  public onSaveChanges(): void {
    if (!this.checkHasUnitInGroup()) {
      return;
    }
    const updateGroup = new UnitGroup(
      this.data.group.id,
      this.form.value[Control.NAME],
      this.form.value[Control.COLOR],
      this.selectedUnits
    );
    this.unitGroupService.update(updateGroup).subscribe((group) => {
        this.dialogRef.close({editGroup: group});
        this.appUserMessageService.openSnackBar('message.info.changes-saved');
      }
    );
  }
}
