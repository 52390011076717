<div *ngIf="(!dailyNormForm || !factorsForm)" class="dialog-subheader additional-message">
  <span *ngIf="isNoMechanisms">{{ 'mechanism.no-for-adjusting' | translate }}</span>
  <span *ngIf="!isNoMechanisms">&nbsp;</span>
</div>

<form [formGroup]="dailyNormForm" *ngIf="dailyNormForm">
  <header class="dialog-title to-left">
    <span>{{ mechanism.name }}</span>
  </header>

  <div class="form-container vehicle">
    <mat-form-field>
      <mat-label>{{ 'mechanism.norm-engine-hours' | translate }}</mat-label>
      <input matInput type="number" formControlName="norm">
      <mat-error *ngIf="dailyNormForm.controls['norm'].errors?.required">
        {{ 'message.error.required-field' | translate }}
      </mat-error>
      <mat-error *ngIf="dailyNormForm.controls['norm'].errors?.min">
        {{ 'message.error.min-value' | translate : {min: 0} }}
      </mat-error>
    </mat-form-field>
  </div>
</form>

<form [formGroup]="factorsForm" *ngIf="factorsForm">
  <header class="dialog-title to-left">
    <span>{{ 'mechanism.factors-engine-hours' | translate }}</span>
  </header>

  <div class="form-container vehicle" formArrayName="factors">
    <div class="field" *ngFor="let control of getFactorControls(); index as i">
      <div class="field-name">
        {{ mechanism.modes[i]?.name }}
      </div>

      <mat-form-field>
        <mat-label>{{ 'term.factor' | translate }}</mat-label>
        <input matInput type="number" [formControlName]="i">
        <mat-error *ngIf="control.errors?.min">
          {{ 'message.error.min-value' | translate : {min: 0} }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>

@if (!isNoMechanisms) {
  <ng-content></ng-content> <!-- project actionButtons -->
}
