import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { Layer } from 'ol/layer';
import OlMap from 'ol/Map';
import { Observable } from 'rxjs';
import { AppColor } from '../../../../../../../../common-module/src/lib/app-enums/app-color';
import { Tab } from '../../../../../../../../common-module/src/lib/app-enums/tab';
import { LengthErrorI } from '../../../../../../../../common-module/src/lib/app-interfaces/length-error-i';
import { AppMessageService } from '../../../../../../../../common-module/src/lib/app-services/app-message.service';
import { UiSpinnerService } from '../../../../../../../../common-module/src/lib/app-services/ui-spinner.service';
import { ConfigType } from '../../../../../../../../common-module/src/lib/modelinterfaces/enums/config-type';
import { MaxLength } from '../../../../../../../../common-module/src/lib/modelinterfaces/enums/max-length';
import {
  NotificationTypeRequiredGeofence
} from '../../../../../../../../common-module/src/lib/modelinterfaces/enums/notification-type-required-geofence';
import { RequiredType } from '../../../../../../../../common-module/src/lib/modelinterfaces/enums/required-type';
import { SelectionType } from '../../../../../../../../common-module/src/lib/modelinterfaces/enums/selection-type';
import { Geofence } from '../../../../../../../../common-module/src/lib/modelinterfaces/geofence.model';
import { NotificationFormConfig } from '../../../../../../../../common-module/src/lib/modelinterfaces/notification-form-config.model';
import { NotificationSourceList } from '../../../../../../../../common-module/src/lib/modelinterfaces/notification-source-list.model';
import { NotificationSourceValue } from '../../../../../../../../common-module/src/lib/modelinterfaces/notification-source-value.model';
import { NotificationSource } from '../../../../../../../../common-module/src/lib/modelinterfaces/notification-source.model';
import { NotificationType } from '../../../../../../../../common-module/src/lib/modelinterfaces/notification-type.model';
import { ScheduleRange } from '../../../../../../../../common-module/src/lib/modelinterfaces/schedule-range.model';
import { TelegramChat } from '../../../../../../../../common-module/src/lib/modelinterfaces/telegram-chat.model';
import { NotificationSourceService } from '../../../../../../../../common-module/src/lib/services/notification-source.service';
import { TelegramService } from '../../../../../../../../common-module/src/lib/services/telegram.service';
import { asyncIsExist } from '../../../../../../../../common-module/src/lib/validators/AppValidators';
import { MapSettings } from '../../../../../shared/constants/enums/map-settings';
import { DrawGeofenceService } from '../../../../../shared/services/draw-geofence.service';
import { GeofenceListService } from '../../../../../shared/services/geofence-list.service';
import { OpenLayerMapService } from '../../../../../shared/services/open-layer-map.service';
import { TileLayerService } from '../../../../../shared/services/tile-layer.service';

export enum RuleFormControl {
  LABEL = 'label',
  NAME = 'name',
  TYPE = 'type',
  DESCRIPTION = 'description',
  MESSAGE = 'message',
  PENDING_TIME = 'delay-time',
  GEOFENCE_TRIGGER_MODE = 'geofence-trigger-mode',
  GEOFENCES = 'geofences',
  PUSH_ENABLED = 'push-enabled',
  TELEGRAM_CHATS = 'telegram-chats',
  ACTION_COMMAND = 'action-command',
  ACTIVATED_TEXT = 'activated-text',
  ACTIVATED_TIMEOUT = 'activated-timeout',
  DEACTIVATED_TEXT = 'deactivated-text',
  DEACTIVATED_TIMEOUT = 'deactivated-timeout',
  SCHEDULE = 'schedule'
}

@Component({
  selector: 'app-rule-settings',
  templateUrl: './rule-settings.component.html',
  styleUrls: ['./rule-settings.component.scss', '../../../../../../../../common-module/src/lib/app-styles/dialog-common.scss']
})

export class RuleSettingsComponent implements OnChanges, OnInit, OnDestroy {

  private readonly MAX_TIMEOUT = 14400;

  @Input() currentItem: NotificationType | NotificationSource;
  @Input() currentRule: NotificationSource;
  @Input() formConfig: NotificationFormConfig;

  @Output() changedRuleForm = new EventEmitter<UntypedFormGroup>();

  public collectionColors: Array<AppColor> = [AppColor.PRIMARY, AppColor.YELLOW, AppColor.GREEN, AppColor.BLUE, AppColor.RED, AppColor.GRAY,
    AppColor.AQUA, AppColor.ORANGE, AppColor.PURPLE, AppColor.BLACK, AppColor.MAUVE, AppColor.BROWN,
    AppColor.ACCENT, AppColor.LIGHT_GREY, AppColor.TRACK_GREEN
  ];
  public configType = ConfigType;
  public control = RuleFormControl;
  public geofenceList$: Observable<Geofence[]>;
  public iconColor: AppColor;
  public isOpenMessageContainer = false;
  public map: OlMap;
  public requiredType = RequiredType;
  public ruleForm: UntypedFormGroup;
  public searchGeofence: string;
  public selectionType = SelectionType;
  public telegramChats: TelegramChat[] = [];

  constructor(private appMessageService: AppMessageService,
              private drawGeofenceService: DrawGeofenceService,
              private geofenceListService: GeofenceListService,
              private notificationSourceService: NotificationSourceService,
              private openLayerMapService: OpenLayerMapService,
              private telegramService: TelegramService,
              private tileLayerService: TileLayerService,
              private uiSpinnerService: UiSpinnerService) {
  }

  get formControl(): { [p: string]: AbstractControl } {
    return this.ruleForm.controls;
  }

  get formValue(): any {
    return this.ruleForm.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formConfig'] && this.formConfig) {
      this.ruleForm = this.buildForm();
      this.ruleForm.get(RuleFormControl.NAME).markAsDirty(); // for async validate default value
      this.changedRuleForm.emit(this.ruleForm);
    }
  }

  ngOnInit(): void {
    this.geofenceListService.init();
    this.geofenceList$ = this.geofenceListService.currentGeofences$;
  }

  private buildForm(): UntypedFormGroup {
    const ruleForm = new UntypedFormGroup({
      [RuleFormControl.NAME]: new UntypedFormControl(
        this.currentRule?.name || (this.currentItem as NotificationType).translated,
        [Validators.required, Validators.maxLength(MaxLength.NAME)],
        asyncIsExist(this.notificationSourceService.isRuleExist.bind(this.notificationSourceService), this.currentRule?.name)
      ),
      [RuleFormControl.TYPE]: new UntypedFormControl(
        {
          value: this.currentRule?.typeTranslated || (this.currentItem as NotificationType).translated,
          disabled: true
        },
      ),
      [RuleFormControl.DESCRIPTION]: new UntypedFormControl(
        this.currentRule?.description
      ),
      [RuleFormControl.MESSAGE]: new UntypedFormControl(
        this.currentRule?.message, [Validators.required, Validators.maxLength(MaxLength.SMALL_DESCRIPTION)]
      ),
      [RuleFormControl.PENDING_TIME]: new UntypedFormControl(
        this.currentRule?.pendingSeconds || this.formConfig.pendingSeconds.defaultValue,
        this.setValidatorList(this.formConfig.pendingSeconds)
      ),
      [RuleFormControl.LABEL]: new UntypedFormControl(
        this.currentRule?.color
      ),
      [RuleFormControl.SCHEDULE]: new UntypedFormControl(
        this.currentRule?.timeFilter
      ),
    });
    this.formConfig.getParamsValue().forEach(el => {
      ruleForm.setControl(
        el.name,
        new UntypedFormControl(this.getCurrentParamValue(el.name) || el.defaultValue, this.setValidatorList(el))
      );
    });
    this.formConfig.getParamsList().forEach(el => {
      ruleForm.setControl(el.name, new UntypedFormControl(this.getCurrentParamValue(el.name), this.setValidatorList(el)));
    });
    this.setPushEnabledFormControl(ruleForm);
    this.setTelegramChatsFormControl(ruleForm);
    this.setGeofencesFormControl(ruleForm);
    this.setActionCommandsFormControls(ruleForm);
    return ruleForm;
  }

  private setValidatorList(el: NotificationSourceValue | NotificationSourceList): ValidatorFn[] {
    const validators: ValidatorFn[] = [];
    if (el.requiredType === RequiredType.REQUIRED) {
      validators.push(Validators.required);
    }
    if (el instanceof NotificationSourceValue) {
      validators.push(Validators.min(el.minConstraint));
      validators.push(Validators.max(el.maxConstraint));
    }
    return validators;
  }

  private getCurrentParamValue(key: string): unknown {
    if (!this.currentRule?.params) {
      return null;
    }
    const currentParam = Object.entries(this.currentRule.params).find(item => item[0] === key);
    if (!currentParam) {
      return null;
    }
    if (typeof currentParam[1] === 'number') {
      return currentParam[1].toString();
    } else {
      return currentParam[1];
    }
  }

  private setGeofencesFormControl(form: UntypedFormGroup): void {
    form.setControl(RuleFormControl.GEOFENCE_TRIGGER_MODE, new UntypedFormControl(this.currentRule?.geofenceTriggerMode ?? 'WITHIN', Validators.required))
    form.setControl(RuleFormControl.GEOFENCES, new UntypedFormControl(
      this.currentRule?.geofences, this.isRequiredGeofence(this.currentItem.type) ? Validators.required : null
    ));
  }

  private setPushEnabledFormControl(form: UntypedFormGroup): void {
    if (this.formConfig.actionForm.push) {
      form.setControl(RuleFormControl.PUSH_ENABLED, new UntypedFormControl(
        this.currentRule?.interaction.pushEnabled
      ));
    }
  }

  private setTelegramChatsFormControl(form: UntypedFormGroup): void {
    if (this.formConfig.actionForm.telegram) {
      form.setControl(RuleFormControl.TELEGRAM_CHATS, new UntypedFormControl(
        this.currentRule?.interaction.telegramChats
      ));
    }
  }

  private setActionCommandsFormControls(form: UntypedFormGroup): void {
    if (this.formConfig.actionForm.activateCommand || this.formConfig.actionForm.deactivateCommand) {
      form.setControl(RuleFormControl.ACTION_COMMAND, new UntypedFormControl(
        !!this.currentRule?.interaction.actionCommand?.activate?.text ||
        !!this.currentRule?.interaction.actionCommand?.deactivate?.text
      ));
    }
    if (this.formConfig.actionForm.activateCommand) {
      form.setControl(RuleFormControl.ACTIVATED_TEXT, new UntypedFormControl(
        this.currentRule?.interaction.actionCommand?.activate?.text ?
          this.currentRule?.interaction.actionCommand?.activate?.text : null
      ));
      form.setControl(RuleFormControl.ACTIVATED_TIMEOUT, new UntypedFormControl(
        this.currentRule?.interaction.actionCommand?.activate?.timeout ?
          this.currentRule?.interaction.actionCommand?.activate?.timeout : 0, Validators.max(this.MAX_TIMEOUT)
      ));
    }
    if (this.formConfig.actionForm.deactivateCommand) {
      form.setControl(RuleFormControl.DEACTIVATED_TEXT, new UntypedFormControl(
        this.currentRule?.interaction.actionCommand?.deactivate?.text ?
          this.currentRule?.interaction.actionCommand?.deactivate?.text : null
      ));
      form.setControl(RuleFormControl.DEACTIVATED_TIMEOUT, new UntypedFormControl(
        this.currentRule?.interaction.actionCommand?.deactivate?.timeout ?
          this.currentRule?.interaction.actionCommand?.deactivate?.timeout : 0, Validators.max(this.MAX_TIMEOUT)
      ));
    }
  }

  public isRequiredGeofence(type: string): boolean {
    const typeList = Object.values(NotificationTypeRequiredGeofence);
    return typeList.includes(type as NotificationTypeRequiredGeofence);
  }

  public tabChange(tabIndex: number): void {
    if (tabIndex === 1 && !this.map) {
      this.createMap();
    }
    if (tabIndex === 2) {
      this.loadTelegramChat();
    }
  }

  private createMap(): void {
    const layer: Layer[] = this.tileLayerService.buildCurrentSelectedTile();
    this.map = this.openLayerMapService.create(layer, '', MapSettings.DIALOG_ZOOM);

    setTimeout(() => {
      this.map.setTarget('map-rule');
      this.drawGeofenceService.init(this.map, Tab.NOTIFICATION);
      if (this.currentRule) {
        this.currentRule.geofences.forEach(geofence => this.drawGeofenceService.show(geofence, this.map, Tab.NOTIFICATION));
      }
    }, 0);
  }

  private loadTelegramChat(): void {
    this.uiSpinnerService.show();
    this.telegramService.getList().subscribe(list => {
        this.telegramChats = list;
        if (this.telegramChats.length === 0) {
          this.formControl[RuleFormControl.TELEGRAM_CHATS].disable();
        }
        this.uiSpinnerService.stop();
      }
    );
  }

  public onChangeColor(color: AppColor): void {
    this.iconColor = color;
  }

  public comparisonId = (option: any, value: any): boolean => {
    if (!option || !value) {
      return false;
    }
    return option.id === value.id;
  };

  public asSourceValue(value: NotificationSourceValue | NotificationSourceList): NotificationSourceValue {
    return value as NotificationSourceValue;
  }

  public asSourceList(value: NotificationSourceValue | NotificationSourceList): NotificationSourceList {
    return value as NotificationSourceList;
  }

  public filterGeofences(event: EventTarget): void {
    this.searchGeofence = (event as HTMLInputElement).value;
  }

  public clearFilterGeofences(): void {
    this.searchGeofence = '';
  }

  public toggleGeofence(geofenceOptions: MatOptionSelectionChange): void {
    if (!this.map?.getTarget()) {
      return;
    }
    if (geofenceOptions.source.selected) {
      this.drawGeofenceService.show(geofenceOptions.source.value, this.map, Tab.NOTIFICATION);
    } else {
      this.drawGeofenceService.hide(geofenceOptions.source.value, Tab.NOTIFICATION);
    }
  }

  public toggleMessageContainer(): void {
    this.isOpenMessageContainer = !this.isOpenMessageContainer;
  }

  public receiveSchedule(schedule: ScheduleRange): void {
    this.formControl[RuleFormControl.SCHEDULE].setValue(schedule);
  }

  public asMaxlength(maxlength: LengthErrorI): LengthErrorI {
    return maxlength;
  }

  ngOnDestroy(): void {
    this.geofenceListService.clearSelectedGeofences(Tab.NOTIFICATION);
  }
}
