import OlMap from 'ol/Map';
import {GpsCoordinate} from '../../../../../common-module/src/lib/modelinterfaces/gps-coordinate.model';
import {SensorData} from '../../../../../common-module/src/lib/modelinterfaces/sensor-data.model';
import {MapSettings} from '../constants/enums/map-settings';
import {OlCoordinate} from './OlCoordinate';
import {OlLineString} from './OlLineString';
import {BoundBoxed} from "../../../../../common-module/src/lib/modelinterfaces/bound-boxed.model";
import Feature from "ol/Feature";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import {Extent} from "ol/extent";
import {Size} from "ol/size";

export interface MapCenterOpt {
  zoom?: number;
  padding?: MapPadding;
  duration?: number;
}

export interface MapPadding {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

export class CenterMapUtil {

  /** padding для трека для карты сверху ограничена малым окном информации по треку
   * и снизу карточками информации по объекту*/
  public static MAP_PADDING_TRACK_MAIN: MapPadding = {top: 150, right: 50, bottom: 170, left: 100};

  public static center(obj: GpsCoordinate | GpsCoordinate[] | SensorData[] | BoundBoxed | VectorSource | VectorSource[] | VectorLayer | VectorLayer[] | Feature,
                       map: OlMap,
                       opt?: MapCenterOpt): void {
    opt = CenterMapUtil.optFactory(map, opt);
    const padding = this.olPadding(opt.padding);
    if (obj instanceof VectorLayer) {
      if (obj.getSource().getFeatures().length > 0) {
        this.centerByExtent(obj.getSource().getExtent(), map, padding, opt.duration);
      }
    }
    if (obj instanceof VectorSource) {
      if (obj.getFeatures().length > 0) {
        this.centerByExtent(obj.getExtent(), map, padding, opt.duration);
      }
    }
    if (obj instanceof GpsCoordinate) {
      map.getView().setCenter(OlCoordinate.createFromGpsCoordinate(obj));
      map.getView().setZoom(opt.zoom);
      return;
    }
    if (obj.hasOwnProperty('boundBox') && (<BoundBoxed>obj).boundBox) {
      map.getView().fit((<BoundBoxed>obj).boundBox, {size: map.getSize(), padding, duration: opt.duration});
      return;
    }
    if (obj instanceof Feature) {
      map.getView().fit(obj.getGeometry().getExtent(), {size: map.getSize(), padding, duration: opt.duration})
    }
    if (obj instanceof Array && obj.length > 0) {
      if (obj[0] instanceof GpsCoordinate) {
        const coordinates = obj.map(c => OlCoordinate.createFromGpsCoordinate(c))
        map.getView().fit(OlLineString.build(coordinates), {size: map.getSize(), padding, duration: opt.duration});
        return;
      }
      if (obj[0] instanceof SensorData) {
        const coordinates = obj.map(o => OlCoordinate.createFromGpsCoordinate((o as SensorData).getGpsCoordinate()));
        map.getView().fit(OlLineString.build(coordinates), {size: map.getSize(), padding, duration: opt.duration});
      }
      if (obj[0] instanceof VectorSource) {
        this.centerByVectorSources((obj as VectorSource[]), map, padding, opt);
      }
      if(obj[0] instanceof VectorLayer) {
        const sources = obj.map(vl => (vl as VectorLayer).getSource());
        this.centerByVectorSources(sources, map, padding, opt);
      }
    }
  }

  private static centerByVectorSources(sources: VectorSource[], map: OlMap, padding: number[], opt: MapCenterOpt){
    const arr = sources.filter(vs => !!vs && vs.getFeatures().length > 0);

    if (arr.length > 0) {
      const ext = arr.reduce((prev, curr) => this.extent(prev, curr.getExtent()), ([100_000_000_000, 100_000_000_000, -100_000_000_000, -100_000_000_000] as Extent))
      this.centerByExtent(ext, map, padding, opt.duration);
    }
  }

  private static centerByExtent(ext: Extent, map: OlMap, padding: number[], duration: number) {
    map.getView().fit(ext, {size: map.getSize(), padding, duration});
  }

  private static extent(source: Extent, other: Extent): Extent {
    const result = ([source[0], source[1], source[2], source[3]] as Extent)
    if (other[0] < source[0]) {
      result[0] = other[0];
    }
    if (other[1] < source[1]) {
      result[1] = other[1];
    }
    if (other[2] > source[2]) {
      result[2] = other[2];
    }
    if (other[3] > source[3]) {
      result[3] = other[3];
    }
    return result;
  }

  private static optFactory(map: OlMap, opt?: MapCenterOpt): MapCenterOpt {
    return {
      zoom: opt?.zoom ? (opt.zoom === 0 ? map.getView().getZoom() : opt.zoom) : MapSettings.DEFAULT_ZOOM,
      duration: opt?.duration ? opt.duration : 700,
      padding: {
        top: opt?.padding?.top ? opt.padding.top : map.getSize()[1] * 0.1,
        left: opt?.padding?.left ? opt.padding.left : map.getSize()[0] * 0.1,
        bottom: opt?.padding?.bottom ? opt.padding.bottom : map.getSize()[1] * 0.1,
        right: opt?.padding?.right ? opt.padding.right : map.getSize()[0] * 0.1,
      }
    }
  }

  private static olPadding(padding: MapPadding): number[] {
    return [padding.top, padding.right, padding.bottom, padding.left];
  }
}
