import {ConnectionState} from './connection-state.model';
import {Driver} from './driver.model';
import {GpsState} from './gps-state.model';
import {ParameterizedMessage} from "./paramitized-message.model";

export class UnitState {

  constructor(public gpsState: GpsState,
              public connectionState: ConnectionState,
              public driver: Driver,
              public warnings: ParameterizedMessage[],
              public alerts: ParameterizedMessage[]
  ) {
  }

  public static valueOf(o: UnitState): UnitState {
    return o ?
      new UnitState(GpsState.valueOf(o.gpsState), ConnectionState.valueOf(o.connectionState), Driver.valueOf(o.driver),
        ParameterizedMessage.valuesOf(o.warnings), ParameterizedMessage.valuesOf(o.alerts))
      : null;
  }
}
