import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageParams } from '../modelinterfaces/page-params.model';
import { Page } from '../modelinterfaces/page.model';
import { UnitShort } from '../modelinterfaces/unit-short.model';
import { HttpParamsUtil } from '../utils/http-params-util';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class UnitShortService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getData(hasEcoDrivingCriterion = false): Observable<UnitShort[]> {
    const url = `${this.environmentHelper.urlV2}/unitShort`;

    let params = new HttpParams();
    if (hasEcoDrivingCriterion) {
      params = params.set('hasEcoDrivingCriterion', true)
    }

    return this.http.get<UnitShort[]>(url, {params: params}).pipe(
      map(list => UnitShort.valuesOf(list))
    );
  }

  public getPaginalList(pageParams: PageParams, filter: Map<string, string>): Observable<Page<UnitShort>> {
    const url = `${this.environmentHelper.urlV2}/unitShort/page`;
    const params: HttpParams = HttpParamsUtil.createByPageAndFilter(pageParams, filter);
    return this.http.get<Page<UnitShort>>(url, {params: params})
      .pipe(
        map(value => {
          value.urlParams = params;
          return UnitShort.pageableValueOf(value);
        })
      );
  }
}
