<form [formGroup]="editForm" *ngIf="editForm">
  <div class="form-container vehicle">

    <app-color-picker-input [formControlName]="control.COLOR">
    </app-color-picker-input>

    <mat-form-field>
      <mat-icon *ngIf="formControl[control.NAME].pending" class="show-spinner">
        <mat-spinner diameter="20"></mat-spinner>
      </mat-icon>

      <mat-label>{{ 'unit.name' | translate }}</mat-label>
      <input matInput
             type="text"
             [formControlName]="control.NAME">
      <mat-error *ngIf="formControl[control.NAME].errors?.required">
        {{ 'message.error.required-field' | translate }}
      </mat-error>
      <mat-error *ngIf="formControl[control.NAME].errors?.pattern">
        {{ 'message.error.contains-illegal-symbols' | translate }}
      </mat-error>
      <mat-error *ngIf="formControl[control.NAME].errors?.maxlength">
        {{
          'message.error.max-length-symbols' | translate :
            {max: asMaxlength(formControl.name.errors.maxlength).requiredLength}
        }}
      </mat-error>
      <mat-error *ngIf="formControl[control.NAME].errors?.nameIsUsed">
        {{ 'message.error.already-exist' | translate }}
      </mat-error>
    </mat-form-field>

    <div>
      <div class="example">
        <img [src]="UNIT_MOVING" alt="unit-moving">
        <span
          [ngStyle]="{'background-color': this.formControl[control.COLOR].value}">{{ formControl[control.NAME].value }}</span>
      </div>
    </div>
  </div>
</form>
<ng-content></ng-content> <!-- project actionButtons -->
