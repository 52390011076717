import {Component, EventEmitter, Output} from '@angular/core';
import {ActionAbstractButtonComponent} from "../../../common/concrete-button/action-abstract-button.component";

@Component({
  selector: 'app-button-reset',
  templateUrl: './button-reset.component.html',
})
export class ButtonResetComponent extends ActionAbstractButtonComponent {
  @Output() reset = new EventEmitter<MouseEvent>();

  onAction(event: MouseEvent): void {
    this.reset.emit(event);
  }
}
