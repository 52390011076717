<app-dialog [title]="'driver.remove' | translate">

  <ng-container appDialogContent>
    <div class="margin-16">
      {{ 'driver.will-be-removed' | translate: {fullDriverName: data?.driver?.getFullName(), unitName: data?.unit?.name} }}
    </div>
  </ng-container>

  <ng-container appDialogActions>
    <app-button-cancel></app-button-cancel>
    <app-button-primary [text]="'action.remove' | translate" (action)="onRemove()"></app-button-primary>
  </ng-container>

</app-dialog>
