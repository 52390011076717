import {ScheduleRange} from "../../../../../../../../../common-module/src/lib/modelinterfaces/schedule-range.model";
import {RangeDayTime} from "../../../../../../../../../common-module/src/lib/modelinterfaces/range-day-time.model";
import {TimeTableRectangleIterator, TimeTableSerialIterator} from "./TimeTableIterator";
import {ScheduleFactory} from "./ScheduleFactory";

export interface TimeCellI {

  /**
   * starts from 0;
   */
  weekday: number;

  /**
   * seconds of day 0 - 86399
   */
  timeFrom: number;

  /**
   * seconds of day 0 - 86399
   */
  timeTo: number;

  /**
   * this cell is selected
   */
  isSelected: boolean;
}

/**
 * Main table that store selected states
 */
export class TimeTable {

  public static WEEK_DAY_NAMES = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];

  constructor(public rows: TimeCellI[][],
              public step: number) {
  }

  public getDayName(cell: TimeCellI) {
    return TimeTable.WEEK_DAY_NAMES[cell.weekday];
  }

  /**
   * клетки {@param a} и {@param b} образуют прямоугольник
   * эти клетки заполняются в соответсвии {@param isSelected}
   *  - - - - - - - - - - - - - -
   *  - - - a $ $ $ $ $ - - - - -
   *  - - - $ $ $ $ $ $ - - - - -
   *  - - - $ $ $ $ $ b - - - - -
   *  - - - - - - - - - - - - - -
   */
  public setSelectedBetween(a: TimeCellI, b: TimeCellI, isSelected: boolean) {
    let iterator = this.rectangleIterator(a, b);
    while (iterator.hasNext()) {
      iterator.next().isSelected = isSelected;
    }
  }

  /**
   * @return convert current state to {@link ScheduleRange}
   */
  public toSchedule(): ScheduleRange {
    return ScheduleFactory.create(this);
  }

  public serialIterator(range: RangeDayTime): TimeTableSerialIterator {
    return new TimeTableSerialIterator(this, range);
  }

  private rectangleIterator(a: TimeCellI, b: TimeCellI) {
    return new TimeTableRectangleIterator(this, a, b);
  }
}
