import { ContentType } from './content-type.model';
import { Camera } from './camera.model';
import { DvrFileStatus } from './enums/dvr-file-status';
import { DvrFileType } from './enums/dvr-file-type';
import { PageModel } from './page-model.model';
import { Page } from './page.model';

export class DvrFile {

  constructor(public name: string,
              public camera: Camera,
              public time: string,
              public contentType: ContentType,
              public fileType: DvrFileType,
              public fileStatus: DvrFileStatus,
              public trackerDir: string,
              public path: string,
              public size: number,
              public contentTypeTranslated: string) {
  }

  public static valueOf(o: DvrFile): DvrFile {
    return o ? new DvrFile(o.name, Camera.valueOf(o.camera), o.time, ContentType.valueOf(o.contentType), o.fileType, o.fileStatus, o.trackerDir, o.path,
      o.size, o.contentTypeTranslated) : null;
  }

  public static valuesOf(list: DvrFile[]): DvrFile[] {
    return list ? list.map(o => DvrFile.valueOf(o)) : [];
  }

  public static pageableValueOf(o: Page<DvrFile>): Page<DvrFile> {
    return o ? new PageModel<DvrFile>(o.empty, o.first, o.last, o.number, o.numberOfElements, o.totalElements, o.totalPages,
      o.urlParams, DvrFile.valuesOf(o.content)) : null;
  }
}
