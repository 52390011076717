import {Coordinate} from 'ol/coordinate';
import Feature from 'ol/Feature';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import {PointCoordType} from 'ol/interaction/Draw';
import Style from 'ol/style/Style';
import {AppColor} from '../../../../../common-module/src/lib/app-enums/app-color';
import {Geofence} from '../../../../../common-module/src/lib/modelinterfaces/geofence.model';
import {OlCoordinate} from './OlCoordinate';
import {OlStyle} from './OlStyle';
import {GpsCoordinate} from "../../../../../common-module/src/lib/modelinterfaces/gps-coordinate.model";
import {Circle} from "ol/geom";
import {transform} from "ol/proj";
import {UrlImage} from "../constants/url-image";
import {
  JobPointType
} from "../../system/logistic/logistic/logistic-order-list/job-dialog/impl/abstract-job-dialog/map-manager/job-feature";
import {Logistic} from "../../../../../common-module/src/lib/modelinterfaces/logistic.model";
import {OlLineString} from "./OlLineString";
import {Polyline} from "ol/format";
import {Stroke} from "ol/style";
import {LogisticStageActionUtil} from "../../system/logistic/common/utils/logistic-stage-action.util";
import SolutionStage = Logistic.Stage;
import StageType = Logistic.StageAction;

export interface LineStyleOpt {
  color?: string;
  width?: number;
}

export class OlFeature {

  public static createIcon(point: Point, text: string, iconStyle: Style, color: string, id: number, description: string = ''): Feature {
    const iconFeature = new Feature({
      geometry: point,
      name: text,
      id: id,
      description: description,
      population: 4000,
      rainfall: 500,
    });
    iconFeature.setStyle([OlStyle.createForIconText(text, color), iconStyle]);
    return iconFeature;
  }

  public static createRotatedIcon(point: Point, text: string, course: number, imageUrl: string, color: string, id: number): Feature {
    const rotatedIconStyle = OlStyle.createForRotatedIcon(course, imageUrl);
    return this.createIcon(point, text, rotatedIconStyle, color, id);
  }

  public static createMarker(imageUrl: string, pointCoordinate: Point): Feature {
    const marker = new Feature({
      type: 'icon',
      geometry: pointCoordinate
    });
    marker.setStyle(OlStyle.createForIconMarker(imageUrl));
    return marker;
  }

  public static createMarkerWithStyle(markerStyle: Style, pointCoordinate: Point): Feature {
    const marker = new Feature({
      type: 'icon',
      geometry: pointCoordinate
    });
    marker.setStyle(markerStyle);
    return marker;
  }

  public static createAnimationMarker(coords: number[]): Feature {
    const marker = new Feature(
      {
        type: 'animationMarker',
        geometry: new Point(coords),
      });
    marker.setStyle(OlStyle.createForAnimationMarker());
    return marker;
  }

  public static createLine(a: GpsCoordinate, b: GpsCoordinate, color: AppColor | string, width: number = 3): Feature {
    const line = new Feature({
      type: 'route',
      geometry: OlLineString.fromPoints(a, b)
    });
    line.setStyle(OlStyle.createForRoute(color, width));
    return line;
  }

  public static createArrow(from: GpsCoordinate, to: GpsCoordinate, color: AppColor): Feature[] {
    return OlFeature.createArrowLine(OlLineString.fromPoints(from, to), color);
  }

  public static createArrowLine(route: LineString, color: AppColor): Feature[] {
    const line = new Feature({
      type: 'route',
      geometry: route
    });
    line.setStyle(OlStyle.createForRoute(color))
    let features = this.createTrackArrows(line, color);
    features.push(line);
    return features;
  }

  public static createRoute(route: LineString, trackColor: AppColor, description: string = ''): Feature[] {
    let features = [];
    const routeFeature = new Feature({
      type: 'route',
      description: description,
      geometry: route
    });
    routeFeature.setStyle(OlStyle.createForRoute(trackColor));
    features.push(routeFeature);
    features = features.concat(OlFeature.createTrackArrows(routeFeature, trackColor));
    return features;
  }

  public static createTrackArrows(route: Feature, trackColor: AppColor): Feature[] {
    const featureArrows = [];
    const geometry = <LineString>route.getGeometry();
    let j = 0;
    let arrowSegmentPace = 50;
    geometry.forEachSegment((start, end) => {
      if (j % arrowSegmentPace === 0) {
        if (end[0] !== start[0]) {
          const dx = end[0] - start[0];
          const dy = end[1] - start[1];
          const rotation = Math.atan2(dy, dx);
          featureArrows.push(OlFeature.createTrackArrow(end, rotation, trackColor));
          arrowSegmentPace = 50;
          j++;
        } else {
          arrowSegmentPace = 1;
          j++;
        }
      } else {
        j++;
      }
    });
    return featureArrows;
  }

  private static createTrackArrow(coords: PointCoordType, rotation: number, trackColor: AppColor): Feature {
    const pointFeature = new Feature({
      type: 'arrow',
      geometry: new Point(coords)
    });
    pointFeature.setStyle(OlStyle.createForTrackArrow(rotation, trackColor));
    return pointFeature;
  }

  public static createTrackPoints(lineString: LineString): Feature[] {
    const featurePoints = [];
    const coordinatesList = lineString.getCoordinates();
    let j = 0;
    const trackPointSegmentPace = 30;
    for (let i = 0; i < coordinatesList.length; i++) {
      if (j % trackPointSegmentPace === 0) {
        featurePoints.push(OlFeature.createTrackPoint(coordinatesList[i]));
      }
      j++;
    }
    return featurePoints;
  }

  private static createTrackPoint(coords: PointCoordType): Feature {
    const pointFeature = new Feature({
      type: 'point',
      geometry: new Point(coords)
    });
    pointFeature.setStyle(OlStyle.createForTrackPoint());
    return pointFeature;
  }

  public static createViolationPoint(coords: Coordinate, color: string, description: string): Feature {
    const pointFeature = new Feature({
      type: 'violation-point',
      geometry: new Point(coords),
      description: description
    });
    pointFeature.setStyle(OlStyle.createForViolationPoint(color));
    return pointFeature;
  }

  public static createCheckPoints(coordsRoute: Array<number[]>, indexes: [number, number]): Feature[] {
    let firstIndex = coordsRoute.findIndex(el => el[2] === indexes[0]);
    if (firstIndex === -1) {
      firstIndex = 0;
    }
    let lastIndex = firstIndex + (indexes[1] - indexes[0]);
    if (lastIndex > coordsRoute.length - 1) {
      lastIndex = coordsRoute.length - 1;
    }
    const featurePointerMovePoints = [];
    for (let i = firstIndex; i < lastIndex; i++) {
      featurePointerMovePoints.push(OlFeature.createCheckPoint(coordsRoute[i]));
    }
    return featurePointerMovePoints;
  }

  private static createCheckPoint(coords: number[]): Feature {
    const pointFeature = new Feature({
      type: 'checkPoint',
      geometry: new Point(coords)
    });
    pointFeature.setStyle(OlStyle.createForCheckPoint());
    return pointFeature;
  }

  public static createVideoPoint(
    coords: number[], name: string, cameraId: number, description: string, urlImage: string, isReady: boolean
  ): Feature {
    const pointFeature = new Feature({
      type: 'videoPoint',
      geometry: new Point(coords),
      name: name,
      cameraId: cameraId,
      description: description
    });
    pointFeature.setStyle([OlStyle.createForVideoPoint(), OlStyle.createVideoIcon(urlImage, isReady)]);
    return pointFeature;
  }

  public static createTrace(coords: Coordinate[]): Feature {
    if (coords.length === 0) {
      return null;
    }
    const traceFeature = new Feature({
      geometry: new LineString(coords),
    });
    const traceStyles = [OlStyle.createForTrace()];

    (traceFeature.getGeometry() as LineString).forEachSegment((start) => {
      traceStyles.push(OlStyle.createForTraceSegment(start));
    });
    traceFeature.setStyle(traceStyles);
    return traceFeature;
  }

  public static createGeofencePolygon(g: Geofence, opacity: number): Feature {
    const coordinates: Coordinate[] = g.coordinates.map(c => OlCoordinate.createFromGpsCoordinate(c));
    const f = new Feature({
      geometry: new Polygon([coordinates]),
      name: g.name,
    });
    f.setStyle([OlStyle.createForGeofencePolygon(g.color, opacity), OlStyle.createGeofenceLabel(g.name)]);
    return f;
  }

  private static readonly JOB_IMAGE_URL = new Map([
    [JobPointType.DELIVERY, UrlImage.DELIVERY_POINT],
    [JobPointType.DELIVERY_NON_EDITABLE, UrlImage.DELIVERY_POINT],
    [JobPointType.DELIVERY_EDITABLE, UrlImage.DELIVERY_POINT],
    [JobPointType.PICKUP, UrlImage.PICKUP_POINT],
    [JobPointType.PICKUP_NON_EDITABLE, UrlImage.PICKUP_POINT],
    [JobPointType.PICKUP_EDITABLE, UrlImage.PICKUP_POINT],
    [JobPointType.JOB, UrlImage.JOB_POINT],
  ]);

  public static createLogisticFeature(gpsCoordinate: GpsCoordinate, type: JobPointType, iconColor: AppColor, label?: string, labelColor: AppColor = AppColor.PRIMARY): Feature<Point> {
    const pointFeature = new Feature<Point>({
      geometry: new Point(OlCoordinate.createFromGpsCoordinate(gpsCoordinate)),
      type: 'job-point'
    });
    if (type === JobPointType.HIDDEN) {
      return pointFeature;
    }
    let image = this.JOB_IMAGE_URL.get(type) || UrlImage.WAREHOUSE_POINT;

    const styles = [
      OlStyle.createCircle(12, AppColor.WHITE, AppColor.WHITE, 2),
      OlStyle.createIcon(image, iconColor, [24, 24]),
    ]
    if (label) {
      styles.push(OlStyle.createForIconText(label, labelColor))
    }
    pointFeature.setStyle(styles);
    return pointFeature;
  }

  public static createCarrierGarage(gpsCoordinate: GpsCoordinate, state: 'start' | 'end'): Feature<Point> {
    const pointFeature = new Feature<Point>({
      geometry: new Point(OlCoordinate.createFromGpsCoordinate(gpsCoordinate)),
      type: 'garage-point'
    });
    const image = UrlImage.PLACE_POINT;

    if (state === 'start') {
      pointFeature.setStyle([
        OlStyle.createCircle(12, AppColor.WHITE, AppColor.WHITE, 2),
        OlStyle.createIcon(image, AppColor.GREEN, [24, 24]),
        OlStyle.createForIconText('Гараж на начало смены', AppColor.GREEN)
      ]);
    } else {
      pointFeature.setStyle([
        OlStyle.createCircle(12, AppColor.WHITE, AppColor.WHITE, 2),
        OlStyle.createIcon(image, AppColor.RED, [24, 24]),
        OlStyle.createForIconText('Гараж на конец смены', AppColor.RED)
      ]);
    }
    return pointFeature;
  }

  public static createSolutionFeature(stage: SolutionStage): Feature<Point> {
    const pointFeature = new Feature<Point>({
      geometry: new Point(OlCoordinate.createFromGpsCoordinate(stage.step.getCoordinate())),
      type: 'stage-point'
    });
    let image;
    image = this.getStageImage(stage)

    pointFeature.setStyle([
      OlStyle.createCircle(12, AppColor.WHITE, AppColor.WHITE, 2),
      OlStyle.createIcon(image, AppColor.PRIMARY, [24, 24])]);
    return pointFeature;
  }

  public static createSolutionFeatureStageGroup(stage: Logistic.StageGroup, index: number, color: AppColor): Feature<Point> {
    const pointFeature = new Feature<Point>({
      geometry: new Point(OlCoordinate.createFromGpsCoordinate(stage.getCoordinate())),
      type: 'stage-point'
    });

    const statusColor = LogisticStageActionUtil.wrapperColorStageGroup(stage);

    pointFeature.setStyle([
      OlStyle.createCircleWithNumber(12, AppColor.WHITE, statusColor, index, color, 4),
    ]);
    return pointFeature;
  }

  public static getStageImage(stage: SolutionStage): string {
    switch (stage.action) {
      case StageType.DELIVERY:
        return UrlImage.DELIVERY_POINT;
      case StageType.PICKUP :
        return UrlImage.PICKUP_POINT;
      case StageType.START :
        return UrlImage.ROUTE_START_POINT;
      case StageType.END :
        return UrlImage.ROUTE_END_POINT;
      case StageType.JOB:
        return UrlImage.JOB_POINT;
      default:
        return UrlImage.PARKING_POINT;
    }
  }

  public static createCircle(center: GpsCoordinate, radiusMeters: number) {
    return new Feature({
      geometry: new Circle(this.transformCoordinates(center), radiusMeters),
    });
  }

  public static createPoint(center: GpsCoordinate, styles: Style[], type = 'point'): Feature<Point> {
    const pointFeature = new Feature<Point>({
      geometry: new Point(OlCoordinate.createFromGpsCoordinate(center)),
      type: type
    });
    pointFeature.setStyle(styles);
    return pointFeature;
  }

  private static transformCoordinates(gpsCoordinate: GpsCoordinate): Coordinate {
    return transform([gpsCoordinate.longitude, gpsCoordinate.latitude], 'EPSG:4326', 'EPSG:3857')
  }

  public static createFromStringGeometry(geometry: string, opt?: LineStyleOpt): Feature {

    const color = opt.color || AppColor.BLUE;
    const width = opt.width || 2;

    const polyline = new Polyline({
      factor: 1e5
    });

    const lineString = polyline.readGeometry(geometry, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857'
    });

    const lineStyle = new Style({
      stroke: new Stroke({
        color: color,
        width: width
      })
    });
    const feature = new Feature(lineString);
    feature.setStyle(lineStyle);
    return feature;
  }
}
