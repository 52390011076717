import {Geometry} from "ol/geom";
import {AbstractFeatureWrapper} from "./abstract.feature-wrapper";
import {Feature} from "ol";
import {StyleFactory, StyleOpts} from "../style.factory";

/**
 * The `AbstractValueFeature` class extends `AbstractFeatureWrapper` to provide a way to associate
 * a value of type `V` with a geographical feature. This class is useful for applications
 * that need to attach additional metadata or model-specific data to a feature. The value
 * is stored as a property within the feature's properties, using a standard property name.
 *
 * @template T The geometry type that extends `Geometry`, indicating the type of geometry
 *             the feature will contain.
 * @template V The type of the value that will be associated with the feature.
 */
export abstract class AbstractValueFeatureWrapper<T extends Geometry, OPTS extends StyleOpts, V> extends AbstractFeatureWrapper<T, OPTS> {

  public static readonly PROPERTY_NAME_VALUE = 'value';

  constructor(feature: Feature<T>,
              styleFactory: StyleFactory<OPTS>,
              sourceOpts: OPTS,
              value: V) {
    super(feature, styleFactory, sourceOpts);
    this.setValue(value);
  }

  /**
   * Sets the value associated with the feature.
   *
   * @param {V} value The value to be associated with the feature.
   */
  public setValue(value: V) {
    super.setProperty(AbstractValueFeatureWrapper.PROPERTY_NAME_VALUE, value);
  }

  /**
   * Retrieves the value associated with the feature.
   *
   * @return {V} The value associated with the feature.
   */
  public getValue(): V {
    return super.getProperty(AbstractValueFeatureWrapper.PROPERTY_NAME_VALUE);
  }
}
