import { RawData } from './raw-data.model';
import { UnitOfMeasure } from './unit-of-measure.model';

export class Sensor {

  constructor(
    public readonly id: number,
    public name: string,
    public type: string,
    public calibrationTable: RawData[],
    public unitOfMeasure: UnitOfMeasure,
    public options: object,
    public visible: boolean,
    public nameTranslated: string,
    public typeTranslated: string,
    public formulaRaw: string,
    public conditionRaw: string,
  ) {
  }

  public static valueOf(i: Sensor): Sensor {
    return i ? new Sensor(i.id, i.name, i.type, i.calibrationTable, i.unitOfMeasure,
      i.options, i.visible, i.nameTranslated, i.typeTranslated, i.formulaRaw, i.conditionRaw) : null;
  }

  public static valuesOf(list: Sensor[]): Sensor[] {
    return list ? list.map(o => Sensor.valueOf(o)) : [];
  }
}
