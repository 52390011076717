<app-dialog [title]=" 'version.about' | translate">

  <ng-container appDialogContent>
    <div class="app-font ">

      <h5 class="subheader">GUI user-portal: {{ getUserGuiVersion() }}</h5>

      <h5 class="subheader">GUI admin-portal: {{ getAdminGuiVersion() }}</h5>

      <h5 class="subheader">{{ 'version.application' | translate }}: {{ (version$ | async)?.appVersion }}</h5>

      <mat-divider></mat-divider>

      <h5 class="subheader">{{ 'version.metrological' | translate }}: {{ (version$ | async)?.metrVersion }}</h5>
      <h5>md5:{{ (version$ | async)?.metrChecksum }}</h5>

    </div>
  </ng-container>

</app-dialog>
