import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

import GeometryType from 'ol/geom/GeometryType';
import Feature from 'ol/Feature';
import Map from 'ol/Map';
import Overlay from 'ol/Overlay';
import OverlayPositioning from 'ol/OverlayPositioning';
import {SelectEvent} from 'ol/interaction/Select';
import {Draw, Modify, Snap} from 'ol/interaction';
import {DrawEvent} from 'ol/interaction/Draw';
import {EventsKey} from 'ol/events';
import {LineString, Polygon} from 'ol/geom';
import {ModifyEvent} from 'ol/interaction/Modify';
import {Vector as VectorLayer} from 'ol/layer';
import {Vector as VectorSource} from 'ol/source';
import {unByKey} from 'ol/Observable';
import {MapBrowserEvent} from 'ol';

import {MeasureUtils} from './measure.utils';
import {OlDraw} from '../../../../shared/map-utils/OlDraw';
import {OlVectorLayer} from '../../../../shared/map-utils/OlVectorLayer';
import {OlClassName} from '../../../../shared/constants/enums/ol-class-name';


export enum MeasureState {
  DEFAULT = 'default',
  STOP = 'stop'
}

@Injectable({providedIn: 'root'})

export class MeasureManagerService {

  private stateMeasuring = new BehaviorSubject<MeasureState | GeometryType>(MeasureState.DEFAULT);
  private stateMeasuring$: Observable<MeasureState | GeometryType> = this.stateMeasuring.asObservable();

  private btnDelMeasure: HTMLButtonElement;
  private draw: Draw;
  private measureTooltipElement: HTMLElement;
  private measureTooltip: Overlay;
  private listener: EventsKey;
  private map: Map;
  private measureIndex = 1;
  private modify: Modify;
  private modifySketch: Feature;
  private modifyListener: EventsKey;
  private sketch: Feature;
  private snap: Snap;
  private source: VectorSource;
  private tooltipCoords: Array<number>;
  private valueType: GeometryType;
  private vector: VectorLayer;

  constructor(private translateService: TranslateService) {
  }

  public init(map: Map): void {
    this.map = map;
  }

  public changeState(value: MeasureState | GeometryType): void {
    this.stateMeasuring.next(value);
    this.checkState(value);
  }

  public state$(): Observable<MeasureState | GeometryType> {
    return this.stateMeasuring$;
  }

  private checkState(value: MeasureState | GeometryType): void {
    if ((<string[]>Object.values(GeometryType)).includes(value as string)) {
      this.stopMeasuring();
      this.start(value as GeometryType);
    }
    if (value === MeasureState.STOP) {
      this.stopMeasuring();
    }
  }

  private start(value: GeometryType): void {
    if (!this.source) {
      this.source = new VectorSource();
    }
    if (!this.vector) {
      this.vector = OlVectorLayer.createMeasuringVectorLayer(this.source);
      this.map.addLayer(this.vector);
    }

    this.map.on('pointermove', this.pointerMoveHandler.bind(this));
    this.modify = new Modify({
      source: this.source,
    });
    this.map.addInteraction(this.modify);
    this.addInteraction(value);
  }

  private stopMeasuring(): void {
    this.map.removeInteraction(this.draw);
    this.map.removeInteraction(this.modify);
    this.map.removeInteraction(this.snap);
    this.sketch = null;
    this.valueType = null;
  }

  private addInteraction(valueType: GeometryType): void {
    this.draw = OlDraw.buildMeasure(this.source, valueType);
    this.map.addInteraction(this.draw);
    this.snap = new Snap({source: this.source});
    this.map.addInteraction(this.snap);
    this.createMeasureTooltip(this.measureIndex);
    this.draw.on('drawstart', this.drawStart.bind(this));
    this.draw.on('drawend', this.drawEnd.bind(this));
    this.modify.on('modifystart', this.modifyStart.bind(this));
    this.modify.on('modifyend', this.modifyEnd.bind(this));
  }

  private createMeasureTooltip(index: number): void {
    if (this.measureTooltipElement) {
      this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement);
    }
    this.measureTooltipElement = document.createElement('div');
    this.measureTooltipElement.className = OlClassName.TOOLTIP_MEASURE;
    this.measureTooltip = new Overlay({
      element: this.measureTooltipElement,
      id: index,
      offset: [0, -15],
      positioning: OverlayPositioning.BOTTOM_CENTER
    });
    this.map.addOverlay(this.measureTooltip);
  }

  private drawStart(evt: DrawEvent): void {
    this.sketch = evt.feature;
    this.listener = this.sketch.getGeometry().on('change', this.setCoords.bind(this));
  }

  private drawEnd(evt: DrawEvent): void {
    evt.feature.setId(this.measureIndex);
    this.measureTooltipElement.className = OlClassName.TOOLTIP_STATIC;
    this.measureTooltip.setOffset([0, -7]);
    this.measureTooltip.setPosition(this.tooltipCoords);
    this.addBtnDelMeasure(this.sketch.getId().toString());
    this.sketch = null;
    this.measureTooltipElement = null;
    this.measureIndex++;
    this.createMeasureTooltip(this.measureIndex);
    unByKey(this.listener);
  }

  private addBtnDelMeasure(id: string): void {
    this.btnDelMeasure = document.createElement('button');
    this.btnDelMeasure.classList.add('delete-measure-button');
    this.btnDelMeasure.setAttribute('id', id);
    this.measureTooltipElement.appendChild(this.btnDelMeasure);
    this.btnDelMeasure.addEventListener('click', this.deleteMeasure.bind(this));
  }

  private deleteMeasure(event: MouseEvent): void {
    const id = (event.target as HTMLElement).getAttribute('id');
    const feature = this.source.getFeatureById(id);
    this.source.removeFeature(feature);
    const measureTooltip = this.map.getOverlayById(id);
    this.map.removeOverlay(measureTooltip);
  }

  private modifyStart(evt: ModifyEvent): void {
    this.modifySketch = evt.features.getArray()[0];
    const modifiedMeasureIndex = this.modifySketch.getId();
    this.measureTooltip = this.map.getOverlayById(modifiedMeasureIndex);
    this.measureTooltipElement = this.measureTooltip.getElement();
    this.measureTooltipElement.className = OlClassName.TOOLTIP_MEASURE;
    this.modifyListener = this.modifySketch.getGeometry().on('change', this.setCoords.bind(this));
  }

  private modifyEnd(): void {
    this.measureTooltipElement.className = OlClassName.TOOLTIP_STATIC;
    this.measureTooltip.setOffset([0, -7]);
    this.addBtnDelMeasure(this.modifySketch.getId().toString());
    this.measureTooltipElement = null;
    this.modifySketch = null;
    this.measureTooltip = this.map.getOverlayById(this.measureIndex);
    this.measureTooltipElement = this.measureTooltip.getElement();
    unByKey(this.modifyListener);
  }

  private setCoords(event: Event): void {
    const geom: EventTarget = event.target;
    let output: string;
    if (geom instanceof Polygon) {
      output = MeasureUtils.formatArea(geom).value1 + this.translateService.instant(MeasureUtils.formatArea(geom).uom1)
        + '<sup>2</sup><br /> (' + MeasureUtils.formatArea(geom).value2
        + this.translateService.instant(MeasureUtils.formatArea(geom).uom2) + ')';
      this.tooltipCoords = geom.getInteriorPoint().getCoordinates();
      if (this.modifySketch) {
        this.measureTooltip.setPosition(this.tooltipCoords);
      }
    } else if (geom instanceof LineString) {
      output = MeasureUtils.formatLength(geom).value + this.translateService.instant(MeasureUtils.formatLength(geom).uom);
      this.tooltipCoords = geom.getLastCoordinate();
      this.measureTooltip.setPosition(this.tooltipCoords);
    }
    this.measureTooltipElement.innerHTML = output;
  }

  private pointerMoveHandler = (evt: MapBrowserEvent): void => {
    if (evt.dragging) {
      return;
    }
    if (this.measureTooltipElement.innerHTML && this.measureTooltipElement.classList.contains(OlClassName.TOOLTIP_MEASURE)) {
      this.measureTooltip.setPosition(evt.coordinate);
    }
  };

  public removeMeasures(): void {
    this.stopMeasuring();
    this.map.getOverlays().clear();
    this.source.clear();
    this.map.removeLayer(this.vector);
    this.vector = null;
  }
}
