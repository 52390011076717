import { ConfigType } from './enums/config-type';
import { RequiredType } from './enums/required-type';
import { SelectionType } from './enums/selection-type';
import { NotificationSourceBase } from './notification-source-base.model';
import { UnitOfMeasure } from './unit-of-measure.model';
import { TranslatedString } from './translated-string.model';

export class NotificationSourceList extends NotificationSourceBase {

  constructor(public name: string,
              public nameTranslated: string,
              public requiredType: RequiredType,
              public configType: ConfigType,
              public unitOfMeasure: UnitOfMeasure,
              public selectionType: SelectionType,
              public values: TranslatedString[]) {
    super(name, nameTranslated, requiredType, configType, unitOfMeasure);
  }

  public static valueOf(i: NotificationSourceList): NotificationSourceList {
    return i ? new NotificationSourceList(i.name, i.nameTranslated, i.requiredType, i.configType, i.unitOfMeasure,
      i.selectionType, TranslatedString.valuesOf(i.values)) : null;
  }

  public static valuesOf(list: NotificationSourceList[]): NotificationSourceList[] {
    return list ? list.map(o => NotificationSourceList.valueOf(o)) : [];
  }
}
