import { EcoDrivingCriterion } from './eco-driving-criterion.model';
import { CriterionType } from './enums/criterion-type';

export class EcoDrivingCriterionView {

  constructor(public criteria: EcoDrivingCriterion[],
              public availableTypes: CriterionType[]) {
  }

  public static valueOf(o: EcoDrivingCriterionView): EcoDrivingCriterionView {
    return o ? new EcoDrivingCriterionView(EcoDrivingCriterion.valuesOf(o.criteria), o.availableTypes) : null;
  }
}
