import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {EnvironmentHelper} from "./environment-helper";
import {Observable} from "rxjs";
import {GeoJSON} from "geojson";

@Injectable({
  providedIn: 'root'
})
export class MileageClassifierService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
    console.log(this.environmentHelper)
  }

  public list(): Observable<GeoJSON> {
    const url = `${this.environmentHelper.baseUrl}/mileage-classifier/api/v1/cityFeature`;
    return this.http.get<GeoJSON>(url);
  }
}
