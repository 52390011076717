import {Injectable} from '@angular/core';
import SelectCluster from 'ol-ext/interaction/SelectCluster';
import {easeOut} from 'ol/easing';
import Point from 'ol/geom/Point';
import {Layer} from 'ol/layer';
import OlMap from 'ol/Map';
import {unByKey} from 'ol/Observable';
import {FrameState} from 'ol/PluggableMap';
import {getVectorContext} from 'ol/render';
import RenderEvent from 'ol/render/Event';
import VectorContext from 'ol/render/VectorContext';
import {BehaviorSubject, Observable} from 'rxjs';
import {GpsCoordinate} from '../../../../../common-module/src/lib/modelinterfaces/gps-coordinate.model';
import {UnitState} from '../../../../../common-module/src/lib/modelinterfaces/unit-state.model';
import {CenterMapUtil} from '../map-utils/center-map.util';
import {OlCoordinate} from '../map-utils/OlCoordinate';
import {OlSelectCluster} from '../map-utils/OlSelectCluster';
import {OlStyle} from '../map-utils/OlStyle';
import {SelectedService} from './selected.service';
import {UnitLayerService} from './unit-layer.service';
import {OlAnimation} from "../map-utils/OlAnimation";

@Injectable({
  providedIn: 'root'
})

export class MapSelectUnitService {

  private readonly RADIUS_CLUSTER = 30;

  private isTrackingSource = new BehaviorSubject<boolean>(false);
  public isTracking$: Observable<boolean> = this.isTrackingSource.asObservable();

  private _map: OlMap;
  private _selectClusterInteraction: SelectCluster;

  constructor(private selectedService: SelectedService,
              private unitLayerService: UnitLayerService) {
  }

  get map(): OlMap {
    return this._map;
  }

  get selectClusterInteraction(): SelectCluster {
    return this._selectClusterInteraction;
  }

  public initMapOptions(map: OlMap): void {
    this._map = map;

    this._selectClusterInteraction = OlSelectCluster.build(this.RADIUS_CLUSTER);
    this._map.addInteraction(this._selectClusterInteraction);
  }

  public centerSelectedUnit(unitCoords: GpsCoordinate, zoom: number): void {
    OlAnimation.flash(this._map, unitCoords, this.unitLayerService.unitsLayer, {zoom});
  }

  public changeIsTracking(isTracking: boolean = null): void {
    if (isTracking !== null) {
      this.isTrackingSource.next(isTracking);
      return;
    }
    this.isTrackingSource.next(!this.isTrackingSource.getValue());
  }

  public trackingSelectedUnits(unitStates: Map<number, UnitState>): void {
    if (!this.isTrackingSource.getValue()) {
      return;
    }
    const selectedIdSet = this.selectedService.getInstantUnitIdsSet();
    const filteredUnitStates: UnitState[] = [];
    unitStates.forEach((value, key) => {
      if (selectedIdSet.has(key)) {
        filteredUnitStates.push(value);
      }
    });
    const coordinateList: GpsCoordinate[] = [];
    filteredUnitStates.forEach(state => {
      if (state?.gpsState?.coordinate) {
        coordinateList.push(state.gpsState.coordinate);
      }
    });
    CenterMapUtil.center(coordinateList, this.map);
  }
}
