@if (type === 'minor') {
  <app-button-minor (action)="onAction($event)"
                    [disabled]="disabled"
                    [showSpinner]="showSpinner"
                    [text]="'action.close' | translate"
                    [matDialogClose]="enableMatDialogClose">
  </app-button-minor>
} @else {
  <app-button-primary (action)="onAction($event)"
                      [disabled]="disabled"
                      [showSpinner]="showSpinner"
                      [text]="'action.close' | translate"
                      [matDialogClose]="enableMatDialogClose">
  </app-button-primary>
}
