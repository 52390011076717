export abstract class AppRegexp {

  public static readonly REAL_NUMBER = /^[0-9]*[.]?[0-9]+$/;
  public static readonly REAL_NUMBER_OPTIONAL_NEGATIVE = /^[\-]?[0-9]*[.]?[0-9]+$/;
  public static readonly ONLY_DIGITS = /^[0-9]*[.]?[0-9]+$/;
  public static readonly ONLY_DIGITS_WITH_ONE_DECIMAL = /^[0-9]+(\.[0-9]{1})?$/;
  public static readonly ONLY_INTEGER = /^\d*$/;
  public static readonly IMEI = /^[0-9_]+$/;
  public static readonly VIN = /^[a-zA-Z0-9]+$/;

  // не может начинаться с пробела и содержать кавычки
  public static readonly TEXT = /^(?!\s)[^"']*$/;

  // не может начинаться с пробела и содержать кавычки
  public static readonly TEXT_WITHOUT_POINT =  /^(?!\s)[^"'.]*$/;
  public static readonly PHONE_NUMBER = /^[0-9\-\+]{9,15}$/;
  public static readonly DRIVER_PHONE = /^\+([0-9]){10,14}$/;
  public static readonly ONE_OR_MORE_SPACES = /\s+/;
  public static readonly NEW_LINE = /[\r\n]+/;
  public static readonly EMPTY_LINE = /(^$)|(\s+$)/;
  public static readonly NOT_EMPTY_LINE = /^\s*$/;
  public static readonly ONLY_QUOTES_OR_SPACES = /^["' ]+$/;
  public static readonly AT_LEAST_ONE_DIGIT_UPPERCASE_LOWERCASE = /(?=.*\d)(?=.*[\D])/;
  public static readonly SERVER = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)+([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$/;

  public static readonly LOGISTIC_TIME_WINDOWS = /^\d{2}\.\d{2}\.\d{4} \d{2}:\d{2} - (\d{2}\.\d{2}\.\d{4} )?\d{2}:\d{2}$/;

  /**
   * 09:00 - 10:00, 12:00 - 14:00, ...
   */
  public static readonly LOGISTIC_TIME_RANGE_ARRAY = /^([01]\d|2[0-3]):[0-5]\d\s-\s([01]\d|2[0-3]):[0-5]\d(,\s([01]\d|2[0-3]):[0-5]\d\s-\s([01]\d|2[0-3]):[0-5]\d)*$/

  public static readonly LOGISTIC_COORDINATES_STRING = /^\d{1,2}\.\d{1,9} \d{1,2}\.\d{1,9}$/;
}
