import {DialogOpener} from "../../../../../../shared/components/dialogs/base-dialog/base-dialog.opener";
import {MatDialog} from "@angular/material/dialog";
import {ReportSettingsConstructorDialogComponent} from "./report-settings-constructor-dialog.component";
import {Injectable} from "@angular/core";

@Injectable()
export class ReportSettingsConstructorDialogOpener extends DialogOpener.Base<string> {
  constructor(matDialog: MatDialog) {
    super(matDialog, ReportSettingsConstructorDialogComponent)
  }
}
