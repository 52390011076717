import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-snack-bar',
  standalone: true,
  templateUrl: './snack-bar.component.html',
  imports: [
    TranslateModule
  ],
  styleUrls: ['./snack-bar.component.scss']
})

export class SnackBarComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { message: string; link: string },
              private snackBarRef: MatSnackBarRef<SnackBarComponent>) {
  }

  public onClose(): void {
    this.snackBarRef.dismiss();
  }
}
