import { Component } from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-telegram-message',
  templateUrl: './telegram-message.component.html',
  styleUrls: ['./telegram-message.component.scss']
})

export class TelegramMessageComponent {

  public readonly TELEGRAM_URL = environment.TELEGRAM_URL;
  public readonly QRCODE_SIZE = 256;
}
