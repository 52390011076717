export class UrlImage {

  private static readonly PATH: string = 'assets/images/';

  public static readonly CUT = UrlImage.PATH + 'cut.svg';
  public static readonly DEFAULT_AVATAR = UrlImage.PATH + 'default-avatar.png';
  public static readonly GEOFENCE = UrlImage.PATH + 'geofence.svg';
  public static readonly IMAGE = UrlImage.PATH + 'image.svg';
  public static readonly LOGO_WHITE = UrlImage.PATH + 'AuroraWhite.png';
  public static readonly POSITION = UrlImage.PATH + 'position-marker.svg';
  public static readonly SETTINGS = UrlImage.PATH + 'settings.svg';
  public static readonly SENSOR = UrlImage.PATH + 'sensors.svg';
  public static readonly TRACK_ARROW_BLUE = UrlImage.PATH + 'arrow-blue.svg';
  public static readonly TRACK_ARROW_ORANGE = UrlImage.PATH + 'arrow-orange.svg';
  public static readonly TRACK_ARROW_RED = UrlImage.PATH + 'arrow-red.svg';
  public static readonly TRACK_ARROW_BROWN = UrlImage.PATH + 'arrow-brown.svg';
  public static readonly TRACK_ARROW_PURPLE = UrlImage.PATH + 'arrow-purple.svg';
  public static readonly TRACK_ARROW_MAUVE = UrlImage.PATH + 'arrow-mauve.svg';
  public static readonly TRACK_ARROW_ACCENT = UrlImage.PATH + 'arrow-accent.svg';
  public static readonly TRACK_ARROW_GREEN = UrlImage.PATH + 'arrow-green.svg';
  public static readonly TRACK_ARROW_GRAY = UrlImage.PATH + 'arrow-gray.svg';
  public static readonly TRACK_ARROW_BLACK = UrlImage.PATH + 'arrow-black.svg';
  public static readonly UNIT_MOVING = UrlImage.PATH + 'unit-moving.png';
  public static readonly UNIT_PARKING = UrlImage.PATH + 'unit-parking.svg';
  public static readonly TRACK_START = UrlImage.PATH + 'start-track-marker.svg';
  public static readonly TRACK_FINISH = UrlImage.PATH + 'finish-track-marker.svg';
  public static readonly TRACK_PARKING = UrlImage.PATH + 'parking-track-marker.svg';
  public static readonly TRACK_SELECTED_PARKING = UrlImage.PATH + 'parking-track-selected-marker.svg';
  public static readonly VIDEO = UrlImage.PATH + 'video.svg';
  public static readonly PICKUP_POINT = UrlImage.PATH + 'pickup_point.svg';
  public static readonly DELIVERY_POINT = UrlImage.PATH + 'delivery_point.svg';
  public static readonly JOB_POINT = UrlImage.PATH + 'job_point.svg';
  public static readonly ROUTE_START_POINT = UrlImage.PATH + 'route_start_point.svg';
  public static readonly ROUTE_END_POINT = UrlImage.PATH + 'route_end_point.svg';
  public static readonly WAREHOUSE_POINT = UrlImage.PATH + 'warehouse_point.svg';
  public static readonly PARKING_POINT = UrlImage.PATH + 'parking_point.svg';

  public static readonly PLACE_POINT = UrlImage.PATH + 'place.svg';
}
