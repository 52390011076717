import SelectCluster from 'ol-ext/interaction/SelectCluster';
import Style from 'ol/style/Style';
import {Select} from 'ol/interaction';
import {OlStyle} from './OlStyle';
import OlMap from "ol/Map";

export class OlSelectCluster extends Select {

  public static createOnMap(map: OlMap, radius: number): SelectCluster {
    const clusterInteraction = OlSelectCluster.build(radius);
    map.addInteraction(clusterInteraction);
  }

  public static build(radius: number): SelectCluster {
    return new SelectCluster({
      pointRadius: radius,
      style: this.getSelectedStyle,
      filter: (feature) => !!feature.get('features')
    });
  }

  private static getSelectedStyle(feature): Style[] {
    const cluster = feature.get('features');
    if (cluster.length > 1) {
      return [OlStyle.createForSelectCluster()];
    } else {
      return cluster[0].getStyle();
    }
  }
}
