import {UnitShort} from './unit-short.model';
import {UnitGroupView, UserUnitGroups} from './user-unit-groups.model';

export class UnitGroupStorage {

  constructor(public all: UnitShort[],
              public grouped: UserUnitGroups[]) {
  }

  public getUnitGroupView(): Map<number, UnitGroupView> {
    const unitGroupView = new Map<number, UnitGroupView>();
    this.grouped.forEach(g => g.getUnitGroupViews().forEach((v, k) => unitGroupView.set(k, v)));
    return unitGroupView;
  }

  public static valueOf(i: UnitGroupStorage): UnitGroupStorage {
    return i ? new UnitGroupStorage(i.all, UserUnitGroups.valuesOf(i.grouped)) : null;
  }
}
