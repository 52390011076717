import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VehicleType } from '../modelinterfaces/vehicle-type.model';
import { HttpParamsUtil } from '../utils/http-params-util';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class VehicleTypeService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getList(lang: string): Observable<VehicleType[]> {
    const url = `${this.environmentHelper.urlV2}/vehicleType`;
    return this.http.get<VehicleType[]>(url, {params: HttpParamsUtil.createByLang(lang)}).pipe(
      map(list => VehicleType.valuesOf(list))
    );
  }
}
