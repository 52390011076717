<app-dialog [title]="data.group? ('action.edit-group' | translate): ('action.add-group' | translate)">

  <ng-container appDialogStickyHeader>
    <form *ngIf="form" [formGroup]="form">
      <div class="flex flex-v-center flex-gap8">

        <app-color-picker-input class="input-color flex1"
                                [formControlName]="controls.COLOR">
        </app-color-picker-input>

        <mat-form-field class="flex1">
          <mat-label>{{ 'term.name' | translate }}</mat-label>
          <input matInput [formControlName]="controls.NAME">
          <mat-error *ngIf="form.controls[controls.NAME].errors?.required">
            {{ 'message.error.required-field' | translate }}
          </mat-error>
          <mat-error *ngIf="form.controls[controls.NAME].errors?.pattern">
            {{ 'message.error.contains-illegal-symbols' | translate }}
          </mat-error>
          <mat-error
            *ngIf="form.controls[controls.NAME].errors?.maxlength">
            {{ 'message.error.max-length-symbols' | translate: {max: MAX_LENGTH_NAME_GROUP} }}
          </mat-error>
          <mat-error *ngIf="form.controls[controls.NAME].errors?.nameIsUsed">
            {{ 'message.error.name-already-exist' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </ng-container>

  <ng-container appDialogContent>
    <div class="unit-list">
      <app-unit-list-simple [units]="this.data?.allUnits ?? []"
                            [selected]="this.data?.group?.units ?? []"
                            (change)="selectedUnits = $event">
      </app-unit-list-simple>
    </div>
  </ng-container>


  <ng-container appDialogActions>

    <app-button-cancel></app-button-cancel>

    <app-button-delete *ngIf="data.group" (delete)="onDelete()">
    </app-button-delete>

    @if (!data.group) {
      <app-button-save (save)="onAddGroup()"
                       [disabled]="!form.valid">
      </app-button-save>
    } @else {
      <app-button-save (save)="onSaveChanges()"
                       [disabled]="!form.valid">
      </app-button-save>
    }
  </ng-container>
</app-dialog>
