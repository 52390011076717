<div class="container">
  <mat-form-field class="form-field-geofence">
    <mat-label>{{ 'term.geofences' | translate }}</mat-label>
    <mat-select panelClass="select-panel-geofences"
                [ngModel]="selectedGeofenceList"
                [compareWith]="comparisonId"
                [multiple]="true">
      <mat-option>
        <ngx-mat-select-search
          (input)="filterGeofences($event.target)"
          placeholderLabel="{{ 'action.search' | translate }}"
          noEntriesFoundLabel="{{ 'message.error.nothing-found' | translate }}">
          <mat-icon ngxMatSelectSearchClear (click)="clearFilterGeofences()">close</mat-icon>
        </ngx-mat-select-search>
      </mat-option>

      <mat-option
        *ngFor="let geofence of (geofenceList$ | async) | geofenceFilter : searchGeofence"
        [value]="geofence"
        class="geofence-option"
        (onSelectionChange)="toggleGeofence($event)">
        <span>{{ geofence.name }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div id="map-report" class="map">
    <div class="box-buttons-and-conditions">
      <app-map-buttons
        [map]="map"
        [isShowButtons]="false"
      ></app-map-buttons>

      <app-map-conditions>
      </app-map-conditions>
    </div>
  </div>
</div>
