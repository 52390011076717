<form *ngIf="ruleForm" [formGroup]="ruleForm">
  <mat-tab-group class="tab-list" (selectedIndexChange)="tabChange($event)">
    <mat-tab label="{{ 'axle-load.basic-settings' | translate }}">
      <div class="container">

        <app-color-picker-input [nullColor]="true"
                                class="margin-top-8"
                                [label]="'term.label' | translate"
                                [formControlName]="control.LABEL">
        </app-color-picker-input>

        <mat-form-field>
          <mat-label>{{ 'term.' + control.NAME | translate }}</mat-label>
          <input matInput [formControlName]="control.NAME"/>
          <mat-error *ngIf="formControl[control.NAME].errors?.required">
            {{ 'message.error.can-not-be-empty' | translate }}
          </mat-error>
          <mat-error *ngIf="formControl[control.NAME].errors?.maxlength">
            {{
              'message.error.max-length-symbols' | translate:
                {max: asMaxlength(formControl[control.NAME].errors.maxlength).requiredLength}
            }}
          </mat-error>
          <mat-error *ngIf="formControl[control.NAME].errors?.nameIsUsed">
            {{ 'message.error.name-already-exist' | translate }}
          </mat-error>

          <ng-container *ngIf="formControl[control.NAME].pending">
            <div class="suffix-spinner">
              <mat-icon>
                <mat-spinner diameter="20"></mat-spinner>
              </mat-icon>
            </div>
          </ng-container>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'term.' + control.TYPE | translate }}</mat-label>
          <input matInput [formControlName]="control.TYPE"/>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'term.' + control.DESCRIPTION | translate }}</mat-label>
          <input matInput [formControlName]="control.DESCRIPTION"/>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'term.' + control.MESSAGE | translate }}</mat-label>
          <input matInput [formControlName]="control.MESSAGE"/>
          <mat-error *ngIf="formControl[control.MESSAGE].errors?.required">
            {{ 'message.error.can-not-be-empty' | translate }}
          </mat-error>
          <mat-error *ngIf="formControl[control.MESSAGE].errors?.maxlength">
            {{
              'message.error.max-length-symbols' | translate:
                {max: asMaxlength(formControl[control.MESSAGE].errors.maxlength).requiredLength}
            }}
          </mat-error>
        </mat-form-field>

        <ng-container *ngIf="formConfig.pendingSeconds.requiredType !== requiredType.IGNORE">
          <mat-form-field>
            <mat-label>{{ 'eco-driving.min-duration' | translate :formConfig.pendingSeconds.requiredType === requiredType.REQUIRED }}</mat-label>
            <input matInput class="item-input" type="number" min="0" [formControlName]="control.PENDING_TIME"/>
            <span matTextSuffix>{{ formConfig.pendingSeconds.unitOfMeasure.nameTranslated }}</span>
            <mat-error *ngIf="formConfig.pendingSeconds.requiredType === requiredType.REQUIRED
                                      && formControl[control.PENDING_TIME].errors?.required">
              {{ 'message.error.can-not-be-empty' | translate }}
            </mat-error>
            <mat-error *ngIf="formControl[control.PENDING_TIME].errors?.min">
              {{ 'message.error.min-value' | translate : {min: formConfig.pendingSeconds.minConstraint} }}
            </mat-error>
            <mat-error *ngIf="formControl[control.PENDING_TIME].errors?.max">
              {{ 'message.error.max-value' | translate : {max: formConfig.pendingSeconds.maxConstraint} }}
            </mat-error>
          </mat-form-field>
        </ng-container>

        <ng-container *ngFor="let param of formConfig.params">
          <ng-container *ngIf="param.requiredType !== requiredType.IGNORE">
            <ng-container *ngIf="param.configType === configType.NUMBER; else paramSelectionList">
              <mat-form-field>
                <mat-label>{{ param.nameTranslated }}</mat-label>
                <input matInput type="number"
                       [min]="asSourceValue(param).minConstraint"
                       [max]="asSourceValue(param).maxConstraint"
                       [formControlName]="param.name"/>
                <span matTextSuffix>{{ param.unitOfMeasure.nameTranslated }}</span>
                <mat-error
                  *ngIf="param.requiredType === requiredType.REQUIRED && formControl[param.name].errors?.required">
                  {{ 'message.error.can-not-be-empty' | translate }}
                </mat-error>
                <mat-error *ngIf="formControl[param.name].errors?.min">
                  {{ 'message.error.min-value' | translate : {min: asSourceValue(param).minConstraint} }}
                </mat-error>
                <mat-error *ngIf="formControl[param.name].errors?.max">
                  {{ 'message.error.max-value' | translate : {max: asSourceValue(param).maxConstraint} }}
                </mat-error>
              </mat-form-field>
            </ng-container>

            <ng-template #paramSelectionList>
              <mat-form-field>
                <mat-select [formControlName]="param.name"
                            [multiple]="asSourceList(param).selectionType === selectionType.MULTI">
                  <mat-option *ngFor="let value of asSourceList(param).values" [value]="value.value">
                    {{ value.translated }}
                  </mat-option>
                </mat-select>
                <span matTextSuffix>{{ param.unitOfMeasure.nameTranslated }}</span>
                <mat-error
                  *ngIf="param.requiredType === requiredType.REQUIRED && formControl[param.name].errors?.required">
                  {{ 'message.error.can-not-be-empty' | translate }}
                </mat-error>
              </mat-form-field>
            </ng-template>
          </ng-container>
        </ng-container>
      </div>
    </mat-tab>


    <mat-tab label="{{ 'term.geofences' | translate }}">
      <div class="container">
        <div class="flex flex-v-center flex-gap8">
          <div>Генерировать событие если оно произошло:</div>
          <div class="flex-grow-1">
            <mat-form-field class="width-100-percent" subscriptSizing="dynamic">
              <mat-select  [formControlName]="control.GEOFENCE_TRIGGER_MODE">
                <mat-option value="WITHIN">Внутри выбранных геозон</mat-option>
                <mat-option value="DISJOINT">Вне выбранных геозон</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <mat-form-field class="form-field-geofence margin-top-8" subscriptSizing="dynamic">
          <mat-label>{{ 'term.geofences' | translate }}</mat-label>
          <mat-select panelClass="select-panel-geofences"
                      [value]="currentRule?.geofences"
                      [formControlName]="control.GEOFENCES"
                      [compareWith]="comparisonId"
                      [multiple]="true">
            <mat-icon class="search-spinner" *ngIf="!(geofenceList$ | async)">
              <mat-spinner diameter="20"></mat-spinner>
            </mat-icon>
            <mat-option>
              <ngx-mat-select-search
                (input)="filterGeofences($event.target)"
                placeholderLabel="{{ 'action.search' | translate }}"
                noEntriesFoundLabel="{{ 'message.error.nothing-found' | translate }}">
                <mat-icon ngxMatSelectSearchClear (click)="clearFilterGeofences()">close</mat-icon>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let geofence of ((geofenceList$ | async) | geofenceFilter : searchGeofence) "
                        [value]="geofence"
                        class="geofence-option"
                        (onSelectionChange)="toggleGeofence($event)">
              <span>{{ geofence.name }}</span>
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="isRequiredGeofence(currentRule?.type) && formControl[control.GEOFENCES].errors?.required">
            {{ 'message.error.can-not-be-empty' | translate }}
          </mat-error>
        </mat-form-field>

        <div id="map-rule" class="map margin-top-8">
          <div class="box-buttons-and-conditions">
            <app-map-buttons
              [map]="map"
              [isShowButtons]="false">
            </app-map-buttons>

            <app-map-conditions>
            </app-map-conditions>
          </div>
        </div>
      </div>
    </mat-tab>


    <mat-tab label="{{ 'term.informing' | translate }}">
      <div class="container">
        <mat-form-field class="without-underline" *ngIf="formControl[control.PUSH_ENABLED]">
          <div class="slide-toggle-field">
            <span>{{ 'term.push-notifications' | translate }}</span>
            <mat-slide-toggle class="group-selector" formControlName="{{control.PUSH_ENABLED}}">
            </mat-slide-toggle>
          </div>
          <input matInput [readonly]="true"/>
        </mat-form-field>

        <div class="box-list-and-button" *ngIf="formControl[control.TELEGRAM_CHATS]">
          <mat-form-field class="telegram-list">
            <mat-label>{{ 'term.telegram-informing' | translate }}</mat-label>
            <mat-select [formControlName]="control.TELEGRAM_CHATS"
                        [compareWith]="comparisonId"
                        [multiple]="true">
              <mat-option
                *ngFor="let chat of telegramChats"
                [value]="chat">
                <span>{{ chat.telegramUser.firstName }} </span>
                <span *ngIf="chat.telegramUser.lastName">{{ chat.telegramUser.lastName }} </span>
                <span *ngIf="chat.telegramUser.username">{{ chat.telegramUser.username }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <button mat-icon-button class="more-info" (click)="toggleMessageContainer()">
            <mat-icon>help</mat-icon>
          </button>
        </div>

        <app-telegram-message *ngIf="isOpenMessageContainer"></app-telegram-message>

        <mat-form-field class="without-underline" *ngIf="formControl[control.ACTION_COMMAND]">
          <div class="slide-toggle-field">
            <span>{{ 'term.sending-commands-to-tracker' | translate }}</span>
            <mat-slide-toggle
              class="group-selector"
              [formControlName]="control.ACTION_COMMAND">
            </mat-slide-toggle>
          </div>

          <input matInput hidden/>
        </mat-form-field>

        <div [ngClass]="{'not-display': !(formValue[control.ACTION_COMMAND])}">
          <ng-container *ngIf="formControl[control.ACTIVATED_TEXT]">
            <p class="subheader">
              {{ 'term.when-notification-activated' | translate }}:
            </p>
            <div class="multi-form-fields">
              <mat-form-field class="first-inline-field">
                <mat-label>{{ 'term.command' | translate }}</mat-label>
                <input matInput
                       [formControlName]="control.ACTIVATED_TEXT"/>
              </mat-form-field>

              <mat-form-field class="number-inline-field">
                <mat-label>{{ 'sensor.option.timeout' | translate }}</mat-label>
                <input matInput
                       type="number"
                       min="0"
                       [formControlName]="control.ACTIVATED_TIMEOUT"/>
                <mat-error *ngIf="formControl[control.ACTIVATED_TIMEOUT].errors?.max">
                  {{
                    'message.error.max-value' | translate:
                      {max: formControl[control.ACTIVATED_TIMEOUT].errors?.max.max}
                  }}
                </mat-error>
                <span matTextSuffix>{{ 'uom.sec' | translate }}</span>
              </mat-form-field>
            </div>
          </ng-container>

          <ng-container *ngIf="formControl[control.DEACTIVATED_TEXT]">
            <p class="subheader">{{ 'term.when-notification-deactivated' | translate }}:</p>
            <div class="multi-form-fields">
              <mat-form-field class="first-inline-field">
                <mat-label>{{ 'term.command' | translate }}</mat-label>
                <input matInput [formControlName]="control.DEACTIVATED_TEXT"/>
              </mat-form-field>

              <mat-form-field class="number-inline-field">
                <mat-label>{{ 'sensor.option.timeout' | translate }}</mat-label>
                <input matInput type="number" min="0" [formControlName]="control.DEACTIVATED_TIMEOUT"/>
                <mat-error *ngIf="formControl[control.DEACTIVATED_TIMEOUT].errors?.max">
                  {{
                    'message.error.max-value' | translate:
                      {max: formControl[control.DEACTIVATED_TIMEOUT].errors?.max.max}
                  }}
                </mat-error>
                <span matTextSuffix>{{ 'uom.sec' | translate }}</span>
              </mat-form-field>
            </div>
          </ng-container>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="{{ 'term.control-schedule' | translate }}">
      <div class="container for-schedule">
        <app-control-schedule [schedule]="formValue[control.SCHEDULE]"
                              (scheduleChanged)="receiveSchedule($event)">
        </app-control-schedule>
      </div>
    </mat-tab>
  </mat-tab-group>
</form>
