import {LayoutModule} from '@angular/cdk/layout';
import {OverlayModule} from '@angular/cdk/overlay';
import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import {APP_INITIALIZER, ErrorHandler, Injector, NgModule} from '@angular/core';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {AppCommonModule} from '../../../common-module/src/lib/app-common.module';
import {GlobalErrorHandler} from '../../../common-module/src/lib/interceptors/global-error-handler';
import {HttpErrorsInterceptor} from '../../../common-module/src/lib/interceptors/http-errors.interceptor';
import {AppCommonEnvironmentModule} from '../../../common-module/src/lib/services/environment-helper';
import {MatPaginationIntlService} from '../../../common-module/src/lib/services/mat-pagination-intl.service';
import {environment} from '../environments/environment';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app.routing.module';
import {initTranslationService} from './shared/config/translation.config';

import {AuthInterceptor} from './shared/interceptors/auth.interceptor';
import {SharedModule} from './shared/shared.module';
import {SystemModule} from './system/system.module';
import {LazyTranslateLoader} from "../../../common-module/src/lib/translate-config/LazyTranslateLoader";
import {AuthUserService} from "../../../common-module/src/lib/app-services/auth-user.service";
import {filter, first} from "rxjs/operators";
import {AppCustomizationService} from "../../../common-module/src/lib/app-services/app-customization.service";


registerLocaleData(localeRu);


export function isPublicView(): boolean {
  return window.location.href.toLowerCase().includes('publickey');
}

export function isLoginView(): boolean {
  return window.location.href.toLowerCase().includes('/login');
}

export function initAppCustomization(service: AppCustomizationService) {
  return () => {
    service.init();
    return service.customization$.pipe(
      filter(customization => customization !== null),
      first()
    );
  }
}

export function initAuthUser(authUserService: AuthUserService) {
  return () => {
    if (isLoginView() && !window.localStorage.getItem('bearer_token')) {
      return true;
    }
    if (!isPublicView()) {
      authUserService.init();
      return authUserService.currentUser$.pipe(
        filter(user => user !== null),
        first()
      );
    } else {
      return true;
    }
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    AppCommonEnvironmentModule.forRoot(environment.BASE_URL, environment.HEADER, environment.DEFAULT_LANGUAGE, environment.OUTAGE, environment.TOOLBAR_RESTRICTIONS, environment.LOGISTIC_RESTRICTION),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: LazyTranslateLoader
      },
    }),

    BrowserModule,
    AppCommonModule,
    AppCommonEnvironmentModule,
    SystemModule,
    SharedModule,
    OverlayModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    LayoutModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initTranslationService,
      deps: [TranslateService, Injector],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initAuthUser,
      deps: [AuthUserService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initAppCustomization,
      deps: [AppCustomizationService],
      multi: true
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initUnitGroupsService,
    //   deps: [UnitGroupsService],
    //   multi: true
    // },
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorsInterceptor, multi: true},
    MatBottomSheet,
    {provide: MatPaginatorIntl, useClass: MatPaginationIntlService},
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
  ],
  bootstrap: [AppComponent],
  exports: []
})

export class AppModule {
}
