<app-dialog [title]="'term.public-link' | translate">

  <div class="app-dialog-content-column" appDialogContent>
    <app-date-range-picker
      matTooltip="{{ 'message.tooltip.link-expiration' | translate }}"
      [actionButtons]="true"
      [dateTimeRange]="dateTimeRange"
      [minDate]="minDate"
      [position]="'center'"
      [isFuturePeriod]="isFuturePeriod"
      (dateTimeRangeChange)="changeDateTimeRange($event)">
    </app-date-range-picker>

    <div class="box-unit-list">
      <div class="title">{{ 'term.unit-list' | translate }}</div>
      <mat-list class="unit-list">
        <mat-list-item class="item" *ngFor="let unit of selectedUnits$ | async">
          <mat-icon>directions_car</mat-icon>
          {{ unit.name }}
        </mat-list-item>
      </mat-list>
    </div>

    <div class="box-link" *ngIf="link">
      <div class="link">
        <mat-form-field>
          <mat-label>{{ 'term.link' | translate }}</mat-label>
          <input matInput
                 [value]="link"
                 readonly/>
        </mat-form-field>

        <button mat-icon-button
                matTooltip="{{ 'action.copy' | translate }}"
                (click)="copyToClipboard()">
          <mat-icon>content_paste</mat-icon>
        </button>
      </div>

      <div class="message">
        {{
          'message.info.anyone-can-view-location' | translate:
            {time: dateTimeRange.endDate.toISOString() | utcDateTz: (authUser$ | async)?.timeZone.name: 'DD.MM.YYYY HH:mm'}
        }}
      </div>
    </div>
  </div>

  <ng-container appDialogActions>
    <app-button-cancel (cancel)="onClose()">
    </app-button-cancel>

    <app-button-primary [text]="'action.generate' | translate"
                        [disabled]="!!link"
                        (action)="onGenerateLink()">
    </app-button-primary>

  </ng-container>

</app-dialog>
