import { animate, keyframes, style, transition, trigger } from "@angular/animations";
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { ActivatedRoute, Router } from "@angular/router";

import { Observable, ReplaySubject, timer } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Tab } from '../../../../../../../../../common-module/src/lib/app-enums/tab';
import { AppMessageService } from "../../../../../../../../../common-module/src/lib/app-services/app-message.service";
import { UiSpinnerService } from "../../../../../../../../../common-module/src/lib/app-services/ui-spinner.service";
import { ViewBreakpointService, ViewSize } from "../../../../../../../../../common-module/src/lib/app-services/view-breakpoint.service";
import { CommandTemplateShort } from "../../../../../../../../../common-module/src/lib/modelinterfaces/command-template-short.model";
import { DeviceCommand } from '../../../../../../../../../common-module/src/lib/modelinterfaces/device-command.model';
import { CommandStatus } from "../../../../../../../../../common-module/src/lib/modelinterfaces/enums/command-status";
import {
  CommandTemplateTimeout
} from "../../../../../../../../../common-module/src/lib/modelinterfaces/enums/command-template-timeout.enum";
import { CommandType } from "../../../../../../../../../common-module/src/lib/modelinterfaces/enums/command-type";
import { ProviderType } from "../../../../../../../../../common-module/src/lib/modelinterfaces/enums/provider-type";
import { Unit } from '../../../../../../../../../common-module/src/lib/modelinterfaces/unit.model';
import { User } from '../../../../../../../../../common-module/src/lib/modelinterfaces/user.model';
import { CommandTemplateShortService } from '../../../../../../../../../common-module/src/lib/services/command-template-short.service';
import { DeviceCommandService } from "../../../../../../../../../common-module/src/lib/services/device-command.service";
import { AppRegexp } from "../../../../../../../../../common-module/src/lib/utils/app-regexp";
import { AuthUserService } from "../../../../../../../../../common-module/src/lib/app-services/auth-user.service";
import {DayjsUtil} from "../../../../../../../../../common-module/src/lib/dayjs.util";

@Component({
  selector: 'app-send-command-dialog',
  templateUrl: './send-command-dialog.component.html',
  styleUrls: ['./send-command-dialog.component.scss'],
  animations: [
    trigger('new-row', [
      transition('* => true', animate('3s ease-out', keyframes([
        style({background: 'inherit', offset: 0}),
        style({background: 'pink', offset: 0.5}),
        style({background: 'pink', offset: 0.7}),
        style({background: 'inherit', offset: 1}),
      ]))),
    ]),
  ]
})

export class SendCommandDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  public readonly UPDATE_TABLE_PERIOD = 15000;

  @ViewChild('inputCommand') inputCommand: ElementRef;
  @ViewChild('inputTimeout') inputTimeout: ElementRef;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  public authUser$: Observable<User>;
  public commandStatus = CommandStatus;
  public commandTemplateTimeout = CommandTemplateTimeout;
  public commandType = CommandType;
  public dataSource: DeviceCommand[] = [];
  public displayedColumns: Array<string> = ['status', 'createdTime', 'sendingTime', 'type', 'text'];
  public disableSendButton = true;
  public loading = false;
  public pageSize = 10;
  public pageSizeOptions = [5, 10, 15];
  public resultsLength: number;
  public selectedTemplate: CommandTemplateShort;
  public showSpinner = false;
  public size$: Observable<ViewSize>;
  public templateList: CommandTemplateShort[] = [];

  private destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  private isFirstPage: boolean;
  private previousActualTime = 0;
  private nextActualTime = 0;
  private sort = '-createdTime';
  private unitId: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { unit: Unit },
              private appMessageService: AppMessageService,
              private authUserService: AuthUserService,
              private commandTemplateShortService: CommandTemplateShortService,
              private deviceCommandService: DeviceCommandService,
              private dialogRef: MatDialogRef<SendCommandDialogComponent>,
              private route: ActivatedRoute,
              private router: Router,
              private uiSpinnerService: UiSpinnerService,
              private viewBreakpointService: ViewBreakpointService) {
  }

  ngOnInit(): void {
    this.authUser$ = this.authUserService.currentUser$;
    this.size$ = this.viewBreakpointService.size$;
    this.unitId = this.data.unit.id;

    this.uiSpinnerService.show();
    this.commandTemplateShortService.getList(this.unitId)
      .pipe(takeUntil(this.destroy))
      .subscribe(templates => {
          this.templateList = templates;
          this.uiSpinnerService.stop();
        }
      );

    timer(0, this.UPDATE_TABLE_PERIOD)
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        if (this.paginator) {
          if (this.paginator.pageIndex === 0) {
            this.loadData(this.unitId, this.paginator.pageIndex, this.paginator.pageSize, this.sort);
          }
        } else {
          this.loadData(this.unitId, 0, this.pageSize, this.sort);
        }
      });
  }

  ngAfterViewInit(): void {
    this.paginator.page
      .pipe(takeUntil(this.destroy))
      .subscribe(() =>
        this.loadData(this.unitId, this.paginator.pageIndex, this.paginator.pageSize, this.sort));
  }

  private loadData(unitId: number, page: number, size: number, sort: string): void {
    this.showSpinner = true;
    this.deviceCommandService.getList(unitId, page, size, sort)
      .pipe(takeUntil(this.destroy))
      .subscribe(data => {
          this.isFirstPage = data.first;
          this.resultsLength = data.totalElements;
          this.dataSource = data.content;
          this.getActualTime();
          this.showSpinner = false;
        },
        error => {
          this.showSpinner = false;
          this.dataSource = [];
          throw error;
        });
  }

  private getActualTime(): void {
    if (!this.isFirstPage) {
      return;
    }
    const element = this.dataSource.find(el => !!el.actualTime);
    if (element) {
      this.previousActualTime = this.nextActualTime;
      this.nextActualTime = Date.parse(element.actualTime);
    }
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public onAddTemplate(): void {
    this.router.navigate([`${Tab.COMMAND_TEMPLATES}`]).then();
    this.dialogRef.close();
  }

  public onEditTemplate(templateId: number): void {
    this.router.navigate([`${Tab.COMMAND_TEMPLATES}/${templateId}`]).then();
    this.dialogRef.close();
  }

  public onAddTemplateToInput(template: CommandTemplateShort): void {
    this.selectedTemplate = template;
    this.inputCommand.nativeElement.value = this.inputCommand.nativeElement.value + template.text;
    if (template.timeout > 0) {
      this.inputTimeout.nativeElement.value = template.timeout;
    }
    this.disableSendButton = false;
  }

  public onRemoveTemplateFromInput(template: CommandTemplateShort): void {
    this.inputCommand.nativeElement.value =
      this.excludeTemplateTextFromCommandText(this.inputCommand.nativeElement.value, template.text);
    if (template.timeout > 0) {
      this.inputTimeout.nativeElement.value = 0;
    }
    this.selectedTemplate = null;
  }

  private excludeTemplateTextFromCommandText(commandText: string, templateText: string): string {
    return commandText.toLowerCase().replace(templateText.toLowerCase(), '');
  }

  public checkNewTime(element: string): boolean {
    return !!(Date.parse(element) > this.previousActualTime && !!this.previousActualTime);
  }

  public onKeyUp(value: string, timeout: string, event: KeyboardEvent): void {
    if (!value || value.match(AppRegexp.ONLY_QUOTES_OR_SPACES)) {
      this.disableSendButton = true;
      return;
    }
    this.disableSendButton = false;
    if (event.code === 'Enter') {
      this.sendCommand(value, timeout);
    }
  }

  public sendCommand(value: string, timeout: string): void {
    const lifeTimeout = (!Number(timeout) || Number(timeout) <= 0) ? 0 : Number(timeout);
    const command = new DeviceCommand(
      0,
      CommandStatus.SENDING,
      null,
      CommandType.TCP_COMMAND,
      DayjsUtil.instant().toISOString(),
      ProviderType.COMMON,
      value,
      lifeTimeout,
      null);
    this.loading = true;
    this.deviceCommandService.sendCommand(this.unitId, command)
      .pipe(takeUntil(this.destroy))
      .subscribe(
        () => {
          this.appMessageService.openSnackBar('message.info.command-sent');
          this.loadData(this.unitId, this.paginator.pageIndex, this.paginator.pageSize, this.sort);
          this.loading = false;
        },
        () => {
          this.loading = false;
          this.appMessageService.openSnackBar('message.error.error', true);
        });
  }

  public isolateTemplateFromText(command: DeviceCommand): string {
    if (!command.template?.text) {
      return command.text;
    } else {
      return this.excludeTemplateTextFromCommandText(command.text, command.template.text);
    }
  }

  public asCommand(command: DeviceCommand): DeviceCommand {
    return command;
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }
}
