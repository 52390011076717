declare var require: any

export const environment = {
  production: false,
  GUI_VERSION: require('../../../../package.json').version,
  BASE_URL: {
    BASE: 'https://api.aurora-soft.xyz',
    AURORA: '/aurora/api/v1',
    AURORA_V2: '/aurora/api/v2',
    DVR: '/bi-dvr/api/v1',
  },
  OUTAGE: {
    BASE_URL: 'https://outage.aurora-soft.xyz/serverOutageTracker/api/v1',
    SERVER_NAME: 'dev'
  },
  TOOLBAR_RESTRICTIONS: {
    DISABLE_CHART: [],
    DISABLE_TRACK: [],
    DISABLE_REPORT: []
  },
  LOGISTIC_RESTRICTION: {
    AVAILABLE_USER_IDS:[]
  },
  TELEGRAM_URL: 'http://t.me/aurora_ibot',
  HEADER: 'DEV',
  DEMO_LOGIN: 'admin',
  DEMO_PASSWORD: 'rootroot',
  DEFAULT_LANGUAGE: 'ru',
  APP_ANDROID_URL: 'https://play.google.com/store/apps/details?id=by.aurorasoft.androidclient',
  SEARCH_ADDRESS_URL: 'https://nominatim.openstreetmap.org',
  REVERSE_GEOCODING_URL: 'http://geo.aurora-soft.by:8081/reverse?format=json&lat=${lat}&lon=${lng}&zoom=18&accept-language=${lang}'
};
