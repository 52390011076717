import { Component } from '@angular/core';

@Component({
  selector: 'app-mileage-classifier-scanned-dialog',
  templateUrl: './mileage-classifier-scanned-dialog.component.html',
  styleUrl: './mileage-classifier-scanned-dialog.component.scss'
})
export class MileageClassifierScannedDialogComponent {

}
