import { DayTime } from './day-time.model';

export class RangeDayTime {

  public static FULL_WEEK = new Array(1)
    .fill(new RangeDayTime(new DayTime(1, '00:00:00'), new DayTime(7, '23:59:59')));

  constructor(
    public from: DayTime,
    public to: DayTime,
  ) {
  }

  public static valueOf(o: RangeDayTime): RangeDayTime {
    return o ? new RangeDayTime(o.from, o.to) : null;
  }

  public static valuesOf(list: RangeDayTime[]): RangeDayTime[] {
    return list ? list.map(o => RangeDayTime.valueOf(o)) : [];
  }
}
