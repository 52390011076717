import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommandTemplateShort } from '../modelinterfaces/command-template-short.model';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class CommandTemplateShortService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getList(unitId?: number): Observable<CommandTemplateShort[]> {
    const url = `${this.environmentHelper.urlV2}/commandTemplateShort`
    let params = new HttpParams();
    if (unitId) {
      params = params.set('unitId', unitId)
    }
    return this.http.get<CommandTemplateShort[]>(url, {params: params}).pipe(
      map(list => CommandTemplateShort.valuesOf(list))
    );
  }
}
