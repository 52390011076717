import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatListOption, MatSelectionList} from '@angular/material/list';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {UnitFilterService} from '../../../shared/services/unit-filter.service';


@Component({
  selector: 'app-filter-users-dialog',
  templateUrl: './filter-users-dialog.component.html',
  styleUrls: ['./filter-users-dialog.component.scss', '../../../../../../common-module/src/lib/app-styles/dialog-common.scss']
})
export class FilterUsersDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('userList', {static: false}) userList: MatSelectionList;
  @ViewChild('allSelected', {static: false}) allSelected: MatListOption;

  public lastSelectedUserNames: string[] = [];
  public selectedUserNames: string[] = [];
  public selectAllCheckbox = false;

  private subscription: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { userNameList: string[] },
              private dialogRef: MatDialogRef<FilterUsersDialogComponent>,
              private unitFilterService: UnitFilterService) {
  }

  ngOnInit(): void {
    this.subscription = this.unitFilterService.filteringUnitBySelectedUsers$.subscribe(
      userNames => {
        this.selectedUserNames = userNames;
      }
    );
  }

  ngAfterViewInit(): void {
    this.selectAllCheckbox = this.data.userNameList.length === this.selectedUserNames.length;
    setTimeout(() => { // use setTimeout for avoid error: Expression has changed after it was checked
      if (this.selectAllCheckbox) {
        this.userList.selectAll();
      }
    });
  }

  public toggleAllSelection(): void {
    this.selectAllCheckbox = !this.selectAllCheckbox;
    this.handlerAllSelection();
  }

  private handlerAllSelection(): void {
    if (this.selectAllCheckbox) {
      this.userList.selectAll();
      this.selectedUserNames = this.userList.selectedOptions.selected.map(v => v.value).filter(el => !!el);
      this.unitFilterService.changeFilteringSelectedUsers(this.selectedUserNames);
    }
    if (!this.selectAllCheckbox) {
      this.userList.deselectAll();
      this.unitFilterService.changeFilteringSelectedUsers([]);
    }
  }

  public handleSelection(): void {
    this.selectedUserNames = this.userList.selectedOptions.selected.map(v => v.value).filter(el => !!el);
    this.unitFilterService.changeFilteringSelectedUsers(this.selectedUserNames);
    this.selectAllCheckbox = this.data.userNameList.length === this.selectedUserNames.length;
    if (this.selectAllCheckbox) {
      this.allSelected.toggle();
    }
    if (this.allSelected.selected && !this.selectAllCheckbox) {
      this.allSelected.toggle();
    }
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
