import {Pipe, PipeTransform} from "@angular/core";
import {DateConverter} from "../utils/date-converter";
import {Dayjs} from "dayjs";
import {LocalDate, LocalDateTime} from "@js-joda/core";

@Pipe({
  name: 'dateFormat',
  standalone: true
})
export class MomentPipe implements PipeTransform {
  transform(value: Date | Dayjs | LocalDateTime | LocalDate, dateFormat: string = "YYYY-MM-DD HH:mm:ss"): any {
    return DateConverter.toLocalDateTimeString(value, dateFormat);
  }
}
