export enum AppColor {
  WHITE = '#ffffff',
  YELLOW = '#ffff00',
  RED = '#ff0000',
  GREEN = '#008000',
  BLUE = '#0000ff',
  BLACK = '#000000',
  AQUA = '#00ffff',
  ORANGE = '#ffa500',
  PURPLE = '#800080',
  GRAY = '#808080',
  BROWN = '#a52a2a',
  MAUVE = '#993366',

  PRIMARY = '#2b8cf7',

  PRIMARY_LIGHT = '#96bfef',

  ACCENT = '#ff4081',
  LIGHT_GREY = '#d3d3d3',
  TRACK_GREEN = '#589e21',

  TEAL = '#008080',
  PINK = '#ffC0cb',
  LIME = '#00ff00',
  OLIVE = '#808000',
  NAVY = '#000080',
  MAROON = '#800000',
  CORAL = '#ff7f50',
  TURQUOISE = '#40e0d0',
  GOLD = '#ffd700',
  SILVER = '#c0c0c0',
  INDIGO = '#4b0082',
  VIOLET = '#ee82ee',
  BEIGE = '#f5f5dc',
  MINT = '#3eb489',
  LAVENDER = '#e6e6fa',
  SAND = '#fcdd76',
  RUBY = '#9b111e',
  EMERALD = '#50c878',
  AMBER = '#ffbf00',
  CREAM = '#fffdd0',
  PEACH = '#ffe5b4',
  CHARCOAL = '#36454f'
}
