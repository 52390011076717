import { UnitStatus } from './enums/unit-status';
import { PageModel } from './page-model.model';
import { Page } from './page.model';

export class UnitShort {

  constructor(public id: number,
              public name: string,
              public color: string,
              public status: UnitStatus) {
  }

  public static valueOf(o: UnitShort): UnitShort {
    return o ? new UnitShort(o.id, o.name, o.color, o.status) : null;
  }

  public static valuesOf(list: UnitShort[]): UnitShort[] {
    return list ? list.map(o => UnitShort.valueOf(o)) : [];
  }

  public static pageableValueOf(o: Page<UnitShort>): Page<UnitShort> {
    return o ? new PageModel<UnitShort>(o.empty, o.first, o.last, o.number, o.numberOfElements, o.totalElements, o.totalPages,
      o.urlParams, UnitShort.valuesOf(o.content)) : null;
  }
}
