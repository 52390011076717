import { ConfigType } from './enums/config-type';
import { RequiredType } from './enums/required-type';
import { NotificationSourceBase } from './notification-source-base.model';
import { UnitOfMeasure } from './unit-of-measure.model';

export class NotificationSourceValue extends NotificationSourceBase {

  constructor(public name: string,
              public nameTranslated: string,
              public requiredType: RequiredType,
              public configType: ConfigType,
              public unitOfMeasure: UnitOfMeasure,
              public minConstraint: number,
              public maxConstraint: number,
              public defaultValue: number) {
    super(name, nameTranslated, requiredType, configType, unitOfMeasure);
  }

  public static valueOf(i: NotificationSourceValue): NotificationSourceValue {
    return i ? new NotificationSourceValue(i.name, i.nameTranslated, i.requiredType, i.configType, i.unitOfMeasure,
      i.minConstraint, i.maxConstraint, i.defaultValue) : null;
  }

  public static valuesOf(list: NotificationSourceValue[]): NotificationSourceValue[] {
    return list ? list.map(o => NotificationSourceValue.valueOf(o)) : [];
  }
}
