import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'timeFromNowText',
  standalone: true,
})

export class TimeFromNowTextPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(datetime: number): string {
    const d = this.translateService.instant('time.d');
    const h = this.translateService.instant('time.h');
    const min = this.translateService.instant('time.min');
    const sec = this.translateService.instant('time.sec');
    if (!datetime) {
      return '';
    }
    if (datetime < 60) {
      return Math.round(datetime) + ' ' + sec;
    }
    if (datetime < 3600) {
      return Math.round(datetime / 60) + ` ${min}`;
    }
    if (datetime < 86400) {
      return Math.round(datetime / 60 / 60) + ` ${h}`;
    }
    return Math.round(datetime / 60 / 60 / 24) + ` ${d}`;
  }
}
