import {Component, Input, OnInit} from '@angular/core';
import {Driver} from "../../../../../../common-module/src/lib/modelinterfaces/driver.model";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'cd app-driver-with-avatar',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './driver-with-avatar.component.html',
  styleUrls: ['./driver-with-avatar.component.scss']
})
export class DriverWithAvatarComponent implements OnInit {

  @Input() driver: Driver;

  constructor() {
  }

  ngOnInit(): void {
  }
}
