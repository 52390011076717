import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AppButtonModule} from "../../../buttons/app-button.module";
import {MatDialogClose} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-actions-cancel-save',
  standalone: true,
  imports: [
    AppButtonModule,
    MatDialogClose
  ],
  templateUrl: './dialog-actions-cancel-save.component.html'
})
export class DialogActionsCancelSaveComponent {
  @Input() saveDisable = false;
  @Input() showSpinner = false;

  @Output() save = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
}
