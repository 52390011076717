<div *ngIf="openTab !== tabName.PUBLIC"
     class="inline nav"
     [ngClass]="{'add-border': !searchState}">
  <button mat-icon-button
          [matTooltip]="isSplitByUsers ? ('action.display-by-list' | translate) : ('action.display-by-groups' | translate)"
          (click)="toggleIsSplitByUsers()"
          [ngClass]="{'accent': isSplitByUsers}">
    <mat-icon>{{ isSplitByUsers ? 'format_list_bulleted' : 'account_tree' }}</mat-icon>
  </button>

  <div class="wrapper-button">
    <button mat-icon-button [matMenuTriggerFor]="menuUsers"
            matTooltip="{{ 'term.group-management' | translate }}">
      <mat-icon>label</mat-icon>
    </button>

    <mat-menu #menuUsers="matMenu">
      <button mat-menu-item
              [matMenuTriggerFor]="menuGroups"
              [matMenuTriggerData]="{user: user}"
              *ngFor="let user of filteredUnitGroups">
        <mat-icon>user</mat-icon>
        <span>{{ user.name }}</span>
      </button>
    </mat-menu>

    <mat-menu #menuGroups="matMenu">
      <ng-template matMenuContent let-user="user">
        <button mat-menu-item (click)="onAddGroupDialog(user)">
          <mat-icon class="icon-add-group">label</mat-icon>
          <span>{{ 'action.add-group' | translate }}</span>
        </button>
        <button mat-menu-item
                *ngFor="let group of asUserUnitGroups(user).groups"
                (click)="onEditGroupDialog(user, group)">
          <mat-icon [ngStyle]="{'color': group.color }">label</mat-icon>
          <span>{{ group.name }}</span>
        </button>
      </ng-template>
    </mat-menu>
  </div>

  <button *ngIf="(selectedUserIds$ | async)?.length"
          [disabled]="!isSplitByUsers"
          mat-icon-button
          matTooltip="{{ 'message.tooltip.fold' | translate }}"
          (click)="userAccordion.closeAll()">
    <mat-icon>unfold_less</mat-icon>
  </button>

  <button *ngIf="!(selectedUserIds$ | async)?.length"
          mat-icon-button
          (click)="userAccordion.openAll()"
          [disabled]="!isSplitByUsers"
          matTooltip="{{ 'message.tooltip.unfold' | translate }}">
    <mat-icon>unfold_more</mat-icon>
  </button>

  <button mat-icon-button
          (click)="onOpenPublicAccessDialog()"
          [disabled]="selectedUnitIds.size === 0"
          matTooltip="{{ 'term.public-link' | translate }}">
    <mat-icon>share</mat-icon>
  </button>

  <div class="wrapper-button">
    <button mat-icon-button [matMenuTriggerFor]="menuFilters"
            [ngClass]="{'accent': (currentFilter$ | async) !== unitsFilter.NONE ||
                                  (filteringUnitBySelectedUsers$ | async).length !== userNameList.length}"
            matTooltip="{{ 'term.filter' | translate }}">
      <mat-icon>filter_list</mat-icon>
    </button>
    <mat-menu #menuFilters="matMenu">
      <button mat-menu-item
              *ngFor="let setting of FILTER_LIST"
              (click)="onChangeFilterSetting(setting)">
        <mat-icon *ngIf="setting === (currentFilter$ | async)">check</mat-icon>
        <span *ngIf="setting !== 'video'">{{ 'filter.' + setting | translate }}</span>
        <span *ngIf="setting === 'video'">{{ 'video.' + setting | translate }}</span>
      </button>
      <button mat-menu-item
              (click)="onOpenFilterUsersDialog()">
        <span>{{ 'term.users' | translate }}: </span>
        <span [ngClass]="{accent: (filteringUnitBySelectedUsers$ | async).length !== userNameList.length }">
          ({{ (filteringUnitBySelectedUsers$ | async).length }} / {{ userNameList.length }})
        </span>
      </button>
    </mat-menu>
  </div>

  <!--  orders-->
  <div class="wrapper-button">
    <button mat-icon-button [matMenuTriggerFor]="menuOrders"
            matTooltip="{{ 'term.order' | translate }}">
      <mat-icon>sort</mat-icon>
    </button>
    <mat-menu #menuOrders="matMenu">
      <button mat-menu-item
              *ngFor="let setting of ORDER_LIST"
              (click)="onChangeOrderSetting(setting)">
        {{ ('order.' + setting) | translate }}
      </button>
    </mat-menu>
  </div>

  <button mat-icon-button
          (click)="toggleSearchState()"
          [ngClass]="{'accent': searchState}"
          matTooltip="{{ 'action.search' | translate }}">
    <mat-icon>search</mat-icon>
  </button>
</div>

<div class="search" [ngClass]="{'show': searchState}">
  <mat-form-field class="full-width" [appearance]="'outline'" [subscriptSizing]="'dynamic'" style="padding-top: 0 !important;">
    <mat-label>{{ 'action.search' | translate }}</mat-label>
    <input #inputTag matInput>
    <mat-icon (click)="toggleSearchState()" class="pointer" matSuffix [matTooltip]="'action.close'| translate">close
    </mat-icon>
  </mat-form-field>
</div>

<div class="unit-list-and-notifications" [ngClass]="{'reduce-height': searchState}">
  <div class="list">
    <mat-accordion [multi]="true" #userAccordion="matAccordion" class="accordion">
      <ng-container *ngIf="isSplitByUsers">
        <app-user-expandable *ngFor="let user of filteredUnitGroups"
                             [filter]="currentFilter$ | async"
                             [searchStr]="searchStr"
                             [selectedUnitIdListByUser]="getSelectedUnitIdListByUser(user, selectedUnitIds)"
                             [allUnitsFiltered]="filteredUnits$ | async"
                             [user]="user"
        ></app-user-expandable>
      </ng-container>
      <app-unit-list *ngIf="!isSplitByUsers"
                     [isSplitByUsers]="isSplitByUsers"
                     [isPublicInterface]="openTab === tabName.PUBLIC"
                     [units]="filteredUnits$ | async"
                     [unitGroupViews]="unitGroupViews">
      </app-unit-list>
    </mat-accordion>
  </div>

  <mat-expansion-panel class="notification-panel" *ngIf="showNotificationPanel"
                       (opened)="onChangeStateNotificationPanel()"
                       (closed)="onChangeStateNotificationPanel(false)">
    <mat-expansion-panel-header class="panel-header">
      <mat-panel-title>
        {{ 'term.notifications-last-unread' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <app-notification-table>
    </app-notification-table>
  </mat-expansion-panel>
</div>
