import { Directive } from '@angular/core';

@Directive({
  selector: '[cmnDialogStickyHeader]',
})
export class DialogStickyHeaderDirective {

  constructor() { }

}
