import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DriverManagementService } from '../../../../shared/services/driver-management.service';
import { DriverState } from '../../../../shared/constants/enums/driver-state.enum';

@Component({
  selector: 'app-driver-dialog',
  templateUrl: './driver-dialog.component.html',
  styleUrls: ['./driver-dialog.component.scss', '../../../../../../../common-module/src/lib/app-styles/dialog-common.scss']
})

export class DriverDialogComponent implements OnInit {

  public currentDriverState$: Observable<DriverState>;
  public driverState = DriverState;

  constructor(private driverManagementService: DriverManagementService) { }

  ngOnInit(): void {
    this.currentDriverState$ = this.driverManagementService.state$;
  }
}
