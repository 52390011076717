import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ViewBreakpointService } from '../../../../../../../../common-module/src/lib/app-services/view-breakpoint.service';
import { ConnectionStatus } from '../../../../../../../../common-module/src/lib/modelinterfaces/enums/connection-status';
import { SensorTypeName } from '../../../../../../../../common-module/src/lib/modelinterfaces/enums/sensor-type-name';
import { SensorLastValue } from '../../../../../../../../common-module/src/lib/modelinterfaces/sensor-last-value.model';
import { UnitAdditionalData } from '../../../../../../../../common-module/src/lib/modelinterfaces/unit-additional-data.model';
import { AuthUserService } from '../../../../../../../../common-module/src/lib/app-services/auth-user.service';

@Component({
  selector: 'app-device-status-card',
  templateUrl: './device-status-card.component.html',
  styleUrls: ['../carousel-card-styles.component.scss']
})

export class DeviceStatusCardComponent implements OnChanges {

  @Input() width: number;
  @Input() unitData: UnitAdditionalData;

  public connectionStatus = ConnectionStatus;
  public sensorBatteryLevel: SensorLastValue;
  public sensorGsmLevel: SensorLastValue;
  public timeZone: string;

  constructor(public viewBreakpointService: ViewBreakpointService,
              public translateService: TranslateService,
              private authUserService: AuthUserService) {
    this.timeZone = this.authUserService.getInstantTimeZone();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['unitData']) {
      this.sensorGsmLevel = this.unitData.getSensorLastValueByType(SensorTypeName.GSM_STRENGTH);
      this.sensorBatteryLevel = this.unitData.getSensorLastValueByType(SensorTypeName.DEVICE_BATTERY);
    }
  }
}
