import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import {WarehouseMapComponent} from "../../warehouse-map/warehouse-map.component";
import {Logistic} from "../../../../../../../../common-module/src/lib/modelinterfaces/logistic.model";
import {JobManagerMap} from "../map-wrapper/job-manager.map";
import {SelectionModel} from "@angular/cdk/collections";
import {takeUntil} from "rxjs/operators";
import {JobManagerMapFactory} from "../map-wrapper/job-manager-map.factory";
import {Observable} from "rxjs";

enum SelectionState {
  NONE = 'NONE',
  START = 'START'
}

@Component({
  selector: 'app-job-manager-map',
  templateUrl: './job-manager-map.component.html',
  styleUrls: ['./job-manager-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobManagerMapComponent extends WarehouseMapComponent implements AfterViewInit, OnChanges, OnDestroy {

  selectionStateEnum = SelectionState;

  map: JobManagerMap;

  @Input() jobs: Logistic.Job[] = [];
  @Input() selectionModel: SelectionModel<Logistic.Job> = new SelectionModel<Logistic.Job>();

  @Output() doubleClickedJob = new EventEmitter<Logistic.Job>();

  public groupSelectState = SelectionState.NONE;

  public groupSelectionCount: Observable<number> = new Observable<number>();

  rotatingAnimationState: 'rotate' | 'stop' = 'stop';

  constructor(protected jobManagerMapFactory: JobManagerMapFactory, private cdr: ChangeDetectorRef) {
    super(jobManagerMapFactory);
  }

  ngAfterViewInit(): void {
    this.map = this.jobManagerMapFactory.create({
      mapDivId: this.mapDivId,
      popupDivId: this.popupDivId,
      warehouses: this.warehouses,
      jobs: this.jobs,
      selectionModel: this.selectionModel
    });

    this.map.doubleClickedJob.pipe(takeUntil(this.destroy))
      .subscribe(job => this.doubleClickedJob.emit(job));

    this.groupSelectionCount = this.map.groupSelectionCount;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.map) {
      if (changes.jobs) {
        this.map.setJobs(this.jobs);
      }
      if (changes.selectionModel) {
        this.map.setSelectionModel(this.selectionModel)
      }
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.map.destroy();
  }

  uncheckGroupSelection() {
    this.groupSelectState = SelectionState.NONE;
    this.map.selectGroupSelection("deselect")
  }

  checkGroupSelection() {
    this.groupSelectState = SelectionState.NONE;
    this.map.selectGroupSelection("select")
  }

  cancelGroupSelection() {
    this.groupSelectState = SelectionState.NONE;
    this.map.cancelGroupSelection();
  }

  startGroupSelection() {
    this.groupSelectState = SelectionState.START
    this.map.startGroupSelection()
  }

  centerAllJobs() {
    this.rotatingAnimationState = 'rotate';
    this.map.centerAllJobs();
    setTimeout(() => {
      this.rotatingAnimationState = 'stop';
      this.cdr.detectChanges();
    }, 700);
  }
}

