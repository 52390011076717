import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subscription, timer} from 'rxjs';
import {GpsOdometer} from '../../../../../common-module/src/lib/modelinterfaces/gps-odometer.model';

import {UnitAdditionalData} from '../../../../../common-module/src/lib/modelinterfaces/unit-additional-data.model';
import {Unit} from '../../../../../common-module/src/lib/modelinterfaces/unit.model';
import {UnitAdditionalDataService} from '../../../../../common-module/src/lib/services/unit-additional-data.service';
import {Vehicle} from '../../../../../common-module/src/lib/modelinterfaces/vehicle.model';
import {UnitStateService} from './unit-state.service';


@Injectable({
  providedIn: 'root'
})

export class UnitDataService {

  private readonly PERIOD_TIMER = 90000;

  private _unitDataSource = new BehaviorSubject<UnitAdditionalData>(null);
  private _unitData$ = this._unitDataSource.asObservable();

  private subscription: Subscription;

  constructor(private unitStateService: UnitStateService,
              private unitAdditionalDataService: UnitAdditionalDataService) {
  }

  get unitData$(): Observable<UnitAdditionalData> {
    return this._unitData$;
  }

  public getSelectedUnitData(unitId: number): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this._unitDataSource.next(null);
    if (!unitId) {
      return;
    }
    this.subscription = timer(0, this.PERIOD_TIMER)
      .subscribe(() => {
        this.unitAdditionalDataService.getData(unitId).subscribe(
          data => {
            this._unitDataSource.next(data);
            if (data) {
              this.unitStateService.changeCurrentUnitState(data.unit.id, data.unitState);
            }
          });
      });
  }

  public getInstantUnitData(): UnitAdditionalData {
    return this._unitDataSource.getValue();
  }

  public changeGpsOdometer(unitId: number, gpsOdometer: GpsOdometer): void {
    const updatedUnitData = this.getInstantUnitData();
    if (updatedUnitData.unit.id === unitId) {
      updatedUnitData.gpsOdometer = gpsOdometer;
      this._unitDataSource.next(updatedUnitData);
    }
  }

  public changeVehicle(vehicle: Vehicle): void {
    const updatedUnitData = this.getInstantUnitData();
    if (updatedUnitData.unit.id === vehicle.unitId) {
      updatedUnitData.vehicle = vehicle;
      this._unitDataSource.next(updatedUnitData);
    }
  }

  public changeUnit(unit: Unit): void {
    const updatedUnitData = this.getInstantUnitData();
    if (updatedUnitData.unit.id === unit.id) {
      updatedUnitData.unit = unit;
      this._unitDataSource.next(updatedUnitData);
    }
  }

  public destroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this._unitDataSource.next(null);
  }
}
