<header class="dialog-title">{{ 'term.filter-parameters' | translate }}</header>

<div class="form-container">
  <app-filter-video [isResetAll]="isResetAll"
                    [isTrackOptions]="data?.isTrackOptions"
                    (filterChange)="onReceiveFilter($event)"
  >
  </app-filter-video>
</div>

<div class="dialog-box-buttons">
  <button mat-button class="button" (click)="onResetAll()">{{ 'action.reset' | translate }}</button>
  <button mat-button class="button" (click)="onCancel()">{{ 'action.cancel' | translate }}</button>
  <button mat-button class="button major" (click)="onSubmit()">{{ 'action.save' | translate }}</button>
</div>
