import {Component} from '@angular/core';
import {UrlImage} from '../../constants/url-image';
import {TranslateModule} from "@ngx-translate/core";
import {MatDialogActions, MatDialogClose} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-check-mobile-device',
  standalone: true,
  imports: [
    TranslateModule,
    MatDialogActions,
    MatDialogClose,
    MatButton
  ],
  templateUrl: './check-mobile-device.component.html',
  styleUrls: ['./check-mobile-device.component.scss']
})

export class CheckMobileDeviceComponent {

  public readonly LOGO_ICON = UrlImage.LOGO_WHITE;
}
