import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UnitShort} from "../../../../../../../common-module/src/lib/modelinterfaces/unit-short.model";
import {UnitState} from "../../../../../../../common-module/src/lib/modelinterfaces/unit-state.model";
import {UnitGroupView} from "../../../../../../../common-module/src/lib/modelinterfaces/user-unit-groups.model";

@Component({
  selector: 'app-unit-list-option',
  templateUrl: './unit-list-option.component.html',
  styleUrls: ['./unit-list-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitListOptionComponent {

  readonly DEFAULT_COLOR = '#2b8cf7';

  @Input() timeZone = 'UTC';
  @Input() unit: UnitShort;
  @Input() unitState: UnitState;
  @Input() unreadNotificationCount = 0;
  @Input() highlight = false;
  @Input() unitGroupView: UnitGroupView | undefined;
}
