import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ViewBreakpointService } from '../../../../../../../../common-module/src/lib/app-services/view-breakpoint.service';
import { SensorName } from '../../../../../../../../common-module/src/lib/modelinterfaces/enums/sensor-name';
import { SensorLastValue } from '../../../../../../../../common-module/src/lib/modelinterfaces/sensor-last-value.model';
import { UnitAdditionalData } from '../../../../../../../../common-module/src/lib/modelinterfaces/unit-additional-data.model';

@Component({
  selector: 'app-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['../carousel-card-styles.component.scss']
})

export class AddressCardComponent implements OnChanges {

  @Input() width: number;
  @Input() unitData: UnitAdditionalData;

  public sensorGpsSatellites: SensorLastValue;

  constructor(public viewBreakpointService: ViewBreakpointService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['unitData']) {
      this.sensorGpsSatellites = this.unitData.getSensorLastValueByName(SensorName.SATELLITES);
    }
  }
}
