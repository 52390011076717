import { Mode } from './mode.model';

export class Mechanism {

  constructor(
    public id: number,
    public name: string,
    public engineHoursNorm: number,
    public modes: Mode[]) {
  }

  public static valueOf(i: Mechanism): Mechanism {
    return i ? new Mechanism(i.id, i.name, i.engineHoursNorm, Mode.valuesOf(i.modes)) : null;
  }

  public static valuesOf(list: Mechanism[]): Mechanism[] {
    return list ? list.map(o => Mechanism.valueOf(o)) : [];
  }
}
