import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {CheckMobileDeviceComponent} from './check-mobile-device.component';
import {environment} from '../../../../environments/environment';
import {LocalStorageKey} from '../../../../../../common-module/src/lib/app-enums/local-storage-key';
import {GlobalStyleClass} from '../../../../../../common-module/src/lib/app-enums/global-style-class.enum';

@Injectable({
  providedIn: 'root'
})

export class CheckDeviceService {

  constructor(public dialog: MatDialog) { }

  public openDialog(): void {
    if (navigator.userAgent.includes('Android') && window.localStorage.getItem(LocalStorageKey.CHECK_DEVICE) !== 'disagree') {
      const dialogRef = this.dialog.open(CheckMobileDeviceComponent, {
        panelClass: ['check-device', GlobalStyleClass.DIALOG_SIZE_LIMIT],
        });
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'agree') {
          window.location.href = environment.APP_ANDROID_URL;
        }
        if (result === 'disagree') {
          window.localStorage.setItem(LocalStorageKey.CHECK_DEVICE, result);
        }
      });
    }
  }
}
