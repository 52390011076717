import { DvrFileStatus } from "./enums/dvr-file-status";

export class DvrFileShort {

  constructor(public cameraId: number,
              public name: string,
              public time: string,
              public status: DvrFileStatus,
              public isVideo: boolean) {
  }

  public static valueOf(o: DvrFileShort): DvrFileShort {
    return o ? new DvrFileShort(o.cameraId, o.name, o.time, o.status, o.isVideo) : null;
  }

  public static valuesOf(list: DvrFileShort[]): DvrFileShort[] {
    return list ? list.map(o => DvrFileShort.valueOf(o)) : [];
  }
}
