export class NotificationActionText {

  constructor(public text: string,
              public timeout: number) {
  }

  public static valueOf(i: NotificationActionText): NotificationActionText {
    return i ? new NotificationActionText(i.text, i.timeout) : null;
  }
}
