<header class="box-header">
  <span>{{ title }}</span>
  <app-icon-button-primary *ngIf="actionIcon && !showSpinner"
                           [icon]="actionIcon"
                           (action)="action.emit($event)"
                           [disabled]="actionDisabled">
  </app-icon-button-primary>
  <span *ngIf="showSpinner" [ngTemplateOutlet]="spinner"></span>
</header>

<ng-template #spinner>
  <mat-icon class="header-icon">
    <mat-spinner diameter="20"></mat-spinner>
  </mat-icon>
</ng-template>
