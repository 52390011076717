import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import {Track} from "../../../../../../../common-module/src/lib/modelinterfaces/track.model";
import {Unit} from "../../../../../../../common-module/src/lib/modelinterfaces/unit.model";
import {AppColor} from "../../../../../../../common-module/src/lib/app-enums/app-color";
import {
  MultiTrackToolService,
  MultiTrackToolState
} from "../../toolbar/track-options/search-track-by-geometry/multi-track-tool.service";
import {BaseUnsubscribeComponent} from "../../../../shared/components/base-unsubscribe.component";
import {takeUntil} from "rxjs/operators";
import {DayjsUtil} from "../../../../../../../common-module/src/lib/dayjs.util";
import {MatExpansionPanel} from "@angular/material/expansion";
import {BuildOptions} from "../../../../../../../common-module/src/lib/modelinterfaces/build-options.model";
import {SensorDataStorage} from "../../../../../../../common-module/src/lib/modelinterfaces/sensor-data-storage.model";
import {Mileage} from "../../../../../../../common-module/src/lib/modelinterfaces/mileage.model";

interface Data {
  unit: Unit,
  visible: boolean;
  fullTrack?: Track;
  tracks: TrackData[];
  totalMileage: Mileage;
}

interface TrackData {
  visible: boolean;
  track: Track;
}

@Component({
  selector: 'app-track-trip-geometry-intersection-tool',
  templateUrl: './multi-track-tool.component.html',
  styleUrl: './multi-track-tool.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiTrackToolComponent extends BaseUnsubscribeComponent implements OnInit, OnDestroy {

  @ViewChildren(MatExpansionPanel) panels: QueryList<MatExpansionPanel>;

  isExpanded = true;

  showSearchArea = true;

  data: Data[];

  filteredData: Data[];

  selectedTracks: Set<Track> = new Set();

  totalMileage = 0;

  isShowAllTracks = true;

  currentSort: 'name-asc' | 'name-desc' | 'mileage-asc' | 'mileage-desc' = 'name-asc';

  openedUnitId: number;


  constructor(protected service: MultiTrackToolService,
              private cdr: ChangeDetectorRef) {
    super();
  }

  @Input() colors: AppColor[] = [AppColor.RED, AppColor.GREEN];

  ngOnInit(): void {
    this.service.tracks$.pipe(takeUntil(this.destroy))
      .subscribe(tracks => {
        const unitMap = new Map<number, Data>();
        if (!tracks) {
          return;
        }
        this.service.selectTrack(null);

        tracks.forEach(track => {
          const unitId = track.getUnitId();
          if (!unitMap.has(unitId)) {
            unitMap.set(unitId, {
              unit: track.unit,
              visible: true,
              tracks: [],
              totalMileage: new Mileage(0, 0, 1)
            });
          }
          unitMap.get(unitId).tracks.push(
            {
              track: track,
              visible: true,
            });
          unitMap.get(unitId).totalMileage = unitMap.get(unitId).totalMileage.plus(track.mileage);
        });

        //sort all
        unitMap.forEach(data => data.tracks.sort((a, b) => DayjsUtil.instant(a.track.buildOptions.startTime).unix() - DayjsUtil.instant(b.track.buildOptions.startTime).unix()))

        this.filteredData = Array.from(unitMap.values());
        this.data = Array.from(unitMap.values());

        this.totalMileage = 0;
        for (const d of this.data) {
          this.totalMileage += d.totalMileage.corrected;
        }
        this.doSort();
      })

    this.service.externalTrackSelect$.pipe(takeUntil(this.destroy))
      .subscribe(track => {
        this.selectTrack(track);
        if (track) {
          this.openPanelByUnitId(track.buildOptions.unit.id)
        }
        this.cdr.detectChanges()
      })
  }

  openPanelByUnitId(unitId: number) {
    if (this.filteredData && this.panels) {
      let index = this.filteredData.findIndex(d => d.unit.id === unitId);
      this.panels.toArray()[index].open();
      this.scrollToSelectedTrack();
    }
  }

  scrollToSelectedTrack() {
    setTimeout(() => {
      const element = document.querySelector('.selected-track');
      if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'center'});
      }
    }, 500)
  }


  toggleVisibilityUnit(data: Data, visibility: boolean, $event?: MouseEvent) {
    if ($event) {
      $event.stopPropagation();
    }
    data.visible = visibility;
    data.tracks.forEach(t => this.toggleVisibilityUnitTrack(t, visibility, $event))
  }

  toggleVisibilityUnitTrack(trackData: TrackData, visible: boolean, $event?: MouseEvent) {
    if ($event) {
      $event.stopPropagation();
    }
    trackData.visible = visible;
    this.service.toggleVisibilityForTrack(trackData.track, visible);
    this.updateParentVisibility(trackData);
  }

  updateParentVisibility(trackData: TrackData) {
    const parentData = this.filteredData.find(d => d.tracks.includes(trackData));
    if (parentData) {
      parentData.visible = parentData.tracks.some(t => t.visible);
    }
  }

  select(trackData: TrackData) {
    this.selectTrack(trackData.track);
  }

  selectTrack(track: Track) {
    if (!this.selectedTracks.has(track)) {
      this.selectedTracks.clear();
      this.selectedTracks.add(track);
      this.service.selectTrack(track);
    } else {
      this.selectedTracks.clear();
      this.service.selectTrack(null);
    }
  }

  protected readonly AppColor = AppColor;

  onClose() {
    this.data = null;
    this.filteredData = null;
    this.service.clear();
    this.selectedTracks.clear();
    this.service.selectTrack(null);
  }

  protected readonly MultiTrackToolState = MultiTrackToolState;

  ngOnDestroy() {
    this.onClose();
  }


  selectAll(data: Data) {
    this.selectedTracks.clear();
    this.service.selectTrack(null);
    data.tracks.forEach(t => this.selectedTracks.add(t.track));

    if (data.fullTrack) {
      this.service.selectTrack(data.fullTrack)
    } else {
      let copyBO = BuildOptions.valueOf(data.tracks[0].track.buildOptions);
      copyBO.startTime = data.tracks[0].track.buildOptions.startTime;
      copyBO.finishTime = data.tracks[data.tracks.length - 1].track.buildOptions.finishTime;
      const fullTrackSample = new Track(copyBO, null, [], [], new SensorDataStorage(data.tracks[0].track.storage.sensors, null))
      data.fullTrack = fullTrackSample;
      this.service.selectFullTrack(fullTrackSample);
    }
  }

  onSearchChange(searchString: string) {
    this.filteredData = this.data.filter(d => d.unit.name.toLowerCase().includes(searchString.toLowerCase()));
  }

  toggleShowAll(visible: boolean) {
    this.filteredData.forEach(data => this.toggleVisibilityUnit(data, visible));
    this.isShowAllTracks = visible;
  }

  onChangeSort(state: 'name-desc' | 'name-asc' | 'mileage-desc' | 'mileage-asc') {
    this.currentSort = state;
    this.doSort();
  }

  doSort() {
    switch (this.currentSort) {
      case 'name-asc':
        this.filteredData.sort((a, b) => a.unit.name.localeCompare(b.unit.name));
        break;

      case 'name-desc':
        this.filteredData.sort((a, b) => b.unit.name.localeCompare(a.unit.name));
        break;

      case 'mileage-asc':
        this.filteredData.sort((a, b) => a.totalMileage.corrected - b.totalMileage.corrected);
        break;

      case 'mileage-desc':
        this.filteredData.sort((a, b) => b.totalMileage.corrected - a.totalMileage.corrected);
        break;
    }
  }

  toggleExpand(isExpanded: boolean) {
    this.isExpanded = isExpanded;
    if (isExpanded) {
      this.scrollToSelectedTrack();
    }
  }
}
