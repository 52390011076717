import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {UnitShort} from '../../../../../../../../common-module/src/lib/modelinterfaces/unit-short.model';
import {UnitShortService} from '../../../../../../../../common-module/src/lib/services/unit-short.service';
import {MatSelectionList} from "@angular/material/list";

@Component({
  selector: 'app-import-criterion-dialog',
  templateUrl: './import-criterion-dialog.component.html',
  styleUrls: ['../../../../../../../../common-module/src/lib/app-styles/dialog-common.scss', './import-criterion-dialog.component.scss']
})

export class ImportCriterionDialogComponent implements OnInit {

  @ViewChild('unitList') unitList: MatSelectionList;

  public searchIn: string;
  public selectedUnit: UnitShort;
  public unitList$: Observable<UnitShort[]>;

  constructor(private dialogRef: MatDialogRef<ImportCriterionDialogComponent>,
              private unitShortService: UnitShortService) {
  }

  ngOnInit(): void {
    this.unitList$ = this.unitShortService.getData(true);
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onSubmit(): void {

    this.dialogRef.close({
      selectedUnitId: this.unitList.selectedOptions.hasValue() ? this.unitList.selectedOptions.selected[0].value.id : undefined
    });
  }
}
