@if (certificateExpire) {
  <div class="flex flex-v-center flex-gap8" [matTooltip]="shortView? expireMessage: null" [matTooltipShowDelay]="700">
    @if (expireType === 'ok') {
      <div>
        <mat-icon class="text-green">verified_user</mat-icon>
      </div>
    }
    @if (expireType === 'expiry-soon') {
      <div>
        <mat-icon class="text-orange">privacy_tip</mat-icon>
      </div>
    }
    @if (expireType === 'expired') {
      <div>
        <mat-icon class="text-red">shield</mat-icon>
      </div>
    }
    <div class="message">
      @if (shortView) {
        {{ certificateExpire | dateFormat : 'DD.MM.YYYY' }}
      } @else {
        {{ expireMessage }}
      }
    </div>

  </div>
} @else {
  <mat-icon>remove_moderator</mat-icon>
}
