export class UnitVideoShort {

  constructor(public id: number,
              public deviceImei: string) {
  }

  public static valueOf(i: UnitVideoShort): UnitVideoShort {
    return i ? new UnitVideoShort(i.id, i.deviceImei) : null;
  }

  public static valuesOf(list: UnitVideoShort[]): UnitVideoShort[] {
    return list ? list.map(o => UnitVideoShort.valueOf(o)) : [];
  }
}
