
<app-dialog [title]="'Доступные города для классификации пробега по геопозиции'"
            [showDialogActions]="false">

  <ng-container appDialogContent>
    <app-mileage-classifier-scanned-map></app-mileage-classifier-scanned-map>
  </ng-container>

</app-dialog>

