import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {AppVersion} from '../../modelinterfaces/app-version.model';
import {AppVersionService} from '../../services/app-version.service';
import {environment as userEnv} from '../../../../../user-portal/src/environments/environment';
import {environment as adminEnv} from '../../../../../admin-portal/src/environments/environment';
import {AppDialogModule} from "../../dialog/dialog/app-dialog.module";
import {TranslateModule} from "@ngx-translate/core";
import {MatDivider} from "@angular/material/divider";
import {AsyncPipe} from "@angular/common";

@Component({
  selector: 'app-version',
  standalone: true,
  imports: [
    AppDialogModule,
    TranslateModule,
    MatDivider,
    AsyncPipe
  ],
  templateUrl: './app-version.component.html',
  styleUrls: ['./app-version.component.scss', '../../app-styles/dialog-common.scss']
})

export class AppVersionComponent implements OnInit {

  public version$: Observable<AppVersion>;

  constructor(private appVersionService: AppVersionService,
              private dialogRef: MatDialogRef<AppVersionComponent>) {
  }

  ngOnInit(): void {
    this.getData();
  }

  getAdminGuiVersion(): string {
    return adminEnv.GUI_VERSION;
  }

  getUserGuiVersion(): string {
    return userEnv.GUI_VERSION;
  }

  private getData(): void {
    this.version$ = this.appVersionService.get();
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}
