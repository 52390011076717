import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AppCommonModule} from '../../../../../../common-module/src/lib/app-common.module';
import {SharedModule} from '../../../shared/shared.module';
import { MapToolsModule } from '../map/map-tools.module';
import {VideoSharedModule} from "../video/video-shared.module";
import {ChartDialogComponent} from './chart-dialog/chart-dialog.component';
import {ReportOptionsComponent} from './report-options/report-options.component';
import {
  ReportSelectedOptionsComponent
} from './report-options/report-selected-options/report-selected-options.component';
import {ToolbarComponent} from './toolbar.component';
import {TrackOptionsComponent} from './track-options/track-options.component';
import {SelectGeofencesComponent} from './report-options/select-geofences/select-geofences.component';
import {SensorValuePipe} from "../../../../../../common-module/src/lib/pipes/sensor-value.pipe";
import {SensorListComponent} from "./chart-dialog/sensor-list/sensor-list.component";
import {
  ReportSettingsConstructorDialogComponent
} from "./report-options/report-selected-options/report-settings-constructor-dialog/report-settings-constructor-dialog.component";
import {
  ReportSettingsConstructorDialogOpener
} from "./report-options/report-selected-options/report-settings-constructor-dialog/report-settings-constructor-dialog.opener";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {AppDialogModule} from "../../../../../../common-module/src/lib/dialog/dialog/app-dialog.module";
import {TranslateModule} from "@ngx-translate/core";
import {MatIconModule} from "@angular/material/icon";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatCardModule} from "@angular/material/card";
import {
  DateRangePickerComponent
} from "../../../../../../common-module/src/lib/app-components/date-range-picker/date-range-picker.component";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatSelectModule} from "@angular/material/select";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {GeofenceFilterPipe} from "../../../shared/pipes/geofence-filter.pipe";
import {MatListModule} from "@angular/material/list";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {AppButtonModule} from "../../../../../../common-module/src/lib/buttons/app-button.module";
import {MatInputModule} from "@angular/material/input";
import {
  DialogActionsCancelSaveComponent
} from "../../../../../../common-module/src/lib/dialog/dialog-save/dialog-actions-cancel-save/dialog-actions-cancel-save.component";

@NgModule({
  declarations: [
    ToolbarComponent,
    ReportOptionsComponent,
    ReportSelectedOptionsComponent,
    TrackOptionsComponent,
    ChartDialogComponent,
    SelectGeofencesComponent,
    ReportSettingsConstructorDialogComponent
  ],
  imports: [
    AppCommonModule,
    CommonModule,
    SharedModule,
    VideoSharedModule,
    MapToolsModule,
    SensorValuePipe,
    SensorListComponent,
    DragDropModule,
    MapToolsModule,
    AppDialogModule,
    TranslateModule,
    MatIconModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatCardModule,
    DateRangePickerComponent,
    MatButtonToggleModule,
    MatSelectModule,
    FormsModule,
    NgxMatSelectSearchModule,
    GeofenceFilterPipe,
    MatListModule,
    MatSlideToggleModule,
    AppButtonModule,
    SensorValuePipe,
    MatInputModule,
    DialogActionsCancelSaveComponent
  ],
  providers: [
    ReportSettingsConstructorDialogOpener
  ],
  bootstrap: [],
  exports: [
    ToolbarComponent,
    ReportOptionsComponent,
    ReportSelectedOptionsComponent,
    TrackOptionsComponent,
    ChartDialogComponent
  ]
})

export class ToolbarModule {
}
