<app-dialog [title]="title"
            [showSpinner]="showSpinner">

  <ng-container appDialogContent>
    <ng-content></ng-content>
  </ng-container>

  <ng-container appDialogActions>
    <app-dialog-actions-cancel-save (cancel)="cancel.emit()"
                                    (save)="save.emit()"
                                    [saveDisable]="disableSave"
                                    [showSpinner]="saveButtonShowSpinner">
    </app-dialog-actions-cancel-save>
  </ng-container>
</app-dialog>
