import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {
  ParameterizedMessage
} from "../../../../../../../common-module/src/lib/modelinterfaces/paramitized-message.model";
import {TranslateService} from "@ngx-translate/core";
import {UnitState} from "../../../../../../../common-module/src/lib/modelinterfaces/unit-state.model";
import {UnitStateConst} from "../../../../shared/utils/unit-state.const";
import {Driver} from "../../../../../../../common-module/src/lib/modelinterfaces/driver.model";

@Component({
  selector: 'app-short-unit-state-info',
  templateUrl: './short-unit-state-info.component.html',
  styleUrls: ['./short-unit-state-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShortUnitStateInfoComponent {

  readonly STOP_SPEED = UnitStateConst.STOP_SPEED;

  warningText = '';
  alertText = '';
  secondsAgo = 0;
  speed = 0;
  driver: Driver;

  constructor(private translateService: TranslateService) {
  }

  @Input() highlight = false;

  @Input() set unitState(unitState: UnitState) {
    this.warningText = this.convertWarningsInString(unitState.warnings);
    this.alertText = this.convertWarningsInString(unitState.alerts);
    this.secondsAgo = unitState.connectionState.secondsAgo;
    this.speed = unitState.gpsState?.speed || 0;
    this.driver = unitState.driver;
  }

  public convertWarningsInString(messages: ParameterizedMessage[]): string {
    return messages.map(m => this.translateService.instant('parameterized-message.' + m.code, m.params)).join(`;\n`);
  }
}
