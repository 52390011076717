<div class="flex flex-direction-row flex-justify-center flex-v-center flex-gap8">
  <app-button-cancel (cancel)="cancel.emit()" matDialogClose>
  </app-button-cancel>

  <app-button-save (save)="save.emit()"
                          [showSpinner]="showSpinner"
                          [disabled]="saveDisable">
  </app-button-save>

</div>
