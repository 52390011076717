<app-dialog [title]="'action.send-command' | translate" [showDialogActions]="">

  <div appDialogContent [ngClass]="{'grow-width': (size$ | async).more('m')}">
    <span *ngIf="showSpinner" [ngTemplateOutlet]="spinner"></span>

    <div class="dialog-subheader to-left flex flex-v-center">
      <span>{{ 'term.command-templates' | translate }}</span>

      <button mat-icon-button class="app-icon-button primary" (click)="onAddTemplate()">
        <mat-icon>add</mat-icon>
      </button>
    </div>

    <div class="template-box">
      <mat-chip-listbox>
        <mat-chip-option *ngFor="let template of templateList">

          <div class="flex flex-v-center flex-gap12">
            <div matTooltip="{{ template.text + ', ' + template.timeout }}{{ 'uom.sec' | translate }}"
                 (click)="onAddTemplateToInput(template)">
              {{ template.name }}
            </div>

            <mat-icon matTooltip="{{ 'action.edit' | translate }}"
                      class="pointer text-primary-color"
                      (click)="onEditTemplate(template.id)">
              edit
            </mat-icon>
          </div>
        </mat-chip-option>
      </mat-chip-listbox>
    </div>

    <div class="box-send-command">
      <mat-chip-option *ngIf="selectedTemplate">
        <div class="flex flex-v-center flex-gap12">
          <div>{{ selectedTemplate.name }}</div>
          <mat-icon class="pointer text-primary-color"
                    (click)="onRemoveTemplateFromInput(selectedTemplate)">
            clear
          </mat-icon>
        </div>
      </mat-chip-option>

      <mat-form-field class="form-send-command">
        <mat-label>{{ 'term.command' | translate }}</mat-label>
        <input matInput
               #inputCommand
               (keyup)="onKeyUp(inputCommand.value, inputTimeout.value, $event)">

        <div class="protocol">
          <span>{{ 'unit.protocol' | translate }}: </span>
          <span>{{ data?.unit?.device?.protocol.name }}</span>
        </div>
      </mat-form-field>

      <mat-form-field class="form-timeout">
        <mat-label>{{ 'sensor.option.timeout' | translate }}</mat-label>
        <input matInput
               #inputTimeout
               matTooltip="{{ 'message.tooltip.send-command-timeout' | translate }}"
               type="number"
               [min]="commandTemplateTimeout.MIN_SEC"
               [max]="commandTemplateTimeout.MAX_SEC"
               [value]="commandTemplateTimeout.DEFAULT_SEC.toString()"/>
        <span matTextSuffix>{{ 'uom.sec' | translate }}</span>
      </mat-form-field>

      <button mat-button
              class="app-button primary"
              (click)="sendCommand(inputCommand.value, inputTimeout.value)"
              [disabled]="disableSendButton">

        <span *ngIf="!loading else spinner">{{ 'action.send' | translate }}</span>
      </button>
    </div>

    <ng-template #spinner>
      <mat-icon class="header-icon">
        <mat-spinner diameter="20"></mat-spinner>
      </mat-icon>
    </ng-template>

    <div class="common-box-table specify-height" *ngIf="dataSource.length > 0">
      <table class="table" mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="displayedColumns[0]">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="status">
            <ng-container [ngSwitch]="true">
              <mat-spinner *ngSwitchCase="asCommand(element).status === commandStatus.SENDING"
                           diameter="20"
                           matTooltip="{{ 'message.tooltip.sending' | translate }}">
              </mat-spinner>

              <mat-icon *ngSwitchCase="asCommand(element).status === commandStatus.SENT"
                        class="green"
                        matTooltip="{{ 'message.tooltip.sent' | translate }}">
                check_circle
              </mat-icon>

              <mat-icon *ngSwitchCase="asCommand(element).status === commandStatus.PENDED"
                        class="gray"
                        matTooltip="{{ 'message.tooltip.pended' | translate }}">
                pending
              </mat-icon>

              <mat-icon *ngSwitchCase="asCommand(element).status === commandStatus.TIMEOUTED"
                        class="red"
                        matTooltip="{{ 'message.tooltip.timeouted' | translate }}">
                timer
              </mat-icon>

              <mat-icon *ngSwitchCase="asCommand(element).status === commandStatus.ERROR"
                        class="red"
                        matTooltip="{{ 'message.error.error' | translate }}">
                error
              </mat-icon>
            </ng-container>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="displayedColumns[1]">
          <th mat-header-cell *matHeaderCellDef>{{ 'term.created-time' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{ asCommand(element).createdTime | utcDateTz: (authUser$ | async)?.timeZone.name }}
          </td>
        </ng-container>

        <ng-container [matColumnDef]="displayedColumns[2]">
          <th mat-header-cell *matHeaderCellDef>{{ 'term.sending-time' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{
              asCommand(element).actualTime ?
                (asCommand(element).actualTime | utcDateTz: (authUser$ | async)?.timeZone.name) : asCommand(element).actualTime
            }}
          </td>
        </ng-container>

        <ng-container [matColumnDef]="displayedColumns[3]">
          <th mat-header-cell *matHeaderCellDef>{{ 'term.type' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="asCommand(element).commandType === commandType.TCP_COMMAND">call_made</mat-icon>
            <mat-icon *ngIf="asCommand(element).commandType === commandType.TCP_ANSWER">call_received</mat-icon>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="displayedColumns[4]">
          <th mat-header-cell *matHeaderCellDef>{{ 'term.text' | translate }}</th>
          <td class="column-text" mat-cell *matCellDef="let element">
            <mat-chip-option *ngIf="asCommand(element).template"
                             class="template-chip template-in-text"
                             matTooltip="{{  asCommand(element).template.text + ', ' +  asCommand(element).template.timeout }}
                                {{ 'uom.sec' | translate }}">
              {{ asCommand(element).template.name }}
            </mat-chip-option>

            <span>{{ isolateTemplateFromText(element) }}</span>
          </td>
        </ng-container>

        <tr class="header" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row [@new-row]="checkNewTime(asCommand(row).actualTime)"
            *matRowDef="let row; columns: displayedColumns;">
        </tr>
      </table>
    </div>
  </div>

  <mat-paginator appDialogActions
                 [ngClass]="{'paginator_eq_s': (size$ | async).equal('s')}"
                 [length]="resultsLength"
                 [pageSize]="pageSize"
                 [pageSizeOptions]="pageSizeOptions"
                 [showFirstLastButtons]="(size$ | async).more('s')">
  </mat-paginator>

</app-dialog>
