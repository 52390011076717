import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationDetails } from '../modelinterfaces/notification-details.model';
import { HttpParamsUtil } from '../utils/http-params-util';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class NotificationDetailsService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getDetails(notificationId: number, lang: string): Observable<NotificationDetails> {
    const url = `${this.environmentHelper.urlV2}/notificationDetails/${notificationId}`;
    return this.http.get<NotificationDetails>(url, {params: HttpParamsUtil.createByLang(lang)}).pipe(
      map(obj => NotificationDetails.valueOf(obj))
    );
  }
}
