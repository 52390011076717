import { UnitShort } from './unit-short.model';

export class UnitGroup {

  constructor(public id: number,
              public name: string,
              public color: string,
              public units: UnitShort[]) {
  }

  public static valueOf(i: UnitGroup): UnitGroup {
    return i ? new UnitGroup(i.id, i.name, i.color, i.units) : null;
  }

  public static valuesOf(list: UnitGroup[]): UnitGroup[] {
    return list ? list.map(o => UnitGroup.valueOf(o)) : [];
  }
}
