<div [style.width.px]="width" class="card"
     [ngClass]="{'card_le_l': (viewBreakpointService.size$ | async).less('l')}"
>
  <h4>{{ 'term.expiring-terms' | translate }}</h4>

  <p class="title">
    <span>{{ 'unit.expiry-date-tech-inspection' | translate }}:</span>
  </p>

  <p class="align-text">
    <app-expire-icon [dateString]="unitData?.vehicle?.expiryDateTechInspection"></app-expire-icon>
    {{ unitData?.vehicle?.expiryDateTechInspection ?
    (unitData.vehicle.expiryDateTechInspection | date : 'dd.MM.yyyy' : '' : 'en-US') : '__.__.____' }}
  </p>

  <p class="title">
    <span>{{ 'unit.expiry-date-insurance' | translate }}:</span>
  </p>
  <p class="align-text">
    <app-expire-icon [dateString]="unitData?.vehicle?.expiryDateInsurance"></app-expire-icon>
    {{ unitData?.vehicle?.expiryDateInsurance ?
    (unitData.vehicle.expiryDateInsurance | date : 'dd.MM.yyyy' : '' : 'en-US') : '__.__.____' }}
  </p>

  <p class="title">
    <span>{{ 'unit.expiry-date-speedometer' | translate }}:</span>
  </p>
  <p class="align-text">
    <app-expire-icon [dateString]="unitData?.vehicle?.expiryDateSpeedometer"></app-expire-icon>
    {{ unitData?.vehicle?.expiryDateSpeedometer ?
    (unitData.vehicle.expiryDateSpeedometer | date : 'dd.MM.yyyy' : '' : 'en-US') : '__.__.____' }}
  </p>
</div>
