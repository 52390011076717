import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnitStatus } from '../modelinterfaces/enums/unit-status';
import { ImportResult } from '../modelinterfaces/import-result.model';
import { MultiUnits } from '../modelinterfaces/multi-units.model';
import { TripSettings } from '../modelinterfaces/trip-settings.model';
import { Unit } from '../modelinterfaces/unit.model';
import { UserShort } from '../modelinterfaces/user-short.model';
import { HttpParamsUtil } from '../utils/http-params-util';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class UnitService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getById(unitId: number): Observable<Unit> {
    const url = `${this.environmentHelper.urlV2}/unit/${unitId}`;
    return this.http.get<Unit>(url).pipe(
      map(obj => Unit.valueOf(obj))
    );
  }

  public save(unit: Unit): Observable<Unit> {
    const url = `${this.environmentHelper.urlV2}/unit`;
    return this.http.post<Unit>(url, unit).pipe(
      map(obj => Unit.valueOf(obj))
    );
  }

  public multiSave(units: MultiUnits): Observable<Unit[]> {
    const url = `${this.environmentHelper.urlV2}/unit/multi`;
    return this.http.post<Unit[]>(url, units).pipe(
      map(obj => Unit.valuesOf(obj))
    );
  }

  public update(unit: Unit): Observable<Unit> {
    const url = `${this.environmentHelper.urlV2}/unit/${unit.id}`;
    return this.http.put<Unit>(url, unit).pipe(
      map(obj => Unit.valueOf(obj))
    );
  }

  public delete(unitId: number): Observable<void> {
    const url = `${this.environmentHelper.urlV2}/unit/${unitId}`;
    return this.http.delete<void>(url);
  }

  public import(userId: number, files: FormData, actions: string[], lang: string): Observable<ImportResult[]> {
    const url = `${this.environmentHelper.urlV2}/unit/import`;

    let params = HttpParamsUtil.createByLang(lang);
    params = params.set('userId', userId);
    params = params.set('actions', actions.join(','));
    return this.http.post<ImportResult[]>(url, files, {params: params}).pipe(
      map(list => ImportResult.valuesOf(list))
    );
  }

  public assignDriver(unitId, driverId: number): Observable<Unit> {
    const url = `${this.environmentHelper.urlV2}/unit/${unitId}/driver/${driverId}`;
    return this.http.patch<Unit>(url, null).pipe(
      map(obj => Unit.valueOf(obj))
    );
  }

  public clearDriver(unitId: number): Observable<Unit> {
    const url = `${this.environmentHelper.urlV2}/unit/${unitId}/driver/clear`;
    return this.http.patch<Unit>(url, null).pipe(
      map(obj => Unit.valueOf(obj))
    );
  }

  public isExistName(value: string, name: string): Observable<boolean> {
    const url = `${this.environmentHelper.urlV2}/unit/existBy`;
    let params = new HttpParams();
    params = params.set(name, value.replace('+', '%2b'));
    return this.http.get<boolean>(url, {params: params});
  }

  public changeName(unitId: number, name: string): Observable<Unit> {
    const url = `${this.environmentHelper.urlV2}/unit/${unitId}/name`;
    return this.http.patch<Unit>(url, {name: name}).pipe(
      map(obj => Unit.valueOf(obj))
    );
  }

  public changeColor(unitId: number, color: string): Observable<Unit> {
    const url = `${this.environmentHelper.urlV2}/unit/${unitId}/color`;
    return this.http.patch<Unit>(url, {color: color}).pipe(
      map(obj => Unit.valueOf(obj))
    );
  }

  public changeOwner(unitId: number, owner: UserShort): Observable<Unit> {
    const url = `${this.environmentHelper.urlV2}/unit/${unitId}/owner`;
    return this.http.patch<Unit>(url, {owner: owner}).pipe(
      map(obj => Unit.valueOf(obj))
    );
  }

  public changeDataStorageTimeDays(unitId: number, storagePeriod: number): Observable<Unit> {
    const url = `${this.environmentHelper.urlV2}/unit/${unitId}/dataStorageTimeDays`;
    return this.http.patch<Unit>(url, {dataStorageTimeDays: storagePeriod}).pipe(
      map(obj => Unit.valueOf(obj))
    );
  }

  public changeStatus(unitId: number, status: UnitStatus): Observable<Unit> {
    const url = `${this.environmentHelper.urlV2}/unit/${unitId}/status`;
    return this.http.patch<Unit>(url, {status: status}).pipe(
      map(obj => Unit.valueOf(obj))
    );
  }

  public changeTripSettings(unitId: number, tripSettings: TripSettings): Observable<Unit> {
    const url = `${this.environmentHelper.urlV2}/unit/${unitId}/tripSettings`;
    return this.http.patch<Unit>(url, {tripSettings: tripSettings}).pipe(
      map(obj => Unit.valueOf(obj))
    );
  }

  public changeUrbanThresholdSpeed(unitId: number, speed: number): Observable<Unit> {
    const url = `${this.environmentHelper.urlV2}/unit/${unitId}/urbanThresholdSpeed`;
    return this.http.patch<Unit>(url, {urbanThresholdSpeed: speed}).pipe(
      map(obj => Unit.valueOf(obj))
    );
  }

  public changeCustomFields(unitId: number, customFields: {[p: string]: string}): Observable<Unit> {
    const url = `${this.environmentHelper.urlV2}/unit/${unitId}/customFields`;
    return this.http.patch<Unit>(url, {customFields}).pipe(
      map(obj => Unit.valueOf(obj))
    );
  }


}
