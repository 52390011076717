<mat-form-field [appearance]="appearance">
  <mat-label>{{label}}</mat-label>
  <mat-icon matPrefix color="primary">language</mat-icon>
  <mat-select [formControl]="control" (selectionChange)="languageChange.emit($event.value)">
    <mat-option *ngFor="let language of $languages | async" [value]="language.name">
      {{ language.localName }}
    </mat-option>
  </mat-select>
</mat-form-field>

