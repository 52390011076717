import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, delay, map, switchMap } from 'rxjs/operators';

export const hasDuplicate = (lines: string[], ignoreLines: string[] = []) => (control: AbstractControl): { [p: string]: boolean } => {
  if (ignoreLines.indexOf(control.value) > -1) {
    return null;
  }
  if (lines.indexOf(control.value) > -1) {
    return {duplicate: true};
  } else {
    return null;
  }
};

export const hasDuplicateInList = (control: AbstractControl): { [p: string]: boolean } => {
  const formGroup = control?.parent;
  if (!formGroup || !formGroup?.controls) {
    return null;
  }
  const controlName = Object.keys(formGroup.controls).find(name => control === formGroup.controls[name]) || null;
  const formGroupList = formGroup?.parent.controls as UntypedFormGroup[];
  const list = formGroupList.map(group => group.controls[controlName]?.value).filter(value => !!value && control?.value === value);
  if (list.length > 1) {
    return {duplicateInList: true};
  } else {
    return null;
  }
};

export const asyncIsExist = (functionIsExist: (name: string) => Observable<boolean>, currentValue = null) =>
  (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (currentValue === control.value || !control.value) {
      return of(null);
    }
    const formGroup = control.parent?.controls || {};
    const controlName = Object.keys(formGroup).find(name => control === formGroup[name]) || null;
    return of({name: controlName, value: control.value})
      .pipe(delay(700),
        switchMap(res => functionIsExist.call(this, res.value, res.name)
          .pipe(
            catchError(() => of({nameIsUsed: true})),
            map(isExist => isExist ? {nameIsUsed: true} : null))
        )
      );
  };

export const asyncIsExistInFormGroup = (functionIsExist: (name: string) => Observable<boolean>, currentValue = null) =>
  (formGroup: UntypedFormGroup): Observable<ValidationErrors | null> => {
    const nameList = Object.keys(formGroup.value).map(name => formGroup.value[name]).filter(name => !!name);
    if (nameList.length === 0 || nameList.join(',') === currentValue.join(',')) {
      return of(null);
    }
    return of({value: nameList})
      .pipe(delay(700),
        switchMap(res => functionIsExist.call(this, res.value)
          .pipe(
            catchError(() => of({nameIsUsed: true})),
            map(isExist => isExist ? {nameIsUsed: true} : null))
        )
      );
  }
