import {Component, Inject} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {UnitShort} from '../../../../../../../../common-module/src/lib/modelinterfaces/unit-short.model';
import {Unit} from '../../../../../../../../common-module/src/lib/modelinterfaces/unit.model';
import {VehicleManagerService} from "../../../../../shared/services/vehicle-manager.service";

@Component({
  selector: 'app-edit-unit-view-dialog',
  templateUrl: './edit-unit-view-dialog.component.html',
  styleUrls: ['../../../../../../../../common-module/src/lib/app-styles/dialog-common.scss']
})

export class EditUnitViewDialogComponent {

  public form$: Observable<UntypedFormGroup> = this.vehicleManagerService.currentForms$.pipe(map(forms => forms[0]));

  constructor(@Inject(MAT_DIALOG_DATA) public data: { unit: UnitShort | Unit },
              private dialogRef: MatDialogRef<EditUnitViewDialogComponent>,
              private vehicleManagerService: VehicleManagerService) {
  }

  public onCancel(): void {
    this.vehicleManagerService.onCancelForms(0);
    this.dialogRef.close();
  }

  public onSave(): void {
    this.vehicleManagerService.onSubmitForms(0);
    this.dialogRef.close();
  }
}
