export class MinMaxRange {

  public min: number;
  public max: number;

  constructor(min: number, max: number) {
    this.min = isNaN(min) ? null : min;
    this.max = isNaN(max) ? null : max;
  }

  public static valueOf(i: MinMaxRange): MinMaxRange {
    return i ? new MinMaxRange(i.min, i.max) : null;
  }
}
