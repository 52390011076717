<div class="date-range-input range-below-input"
     [ngClass]="{
      'column_le_l': (size$ | async).less('l'),
      'position-buttons': (size$ | async).less('l') || actionButtons,
      'single-picker': isSinglePicker
     }">
  <header class="header">{{ placeholderName || ('term.date-picker' | translate) }}</header>

  <input ngxDaterangepickerMd
         [alwaysShowCalendars]="true"
         [autoApply]="(size$ | async).more('m') && !actionButtons"
         [closeOnAutoApply]="false"
         [customRangeDirection]="false"
         [dateLimit]="dayLimit ? dayLimit : isSinglePicker ? 1 : null"
         [keepCalendarOpeningWithRange]="actionButtons"
         [linkedCalendars]="true"
         [locale]="locale"
         [lockStartDate]="!!isFuturePeriod"
         [maxDate]="pickerMaxDate"
         [minDate]="minDate"
         [ngModel]="dateTimeRange"
         [opens]="position"
         [ranges]="isSinglePicker ? null : ranges"
         [showCancel]="true"
         [singleDatePicker]="isSinglePicker"
         [timePicker]="true"
         [timePicker24Hour]="true"
         (ngModelChange)="onChangeTimeRange($event)"
         (rangeClicked)="onClickRange()"
         readonly
         class="range-selector form-control"
  />

  <button *ngIf="isClearButton && dateTimeRange?.startDate"
          mat-icon-button
          matTooltip="{{ 'action.clear' | translate }}"
          (click)="clear()"
          class="close">
    <mat-icon>close</mat-icon>
  </button>
</div>
