import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {UnitAdditionalData} from '../modelinterfaces/unit-additional-data.model';
import {HttpParamsUtil} from '../utils/http-params-util';
import {EnvironmentHelper} from './environment-helper';
import {TranslateService} from "@ngx-translate/core";
import {Sensor} from "../modelinterfaces/sensor.model";

@Injectable({
  providedIn: 'root'
})

export class UnitAdditionalDataService {

  private cache = new Map<number, { data: UnitAdditionalData; timestamp: number }>();
  private unitSensorCache = new Map<number, Sensor[]>

  constructor(private http: HttpClient,
              private environmentHelper: EnvironmentHelper,
              private translateService: TranslateService) {
  }

  public getData(unitId: number): Observable<UnitAdditionalData> {

    const currentTime = Date.now();

    if (this.cache.has(unitId)) {
      const cachedEntry = this.cache.get(unitId)!;
      if (currentTime - cachedEntry.timestamp < 60000) {
        return of(cachedEntry.data);
      } else {
        this.cache.delete(unitId)
      }
    }

    const url = `${this.environmentHelper.urlV2}/unitAdditionalData/unit/${unitId}`;
    return this.http.get<UnitAdditionalData>(url, {params: HttpParamsUtil.createByLang(this.translateService.currentLang)}).pipe(
      map(obj => UnitAdditionalData.valueOf(obj)),
      tap((obj) => {
        this.cache.set(unitId, {data: obj, timestamp: currentTime});
      })
    );
  }

  public getSensors(unitId: number): Observable<Sensor[]> {
    if (this.unitSensorCache.has(unitId)) {
      return of(this.unitSensorCache.get(unitId));
    } else {
      return this.getData(unitId).pipe(
        map(d => <Sensor[]>d.sensorLastValue),
        tap(sensors => this.unitSensorCache.set(unitId, sensors))
    )
    }
  }
}
