<div class="container">

  <img [src]="customization?.logoUrl"
       class="logo"
       [ngClass]="{logo_eq_s: size.equal('s')}">

  <p *ngIf="size.more('s')">
      <span class="logo-title">
        {{ customization?.title || '' }}&nbsp;
      </span>
  </p>

  <div *ngIf="badge" class="badge">{{ badge || '' }}</div>

</div>
