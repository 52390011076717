<app-dialog [title]=" 'term.settings' | translate">

  <ng-container appDialogContent>
    <cmn-select-language [label]="'term.language-interface' | translate"
                         [appearance]="'outline'"
                         [language]="changeTracker.interfaceLanguage"
                         (languageChange)="onInterfaceLanguageChange($event)">
    </cmn-select-language>

    <cmn-select-language *ngIf="!isAdminPortal"
                         [label]="'term.language-address' | translate"
                         [appearance]="'outline'"
                         [language]="changeTracker.addressLanguage"
                         (languageChange)="onAddressLanguageChange($event)">
    </cmn-select-language>

    <app-select-timezone [timezone]="changeTracker.timezone"
                         (timezoneChange)="onTimezoneChange($event)">
    </app-select-timezone>
  </ng-container>

  <ng-container appDialogActions>
    <app-dialog-actions-cancel-save [showSpinner]="showSpinner"
                                    [saveDisable]="!changeTracker.hasChanges || showSpinner"
                                    (save)="onSubmit()"
                                    (cancel)="closeDialog()">
    </app-dialog-actions-cancel-save>
  </ng-container>

</app-dialog>
