import { VehicleType } from './vehicle-type.model';

export class Vehicle {

  constructor(public unitId: number,
              public model: string,
              public type: VehicleType,
              public description: string,
              public stateNumber: string,
              public vin: string,
              public chassisNumber: string,
              public inventoryNumber: string,
              public dateManufacture: string,
              public startOperation: string,
              public expiryDateTechInspection: string,
              public expiryDateInsurance: string,
              public expiryDateSpeedometer: string) {
  }

  public static valueOf(o: Vehicle): Vehicle {
    return o ? new Vehicle(o.unitId, o.model, VehicleType.valueOf(o.type), o.description, o.stateNumber,
      o.vin, o.chassisNumber, o.inventoryNumber, o.dateManufacture, o.startOperation, o.expiryDateTechInspection, o.expiryDateInsurance,
      o.expiryDateSpeedometer) : null;
  }
}
