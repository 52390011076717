<app-dialog
  [title]="('action.' + data.action + '-rule' | translate) + ': ' + (currentRule?.name || asType(currentItem)?.translated || '')">

  <ng-container appDialogContent>
    <mat-horizontal-stepper #stepper (selectionChange)="checkIndex($event)">

      <!--      step 0 -->
      <mat-step *ngIf="!data?.notification?.source"> <!-- this step only show from Notification tab -->
        <ng-template *ngIf="(size$ | async).more('s')" matStepLabel>
          <span *ngIf="data.action === action.ADD">{{ 'action.select-type-for-rule' | translate }}</span>
          <span *ngIf="data.action === action.EDIT">{{ 'action.select-rule' | translate }}</span>
        </ng-template>

        <div class="container">
          <button mat-button class="app-button stroked margin-top-8" *ngFor="let item of typeList"
                  (click)="goSelectUnit(item)">
            {{ item.translated }}
          </button>

          <div class="border" *ngIf="sourceListByLastSelectedUnit.length > 0">
            <span class="unit">{{ lastSelectedUnit?.name }}</span>
            <div class="box-rules" *ngFor="let item of sourceListByLastSelectedUnit">
              <ng-container *ngTemplateOutlet="rule; context: {$implicit: item}"></ng-container>
            </div>
          </div>

          <div class="box-rules" *ngFor="let item of sourceListOthers">
            <ng-container *ngTemplateOutlet="rule; context: {$implicit: item}"></ng-container>
          </div>
        </div>

        <ng-template #rule let-item>
          <div class="rule-wrapper">
            <div class="width-100-percent flex flex-v-center flex-justify-space-between flex-gap8">
              <mat-slide-toggle [checked]="item.state === notificationState.ENABLE"
                                (change)="onToggleRuleState($event, item)"
                                matTooltip="{{ item.state === notificationState.ENABLE ?
                           ('action.suspend' | translate) : 'action.resume' | translate }}">
              </mat-slide-toggle>

              <button mat-button (click)="goSelectUnit(item)" class="flex-grow-1 app-button stroked">
                <mat-icon *ngIf="item.color" class="icon-color" [style.color]="item.color">label</mat-icon>
                <span>{{ item.name }}</span>
              </button>

              <app-icon-button-delete matTooltip="{{ 'action.delete' | translate }}"
                                      (delete)="selectedRuleIdToDelete = item.id">
              </app-icon-button-delete>
            </div>

          </div>

          <div *ngIf="item.id === selectedRuleIdToDelete">
            <h3 class="subheader">{{ 'titles.delete-rule' | translate: {rule: item.name} }}</h3>
            <div class="dialog-box-buttons">
              <button class="button" mat-button
                      (click)="selectedRuleIdToDelete = null">{{ 'action.cancel' | translate }}
              </button>
              <button class="button delete" mat-button
                      (click)="onDeleteRule(item)">{{ 'action.delete' | translate }}
              </button>
            </div>
          </div>
        </ng-template>
      </mat-step>
      <!--     end step 0 -->

      <!--     step 1 -->
      <mat-step>
        <ng-template *ngIf="(size$ | async).more('s')" matStepLabel>
          {{ 'action.select-unit' | translate }}
        </ng-template>

        <div class="container" [style.display]="'block'">
          <app-search (searchChange)="searchIn = $event"></app-search>

          <mat-accordion class="accordion mat-elevation-z1" *ngIf="showAccordion">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>{{ 'message.info.not-available-for-units' | translate }}:</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-list>
                <mat-list-item *ngFor="let unit of unusableUnits">{{ unit.name }}</mat-list-item>
              </mat-list>
            </mat-expansion-panel>
          </mat-accordion>

          <div class="selected-number">
            <span>{{ 'message.info.selected-count' | translate: {count: '(' + selectedUnitList.length + ')'} }}</span>
          </div>

          <div class="box-selected-units-search">
            <button
              mat-icon-button
              class="button-apply"
              (click)="applySelectedUnits()"
              matTooltip="{{ 'action.apply-selected-objects' | translate }} {{ getUnitNames(filteredUnitsFromSideBar) }}"
              [disabled]="filteredUnitsFromSideBar.length === 0 && (!showAccordion || (unusableUnits.length > 0 && showAccordion))"
            >
          <span class="unusable-unit" *ngIf="!showAccordion"
                [matBadge]="unusableUnits.length"
                [matBadgeHidden]="unusableUnits.length === 0"
                [matBadgePosition]="'above before'"
                [matBadgeSize]="'small'">
          </span>
              <i class="material-icons"
                 [matBadge]="filteredUnitsFromSideBar.length"
                 [matBadgeHidden]="filteredUnitsFromSideBar.length === 0"
                 [matBadgeSize]="'small'">
                add_task
              </i>
            </button>

            <div class="box-select-all" (click)="onToggleAllChange()">
              <span>{{ 'action.select-all' | translate }}</span>
              <mat-checkbox
                [checked]="selectAll"
                [indeterminate]="isSomeChecked()"
                disabled
              ></mat-checkbox>
            </div>
          </div>

          <div class="selection-list">
            <mat-selection-list
              [ngModel]="selectedUnitList"
              (ngModelChange)="changeSelectedUnitList($event)"
              [compareWith]="comparisonId"
            >
              <mat-list-option *ngFor="let unit of unitList"
                               class="list-option scrolling"
                               [value]="unit"
                               [ngClass]="{'hide-option': !unit.name.toLowerCase().includes(searchIn)}"
              >
                {{ unit.name }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </div>
      </mat-step>
      <!--    end step 1 -->

      <!--    step 2 -->
      <mat-step>
        <ng-template *ngIf="(size$ | async).more('s')" matStepLabel>
          {{ 'term.rule-setting' | translate }}
        </ng-template>

        <div class="container">
          <app-rule-settings
            [currentItem]="currentItem"
            [currentRule]="currentRule"
            [formConfig]="formConfig"
            (changedRuleForm)="receiveRuleForm($event)"
          >
          </app-rule-settings>
        </div>
      </mat-step>
      <!--   end step 2 -->
    </mat-horizontal-stepper>
  </ng-container>


  <ng-container appDialogActions>
    @switch (stepper.selectedIndex) {
      @case (0) {
        <app-button-cancel></app-button-cancel>
      }
      @case (1) {
        <app-button-cancel (cancel)="onCancel()"></app-button-cancel>
        <app-button-back *ngIf="!data?.notification?.source"
                         [stepper]="stepper"
                         (back)="onBackToFirstStep();">
        </app-button-back>

        <app-button-next [disabled]="selectedUnitList.length === 0"
                         [stepper]="stepper">
        </app-button-next>
      }
      @case (2) {
        <app-button-back [stepper]="stepper"></app-button-back>
        <app-button-cancel></app-button-cancel>
        <app-button-save (save)="onSubmit()"
                         [disabled]="!ruleForm?.valid">
        </app-button-save>
      }
      @default {
        <p>actions for step index:{{stepper.selectedIndex}} not found</p>
      }

    }
  </ng-container>

</app-dialog>
