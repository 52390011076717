import {Logistic} from "../../../../../../../../common-module/src/lib/modelinterfaces/logistic.model";
import {
  AbstractValueFeatureWrapper
} from "../../../../aur-map-creator/feature/core/app/feature-wrapper/abstract-value.feature-wrapper";
import {Feature} from "ol";
import {Geometry} from "ol/geom";
import {AppColor} from "../../../../../../../../common-module/src/lib/app-enums/app-color";
import {FeatureLike} from "ol/Feature";
import {StyleFactory, StyleOpts} from "../../../../aur-map-creator/feature/core/app/style.factory";
import Job = Logistic.Job;

export interface JobFeatureSelectOpts {
  fill: AppColor;
  iconColor: AppColor;
  lineWidth: number;
}

export interface JobFeatureHighlightOpts {
  fill: AppColor;
  lineWidth: number;
  zIndex: number;
}

export abstract class AbstractJobFeatureWrapper<G extends Geometry, F extends StyleFactory<OPTS>, OPTS extends StyleOpts> extends AbstractValueFeatureWrapper<G, OPTS, Logistic.Job> {

  private selectedStyle: OPTS = null;
  private _isHighlighted = false;

  constructor(feature: Feature<G>, styleFactory: F, opts: OPTS, value: Logistic.Job,) {
    super(feature, styleFactory, opts, value);
  }

  public isSelected(): boolean {
    return !!this.selectedStyle;
  }

  public select(opts: JobFeatureSelectOpts) {
    if (!this.isSelected()) {
      const styleOptions = this.createSelectStyleOpts(opts);
      this.applyStyle(styleOptions);
      this.selectedStyle = styleOptions;
    }
  }

  protected abstract createSelectStyleOpts(opts: JobFeatureSelectOpts): OPTS;

  public unSelect() {
    if (this.isSelected()) {
      this.resetStyle();
      this.selectedStyle = null;
    }
  }

  public isHighlighted() {
    return this._isHighlighted;
  }

  public highlight(opts: JobFeatureHighlightOpts) {
    if (!this.isHighlighted()) {
      const styleOptions = this.createHighlightStyleOpts(opts);
      this.applyStyle(styleOptions);
      this._isHighlighted = true
    }
  }

  protected abstract createHighlightStyleOpts(opts: JobFeatureHighlightOpts): OPTS;

  public unHighlight() {
    if (this.isHighlighted()) {
      if (this.isSelected()) {
        this.revertSelectStyleIfPresent();
      } else {
        this.resetStyle();
      }
      this._isHighlighted = false
    }
  }

  private revertSelectStyleIfPresent() {
    this.applyStyle(this.selectedStyle);
  }


  public static getJobFromFeature(feature: FeatureLike): Logistic.Job | undefined {
    const job = feature.get(AbstractJobFeatureWrapper.PROPERTY_NAME_VALUE);
    if (job instanceof Logistic.Job) {
      return job;
    }
    return undefined;
  }

}
