export class CalendarFormat {
  public static readonly Month = {
    parse: {
      dateInput: 'D MMMM',
    },
    display: {
      dateInput: 'D MMMM',
      monthYearLabel: 'MMMM',
      dateA11yLabel: '',
      monthYearA11yLabel: 'MMMM YYYY'
    }
  }
}
