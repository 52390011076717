import {ScheduleRange} from "../../../../../../../../../common-module/src/lib/modelinterfaces/schedule-range.model";
import {TimeCellI, TimeTable} from "./TimeTable";
import {Durations} from "../../../../../../shared/utils/durations";

/**
 * create and init
 */
export class TimeTableFactory {

  /**
   * нужно чтобы это значение делило без остатка 86400
   */
  private static STEP = 1800;

  /**
   * @param schedule nullable
   * @return convert {@link ScheduleRange} to {@link TimeTable}
   */
  public static create(schedule: ScheduleRange): TimeTable {
    let table = TimeTableFactory.timeTable();
    this.selectSchedule(table, schedule);
    return table;
  }

  private static selectSchedule(table: TimeTable, schedule: ScheduleRange) {
    schedule = schedule ? schedule : ScheduleRange.FULL_WEEK;
    for (const range of schedule.ranges) {
      let iterator = table.serialIterator(range);
      while (iterator.hasNext()) {
        iterator.next().isSelected = true;
      }
    }
  }

  private static timeTable(): TimeTable {
    const table: TimeCellI[][] = [];
    for (let dayIndex = 0; dayIndex < TimeTable.WEEK_DAY_NAMES.length; dayIndex++) {
      const row = [];
      for (let seconds = 0; seconds <= Durations.DAY_SECONDS - this.STEP; seconds += this.STEP) {
        const cell = {
          weekday: dayIndex,
          timeFrom: seconds,
          timeTo: this.calcBoundDayTime(seconds),
          isSelected: false,
        }
        row.push(cell);
      }
      table.push(row);
    }
    return new TimeTable(table, this.STEP);
  }

  /**
   * если это последняя ячейка то устанавливает время в 23:59:59
   */
  private static calcBoundDayTime(seconds: number) {
    if (seconds + this.STEP != Durations.DAY_SECONDS) {
      return seconds + this.STEP;
    } else {
      return seconds + this.STEP - 1;
    }
  }
}
