import { Component } from '@angular/core';

@Component({
  selector: 'app-toolbar-dialog',
  templateUrl: './toolbar-dialog.component.html',
  styleUrl: './toolbar-dialog.component.scss'
})
export class ToolbarDialogComponent {

}
