import {AbstractMapFactory} from "../abstract.map-factory";
import {Injectable} from "@angular/core";
import {TileLayerService} from "../../../../../services/tile-layer.service";
import {OpenLayerMapService} from "../../../../../services/open-layer-map.service";
import {JobsOverviewMap, JobsOverviewMapConfig} from "../../../../../maps/map/jobs-overview-map/jobs-overview.map";


@Injectable({
  providedIn: 'root'
})
export class JobOverviewMapFactory extends AbstractMapFactory<JobsOverviewMapConfig, JobsOverviewMap> {

  constructor(tileLayerService: TileLayerService,
              openLayerMapService: OpenLayerMapService) {
    super(tileLayerService, openLayerMapService)
  }

  public create(config: JobsOverviewMapConfig) {
    return new JobsOverviewMap(this.createOlMap(config), config);
  }
}
