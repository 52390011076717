import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {LocalStorageKey} from '../../../../../common-module/src/lib/app-enums/local-storage-key';
import {Tab} from '../../../../../common-module/src/lib/app-enums/tab';
import {isPublicView} from "../../app.module";

@Injectable()

export class AuthInterceptor implements HttpInterceptor {

  private readonly EXCLUDE_JSON_CONTENT = [new RegExp(/ecoDrivingCriterion\/unit\/\d+\/import/), /jobImport\/parse/, /logisticRoute\/\d+\/download/];

  constructor() {
  }

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: this.setHeaders(req.url)
    });
    return next.handle(req);
  }

  /* eslint-disable @typescript-eslint/naming-convention */
  private setHeaders(reqUrl: string): { [p: string]: string | string[] } {
    if (isPublicView()) {
      const publicKey: string = this.getPublicKey();
      return {
        'Content-Type': 'application/json',
        'publicKey': publicKey || ''
      };
    }
    const authToken = localStorage.getItem(LocalStorageKey.BEARER_TOKEN);
    if (authToken && this.EXCLUDE_JSON_CONTENT.some(pattern => pattern.test(reqUrl))) {
      return {
        'Authorization': `Bearer ${authToken}`
      };
    }
    if (authToken) {
      return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      };
    }
    return {
      'Content-Type': 'application/json'
    };
  }

  // получаем из window потому что сервис UnitGroupsService инициализируется в AppModule на стадии APP_INITIALIZER на этой стадии Router не доступен
  getPublicKey(): string {
    let url = new URL(window.location.href.toLowerCase());
    return url.searchParams.get('publickey')
  }
}
