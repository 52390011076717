import {ZoneId} from "@js-joda/core";
import '@js-joda/timezone/dist/js-joda-timezone-10-year-range'

export class TimeZoneUtil {

  public static BROWSER_ZONE_ID = TimeZoneUtil.getBrowserTz();

  private static getBrowserTz(): ZoneId {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return ZoneId.of(tz);
  }
}
