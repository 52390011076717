import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from "@ngx-translate/core";
import {Subject} from 'rxjs';
import {takeUntil} from "rxjs/operators";
import {LengthErrorI} from "../../../../../../../common-module/src/lib/app-interfaces/length-error-i";
import {AppMessageService} from "../../../../../../../common-module/src/lib/app-services/app-message.service";
import {UiSpinnerService} from "../../../../../../../common-module/src/lib/app-services/ui-spinner.service";
import {
  UnitAdditionalData
} from '../../../../../../../common-module/src/lib/modelinterfaces/unit-additional-data.model';
import {VehicleType} from "../../../../../../../common-module/src/lib/modelinterfaces/vehicle-type.model";
import {Vehicle} from "../../../../../../../common-module/src/lib/modelinterfaces/vehicle.model";
import {VehicleTypeService} from "../../../../../../../common-module/src/lib/services/vehicle-type.service";
import {VehicleService} from "../../../../../../../common-module/src/lib/services/vehicle.service";
import {AppRegexp} from "../../../../../../../common-module/src/lib/utils/app-regexp";
import {DateConverter} from "../../../../../../../common-module/src/lib/utils/date-converter";
import {VehicleFields} from "../../../../shared/constants/enums/vehicle-fields";
import {SelectedService} from '../../../../shared/services/selected.service';
import {UnitDataService} from "../../../../shared/services/unit-data.service";
import {VehicleManagerService} from "../../../../shared/services/vehicle-manager.service";

@Component({
  selector: 'app-vehicle-form',
  templateUrl: './vehicle-form.component.html',
  styleUrls: ['../../../../../../../common-module/src/lib/app-styles/dialog-common.scss']
})

export class VehicleFormComponent implements OnChanges, OnInit, OnDestroy {

  private readonly MAX_LENGTH_VEHICLE_MODEL = 50;
  private readonly MAX_LENGTH_VEHICLE_STATE_NUMBER = 15;
  private readonly MAX_LENGTH_VEHICLE_DESCRIPTION = 50;
  private readonly MAX_LENGTH_CHASSIS_NUMBER = 20;
  private readonly MAX_LENGTH_INVENTORY_NUMBER = 20;
  private readonly LENGTH_VIN = 17;

  @Input() unitData: UnitAdditionalData;
  @Input() fields: VehicleFields;

  public editVehicleForm: UntypedFormGroup;
  public showSpinner = false;
  public vehicleTypes: VehicleType[];
  public vehicleFields = VehicleFields;

  private destroyed = new Subject<boolean>();

  constructor(private appMessageService: AppMessageService,
              private cdr: ChangeDetectorRef,
              private dialog: MatDialog,
              private formBuilder: UntypedFormBuilder,
              private selectedService: SelectedService,
              private translateService: TranslateService,
              private uiSpinnerService: UiSpinnerService,
              private unitDataService: UnitDataService,
              private vehicleService: VehicleService,
              private vehicleManagerService: VehicleManagerService,
              private vehicleTypeService: VehicleTypeService) {
  }

  get formControl(): { [p: string]: AbstractControl } {
    return this.editVehicleForm.controls;
  }

  get formValue(): any {
    return this.editVehicleForm.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['unitData'] && this.unitData.unit.id) {
      this.buildForm();
    }
  }

  ngOnInit(): void {
    this.getVehicleTypes();

    this.vehicleManagerService.isCancelForms$
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        isCancel => {
          if (isCancel && this.vehicleManagerService.instantTabSelectedIndex() === 1) {
            this.buildForm();
          }
        }
      )

    this.vehicleManagerService.isSubmitForms$
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        isSubmit => {
          if (isSubmit && this.vehicleManagerService.instantTabSelectedIndex() === 1) {
            this.onSubmit();
          }
        }
      )
  }

  private buildForm(): void {
    this.editVehicleForm = this.formBuilder.group({
      model: [
        this.unitData.vehicle ? this.unitData.vehicle.model : null,
        [Validators.pattern(AppRegexp.TEXT), Validators.maxLength(this.MAX_LENGTH_VEHICLE_MODEL)]
      ],
      dateManufacture: [
        this.unitData.vehicle ? this.unitData.vehicle.dateManufacture : null
      ],
      startOperation: [
        this.unitData.vehicle ? this.unitData.vehicle.startOperation : null
      ],
      vehicleType: [null],
      description: [
        this.unitData.vehicle ? this.unitData.vehicle.description : null,
        [Validators.pattern(AppRegexp.TEXT), Validators.maxLength(this.MAX_LENGTH_VEHICLE_DESCRIPTION)]
      ],
      stateNumber: [
        this.unitData.vehicle ? this.unitData.vehicle.stateNumber : null,
        [Validators.pattern(AppRegexp.TEXT), Validators.maxLength(this.MAX_LENGTH_VEHICLE_STATE_NUMBER)]
      ],
      vin: [
        this.unitData.vehicle ? this.unitData.vehicle.vin : null,
        [Validators.pattern(AppRegexp.VIN), Validators.minLength(this.LENGTH_VIN), Validators.maxLength(this.LENGTH_VIN)]
      ],
      chassisNumber: [
        this.unitData.vehicle ? this.unitData.vehicle.chassisNumber : null,
        [Validators.pattern(AppRegexp.TEXT), Validators.maxLength(this.MAX_LENGTH_CHASSIS_NUMBER)]
      ],
      inventoryNumber: [
        this.unitData.vehicle ? this.unitData.vehicle.inventoryNumber : null,
        [Validators.pattern(AppRegexp.TEXT), Validators.maxLength(this.MAX_LENGTH_INVENTORY_NUMBER)]
      ],
      expiryDateTechInspection: [
        this.unitData.vehicle ? this.unitData.vehicle.expiryDateTechInspection : null
      ],
      expiryDateInsurance: [
        this.unitData.vehicle ? this.unitData.vehicle.expiryDateInsurance : null
      ],
      expiryDateSpeedometer: [
        this.unitData.vehicle ? this.unitData.vehicle.expiryDateSpeedometer : null
      ]
    });
    this.editVehicleForm.valueChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.vehicleManagerService.changeForms([this.editVehicleForm]);
      });
    this.vehicleManagerService.changeForms([this.editVehicleForm]);
  }

  private getVehicleTypes(): void {
    if (!this.vehicleTypes) {
      this.showSpinner = true;
      this.vehicleTypeService.getList(this.translateService.currentLang).subscribe(list => {
          this.vehicleTypes = list;
          this.showSpinner = false;
          if (this.unitData?.vehicle?.type?.nameTranslated) {
            const currentVehicleType = this.vehicleTypes.find(el => el.id === this.unitData.vehicle.type.id);
            this.formControl.vehicleType.setValue(currentVehicleType);
          }
          this.cdr.detectChanges();
        },
        error => {
          this.showSpinner = false;
          throw error;
        }
      );
    }
  }

  public onSubmit(): void {
    const newVehicle = new Vehicle(
      this.unitData.vehicle ? this.unitData.vehicle.unitId : 0,
      this.formValue.model,
      this.formValue.vehicleType,
      this.formValue.description,
      this.formValue.stateNumber,
      this.formValue.vin ? this.formValue.vin : null,
      this.formValue.chassisNumber,
      this.formValue.inventoryNumber,
      DateConverter.toDateString(this.formValue.dateManufacture),
      DateConverter.toDateString(this.formValue.startOperation),
      DateConverter.toDateString(this.formValue.expiryDateTechInspection),
      DateConverter.toDateString(this.formValue.expiryDateInsurance),
      DateConverter.toDateString(this.formValue.expiryDateSpeedometer)
    );
    this.uiSpinnerService.show();
    this.vehicleService.update(this.unitData.vehicle.unitId, this.translateService.currentLang, newVehicle)
      .subscribe(
        updatedVehicle => {
          this.unitDataService.changeVehicle(updatedVehicle);
          this.appMessageService.openSnackBar('message.info.changes-saved');
          this.editVehicleForm.markAsPristine();
          this.vehicleManagerService.changeForms([this.editVehicleForm]);
          this.uiSpinnerService.stop();
        }
      );
  }

  public asMaxlength(maxlength: LengthErrorI): LengthErrorI {
    return maxlength;
  }

  ngOnDestroy(): void {
    this.destroyed.next(null);
    this.destroyed.complete();
  }

  clear($event: MouseEvent, formControlName: string) {
    this.editVehicleForm.get(formControlName).setValue(null);
    this.editVehicleForm.get(formControlName).markAsDirty();
    this.vehicleManagerService.changeForms([this.editVehicleForm]);
    $event.stopPropagation();
  }
}
