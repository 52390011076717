import { RangeDayTime } from './range-day-time.model';

export class ScheduleRange {

  public static FULL_WEEK = new ScheduleRange(RangeDayTime.FULL_WEEK);

  constructor(public ranges: RangeDayTime[]) {
  }

  public static valueOf(o: ScheduleRange): ScheduleRange {
    return o ? new ScheduleRange(RangeDayTime.valuesOf(o.ranges)) : null;
  }
}
