import { NotificationStatus } from './enums/notification-status';
import { NotificationSource } from './notification-source.model';
import { PageModel } from './page-model.model';
import { Page } from './page.model';
import { UnitShort } from './unit-short.model';

export class Notification {

  constructor(public id: number,
              public unit: UnitShort,
              public source: NotificationSource,
              public startTime: string,
              public finishTime: string,
              public status: NotificationStatus,
              public createdTime: string,
              public read: boolean) {
  }

  public static valueOf(o: Notification): Notification {
    return o ? new Notification(o.id, o.unit, NotificationSource.valueOf(o.source), o.startTime, o.finishTime, o.status,
      o.createdTime, o.read) : null;
  }

  public static valuesOf(list: Notification[]): Notification[] {
    return list ? list.map(o => Notification.valueOf(o)) : [];
  }

  public static pageableValueOf(o: Page<Notification>): Page<Notification> {
    return o ? new PageModel<Notification>(o.empty, o.first, o.last, o.number, o.numberOfElements, o.totalElements, o.totalPages,
      o.urlParams, Notification.valuesOf(o.content)) : null;
  }
}
