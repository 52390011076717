import {AccuracyAddress} from "./accuracy-address.model";

export class ReverseGeocodedAddress extends AccuracyAddress {

  constructor(latitude: number, longitude: number, name: string, accuracy: Accuracy,
              public lookupAddress: string) {
    super(latitude, longitude, name, accuracy);
  }

  public static valueOf(o: ReverseGeocodedAddress): ReverseGeocodedAddress {
    return o ? new ReverseGeocodedAddress(o.latitude, o.longitude, o.name, o.accuracy, o.lookupAddress) : null;
  }

  public static valuesOf(objs: ReverseGeocodedAddress[]): ReverseGeocodedAddress[] {
    return objs.map(o => this.valueOf(o));
  }
}

export enum Accuracy {
  EXACT = 'EXACT', APPROXIMATE = 'APPROXIMATE', NOT_FOUND = 'NOT_FOUND', CHANGING='CHANGING'
}
