import {AccuracyAddress} from "./accuracy-address.model";
import {Accuracy} from "./geolocated-address.model";

export class GeocodedAddress extends AccuracyAddress {

  constructor(latitude: number, longitude: number, name: string, accuracy: Accuracy) {
    super(latitude, longitude, name, accuracy);
  }

  public static valueOf(o: GeocodedAddress): GeocodedAddress {
    return new GeocodedAddress(o.latitude, o.longitude, o.name, o.accuracy);
  }

  public static valuesOf(objs: GeocodedAddress[]): GeocodedAddress[] {
    return objs.map(o => this.valueOf(o));
  }
}
