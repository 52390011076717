import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {combineLatest, Observable, Subscription, zip} from 'rxjs';
import {map, take} from 'rxjs/operators';

import {ViewBreakpointService, ViewSize} from '../../../../common-module/src/lib/app-services/view-breakpoint.service';
import {FooterButtonsService} from '../shared/services/footer-buttons.service';
import {UiSpinnerService} from '../../../../common-module/src/lib/app-services/ui-spinner.service';
import {SelectedService} from '../shared/services/selected.service';
import {UnitGroupsService} from '../shared/services/unit-groups.service';
import {UnitStateService} from '../shared/services/unit-state.service';
import {UnitFilterService} from '../shared/services/unit-filter.service';
import {JwtTokenService} from '../../../../common-module/src/lib/services/jwt-token.service';
import {LocalStorageKey} from '../../../../common-module/src/lib/app-enums/local-storage-key';
import {ChartDataService} from '../shared/services/chart-data.service';
import {TrackLayerService} from '../shared/services/track-layer.service';
import {Tab} from '../../../../common-module/src/lib/app-enums/tab';
import {NotificationManagerService} from '../shared/services/notification-manager.service';
import {TileLayerService} from '../shared/services/tile-layer.service';
import {GeofenceListService} from '../shared/services/geofence-list.service';
import {MapToolsService} from '../shared/services/map-tools.service';
import {MapSelectUnitService} from '../shared/services/map-select-unit.service';
import {RouteService} from '../shared/services/route.service';
import {ToolbarService} from "../shared/services/toolbar.service";
import {isPublicView} from "../app.module";

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SystemComponent implements OnInit, OnDestroy {

  public isPublicInterface = false;
  public opened = true;
  public loadingComplete = false;
  public size$: Observable<ViewSize>;

  private subscription: Subscription;

  constructor(private chartDataService: ChartDataService,
              private footerButtonsService: FooterButtonsService,
              private geofenceListService: GeofenceListService,
              private jwtTokenService: JwtTokenService,
              private mapSelectUnitService: MapSelectUnitService,
              private mapToolsService: MapToolsService,
              private notificationManagerService: NotificationManagerService,
              private router: Router,
              private routeService: RouteService,
              private selectedService: SelectedService,
              private tileLayerService: TileLayerService,
              private trackLayerService: TrackLayerService,
              private uiSpinnerService: UiSpinnerService,
              private unitGroupsService: UnitGroupsService,
              private unitStateService: UnitStateService,
              private unitFilterService: UnitFilterService,
              private viewBreakpointService: ViewBreakpointService,
              private toolbarService: ToolbarService) {
  }

  ngOnInit(): void {
    this.size$ = this.viewBreakpointService.size$;
    if (isPublicView()) {
      this.loadPublicInterface();
    } else {
      this.loadUsualInterface();
    }
    this.isPublicInterface = this.router.url.includes("public");
  }

  private loadPublicInterface(): void {
    this.loadFooterButtons();
    this.uiSpinnerService.show();
    this.unitStateService.init();
    this.unitGroupsService.initWithOutGroups();
    this.unitFilterService.init(true);
    this.tileLayerService.init();
    this.subscription = zip(
      this.unitStateService.unitsStateWithCheck$,
      this.unitGroupsService.allUnits$
    )
      .pipe(
        map(([unitsStateWithCheck, unitList]) =>
          ({unitsStateWithCheck: unitsStateWithCheck, unitList: unitList}))
      )
      .subscribe((res) => {
          if (res.unitsStateWithCheck?.unitsState && res.unitList) {
            this.uiSpinnerService.stop();
            this.loadingComplete = true;
            this.subscription.unsubscribe();
          }
        },
        error => {
          this.router.navigate([Tab.LOGIN]).then();
          if (this.subscription) {
            this.subscription.unsubscribe();
          }
          throw error;
        });
  }

  private loadUsualInterface(): void {
    if (window.localStorage.getItem(LocalStorageKey.BEARER_TOKEN)) {
      this.loadFooterButtons();
      this.toolbarService.init();
      this.uiSpinnerService.show();
      this.unitGroupsService.init();
      this.unitStateService.init();
      this.unitFilterService.init();
      this.notificationManagerService.init();
      this.tileLayerService.init();
      this.jwtTokenService.refreshBearerToken().subscribe(res => {
        window.localStorage.setItem(LocalStorageKey.BEARER_TOKEN, res.token);
      });

      this.subscription = combineLatest(
        [
          this.unitStateService.unitsStateWithCheck$,
          this.unitGroupsService.unitGroups$]
      )
        .pipe(
          map(([unitsStateWithCheck, unitGroups]) =>
            ({unitsStateWithCheck: unitsStateWithCheck, unitGroups: unitGroups}))
        )
        .subscribe((res) => {
            if (res.unitGroups && res.unitsStateWithCheck?.unitsState) {
              this.uiSpinnerService.stop();
              this.loadingComplete = true;
              if (this.subscription) {
                this.subscription.unsubscribe();
              }
            }
          },
          error => {
            this.router.navigate([Tab.LOGIN]).then();
            if (this.subscription) {
              this.subscription.unsubscribe();
            }
            throw error;
          });
    } else {
      this.routeService.change(this.router.url);
      this.router.navigate([Tab.LOGIN]).then();
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }
  }

  private loadFooterButtons(): void {
    this.footerButtonsService.currentIsOpenSideNav$.subscribe(f => {
      this.opened = f;
    });
    this.viewBreakpointService.size$
      .pipe(take(2))
      .subscribe(size => {
        if (size.less('l')) {
          this.opened = false;
          this.footerButtonsService.changeIsOpenSideNav(false);
        }
      });
  }

  public updateMap(): void {
    setTimeout(() => {
      if (this.mapSelectUnitService.map) {
        this.mapSelectUnitService.map.updateSize();
      }
    }, 500);
  }

  ngOnDestroy(): void {
    this.chartDataService.destroy();
    this.geofenceListService.destroy();
    this.mapToolsService.destroy();
    this.notificationManagerService.destroy();
    this.unitFilterService.destroy();
    this.unitGroupsService.destroy();
    this.unitStateService.destroy();
    this.selectedService.destroy();
    this.trackLayerService.destroy();
    this.uiSpinnerService.stop();
  }
}
