import {HttpClient, HttpParams} from "@angular/common/http";
import {EnvironmentHelper} from "./environment-helper";
import {Injectable} from "@angular/core";
import {Observable, of} from "rxjs";
import {ReportSettings} from "../modelinterfaces/report-settings.model";
import {TranslateService} from "@ngx-translate/core";
import {HttpParamsUtil} from "../utils/http-params-util";
import {map} from "rxjs/operators";
import {isPublicView} from "../../../../user-portal/src/app/app.module";

@Injectable({
  providedIn: 'root'
})
export class ReportSettingsService {

  private readonly baseUrl;

  constructor(private http: HttpClient,
              private translateService: TranslateService,
              environmentHelper: EnvironmentHelper) {
    this.baseUrl = `${environmentHelper.urlV2}/reportSettings`;
  }

  public getReportSettingsByName(reportName: string): Observable<ReportSettings> {
    const url = `${this.baseUrl}/name/${reportName}`;
    const params = this.paramsLang();
    return this.http.get<ReportSettings>(url, {params})
      .pipe(map(v => ReportSettings.valueOf(v)));
  }

  public getAllReportSettings(): Observable<ReportSettings[]> {
    if (isPublicView()) {
      return of([]);
    }

    const params = this.paramsLang();
    return this.http.get<ReportSettings[]>(this.baseUrl, {params})
      .pipe(map(v => ReportSettings.valuesOf(v)));
  }

  public updateReportSettings(reportSettings: ReportSettings): Observable<ReportSettings> {
    const url = `${this.baseUrl}/${reportSettings.id}`;
    const params = this.paramsLang();
    return this.http.put<ReportSettings>(url, reportSettings, {params})
      .pipe(map(v => ReportSettings.valueOf(v)));
  }

  private paramsLang(): HttpParams {
    let currentLang = this.translateService.currentLang;
    return HttpParamsUtil.createByLang(currentLang);
  }
}
