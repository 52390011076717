import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MileageNormConsumption } from '../modelinterfaces/mileage-norm-consumption.model';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class MileageNormConsumptionService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getByUnitId(unitId: number): Observable<MileageNormConsumption> {
    const url = `${this.environmentHelper.urlV2}/mileageNormConsumption/unit/${unitId}`;
    return this.http.get<MileageNormConsumption>(url).pipe(
      map(obj => MileageNormConsumption.valueOf(obj))
    );
  }

  public update(unitId: number, norm: MileageNormConsumption): Observable<MileageNormConsumption> {
    const url = `${this.environmentHelper.urlV2}/mileageNormConsumption/unit/${unitId}`;
    return this.http.put<MileageNormConsumption>(url, norm).pipe(
      map(obj => MileageNormConsumption.valueOf(obj))
    );
  }
}
