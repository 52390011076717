import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subscription, timer} from 'rxjs';
import {Driver} from '../../../../../common-module/src/lib/modelinterfaces/driver.model';
import {UnitStateStorageService} from '../../../../../common-module/src/lib/services/unit-state-storage.service';
import {UnitStateStorage} from '../../../../../common-module/src/lib/modelinterfaces/unit-state-storage.model';
import {UnitState} from '../../../../../common-module/src/lib/modelinterfaces/unit-state.model';

export interface UnitsStateWithCheck {
  unitsState: UnitStateStorage;
  isNewData: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class UnitStateService {

  private readonly PERIOD_TIMER_MS = 20000;

  private _unitsStateWithCheckSource = new BehaviorSubject<UnitsStateWithCheck>(null);
  private _unitsStateWithCheck$ = this._unitsStateWithCheckSource.asObservable();

  private subscription: Subscription;

  constructor(private unitStateStorageService: UnitStateStorageService) {
  }

  get unitsStateWithCheck$(): Observable<UnitsStateWithCheck> {
    return this._unitsStateWithCheck$;
  }

  public init(): void {
    this.subscription = timer(0, this.PERIOD_TIMER_MS).subscribe(() => {
     this.getData();
    });
  }

  public getData(): void {
    this.unitStateStorageService.getData().subscribe(
      state => {
        this._unitsStateWithCheckSource.next({unitsState: state, isNewData: true});
      }
    );
  }

  public getInstant(): UnitStateStorage {
    return this._unitsStateWithCheckSource.getValue()?.unitsState;
  }

  public changeCurrentUnitState(unitId: number, state: UnitState): void {
    const unitsState = this.getInstant();
    unitsState.data.set(unitId, state);
    this._unitsStateWithCheckSource.next({unitsState: unitsState, isNewData: false});
  }

  public changeDriverInState(unitId: number, driver: Driver): void {
    const unitsState = this.getInstant();
    const state = unitsState.data.get(unitId);
    state.driver = driver;
    unitsState.data.set(unitId, state);
    this._unitsStateWithCheckSource.next({unitsState: unitsState, isNewData: false});
  }

  public destroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this._unitsStateWithCheckSource.next(null);
  }
}
