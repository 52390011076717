import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SensorCalculatorSource} from '../modelinterfaces/sensor-calculator-source.model';
import {HttpParamsUtil} from '../utils/http-params-util';
import {EnvironmentHelper} from './environment-helper';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})

export class SensorCalculatorService {

  constructor(private http: HttpClient,
              private environmentHelper: EnvironmentHelper,
              private translateService: TranslateService) {
  }

  public getByUnitId(unitId: number): Observable<SensorCalculatorSource> {
    const url = `${this.environmentHelper.urlV2}/sensorCalculatorFormSource/unit/${unitId}`;
    return this.http.get<SensorCalculatorSource>(url, {params: this.langParam()}).pipe(
      map(obj => SensorCalculatorSource.valueOf(obj))
    );
  }

  public getByTemplateSetId(templateSetId: number): Observable<SensorCalculatorSource> {
    const url = `${this.environmentHelper.urlV2}/sensorCalculatorFormSource/sensorTemplateSet/${templateSetId}`;
    return this.http.get<SensorCalculatorSource>(url, {params: this.langParam()}).pipe(
      map(obj => SensorCalculatorSource.valueOf(obj))
    );
  }

  private langParam(): HttpParams {
    let currentLang = this.translateService.currentLang;
    return HttpParamsUtil.createByLang(currentLang);
  }
}
