import { GeofenceType } from './enums/geofence-type';
import { GeofenceLight } from './geofence-light.model';
import { GpsCoordinate } from './gps-coordinate.model';
import { UserShort } from './user-short.model';

export class Geofence extends GeofenceLight {

  public isSelected = false;

  constructor(public id: number,
              public name: string,
              public type: GeofenceType,
              public coordinates: GpsCoordinate[],
              public description: string,
              public owner: UserShort,
              public maxSpeed: number,
              public color: string) {
    super(id, name);
  }

  public static valueOf(o: Geofence): Geofence {
    return o ? new Geofence(o.id, o.name, o.type, GpsCoordinate.valuesOf(o.coordinates), o.description, o.owner, o.maxSpeed,
      o.color) : null;
  }

  public static valuesOf(list: Geofence[]): Geofence[] {
    return list ? list.map(o => Geofence.valueOf(o)) : [];
  }
}
