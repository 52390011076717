import {getArea, getLength} from 'ol/sphere';
import {LineString, Polygon} from 'ol/geom';

export class MeasureUtils {

   public static formatLength(line: LineString): { value: number; uom: string } {
    const length = getLength(line);
    if (length > 100) {
      return {
        value: MeasureUtils.round(length, 1000, 2),
        uom: 'uom.km'
      };
    } else {
      return {
        value: MeasureUtils.round(length, 1, 2),
          uom: 'uom.m'
      };
    }
  }

  public static formatArea(polygon: Polygon): { value2: number; value1: number; uom1: string; uom2: string } {
    const area = getArea(polygon);
    switch (true) {
      case area > 1000000:
        return {
          value1: MeasureUtils.round(area, 1000000, 1),
          uom1: 'uom.km',
          value2: MeasureUtils.round(area, 10000, 0),
          uom2: 'uom.ha',
        };
      case area > 100000:
        return {
          value1: MeasureUtils.round(area, 1000000, 2),
          uom1: 'uom.km',
          value2: MeasureUtils.round(area, 10000, 1),
          uom2: 'uom.ha',
        };
      case area > 10000:
        return {
          value1: MeasureUtils.round(area, 1, 1),
          uom1: 'uom.m',
          value2: MeasureUtils.round(area, 10000, 2),
          uom2: 'uom.ha',
        };
      default:
        return {
          value1: MeasureUtils.round(area, 1, 2),
          uom1: 'uom.m',
          value2: MeasureUtils.round(area, 100, 1),
          uom2: 'uom.a',
        };
    }
  }

  private static round(numerator: number, denominator: number, rounding: number) {
    return Math.round(numerator / denominator * Math.pow(10, rounding)) / Math.pow(10, rounding);
  }
}
