import {GpsCoordinate} from "../../../../../common-module/src/lib/modelinterfaces/gps-coordinate.model";
import {Layer} from "ol/layer";
import RenderEvent from "ol/render/Event";
import VectorContext from "ol/render/VectorContext";
import {getVectorContext} from "ol/render";
import {FrameState} from "ol/PluggableMap";
import Point from "ol/geom/Point";
import {OlCoordinate} from "./OlCoordinate";
import {easeOut} from "ol/easing";
import {OlStyle} from "./OlStyle";
import {unByKey} from "ol/Observable";
import OlMap from "ol/Map";
import {CenterMapUtil, MapCenterOpt} from "./center-map.util";

export class OlAnimation {

  public static flash(map: OlMap, gpsCoordinate: GpsCoordinate, layer: Layer, zoom?: MapCenterOpt) {
    this.flashInternal(map, gpsCoordinate, layer, true, zoom);
  }

  /**
   * перед вызовом этой функции вызвать центрирование карты вручную, иначе не работает
   */
  public static flashWithoutCentering(map: OlMap, gpsCoordinate: GpsCoordinate | GpsCoordinate[], layer: Layer) {
    if (gpsCoordinate instanceof Array) {
      gpsCoordinate.forEach(c => this.flashInternal(map, c, layer, false))
    } else {
      this.flashInternal(map, gpsCoordinate, layer, false)
    }
  }

  /**
   * Center and show flash animation
   * @param map
   * @param gpsCoordinate
   * @param layer
   * @param doCenter
   * @param zoom
   */
  private static flashInternal(map: OlMap, gpsCoordinate: GpsCoordinate, layer: Layer, doCenter: boolean, zoom?: MapCenterOpt): void {
    if (doCenter) {
      CenterMapUtil.center(gpsCoordinate, map, zoom);
    }
    const start = new Date().getTime();
    const listenerKey = layer.on('postrender', (event: RenderEvent) => {
      const duration = 1500;
      const vectorContext: VectorContext = getVectorContext(event);
      const frameState: FrameState = event.frameState;
      const flashGeom = new Point(OlCoordinate.createFromGpsCoordinate(gpsCoordinate));
      const elapsed = frameState.time - start;
      const elapsedRatio = elapsed / duration;
      // radius will be 5 at start and 30 at end.
      const radius = easeOut(elapsedRatio) * 25 + 5;
      const opacity = easeOut(1 - elapsedRatio);

      vectorContext.setStyle(OlStyle.createForSelectedUnitAnimation(radius, opacity));
      vectorContext.drawGeometry(flashGeom);
      if (elapsed > duration) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        unByKey(listenerKey);
        return;
      }
      map.render();
    });
  }
}
