import OlMap from "ol/Map";
import {Draw} from "ol/interaction";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import GeometryType from "ol/geom/GeometryType";
import {Circle, Geometry, Polygon} from "ol/geom";
import {getArea} from "ol/sphere";
import {AppMessageService} from "../../../../../../../../common-module/src/lib/app-services/app-message.service";
import {BehaviorSubject, Observable} from "rxjs";
import {CenterMapUtil} from "../../../../../shared/map-utils/center-map.util";
import {LayerUtil} from "../../../../../shared/map-utils/layer.util";

export interface GeometryDrawOpts {
  maxArea?: number,
  doCenterOnDrawEnd?: boolean;
}

export class GeometryDrawMapHandler {
  private readonly DEFAULT_MAX_AREA = 1_500_000_000_000;
  private readonly DEFAULT_DO_CENTER_ON_DRAW_END = true;

  private readonly _maxArea: number;
  private readonly _doCenterOnDrawEnd: boolean;

  private _draw: Draw | null = null;
  private _drawVectorSource: VectorSource | null = null;
  private _drawVectorLayer: VectorLayer | null = null;

  private _geometry$ = new BehaviorSubject<Geometry>(null);

  constructor(private readonly _map: OlMap,
              private readonly appMessageService: AppMessageService,
              opts?: GeometryDrawOpts) {
    this._maxArea = opts?.maxArea ?? this.DEFAULT_MAX_AREA;
    this._doCenterOnDrawEnd = opts?.doCenterOnDrawEnd ?? this.DEFAULT_DO_CENTER_ON_DRAW_END;
  }

  public startDraw(geometryType: GeometryType): Observable<Geometry> {
    this.clear();
    this.prepareDraw(geometryType);
    this.setupAreaCheck(geometryType);
    return this.runDrawIteraction();
  }

  private prepareDraw(geometryType: GeometryType) {
    this._drawVectorSource = new VectorSource();
    this._drawVectorLayer = new VectorLayer({
      source: this._drawVectorSource,
    });
    this._map.addLayer(this._drawVectorLayer);

    this._draw = new Draw({
      source: this._drawVectorSource,
      type: geometryType,
    });
  }

  private setupAreaCheck(geometryType: GeometryType) {
    if (geometryType === 'Circle') {
      this.addCheckOfCircleArea();
    }

    if (geometryType === 'Polygon') {
      this.addCheckOfPolygonArea();
    }
  }

  private addCheckOfCircleArea() {
    let isModifying = false; // Флаг для предотвращения рекурсии

    this._draw.on('drawstart', (event) => {
      const sketch = event.feature;
      sketch.getGeometry().on('change', () => {
        if (isModifying) return;

        const circle = sketch.getGeometry() as Circle;
        const radius = circle.getRadius();
        const maxRadius = Math.sqrt(this._maxArea / Math.PI);

        if (radius > maxRadius) {
          isModifying = true; // Устанавливаем флаг, чтобы избежать рекурсии
          circle.setRadius(maxRadius); // Ограничиваем радиус
          isModifying = false; // Сбрасываем флаг после изменения
        }
      });
    });
  }

  private addCheckOfPolygonArea() {
    this._draw.on('drawstart', (event) => {
      const sketch = event.feature;
      sketch.getGeometry().on('change', () => {
        const polygon = sketch.getGeometry() as Polygon;
        const area = getArea(polygon);
        if (area > this._maxArea) {
          const coordinates = polygon.getCoordinates()[0];
          coordinates.pop();
          polygon.setCoordinates([coordinates]);
          this.appMessageService.openSnackBar('Достигнут максимальный размер фигуры')
        }
      });
    });
  }

  private runDrawIteraction(): Observable<Geometry> {
    this._draw.on('drawend', (event) => {
      if (this._draw) {
        this._map.removeInteraction(this._draw);
        this._draw = null;

        const geometry = event.feature.getGeometry();
        if (geometry) {
          this._geometry$.next(geometry);
          if (this._doCenterOnDrawEnd) {
            this.centerOnGeometry();
          }
        } else {
          this._geometry$.next(null);
        }
      }
    });
    this._map.addInteraction(this._draw);
    return this._geometry$.asObservable();
  }

  public centerOnGeometry() {
    if (this._geometry$.getValue()) {
      CenterMapUtil.center(this._geometry$.getValue().getExtent(), this._map, {padding:  {top: 150, right: 500, bottom: 170, left: 100}});
    }
  }

  public get geometry(): Geometry {
    return this._geometry$.getValue();
  }

  public show() {
    this._drawVectorLayer.setOpacity(1);
  }

  public hide() {
    this._drawVectorLayer.setOpacity(0);
  }

  public clear() {
    LayerUtil.remove(this._map, this._drawVectorLayer);
    if (this._draw) {
      this._map.removeInteraction(this._draw);
    }
    this._geometry$.next(null);
    this._draw = null;
    this._drawVectorSource = null;
    this._drawVectorLayer = null;
  }
}
