import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppCommonModule} from '../../../../../common-module/src/lib/app-common.module';
import {SideNavComponent} from './side-nav.component';
import {UserExpandableComponent} from './user-expandable/user-expandable.component';
import {UnitListComponent} from './unit-list/unit-list.component';
import {FilterUsersDialogComponent} from './filter-users-dialog/filter-users-dialog.component';
import {EditGroupDialogComponent} from './edit-group-dialog/edit-group-dialog.component';
import {PublicLinkDialogComponent} from './public-link-dialog/public-link-dialog.component';
import {SharedModule} from '../../shared/shared.module';
import { NotificationTableComponent } from './notification-table/notification-table.component';
import { UnitStatusIconComponent } from './unit-list/unit-status-icon/unit-status-icon.component';
import { ShortUnitStateInfoComponent } from './unit-list/short-unit-state-info/short-unit-state-info.component';
import { UnitListOptionComponent } from './unit-list/unit-list-option/unit-list-option.component';

@NgModule({
  declarations: [
    SideNavComponent,
    UserExpandableComponent,
    UnitListComponent,
    FilterUsersDialogComponent,
    EditGroupDialogComponent,
    PublicLinkDialogComponent,
    NotificationTableComponent,
    UnitStatusIconComponent,
    ShortUnitStateInfoComponent,
    UnitListOptionComponent
  ],
    imports: [
        CommonModule,
        AppCommonModule,
        SharedModule
    ],
  providers: [],
  bootstrap: [],
  exports: [
    SideNavComponent
  ]
})

export class SideNavModule {
}
