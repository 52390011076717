import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Orientation} from '../../constants/enums/orientation';
import {AppButtonModule} from "../../../../../../common-module/src/lib/buttons/app-button.module";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-print-button',
  standalone: true,
  imports: [
    AppButtonModule,
    MatMenuTrigger,
    MatMenu,
    MatIcon,
    MatMenuItem
  ],
  templateUrl: './print-button.component.html',
  styleUrls: ['./print-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintButtonComponent {

  @Input() showSpinner = false;

  @Output() printChange = new EventEmitter<Orientation>();

  public orientation = Orientation;

  constructor() {
  }

  public print(orientation: Orientation): void {
    this.showSpinner = true;
    this.printChange.emit(orientation);
  }
}
