import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TelegramChat } from '../modelinterfaces/telegram-chat.model';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class TelegramService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getList(): Observable<TelegramChat[]> {
    const url = `${this.environmentHelper.urlV2}/telegramChat`;
    return this.http.get<TelegramChat[]>(url).pipe(
      map(list => TelegramChat.valuesOf(list))
    );
  }

  public getById(chatId: number): Observable<TelegramChat> {
    const url = `${this.environmentHelper.urlV2}/telegramChat/${chatId}`;
    return this.http.get<TelegramChat>(url).pipe(
      map(obj => TelegramChat.valueOf(obj))
    );
  }

  public activateChat(chatId: number, isActivated: boolean): Observable<TelegramChat> {
    const url = `${this.environmentHelper.urlV2}/telegramChat/${chatId}`;
    return this.http.patch<TelegramChat>(url, {activated: isActivated}).pipe(
      map(obj => TelegramChat.valueOf(obj))
    );
  }

  public deleteChat(chatId: number): Observable<void> {
    const url = `${this.environmentHelper.urlV2}/telegramChat/${chatId}`;
    return this.http.delete<void>(url);
  }
}
