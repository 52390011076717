<div class="search">
  <mat-form-field (click)="$event.stopPropagation()" [subscriptSizing]="'dynamic'">
    <mat-label>{{ 'action.search' | translate }}</mat-label>
    <input matInput
           type="text"
           [value]="searchValue"
           (keyup)="onChangeSearch($event)">

    <mat-icon *ngIf="!searchValue" class="icon-search" matIconSuffix>search</mat-icon>
    <mat-icon *ngIf="searchValue" class="icon-clear" matIconSuffix (click)="clearSearch()">close</mat-icon>
  </mat-form-field>
</div>
