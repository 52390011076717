import {Draw} from 'ol/interaction';
import VectorSource from 'ol/source/Vector';
import GeometryType from 'ol/geom/GeometryType';
import {OlStyle} from './OlStyle';

export class OlDraw {

  public static buildMeasure(source: VectorSource, type: GeometryType): Draw {
    return new Draw({
      source: source,
      type: type,
      style: OlStyle.createForMeasure()
    });
  }
}
