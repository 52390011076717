import {
  GpsCoordinate
} from "../../../../../../../../../../../common-module/src/lib/modelinterfaces/gps-coordinate.model";
import Feature from "ol/Feature";
import {BehaviorSubject} from "rxjs";
import OlMap from "ol/Map";
import VectorLayer from "ol/layer/Vector";
import {CenterMapUtil} from "../../../../../../../../shared/map-utils/center-map.util";
import {OlFeature} from "../../../../../../../../shared/map-utils/OlFeature";
import {OlCoordinate} from "../../../../../../../../shared/map-utils/OlCoordinate";
import Modify from "ol/interaction/Modify";
import Collection from "ol/Collection";
import {AppColor} from "../../../../../../../../../../../common-module/src/lib/app-enums/app-color";

export enum JobPointType {
  PICKUP = 'PICKUP',
  PICKUP_EDITABLE = 'PICKUP_EDITABLE',
  PICKUP_NON_EDITABLE = 'PICKUP_NON_EDITABLE',
  DELIVERY_EDITABLE = 'DELIVERY_EDITABLE',
  DELIVERY_NON_EDITABLE = 'DELIVERY_NON_EDITABLE',
  DELIVERY='DELIVERY',
  WAREHOUSE = 'WAREHOUSE',
  JOB = 'JOB',
  HIDDEN = 'HIDDEN'
}

export class JobFeature {

  private NOT_DRAGGABLE_POINT_TYPE = new Set([
    JobPointType.WAREHOUSE,
    JobPointType.HIDDEN,
    JobPointType.DELIVERY_NON_EDITABLE,
    JobPointType.PICKUP_NON_EDITABLE
  ])

  private _coordinate: GpsCoordinate;
  private _circle: Feature;
  private _pointFeature: Feature;
  private _$coordinates: BehaviorSubject<GpsCoordinate> = new BehaviorSubject<GpsCoordinate>(null);
  private readonly _isDraggable: boolean;

  constructor(private map: OlMap,
              private layer: VectorLayer,
              private pointType: JobPointType,
              private disabled = false) {
    this._isDraggable = !disabled && !this.NOT_DRAGGABLE_POINT_TYPE.has(pointType);
  }

  /**
   * метод который принимает изменения координаты из вне
   */
  public setCoordinate(coordinate: GpsCoordinate, emit: boolean = false): void {
    this._coordinate = coordinate;
    if (emit) {
      this.fireCoordinatesChanges();
    }
    this.drawPointFeature();
  }

  public doCenter() {
    CenterMapUtil.center(this.coordinate, this.map, {zoom: 16})
  }

  /**
   * Отрисовывает точку а также обрабатывает callback изменение координаты точки
   */
  public drawPointFeature() {
    this.clearPointFeature();
    this._pointFeature = OlFeature.createLogisticFeature(this._coordinate, this.pointType, AppColor.PRIMARY)
    this.layer.getSource().addFeature(this._pointFeature);
    this._pointFeature.on("change", (evt) => {
      this._coordinate = OlCoordinate.transform(evt.target.getGeometry().getCoordinates());
      this.drawCircle();
    })
    this.drawCircle();
    if (this._isDraggable) {
      this.initDragFeature();
    }
  }

  public drawCircle() {
    // if (this.pointType === JobPointType.HIDDEN) {
    //   return;
    // }
    // this.removeFeature(this._circle)
    // this._circle = new Feature({
    //   geometry: new Circle(this.transformCoordinates(this._coordinate), 300),
    // });
    // this.layer.getSource().addFeature(this._circle)
  }

  private initDragFeature() {
    const dragInteraction = new Modify({
      features: new Collection([this._pointFeature]),
      style: null
    });
    dragInteraction.on("modifyend", evt => {
      this.fireCoordinatesChanges()
    })
    this.map.addInteraction(dragInteraction);
  }

  private fireCoordinatesChanges() {
    this._$coordinates.next(this._coordinate);
  }

  private clearPointFeature() {
    this.removeFeature(this._pointFeature);
    this._pointFeature = null;
  }

  private removeFeature(f: Feature) {
    if (f) {
      this.layer.getSource().removeFeature(f);
    }
  }

  get coordinate(): GpsCoordinate {
    return this._coordinate;
  }

  get pointFeature(): Feature {
    return this._pointFeature;
  }

  get $coordinates(): BehaviorSubject<GpsCoordinate> {
    return this._$coordinates;
  }
}




