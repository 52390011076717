import {RangeDayTime} from "../../../../../../../../../common-module/src/lib/modelinterfaces/range-day-time.model";
import {TimeCellI, TimeTable} from "./TimeTable";
import {HHMMSS} from "../../../../../../shared/utils/h-h-m-m-s-s";

/**
 * Abstract superclass
 */
abstract class TimeTableIterator {
  protected readonly TABLE: TimeTable;
  protected readonly MIN_TIME_INDEX: number;
  protected readonly MIN_DAY_INDEX: number;
  protected readonly MAX_TIME_INDEX: number;
  protected readonly MAX_DAY_INDEX: number;
  protected nextDayIndex: number;
  protected nextTimeIndex: number;

  protected constructor(table: TimeTable,
                        timeIndexA: number,
                        dayIndexA: number,
                        timeIndexB: number,
                        dayIndexB: number) {
    this.TABLE = table;
    this.MIN_TIME_INDEX = Math.min(timeIndexA, timeIndexB);
    this.MIN_DAY_INDEX = Math.min(dayIndexA, dayIndexB);
    this.MAX_TIME_INDEX = Math.max(timeIndexA, timeIndexB);
    this.MAX_DAY_INDEX = Math.max(dayIndexA, dayIndexB);
    this.nextDayIndex = this.MIN_DAY_INDEX;
    this.nextTimeIndex = this.MIN_TIME_INDEX;
  }

  /**
   * @return true if next element exists
   * @return false if next element not exists
   */
  public hasNext(): boolean {
    return this.nextDayIndex <= this.MAX_DAY_INDEX && this.nextTimeIndex <= this.MAX_TIME_INDEX;
  }

  /**
   * @return next element
   * @throws
   */
  next(): TimeCellI {
    let next = this.TABLE.rows[this.nextDayIndex][this.nextTimeIndex];
    if (this.nextTimeIndex >= this.MAX_TIME_INDEX) {
      this.nextDayIndex++;
      this.nextTimeIndex = this.resetNextTimeIndex();
    } else {
      this.nextTimeIndex++;
    }
    return next;
  }

  /**
   * how reset {@link TimeTableIterator#nextTimeIndex} when the end of the times array is reached
   */
  protected abstract resetNextTimeIndex(): number;

  protected static secondsToIndex(seconds: number, step: number) {
    return Math.floor(seconds / step);
  }

  protected static hmsToIndex(hms: string, step: number): number {
    let seconds = HHMMSS.toSeconds(hms);
    return this.secondsToIndex(seconds, step);
  }
}

/**
 * This iterator iterate table bounded by a rectangle
 *  - - - - - - - - - - - - - -
 *  - - - a $ $ $ $ $ - - - - -
 *  - - - $ $ $ $ $ $ - - - - -
 *  - - - $ $ $ $ $ b - - - - -
 *  - - - - - - - - - - - - - -
 */
export class TimeTableRectangleIterator extends TimeTableIterator {
  constructor(table: TimeTable, a: TimeCellI, b: TimeCellI) {
    super(
      table,
      TimeTableRectangleIterator.secondsToIndex(a.timeFrom, table.step),
      a.weekday,
      TimeTableRectangleIterator.secondsToIndex(b.timeFrom, table.step),
      b.weekday
    );
  }

  protected resetNextTimeIndex(): number {
    return this.MIN_TIME_INDEX;
  }
}

/**
 * This iterator iterate all cells between selected cells a and b
 *  - - - - - - - - - - - - - -
 *  - - - a $ $ $ $ $ $ $ $ $ $
 *  $ $ $ $ $ $ $ $ $ $ $ $ $ $
 *  $ $ $ $ $ $ $ $ b - - - - -
 *  - - - - - - - - - - - - - -
 */
export class TimeTableSerialIterator extends TimeTableIterator {

  constructor(table: TimeTable, range?: RangeDayTime) {
    super(
      table,
      range ? TimeTableIterator.hmsToIndex(range.from.time, table.step) : 0,
      range ? range.from.weekday - 1 : 0,
      range ? TimeTableIterator.hmsToIndex(range.to.time, table.step) : table.rows[0].length - 1,
      range ? range.to.weekday - 1 : table.rows.length - 1
    )
  }

  protected resetNextTimeIndex(): number {
    return 0;
  }
}
