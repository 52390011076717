import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import { UnitShort } from '../../../../../../common-module/src/lib/modelinterfaces/unit-short.model';
import {SelectedService} from '../../../shared/services/selected.service';
import {UserUnitGroups} from '../../../../../../common-module/src/lib/modelinterfaces/user-unit-groups.model';
import {UnitGroup} from '../../../../../../common-module/src/lib/modelinterfaces/unit-group.model';

@Component({
  selector: 'app-user-expandable',
  templateUrl: './user-expandable.component.html',
  styleUrls: ['./user-expandable.component.scss']
})

export class UserExpandableComponent implements OnChanges {

  @Input() filter: string;
  @Input() searchStr: string;
  @Input() selectedUnitIdListByUser: number[] = [];
  @Input() allUnitsFiltered: UnitShort[] = [];
  @Input() user: UserUnitGroups;

  public unitCount: number;
  public unitsByUserFiltered: UnitShort[] = [];
  public userGroupList: UnitGroup[] = [];

  constructor(private selectedService: SelectedService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['allUnitsFiltered'] && this.allUnitsFiltered) {
      const allUserUnits = this.user.shared.concat(this.user.owned);
      this.unitsByUserFiltered = this.filterUnits(allUserUnits);
      this.unitCount = this.unitsByUserFiltered.length;
    }
  }

  public filterUnits(units: UnitShort[]): UnitShort[] {
    return (this.allUnitsFiltered?.length > 0) ? units.filter(el => this.allUnitsFiltered.some(unit => unit.id === el.id)) : [];
  }

  public expand(): void {
    this.selectedService.appendSelectedUser(this.user.id);
  }

  public collapse(): void {
    this.selectedService.removeSelectedUser(this.user.id);
  }

  public getCountSelectedGroupUnits(units: UnitShort[]): string {
    let unitsCount = 0;
    const unitIdsSet = this.selectedService.getInstantUnitIdsSet();
    units.forEach(el => {
        if (unitIdsSet.has(el.id)) {
          unitsCount++;
        }
      });
    if (unitsCount > 0) {
      return unitsCount + ' / ';
    } else {
      return '';
    }
  }
}
