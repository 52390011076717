import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationState } from '../modelinterfaces/enums/notification-state';
import { NotificationFormConfig } from '../modelinterfaces/notification-form-config.model';
import { NotificationSource } from '../modelinterfaces/notification-source.model';
import { HttpParamsUtil } from '../utils/http-params-util';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class NotificationSourceService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getList(lang: string): Observable<NotificationSource[]> {
    const url = `${this.environmentHelper.urlV2}/notificationSource`;
    return this.http.get<NotificationSource[]>(url, {params: HttpParamsUtil.createByLang(lang)}).pipe(
      map(list => NotificationSource.valuesOf(list))
    );
  }

  public getUnitsByNotificationType(param: number | string): Observable<number[]> {
    const url = `${this.environmentHelper.urlV2}/notificationSourceHelper/unitIds`;

    let params = new HttpParams();
    if (typeof param === 'number') {
      params = params.set('notificationSourceId', param.toString());
    } else {
      params = params.set('notificationType', param);
    }
    return this.http.get<number[]>(url, {params: params});
  }

  public setUnitsAndGetFormConfig(type: string, unitIds: number[], lang: string): Observable<NotificationFormConfig> {
    const url = `${this.environmentHelper.urlV2}/notificationSourceHelper/notificationType/${type}/formConfig`;
    return this.http.post<NotificationFormConfig>(url, unitIds, {params: HttpParamsUtil.createByLang(lang)}).pipe(
      map(obj => NotificationFormConfig.valueOf(obj))
    );
  }

  public updateRule(lang: string, rule: NotificationSource): Observable<NotificationSource> {
    const url = `${this.environmentHelper.urlV2}/notificationSource/${rule.id}`;
    return this.http.put<NotificationSource>(url, rule, {params: HttpParamsUtil.createByLang(lang)}).pipe(
      map(obj => NotificationSource.valueOf(obj))
    );
  }

  public saveRule(lang: string, rule: NotificationSource): Observable<NotificationSource> {
    const url = `${this.environmentHelper.urlV2}/notificationSource`;
    return this.http.post<NotificationSource>(url, rule, {params: HttpParamsUtil.createByLang(lang)}).pipe(
      map(obj => NotificationSource.valueOf(obj))
    );
  }

  public changeRuleState(ruleId: number, state: NotificationState): Observable<NotificationSource> {
    const url = `${this.environmentHelper.urlV2}/notificationSource/${ruleId}/status`;
    return this.http.patch<NotificationSource>(url, {state: state}).pipe(
      map(obj => NotificationSource.valueOf(obj))
    );
  }

  public deleteRule(ruleId: number): Observable<void> {
    const url = `${this.environmentHelper.urlV2}/notificationSource/${ruleId}`;
    return this.http.delete<void>(url);
  }

  public isRuleExist(name: string): Observable<boolean> {
    const url = `${this.environmentHelper.urlV2}/notificationSource/existBy`;
    return this.http.get<boolean>(url, {params: HttpParamsUtil.createByName(name)});
  }

  public getRulesByDeletedGeofence(geofenceId: number, lang: string): Observable<NotificationSource[]> {
    const url = `${this.environmentHelper.urlV2}/notificationSource`;

    let params = new HttpParams();
    params = params.set('geofenceId', geofenceId.toString());
    params = params.set('lang', lang);

    return this.http.get<NotificationSource[]>(url, {params: params}).pipe(
      map(list => NotificationSource.valuesOf(list))
    );
  }

  public getRulesByChangedSensor(sensorId: number, lang: string): Observable<NotificationSource[]> {
    const url = `${this.environmentHelper.urlV2}/notificationSource`;

    let params = new HttpParams();
    params = params.set('sensorId', sensorId.toString());
    params = params.set('lang', lang);

    return this.http.get<NotificationSource[]>(url, {params: params}).pipe(
      map(list => NotificationSource.valuesOf(list))
    );
  }
}
