<div class="video-filters-container" *ngIf="lastSelectedUnit$ | async"
     [ngClass]="{more_m: (size$ | async).more('m'),
      column_le_l: (size$ | async).less('l') || isTrackOptions,
     'increase-width': isTrackOptions && (size$ | async).more('m')}">

  <app-date-range-picker [actionButtons]="(size$ | async).less('l') || isTrackOptions"
                         [dateTimeRange]="dateTimeRange"
                         [dayLimit]="isTrackOptions ? DAY_LIMIT : null"
                         [isClearButton]="!isTrackOptions"
                         [minDate]="minDate"
                         [maxDate]="maxDate"
                         [position]="'center'"
                         (dateTimeRangeChange)="changeDateTimeRange($event)">
  </app-date-range-picker>

  <div class="items" *ngIf="(size$ | async).more('m') && !isTrackOptions">
    <ng-container *ngFor="let filter of filterList">

      <cmn-common-filter [name]="filter.name | translate"
                         [viewValue]="selectionList.selectedOptions.selected.length? '' + selectionList.selectedOptions.selected.length : null"
                         (clear)="clearFilter(filter.param)"
                         (apply)="onSubmit()">
        <mat-selection-list #selectionList
                            (click)="$event.stopPropagation()"
                            (selectionChange)="onChangeFilterParam(selectionList.selectedOptions.selected, filter.param)"
                            [compareWith]="comparisonId">
          <mat-list-option *ngFor="let filterItem of (filter.list | async)"
                           [checkboxPosition]="'before'"
                           [value]="filter.keyItemParam ? filterItem[filter.keyItemParam] : filterItem"
                           [selected]="isSelected(filter.keyItemParam ? filterItem[filter.keyItemParam] : filterItem, filter.param)">
            <ng-container *ngTemplateOutlet="optionContext; context: {filter: filter, filterItem: filterItem}">
            </ng-container>
          </mat-list-option>
        </mat-selection-list>
      </cmn-common-filter>

    </ng-container>
  </div>

  <div class="items_le_l" *ngIf="(size$ | async).less('l') || isTrackOptions">
    <ng-container *ngFor="let filter of filterList">
      <header class="subheader">
        {{ filter.name | translate }}
      </header>

      <mat-selection-list #selectionList
                          (click)="$event.stopPropagation(); $event.preventDefault()"
                          (selectionChange)="onChangeFilterParam(selectionList.selectedOptions.selected, filter.param); onSubmit()"
                          [compareWith]="comparisonId">
        <mat-list-option
          *ngFor="let filterItem of (filter.list | async)"
          [checkboxPosition]="'before'"
          [value]="filter.keyItemParam ? filterItem[filter.keyItemParam] : filterItem"
          [selected]="isSelected(filter.keyItemParam ? filterItem[filter.keyItemParam] : filterItem, filter.param)">
          <ng-container *ngTemplateOutlet="optionContext; context: {filter: filter, filterItem: filterItem}">
          </ng-container>
        </mat-list-option>
      </mat-selection-list>
    </ng-container>
  </div>
</div>

<ng-template #optionContext let-filter="filter" let-filterItem="filterItem">
  <p matListItemTitle>
    <span *ngIf="asFilter(filter).keyItemName">{{ filterItem[asFilter(filter).keyItemName] }}</span>
    <span *ngIf="!asFilter(filter).keyItemName">{{ 'video.' + filterItem.toLowerCase() | translate }}</span>
  </p>
  <p matListItemLine *ngIf="asFilter(filter).name === 'video.cameras'">
    {{ filterItem.description }}
  </p>
</ng-template>
