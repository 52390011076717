import {Component, Input} from '@angular/core';
import {ViewBreakpointService} from '../../../../../../../../common-module/src/lib/app-services/view-breakpoint.service';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['../carousel-card-styles.component.scss']
})

export class InfoCardComponent {

 @Input() width: number;

  constructor(public viewBreakpointService: ViewBreakpointService) { }
}
