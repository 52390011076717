import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Action } from 'projects/user-portal/src/app/shared/constants/enums/action';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalStyleClass } from '../../../../../../../common-module/src/lib/app-enums/global-style-class.enum';
import { AppMessageService } from '../../../../../../../common-module/src/lib/app-services/app-message.service';
import { UiSpinnerService } from '../../../../../../../common-module/src/lib/app-services/ui-spinner.service';
import { ViewBreakpointService, ViewSize } from '../../../../../../../common-module/src/lib/app-services/view-breakpoint.service';
import { NotificationDetails } from '../../../../../../../common-module/src/lib/modelinterfaces/notification-details.model';

import { Notification } from '../../../../../../../common-module/src/lib/modelinterfaces/notification.model';
import { NotificationDetailsService } from '../../../../../../../common-module/src/lib/services/notification-details.service';
import { DurationConverter } from '../../../../../../../common-module/src/lib/utils/duration-converter';
import { AuthUserService } from '../../../../../../../common-module/src/lib/app-services/auth-user.service';
import { RuleDialogComponent } from '../rule-dialog/rule-dialog.component';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})

export class NotificationDialogComponent implements OnInit {

  public notificationDetails: NotificationDetails;
  public notificationDuration: string;
  public size$: Observable<ViewSize>;
  public timeZone$: Observable<string>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Notification,
              private appMessageService: AppMessageService,
              private authUserService: AuthUserService,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<NotificationDialogComponent>,
              private notificationDetailsService: NotificationDetailsService,
              private uiSpinnerService: UiSpinnerService,
              private translateService: TranslateService,
              private viewBreakpointService: ViewBreakpointService) {
  }

  ngOnInit(): void {
    this.size$ = this.viewBreakpointService.size$;
    this.timeZone$ = this.authUserService.currentUser$.pipe(
      map(user => user.getUserTimeZone())
    );
    this.notificationDuration = DurationConverter.stringDatetimeToStringDuration(
      [this.data.finishTime, this.data.startTime],
      this.translateService
    );
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public showDetailsInfo(): void {
    this.dialogRef.updateSize('90vw', '90vh');
    this.uiSpinnerService.show();
    this.notificationDetailsService.getDetails(this.data.id, this.translateService.currentLang)
      .subscribe(details => {
        this.notificationDetails = details;
        this.uiSpinnerService.stop();
      }
    );
  }

  public onOpenRuleDialog($event: Event): void {
    $event.stopPropagation();
    this.dialogRef.close({openRule: true});
    this.dialog.open(RuleDialogComponent, {
      panelClass: ['rule-dialog', GlobalStyleClass.DIALOG_SIZE_LIMIT],
      autoFocus: false,
      data: {
        action: Action.EDIT,
        notification: this.data
      }
    });
  }
}
