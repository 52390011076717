export class ChartSegment {

  constructor(public epochSeconds: number,
              public values: number[]) {
  }

  public static valueOf(o: ChartSegment): ChartSegment {
    return o ? new ChartSegment(o.epochSeconds, o.values) : null;
  }

  public static valuesOf(list: ChartSegment[]): ChartSegment[] {
    return list ? list.map(o => ChartSegment.valueOf(o)) : [];
  }
}
