import { GpsCoordinate } from './gps-coordinate.model';

export class SensorData {

  constructor(public time: string,
              public latitude: number,
              public longitude: number,
              public messageId: number,
              public sensorRecords: Map<number, number>) {
    this.sensorRecords = new Map();
    Object.keys(sensorRecords).forEach(key => {
      this.sensorRecords.set(Number(key), sensorRecords[key]);
    });
  }

  public static valueOf(i: SensorData): SensorData {
    return i ? new SensorData(i.time, i.latitude, i.longitude, i.messageId, i.sensorRecords) : null;
  }

  public static valuesOf(list: SensorData[]): SensorData[] {
    return list ? list.map(o => SensorData.valueOf(o)) : [];
  }

  public static createMapValuesOf(list: SensorData[]): Map<number, SensorData> {
    const sensorDataMap = new Map();
    list.forEach(el => sensorDataMap.set(el.messageId, el));
    return sensorDataMap;
  }

  public static searchExactlyOrNear(datetime: string, sensorDataList: SensorData[]): SensorData {
    let i = 0;
    let j = sensorDataList.length - 1;
    let k;
    while (i <= j) {
      k = Math.floor((i + j) / 2);
      if (datetime === sensorDataList[k].time) {
        return sensorDataList[k];
      } else {
        if (datetime < sensorDataList[k].time) {
          j = k - 1;
        } else {
          i = k + 1;
        }
      }
    }
    return sensorDataList[k];
  }

  public getGpsCoordinate(): GpsCoordinate {
    return new GpsCoordinate(this.latitude, this.longitude);
  }
}
