import { Component, Input } from '@angular/core';
import {MatTooltip} from "@angular/material/tooltip";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";

@Component({
  selector: 'app-helper-info',
  standalone: true,
  templateUrl: './helper-info.component.html',
  imports: [
    MatTooltip,
    MatIcon,
    MatIconButton
  ],
  styleUrls: ['./helper-info.component.scss']
})

export class HelperInfoComponent {

  @Input() info: string;
}
