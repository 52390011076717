import { Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { VehicleFields } from "../../../../../shared/constants/enums/vehicle-fields";
import { UnitAdditionalData } from '../../../../../../../../common-module/src/lib/modelinterfaces/unit-additional-data.model';
import { VehicleManagerService } from "../../../../../shared/services/vehicle-manager.service";


export interface VehicleDialogData {
  unitData: UnitAdditionalData;
  fields: VehicleFields;
}

@Component({
  selector: 'app-edit-vehicle-dialog',
  templateUrl: './edit-vehicle-dialog.component.html',
  styleUrls: ['../../../../../../../../common-module/src/lib/app-styles/dialog-common.scss']
})

export class EditVehicleDialogComponent {

  public form$: Observable<UntypedFormGroup> = this.vehicleManagerService.currentForms$.pipe(map(forms => forms[0]));

  constructor(@Inject(MAT_DIALOG_DATA) public data: VehicleDialogData,
              private dialogRef: MatDialogRef<EditVehicleDialogComponent>,
              private vehicleManagerService: VehicleManagerService) {
  }

  public onCancel(): void {
    this.vehicleManagerService.onCancelForms(1);
    this.dialogRef.close();
  }

  public onSubmit(): void {
    this.vehicleManagerService.onSubmitForms(1);
    this.dialogRef.close();
  }
}
