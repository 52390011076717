import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnitAdditionalData } from '../modelinterfaces/unit-additional-data.model';
import { HttpParamsUtil } from '../utils/http-params-util';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class UnitAdditionalDataService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getData(unitId: number, lang: string): Observable<UnitAdditionalData> {
    const url = `${this.environmentHelper.urlV2}/unitAdditionalData/unit/${unitId}`;
    return this.http.get<UnitAdditionalData>(url, {params: HttpParamsUtil.createByLang(lang)}).pipe(
      map(obj => UnitAdditionalData.valueOf(obj))
    );
  }
}
