import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {SystemComponent} from './system.component';
import {AppCommonModule} from '../../../../common-module/src/lib/app-common.module';
import {FooterComponent} from './footer/footer.component';
import {SharedModule} from '../shared/shared.module';
import {HeaderModule} from './header/header.module';
import {SideNavModule} from './side-nav/side-nav.module';
import {MapDialogModule} from './main/map-dialog/map-dialog.module';
import {UnitInfoCarouselDialogComponent} from "./footer/unit-info-carousel-dialog/unit-info-carousel-dialog.component";
import {UnitInfoCarouselModule} from "./main/unit-info-carousel/unit-info-carousel.module";
import {ToolbarDialogComponent} from "./footer/toolbar-dialog/toolbar-dialog.component";
import {ToolbarModule} from "./main/toolbar/toolbar.module";

@NgModule({
  declarations: [
    SystemComponent,
    FooterComponent,
    UnitInfoCarouselDialogComponent,
    ToolbarDialogComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    RouterModule,
    SharedModule,
    HeaderModule,
    SideNavModule,
    MapDialogModule,
    UnitInfoCarouselModule,
    ToolbarModule
  ],
  providers: [],
  bootstrap: [],
  exports: []
})

export class SystemModule {
}
