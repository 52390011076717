export class TelegramUser {

  constructor(public chatId: number,
              public firstName: string,
              public lastName: string,
              public username: string,
              public language: string,
              public id: number) {
  }

  public static valueOf(i: TelegramUser): TelegramUser {
    return i ? new TelegramUser(i.chatId, i.firstName, i.lastName, i.username, i.language, i.id) : null;
  }
}
