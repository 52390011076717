import { NotificationActionForm } from './notification-action-form.model';
import { NotificationSourceList } from './notification-source-list.model';
import { NotificationSourceValue } from './notification-source-value.model';

export class NotificationFormConfig {

  constructor(public params: (NotificationSourceValue | NotificationSourceList)[],
              public pendingSeconds: NotificationSourceValue,
              public actionForm: NotificationActionForm) {
  }

  public static valueOf(i: NotificationFormConfig): NotificationFormConfig {
    return i ? new NotificationFormConfig(NotificationFormConfig.makeParams(i.params),
      NotificationSourceValue.valueOf(i.pendingSeconds), NotificationActionForm.valueOf(i.actionForm)) : null;
  }

  private static makeParams(
    params: (NotificationSourceValue | NotificationSourceList)[]
  ): (NotificationSourceValue | NotificationSourceList)[] {
    return params.map(el => {
      if (el.hasOwnProperty('selectionType')) {
        return NotificationSourceList.valueOf(el as NotificationSourceList);
      }
      return NotificationSourceValue.valueOf(el as NotificationSourceValue);
    });
  }

  public getParamsValue(): NotificationSourceValue[] {
    return this.params.filter(el => el instanceof NotificationSourceValue) as NotificationSourceValue[];
  }

  public getParamsList(): NotificationSourceList[] {
    return this.params.filter(el => el instanceof NotificationSourceList) as NotificationSourceList[];
  }
}
