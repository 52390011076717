import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatButtonModule} from "@angular/material/button";
import {IconButtonBaseComponent} from "./icon/core/icon-button-base/icon-button-base.component";
import {IconButtonPrimaryComponent} from "./icon/styled/icon-button-primary/icon-button-primary.component";
import {IconButtonRedComponent} from "./icon/styled/icon-button-red/icon-button-red.component";
import {ButtonSpinnerComponent} from './common/button-spinner/button-spinner.component';
import {StyledAbstractIconButtonComponent} from './icon/core/icon-styled-button/styled-abstract-icon-button.component';
import {StyledAbstractButtonComponent} from './base/core/styled-abstract-button/styled-abstract-button.component';
import {ButtonCancelComponent} from "./base/concrete/button-cancel/button-cancel.component";
import {ButtonSaveComponent} from "./base/concrete/button-save/button-save.component";
import {ButtonPrimaryComponent} from "./base/style/button-primary/button-primary.component";
import {ButtonMinorComponent} from "./base/style/button-minor/button-minor.component";
import {ButtonBaseComponent} from "./base/core/button-base/button-base.component";
import {ButtonDeleteComponent} from "./base/concrete/button-delete/button-delete.component";
import {ButtonNextComponent} from "./base/concrete/button-next/button-next.component";
import {ButtonDownloadComponent} from "./base/concrete/button-download/button-download.component";
import {ButtonBackComponent} from "./base/concrete/button-back/button-back.component";
import {ButtonAddComponent} from './base/concrete/button-add/button-add.component';
import {MatDialogModule} from "@angular/material/dialog";
import {ButtonBuildComponent} from './base/concrete/button-build/button-build.component';
import {ButtonSendComponent} from './base/concrete/button-send/button-send.component';
import {ButtonRedComponent} from './base/style/app-button-red/button-red.component';
import {IconButtonRefreshComponent} from './icon/concrete/button-icon-refresh/icon-button-refresh.component';
import {ButtonGreenComponent} from './base/style/button-green/button-green.component';
import {ButtonEditComponent} from './base/concrete/button-edit/button-edit.component';
import {IconButtonGreenComponent} from "./icon/styled/icon-button-green/icon-button-green.component";
import {IconButtonOrangeComponent} from "./icon/styled/icon-button-orange/icon-button-orange.component";
import {IconButtonWhiteComponent} from "./icon/styled/icon-button-white/icon-button-white.component";
import {ButtonApplyComponent} from "./base/concrete/button-apply/button-apply.component";
import {
  IconTextButtonFixedWidthComponent
} from "./fixed-width/icon-text-button-fixed-length/icon-text-button-fixed-width.component";
import {IconButtonClipboardComponent} from "./icon/concrete/icon-button-clipboard/icon-button-clipboard.component";
import {IconButtonClearComponent} from "./icon/concrete/icon-button-clear/icon-button-clear.component";
import {IconButtonDeleteComponent} from "./icon/concrete/icon-button-delete/icon-button-delete.component";
import {IconButtonAddComponent} from "./icon/concrete/icon-button-add/icon-button-add.component";
import {IconButtonEditComponent} from "./icon/concrete/icon-button-edit/icon-button-edit.component";
import {IconButtonBackComponent} from "./icon/concrete/icon-button-back/icon-button-back.component";
import {IconButtonAttachComponent} from "./icon/concrete/icon-button-attach/icon-button-attach.component";
import {IconButtonTableComponent} from "./icon/concrete/icon-button-table/icon-button-table.component";
import {IconButtonAccentComponent} from "./icon/styled/icon-button-accent/icon-button-accent.component";
import {IconButtonDownloadComponent} from "./icon/concrete/icon-button-download/icon-button-download.component";
import {ButtonCloseComponent} from "./base/concrete/button-close/button-close.component";
import {IconButtonGrayComponent} from "./icon/styled/icon-button-gray/icon-button-gray.component";
import {ButtonResetComponent} from "./base/concrete/button-reset/button-reset.component";
import {IconButtonRevertComponent} from "./icon/concrete/icon-button-revert/icon-button-revert.component";
import {IconButtonShowHideComponent} from "./icon/concrete/icon-button-show-hide/icon-button-show-hide.component";
import {MatTooltip} from "@angular/material/tooltip";


@NgModule({
  declarations: [
    ButtonCancelComponent,
    ButtonSaveComponent,
    ButtonPrimaryComponent,
    ButtonMinorComponent,
    ButtonBaseComponent,
    ButtonDeleteComponent,
    ButtonNextComponent,
    ButtonDownloadComponent,
    ButtonBackComponent,
    IconButtonGreenComponent,
    IconButtonOrangeComponent,
    ButtonApplyComponent,
    ButtonCloseComponent,
    ButtonResetComponent,

    IconButtonBaseComponent,
    IconButtonAddComponent,
    IconButtonPrimaryComponent,
    IconButtonRedComponent,
    IconButtonClearComponent,
    IconButtonDeleteComponent,
    ButtonSpinnerComponent,
    StyledAbstractIconButtonComponent,
    StyledAbstractButtonComponent,
    IconButtonEditComponent,
    ButtonAddComponent,
    ButtonBuildComponent,
    ButtonSendComponent,
    ButtonRedComponent,
    IconButtonRefreshComponent,
    ButtonGreenComponent,
    IconButtonBackComponent,
    ButtonEditComponent,
    IconButtonWhiteComponent,
    IconButtonAttachComponent,
    IconButtonTableComponent,
    IconButtonClipboardComponent,
    IconButtonAccentComponent,
    IconButtonDownloadComponent,
    IconButtonRevertComponent,

    IconTextButtonFixedWidthComponent,
    IconButtonGrayComponent,

    IconButtonShowHideComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltip
  ],
  exports: [
    ButtonCancelComponent,
    ButtonSaveComponent,
    ButtonPrimaryComponent,
    ButtonMinorComponent,
    ButtonRedComponent,
    ButtonGreenComponent,
    ButtonDeleteComponent,
    ButtonNextComponent,
    ButtonDownloadComponent,
    ButtonBackComponent,
    ButtonAddComponent,
    ButtonBuildComponent,
    ButtonSendComponent,
    ButtonEditComponent,
    ButtonApplyComponent,
    ButtonCloseComponent,

    IconButtonAddComponent,
    IconButtonPrimaryComponent,
    IconButtonRedComponent,
    IconButtonClearComponent,
    IconButtonDeleteComponent,
    IconButtonEditComponent,
    IconButtonRefreshComponent,
    IconButtonBackComponent,
    IconButtonGreenComponent,
    IconButtonOrangeComponent,
    IconButtonWhiteComponent,
    IconButtonAttachComponent,
    IconButtonTableComponent,
    IconButtonClipboardComponent,
    IconButtonAccentComponent,
    IconButtonDownloadComponent,

    IconButtonGrayComponent,

    IconTextButtonFixedWidthComponent,
    IconButtonRevertComponent,
    IconTextButtonFixedWidthComponent,
    IconButtonShowHideComponent,
    ButtonResetComponent
  ]
})
export class AppButtonModule {
}
