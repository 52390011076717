import {ChangeDetectionStrategy, Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {BaseUnsubscribeComponent} from "../../base-unsubscribe.component";
import {takeUntil} from "rxjs/operators";

enum Control {
  HOURS = 'HOURS', MINUTES = 'MINUTES'
}

/**
 * Component to input a duration in hours and minutes, and emit the value in seconds.
 * This component implements the ControlValueAccessor interface, allowing it to be used within
 * Angular reactive forms. It also extends a BaseUnsubscribeComponent to handle unsubscribe logic.
 */
@Component({
  selector: 'app-duration-input',
  templateUrl: './duration-input.component.html',
  styleUrls: ['./duration-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DurationInputComponent),
      multi: true
    }
  ]
})
export class DurationInputComponent extends BaseUnsubscribeComponent implements OnInit, ControlValueAccessor {

  /**
   * Input property to set the label for the duration input component.
   */
  @Input() name;

  control = Control;
  form: UntypedFormGroup = new UntypedFormGroup({
    [Control.HOURS]: new UntypedFormControl(0),
    [Control.MINUTES]: new UntypedFormControl(0)
  });

  private onChange = (duration: number) => {
  };
  private onTouched = () => {
  };

  ngOnInit(): void {
    this.subscribeToChanges();
  }

  private subscribeToChanges(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy)).subscribe(() => {
      this.onChange(this.getActualSeconds());
    });
  }

  getActualSeconds(): number {
    const hours = this.form.get(Control.HOURS)?.value || 0;
    const minutes = this.form.get(Control.MINUTES)?.value || 0;
    return hours * 3600 + minutes * 60;
  }

  writeValue(totalSeconds: number): void {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    this.form.setValue({
      [Control.HOURS]: hours,
      [Control.MINUTES]: minutes
    }, {emitEvent: false});
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  stopClickPropagation($event: MouseEvent) {
    $event.stopPropagation();
  }

  setDefaultIfEmpty(controlName: Control) {
    const control = this.form.get(controlName);
    const value = control.value;
    if (control && (value === null || value === '')) {
      control.setValue(0);
    }
    control.setValue(Math.abs(value));
  }
}
