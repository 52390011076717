import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnitGroup } from '../modelinterfaces/unit-group.model';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class UnitGroupService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getList(userId: number): Observable<UnitGroup[]> {
    const url = `${this.environmentHelper.urlV2}/unitGroup/user/${userId}`;
    return this.http.get<UnitGroup[]>(url).pipe(
      map(list => UnitGroup.valuesOf(list))
    );
  }

  public save(userId: number, group: UnitGroup): Observable<UnitGroup> {
    const url = `${this.environmentHelper.urlV2}/unitGroup/user/${userId}`;
    return this.http.post<UnitGroup>(url, group).pipe(
      map(obj => UnitGroup.valueOf(obj))
    );
  }

  public update(group: UnitGroup): Observable<UnitGroup> {
    const url = `${this.environmentHelper.urlV2}/unitGroup/${group.id}`;
    return this.http.put<UnitGroup>(url, group).pipe(
      map(obj => UnitGroup.valueOf(obj))
    );
  }

  public delete(groupId: number): Observable<void> {
    const url = `${this.environmentHelper.urlV2}/unitGroup/${groupId}`;
    return this.http.delete<void>(url);
  }

  public changeName(groupId: number, name: string): Observable<UnitGroup> {
    const url = `${this.environmentHelper.urlV2}/unitGroup/${groupId}/name`;
    return this.http.patch<UnitGroup>(url, {name: name}).pipe(
      map(obj => UnitGroup.valueOf(obj))
    );
  }
}
