import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PageParam} from '../modelinterfaces/enums/page-param';
import {Page} from '../modelinterfaces/page.model';
import {ReportMeta} from '../modelinterfaces/report-meta.model';
import {ReportOptions} from '../modelinterfaces/report-options.model';
import {Report} from '../modelinterfaces/report.model';
import {HttpParamsUtil} from '../utils/http-params-util';
import {EnvironmentHelper} from './environment-helper';
import {TranslateService} from "@ngx-translate/core";
import {
  ReportSettingsStorageService
} from "../../../../user-portal/src/app/shared/services/report-settings-storage.service";

@Injectable({
  providedIn: 'root'
})

export class ReportService {

  constructor(private http: HttpClient,
              private environmentHelper: EnvironmentHelper,
              private translateService: TranslateService,
              private reportSettingsStorageService: ReportSettingsStorageService,
  ) {
  }

  public getList(reportName: string, grouping: string, startTime: string, finishTime: string,
                 unitIds: Set<number>, unite: boolean, useCache: boolean, geofenceIds: number[]): Observable<Report[]> {
    const unitIdsStr = Array.from(unitIds).join(',');
    const url = `${this.environmentHelper.urlV2}/report/${reportName}`;
    let params = new HttpParams()
      .set('grouping', grouping)
      .set('startTime', startTime)
      .set('finishTime', finishTime)
      .set('unitIds', unitIdsStr)
      .set('lang', this.translateService.currentLang)
      .set('displayColumns', this.reportSettingsStorageService.getDisplayColumns(reportName).join(","))
      .set('unite', unite.toString())
      .set('useCache', useCache.toString())
      .set('geofenceIds', geofenceIds.join(','));
    return this.http.get<Report[]>(url, {params: params}).pipe(
      map(list => Report.valuesOf(list))
    );
  }

  public getListOptions(lang: string): Observable<ReportOptions[]> {
    const url = `${this.environmentHelper.urlV2}/report/options`;
    return this.http.get<ReportOptions[]>(url, {params: HttpParamsUtil.createByLang(lang)}).pipe(
      map(list => ReportOptions.valuesOf(list))
    );
  }

  public delete(reportIds: number[]): Observable<void> {
    const url = `${this.environmentHelper.urlV2}/report/cache/reportTable`;
    let params = new HttpParams();
    params = params.set('reportIds', reportIds.join(','));
    return this.http.delete<void>(url, {params: params});
  }

  public download(reportIds: number[], type: string): Observable<HttpResponse<Blob>> {
    const url = `${this.environmentHelper.urlV2}/report/download`;
    let params = new HttpParams();
    params = params.set('reportIds', reportIds.join(','));
    params = params.set('fileType', type);
    return this.http.get<Blob>(url, {params: params, observe: 'response', responseType: 'blob' as 'json'});
  }

  public getAvailableReportMetaPages(page: number, size: number): Observable<Page<ReportMeta>> {
    const url = `${this.environmentHelper.urlV2}/report/cache/reportMeta/page`;

    let params = new HttpParams();
    params = params.set(PageParam.PAGE, String(page));
    params = params.set(PageParam.SIZE, String(size));

    return this.http.get<Page<ReportMeta>>(url, {params: params}).pipe(
      map(page => ReportMeta.pageableValueOf(page))
    );
  }

  public getReportFromCache(reportIds: number[]): Observable<Report[]> {
    const url = `${this.environmentHelper.urlV2}/report/cache/reportTable`;
    let params = new HttpParams();
    params = params.set('reportIds', reportIds.join(','));
    return this.http.get<Report[]>(url, {params: params}).pipe(
      map(list => Report.valuesOf(list))
    );
  }
}
