@if (!status) {
  <mat-icon class="opacity50">calendar_today</mat-icon>
} @else if (status === 'expired') {
  <mat-icon [style.color]="'red'" [matTooltip]="'message.info.expired'|translate:{days}">error</mat-icon>
} @else if (status === 'expiring') {
  <mat-icon [style.color]="'orange'" [matTooltip]="'message.info.expire-soon'|translate:{days}">warning</mat-icon>
} @else if (status === 'ok') {
  <mat-icon class="text-green" [matTooltip]="'message.info.left-days'|translate:{days}">verified</mat-icon>
}



