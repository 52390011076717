import { Component } from '@angular/core';
import {StyledAbstractIconButtonComponent} from "../../core/icon-styled-button/styled-abstract-icon-button.component";

@Component({
  selector: 'app-icon-button-gray',
  templateUrl: './icon-button-gray.component.html',
})
export class IconButtonGrayComponent extends StyledAbstractIconButtonComponent {

}

