import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Observable} from 'rxjs';
import OlMap from 'ol/Map';
import GeometryType from 'ol/geom/GeometryType';
import {MeasureManagerService, MeasureState} from './measure-manager.service';
import {MapTool} from '../../../../shared/constants/enums/map-tool';
import {MapToolsService} from '../../../../shared/services/map-tools.service';

@Component({
  selector: 'app-measure-manager',
  templateUrl: './measure-manager.component.html',
  styleUrls: ['./measure-manager.component.scss']
})

export class MeasureManagerComponent implements OnChanges, OnInit, OnDestroy {

  @Input() map: OlMap;
  @Input() currentMeasureTool: GeometryType;

  @Output() private changeCurrentMeasureTool = new EventEmitter<GeometryType>();

  public measureState = MeasureState;
  public measureState$: Observable<MeasureState | GeometryType>;

  constructor(private mapToolsService: MapToolsService,
              private measureManagerService: MeasureManagerService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentMeasureTool'] && this.currentMeasureTool) {
      this.measureManagerService.init(this.map);
      this.measureManagerService.changeState(this.currentMeasureTool);
    }
  }

  ngOnInit(): void {
    this.measureState$ = this.measureManagerService.state$();
  }

  public onChangeStateMeasure(state: MeasureState | GeometryType): void {
    this.measureManagerService.changeState(state);
  }

  public onCloseMeasuring(): void {
    this.mapToolsService.changeState(MapTool.DEFAULT);
  }

  ngOnDestroy(): void {
    this.measureManagerService.removeMeasures();
    this.changeCurrentMeasureTool.emit(GeometryType.LINE_STRING);
  }
}
