<app-dialog [title]="title">

  <div class="app-dialog-content-column app-font" appDialogContent>
    <div *ngIf="message; else content">
      <h2>{{ message }}</h2>
    </div>
    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>
  </div>


  <ng-container appDialogActions>
    <app-button-cancel (cancel)="cancel.emit()" mat-dialog-close>
    </app-button-cancel>

    <app-button-delete (delete)="delete.emit()"
                       [showSpinner]="showSpinner"
                       [disabled]="disableDelete">
    </app-button-delete>
  </ng-container>

</app-dialog>
