<mat-selection-list class="selection-list mat-typography"
                    [multiple]="multiple"
                    (selectionChange)="onSelectionChange()">
  <div class="options">
    <mat-list-option *ngFor="let sensor of _sensors"
                     [selected]="selectionModel.isSelected(sensor)"
                     (click)="onToggleSelection(sensor)">
      <app-sensor-last-value-item [sensorLastValue]="sensor">
      </app-sensor-last-value-item>
    </mat-list-option>
  </div>
</mat-selection-list>
