
<!-- ngx-daterangepicker-action если указан не во всех элементах то  датапикер не откроется -->
<button [matTooltip]="tooltip"
        [ngClass]="{'app-button primary': viewValue}"
        class="menu width-100-percent ngx-daterangepicker-action"
        mat-button
        (click)="click.emit()">

  <div class="flex flex-justify-space-between ngx-daterangepicker-action">

    <div *ngIf="name; else filterIcon" class="ngx-daterangepicker-action">{{ name }}</div>
    <ng-template #filterIcon>
      <mat-icon *ngIf="!viewValue" class="ngx-daterangepicker-action">filter_alt</mat-icon>
    </ng-template>

    <ng-container *ngIf="viewValue; else expandIcon">
      <span *ngIf="name" class="ngx-daterangepicker-action">: </span>
      <span class="view-value ngx-daterangepicker-action">{{ viewValue | truncate:viewValueTruncate }}</span>
    </ng-container>

    <ng-template #expandIcon>
      <mat-icon class="ngx-daterangepicker-action">expand_more</mat-icon>
    </ng-template>
  </div>

</button>
