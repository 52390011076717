import { Unit } from './unit.model';

export class BuildOptions {

  constructor(public unit: Unit,
              public startTime: string,
              public finishTime: string,
              public lastMessageUpdate: string,
              public messageExist: boolean) {
  }

  public static valueOf(i: BuildOptions): BuildOptions {
    return i ? new BuildOptions(Unit.valueOf(i.unit), i.startTime, i.finishTime, i.lastMessageUpdate, i.messageExist) : null;
  }
}
