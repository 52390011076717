import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { IMediaElement, VgApiService } from '@videogular/ngx-videogular/core';
import { Observable, ReplaySubject, Subscription, timer } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { GlobalStyleClass } from '../../../../../../../common-module/src/lib/app-enums/global-style-class.enum';
import { AppMessageService } from '../../../../../../../common-module/src/lib/app-services/app-message.service';
import { UiSpinnerService } from '../../../../../../../common-module/src/lib/app-services/ui-spinner.service';
import { DvrFile } from '../../../../../../../common-module/src/lib/modelinterfaces/dvr-file.model';
import { DvrFileStatus } from "../../../../../../../common-module/src/lib/modelinterfaces/enums/dvr-file-status";
import { DvrFileType } from '../../../../../../../common-module/src/lib/modelinterfaces/enums/dvr-file-type';
import { MapDetails } from '../../../../../../../common-module/src/lib/modelinterfaces/map-details.model';
import { DvrFileService } from "../../../../../../../common-module/src/lib/services/dvr-file.service";
import { MapDetailsService } from '../../../../../../../common-module/src/lib/services/map-details.service';
import { DateConverter } from '../../../../../../../common-module/src/lib/utils/date-converter';
import { AuthUserService } from '../../../../../../../common-module/src/lib/app-services/auth-user.service';
import { MapOnClickService } from "../../../../shared/services/map-on-click.service";
import { SelectedService } from '../../../../shared/services/selected.service';
import { VideoService } from '../../../../shared/services/video.service';
import { MapDialogComponent } from '../../map-dialog/map-dialog.component';
import {DayjsUtil} from "../../../../../../../common-module/src/lib/dayjs.util";

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})

export class VideoPlayerComponent implements OnInit, OnDestroy {

  private readonly POSITION_TIMER = 5000;
  private readonly UPDATE_FILE_STATUS_PERIOD = 10000;

  @Input() isSeparateWindow = false;

  @Output() changeCurrentTime: EventEmitter<string> = new EventEmitter();
  @Output() startFinishTime: EventEmitter<{ startTime: string, finishTime: string }> = new EventEmitter();

  public downloadUrl$: Observable<string>;
  public dvrFileStatus = DvrFileStatus;
  public dvrFileType = DvrFileType;
  public isFullScreen = false;
  public showUrl$: Observable<string>;
  public selectedCard$: Observable<DvrFile>;
  public timeZone$: Observable<string>;

  private dvrFileTime: string;
  private timerSubscription: Subscription;
  private vgApi: VgApiService;
  private destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private appMessageService: AppMessageService,
              private authUserService: AuthUserService,
              private dialog: MatDialog,
              private dvrFileService: DvrFileService,
              private mapDetailsService: MapDetailsService,
              private mapOnClickService: MapOnClickService,
              private selectedService: SelectedService,
              private translateService: TranslateService,
              private uiSpinnerService: UiSpinnerService,
              private videoService: VideoService) {
  }

  ngOnInit(): void {
    this.timeZone$ = this.authUserService.currentUser$.pipe(
      filter(value => !!value),
      map(user => user.getUserTimeZone())
    );
    this.downloadUrl$ = this.videoService.downloadUrl$;
    this.showUrl$ = this.videoService.showUrl$;
    this.selectedCard$ = this.videoService.selectedCard$.pipe(
      map(dvrFile => {
        this.unsubscribeTimerSubscription();
        if (dvrFile?.fileStatus === DvrFileStatus.DOWNLOADING) {
          this.getStatusFileByTimer(dvrFile);
        }
        this.dvrFileTime = dvrFile?.time;
        return dvrFile;
      })
    );
  }

  private unsubscribeTimerSubscription(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  public closeShowContent(): void {
    this.videoService.changeSelectedCard(null);
    this.videoService.openPlayerInSeparateWindow(false);
    this.mapOnClickService.cancelSelectedVideoPointFeature();
  }

  public requestFile(): void {
    const card = this.videoService.instantSelectedCard();
    this.dvrFileService.requestFileFromTracker(card.camera.id, card.name, this.translateService.currentLang).subscribe(
      file => {
        this.getStatusFileByTimer(file);
        this.videoService.changeSelectedCard(file);
      }
    );
  }

  private getStatusFileByTimer(card: DvrFile): void {
    this.timerSubscription = timer(this.UPDATE_FILE_STATUS_PERIOD, this.UPDATE_FILE_STATUS_PERIOD)
      .subscribe(() => {
        this.dvrFileService.getFileByNameAndCameraId(card.camera.id, card.name, this.translateService.currentLang).subscribe(
          file => {
            if (file.fileStatus === DvrFileStatus.READY) {
              this.videoService.changeSelectedCard(file);
              this.timerSubscription.unsubscribe();
            }
          }
        );
      });
  }

  public onPlayerReady(api: VgApiService): void {
    this.vgApi = api;
    if (this.isSeparateWindow) {
      this.changeCurrentTime.emit(null);
      this.vgApi.subscriptions.loadedMetadata.subscribe(
        () => {
          this.startFinishTime.emit({
            startTime: this.dvrFileTime,
            finishTime: this.calculateTime(this.dvrFileTime, this.vgApi.duration)
          });
        });
      timer(0, this.POSITION_TIMER)
        .pipe(takeUntil(this.destroy))
        .subscribe(() => {
          this.changeCurrentTime.emit(this.calculateTime(this.dvrFileTime, this.vgApi.currentTime));
        });
    }
  }

  private calculateTime(startTime: string, addingTime: number): string {
    return DayjsUtil.instant(startTime).add(addingTime, 'second').toISOString();
  }

  public onOpenTrack(): void {
    const currentTime = this.calculateTime(this.dvrFileTime, this.vgApi?.currentTime);
    const finishTime = this.calculateTime(this.dvrFileTime, this.vgApi?.duration);
    this.uiSpinnerService.show();
    this.mapDetailsService.getTrackEventCenter(
      this.selectedService.getInstantUnit().id,
      currentTime,
      null,
      this.dvrFileTime,
      finishTime
    )
      .subscribe(details => {
          this.uiSpinnerService.stop();
          this.openMapDialog(details, currentTime);
        }
      );
  }

  private openMapDialog(mapDetails: MapDetails, currentTimeString: string): void {
    const row: object = {};
    row['time'] = DateConverter.utcDateTimeToTzDateString(currentTimeString, this.authUserService.getInstantTimeZone());
    const tableColumnNames: string[] = ['time'];
    const title = {time: this.translateService.instant('term.time')};
    if (!mapDetails.points[0]) {
      this.appMessageService.openSnackBar('message.error.no-data', true);
      return;
    }
    if (mapDetails.points[0].params.hasOwnProperty('time')) {
      title['point-time'] = this.translateService.instant('term.point-time');
      tableColumnNames.push('point-time');
      row['point-time'] = DateConverter.utcDateTimeToTzDateString(
        mapDetails.points[0].params['time'],
        this.authUserService.getInstantTimeZone()
      );
    }
    this.dialog.open(MapDialogComponent, {
      panelClass: ['map-dialog', GlobalStyleClass.DIALOG_SIZE_LIMIT],
      data: {
        unitName: this.selectedService.getInstantUnit().name,
        track: mapDetails.track,
        showStartFinishMarkers: false,
        showParkingMarkers: false,
        point: mapDetails.points[0].gpsCoordinate,
        titleData: title,
        tableData: [row],
        displayedColumns: tableColumnNames
      }
    });
  }

  public asMedia(value: any): IMediaElement {
    return value as IMediaElement;
  }

  public toggleFullScreen(element: HTMLDivElement): void {
    this.isFullScreen = !this.isFullScreen;
    if (!this.isFullScreen) {
      document.exitFullscreen().then();
      return;
    }
    if (element.requestFullscreen) {
      element.requestFullscreen().then();
      return;
    }
    // @ts-ignore
    if (element.webkitRequestFullscreen) {
      // @ts-ignore
      element.webkitRequestFullscreen().then();
    }
  }

  ngOnDestroy(): void {
    if (this.isSeparateWindow) {
      this.changeCurrentTime.emit(null);
      this.startFinishTime.emit(null);
    }
    this.unsubscribeTimerSubscription();
    this.destroy.next(null);
    this.destroy.complete();
  }
}
