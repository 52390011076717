import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthRequest } from '../modelinterfaces/auth-request.model';
import { AuthResponse } from '../modelinterfaces/auth-response.model';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class JwtTokenService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getBearerToken(username: string, password: string, checkIsAdmin: boolean = false): Observable<AuthResponse> {
    const url = `${this.environmentHelper.urlV2}/token`;
    let params = new HttpParams();
    params = params.set('checkIsAdmin', String(checkIsAdmin));
    return this.http.post<AuthResponse>(url, new AuthRequest(username, password), {params: params}).pipe(
      map(obj => AuthResponse.valueOf(obj))
    );
  }

  public refreshBearerToken(): Observable<AuthResponse> {
    const url = `${this.environmentHelper.urlV2}/token/refresh`;
    return this.http.get<AuthResponse>(url).pipe(
      map(obj => AuthResponse.valueOf(obj))
    );
  }

  public getAdminBearerToken(userId: number): Observable<AuthResponse> {
    const url = `${this.environmentHelper.urlV2}/token/admin/${userId}`;
    return this.http.get<AuthResponse>(url).pipe(
      map(obj => AuthResponse.valueOf(obj))
    );
  }
}
