import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Geofence } from '../../../../../../../../common-module/src/lib/modelinterfaces/geofence.model';
import { NotificationSource } from '../../../../../../../../common-module/src/lib/modelinterfaces/notification-source.model';

@Component({
  selector: 'app-delete-geofence-dialog',
  templateUrl: './delete-geofence-dialog.component.html',
  styleUrls: ['./delete-geofence-dialog.component.scss', '../../../../../../../../common-module/src/lib/app-styles/dialog-common.scss']
})

export class DeleteGeofenceDialogComponent {

  public disabled = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { geofence: Geofence; ruleList: NotificationSource[] },
              private dialogRef: MatDialogRef<DeleteGeofenceDialogComponent>) {
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onSubmit(geofence: Geofence): void {
    this.dialogRef.close(geofence);
  }
}
