import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { AppColor } from "../../../../../../../common-module/src/lib/app-enums/app-color";
import { LengthErrorI } from "../../../../../../../common-module/src/lib/app-interfaces/length-error-i";
import { AppMessageService } from "../../../../../../../common-module/src/lib/app-services/app-message.service";
import { UiSpinnerService } from "../../../../../../../common-module/src/lib/app-services/ui-spinner.service";
import { MaxLength } from "../../../../../../../common-module/src/lib/modelinterfaces/enums/max-length";
import { UnitShort } from '../../../../../../../common-module/src/lib/modelinterfaces/unit-short.model';
import { Unit } from '../../../../../../../common-module/src/lib/modelinterfaces/unit.model';
import { UnitService } from "../../../../../../../common-module/src/lib/services/unit.service";
import { AppRegexp } from "../../../../../../../common-module/src/lib/utils/app-regexp";
import { asyncIsExist } from "../../../../../../../common-module/src/lib/validators/AppValidators";
import { UrlImage } from "../../../../shared/constants/url-image";
import { SelectedService } from "../../../../shared/services/selected.service";
import { UnitGroupsService } from "../../../../shared/services/unit-groups.service";
import { VehicleManagerService } from "../../../../shared/services/vehicle-manager.service";

enum Control {
  COLOR = 'color',
  NAME = 'name'
}

@Component({
  selector: 'app-unit-view-form',
  templateUrl: './unit-view-form.component.html',
  styleUrls: ['./unit-view-form.component.scss', '../../../../../../../common-module/src/lib/app-styles/dialog-common.scss']
})

export class UnitViewFormComponent implements OnChanges, OnInit, OnDestroy {

  public readonly UNIT_MOVING = UrlImage.UNIT_MOVING;

  @Input() unit: Unit | UnitShort;

  public collectionColors: Array<AppColor> = [AppColor.PRIMARY, AppColor.YELLOW, AppColor.GREEN, AppColor.BLUE, AppColor.RED, AppColor.GRAY,
    AppColor.AQUA, AppColor.ORANGE, AppColor.PURPLE, AppColor.BLACK, AppColor.MAUVE, AppColor.BROWN,
    AppColor.ACCENT, AppColor.LIGHT_GREY, AppColor.TRACK_GREEN
  ];
  public control = Control;
  public editForm: UntypedFormGroup;
  public iconColor: AppColor;

  private destroyed = new Subject<boolean>();

  constructor(private appMessageService: AppMessageService,
              private selectedService: SelectedService,
              private uiSpinnerService: UiSpinnerService,
              private unitGroupsService: UnitGroupsService,
              private unitService: UnitService,
              private vehicleManagerService: VehicleManagerService) {
  }

  get formControl(): { [p: string]: AbstractControl } {
    return this.editForm.controls;
  }

  get formValue(): any {
    return this.editForm.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['unit'] && this.unit) {
      this.buildForm();
    }
  }

  ngOnInit(): void {
    this.vehicleManagerService.isCancelForms$
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        isCancel => {
          if (isCancel && this.vehicleManagerService.instantTabSelectedIndex() === 0) {
            this.buildForm();
          }
        }
      )

    this.vehicleManagerService.isSubmitForms$
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        isSubmit => {
          if (isSubmit && this.vehicleManagerService.instantTabSelectedIndex() === 0) {
            this.onSubmit();
          }
        }
      )
  }

  private buildForm(): void {
    this.editForm = new UntypedFormGroup({
      [Control.COLOR]: new UntypedFormControl(this.unit.color),
      [Control.NAME]: new UntypedFormControl(this.unit.name,
        [Validators.pattern(AppRegexp.TEXT), Validators.maxLength(MaxLength.NAME), Validators.required],
        asyncIsExist(this.unitService.isExistName.bind(this.unitService), this.unit.name)
      )
    });
    this.editForm.valueChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.vehicleManagerService.changeForms([this.editForm]);
      });
    this.editForm.statusChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.vehicleManagerService.changeForms([this.editForm]);
      });
    this.vehicleManagerService.changeForms([this.editForm]);
  }

  public onChangeColor(color: AppColor): void {
    this.iconColor = color;
  }

  public onSubmit(): void {
    if (this.formControl[Control.COLOR].dirty) {
      this.updateColor();
    } else {
      this.updateUnitName();
    }
  }

  private updateColor(): void {
    this.uiSpinnerService.show();
    this.unitService.changeColor(this.unit.id, this.formValue[Control.COLOR]).subscribe(
      unit => {
        this.uiSpinnerService.stop();
        if (this.formControl[Control.NAME].dirty) {
          this.updateUnitName();
        } else {
          this.resultHandling(unit);
        }
      }
    );
  }

  private updateUnitName(): void {
    this.uiSpinnerService.show();
    this.unitService.changeName(this.unit.id, this.formValue[Control.NAME]).subscribe(
      unit => {
        this.uiSpinnerService.stop();
        this.resultHandling(unit);
      }
    );
  }

  private resultHandling(unit: Unit): void {
    this.appMessageService.openSnackBar('message.info.changes-saved');
    this.unitGroupsService.init();
    this.selectedService.changeLastSelectedUnit(unit);
  }

  public asMaxlength(maxlength: LengthErrorI): LengthErrorI {
    return maxlength;
  }

  ngOnDestroy(): void {
    this.destroyed.next(null);
    this.destroyed.complete();
  }
}
