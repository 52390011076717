import {Address} from "./address.model";
import {Accuracy} from "./geolocated-address.model";

export class AccuracyAddress extends Address {

  constructor(latitude: number, longitude: number, name: string, public accuracy: Accuracy) {
    super(latitude, longitude, name);
  }

  public static valueOf(i: AccuracyAddress): AccuracyAddress {
    return new AccuracyAddress(i.latitude, i.longitude, i.name, i.accuracy);
  }

  public static valuesOf(list: AccuracyAddress[]): AccuracyAddress[] {
    return list.map(a => this.valueOf(a));
  }
}
