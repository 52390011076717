import {Logistic} from "../../../../../common-module/src/lib/modelinterfaces/logistic.model";
import {OlFeature} from "../../shared/map-utils/OlFeature";
import {JobPointType} from "./logistic/logistic-order-list/job-dialog/impl/abstract-job-dialog/map-manager/job-feature";
import {OlLineString} from "../../shared/map-utils/OlLineString";
import {OlCoordinate} from "../../shared/map-utils/OlCoordinate";
import {AppColor} from "../../../../../common-module/src/lib/app-enums/app-color";
import VectorLayer from "ol/layer/Vector";
import {Feature} from "ol";
import {OlDescriptionOverlay} from "../../shared/map-utils/OlDescriptionOverlay";
import OlMap from "ol/Map";
import {OlVectorLayer} from "../../shared/map-utils/OlVectorLayer";
import {LayerName} from "../../shared/constants/enums/layer-name";
import {OlFeatureProps} from "../../shared/map-utils/OlFeatureProps";

export class JobMapUtils {

  public static createLayer(map: OlMap, jobs: Logistic.Job[], orderArrow: Map<string, Feature>, color: AppColor): VectorLayer {
    let layer = OlVectorLayer.createLayerOnMap(map, LayerName.JOBS);
    this.show(jobs, layer, orderArrow, color);
    return layer;
  }

  private static show(jobs: Logistic.Job[], layer: VectorLayer, orderArrow: Map<string, Feature>, color: AppColor) {
    for (const job of jobs) {
      if (!job.isShipment()) {
        const feature = OlFeature.createLogisticFeature(job.delivery.getLocation().coordinate, JobPointType.JOB, color);
        OlDescriptionOverlay.setDescription(feature, job.order.name);
        OlFeatureProps.set(feature, job);
        layer.getSource().addFeature(feature);
      }
      if (job.isShipment() && job.delivery.isStepAddress()) {
        const feature = OlFeature.createLogisticFeature(job.delivery.getLocation().coordinate, JobPointType.DELIVERY_EDITABLE, color);
        OlDescriptionOverlay.setDescription(feature, job.order.name);
        OlFeatureProps.set(feature, job);
        layer.getSource().addFeature(feature);
      }
      if (job.isShipment() && job.toShipment().pickup.isStepAddress()) {
        const feature = OlFeature.createLogisticFeature(job.toShipment().pickup.getLocation().coordinate, JobPointType.PICKUP_EDITABLE, color);
        OlDescriptionOverlay.setDescription(feature, job.order.name);
        OlFeatureProps.set(feature, job);
        layer.getSource().addFeature(feature);
      }
      if (job.isShipment()) {
        let lineString = OlLineString.build([
          OlCoordinate.createFromGpsCoordinate(job.toShipment().pickup.getLocation().coordinate),
          OlCoordinate.createFromGpsCoordinate(job.delivery.getLocation().coordinate)
        ]);
        const arrow = OlFeature.createArrowLine(lineString, color);

        arrow.forEach(f => {
          if (f.get('type') === 'route') {
            OlDescriptionOverlay.setDescription(f, job.order.name);
            orderArrow.set(job.order.name, f);
            OlFeatureProps.set(f, job);
          }
        });
        layer.getSource().addFeatures(arrow);
      }
    }
  }
}
