import { Injectable } from '@angular/core';
import { Extent } from 'ol/extent';
import { Layer } from 'ol/layer';
import TileLayer from 'ol/layer/Tile';
import OlMap from 'ol/Map';
import * as olProj from 'ol/proj';
import { register } from 'ol/proj/proj4';
import OSMSource from 'ol/source/OSM';
import XYZ from 'ol/source/XYZ';
import * as olTilegrid from 'ol/tilegrid';
import proj4 from 'proj4';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageKey } from '../../../../../common-module/src/lib/app-enums/local-storage-key';
import { LayerName } from '../constants/enums/layer-name';
import { TileName } from '../constants/enums/tile-name';
import { WeatherElement } from '../constants/enums/weather-element';
import { ZIndexLayer } from '../constants/z-index';
import { LayerUtil } from '../map-utils/layer.util';

export interface MapDescription {
  fullName: string;
  url: string;
}

@Injectable({providedIn: 'root'})

export class TileLayerService {

  private readonly descriptionMap: Map<TileName, MapDescription> = new Map([
    [TileName.OSM, {fullName: 'OpenStreetMap', url: 'https://www.openstreetmap.org/copyright'}],
    // [TileName.OSM_FR, {fullName: 'Humanitarian OSM Team', url: 'https://www.hotosm.org/'}],
    [TileName.OSM_MAPIA, {fullName: 'Wikimapia', url: 'http://wikimapia.org/terms_reference.html'}],
    [TileName.YA, {fullName: 'Yandex', url: 'https://yandex.com/legal/maps_termsofuse/'}],
    [TileName.YA_SATS, {fullName: 'Yandex-Satellite', url: 'https://yandex.com/legal/maps_termsofuse/'}],
    [TileName.YA_HYBRID, {fullName: 'Yandex-Hybrid', url: 'https://yandex.com/legal/maps_termsofuse/'}],
    // [TileName.YA_TRAFFIC, {fullName: 'Yandex-Traffic', url: 'https://yandex.com/legal/maps_termsofuse/'}],
    [TileName.OSM_SEA, {fullName: 'OpenStreetMap', url: 'https://www.openstreetmap.org/copyright'}],
    // [TileName.HERE, {fullName: 'HERE', url: 'https://developer.here.com/'}],
  ]);
  private readonly MAP_HERE_API_KEY = 'mRNScay4vnWx8KpqVOfqnXVNicGBN81xl_ApA3qhKhc';
  private readonly WEATHER_API_KEY = 'e608f344aed949dcb6f05b4092a6a22b';
  private readonly WEATHER_ELEMENT_API_MAP = new Map<WeatherElement, string>([
    [WeatherElement.TEMPERATURE, 'temp_new'],
    [WeatherElement.PRECIPITATION, 'precipitation_new'],
    [WeatherElement.WIND, 'wind_new'],
    [WeatherElement.PRESSURE, 'pressure_new'],
    [WeatherElement.CLOUDY, 'clouds_new']
  ]);
  private readonly YANDEX_EXTENDS: Extent = [-20037508.342789244, -20037508.342789244, 20037508.342789244, 20037508.342789244];

  private currentTileNameSource = new BehaviorSubject<TileName>(this.getLastSelected());
  public currentTileName$: Observable<TileName> = this.currentTileNameSource.asObservable();

  private currentWeatherElementSource = new BehaviorSubject<WeatherElement>(null);
  public currentWeatherElement$: Observable<WeatherElement> = this.currentWeatherElementSource.asObservable();

  private isFirstTileChange = true;

  constructor() {
  }

  public init(): void {
    proj4.defs('EPSG:3395', '+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs');
    register(proj4);
    olProj.get('EPSG:3395').setExtent(this.YANDEX_EXTENDS);
  }

  public changeLayer(tileName: TileName, map: OlMap): void {
    if (!map) {
      return;
    }
    this.setTile(tileName, map);
    this.isFirstTileChange = false;
  }

  private setTile(tileName: TileName, map: OlMap) {
    window.localStorage.setItem(LocalStorageKey.TILE_SELECTED, tileName);
    this.currentTileNameSource.next(tileName);
    LayerUtil.clear(map, LayerName.TILE);
    LayerUtil.clear(map, LayerName.WEATHER);
    this.setLayersOnMap(this.getTileLayer(tileName), map);
    if (this.currentWeatherElementSource.getValue()) {
      this.setLayersOnMap(this.buildWeather(this.currentWeatherElementSource.getValue()), map);
    }
  }

  private setLayersOnMap(layers: Layer[], map: OlMap): void {
    layers.forEach(l => map.addLayer(l));
    map.updateSize();
  }

  public changeWeatherElement(element: WeatherElement, map: OlMap): void {
    if (!map) {
      return;
    }
    this.currentWeatherElementSource.next(element);
    LayerUtil.clear(map, LayerName.WEATHER);
    if (element) {
      this.setLayersOnMap(this.buildWeather(element), map);
    }
  }

  /** return NEW tiles */
  public buildCurrentSelectedTile(): Layer[] {
    return this.getTileLayer(this.getLastSelected());
  }

  /** return last selected tile name */
  public getLastSelected(): TileName {
    const name = window.localStorage.getItem(LocalStorageKey.TILE_SELECTED) as TileName;
    return name ? name : TileName.OSM;
  }

  /** return description by tile name */
  public description(name: TileName): MapDescription {
    return this.descriptionMap.get(name);
  }

  private buildOSM(): Layer[] {
    const t = new TileLayer({
      source: new OSMSource(),
      preload: 20
    });
    t.set('name', LayerName.TILE);
    t.setZIndex(ZIndexLayer.ZERO);
    return [t];
  }

  private buildOSMFR(): Layer[] {
    const t = new TileLayer({
      source: new OSMSource({
        url: 'http://tile-{a-c}.openstreetmap.fr/hot/{z}/{x}/{y}.png',
      }),
      preload: 20
    });
    t.set('name', LayerName.TILE);
    t.setZIndex(ZIndexLayer.ZERO);
    return [t];
  }

  private buildWikiMapia(): Layer[] {
    const t = new TileLayer({
      source: new XYZ({
        tileUrlFunction: (p0) => {
          const x = p0[1];
          const y = p0[2];
          const z = p0[0];
          const c = x % 4 + (y % 4) * 4;

          const url = 'http://i{c}.wikimapia.org/?zoom={z}&x={x}&y={y}&lng=1';
          return url
            .replace('{c}', '' + c)
            .replace('{z}', '' + z)
            .replace('{x}', '' + x)
            .replace('{y}', '' + y);
        }
      }),
      preload: 20
    });
    t.set('name', LayerName.TILE);
    t.setZIndex(ZIndexLayer.ZERO);
    return [t];
  }

  private buildYandex(): Layer[] {
    const t = new TileLayer({
      source: new XYZ({
        url: 'https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=21.07.12-0-b210701140430&x={x}&y={y}&z={z}&scale=1',
        projection: 'EPSG:3395',
        tileGrid: olTilegrid.createXYZ({
          extent: this.YANDEX_EXTENDS
        }),
        crossOrigin: 'Anonymous'
      })
    });
    t.set('name', LayerName.TILE);
    t.setZIndex(ZIndexLayer.ZERO);
    return [t];
  }

  private buildYandexSats(): Layer[] {
    const t = new TileLayer({
      source: new XYZ({
        url: 'https://sat0{1-4}.maps.yandex.net/tiles?l=sat&x={x}&y={y}&z={z}',
        projection: 'EPSG:3395',
        tileGrid: olTilegrid.createXYZ({
          extent: this.YANDEX_EXTENDS
        }),
        crossOrigin: 'Anonymous',
      })
    });
    t.set('name', LayerName.TILE);
    t.setZIndex(ZIndexLayer.ZERO);
    return [t];
  }

  private buildYandexHybrid(): Layer[] {
    const t = new TileLayer({
      source: new XYZ({
        url: 'https://core-renderer-tiles.maps.yandex.net/tiles?l=skl&v=21.07.12-0-b210701140430&x={x}&y={y}&z={z}&scale=1',
        projection: 'EPSG:3395',
        tileGrid: olTilegrid.createXYZ({
          extent: this.YANDEX_EXTENDS
        }),
        crossOrigin: 'Anonymous',
        transition: 0
      })
    });
    t.set('name', LayerName.TILE);
    t.setZIndex(ZIndexLayer.ZERO);
    const result = this.buildYandexSats();
    result.push(t);
    return result;
  }

  private buildYandexTraffic(): Layer[] {
    const t = new TileLayer({
      source: new XYZ({
        tileUrlFunction: (p0) => {
          const x = p0[1];
          const y = -p0[2] - 1;
          const z = p0[0];
          const time = new Date().getTime();

          const url = 'https://core-jams-rdr.maps.yandex.net/1.1/tiles?l=trf,trfl&x={x}&y={y}&z={z}&tm={time}';
          return url
            .replace('{time}', '' + time)
            .replace('{z}', '' + z)
            .replace('{x}', '' + x)
            .replace('{y}', '' + y);
        },
        projection: 'EPSG:3395',
        tileGrid: olTilegrid.createXYZ({
          extent: this.YANDEX_EXTENDS
        }),
        crossOrigin: 'Anonymous',
        transition: 0
      })
    });
    t.set('name', LayerName.TILE);
    t.setZIndex(ZIndexLayer.ZERO);
    const result = this.buildYandex();
    result.push(t);
    return result;
  }

  private buildOSMSea(): Layer[] {
    const t = new TileLayer({
      source: new OSMSource({
        url: 'http://t1.openseamap.org/seamark/{z}/{x}/{y}.png',
        opaque: false,
      }),
      preload: 20
    });
    t.set('name', LayerName.TILE);
    t.setZIndex(ZIndexLayer.ZERO);
    const result = this.buildOSM();
    result.push(t);
    return result;
  }

  private buildHere(): Layer[] {
    const t = new TileLayer({
      source: new XYZ({
        // eslint-disable-next-line max-len
        url: `https://{1-4}.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?apiKey=${this.MAP_HERE_API_KEY}`,
        opaque: false,
        crossOrigin: 'Anonymous',
      }),
      preload: 20
    });
    t.set('name', LayerName.TILE);
    t.setZIndex(ZIndexLayer.ZERO);
    return [t];
  }

  private getTileLayer(name): Layer[] {
    name = name.toUpperCase();
    switch (name) {
      case TileName.OSM.toUpperCase(): {
        return this.buildOSM();
      }
      // case TileName.OSM_FR.toUpperCase(): {
      //   return this.buildOSMFR();
      // }
      case TileName.OSM_MAPIA.toUpperCase(): {
        return this.buildWikiMapia();
      }
      case TileName.YA.toUpperCase(): {
        return this.buildYandex();
      }
      case TileName.YA_SATS.toUpperCase(): {
        return this.buildYandexSats();
      }
      case TileName.YA_HYBRID.toUpperCase(): {
        return this.buildYandexHybrid();
      }
      // case TileName.YA_TRAFFIC.toUpperCase(): {
      //   return this.buildYandexTraffic();
      // }
      case TileName.OSM_SEA.toUpperCase(): {
        return this.buildOSMSea();
      }
      // case TileName.HERE.toUpperCase(): {
      //   return this.buildHere();
      // }
      default: {
        return this.buildOSM();
      }
    }
  }

  private buildWeather(element: WeatherElement): Layer[] {
    const queryElement = this.WEATHER_ELEMENT_API_MAP.get(element);
    const t = new TileLayer({
      source: new XYZ({
        url: `http://tile.openweathermap.org/map/${queryElement}/{z}/{x}/{y}.png?appid=${this.WEATHER_API_KEY}`,
        opaque: false,
      }),
      preload: 20
    });
    t.set('name', LayerName.WEATHER);
    t.setZIndex(ZIndexLayer.WEATHER);
    return [t];
  }
}
