import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppColor } from '../../../../../../../common-module/src/lib/app-enums/app-color';
import { GlobalStyleClass } from '../../../../../../../common-module/src/lib/app-enums/global-style-class.enum';
import { AppMessageService } from '../../../../../../../common-module/src/lib/app-services/app-message.service';
import { UiSpinnerService } from '../../../../../../../common-module/src/lib/app-services/ui-spinner.service';
import { EcoDrivingCriterion } from '../../../../../../../common-module/src/lib/modelinterfaces/eco-driving-criterion.model';
import { CriterionType } from '../../../../../../../common-module/src/lib/modelinterfaces/enums/criterion-type';
import { VehicleAppointment } from '../../../../../../../common-module/src/lib/modelinterfaces/enums/vehicle-appointment';
import { MinMaxRange } from '../../../../../../../common-module/src/lib/modelinterfaces/min-max-range.model';
import { SensorLastValue } from '../../../../../../../common-module/src/lib/modelinterfaces/sensor-last-value.model';
import { EcoDrivingCriterionViewService } from '../../../../../../../common-module/src/lib/services/eco-driving-criterion-view.service';
import { EcoDrivingCriterionService } from '../../../../../../../common-module/src/lib/services/eco-driving-criterion.service';
import { SensorUtils } from '../../../../../../../common-module/src/lib/utils/sensor-utils';
import { UnitDataService } from '../../../../shared/services/unit-data.service';
import { DeleteCriterionDialogComponent } from './delete-criterion-dialog/delete-criterion-dialog.component';
import { EcoDrivingUtils } from "./eco-driving-utils";
import { ImportCriterionDialogComponent } from './import-criterion-dialog/import-criterion-dialog.component';
import {DayjsUtil} from "../../../../../../../common-module/src/lib/dayjs.util";

enum Control {
  NAME = 'name',
  CRITERION = 'criterion',
  SENSOR = 'sensor',
  MIN_VALUE = 'min-value',
  MAX_VALUE = 'max-value',
  PENALTY = 'penalty',
  MIN_DURATION = 'min-duration',
  MAX_DURATION = 'max-duration',
  MIN_SPEED = 'min-speed',
  MAX_SPEED = 'max-speed',
  VALIDATOR = 'validator',
  MULTIPLIER = 'multiplier',
  COLOR = 'color'
}

@Component({
  selector: 'app-eco-driving-tab',
  templateUrl: './eco-driving-tab.component.html',
  styleUrls: ['./eco-driving-tab.component.scss', '../../../../../../../common-module/src/lib/app-styles/dialog-common.scss']
})

export class EcoDrivingTabComponent implements OnChanges, OnDestroy {

  public readonly collectionColors: Array<AppColor> = [AppColor.PRIMARY, AppColor.YELLOW, AppColor.GREEN, AppColor.BLUE, AppColor.RED,
    AppColor.GRAY, AppColor.AQUA, AppColor.ORANGE, AppColor.PURPLE, AppColor.BLACK, AppColor.MAUVE, AppColor.BROWN,
    AppColor.ACCENT, AppColor.LIGHT_GREY, AppColor.TRACK_GREEN
  ];
  public readonly displayedColumns: string[] = ['name', 'criterion', 'min-value', 'max-value', 'penalty', 'actions'];
  public readonly PENALTY_LIMIT = 100;
  public readonly VEHICLE_TYPES: VehicleAppointment[] = Object.values(VehicleAppointment);

  @Input() unitId: number;

  public availableCriterionTypes: CriterionType[];
  public dataSource: MatTableDataSource<EcoDrivingCriterion>;
  public iconColor: AppColor;
  public isMultiplierEnabled = false;
  public isFullAvailableCriterionTypes: boolean;
  public selectedVehicleType: VehicleAppointment = VehicleAppointment.TRUCK;
  public control = Control;
  public criterionForm: UntypedFormGroup;
  public criterionType = CriterionType;
  public currentCriterionId: number;
  public currentPenaltySliderClass: string;
  public sensors: SensorLastValue[] = [];
  public showValidatorSpinner = false;

  private componentDestroyed = new Subject<boolean>();

  constructor(private appMessageService: AppMessageService,
              private cdr: ChangeDetectorRef,
              private dialog: MatDialog,
              private ecoDrivingCriterionService: EcoDrivingCriterionService,
              private ecoDrivingCriterionViewService: EcoDrivingCriterionViewService,
              private translateService: TranslateService,
              private uiSpinnerService: UiSpinnerService,
              private unitDataService: UnitDataService) {
  }

  get formControl(): { [p: string]: AbstractControl } {
    return this.criterionForm.controls;
  }

  get formValue(): any {
    return this.criterionForm.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['unitId'] && this.unitId) {
      this.currentCriterionId = null;
      this.criterionForm = null;
      this.getCriterionList(this.unitId);
      this.sensors = [];
      this.getSensors();
      this.cdr.detectChanges();
    }
  }

  private getCriterionList(unitId: number): void {
    this.ecoDrivingCriterionViewService.getList(unitId, this.translateService.currentLang).subscribe(
      view => {
        const ecoDrivingView = view;
        this.availableCriterionTypes = ecoDrivingView?.availableTypes || [];
        this.isFullAvailableCriterionTypes = EcoDrivingUtils.isEcoDrivingFullSupported(this.availableCriterionTypes);
        this.parseTableData(ecoDrivingView?.criteria);
      },
      error => {
        this.showValidatorSpinner = false;
        throw error;
      }
    );
  }

  public isAvailable(type: CriterionType): boolean {
    return new Set(this.availableCriterionTypes).has(type);
  }

  private getSensors(): void {
    this.showValidatorSpinner = true;
    this.unitDataService.unitData$
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
          if (data) {
            this.sensors = data.sensorLastValue;
          }
          this.showValidatorSpinner = false;
        },
        error => {
          this.showValidatorSpinner = false;
          throw error;
        });
  }

  public buildForm(): void {
    this.criterionForm = new UntypedFormGroup({
      [Control.NAME]: new UntypedFormControl('', [Validators.required]),
      [Control.CRITERION]: new UntypedFormControl(CriterionType.ACCELERATION, [Validators.required]),
      [Control.SENSOR]: new UntypedFormControl(''),
      [Control.MIN_VALUE]: new UntypedFormControl(''),
      [Control.MAX_VALUE]: new UntypedFormControl(''),
      [Control.PENALTY]: new UntypedFormControl('',
        [Validators.required, Validators.min(0), Validators.max(this.PENALTY_LIMIT)]),
      [Control.MIN_DURATION]: new UntypedFormControl('', [Validators.min(0)]),
      [Control.MAX_DURATION]: new UntypedFormControl('', [Validators.min(0)]),
      [Control.MIN_SPEED]: new UntypedFormControl('', [Validators.min(0)]),
      [Control.MAX_SPEED]: new UntypedFormControl('', [Validators.min(0)]),
      [Control.VALIDATOR]: new UntypedFormControl(''),
      [Control.MULTIPLIER]: new UntypedFormControl({value: '', disabled: true}),
      [Control.COLOR]: new UntypedFormControl('')
    });
    this.formControl[Control.PENALTY].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(value => {
        this.currentPenaltySliderClass = this.setClassNameByPenaltyValue('penalty-slider', value);
        this.formControl[Control.PENALTY].markAsDirty();
      });
    this.formControl[Control.CRITERION].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(value => {
        if (value !== CriterionType.CUSTOM) {
          this.formControl[Control.SENSOR].setValue(null);
        }
      });
    this.formControl[Control.VALIDATOR].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(value => {
        if (value) {
          this.formControl[Control.MULTIPLIER].enable();
        } else {
          this.formControl[Control.MULTIPLIER].setValue(null);
          this.formControl[Control.MULTIPLIER].disable();
        }
      });
  }

  public onImportCriterionListFromTemplate(vehicle: VehicleAppointment): void {
    this.ecoDrivingCriterionService.import(this.unitId, this.translateService.currentLang, vehicle)
      .subscribe(list => this.parseTableData(list));
  }

  public onImportCriterionListFromAnotherUnit(): void {
    const dialogRef = this.dialog.open(ImportCriterionDialogComponent, {
      panelClass: GlobalStyleClass.DIALOG_SIZE_LIMIT,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result?.selectedUnitId) {
        this.ecoDrivingCriterionService
          .import(this.unitId, this.translateService.currentLang, null, result?.selectedUnitId)
          .subscribe(list => this.parseTableData(list));
      }
    });
  }

  public onImportCriterionListFromFile(event: EventTarget): void {
    const files: FileList = (event as HTMLInputElement).files;
    if (!files || files.length === 0) {
      return;
    }
    let formData: FormData = new FormData();
    formData.append('file', files[0], files[0].name);

    this.ecoDrivingCriterionService.importFromFile(this.unitId, this.translateService.currentLang, formData)
      .subscribe(list => this.parseTableData(list));
  }

  private parseTableData(list: EcoDrivingCriterion[]): void {
    this.dataSource = new MatTableDataSource(list);
    this.cdr.detectChanges();
  }

  public saveCriterionListInFile(): void {
    this.ecoDrivingCriterionService.exportToFile(this.unitId)
      .subscribe(file => {
          let name;
          if (file?.headers?.get('Content-Disposition')?.includes('filename=')) {
            name = file?.headers?.get('Content-Disposition').split('filename=')[1];
            name = name?.substring(1, name.length - 1);
          } else {
            name = 'criterion-list-' + DayjsUtil.instant().toISOString() + '.aur';
          }
          const blob = new Blob(
            [JSON.stringify(file)], {type: 'application/json'}
          );
          saveAs(blob, name);
        }
      );
  }

  public setClassNameByPenaltyValue(baseClassName: string, value: number): string {
    let classNames = baseClassName + ' ';
    switch (true) {
      case value > 70:
        classNames += 'red';
        break;
      case value > 30:
        classNames += 'yellow';
        break;
      default:
        classNames += 'blue';
        break;
    }
    return classNames;
  }

  public onChangePenalty(value: number): void {
    this.formControl[Control.PENALTY].setValue(value);
  }

  public onChangeColor(color: AppColor): void {
    this.iconColor = color;
  }

  public onCancelForm(): void {
    this.criterionForm = null;
    this.currentCriterionId = null;
  }

  public onSubmitForm(): void {
    this.uiSpinnerService.show();
    const criterion = new EcoDrivingCriterion(
      this.currentCriterionId || 0,
      this.formValue[this.control.NAME],
      this.formValue[this.control.CRITERION].toUpperCase(),
      this.formValue[this.control.SENSOR],
      new MinMaxRange(this.formValue[this.control.MIN_VALUE], this.formValue[this.control.MAX_VALUE]),
      new MinMaxRange(this.formValue[this.control.MIN_DURATION], this.formValue[this.control.MAX_DURATION]),
      new MinMaxRange(this.formValue[this.control.MIN_SPEED], this.formValue[this.control.MAX_SPEED]),
      this.formValue[this.control.VALIDATOR],
      this.formValue[this.control.PENALTY],
      this.formValue[this.control.MULTIPLIER],
      this.formValue[this.control.COLOR],
      null
    );
    if (this.currentCriterionId) {
      this.ecoDrivingCriterionService.update(this.currentCriterionId, criterion, this.translateService.currentLang).subscribe(
        updatedCriterion => {
          this.uiSpinnerService.stop();
          const updatedDataSource = this.dataSource.data.map(criterion => {
            if (criterion.id === updatedCriterion.id) {
              criterion = updatedCriterion
            }
            return criterion;
          });
          this.dataSource = new MatTableDataSource<EcoDrivingCriterion>(updatedDataSource);
          this.appMessageService.openSnackBar('message.info.changes-will-take-effect-within', false, {minutes: 15});
          this.currentCriterionId = null;
          this.criterionForm = null;
          this.cdr.detectChanges();
        }
      );
    } else {
      this.ecoDrivingCriterionService.save(this.unitId, criterion, this.translateService.currentLang).subscribe(
        criterion => {
          this.uiSpinnerService.stop();
          let updatedDataSource = this.dataSource.data;
          updatedDataSource.push(criterion);
          this.dataSource = new MatTableDataSource<EcoDrivingCriterion>(updatedDataSource);
          this.appMessageService.openSnackBar('message.info.changes-saved');
          this.currentCriterionId = null;
          this.criterionForm = null;
          this.cdr.detectChanges();
        }
      );
    }
  }

  public onCopyCriterion(event: Event, criterion: EcoDrivingCriterion): void {
    event.stopPropagation();
    this.currentCriterionId = 0;
    this.updateFormValues(criterion);
  }

  public onDeleteCriterion(event: Event, deletedCriterion: EcoDrivingCriterion): void {
    event.stopPropagation();
    const dialogRef = this.dialog.open(DeleteCriterionDialogComponent, {
      panelClass: GlobalStyleClass.DIALOG_SIZE_LIMIT,
      autoFocus: false,
      data: {
        criterion: deletedCriterion
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataSource =
          new MatTableDataSource<EcoDrivingCriterion>(this.dataSource.data.filter(criterion => criterion.id !== deletedCriterion.id));
        this.cdr.detectChanges();
      }
    });
  }

  public asCriterion(criterion: EcoDrivingCriterion): EcoDrivingCriterion {
    return criterion;
  }

  public editCriterion(criterion: EcoDrivingCriterion): void {
    this.currentCriterionId = criterion.id;
    this.updateFormValues(criterion);
  }

  private updateFormValues(criterion: EcoDrivingCriterion): void {
    if (!this.criterionForm) {
      this.buildForm();
    }
    this.formControl[Control.NAME].setValue(criterion?.name);
    this.formControl[Control.CRITERION].setValue(criterion?.type || CriterionType.ACCELERATION);
    this.formControl[Control.SENSOR].setValue(criterion?.sensor);
    this.formControl[Control.MIN_VALUE].setValue(SensorUtils.toValue(criterion?.valueRange.min, 2));
    this.formControl[Control.MAX_VALUE].setValue(SensorUtils.toValue(criterion?.valueRange.max, 2));
    this.formControl[Control.PENALTY].setValue(criterion?.penalty);
    this.formControl[Control.MIN_DURATION].setValue(SensorUtils.toValue(criterion?.durationRange.min, 2));
    this.formControl[Control.MAX_DURATION].setValue(SensorUtils.toValue(criterion?.durationRange.max, 2));
    this.formControl[Control.MIN_SPEED].setValue(SensorUtils.toValue(criterion?.speedRange.min, 2));
    this.formControl[Control.MAX_SPEED].setValue(SensorUtils.toValue(criterion?.speedRange.max, 2));
    this.formControl[Control.VALIDATOR].setValue(criterion?.validatingSensor);
    this.formControl[Control.MULTIPLIER].setValue(criterion?.multiplier);
    this.formControl[Control.COLOR].setValue(criterion?.color);
    this.criterionForm.markAsPristine();
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
  }
}
