export class FullName {
  constructor(
    public last: string,
    public first: string,
    public middle: string) {
  }

  public static valueOf(o: FullName): FullName {
    return o ? new FullName(o.last, o.first, o.middle) : null;
  }

  public getFull(): string {
    return `${this.last} ${this.join(' ', this.first, this.middle)}`;
  }

  public getShort(): string {
    return `${this.last} ${this.join('.', this.first?.charAt(0), this.middle?.charAt(0))}`;
  }

  private join(separator: string, ...arr: string[]) {
    return arr.filter(s => !!s).join(separator)
  }
}
