import { NotificationActionText } from './notification-action-text.model';

export class NotificationActionCommand {

  constructor(public activate: NotificationActionText,
              public deactivate: NotificationActionText) {
  }

  public static valueOf(i: NotificationActionCommand): NotificationActionCommand {
    return i ?
      new NotificationActionCommand(NotificationActionText.valueOf(i.activate), NotificationActionText.valueOf(i.deactivate)) : null;
  }
}
