import { TranslateService } from '@ngx-translate/core';
import { Address } from './address.model';
import { Mileage } from './mileage.model';
import { OdometerRange } from './odometer-range.model';
import { DurationConverter } from '../utils/duration-converter';

export class Trip {

  constructor(public id: number,
              public startDatetime: string,
              public finishDatetime: string,
              public startAddress: Address,
              public finishAddress: Address,
              public mileage: Mileage,
              public duration: number,
              public maxSpeed: number,
              public ecoDrivingRate: number,
              public gpsOdometerRange: OdometerRange,
              public odometerRange: OdometerRange) {
  }

  public static valueOf(o: Trip): Trip {
    return o ? new Trip(o.id, o.startDatetime, o.finishDatetime, Address.valueOf(o.startAddress), Address.valueOf(o.finishAddress),
      Mileage.valueOf(o.mileage), o.duration, o.maxSpeed, o.ecoDrivingRate, OdometerRange.valueOf(o.gpsOdometerRange),
      OdometerRange.valueOf(o.odometerRange)) : null;
  }

  public static valuesOf(list: Trip[]): Trip[] {
    return list ? list.map(o => Trip.valueOf(o)) : [];
  }

// return string duration
  public getDuration(translateService: TranslateService): string {
    return DurationConverter.secondsToStringDuration(this.duration, translateService);
  }

  public getMileageInKm(): number {
    return Math.round(this.mileage.corrected * 100) / 100;
  }
}
