import { Directive } from '@angular/core';

@Directive({
  selector: '[appDialogTitle]',
})
export class DialogTitleDirective {

  constructor() { }

}
