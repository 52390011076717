<div [formGroup]="form">
  <mat-form-field class="width-100-percent" [appearance]="appearance" [style.min-width.px]="100">
    <mat-label>{{ label }}</mat-label>
    <input [formControlName]="controls.PHONE_NUMBER"
           mask="+000 (00) 000-00-00"
           [leadZeroDateTime]="true"
           [validation]="false"
           [showMaskTyped]="true"
           matInput>

    <mat-error *ngIf="form.get(controls.PHONE_NUMBER).touched && form.get(controls.PHONE_NUMBER).errors?.minlength"
               [matTooltip]="'Неверная длина ожидается: '+ form.get(controls.PHONE_NUMBER).errors?.minlength.requiredLength + ' цифр, введено: ' + form.get(controls.PHONE_NUMBER).errors?.minlength.actualLength">
      Неверная длина
      <mat-icon>help</mat-icon>
    </mat-error>

  </mat-form-field>

</div>

