import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {LocalStorageKey} from '../../../../../common-module/src/lib/app-enums/local-storage-key';
import {UiSpinnerService} from '../../../../../common-module/src/lib/app-services/ui-spinner.service';
import {ConnectionStatus} from '../../../../../common-module/src/lib/modelinterfaces/enums/connection-status';
import {UnitsFilterBy} from '../../../../../common-module/src/lib/modelinterfaces/enums/units-filter-by';
import {UnitShort} from '../../../../../common-module/src/lib/modelinterfaces/unit-short.model';
import {UnitState} from '../../../../../common-module/src/lib/modelinterfaces/unit-state.model';
import {
  UnitGroupView,
  UserUnitGroups
} from '../../../../../common-module/src/lib/modelinterfaces/user-unit-groups.model';
import {UnitGroupStorageService} from '../../../../../common-module/src/lib/services/unit-group-storage.service';
import {UnitWithCameraService} from '../../../../../common-module/src/lib/services/unit-with-camera.service';
import {ShowUnits} from '../constants/enums/show-units';
import {UnitsFilter, UnitsOrder} from '../constants/enums/units-filter';
import {SelectedService} from './selected.service';
import {UnitGroupsService} from './unit-groups.service';
import {UnitStateService} from './unit-state.service';
import {UnitWithCamerasBiDvrService} from './unit-with-cameras-Bi-Dvr.service';
import {UnitStateStorage} from "../../../../../common-module/src/lib/modelinterfaces/unit-state-storage.model";

@Injectable({
  providedIn: 'root'
})

export class UnitFilterService {

  private _filteredUnitsSource = new BehaviorSubject<UnitShort[]>([]);
  private _filteredUnits$ = this._filteredUnitsSource.asObservable();

  private _unitsOnMap: BehaviorSubject<UnitShort[]> = new BehaviorSubject<UnitShort[]>([]);
  private _unitsOnMap$ = this._unitsOnMap.asObservable();

  private _unitsOnMapCurrentStateSource = new BehaviorSubject<ShowUnits>(ShowUnits.ALL);
  private _unitsOnMapCurrentState$: Observable<ShowUnits> = this._unitsOnMapCurrentStateSource.asObservable();

  private _currentFilterSource = new BehaviorSubject<UnitsFilter>(UnitsFilter.NONE);
  private _currentFilter$: Observable<UnitsFilter> = this._currentFilterSource.asObservable();

  private _currentOrderSource = new BehaviorSubject<UnitsOrder>(UnitsOrder.NEW_DESC);
  private _currentOrder$: Observable<UnitsOrder> = this._currentOrderSource.asObservable();

  private _filteringUnitSelectedUsersSource = new BehaviorSubject<string[]>([]);
  private _filteringUnitBySelectedUsers$: Observable<string[]> = this._filteringUnitSelectedUsersSource.asObservable();

  private allUnits: UnitShort[] = [];
  private searchStr: string;
  private selectedUserIds: number[] = [];
  private selectedUnitIdsSet: Set<number>;
  private mapFiltersForQuery: Map<string, string> = new Map();
  private mapFilterUnitsBy: Map<string, number[]> = new Map();
  private destroyed = new Subject<boolean>();

  constructor(private selectedService: SelectedService,
              private uiSpinnerService: UiSpinnerService,
              private unitGroupsService: UnitGroupsService,
              private unitGroupStorageService: UnitGroupStorageService,
              private unitStateService: UnitStateService,
              private unitWithCameraService: UnitWithCameraService,
              private unitWithCamerasBiDvrService: UnitWithCamerasBiDvrService) {
  }

  get unitsOnMap$(): Observable<UnitShort[]> {
    return this._unitsOnMap$;
  }

  get filteredUnits$(): Observable<UnitShort[]> {
    return this._filteredUnits$;
  }

  get currentFilter$(): Observable<UnitsFilter> {
    return this._currentFilter$;
  }

  get currentOrder$(): Observable<UnitsOrder> {
    return this._currentOrder$;
  }


  get filteringUnitBySelectedUsers$(): Observable<string[]> {
    return this._filteringUnitBySelectedUsers$;
  }

  get unitsOnMapCurrentState$(): Observable<ShowUnits> {
    return this._unitsOnMapCurrentState$;
  }

  public init(isPublicInterface: boolean = false): void {
    this.mapFiltersForQuery.set(UnitsFilter.MECHANISM, UnitsFilterBy.HAS_MECHANISM);
    this.mapFiltersForQuery.set(UnitsFilter.FFS, UnitsFilterBy.HAS_FUEL_FLOW);
    this.mapFiltersForQuery.set(UnitsFilter.FLS, UnitsFilterBy.HAS_FUEL_LEVEL);
    this.mapFiltersForQuery.set(UnitsFilter.VIDEO, UnitsFilter.VIDEO.toUpperCase());
    this.mapFiltersForQuery.set(UnitsFilter.ECO_DRIVING_CRITERIA, UnitsFilterBy.HAS_ECO_DRIVING_CRITERIA);

    this.unitGroupsService.allUnits$
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        units => {
          if (units) {
            this.allUnits = units;
            const filteredUnits = isPublicInterface ? this.allUnits : this.filterUnits();
            this.changeFilteredUnits(filteredUnits);
            this._unitsOnMapCurrentStateSource.next(this.getStateFromLocaleStorage());
          }
        }
      );
    this.selectedService.selectedUserIds$
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        list => {
          this.selectedUserIds = list;
          if (this._unitsOnMapCurrentStateSource.getValue() === ShowUnits.SELECTED_GROUPS) {
            this._unitsOnMap.next(this.showUnitsOnMapByState(ShowUnits.SELECTED_GROUPS));
          }
        }
      );
    this.selectedService.selectedUnitIdsSet$
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        set => {
          this.selectedUnitIdsSet = set;
          if (this._unitsOnMapCurrentStateSource.getValue() === ShowUnits.SELECTED_UNITS) {
            this._unitsOnMap.next(this.showUnitsOnMapByState(ShowUnits.SELECTED_UNITS));
          }
        }
      );
  }

  public changeFilteredUnits(units: UnitShort[]): void {
    units.sort(this.orderComporator(this._currentOrderSource.getValue()))
    this._filteredUnitsSource.next(units);
    this.changeUnitsOnMap();
  }

  public changeFilteringSelectedUsers(userNameList: string[]): void {
    this._filteringUnitSelectedUsersSource.next(userNameList);
    this.changeFilteredUnits(this.filterUnits());
  }

  changeCurrentOrder(setting: UnitsOrder) {
    this._currentOrderSource.next(setting);
    this.changeFilteredUnits(this.filterUnits());
  }

  public changeCurrentFilter(filter: UnitsFilter): void {
    this._currentFilterSource.next(filter);
    if (this.getUnitIdListFilterBy(filter) || !this.mapFiltersForQuery.has(filter)) {
      this.changeFilteredUnits(this.filterUnits());
      return;
    }

    if (filter === UnitsFilter.VIDEO) {
      this.uiSpinnerService.show();
      this.unitWithCameraService.getUnitListWithCameras().subscribe(
        list => {
          this.unitWithCamerasBiDvrService.changeList(list);
          this.unitWithCamerasBiDvrService.changeStatus(false);
          const listId = list.map(el => el.id);
          this.changeFilterBy(filter, listId);
          this.changeFilteredUnits(this.filterUnits());
          this.uiSpinnerService.stop();
        },
        error => {
          this.unitWithCamerasBiDvrService.changeStatus(true);
          this.changeFilterBy(filter, []);
          this.changeFilteredUnits(this.filterUnits());
          throw error;
        });
    } else {
      this.uiSpinnerService.show();
      this.unitGroupStorageService.getDataFilterBy(this.mapFiltersForQuery.get(filter)).subscribe(data => {
          this.changeFilterBy(filter, data);
          this.changeFilteredUnits(this.filterUnits());
          this.uiSpinnerService.stop();
        },
        error => {
          this.changeFilterBy(filter, []);
          this.changeFilteredUnits(this.filterUnits());
          throw error;
        });
    }
  }

  private filterUnits(): UnitShort[] {
    const currentFilter = this._currentFilterSource.getValue();

    let result: UnitShort[];
    const userList: UserUnitGroups[] = this.unitGroupsService.getInstant()?.grouped;
    if (!userList) {
      return [];
    }
    const filteredUserList: UserUnitGroups[] = userList.filter(el => this._filteringUnitSelectedUsersSource.getValue().includes(el.name));
    let unitListBySelectedUsers: UnitShort[] = [];
    filteredUserList.forEach(el => unitListBySelectedUsers = unitListBySelectedUsers.concat(el.owned.concat(el.shared)));
    const units = this.allUnits.filter(el => unitListBySelectedUsers.some(unit => unit.id === el.id));

    if (this.searchStr) {
      result = units.filter(unit => unit.name.toLowerCase().includes(this.searchStr.toLowerCase()));
    } else {
      result = units;
    }

    if (currentFilter === UnitsFilter.NONE) {
      return result;
    }
    if (this.mapFilterUnitsBy.has(currentFilter)) {
      return result.filter(unit => this.mapFilterUnitsBy.get(currentFilter).includes(unit.id));
    }
    if (currentFilter === UnitsFilter.ONLINE) {
      const dataMap: Map<number, UnitState> = this.unitStateService.getInstant().data;
      return result.filter(unit => dataMap.get(unit.id).connectionState.status === ConnectionStatus.ONLINE ||
        dataMap.get(unit.id).connectionState.status === ConnectionStatus.NOT_VALID);
    }
    if (currentFilter === UnitsFilter.OFFLINE) {
      const dataMap: Map<number, UnitState> = this.unitStateService.getInstant().data;
      return result.filter(unit => dataMap.get(unit.id).connectionState.status === ConnectionStatus.OFFLINE ||
        dataMap.get(unit.id).connectionState.status === ConnectionStatus.NO_DATA);
    }
    if (currentFilter === UnitsFilter.VIDEO) {
      return result.filter(unit => this.unitWithCamerasBiDvrService.getInstantListWithCameras() ?
        this.unitWithCamerasBiDvrService.getInstantListWithCameras().some(el => el.id === unit.id) : []);
    }
    return [];
  }

  private getUnitIdListFilterBy(key: string): number[] {
    return this.mapFilterUnitsBy.get(key);
  }

  private changeFilterBy(value, list: number[]): void {
    this.mapFilterUnitsBy.set(value, list);
  }

  public instantUnitsOnMap(): UnitShort[] {
    return this._unitsOnMap.getValue();
  }

  public changeSearchStr(value: string): void {
    this.searchStr = value;
    this._filteredUnitsSource.next(this.filterUnits());
  }

  public changeUnitsOnMapState(state: ShowUnits): void {
    this._unitsOnMapCurrentStateSource.next(state);
    window.localStorage.setItem(LocalStorageKey.SHOW_UNITS_ON_MAP_STATE, state);
    this._unitsOnMap.next(this.showUnitsOnMapByState(state));
  }

  private changeUnitsOnMap(): void {
    this._unitsOnMap.next(this.showUnitsOnMapByState());
  }

  private getStateFromLocaleStorage(): ShowUnits {
    let currentState: ShowUnits;
    if (window.localStorage.getItem(LocalStorageKey.SHOW_UNITS_ON_MAP_STATE)) {
      currentState = <ShowUnits>window.localStorage.getItem(LocalStorageKey.SHOW_UNITS_ON_MAP_STATE);
    } else {
      currentState = ShowUnits.ALL;
    }
    this.changeUnitsOnMapState(currentState);
    return currentState;
  }

  private showUnitsOnMapByState(state: ShowUnits = this._unitsOnMapCurrentStateSource.getValue()): UnitShort[] {
    const units = this._filteredUnitsSource.getValue();
    if (state === ShowUnits.SELECTED_GROUPS) {
      const filteredUserList: UserUnitGroups[] =
        this.unitGroupsService.getInstant().grouped.filter(el => this.selectedUserIds.includes(el.id));
      let unitListBySelectedUsers: UnitShort[] = [];
      filteredUserList.forEach(el => unitListBySelectedUsers = unitListBySelectedUsers.concat(el.owned.concat(el.shared)));
      return units.filter(el => unitListBySelectedUsers.some(unit => unit.id === el.id));
    }
    if (state === ShowUnits.SELECTED_UNITS) {
      return units.filter(el => this.selectedUnitIdsSet.has(el.id));
    }
    return units;
  }

  public destroy(): void {
    this.destroyed.next(null);
    this.destroyed.complete();
    this.changeUnitsOnMapState(ShowUnits.ALL);
    this.changeCurrentFilter(UnitsFilter.NONE);
    this.changeSearchStr(null);
    this.allUnits = [];
    this.changeFilteredUnits([]);
    this.unitWithCamerasBiDvrService.destroy();
  }

  private orderComporator(order: UnitsOrder): (a: UnitShort, b: UnitShort) => number {
    let unitStateStorage = this.unitStateService.getInstant();
    let unitGroups = this.unitGroupsService.getInstant()?.getUnitGroupView() ?? new Map<number, UnitGroupView>();
    switch (order) {
      case UnitsOrder.BY_NAME_ASC:
        return (a: UnitShort, b: UnitShort) => a.name.localeCompare(b.name);
      case UnitsOrder.BY_NAME_DESC:
        return (a: UnitShort, b: UnitShort) => b.name.localeCompare(a.name);
      case UnitsOrder.BY_SECONDS_AGO_ASC:
        return (a: UnitShort, b: UnitShort) => {
          let secondsA = this.getSecondsAgo(a, unitStateStorage);
          let secondsB = this.getSecondsAgo(b, unitStateStorage);
          return secondsA - secondsB;
        };
      case UnitsOrder.BY_SECONDS_AGO_DESC:
        return (a: UnitShort, b: UnitShort) => {
          let secondsA = this.getSecondsAgo(a, unitStateStorage);
          let secondsB = this.getSecondsAgo(b, unitStateStorage);
          return secondsB - secondsA;
        }
      case UnitsOrder.BY_DRIVER_NAME_ASC:
        return (a: UnitShort, b: UnitShort) => {
          const nameA = unitStateStorage?.get(a.id).driver?.name.getShort();
          const nameB = unitStateStorage?.get(b.id).driver?.name.getShort();
          if (!nameA && !nameB) return 0;
          if (!nameA) return 1;
          if (!nameB) return -1;
          return nameA.localeCompare(nameB);
        }
      case UnitsOrder.BY_DRIVER_NAME_DESC:
        return (a: UnitShort, b: UnitShort) => (unitStateStorage?.get(b.id).driver?.name.getShort() ?? '').localeCompare(unitStateStorage?.get(a.id).driver?.name.getShort() ?? '');
      case UnitsOrder.BY_GROUPS:
        return (a: UnitShort, b: UnitShort) => unitGroups?.get(a.id)?.groupName.localeCompare(unitGroups?.get(b.id)?.groupName);
      case UnitsOrder.NEW_ASC:
        return (a: UnitShort, b: UnitShort) => a.id - b.id;
      default:
        return (a: UnitShort, b: UnitShort) => b.id - a.id;
    }
  }

  private getSecondsAgo(unit: UnitShort, storage: UnitStateStorage): number {
    const secondsAgo = storage?.get(unit.id)?.connectionState?.secondsAgo ?? -1;
    return secondsAgo === -1 ? Number.MAX_VALUE : secondsAgo;
  }

}
