// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

declare var require: any
export const environment = {
  production: false,
  GUI_VERSION: require('../../../../package.json').version,
  BASE_URL: {
    BASE: 'http://localhost:8080',
    AURORA: '/aurora/api/v1',
    AURORA_V2: '/aurora/api/v2',
    DVR: '/bi-dvr/api/v1',
  },
  BASE_USER_URL: 'https://monitoring.aurora-soft.xyz',
  BASE_ADMIN_URL: 'https://admin.aurora-soft.xyz',
  HEADER: 'LOCAL',
  DEFAULT_LANGUAGE: 'ru',
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
