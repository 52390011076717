import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatListOption, MatSelectionList} from "@angular/material/list";
import {SensorLastValue} from "../../../../../../../../common-module/src/lib/modelinterfaces/sensor-last-value.model";
import {SensorValuePipe} from "../../../../../../../../common-module/src/lib/pipes/sensor-value.pipe";
import {NgForOf} from "@angular/common";
import {SelectionModel} from "@angular/cdk/collections";
import {SensorLastValueItemComponent} from "./sensor-value-item/sensor-last-value-item.component";

@Component({
  selector: 'app-sensor-list',
  standalone: true,
  imports: [
    MatSelectionList,
    MatListOption,
    SensorValuePipe,
    NgForOf,
    SensorLastValueItemComponent
  ],
  templateUrl: './sensor-list.component.html',
  styleUrl: './sensor-list.component.scss'
})
export class SensorListComponent implements OnInit {

  @Input() multiple = false;
  @Input() selectedSensors: SensorLastValue[] = [];

  @Input() set sensors(sensors: SensorLastValue[]) {
    this._sensors = sensors.filter(sensor => sensor.visible);
  }

  @Output() selectionStateChange = new EventEmitter<'none' | 'all' | 'intermediate'>();

  @Output() selectedSensorsChange = new EventEmitter<SensorLastValue[]>();

  protected _sensors: SensorLastValue[] = [];
  protected selectionModel: SelectionModel<SensorLastValue> = new SelectionModel<SensorLastValue>();

  ngOnInit(): void {
    // Создаем новый SelectionModel и передаем в него возможность множественного выбора и выбранные элементы
    this.selectionModel = new SelectionModel<SensorLastValue>(this.multiple, this.selectedSensors);
    this.fireSelectionState(this.selectedSensors);
    this.selectionModel.changed.subscribe(model => {
      this.fireSelectionState(model.source.selected);
    })
  }

  protected onToggleSelection(sensor: SensorLastValue): void {
    this.selectionModel.toggle(sensor);
    this.selectedSensorsChange.emit(this.selectionModel.selected);
  }

  protected onSelectionChange(): void {
    this.selectedSensorsChange.emit(this.selectionModel.selected);
  }

  private fireSelectionState(selected: SensorLastValue[]): void {
    const currentState = this.calcSelectionState(selected);
    this.selectionStateChange.emit(currentState);
  }

  private calcSelectionState(selected: SensorLastValue[]): 'none' | 'all' | 'intermediate' {
    if (selected.length === 0) {
      return 'none';
    } else if (selected.length === this._sensors.length) {
      return 'all';
    } else {
      return 'intermediate';
    }
  }

  public toggleSelection() {
    const currentState = this.calcSelectionState(this.selectionModel.selected);
    switch (currentState) {
      case "intermediate":
      case "none":
        this.selectionModel.select(...this._sensors);
        break;
      case "all":
        this.selectionModel.deselect(...this._sensors);
        break;
    }
  }
}
