<app-dialog [title]="'term.map' | translate">
  <div appDialogContent class="app-dialog-content-column">

    <app-print-button class="print-button"
                      [showSpinner]="showSpinner"
                      (printChange)="onReceivePrintOrientation($event)">
    </app-print-button>
    <div id="file-title" *ngIf="data.tableData">
      <b class="margin-left-8">{{ 'term.unit' | translate }}: {{ data.unitName }}</b>
      <table mat-table [dataSource]="data.tableData">
        <!-- Position StatisticData -->
        <ng-container [matColumnDef]="column" *ngFor="let column of data.displayedColumns">
          <th mat-header-cell class="title" *matHeaderCellDef>{{ data.titleData[column] | capitalize }}</th>
          <td mat-cell *matCellDef="let element"> {{ element[column] }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="data.displayedColumns"></tr>
        <tr mat-row class="not-hover" *matRowDef="let row; columns: data.displayedColumns;"></tr>
      </table>
    </div>
    <div class="map" id="map-dialog">
      <div class="box-buttons-and-conditions">
        <app-map-buttons
          [map]="map"
          [isShowButtons]="false">
        </app-map-buttons>

        <app-map-conditions>
        </app-map-conditions>
      </div>
    </div>
  </div>
</app-dialog>
