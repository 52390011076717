import Draw from "ol/interaction/Draw";
import GeometryType from "ol/geom/GeometryType";
import OlMap from "ol/Map";
import {OlVectorLayer} from "../../../../map-utils/OlVectorLayer";
import VectorLayer from "ol/layer/Vector";
import {JobFeatureWrapper} from "../../../shared/feature/logistic/job-feature/shipment-feature.wrapper";
import {StepFeatureWrapper} from "../../../shared/feature/logistic/step-feature/step-feature.wrapper";
import {AbstractJobFeatureWrapper} from "../../../shared/feature/logistic/abstract-job-feature.wrapper";
import Modify from "ol/interaction/Modify";
import {BehaviorSubject} from "rxjs";

export class GroupSelectionInteraction {

  private drawLayer: VectorLayer;
  private drawInteraction: Draw;
  private modifyInteraction: Modify;
  private highlightedJobWrappers = new Set<JobFeatureWrapper>();
  private _highlightedCount = new BehaviorSubject<number>(0);
  public readonly highlightedCount = this._highlightedCount.asObservable();

  constructor(private map: OlMap) {
    this.drawLayer = OlVectorLayer.createLayerOnMap(this.map, 'group-selection-interaction');
  }

  public startGroupSelect(featureLayer: VectorLayer, jobFeatureMap: Map<number, JobFeatureWrapper>) {
    this.initializeDrawInteraction();
    this.handleDraw(featureLayer, jobFeatureMap);
  }

  private initializeDrawInteraction(): void {
    this.drawInteraction = new Draw({
      source: this.drawLayer.getSource(),
      type: GeometryType.POLYGON,
    });
    this.map.addInteraction(this.drawInteraction);
  }

  private handleDraw(featureLayer: VectorLayer, jobFeatureMap: Map<number, JobFeatureWrapper>) {
    this.drawInteraction.on('drawstart', (event) => {
      const feature = event.feature;

      feature.getGeometry().on('change', (e) => {
        const polygon = e.target;
        const featureSet = new Set<JobFeatureWrapper>();
        featureLayer.getSource().forEachFeature((otherFeature) => {
          if (polygon.intersectsExtent(otherFeature.getGeometry().getExtent())) {
            if (StepFeatureWrapper.hasStepFromFeature(otherFeature)) {
              const job = AbstractJobFeatureWrapper.getJobFromFeature(otherFeature);
              const featureWrapper = jobFeatureMap.get(job.id);
              featureSet.add(featureWrapper);
            }
          }
        });
        this.highlightedJobWrappers.forEach(w => w.unHighlight());
        this.highlightedJobWrappers = featureSet;
        this.highlightedJobWrappers.forEach(w => w.highlight());
        this._highlightedCount.next(this.highlightedJobWrappers.size)
      });
    });

    this.drawInteraction.on('drawend', (event) => {
      this.map.removeInteraction(this.drawInteraction);

      const modify = new Modify({
        source: this.drawLayer.getSource(),
      });
      this.map.addInteraction(modify);
      this.modifyInteraction = modify;
    });
  }



  public clearGroupSelection() {
    this.map.removeInteraction(this.drawInteraction);
    this.map.removeInteraction(this.modifyInteraction);
    this.clearDrawLayer();
    this.highlightedJobWrappers.forEach(w => w.unHighlight());
    this.highlightedJobWrappers.clear();
    this._highlightedCount.next(0);
  }

  private clearDrawLayer() {
    this.drawLayer?.getSource()?.clear();
  }

  public getHighlightedFeatures(): JobFeatureWrapper[] {
    // @ts-ignore
    return [...this.highlightedJobWrappers];
  }
}
