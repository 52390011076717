import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import OlMap from 'ol/Map';
import GeometryType from 'ol/geom/GeometryType';
import {Observable} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

import {TileLayerService} from '../../../../shared/services/tile-layer.service';
import {ViewBreakpointService, ViewSize} from '../../../../../../../common-module/src/lib/app-services/view-breakpoint.service';
import {MapToolsService} from '../../../../shared/services/map-tools.service';
import {GeofenceManagerModeService, GeofenceMode} from '../geofence-manager/geofence-manager-mode.service';
import {UnitFilterService} from '../../../../shared/services/unit-filter.service';
import {UrlImage} from '../../../../shared/constants/url-image';
import {UnitTraceService} from '../../../../shared/services/unit-trace.service';
import {ShowTrace} from '../../../../shared/constants/enums/show-trace';
import {ShowUnits} from '../../../../shared/constants/enums/show-units';
import {MapSettings} from '../../../../shared/constants/enums/map-settings';
import {TileName} from '../../../../shared/constants/enums/tile-name';
import {PrintToPdfService} from '../../../../shared/services/print-to-pdf.service';
import {TrackLayerService} from '../../../../shared/services/track-layer.service';
import {WeatherElement} from '../../../../shared/constants/enums/weather-element';
import {MapTool} from '../../../../shared/constants/enums/map-tool';
import {MeasureManagerService} from '../measure-manager/measure-manager.service';
import {MapSelectUnitService} from '../../../../shared/services/map-select-unit.service';

@Component({
  selector: 'app-map-buttons',
  templateUrl: './map-buttons.component.html',
  styleUrls: ['./map-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MapButtonsComponent implements OnChanges, OnInit {

  public readonly GEOFENCE_ICON = UrlImage.GEOFENCE;
  public readonly MEASURE_ICON_MAP = new Map<GeometryType, string>(
    [
      [GeometryType.LINE_STRING, 'straighten'],
      [GeometryType.POLYGON, 'photo_size_select_small'],
    ]
  );

  @Input() map: OlMap;
  @Input() isShowButtons = true;
  @Input() isShowViolations = false;
  @Input() showTrackInfoChart = false;

  @Output() changedShowViolations = new EventEmitter<boolean>();

  public currentMeasureTool = GeometryType.LINE_STRING;
  public currentTileName$: Observable<TileName>;
  public currentWeatherElement$: Observable<WeatherElement>;
  public geometryType = GeometryType;
  public isShowOnMapViolations: boolean = true;
  public isShowZoomSlider = false;
  public isTracking$: Observable<boolean>;
  public layerNames: TileName[] = Object.values(TileName);
  public mapSettings = MapSettings;
  public mapTool = MapTool;
  public mapToolState$: Observable<MapTool>;
  public showCurrentTrace$: Observable<ShowTrace>;
  public showTrace = ShowTrace;
  public showTraceList: ShowTrace[] = Object.values(ShowTrace);
  public showUnits = ShowUnits;
  public size$: Observable<ViewSize>;
  public unitsOnMapCurrentState$: Observable<ShowUnits>;
  public unitOnMapStates: ShowUnits[] = Object.values(ShowUnits);
  public weatherElementList: WeatherElement[] = Object.values(WeatherElement);
  public zoom$: Observable<number>;

  constructor(private geofenceManagerModeService: GeofenceManagerModeService,
              private mapSelectUnitService: MapSelectUnitService,
              private mapToolsService: MapToolsService,
              private measureManagerService: MeasureManagerService,
              private printMapToPdfService: PrintToPdfService,
              private tileLayerService: TileLayerService,
              private trackLayerService: TrackLayerService,
              private unitFilterService: UnitFilterService,
              private unitTraceService: UnitTraceService,
              private viewBreakpointService: ViewBreakpointService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['map'] && this.map) {
      this.mapToolsService.changeZoom(this.map.getView().getZoom());
      this.zoomInteraction();
    }
  }

  ngOnInit(): void {
    this.mapToolState$ = this.mapToolsService.state$;
    this.zoom$ = this.mapToolsService.zoom$.pipe(
      debounceTime(10), // for avoid error: Expression has changed after it was checked
    );
    this.currentTileName$ = this.tileLayerService.currentTileName$;
    this.currentWeatherElement$ = this.tileLayerService.currentWeatherElement$;
    this.unitsOnMapCurrentState$ = this.unitFilterService.unitsOnMapCurrentState$;
    this.size$ = this.viewBreakpointService.size$;
    this.unitTraceService.init();
    this.showCurrentTrace$ = this.unitTraceService.showTrace$;
    this.isShowZoomSlider = this.showZoomSlider(window.innerHeight);
    this.isTracking$ = this.mapSelectUnitService.isTracking$;
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.isShowZoomSlider = this.showZoomSlider(window.innerHeight);
  }

  private showZoomSlider(windowHeight: number): boolean {
    return windowHeight > 750;
  }

  public onToggleSearchAddress(): void {
    this.mapToolsService.toggleSearchAddress();
  }

  public onToggleTracking(): void {
    this.mapSelectUnitService.changeIsTracking();
  }

  public receiveMeasureTool(tool: GeometryType): void {
    this.currentMeasureTool = tool;
  }

  public onChangeStateMeasure(tool: GeometryType): void {
    this.mapToolsService.changeState(MapTool.MEASURING);
    this.currentMeasureTool = tool;
  }

  public onShowGeofenceManager(): void {
    this.mapToolsService.changeState(MapTool.GEOFENCE);
    this.geofenceManagerModeService.changeState(GeofenceMode.LIST);
  }

  public onChangeStateShowTrace(state: ShowTrace): void {
    this.unitTraceService.changeTrace(this.map, state);
  }

  public onChangeStateUnitsOnMap(value: ShowUnits): void {
    this.unitFilterService.changeUnitsOnMapState(value);
  }

  public onPrintPDF(): void {
    this.printMapToPdfService.printMap('empty-element-for-print', this.map, this.trackLayerService.instantTrack());
  }

  public onToggleShowViolations(): void {
    this.isShowOnMapViolations = !this.isShowOnMapViolations;
    this.changedShowViolations.emit(this.isShowOnMapViolations);
  }

  public onLayerChange(layerName: TileName): void {
    this.tileLayerService.changeLayer(layerName, this.map);
  }

  public onWeatherElementChange(element: WeatherElement = null): void {
    this.tileLayerService.changeWeatherElement(element, this.map);
  }

  public onChangeStepZoom(step: number): void {
    const zoom = this.mapToolsService.instantZoom() + step;
    this.map.getView().setZoom(zoom);
    this.mapToolsService.changeZoom(zoom);
  }

  public onZoom(zoom: number): void {
    this.map.getView().setZoom(zoom);
    this.mapToolsService.changeZoom(zoom);
  }

  private zoomInteraction(): void {
    this.map.on('moveend', () => {
      this.mapToolsService.changeZoom(Math.round(this.map.getView().getZoom()));
    });
  }
}
