import { BuildInfoTableData } from './build-info-table-data.model';
import { MetaTableData } from './meta-table-data.model';
import { Row } from './row.model';
import {ReportChart} from "./report-chart.model";

export class Report {

  constructor(public id: number,
              public name: string,
              public nameDetails: string,
              public buildInfo: BuildInfoTableData,
              public startReportDatetime: string,
              public finishReportDatetime: string,
              public columnMetaNames: string[],
              public metaTableData: MetaTableData,
              public tableData: Row[],
              public totalRow: Row,
              public statisticTable: Report,
              public reportCharts: ReportChart[]) {
  }

  public static valueOf(i: Report): Report {
    return i ? new Report(i.id, i.name, i.nameDetails, BuildInfoTableData.valueOf(i.buildInfo),
      i.startReportDatetime, i.finishReportDatetime, i.columnMetaNames, MetaTableData.valueOf(i.metaTableData),
      Row.valuesOf(i.tableData), Row.valueOf(i.totalRow), Report.valueOf(i.statisticTable), ReportChart.valuesOf(i.reportCharts)) : null;
  }

  public static valuesOf(list: Report[]): Report[] {
    return list ? list.map(o => Report.valueOf(o)) : [];
  }

  public getUnitName(): string {
    return this.buildInfo.units[0].name;
  }

  public isEmpty(): boolean {
    return this.tableData.length === 0;
  }

  public getColumnTitle(column: string): string {
    return `${this.getColumnLocaleName(column)}${this.getColumnUOM(column).length === 0 ? '' : ','} ${this.getColumnUOM(column)}`;
  }

  private getColumnLocaleName(columnName: string): string {
    return this.metaTableData.tableMetaData.get(columnName).columnNameLocale;
  }

  private getColumnUOM(columnName: string): string {
    if (this.metaTableData.tableMetaData.get(columnName).uom !== 'BLANK') {
      return this.metaTableData.tableMetaData.get(columnName).uom;
    } else {
      return '';
    }
  }

  public getColumnTotalValue(columnName: string): string {
    return this.totalRow.row.get(columnName);
  }

  public getIsBoldRow(index: number): boolean {
    return this.tableData[index].bold;
  }

  public getReportDataByIndex(index: number): Map<string, string> {
    return this.tableData[index].row;
  }

  public getRow(index: number): Row {
    return this.tableData[index];
  }
}
