import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {MatDialog} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {takeUntil} from "rxjs/operators";
import {AppMessageService} from "../../../../../../../common-module/src/lib/app-services/app-message.service";
import {UiSpinnerService} from "../../../../../../../common-module/src/lib/app-services/ui-spinner.service";
import {GpsOdometer} from '../../../../../../../common-module/src/lib/modelinterfaces/gps-odometer.model';
import {GpsOdometerService} from "../../../../../../../common-module/src/lib/services/gps-odometer.service";
import {SensorUtils} from "../../../../../../../common-module/src/lib/utils/sensor-utils";
import {UnitDataService} from "../../../../shared/services/unit-data.service";
import {VehicleManagerService} from "../../../../shared/services/vehicle-manager.service";

enum Control {
  MILEAGE_FACTOR = 'mileageFactor',
  VEHICLE_ODOMETER = 'vehicleOdometer'
}

@Component({
  selector: 'app-odometer-form',
  templateUrl: './odometer-form.component.html',
  styleUrls: ['../../../../../../../common-module/src/lib/app-styles/dialog-common.scss']
})

export class OdometerFormComponent implements OnChanges, OnInit, OnDestroy {

  private readonly MIN_MILEAGE_FACTOR = 0.95;
  private readonly MAX_MILEAGE_FACTOR = 1.05;

  @Input() odometer: GpsOdometer;
  @Input() unitId: number;

  public control = Control;
  public editForm: UntypedFormGroup;

  private destroyed = new Subject<boolean>();

  constructor(private appMessageService: AppMessageService,
              private dialog: MatDialog,
              private gpsOdometerService: GpsOdometerService,
              private uiSpinnerService: UiSpinnerService,
              private unitDataService: UnitDataService,
              private vehicleManagerService: VehicleManagerService) {
  }

  get formControl(): { [p: string]: AbstractControl } {
    return this.editForm.controls;
  }

  get formValue(): any {
    return this.editForm.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['unitId'] && this.unitId) {
      this.buildForm();
    }
  }

  ngOnInit(): void {
    this.vehicleManagerService.isCancelForms$
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        isCancel => {
          if (isCancel && this.vehicleManagerService.instantTabSelectedIndex() === 3) {
            this.buildForm();
          }
        }
      )

    this.vehicleManagerService.isSubmitForms$
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        isSubmit => {
          if (isSubmit && this.vehicleManagerService.instantTabSelectedIndex() === 3) {
            this.onSubmit();
          }
        }
      )
  }

  private buildForm(): void {
    this.editForm = new UntypedFormGroup({
      [Control.MILEAGE_FACTOR]: new UntypedFormControl(this.odometer.gpsOdometerSettings.mileageFactor,
        [Validators.required, Validators.min(this.MIN_MILEAGE_FACTOR), Validators.max(this.MAX_MILEAGE_FACTOR)]),
      [Control.VEHICLE_ODOMETER]: new UntypedFormControl(SensorUtils.toValue(this.odometer.corrected, 2),
        [Validators.min(0)]
      )
    });
    this.formControl[Control.MILEAGE_FACTOR].disable();
    this.editForm.valueChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.vehicleManagerService.changeForms([this.editForm]);
      });
    this.vehicleManagerService.changeForms([this.editForm]);
  }

  public onSubmit(): void {
    if (this.formControl[Control.MILEAGE_FACTOR].dirty) {
      this.updateMileageFactor();
    } else {
      this.updateOdometerValue();
    }
  }

  private updateMileageFactor(): void {
    this.uiSpinnerService.show();
    this.gpsOdometerService.updateFactor(this.unitId, this.formValue[Control.MILEAGE_FACTOR]).subscribe(
      odometer => {
        this.uiSpinnerService.stop();
        if (this.formControl[Control.VEHICLE_ODOMETER].dirty) {
          this.updateOdometerValue();
        } else {
          this.resultHandling(odometer);
        }
      }
    );
  }

  private updateOdometerValue(): void {
    this.uiSpinnerService.show();
    this.gpsOdometerService.updateOdometerValue(this.unitId, this.formValue[Control.VEHICLE_ODOMETER]).subscribe(
      odometer => {
        this.uiSpinnerService.stop();
        this.resultHandling(odometer);
      }
    );
  }

  private resultHandling(odometer: GpsOdometer): void {
    this.appMessageService.openSnackBar('message.info.changes-saved');
    this.unitDataService.changeGpsOdometer(this.unitId, odometer);
    this.editForm.markAsPristine();
    this.vehicleManagerService.changeForms([this.editForm]);
  }

  ngOnDestroy(): void {
    this.destroyed.next(null);
    this.destroyed.complete();
  }
}
