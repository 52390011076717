import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TileLayerService} from '../../../../shared/services/tile-layer.service';


@Component({
  selector: 'app-map-conditions',
  templateUrl: './map-conditions.component.html',
  styleUrls: ['./map-conditions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MapConditionsComponent implements OnInit {

  public readonly OL_URL = 'https://openlayers.org/';

  public name$: Observable<string>;
  public url$: Observable<string>;

  constructor(private tileLayerService: TileLayerService) {
  }

  ngOnInit(): void {
    this.name$ = this.tileLayerService.currentTileName$.pipe(
      map(tileName => this.tileLayerService.description(tileName)?.fullName)
    );
    this.url$ = this.tileLayerService.currentTileName$.pipe(
      map(tileName => this.tileLayerService.description(tileName)?.url)
    );
  }
}
