<mat-toolbar class="footer" [ngClass]="{footer_le_l: (size$ | async).less('l')}">
  <div class="footer-buttons" *ngIf="(size$ | async).less('l')">
    <button mat-button
            [ngClass]="(isSideNavOpen$ | async)? 'app-button stroked white-primary' : 'app-button primary'"
            (click)="toggleSidenav()">
      <mat-icon>view_list</mat-icon>
    </button>
    <button mat-button
            *ngIf="(tab$ | async) !== tabName.PUBLIC"
            (click)="openToolbarDialog()"
            [ngClass]="isToolbarOpen ? 'app-button stroked white-primary': 'app-button primary'">
      <mat-icon>build</mat-icon>
    </button>
    <button mat-button
            *ngIf="(tab$ | async) !== tabName.PUBLIC"
            [ngClass]="isUnitInfoOpen ? 'app-button stroked white-primary': 'app-button primary'"
            [disabled]="!lastSelectedUnit"
            (click)="openUnitInfoDialog()">
      <mat-icon>info</mat-icon>
    </button>
  </div>
</mat-toolbar>
