<app-dialog [title]="'titles.edit-unit'|translate">

  <app-unit-view-form appDialogContent class="flex flex-justify-center"
                      [unit]="data.unit">
  </app-unit-view-form>

  <app-dialog-actions-cancel-save appDialogActions
                                  [saveDisable]="!(form$ | async) || !(form$ | async).dirty || !(form$ | async).valid"
                                  (save)="onSave()"
                                  (cancel)="onCancel()">
  </app-dialog-actions-cancel-save>

</app-dialog>
