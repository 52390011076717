<app-dialog [title]="'titles.select-sensors-to-generate-chart' | translate">

  <ng-container appDialogStickyHeader>
    <div class="header-container mat-typography">
      <div>
        {{ 'message.info.selected-count' | translate: {count: '(' + selectedSensors.length + ')'} }}
      </div>
      <mat-checkbox (click)="toggleAllSelection()" labelPosition="before"
                    [checked]="selectionAllState === 'all'"
                    [indeterminate]="selectionAllState === 'intermediate'">
        {{ 'action.select-all' | translate }}:
      </mat-checkbox>
    </div>
  </ng-container>

  <ng-container appDialogContent>

    <app-sensor-list #list
                     [(selectedSensors)]="selectedSensors"
                     (selectedSensorsChange)="onSelectedSensorsChange($event)"
                     (selectionStateChange)="onSelectionStateChange($event)"
                     [multiple]="true"
                     [sensors]="data.sensors">
    </app-sensor-list>

  </ng-container>

  <ng-container appDialogActions>

    <app-button-cancel (cancel)="onCancel()">
    </app-button-cancel>

    <app-button-primary (action)="onSubmit()"
                        [disabled]="this.selectedSensors.length === 0"
                        [text]="'action.build' | translate">
    </app-button-primary>
  </ng-container>

</app-dialog>

