import {
  CompositeFeatureWrapper
} from "../../../../../aur-map-creator/feature/core/app/feature-wrapper/composite.feature-wrapper";
import {AbstractJobFeatureWrapper} from "../abstract-job-feature.wrapper";
import {AppColor} from "../../../../../../../../../common-module/src/lib/app-enums/app-color";
import {Logistic} from "../../../../../../../../../common-module/src/lib/modelinterfaces/logistic.model";
import Job = Logistic.Job;

export class AbstractJobCompositeFeatureWrapper extends CompositeFeatureWrapper<AbstractJobFeatureWrapper<any, any, any>> {

  private _isSelected = false;

  private _isHighlighted = false;

  constructor(private _job: Job, wrappers: AbstractJobFeatureWrapper<any, any, any>[]) {
    super(wrappers);
  }

  public getJob(): Job {
    return this._job;
  }

  public isSelected(): boolean {
    return this._isSelected;
  }

  public isHighlighted(): boolean {
    return this._isHighlighted;
  }

  public highlight(): void {
    if (!this.isHighlighted()) {
      this.getWrappers().forEach(w => w.highlight({fill: AppColor.INDIGO, lineWidth: 4, zIndex: 5000}))
      this._isHighlighted = true;
    }
  }

  public unHighlight(): void {
    if (this.isHighlighted()) {
      this.getWrappers().forEach(w => w.unHighlight())
      this._isHighlighted = false;
    }
  }

  public select() {
    if (!this.isSelected()) {
      this.getWrappers().forEach(w => w.select({fill: AppColor.ACCENT, iconColor: AppColor.WHITE, lineWidth: 1.5}))
      this._isSelected = true;
    }
  }

  public deselect() {
    if (this.isSelected()) {
      this.getWrappers().forEach(w => w.unSelect())
      this._isSelected = false;
    }
  }
}
