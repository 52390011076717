import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {TileLayerService} from '../../../../../shared/services/tile-layer.service';
import {WeatherElement} from '../../../../../shared/constants/enums/weather-element';

@Component({
  selector: 'app-weather-legend',
  templateUrl: './weather-legend.component.html',
  styleUrls: ['./weather-legend.component.scss']
})

export class WeatherLegendComponent implements OnInit, OnDestroy {

  public readonly ELEMENT_VALUES_MAP = new Map<WeatherElement, number[]>([
    [WeatherElement.TEMPERATURE, [-40, -20, 0, 10, 20, 30]],
    [WeatherElement.PRECIPITATION, [0, 0.2, 0.5, 1, 10, 50]],
    [WeatherElement.WIND, [1, 2.5, 5, 15, 25]],
    [WeatherElement.PRESSURE, [940, 980, 1010, 1040, 1080]],
    [WeatherElement.CLOUDY, [0, 20, 40, 60, 80, 100]]
  ]);

  public readonly ELEMENT_UNIT_OF_MEASURE_MAP = new Map<WeatherElement, string>([
    [WeatherElement.TEMPERATURE, 'c'],
    [WeatherElement.PRECIPITATION, 'mm-h'],
    [WeatherElement.WIND, 'm-s'],
    [WeatherElement.PRESSURE, 'hpa'],
    [WeatherElement.CLOUDY, '%']
  ]);

  public currentElement: WeatherElement;
  public currentUom: string;
  public values: number[];
  public weatherElement = WeatherElement;

  private subscription: Subscription;

  constructor(private tileLayerService: TileLayerService) {
  }

  ngOnInit(): void {
    this.subscription = this.tileLayerService.currentWeatherElement$.subscribe(el => {
      this.currentElement = el;
      this.values = this.ELEMENT_VALUES_MAP.get(this.currentElement);
      this.currentUom = this.ELEMENT_UNIT_OF_MEASURE_MAP.get(this.currentElement);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
