import {Injectable} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Tab} from 'projects/common-module/src/lib/app-enums/tab';
import {BehaviorSubject, Observable, Subject, Subscription, timer} from 'rxjs';
import {filter, map, take, takeUntil} from 'rxjs/operators';
import {LocalStorageKey} from '../../../../../common-module/src/lib/app-enums/local-storage-key';
import {AppMessageService} from '../../../../../common-module/src/lib/app-services/app-message.service';
import {PageParam} from "../../../../../common-module/src/lib/modelinterfaces/enums/page-param";
import { UnitShort } from '../../../../../common-module/src/lib/modelinterfaces/unit-short.model';
import {CameraService} from '../../../../../common-module/src/lib/services/camera.service';
import {ContentTypeService} from '../../../../../common-module/src/lib/services/content-type.service';
import {DvrFileService} from '../../../../../common-module/src/lib/services/dvr-file.service';
import {environment} from '../../../environments/environment';
import {VideoTab} from '../constants/enums/video-tab';
import {Camera} from '../../../../../common-module/src/lib/modelinterfaces/camera.model';
import {ContentType} from '../../../../../common-module/src/lib/modelinterfaces/content-type.model';
import {DvrFile} from '../../../../../common-module/src/lib/modelinterfaces/dvr-file.model';
import {DvrFilters} from '../../../../../common-module/src/lib/app-models/dvr-filters.models';
import {SelectedService} from './selected.service';
import {UnitWithCamerasBiDvrService} from './unit-with-cameras-Bi-Dvr.service';

@Injectable({
  providedIn: 'root'
})

export class VideoService {

  public readonly PAGE_SIZE_LIST = [5, 15, 20, 25, 30];
  public readonly DEFAULT_PAGE_SIZE = this.PAGE_SIZE_LIST[2];
  public readonly DEFAULT_SORT = '-time';

  private readonly BASE_URL = environment.BASE_URL.BASE + environment.BASE_URL.DVR;
  private readonly LOAD_FILES_TIMER = 60000;

  private _cameraListSource = new BehaviorSubject<Camera[]>([]);
  private _cameraList$ = this._cameraListSource.asObservable();

  private _contentListSource: BehaviorSubject<DvrFile[]> = new BehaviorSubject(null);
  private _contentList$: Observable<DvrFile[]> = this._contentListSource.asObservable();

  private _contentTypesSource = new BehaviorSubject<ContentType[]>([]);
  private _contentTypeList$ = this._contentTypesSource.asObservable();

  private _currentPageSource = new BehaviorSubject<number>(0);
  private _currentPage$ = this._currentPageSource.asObservable();

  private _downloadUrlSource = new BehaviorSubject<string>('');
  private _downloadUrl$ = this._downloadUrlSource.asObservable();

  private _dvrFiltersSource = new DvrFilters();

  private _isCurrentFilters = new BehaviorSubject<boolean>(false);
  private _isCurrentFilters$ = this._isCurrentFilters.asObservable();

  private _isSeparateWindowSource = new BehaviorSubject<boolean>(false);
  private _isSeparateWindow$ = this._isSeparateWindowSource.asObservable();

  private _loading = new BehaviorSubject<boolean>(false);
  private _loading$ = this._loading.asObservable();

  private _showUrlSource = new BehaviorSubject<string>('');
  private _showUrl$ = this._showUrlSource.asObservable();

  private _selectedCard = new BehaviorSubject<DvrFile>(null);
  private _selectedCard$ = this._selectedCard.asObservable();

  private _selectedCameraIdSet = new BehaviorSubject(new Set<number>());
  private _selectedCameraIdSet$: Observable<Set<number>> = this._selectedCameraIdSet.asObservable();

  private _totalPageElementsSource = new BehaviorSubject<number>(0);
  private _totalElements$ = this._totalPageElementsSource.asObservable();

  private currentVideoTab: VideoTab = VideoTab.CONTENT;
  private lastSelectedUnit: UnitShort;
  private pageParams = {
    [PageParam.PAGE]: this._currentPageSource.getValue(),
    [PageParam.SIZE]: this.PAGE_SIZE_LIST[2],
    [PageParam.SORT]: this.DEFAULT_SORT
  }
  private queryParamsMap: ParamMap;

  private destroyed = new Subject<boolean>();
  private selectedUnitSubscription: Subscription;
  private subscriptionContentList: Subscription;

  constructor(private appMessageService: AppMessageService,
              private cameraService: CameraService,
              private contentTypeService: ContentTypeService,
              private dvrFileService: DvrFileService,
              private route: ActivatedRoute,
              private router: Router,
              private unitWithCamerasBiDvrService: UnitWithCamerasBiDvrService,
              private selectedService: SelectedService,
              private translateService: TranslateService) {
  }

  get cameraList$(): Observable<Camera[]> {
    return this._cameraList$;
  }

  get contentTypeList$(): Observable<ContentType[]> {
    return this._contentTypeList$;
  }

  get contentList$(): Observable<DvrFile[]> {
    return this._contentList$;
  }

  get currentPage$(): Observable<number> {
    return this._currentPage$;
  }

  get totalElements$(): Observable<number> {
    return this._totalElements$;
  }

  get selectedCard$(): Observable<DvrFile> {
    return this._selectedCard$;
  }

  get selectedCameraIdSet$(): Observable<Set<number>> {
    return this._selectedCameraIdSet$;
  }

  get downloadUrl$(): Observable<string> {
    return this._downloadUrl$;
  }

  get showUrl$(): Observable<string> {
    return this._showUrl$;
  }

  get loading$(): Observable<boolean> {
    return this._loading$;
  }

  get isCurrentFilters$(): Observable<boolean> {
    return this._isCurrentFilters$;
  }

  get isSeparateWindow$(): Observable<boolean> {
    return this._isSeparateWindow$;
  }

  public init(): void {
    this.destroyed = new Subject<boolean>();
    this.pageParams[PageParam.PAGE] = this._currentPageSource.getValue();
    this.getQueryParams();
    this.getContentTypes();
    this.getLastSelectedUnit();
    this.checkUnitList();
  }

  private getQueryParams(): void {
    this.route.queryParamMap.pipe(
      takeUntil(this.destroyed)
    ).subscribe((paramMap) => {
      this.queryParamsMap = paramMap;
      this.getContentListByInterval();
    });
  }

  public getContentTypes(): void {
    if (this._contentTypesSource.getValue().length > 0) {
      return;
    }
    this.contentTypeService.getList(this.translateService.currentLang).subscribe(list => this._contentTypesSource.next(list));
  }

  private getLastSelectedUnit(): void {
    if (this.selectedUnitSubscription && !this.selectedUnitSubscription?.closed) {
      return;
    }
    this.selectedUnitSubscription = this.selectedService.lastSelectedUnit$.subscribe(unit => {
      this.unsubscribeGettingContentList();
      this.lastSelectedUnit = unit;
      if (this.lastSelectedUnit) {
        this.setDefaultQueryParamsPageIndex();
        this.getContentListByInterval();
        this.getCameraList();
      } else {
        this._contentListSource.next(null);
      }
    });
  }

  private getContentListByInterval(): void {
    if (this.queryParamsMap?.get(PageParam.PAGE) !== null && this.queryParamsMap?.get(PageParam.PAGE) !== undefined) {
      this._currentPageSource.next(Number(this.queryParamsMap.get(PageParam.PAGE)));
      this.getContentList();
      if (!this._isSeparateWindowSource.getValue() && !this.subscriptionContentList) {
        this.subscriptionContentList = timer(this.LOAD_FILES_TIMER, this.LOAD_FILES_TIMER).subscribe(() => this.getContentList());
      }
    }
  }

  private unsubscribeGettingContentList(): void {
    if (this.subscriptionContentList) {
      this.subscriptionContentList.unsubscribe();
    }
  }

  private getContentList(): void {
    this._contentListSource.next(null);
    if (!this.lastSelectedUnit?.id) {
      return;
    }
    this._loading.next(true);
    this.dvrFileService.getPage(this.lastSelectedUnit?.id, this.translateService.currentLang, this.queryParamsMap)
      .pipe(
        take(1),
        map(page => {
          this._totalPageElementsSource.next(page.totalElements);
          return page.content;
        })
      )
      .subscribe(
        content => {
          this._contentListSource.next(content);
          this._loading.next(false);
        },
        error => {
          this._loading.next(false);
          throw error;
        }
      );
  }

  public getCameraList(unitId: number = this.lastSelectedUnit.id): void {
    this.cameraService.getData(unitId).subscribe(list => {
        const cameraList = list.sort((a, b) => a.number - b.number);
        this._cameraListSource.next(cameraList);
      });
  }

  private checkUnitList(): void {
    this.unitWithCamerasBiDvrService.unitListWithCameras$.pipe(
      takeUntil(this.destroyed),
      filter(value => !!value)
    ).subscribe(list => {
      if (this.lastSelectedUnit && !list.some(el => el.id === this.lastSelectedUnit.id)) {
        this.appMessageService.openSnackBar('message.error.camera-not-found', false,
          {unitName: this.lastSelectedUnit.name});
      }
    });
  }

  public getInstantFilters(): DvrFilters {
    return this._dvrFiltersSource;
  }

  private setFilterParams(filters: DvrFilters): void {
    const clearedFilters = {};
    Object.keys(filters).forEach(key => {
      if (filters[key].value?.toString()) {
        clearedFilters[filters[key].param] = filters[key].paramValue?.toString();
      }
    });

    this.router.navigate(
      [], {
        relativeTo: this.route,
        queryParamsHandling: '',
        queryParams: Object.assign(clearedFilters, this.pageParams)
      }).then();
  }

  public changeFilters(filters: DvrFilters): void {
    this._isCurrentFilters.next(Object.values(filters || {}).some(el => !!el.value));
    this._dvrFiltersSource = filters;
    this.setFilterParams(filters);
  }

  private setDefaultQueryParamsPageIndex(): void {
    if (this._currentPageSource.getValue() === 0) {
      return;
    }
    this.router.navigate(
      [], {
        relativeTo: this.route,
        queryParamsHandling: 'merge',
        queryParams: {[PageParam.PAGE]: 0}
      }).then();
  }

  public changeVideoTab(tab: VideoTab = this.currentVideoTab): void {
    this.unsubscribeGettingContentList();
    this.currentVideoTab = tab;
    this.router.navigateByUrl(Tab.VIDEO + '/' + this.currentVideoTab).then();
    if (this.unitWithCamerasBiDvrService.getInstantErrorBiDvr()) {
      this.appMessageService.openSnackBar('message.error.load-data', true);
      return;
    }
  }

  public changeSelectedCard(card: DvrFile): void {
    card ? this.unsubscribeGettingContentList() : this.getContentListByInterval();
    this._selectedCard.next(card);
    if (card?.camera) {
      this._showUrlSource.next(this.createContentUrl(card, 'stream'));
      this._downloadUrlSource.next(this.createContentUrl(card, 'download'));
    } else {
      this._showUrlSource.next('');
      this._downloadUrlSource.next('');
    }
  }

  public changeSelectedCameras(id: number): void {
    const currentSelectedCameraIdSet = this._selectedCameraIdSet.getValue();
    if (currentSelectedCameraIdSet.has(id)) {
      currentSelectedCameraIdSet.delete(id);
    } else {
      currentSelectedCameraIdSet.add(id);
    }
    this._selectedCameraIdSet.next(currentSelectedCameraIdSet);
  }

  public openPlayerInSeparateWindow(isSeparate: boolean = true): void {
    this._isSeparateWindowSource.next(isSeparate);
  }

  private createContentUrl(card: DvrFile, type: string): string {
    return `${this.BASE_URL}/${type}/camera/${card.camera.id}/fileName/${card.name}${this.getToken()}`;
  }

  private getToken(): string {
    return `?authorization=Bearer ${window.localStorage.getItem(LocalStorageKey.BEARER_TOKEN)}`;
  }

  public downloadFile(card: DvrFile): void {
    if (card && card.camera) {
      const a = document.createElement('a');
      const url = this.createContentUrl(card, 'download');
      a.href = url;
      a.download = url.split('/').pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      this.appMessageService.openSnackBar('message.error.load-data', true);
    }
  }

  public instantSelectedCard(): DvrFile {
    return this._selectedCard.getValue();
  }

  public destroy(): void {
    this._cameraListSource.next([]);
    this._contentListSource.next(null);
    this._contentTypesSource.next([]);
    this._downloadUrlSource.next('');
    this._dvrFiltersSource = new DvrFilters();
    this._isCurrentFilters.next(false);
    this._loading.next(false);
    this._showUrlSource.next('');
    this._selectedCard.next(null);
    this._selectedCameraIdSet.next(new Set());
    this._totalPageElementsSource.next(0);
    this.lastSelectedUnit = null;
    this.destroyed.next(null);
    this.destroyed.complete();
    this.destroyed = null;
    this.selectedUnitSubscription.unsubscribe();
    this.unsubscribeGettingContentList();
  }
}
