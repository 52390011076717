export class Row {

  constructor(public row: Map<string, string>,
              public additionalData: string[],
              public noData: boolean,
              public bold: boolean,
              public unitId: number) {
    this.row = new Map();
    Object.keys(row).forEach(key => {
      this.row.set(key, row[key]);
    });
  }

  public static valueOf(i: Row): Row {
    return i ? new Row(i.row, i.additionalData, i.noData, i.bold, i.unitId) : null;
  }

  public static valuesOf(list: Row[]): Row[] {
    return list ? list.map(o => Row.valueOf(o)) : [];
  }
}
