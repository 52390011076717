import { Component, OnInit } from '@angular/core';
import { MatOptionSelectionChange } from '@angular/material/core';
import { Layer } from 'ol/layer';
import OlMap from 'ol/Map';
import { Observable } from 'rxjs';
import { Tab } from '../../../../../../../../common-module/src/lib/app-enums/tab';
import { ReportType } from '../../../../../../../../common-module/src/lib/modelinterfaces/enums/report-type';
import { Geofence } from '../../../../../../../../common-module/src/lib/modelinterfaces/geofence.model';
import { GeofenceService } from '../../../../../../../../common-module/src/lib/services/geofence.service';
import { MapSettings } from '../../../../../shared/constants/enums/map-settings';
import { DrawGeofenceService } from '../../../../../shared/services/draw-geofence.service';
import { GeofenceListService } from '../../../../../shared/services/geofence-list.service';
import { OpenLayerMapService } from '../../../../../shared/services/open-layer-map.service';
import { TileLayerService } from '../../../../../shared/services/tile-layer.service';

@Component({
  selector: 'app-select-geofences',
  templateUrl: './select-geofences.component.html',
  styleUrls: ['./select-geofences.component.scss']
})

export class SelectGeofencesComponent implements OnInit {

  public geofenceList$: Observable<Geofence[]> = this.geofenceListService.currentGeofences$;
  public map: OlMap;
  public reportType = ReportType;
  public searchGeofence: string;
  public selectedGeofenceList: Geofence[];

  constructor(private drawGeofenceService: DrawGeofenceService,
              private geofenceService: GeofenceService,
              private geofenceListService: GeofenceListService,
              private openLayerMapService: OpenLayerMapService,
              private tileLayerService: TileLayerService) {
  }

  ngOnInit(): void {
    this.createMap();
    this.geofenceListService.init();
    this.selectedGeofenceList = this.geofenceListService.getSelectedList(Tab.REPORT);
  }

  private createMap(): void {
    const layer: Layer[] = this.tileLayerService.buildCurrentSelectedTile();
    this.map = this.openLayerMapService.create(layer, 'map-report', MapSettings.DIALOG_ZOOM);

    setTimeout(() => {
      this.drawGeofenceService.init(this.map, Tab.REPORT);
    }, 0);
  }

  public comparisonId = (option: any, value: any): boolean => {
    if (!option || !value) {
      return false;
    }
    return option.id === value.id;
  };

  public filterGeofences(event: EventTarget): void {
    this.searchGeofence = (event as HTMLInputElement).value;
  }

  public clearFilterGeofences(): void {
    this.searchGeofence = '';
    this.selectedGeofenceList = this.geofenceListService.getSelectedList(Tab.REPORT);
  }

  public toggleGeofence(geofenceOptions: MatOptionSelectionChange): void {
    if (!this.map?.getTarget()) {
      return;
    }
    if (geofenceOptions.source.selected) {
      this.drawGeofenceService.show(geofenceOptions.source.value, this.map, Tab.REPORT);
    } else {
      this.drawGeofenceService.hide(geofenceOptions.source.value, Tab.REPORT);
    }
  }
}
