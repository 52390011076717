import { UnitVideoShort } from './unit-video-short.model';

export class Camera {

  constructor(
    public id: number,
    public number: number,
    public name: string,
    public description: string,
    public source: string,
    public syncTrackerFiles: boolean,
    public unit: UnitVideoShort) {
  }

  public static valueOf(o: Camera): Camera {
    return o ? new Camera(o.id, o.number, o.name, o.description, o.source, o.syncTrackerFiles, UnitVideoShort.valueOf(o.unit)) : null;
  }

  public static valuesOf(list: Camera[]): Camera[] {
    return list ? list.map(o => Camera.valueOf(o)) : [];
  }
}
