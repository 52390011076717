<form [formGroup]="mileageNormForm" *ngIf="mileageNormForm">
  <header class="dialog-title to-left">
    <span>{{ 'term.norm-consumption-mileage' | translate }}</span>
  </header>

  <h3>Внутри <a (click)="openScannedCityInfo()"
                class="text-primary-color pointer"
                matTooltip="Города и их границы">Беларуси</a> разделение пробег на город/загород происходит по геопозиции. За пределами страны —
    по скорости указанной в поле: <b>'{{ 'term.max-urban-threshold-speed' | translate }}'</b>.</h3>

  <div class="form-container vehicle">
    <div class="flex flex-gap8">
      <mat-form-field class="margin-top-8">
        <app-helper-info matIconSuffix [info]="'term.max-urban-threshold-speed-tooltip' | translate"></app-helper-info>
        <mat-label>{{ 'term.max-urban-threshold-speed' | translate }}, {{ 'uom.km-h' | translate }}</mat-label>
        <input matInput type="number" min="0"
               [formControlName]="MileageNormControl.URBAN_THRESHOLD_SPEED">
        <mat-error *ngIf="mileageFormControl[MileageNormControl.URBAN_THRESHOLD_SPEED].errors?.required">
          {{ 'message.error.required-field' | translate }}
        </mat-error>
        <mat-error *ngIf="mileageFormControl[MileageNormControl.URBAN_THRESHOLD_SPEED].errors?.min">
          {{ 'message.error.min-value' | translate : {min: 0} }}
        </mat-error>
      </mat-form-field>
    </div>

    <mat-divider></mat-divider>

    <div class="flex flex-gap8 margin-top-32 flex-direction-column">
      <mat-form-field>
        <mat-label>{{ 'term.winter-start-date' | translate }}</mat-label>
        <input matInput
               [matDatepicker]="asPicker(picker1)"
               [matDatepickerFilter]="dateFilter"
               (focus)="picker1.open()"
               (click)="picker1.open()"
               [formControlName]="MileageNormControl.WINTER_START"
               readonly>
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1 [calendarHeaderComponent]="datePickerHeader"></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'term.winter-end-date' | translate }}</mat-label>
        <input matInput
               [matDatepicker]="asPicker(picker2)"
               [matDatepickerFilter]="dateFilter"
               (focus)="picker2.open()"
               (click)="picker2.open()"
               [formControlName]="MileageNormControl.WINTER_END"
               readonly>
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2 [calendarHeaderComponent]="datePickerHeader"></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="flex flex-gap8 margin-top-32 flex-direction-column">
      <mat-icon class="text-blue">ac_unit</mat-icon>
      <mat-form-field>
        <mat-label>
          {{ 'term.norm-consumption-winter-urban' | translate }}
        </mat-label>
        <p matTextSuffix> {{ 'uom.l-100-km' | translate }} </p>
        <mat-icon matIconPrefix class="text-primary-color">location_city</mat-icon>
        <input matInput type="number" min="0"
               [formControlName]="MileageNormControl.WINTER_URBAN">
        <mat-error *ngIf="mileageFormControl[MileageNormControl.WINTER_URBAN].errors?.required">
          {{ 'message.error.required-field' | translate }}
        </mat-error>
        <mat-error *ngIf="mileageFormControl[MileageNormControl.WINTER_URBAN].errors?.pattern">
          {{ 'message.error.contains-only-digits-one-decimal' | translate }}
        </mat-error>
        <mat-error *ngIf="mileageFormControl[MileageNormControl.WINTER_URBAN].errors?.min">
          {{ 'message.error.min-value' | translate : {min: 0} }}
        </mat-error>
        <mat-error *ngIf="mileageFormControl[MileageNormControl.WINTER_URBAN].errors?.max">
          {{ 'message.error.max-value' | translate : {max: mileageFormControl.winterConsumptionMileage.errors?.max.max} }}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>
          {{ 'term.norm-consumption-winter-country' | translate }}
        </mat-label>
        <p matTextSuffix> {{ 'uom.l-100-km' | translate }} </p>
        <mat-icon matIconPrefix class="text-primary-color">landscape</mat-icon>
        <input matInput type="number" min="0"
               [formControlName]="MileageNormControl.WINTER_COUNTRY">
        <mat-error *ngIf="mileageFormControl[MileageNormControl.WINTER_COUNTRY].errors?.required">
          {{ 'message.error.required-field' | translate }}
        </mat-error>
        <mat-error *ngIf="mileageFormControl[MileageNormControl.WINTER_COUNTRY].errors?.pattern">
          {{ 'message.error.contains-only-digits-one-decimal' | translate }}
        </mat-error>
        <mat-error *ngIf="mileageFormControl[MileageNormControl.WINTER_COUNTRY].errors?.min">
          {{ 'message.error.min-value' | translate : {min: 0} }}
        </mat-error>
        <mat-error *ngIf="mileageFormControl[MileageNormControl.WINTER_COUNTRY].errors?.max">
          {{ 'message.error.max-value' | translate : {max: mileageFormControl.winterConsumptionMileage.errors?.max.max} }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="flex flex-gap8 margin-top-12 flex-direction-column">
      <mat-icon class="text-orange">sunny</mat-icon>
      <mat-form-field>
        <mat-label>
          {{ 'term.norm-consumption-summer-urban' | translate }}
        </mat-label>
        <p matTextSuffix> {{ 'uom.l-100-km' | translate }} </p>
        <mat-icon matIconPrefix class="text-primary-color">location_city</mat-icon>
        <input matInput type="number" min="0"
               [formControlName]="MileageNormControl.SUMMER_URBAN">
        <mat-error *ngIf="mileageFormControl[MileageNormControl.SUMMER_URBAN].errors?.required">
          {{ 'message.error.required-field' | translate }}
        </mat-error>
        <mat-error *ngIf="mileageFormControl[MileageNormControl.SUMMER_URBAN].errors?.pattern">
          {{ 'message.error.contains-only-digits-one-decimal' | translate }}
        </mat-error>
        <mat-error *ngIf="mileageFormControl[MileageNormControl.SUMMER_URBAN].errors?.min">
          {{ 'message.error.min-value' | translate : {min: 0} }}
        </mat-error>
        <mat-error *ngIf="mileageFormControl[MileageNormControl.SUMMER_URBAN].errors?.max">
          {{ 'message.error.max-value' | translate : {max: mileageFormControl.summerConsumptionMileage.errors?.max.max} }}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>
          {{ 'term.norm-consumption-summer-country' | translate }}
        </mat-label>
        <p matTextSuffix> {{ 'uom.l-100-km' | translate }} </p>
        <mat-icon matIconPrefix class="text-primary-color">landscape</mat-icon>
        <input matInput
               type="number"
               min="0"
               [formControlName]="MileageNormControl.SUMMER_COUNTRY">
        <mat-error *ngIf="mileageFormControl[MileageNormControl.SUMMER_COUNTRY].errors?.required">
          {{ 'message.error.required-field' | translate }}
        </mat-error>
        <mat-error *ngIf="mileageFormControl[MileageNormControl.SUMMER_COUNTRY].errors?.pattern">
          {{ 'message.error.contains-only-digits-one-decimal' | translate }}
        </mat-error>
        <mat-error *ngIf="mileageFormControl[MileageNormControl.SUMMER_COUNTRY].errors?.min">
          {{ 'message.error.min-value' | translate : {min: 0} }}
        </mat-error>
        <mat-error *ngIf="mileageFormControl[MileageNormControl.SUMMER_COUNTRY].errors?.max">
          {{ 'message.error.max-value' | translate : {max: mileageFormControl.summerConsumptionMileage.errors?.max.max} }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>

<form [formGroup]="modesForm" *ngIf="modesForm">
  <header class="dialog-title to-left">
    <span>{{ 'term.norm-consumption-hour' | translate }}</span>
  </header>

  <div class="form-container vehicle" formArrayName="modes">
    <div class="field" *ngFor="let control of getModesControls(); index as i">
      <div class="field-name">
        {{ mechanismModes[i]?.name }}
      </div>

      <mat-form-field>
        <mat-label>{{ 'term.norm-consumption' | translate }}, {{ 'uom.l-h' | translate }}</mat-label>
        <input matInput
               type="number"
               min="0"
               [formControlName]="i">
        <mat-error *ngIf="control.errors?.pattern">
          {{ 'message.error.contains-only-digits-one-decimal' | translate }}
        </mat-error>
        <mat-error *ngIf="control.errors?.min">
          {{ 'message.error.min-value' | translate : {min: 0} }}
        </mat-error>
        <mat-error *ngIf="control.errors?.max">
          {{ 'message.error.max-value' | translate : {max: control.errors.max.max} }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>

<ng-content></ng-content> <!-- project actionButtons -->
