import {Injectable} from '@angular/core';
import OlMap from 'ol/Map';
import {Layer} from 'ol/layer';

import {OlView} from '../map-utils/OlView';
import {OlCoordinate} from '../map-utils/OlCoordinate';
import {MapSettings} from '../constants/enums/map-settings';
import {AuthUserService} from '../../../../../common-module/src/lib/app-services/auth-user.service';


@Injectable({providedIn: 'root'})

export class OpenLayerMapService {

  constructor(private authUserService: AuthUserService) {
  }

  public create(layers: Layer[], target: string, zoom: number = null): OlMap {
    const mapCenter = this.authUserService.getInstantMapCenter();
    const coordinate = mapCenter ?
      OlCoordinate.create(mapCenter.longitude, mapCenter.latitude) : OlCoordinate.create(MapSettings.DEFAULT_LNG, MapSettings.DEFAULT_LAT);
    const scale = zoom ? zoom : mapCenter?.scale ? mapCenter.scale : MapSettings.DEFAULT_ZOOM;

    return new OlMap({
      controls: [],
      target: target,
      layers: layers,
      view: OlView.createView(
        coordinate,
        scale,
        MapSettings.MIN_ZOOM,
        MapSettings.MAX_ZOOM
      )
    });
  }
}
