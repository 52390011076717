<div class="container-measuring">
  <button
    type="button"
    class="buttons"
    matTooltip="{{ 'action.stop-measuring' | translate }}"
    *ngIf="(measureState$ | async) !== measureState.STOP"
    (click)="onChangeStateMeasure(measureState.STOP)"
  >
    <mat-icon>{{ measureState.STOP }}</mat-icon>
  </button>

  <button
    type="button"
    class="buttons"
    matTooltip="{{ 'action.continue-measuring' | translate }}"
    *ngIf="(measureState$ | async) === measureState.STOP"
    (click)="onChangeStateMeasure(currentMeasureTool)"
  >
    <mat-icon>play_arrow</mat-icon>
  </button>

  <button
    type="button" class="buttons"
    matTooltip="{{ 'action.clear-map' | translate }}"
    (click)="onCloseMeasuring()"
  >
    <mat-icon>cancel</mat-icon>
  </button>
</div>
