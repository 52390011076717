import {ArrowStyleFactory, OlArrowOpts} from "./arrow-style.factory";
import {GpsCoordinate} from "../../../../../../../common-module/src/lib/modelinterfaces/gps-coordinate.model";
import {ArrowFeatureWrapper} from "./arrow.feature-wrapper";
import {OlCoordinate} from "../../../map-utils/OlCoordinate";
import LineString from "ol/geom/LineString";
import {Feature} from "ol";

export class ArrowFeatureWrapperFactory {

  constructor(public readonly arrowStyleFactory: ArrowStyleFactory) {
  }

  public create(start: GpsCoordinate, end: GpsCoordinate, opts: OlArrowOpts): ArrowFeatureWrapper {
    const olStart = OlCoordinate.createFromGpsCoordinate(start);
    const olEnd = OlCoordinate.createFromGpsCoordinate(end);
    const lineString = new LineString([olStart, olEnd]);
    const olFeature = new Feature<LineString>(lineString);
    return new ArrowFeatureWrapper(olFeature, this.arrowStyleFactory, opts);
  }
}
