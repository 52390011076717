import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { AppMessageService } from '../../../../../../common-module/src/lib/app-services/app-message.service';
import { UiSpinnerService } from '../../../../../../common-module/src/lib/app-services/ui-spinner.service';
import { PublicAccess } from '../../../../../../common-module/src/lib/modelinterfaces/public-access.model';
import { User } from '../../../../../../common-module/src/lib/modelinterfaces/user.model';
import { PublicAccessService } from '../../../../../../common-module/src/lib/services/public-access.service';
import { AuthUserService } from '../../../../../../common-module/src/lib/app-services/auth-user.service';


@Component({
  selector: 'app-public-links-dialog',
  templateUrl: './public-links-dialog.component.html',
  styleUrls: ['./public-links-dialog.component.scss', '../../../../../../common-module/src/lib/app-styles/dialog-common.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})

export class PublicLinksDialogComponent implements OnInit {

  public readonly displayedColumns = ['arrow', 'term.link', 'message.tooltip.link-expiration', 'term.user', 'actions'];

  public authUser$: Observable<User>;
  public currentId: number;
  public dataSource: MatTableDataSource<PublicAccess>;
  public loading = false;

  private publicAccessList: PublicAccess[] = [];

  constructor(private appMessageService: AppMessageService,
              private authUserService: AuthUserService,
              private dialogRef: MatDialogRef<PublicLinksDialogComponent>,
              private publicAccessService: PublicAccessService,
              private uiSpinnerService: UiSpinnerService) {
  }

  ngOnInit(): void {
    this.authUser$ = this.authUserService.currentUser$;
    this.uiSpinnerService.show();
    this.loading = true;
    this.publicAccessService.getList().subscribe(list => {
        this.publicAccessList = list;
        if (this.publicAccessList.length > 0) {
          this.dataSource = new MatTableDataSource<PublicAccess>(this.publicAccessList);
        } else {
          this.appMessageService.openSnackBar('message.error.no-data', false);
        }
        this.loading = false;
        this.uiSpinnerService.stop();
      },
      error => {
        this.loading = false;
        throw error;
      });
  }

  public toggleShowUnits(id: number): void {
    if (this.currentId === id) {
      this.currentId = null;
      return;
    }
    this.currentId = id;
  }

  public onFollowLink(settings: PublicAccess): void {
    if (this.isExpiredLink(settings.expirationDate)) {
      return;
    }
    window.open(settings.url);
  }

  public copyToClipboard(url: string): void {
    this.appMessageService.copyToClipBoard(url);
  }

  public onDeleteLink(linkId: number): void {
    this.uiSpinnerService.show();
    this.publicAccessService.delete(linkId).subscribe(() => {
        this.publicAccessList = this.publicAccessList.filter(settings => settings.id !== linkId);
        this.dataSource = new MatTableDataSource<PublicAccess>(this.publicAccessList);
        this.dataSource.connect().next(this.dataSource.data.filter(settings => settings.id !== linkId));
        this.appMessageService.openSnackBar('message.info.changes-saved', false);
        this.uiSpinnerService.stop();
      });
  }

  public isExpiredLink(date: string): boolean {
    return Date.parse(date) < Date.now();
  }

  public asSettings(settings: PublicAccess): PublicAccess {
    return settings;
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}
