export class ReportChart {
  constructor(public title: string,
              public charts: object[]) {
  }

  public static valueOf(i: ReportChart): ReportChart {
    return new ReportChart(i.title, i.charts);
  }

  public static valuesOf(i: ReportChart[]): ReportChart[] {
    return i.map(r => this.valueOf(r));
  }
}
