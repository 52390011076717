import {DateConverter} from '../utils/date-converter';
import {BuildOptions} from './build-options.model';
import {Mileage} from './mileage.model';
import {Parking} from './parking.model';
import {SensorDataStorage} from './sensor-data-storage.model';
import {SensorData} from './sensor-data.model';
import {Trip} from './trip.model';
import {LineString} from "ol/geom";
import {BoundBoxed} from "./bound-boxed.model";

export interface SensorDataListWithTripIndex {
  sensorDataList: SensorData[];
  startTrip: number;
}

export class Track implements BoundBoxed {
  public boundBox: LineString;
  constructor(public buildOptions: BuildOptions,
              public mileage: Mileage,
              public parkings: Parking[],
              public trips: Trip[],
              public storage: SensorDataStorage) {
    this.boundBox = this.storage?.boundBox;
  }

  public static valueOf(i: Track): Track {
    return i ? new Track(BuildOptions.valueOf(i.buildOptions), Mileage.valueOf(i.mileage), Parking.valuesOf(i.parkings),
      Trip.valuesOf(i.trips), SensorDataStorage.valueOf(i.storage)) : null;
  }

  // use fromDate time to avoid empty spaces between start of new trip and finish of previous trip
  public getSensorDataListForTrip(trip: Trip, fromDatetime?: string): SensorDataListWithTripIndex {
    if (!fromDatetime) {
      fromDatetime = trip.startDatetime;
    }
    const startTripIndex = this.searchIndexByDatetime(fromDatetime) || 0;
    let finishTripIndex = this.searchIndexByDatetime(trip.finishDatetime);
    if (finishTripIndex < this.storage.sensorDataList.length) {
      finishTripIndex++;
    }
    return {
      sensorDataList: this.storage.getPartOfSensorDataList(startTripIndex, finishTripIndex),
      startTrip: startTripIndex,
    };
  }

  private searchIndexByDatetime(datetime: string): number {
    return this.storage.searchIndexByDatetime(datetime);
  }

  public getUnitName(): string {
    return this.buildOptions.unit.name;
  }

  public getStartTrackDatetimeTZ(tz: string): string {
    return DateConverter.utcDateTimeToTzDateString(this.buildOptions.startTime, tz);
  }

  public getFinishTrackDatetimeTZ(tz: string): string {
    return DateConverter.utcDateTimeToTzDateString(this.buildOptions.finishTime, tz);
  }
}
