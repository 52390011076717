<div class="flex flex-v-center flex-gap12">

  <app-unit-status-icon [timeZone]="timeZone"
                        [unit]="unit"
                        [unitState]="unitState"
                        [activeNotificationCount]="unreadNotificationCount"
                        [highlight]="highlight">
  </app-unit-status-icon>

  <div class="flex flex-direction-column">

    <div class="title flex flex-v-center" [ngClass]="{'title-highlighted': highlight}">
      <mat-icon [style.color]="unit.color" class="title-icon opacity50"
                [ngClass]="{'clear-opacity': highlight}"
                *ngIf="unit.color !== DEFAULT_COLOR">
        bookmark
      </mat-icon>
      <mat-icon *ngIf="unitGroupView"
                [style.color]="unitGroupView.groupColor" class="title-icon opacity50"
                [ngClass]="{'clear-opacity': highlight}"
                [matTooltip]="'Группа: ' + unitGroupView.groupName"
                [matTooltipShowDelay]="700">
        label
      </mat-icon>
      <span>{{ unit.name }}</span>
    </div>

    <app-short-unit-state-info [unitState]="unitState"
                               [highlight]="highlight">
    </app-short-unit-state-info>
  </div>
</div>
