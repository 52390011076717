import {Feature} from "ol";
import {Point} from "ol/geom";
import {OlCoordinate} from "../../../../../map-utils/OlCoordinate";
import {GpsCoordinate} from "../../../../../../../../../common-module/src/lib/modelinterfaces/gps-coordinate.model";

export class OlFeaturePointFactory {

  public static create(gpsCoordinate: GpsCoordinate): Feature<Point> {
    const olCoordinate = OlCoordinate.createFromGpsCoordinate(gpsCoordinate);
    const point = new Point(olCoordinate);
    return new Feature<Point>({
      geometry: point,
    });
  }
}
