<div class="map-buttons-container" id="map-buttons-container">
  <button class="buttons"
          *ngIf="isShowButtons"
          (click)="onToggleSearchAddress()"
          matTooltipPosition="right"
          [matTooltip]="'action.search' | translate">
    <mat-icon>search</mat-icon>
  </button>

  <button class="buttons"
          *ngIf="isShowButtons"
          [matMenuTriggerFor]="mapMenu"
          matTooltipPosition="right"
          [matTooltip]="'message.tooltip.displaying-units-on-the-map' | translate"
          [ngClass]="{'accent': (unitsOnMapCurrentState$ | async) !== showUnits.ALL
                      || (showCurrentTrace$ | async) !== showTrace.NO}">
    <mat-icon>settings</mat-icon>
  </button>

  <mat-menu #mapMenu="matMenu">
    <button mat-menu-item [matMenuTriggerFor]="showUnitsOnMap">
      <span>{{ 'message.tooltip.displaying-units-on-the-map' | translate }}</span>
    </button>

    <button mat-menu-item [matMenuTriggerFor]="showTraceMenu">
      <span>{{ 'term.unit-trace' | translate }}</span>
    </button>

    <button mat-menu-item (click)="onToggleTracking()">
      <mat-icon *ngIf="isTracking$ | async">check</mat-icon>
      <span>{{ 'term.tracking-selected-units' | translate }}</span>
    </button>
  </mat-menu>

  <mat-menu #showUnitsOnMap="matMenu">
    <button mat-menu-item
            *ngFor="let state of unitOnMapStates"
            (click)="onChangeStateUnitsOnMap(state)">
      <mat-icon *ngIf="state === (unitsOnMapCurrentState$ | async)">check</mat-icon>
      <span>{{ 'filter.' + state | translate }}</span>
    </button>
  </mat-menu>

  <mat-menu #showTraceMenu="matMenu">
    <button mat-menu-item
            *ngFor="let stateShowTrace of showTraceList"
            (click)="onChangeStateShowTrace(stateShowTrace)">
      <mat-icon *ngIf="stateShowTrace === (showCurrentTrace$ | async)">check</mat-icon>
      <span>{{ 'filter.' + stateShowTrace | translate }}</span>
    </button>
  </mat-menu>

  <div class="box-measure" *ngIf="isShowButtons">
    <button class="buttons"
            matTooltipPosition="right"
            [matTooltip]="'action.take-measuring' | translate"
            [matMenuTriggerFor]="measuring">
      <mat-icon [class.straighten]="currentMeasureTool === geometryType.LINE_STRING">
        {{ MEASURE_ICON_MAP.get(currentMeasureTool) }}
      </mat-icon>

    </button>

    <app-measure-manager *ngIf="(mapToolState$ | async) === mapTool.MEASURING"
                         [currentMeasureTool]="currentMeasureTool"
                         [map]="map"
                         (changeCurrentMeasureTool)="receiveMeasureTool($event)">
    </app-measure-manager>
  </div>

  <mat-menu #measuring="matMenu">
    <button mat-menu-item (click)="onChangeStateMeasure(geometryType.LINE_STRING)">
      <mat-icon class="straighten">{{ MEASURE_ICON_MAP.get(geometryType.LINE_STRING) }}</mat-icon>
      <span>{{ 'term.length' | translate }}</span>
    </button>

    <button mat-menu-item (click)="onChangeStateMeasure(geometryType.POLYGON)">
      <mat-icon>{{ MEASURE_ICON_MAP.get(geometryType.POLYGON) }}</mat-icon>
      <span>{{ 'term.area' | translate }}</span>
    </button>
  </mat-menu>

  <button class="buttons" *ngIf="isShowButtons" (click)="onPrintPDF()">
    <mat-icon>print</mat-icon>
  </button>

  <button class="buttons"
          *ngIf="isShowButtons"
          matTooltipPosition="right"
          [matTooltip]="'term.geofences' | translate"
          (click)="onShowGeofenceManager()">
    <i>
      <img [src]="GEOFENCE_ICON" alt="geofence">
    </i>
  </button>

  <button class="buttons"
          [matMenuTriggerFor]="layer"
          matTooltipPosition="right"
          [matTooltip]="'term.layers' | translate">
    <mat-icon>layers</mat-icon>
  </button>

  <mat-menu #layer="matMenu">
    <button mat-menu-item
            *ngFor="let layerName of layerNames"
            (click)="onLayerChange(layerName)">
      <mat-icon *ngIf="layerName === (currentTileName$ | async )">check</mat-icon>
      <span>{{ 'tile.name.' + layerName | translate }}</span>
    </button>

    <button mat-menu-item [matMenuTriggerFor]="weatherElements">
      <mat-icon *ngIf="currentWeatherElement$ | async">check</mat-icon>
      <span>{{ 'term.weather' | translate }}</span>
    </button>

    <mat-menu #weatherElements="matMenu">
      <button mat-menu-item
              *ngFor="let element of weatherElementList"
              (click)="onWeatherElementChange(element)"
      >
        <mat-icon *ngIf="element === (currentWeatherElement$ | async )">check</mat-icon>
        <span>{{ 'weather.' + element | translate }}</span>
      </button>
      <button mat-menu-item
              (click)="onWeatherElementChange()"
      >
        <mat-icon>layers_clear</mat-icon>
        <span>{{ 'action.clear' | translate }}</span>
      </button>
    </mat-menu>
  </mat-menu>

  <button *ngIf="isShowViolations"
          class="buttons"
          [ngClass]="{'accent': isShowOnMapViolations}"
          (click)="onToggleShowViolations()"
  >
    <mat-icon>place</mat-icon>
  </button>

  <button class="buttons"
          (click)="onChangeStepZoom(1)"
          [disabled]="mapSettings.MAX_ZOOM <= (zoom$ | async)"
  >
    <mat-icon>add</mat-icon>
  </button>

  <button class="buttons"
          (click)="onChangeStepZoom(-1)"
          [disabled]="mapSettings.MIN_ZOOM >= (zoom$ | async)"
  >
    <mat-icon>remove</mat-icon>
  </button>

  <app-weather-legend class="weather-legend" *ngIf="currentWeatherElement$ | async"></app-weather-legend>
</div>
