<form [formGroup]="editVehicleForm" *ngIf="editVehicleForm">
  <div class="form-container vehicle">

    <mat-form-field *ngIf="fields === vehicleFields.ALL || fields === vehicleFields.VEHICLE">
      <mat-label>{{ 'unit.model' | translate }}</mat-label>
      <input matInput type="text" formControlName="model">
      <mat-icon matSuffix
                *ngIf="formValue['model']"
                [matTooltip]="'action.clear' | translate"
                (click)="clear($event,'model')">
        clear
      </mat-icon>
      <mat-error *ngIf="formControl.model.errors?.pattern">
        {{ 'message.error.contains-illegal-symbols' | translate }}
      </mat-error>
      <mat-error *ngIf="formControl.model.errors?.maxlength">
        {{
          'message.error.max-length-symbols' | translate :
            {max: asMaxlength(formControl.model.errors.maxlength).requiredLength}
        }}
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="fields === vehicleFields.ALL">
      <mat-label>{{ 'unit.issue-date' | translate }}</mat-label>
      <input matInput [matDatepicker]="picker1"
             (focus)="picker1.open()"
             (click)="picker1.open()"
             formControlName="dateManufacture">
      <mat-icon matSuffix
                *ngIf="formValue['dateManufacture']"
                [matTooltip]="'action.clear' | translate"
                (click)="clear($event,'dateManufacture')">
        clear
      </mat-icon>
      <mat-datepicker #picker1 startView="multi-year"></mat-datepicker>
    </mat-form-field>

    <mat-form-field *ngIf="fields === vehicleFields.ALL">
      <mat-label>{{ 'unit.launch-date' | translate }}</mat-label>
      <input matInput
             [matDatepicker]="picker2"
             (focus)="picker2.open()"
             (click)="picker2.open()"
             formControlName="startOperation">
      <mat-icon matSuffix
                *ngIf="formValue['startOperation']"
                [matTooltip]="'action.clear' | translate"
                (click)="clear($event,'startOperation')">
        clear
      </mat-icon>
      <mat-datepicker #picker2 startView="multi-year"></mat-datepicker>
    </mat-form-field>

    <mat-form-field *ngIf="fields === vehicleFields.ALL || fields === vehicleFields.VEHICLE">
      <mat-icon *ngIf="showSpinner" class="show-spinner">
        <mat-spinner diameter="20"></mat-spinner>
      </mat-icon>
      <mat-label>{{ 'term.type' | translate }}</mat-label>
      <mat-select formControlName="vehicleType">
        <mat-option *ngFor="let vehicleType of vehicleTypes; let i = index" [value]="vehicleType">
          {{ vehicleType.nameTranslated }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix
                *ngIf="formValue['vehicleType']"
                [matTooltip]="'action.clear' | translate"
                (click)="clear($event,'vehicleType')">
        clear
      </mat-icon>
    </mat-form-field>

    <mat-form-field *ngIf="fields === vehicleFields.ALL">
      <mat-label> {{ 'term.description' | translate }}</mat-label>
      <input matInput formControlName="description">
      <mat-icon matSuffix
                *ngIf="formValue['description']"
                [matTooltip]="'action.clear' | translate"
                (click)="clear($event,'description')">
        clear
      </mat-icon>
      <mat-error *ngIf="formControl.description.errors?.pattern">
        {{ 'message.error.contains-illegal-symbols' | translate }}
      </mat-error>
      <mat-error *ngIf="formControl.description.errors?.maxlength">
        {{
          'message.error.max-length-symbols' | translate :
            {max: asMaxlength(formControl.description.errors.maxlength).requiredLength}
        }}
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="fields === vehicleFields.ALL || fields === vehicleFields.VEHICLE">
      <mat-label>{{ 'unit.plate-number' | translate }}</mat-label>
      <input matInput formControlName="stateNumber">
      <mat-icon matSuffix
                *ngIf="formValue['stateNumber']"
                [matTooltip]="'action.clear' | translate"
                (click)="clear($event,'stateNumber')">
        clear
      </mat-icon>
      <mat-error *ngIf="formControl.stateNumber.errors?.pattern">
        {{ 'message.error.contains-illegal-symbols' | translate }}
      </mat-error>
      <mat-error *ngIf="formControl.stateNumber.errors?.maxlength">
        {{
          'message.error.max-length-symbols' | translate :
            {max: asMaxlength(formControl.stateNumber.errors.maxlength).requiredLength}
        }}
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="fields === vehicleFields.ALL || fields === vehicleFields.VEHICLE">
      <mat-label>{{ 'unit.VIN' | translate }}</mat-label>
      <input matInput formControlName="vin">
      <mat-icon matSuffix
                *ngIf="formValue['vin']"
                [matTooltip]="'action.clear' | translate"
                (click)="clear($event,'vin')">
        clear
      </mat-icon>
      <mat-error *ngIf="formControl.vin.errors?.pattern">
        {{ 'message.error.contains-illegal-symbols' | translate }}
      </mat-error>
      <mat-error *ngIf="formControl.vin.errors?.minlength || formControl.vin.errors?.maxlength">
        {{ 'message.error.vin-characters' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="fields === vehicleFields.ALL || fields === vehicleFields.VEHICLE">
      <mat-label>{{ 'unit.chassis-number' | translate }}</mat-label>
      <input matInput formControlName="chassisNumber">
      <mat-icon matSuffix
                *ngIf="formValue['chassisNumber']"
                [matTooltip]="'action.clear' | translate"
                (click)="clear($event,'chassisNumber')">
        clear
      </mat-icon>
      <mat-error *ngIf="formControl.chassisNumber.errors?.pattern">
        {{ 'message.error.contains-illegal-symbols' | translate }}
      </mat-error>
      <mat-error *ngIf="formControl.chassisNumber.errors?.maxlength">
        {{
          'message.error.max-length-symbols' | translate :
            {max: asMaxlength(formControl.chassisNumber.errors.maxlength).requiredLength}
        }}
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="fields === vehicleFields.ALL || fields === vehicleFields.VEHICLE">
      <mat-label>{{ 'unit.inventory-number' | translate }}</mat-label>
      <input matInput formControlName="inventoryNumber"/>
      <mat-icon matSuffix
                *ngIf="formValue['inventoryNumber']"
                [matTooltip]="'action.clear' | translate"
                (click)="clear($event,'inventoryNumber')">
        clear
      </mat-icon>
      <mat-error *ngIf="formControl.inventoryNumber.errors?.pattern">
        {{ 'message.error.contains-illegal-symbols' | translate }}
      </mat-error>
      <mat-error *ngIf="formControl.inventoryNumber.errors?.maxlength">
        {{
          'message.error.max-length-symbols' | translate :
            {max: asMaxlength(formControl.inventoryNumber.errors.maxlength).requiredLength}
        }}
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="fields === vehicleFields.ALL || fields === vehicleFields.DATE">
      <mat-label>{{ 'unit.expiry-date-tech-inspection' | translate }}</mat-label>
      <input matInput
             [matDatepicker]="picker3"
             (focus)="picker3.open()"
             (click)="picker3.open()"
             formControlName="expiryDateTechInspection">
      <app-expire-icon class="margin-left-8" matIconPrefix [dateString]="formValue['expiryDateTechInspection']"></app-expire-icon>

      <mat-icon matIconSuffix
                *ngIf="formValue['expiryDateTechInspection']"
                [matTooltip]="'action.clear' | translate"
                (click)="clear($event,'expiryDateTechInspection')">
        clear
      </mat-icon>

      <mat-datepicker #picker3 startView="year"></mat-datepicker>
    </mat-form-field>

    <mat-form-field *ngIf="fields === vehicleFields.ALL || fields === vehicleFields.DATE">
      <mat-label>{{ 'unit.expiry-date-insurance' | translate }}</mat-label>
      <input matInput
             [matDatepicker]="picker4"
             (focus)="picker4.open()"
             (click)="picker4.open()"
             formControlName="expiryDateInsurance">
      <app-expire-icon class="margin-left-8" matIconPrefix [dateString]="formValue['expiryDateInsurance']"></app-expire-icon>
      <mat-icon matIconSuffix
                *ngIf="formValue['expiryDateInsurance']"
                [matTooltip]="'action.clear' | translate"
                (click)="clear($event,'expiryDateInsurance')">
        clear
      </mat-icon>
      <mat-datepicker #picker4 startView="year"></mat-datepicker>
    </mat-form-field>

    <mat-form-field *ngIf="fields === vehicleFields.ALL || fields === vehicleFields.DATE">
      <mat-label>{{ 'unit.expiry-date-speedometer' | translate }}</mat-label>
      <input matInput
             [matDatepicker]="picker5"
             (focus)="picker5.open()"
             (click)="picker5.open()"
             formControlName="expiryDateSpeedometer">
      <app-expire-icon class="margin-left-8" matIconPrefix [dateString]="formValue['expiryDateSpeedometer']"></app-expire-icon>
      <mat-icon matIconSuffix
                *ngIf="formValue['expiryDateSpeedometer']"
                [matTooltip]="'action.clear' | translate"
                (click)="clear($event,'expiryDateSpeedometer')">
        clear
      </mat-icon>
      <mat-datepicker #picker5 startView="year"></mat-datepicker>
    </mat-form-field>
  </div>
</form>

<ng-content></ng-content> <!-- project actionButtons -->
