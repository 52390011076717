import {View} from 'ol';
import {Coordinate} from 'ol/coordinate';

export class OlView {

  public static createView(center: Coordinate, zoom: number, minZoom: number, maxZoom: number): View {
    return new View({
      center: center,
      zoom: zoom,
      maxZoom: maxZoom,
      minZoom: minZoom,
      enableRotation: false
    });
  }
}
