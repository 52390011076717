import {Component, Inject, OnInit} from '@angular/core';
import {DialogComponent} from "../../../../../shared/components/dialogs/base-dialog/base-dialog.component";
import {SensorLastValue} from "../../../../../../../../common-module/src/lib/modelinterfaces/sensor-last-value.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SensorListComponent} from "../../chart-dialog/sensor-list/sensor-list.component";
import {AppCommonModule} from "../../../../../../../../common-module/src/lib/app-common.module";
import {JsonPipe, NgForOf, NgIf} from "@angular/common";
import {
  SensorLastValueItemComponent
} from "../../chart-dialog/sensor-list/sensor-value-item/sensor-last-value-item.component";
import {SharedModule} from "../../../../../shared/shared.module";
import {FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {AppColor} from "../../../../../../../../common-module/src/lib/app-enums/app-color";
import {comparisonId} from "../../../../../../../../common-module/src/lib/utils/comparison-function.util";
import {Sensor} from "../../../../../../../../common-module/src/lib/modelinterfaces/sensor.model";
import {AppDialogModule} from "../../../../../../../../common-module/src/lib/dialog/dialog/app-dialog.module";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {
  ColorPickerInputComponent
} from "../../../../../shared/components/inputs/color-picker-input/color-picker-input.component";
import {AppButtonModule} from "../../../../../../../../common-module/src/lib/buttons/app-button.module";
import {MatTooltipModule} from "@angular/material/tooltip";
import {TranslateModule} from "@ngx-translate/core";
import {MatDividerModule} from "@angular/material/divider";

enum Controls {
  COLOR = 'color',
  MIN = 'min',
  MAX = 'max',
  SENSOR = 'sensor',
  RANGES = 'ranges'
}

interface RangeValues {
  color: AppColor;
  min?: number;
  max?: number;
}

export interface TrackSensorOptionsDialogDate {
  sensors: SensorLastValue[];
  options: SensorRanges[];
}

export interface RangeValue {
  color: AppColor,
  min: number,
  max: number
}

export interface SensorRanges {
  sensorName: string;
  ranges: RangeValue[]
}

@Component({
  selector: 'app-track-sensor-options-dialog',
  standalone: true,
  imports: [
    SensorListComponent,
    AppCommonModule,
    JsonPipe,
    SensorLastValueItemComponent,
    SharedModule,
    NgForOf,
    NgIf,
    AppDialogModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    ColorPickerInputComponent,
    AppButtonModule,
    MatTooltipModule,
    TranslateModule,
    MatDividerModule
  ],
  templateUrl: './track-sensor-options-dialog.component.html',
  styleUrl: './track-sensor-options-dialog.component.scss',
})
export class TrackSensorOptionsDialogComponent extends DialogComponent.Outputable<TrackSensorOptionsDialogDate, SensorRanges[]> implements OnInit {

  // key - sensor index
  readonly DEFAULT_VALUES: RangeValues[][] = [
    [
      {color: AppColor.PURPLE, min: 1, max: 1000},
      {color: AppColor.BROWN},
      {color: AppColor.AMBER}
    ],
    [
      {color: AppColor.AQUA},
      {color: AppColor.MINT},
      {color: AppColor.YELLOW}
    ]
  ];

  currenOptions: RangeValues[][] = [];

  controls = Controls;
  form: FormGroup;

  constructor(public dialogRef: MatDialogRef<TrackSensorOptionsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: TrackSensorOptionsDialogDate) {
    super(dialogRef, data);
  }

  ngOnInit() {
    this.currenOptions = this.merge(this.data.options);
    this.form = new FormGroup({
      sensors: new FormArray(this.createInitialSensorGroups())
    });
  }

  private merge(sensorRanges: SensorRanges[]): RangeValues[][] {
    const copy: RangeValues[][] = JSON.parse(JSON.stringify(this.DEFAULT_VALUES));
    if (!sensorRanges) {
      return copy;
    }
    if (sensorRanges.length >= 1) {
      sensorRanges[0].ranges.forEach((v, i) => {
        copy[0][i] = v;
      })
    }
    if (sensorRanges.length >= 2) {
      sensorRanges[1].ranges.forEach((v, i) => {
        copy[1][i] = v;
      })
    }
    return copy;
  }

  getSensors(): FormArray {
    return this.form.get('sensors') as FormArray;
  }

  getRangesForSensor(sensorIndex: number): FormArray {
    return (this.getSensors().at(sensorIndex).get('ranges') as FormArray);
  }

  // Метод для создания начальных групп сенсоров
  createInitialSensorGroups(): FormGroup[] {
    return this.DEFAULT_VALUES.map((_, sensorIndex) =>
      new FormGroup({
        [Controls.SENSOR]: new FormControl(this.calculateSensor(sensorIndex)),
        [Controls.RANGES]: new FormArray(this.createInitialRanges(sensorIndex), this.rangesValidator)
      })
    );
  }

  calculateSensor(sensorIndex: number): SensorLastValue | undefined {
    if (sensorIndex >= this.data.sensors.length) {
      return this.data.sensors[sensorIndex];
    }
    return this.data.sensors.find(sensor => this.data.options[sensorIndex]?.sensorName === sensor.name);
  }

// Метод для создания начальных диапазонов
  createInitialRanges(sensorIndex: number): FormGroup[] {
    return this.DEFAULT_VALUES[sensorIndex].map((_, rangeIndex) =>
      this.createRangeGroup(sensorIndex, rangeIndex)
    );
  }

// Метод для создания группы диапазона
  createRangeGroup(sensorIndex: number, rangeIndex: number): FormGroup {
    const rangeDefaults = this.getDefault(sensorIndex, rangeIndex);
    return new FormGroup({
      [Controls.COLOR]: new FormControl(rangeDefaults?.color, Validators.required),
      [Controls.MIN]: new FormControl(rangeDefaults?.min),
      [Controls.MAX]: new FormControl(rangeDefaults?.max)
    });
  }

  getDefault(sensorIndex: number, rangeIndex: number): RangeValues {
    return this.currenOptions[sensorIndex][rangeIndex];
  }

  resetRow(sensorIndex: number, rangeIndex: number): void {
    const rangeGroup = this.getRangesForSensor(sensorIndex).at(rangeIndex) as FormGroup;
    const defaults = this.DEFAULT_VALUES[sensorIndex][rangeIndex];
    rangeGroup.reset({
      [Controls.COLOR]: defaults?.color,
      [Controls.MIN]: undefined,
      [Controls.MAX]: undefined
    });
  }

  // Валидатор для проверки пересекающихся диапазонов
  rangesValidator(formArray: FormArray): { [key: string]: boolean } | null {
    const ranges = formArray.controls.map((group, index) => {
      let min = group.get(Controls.MIN).value;
      let max = group.get(Controls.MAX).value;

      // Пропускаем строки, где не указаны оба значения
      if (min == null && max == null) {
        return {min: null, max: null, index};
      }

      // Задаем значения по умолчанию
      if (min == null) {
        min = 0; // Если MIN не указан, предполагать 0
      }
      if (max == null) {
        max = 4294967295; // Если MAX не указан, предполагать максимальное 4-байтное значение
      }

      return {min, max, index};
    });

    let hasOverlap = false;

    // Сброс ошибок перед новой проверкой
    formArray.controls.forEach(group => {
      group.get(Controls.MIN).setErrors(null);
      group.get(Controls.MAX).setErrors(null);
    });

    // Проверяем пересечения диапазонов
    for (let i = 0; i < ranges.length; i++) {
      const rangeA = ranges[i];
      if (rangeA.min == null || rangeA.max == null) continue;

      for (let j = i + 1; j < ranges.length; j++) {
        const rangeB = ranges[j];
        if (rangeB.min == null || rangeB.max == null) continue;

        // Условие пересечения диапазонов
        if (rangeA.max > rangeB.min && rangeA.min < rangeB.max) {
          // Устанавливаем ошибку для пересекающихся контролов min и max
          formArray.controls[rangeA.index].get(Controls.MIN).setErrors({rangeOverlap: true});
          formArray.controls[rangeA.index].get(Controls.MAX).setErrors({rangeOverlap: true});
          formArray.controls[rangeB.index].get(Controls.MIN).setErrors({rangeOverlap: true});
          formArray.controls[rangeB.index].get(Controls.MAX).setErrors({rangeOverlap: true});
          hasOverlap = true;
        }
      }
    }
    return hasOverlap ? {rangesOverlap: true} : null;
  }

  protected readonly comparisonId = comparisonId;

  onSubmit() {
    const result: SensorRanges[] = this.getSensors().controls
      .map((sensorGroup: FormGroup) => {
        const sensorName: string = sensorGroup.get(Controls.SENSOR)?.value?.name;

        // Фильтруем диапазоны, исключая те, где не заданы min и max
        const ranges: RangeValue[] = (sensorGroup.get(Controls.RANGES) as FormArray).controls
          .map((rangeGroup: FormGroup) => {
            return {
              color: rangeGroup.get(Controls.COLOR)?.value,
              min: rangeGroup.get(Controls.MIN)?.value,
              max: rangeGroup.get(Controls.MAX)?.value
            } as RangeValue;
          })
          .filter(range => range.min != null && range.max != null); // Фильтруем только диапазоны с заданными min и max

        return {
          sensorName: sensorName,
          ranges
        } as SensorRanges;
      })
      // Фильтруем сенсоры, у которых нет ни одного диапазона
      .filter(sensorResult => sensorResult.ranges.length > 0)
      .filter(sensorResult => sensorResult.sensorName)
    this.closeOnSaveOutput(result);
  }
}
