import {WarehouseMapConfig, WarehousesMap} from "../../warehouse-map/warehouses.map";
import OlMap from "ol/Map";
import {Logistic} from "../../../../../../../../common-module/src/lib/modelinterfaces/logistic.model";
import VectorLayer from "ol/layer/Vector";
import {JobFeatureWrapperFactory} from "../../../shared/feature/logistic/job-feature/job-feature-wrapper.factory";
import {OlVectorLayer} from "../../../../map-utils/OlVectorLayer";
import {JobFeatureWrapper} from "../../../shared/feature/logistic/job-feature/shipment-feature.wrapper";
import {LayerUtil} from "../../../../map-utils/layer.util";
import {AbstractJobFeatureWrapper} from "../../../shared/feature/logistic/abstract-job-feature.wrapper";
import {SelectionModel} from "@angular/cdk/collections";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {FeatureLike} from "ol/Feature";
import {StepFeatureWrapper} from "../../../shared/feature/logistic/step-feature/step-feature.wrapper";
import {JobManagerMapPopupFactory} from "./job-manager-map.popup-factory";
import Job = Logistic.Job;
import Warehouse = Logistic.Warehouse;
import {GroupSelectionInteraction} from "./group-selection-interaction";

export interface JobsManagerMapConfig extends WarehouseMapConfig {
  jobs: Logistic.Job[];
  selectionModel: SelectionModel<Logistic.Job>;
}

export class JobManagerMap extends WarehousesMap {

  //jobId
  private jobFeatureMap = new Map<number, JobFeatureWrapper>();
  private factory = new JobFeatureWrapperFactory();
  private jobsLayer: VectorLayer;
  private selectionModel: SelectionModel<Logistic.Job> = new SelectionModel<Logistic.Job>();
  private clickTimeout: any;

  private _doubleClickedJob = new BehaviorSubject<Logistic.Job | undefined>(undefined);
  public doubleClickedJob = this._doubleClickedJob.asObservable();

  private destroy$ = new Subject<void>();

  private groupSelectionInteraction: GroupSelectionInteraction;


  public constructor(map: OlMap, config: JobsManagerMapConfig) {
    super(map, config);
    this.initMapEvents();
    this.setJobs(config.jobs);
    this.setSelectionModel(config.selectionModel);
    this.showArchivedWarehousesFromJobs(config);
    this.groupSelectionInteraction = new GroupSelectionInteraction(map);
  }

  private showArchivedWarehousesFromJobs(config: JobsManagerMapConfig) {
    const archivedWarehouses: Set<Warehouse> = config.jobs.reduce((acc, job) => {
      if (job.isShipment()) {
        if (this.stepHasArchivedWarehouse(job.getPickupOptional())) {
          acc.add(job.getPickupOptional().toStepWarehouse().warehouse);
        }
        if (this.stepHasArchivedWarehouse(job.delivery)) {
          acc.add(job.delivery.toStepWarehouse().warehouse);
        }
      }
      return acc;
    }, new Set<Warehouse>());
    if (archivedWarehouses.size > 0) {
      // @ts-ignore
      super.setWarehouses([...config.warehouses, ...archivedWarehouses]);
    }
  }

  private stepHasArchivedWarehouse(step: Logistic.Step): boolean {
    return step.isStepWarehouse() && step.toStepWarehouse().warehouse.archived;
  }

  public setJobs(jobs: Job[]) {
    if (!jobs) {
      return;
    }
    LayerUtil.remove(this.map, this.jobsLayer);

    this.jobFeatureMap.clear();

    jobs.forEach(job => {
      const jobFeatureWrapper = this.factory.create(job, {showWarehouse: false});
      this.jobFeatureMap.set(job.id, jobFeatureWrapper);
    });

    let allFeatures = [];
    this.jobFeatureMap.forEach(compositeWrapper => {
      allFeatures = allFeatures.concat(compositeWrapper.getWrappers()
        .map(wrapper => wrapper.feature));
    });

    this.jobsLayer = OlVectorLayer.createLayerOnMap(this.map, 'jobs', allFeatures);

    this.updateJobSelection(this.selectionModel.selected, 'select')

    this.centerOn(this.jobsLayer);
  }

  public setSelectionModel(selectionModel: SelectionModel<Logistic.Job>) {
    this.selectionModel = selectionModel;
    this.updateJobSelection(this.selectionModel.selected, 'select')
    this.subscribeSelectionChange();
  }

  private initMapEvents() {
    this.initSingleClick();
    this.initDoubleClick();
  }

  private subscribeSelectionChange() {
    this.selectionModel.changed.pipe(takeUntil(this.destroy$)).subscribe(event => {
        if (event.added) {
          this.updateJobSelection(event.added, 'select');
        }
        if (event.removed) {
          this.updateJobSelection(event.removed, 'deselect');
        }
      }
    )
  }

  private initDoubleClick() {
    this.map.on('dblclick', (evt) => {
      clearTimeout(this.clickTimeout);
      this.map.forEachFeatureAtPixel(evt.pixel, (f) => {
        evt.preventDefault();
        const value = AbstractJobFeatureWrapper.getJobFromFeature(f);
        if (value && value instanceof Logistic.Job) {
          this._doubleClickedJob.next(value);
        }
        return true;
      });
    });
  }

  private initSingleClick() {
    this.map.on('click', (e) => {
      clearTimeout(this.clickTimeout);
      this.clickTimeout = setTimeout(() => {
        this.map.forEachFeatureAtPixel(e.pixel, f => {
          const job = AbstractJobFeatureWrapper.getJobFromFeature(f);
          if (job) {
            this.toggleJobSelection(job)
          }
          return true;
        })
      }, 250)
    })
  }

  private toggleJobSelection(job: Logistic.Job) {
    let jobFeatureWrapper = this.jobFeatureMap.get(job.id);
    if (!jobFeatureWrapper.isSelected()) {
      jobFeatureWrapper.select();
      this.selectionModel.select(jobFeatureWrapper.getJob())
    } else {
      jobFeatureWrapper.deselect();
      this.selectionModel.deselect(jobFeatureWrapper.getJob())
    }
  }

  private updateJobSelection(jobs: Logistic.Job[], action: 'select' | 'deselect') {
    jobs.forEach(job => {
      const jobFeatureWrapper = this.jobFeatureMap.get(job.id);
      if (jobFeatureWrapper) {
        action === 'select' ? jobFeatureWrapper.select() : jobFeatureWrapper.deselect();
      }
    });
  }

  public destroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  protected override createCustomPopup(feature: FeatureLike): string {
    const job = AbstractJobFeatureWrapper.getJobFromFeature(feature);
    const step = StepFeatureWrapper.getStepFromFeature(feature);
    if (job && step) {
      return JobManagerMapPopupFactory.create(step, job);
    }
    return null;
  }

  protected override popupOffset(): [number, number] {
    return [-40, 25]
  }

  protected override onFeatureIn(feature: FeatureLike) {
    let job = AbstractJobFeatureWrapper.getJobFromFeature(feature);
    if (job) {
      let jobFeatureWrapper = this.jobFeatureMap.get(job.id);
      jobFeatureWrapper.highlight();
    }
  }

  protected override onFeatureOut(feature: FeatureLike) {
    let job = AbstractJobFeatureWrapper.getJobFromFeature(feature);
    if (job) {
      let jobFeatureWrapper = this.jobFeatureMap.get(job.id);
      jobFeatureWrapper.unHighlight();
    }
  }

  public startGroupSelection() {
    this.groupSelectionInteraction.startGroupSelect(this.jobsLayer, this.jobFeatureMap);
  }

  public cancelGroupSelection() {
    this.groupSelectionInteraction.clearGroupSelection();
  }

  public selectGroupSelection(action: 'select' | 'deselect') {
    const jobs = this.groupSelectionInteraction.getHighlightedFeatures()
      .map(w => w.getJob());
    this.updateJobSelection(jobs, action);
    if (action === 'select') {
      this.selectionModel.select(...jobs);
    } else {
      this.selectionModel.deselect(...jobs);
    }
    this.groupSelectionInteraction.clearGroupSelection();
  }

  public get groupSelectionCount(): Observable<number> {
    return this.groupSelectionInteraction.highlightedCount;
  }

  centerAllJobs() {
    this.centerOn(this.jobsLayer);
  }
}
