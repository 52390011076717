import {FilterOperation} from "../modelinterfaces/enums/filter-operation";
import {PageParams} from "../modelinterfaces/page-params.model";
import {HttpParams} from "@angular/common/http";
import {HttpParamsUtil} from "./http-params-util";

export interface SpecSource {
  filterName: string,
  operation: FilterOperation,
  values: any[]
}

export class SpecsFilterBuilder {

  private static readonly MULTIPLE_OPERATIONS: Set<FilterOperation> = new Set([FilterOperation.IN, FilterOperation.NOT_IN]);
  private static readonly TWO_VALUE_OPERATIONS: Set<FilterOperation> = new Set([FilterOperation.BETWEEN]);
  private static readonly VALUE_SEPARATOR = ",";

  public static buildPageableParams(page: PageParams, ...sources: SpecSource[]): HttpParams {
    let filter = new Map(sources
      .filter(s => !!s)
      .filter(s => s.values.length > 0)
      .map((s) => [s.filterName, this.build(s.operation, ...s.values)]));
    return HttpParamsUtil.createByPageAndFilter(page, filter)
  }

  public static buildParamsMap(...sources: SpecSource[]): Map<string, string> {
    return new Map(sources
      .filter(s => !!s)
      .map((s) => [s.filterName, this.build(s.operation, s.values)]));
  }

  public static build(operation: FilterOperation, ...params: any[]): string {
    if (!operation) {
      throw new Error("can't be build filter operation is empty");
    }
    if (!params) {
      throw new Error("can't be build filter params are empty");
    }
    if (SpecsFilterBuilder.MULTIPLE_OPERATIONS.has(operation)) {
      return SpecsFilterBuilder.buildFilter(operation, params);
    }
    if (SpecsFilterBuilder.TWO_VALUE_OPERATIONS.has(operation)) {
      return SpecsFilterBuilder.buildExtFilter(operation, 2, params);
    }
    return SpecsFilterBuilder.buildExtFilter(operation, 1, params);
  }

  private static buildExtFilter(operation: FilterOperation, expectSize: number, params: any[]): string {
    if (params.length != expectSize) {
      throw new Error("can't be build filter params length is expectSize: 2, but actual: " + params.length);
    }
    return SpecsFilterBuilder.buildFilter(operation, params);
  }

  private static buildFilter(operation: FilterOperation, params: any[]): string {
    const filteredParams = params.filter(param => param !== null && param !== undefined);
    if (filteredParams.length > 0) {
      return operation.toString() + filteredParams.join(this.VALUE_SEPARATOR)
    }
    return null;
  }
}
