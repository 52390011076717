<div class="main" id="notification-details">
  <app-print-button
    class="print-button"
    (printChange)="onReceivePrintOrientation($event)"
    data-html2canvas-ignore="true">
  </app-print-button>

  <ng-content select="[slot=header]"></ng-content>

  <div class="box-info-chart" [ngClass]="{'box-info-chart_le_l': (size$ | async).less('l')}">
    <div class="info" *ngIf="(size$ | async).more('m'); else accordion">
      <table class="table-info">
        <ng-container [ngTemplateOutlet]="unitName"></ng-container>
        <ng-container [ngTemplateOutlet]="detailsInfo"></ng-container>
      </table>
    </div>

    <div class="chart">
      <app-speed-chart
        [sensorDataStorage]="notificationDetails?.sensorChart?.storage"
        (selectCoordinateEvent)="receiveSelectedCoordinate($event)"
      >
      </app-speed-chart>
    </div>
  </div>
</div>

<div id="map-notification" class="map">
  <div class="box-buttons-and-conditions">
    <app-map-buttons
      [map]="map"
      [isShowButtons]="false"
      class="position-buttons"
    ></app-map-buttons>

    <app-map-conditions class="map-conditions">
    </app-map-conditions>
  </div>
</div>

<ng-template #accordion>
    <mat-accordion class="accordion">
      <mat-expansion-panel [expanded]="!notificationDetails?.sensorChart?.storage">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <table class="table-info">
              <ng-container [ngTemplateOutlet]="unitName"></ng-container>
            </table>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <table class="table-info">
          <ng-container [ngTemplateOutlet]="detailsInfo"></ng-container>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
</ng-template>

<ng-template #detailsInfo>
  <tr>
    <td class="left-column">{{ 'term.mileage' | translate }}:</td>
    <td class="right-column">
      {{ mileage ? (mileage | sensorValue: 2) : '-' }} {{ 'uom.km' | translate }}
    </td>
  </tr>
  <tr>
    <td class="left-column">{{ 'report.name.trips' | translate }}:</td>
    <td class="right-column">{{ tripsDuration }}</td>
  </tr>
  <tr>
    <td class="left-column">{{ 'report.name.parking' | translate }}:</td>
    <td class="right-column">{{ parkingsDuration }}</td>
  </tr>
  <ng-content select="[slot=description]"></ng-content>
</ng-template>

<ng-template #unitName>
  <ng-content select="[slot=unit-name]"></ng-content>
</ng-template>
