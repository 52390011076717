import { Component } from '@angular/core';
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-logistic-logo-title',
  standalone: true,
  templateUrl: './logistic-logo-title.component.html',
  imports: [
    MatIcon
  ],
  styleUrl: './logistic-logo-title.component.scss'
})
export class LogisticLogoTitleComponent {

}
