export enum TileName {
  OSM = 'OSM',
  // OSM_FR = 'OSM-FR',
  OSM_MAPIA = 'OSM-MAPIA',
  YA = 'YA',
  YA_SATS = 'YA-SATS',
  YA_HYBRID = 'YA-HYBRID',
  // YA_TRAFFIC = 'YA-TRAFFIC',
  OSM_SEA = 'OSM-SEA',
  // HERE = 'HERE'
}
