import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {QrCodeModule} from "ng-qrcode";
import {AppCommonModule} from 'projects/common-module/src/lib/app-common.module';
import {CheckMobileDeviceComponent} from './components/check-mobile-device/check-mobile-device.component';
import {ColorPaletteComponent} from './components/color-palette/color-palette.component';
import {CurrentDriverComponent} from './components/current-driver/current-driver.component';
import {ModelExpansionListComponent} from "./components/model-expansion-list/model-expansion-list.component";
import {PrintButtonComponent} from './components/print-button/print-button.component';
import {SpeedChartComponent} from './components/speed-chart/speed-chart.component';
import {TelegramMessageComponent} from './components/telegram-message/telegram-message.component';
import {GeofenceFilterPipe} from './pipes/geofence-filter.pipe';
import {HoverDirective} from './utils/hover.directive';
import {
  AddressCoordinateSearchComponent
} from './components/address-coordinate-search/address-coordinate-search.component';
import {DriverWithAvatarComponent} from './components/driver-with-avatar/driver-with-avatar.component';
import {RouteStatusIconComponent} from './components/route-status-icon/route-status-icon.component';
import {ExpireIconComponent} from './components/expire-icon/expire-icon.component';
import {WarehouseMapComponent} from './maps/map/warehouse-map/warehouse-map.component';
import {AbstractMapComponent} from "./maps/map/abstract-map.component";
import {OlMapComponent} from './aur-map-creator/map/component/ol-map/ol-map.component';
import {DurationInputComponent} from './components/inputs/duration-input/duration-input.component';
import {
  MobilePhoneNumberInputComponent
} from './components/inputs/mobile-phone-number-input/mobile-phone-number-input.component';
import {
  AnyPhoneNumberInputComponent
} from './components/inputs/any-phone-number-input/any-phone-number-input.component';
import {JobManagerMapComponent} from './maps/map/job-manager-map/component/job-manager-map.component';
import {JobsOverviewMapComponent} from "./maps/map/jobs-overview-map/jobs-overview-map.component";
import {OlMapButtonBlockDirective} from './aur-map-creator/map/component/ol-map/ol-map-button-block.directive';
import {SlidePanelAnimatedComponent} from './animation/slide-panel-animated/slide-panel-animated.component';
import {UnitListSimpleComponent} from './components/unit-list-simple/unit-list-simple.component';
import {ColorPickerInputComponent} from "./components/inputs/color-picker-input/color-picker-input.component";
import {LogisticLogoTitleComponent} from "./components/logistic-logo-title/logistic-logo-title.component";


@NgModule({
  declarations: [
    GeofenceFilterPipe,
    HoverDirective,
    CheckMobileDeviceComponent,
    ColorPaletteComponent,
    PrintButtonComponent,
    SpeedChartComponent,
    TelegramMessageComponent,
    ModelExpansionListComponent,
    AddressCoordinateSearchComponent,
    CurrentDriverComponent,
    DriverWithAvatarComponent,
    RouteStatusIconComponent,
    ExpireIconComponent,
    WarehouseMapComponent,
    AbstractMapComponent,
    OlMapComponent,
    JobsOverviewMapComponent,
    JobManagerMapComponent,
    DurationInputComponent,
    MobilePhoneNumberInputComponent,
    AnyPhoneNumberInputComponent,
    JobManagerMapComponent,
    OlMapButtonBlockDirective,
    SlidePanelAnimatedComponent,
    UnitListSimpleComponent,
    ColorPickerInputComponent,
    LogisticLogoTitleComponent

  ],
  imports: [
    CommonModule,
    AppCommonModule,
    QrCodeModule,


  ],
  providers: [
  ],
  bootstrap: [],
    exports: [
        CheckMobileDeviceComponent,
        ColorPaletteComponent,
        PrintButtonComponent,
        SpeedChartComponent,
        GeofenceFilterPipe,
        TelegramMessageComponent,
        ModelExpansionListComponent,
        AddressCoordinateSearchComponent,
        CurrentDriverComponent,
        DriverWithAvatarComponent,
        RouteStatusIconComponent,
        ExpireIconComponent,
        WarehouseMapComponent,
        JobsOverviewMapComponent,
        JobManagerMapComponent,
        DurationInputComponent,
        MobilePhoneNumberInputComponent,
        AnyPhoneNumberInputComponent,
        UnitListSimpleComponent,
        ColorPickerInputComponent,
        LogisticLogoTitleComponent,
        OlMapComponent
    ]
})

export class SharedModule {
}
