import {DateTimeFormatter, LocalDateTime, LocalTime, Temporal, ZoneOffset} from "@js-joda/core";
import {Logistic} from "../../../../../../../common-module/src/lib/modelinterfaces/logistic.model";
import TimeWindow = Logistic.TimeWindow;
import TimeWindowLocalDateTime = Logistic.TimeWindowLocalDateTime;
import TimeWindowLocalTime = Logistic.TimeWindowLocalTime;

export class LogisticTimeWindowUtil {
  private static readonly SEPARATOR = ' - ';
  private static readonly JOINER = ', ';
  private static readonly DAY_MONTH_TIME = DateTimeFormatter.ofPattern('dd.MM HH:mm')
  private static readonly DAY_MONTH_YEAR_TIME = DateTimeFormatter.ofPattern('dd.MM.yyyy HH:mm')
  private static readonly TIME = DateTimeFormatter.ofPattern('HH:mm')

  public static sortValue(timeWindows: TimeWindow<any>[]): number | undefined {
    if (!timeWindows || timeWindows.length === 0) {
      return 0;
    }
    const tw = timeWindows[0].from;
    if (tw instanceof LocalDateTime) {
      return tw.toEpochSecond(ZoneOffset.ofHours(0));
    }
    if (tw instanceof LocalTime) {
      return tw.toSecondOfDay();
    }
    return 0;
  }

  public static parseLocalDateTime(timeWindowsStr: string): TimeWindowLocalDateTime[] {
    try {
      if (!timeWindowsStr) {
        return [];
      }
      if (timeWindowsStr.length < 'dd.MM.yyyy HH:mm - dd.MM.yyyy HH:mm'.length) {
        timeWindowsStr = this.formatDateString(timeWindowsStr);
      }
      return this.parse(timeWindowsStr, s => LocalDateTime.parse(s, this.DAY_MONTH_YEAR_TIME))
        .map(twArr => new TimeWindowLocalDateTime(twArr[0], twArr[1]))
    } catch (e) {
      console.log('error parse', timeWindowsStr)
      return null;
    }
  }

  /**
   * Transforms an input string of format 'dd.MM.yyyy HH:mm - HH:mm'
   * into a string of format 'dd.MM.yyyy HH:mm - dd.MM.yyyy HH:mm'.
   *
   * The function splits the input string into two parts using the "-" delimiter,
   * extracts the date from the first part, and appends it to the second part.
   * This method is useful when it is necessary to format a time range to include the date at both ends.
   *
   * @param input - An input string of format 'dd.MM.yyyy HH:mm - HH:mm'
   * @returns A string of format 'dd.MM.yyyy HH:mm - dd.MM.yyyy HH:mm'
   *
   * @example
   * const input = '26.12.2023 10:00 - 12:00';
   * console.log(formatDateString(input));  // '26.12.2023 10:00 - 26.12.2023 12:00'
   */
  public static formatDateString(input: string) {
    const [firstPart, secondPart] = input.split(" - ");
    const date = firstPart.split(" ")[0];
    return firstPart + " - " + date + " " + secondPart;
  }

  public static parseLocalTimeArray(timeWindowsStr: string): TimeWindowLocalTime[] {
    try {
      if (!timeWindowsStr) {
        return [];
      }
      const result = this.parse(timeWindowsStr, s => LocalTime.parse(s, this.TIME))
        .map(twArr => new TimeWindowLocalTime(twArr[0], twArr[1]))
      return this.mergeOverlappingIntervals(result);
    } catch (e) {
      return null;
    }
  }

  private static mergeOverlappingIntervals(timeWindows: TimeWindowLocalTime[]): TimeWindowLocalTime[] {
    if (!timeWindows.length) return [];

    // Сортировка интервалов по времени начала
    timeWindows.sort((a, b) => a.from.compareTo(b.from));

    const mergedIntervals: TimeWindowLocalTime[] = [timeWindows[0]];

    for (let i = 1; i < timeWindows.length; i++) {
      const current = timeWindows[i];
      const lastMerged = mergedIntervals[mergedIntervals.length - 1];

      if (lastMerged.overlapsWith(current)) {
        // Если текущий интервал пересекается с последним объединённым интервалом, объединяем их
        lastMerged.mergeWith(current);
      } else {
        // Иначе добавляем текущий интервал как новый
        mergedIntervals.push(current);
      }
    }

    return mergedIntervals;
  }

  private static parse<T>(timeWindowsStr: string, parser: (s: string) => T): [T, T][] {
    return timeWindowsStr.split(this.JOINER.trim())
      .map(s => s.trim())
      .map(s => s.split(this.SEPARATOR.trim()))
      .map(([from, to]) => [from.trim(), to.trim()])
      .filter(([from, to]) => from && to)
      .map(([from, to]) => [parser(from), parser(to)]);
  }

  /**
   * dd.mm.yyyy hh:mm - dd.mm.yyyy hh:mm
   */
  public static toFullString(tw: TimeWindow<any>[]): string {
    return this.toString(tw, false);
  }

  /**
   * dd.MM hh:mm - hh:mm
   * dd.MM hh:mm - dd.MM hh:mm
   */
  public static toShortString(tw: TimeWindow<any>[]): string {
    return this.toString(tw, true);
  }

  private static toString(timeWindows: TimeWindow<any>[], isShort: boolean): string {
    if (!timeWindows) {
      return null;
    }
    return timeWindows
      .map(tw => this.formatTimeWindow(tw, isShort))
      .join(this.JOINER)
  }

  public static formatTimeWindow(tw: TimeWindow<any>, isShort: boolean = false): string {
    if (!tw) {
      return null;
    }
    if (tw instanceof TimeWindowLocalDateTime) {
      if (isShort) {
        return this.formatShortLocalDateTime(tw);
      } else {
        return this.formatFullLocalDateTime(tw);
      }
    }
    if (tw instanceof TimeWindowLocalTime) {
      return this.formatLocalTime(tw);
    }
    throw new Error('not converter for this time windows: ' + tw);
  }

  private static formatFullLocalDateTime(tw: Logistic.TimeWindowLocalDateTime) {
    return this.format(tw, this.DAY_MONTH_YEAR_TIME, this.DAY_MONTH_YEAR_TIME);
  }

  private static formatShortLocalDateTime(tw: Logistic.TimeWindowLocalDateTime): string {
    if (tw.from.dayOfYear() === tw.to.dayOfYear()) {
      return this.format(tw, this.DAY_MONTH_TIME, this.TIME);
    } else {
      return this.format(tw, this.DAY_MONTH_TIME, this.DAY_MONTH_TIME)
    }
  }

  private static formatLocalTime(tw: Logistic.TimeWindowLocalTime): string {
    return this.format(tw, this.TIME, this.TIME);
  }

  private static format(tw: TimeWindow<any>, fromFormatter: DateTimeFormatter, toFormatter: DateTimeFormatter): string {
    if (!tw.from || !tw.to) {
      return null;
    }
    return `${tw.from.format(fromFormatter)}${this.SEPARATOR}${tw.to.format(toFormatter)}`;
  }
}
