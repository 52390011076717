import {DateTimeFormatter, Instant, LocalDateTime, LocalTime, Temporal, ZoneOffset} from "@js-joda/core";
import {DurationConverter} from "../utils/duration-converter";
import {Driver} from "./driver.model";
import {GpsCoordinate} from "./gps-coordinate.model";
import {PageModel} from "./page-model.model";
import {Page} from "./page.model";
import {UnitShort} from "./unit-short.model";
import {Address} from "./address.model";
import {
  RouteIdGenerator
} from "../../../../user-portal/src/app/system/logistic/logistic/logistic-planning/planning-dialog/do-request-step/solution-map/route-id-generator";
import {NamedArchivable} from "./archiveable.model";
import {DayjsUtil} from "../dayjs.util";
import {LocalTimeRangeI} from "../app-interfaces/local-time-range-i";

export namespace Logistic {

  export class Job implements Skillable {

    protected type = 'JOB';

    constructor(
      public id: number,
      public order: Order,
      public delivery: Step,
      public status: Status,
      public archived: boolean) {
      delivery.stepAction = StepAction.DELIVERY;
    }

    public isAssigned(): boolean {
      return this.status !== Status.NOT_ASSIGNED;
    }

    /**
     * @return  shipment coordinates [delivery, pickup], for job [delivery]
     */
    public getCoordinates(): GpsCoordinate[] {
      const result = [this.delivery.getCoordinate()];
      if (this.isShipment()) {
        result.push(this.toShipment().pickup.getCoordinate());
      }
      return result;
    }

    public static valueOf(obj: Job): Job {
      if (!obj) {
        return null;
      }
      switch (obj.type) {
        case 'SHIPMENT':
          return Shipment.valueOf(<Shipment>obj)
        case 'JOB':
          return new Job(obj.id, Order.valueOf(obj.order), Step.valueOf(obj.delivery), obj.status, obj.archived)
      }
      throw Error(`Job: ${obj} can not cast`);
    }

    public static valuesOf(list: Job[]) {
      return list.map(i => this.valueOf(i));
    }

    public static pageableValueOf(o: Page<Job>): Page<Job> {
      return o ? new PageModel<Job>(o.empty, o.first, o.last, o.number, o.numberOfElements, o.totalElements, o.totalPages,
        o.urlParams, this.valuesOf(o.content)) : null;
    }

    public getPickupOptional(): Step | null {
      return this.isShipment() ? this.toShipment().pickup : null;
    }

    public isShipment(): boolean {
      return this instanceof Shipment;
    }

    public isJob(): boolean {
      return !this.isShipment();
    }

    public toShipment(): Shipment {
      return <Shipment><unknown>this;
    }

    get skills(): Skill[] {
      return this.order.skills;
    };
  }

  export enum Status {
    ASSIGNED = "ASSIGNED",
    NOT_ASSIGNED = "NOT_ASSIGNED",
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED",
    COMPLETED_LATE = "COMPLETED_LATE",
    OVERDUE = "OVERDUE",
    CANCELLED = "CANCELLED",
    CURRENT = "CURRENT",
  }

  export class Shipment extends Job {
    public pickup: Step;
    protected type = 'SHIPMENT';

    constructor(
      id: number,
      order: Order,
      pickup: Step,
      delivery: Step,
      status: Status,
      archived: boolean) {
      super(id, order, delivery, status, archived);
      this.pickup = pickup;
      this.pickup.stepAction = StepAction.PICKUP
      this.delivery.stepAction = StepAction.DELIVERY
    }

    public static valueOf(i: Shipment) {
      return new Shipment(i.id, Order.valueOf(i.order), Step.valueOf(i.pickup), Step.valueOf(i.delivery), i.status, i.archived)
    }
  }

  export abstract class Step implements Contactable {

    protected readonly type = null;

    protected constructor(
      public id: number,
      public timeWindows: TimeWindowLocalDateTime[],
      public duration: Duration,
      public contacts: Contact[],
      public status: Status,
      public planned: Planned,
      public completion: Completion,
      public stepAction = StepAction.UNKNOWN,
    ) {
    }

    private hasContact(): boolean {
      return this.contacts && this.contacts.length > 0;
    }

    public getContact(): Contact {
      return this.hasContact() ? this.contacts[0] : null;
    }

    public getContactNumbers(): string[] {
      return this.getContact()?.phoneNumbers || [];
    }

    public getTimeWindows(): TimeWindow<any>[] {
      if (this.isStepWarehouse() && !this.timeWindows) {
        return this.toStepWarehouse().getTimeWindows();
      } else {
        return this.timeWindows;
      }
    }

    public abstract getLocation(): Location;

    public getCoordinate(): GpsCoordinate {
      return this.getLocation().coordinate;
    }

    public abstract getLocationName(): string;

    public static valueOf(i: Step): Step {
      switch (i.type.toString()) {
        case 'ADDRESS' :
          return StepAddress.valueOf(<StepAddress>i);
        case 'WAREHOUSE' :
          return StepWarehouse.valueOf(<StepWarehouse>i);
        case 'TRANSIT' :
          return StepTransit.valueOf(<StepTransit>i);
      }
      throw new Error(`type ${i.type} was not found`)
    }

    public toStepAddress(): Logistic.StepAddress {
      return <Logistic.StepAddress><unknown>this;
    }

    public toStepWarehouse(): Logistic.StepWarehouse {
      return <Logistic.StepWarehouse><unknown>this;
    }

    public isStepAddress(): boolean {
      return this instanceof StepAddress;
    }

    public isStepWarehouse(): boolean {
      return this instanceof StepWarehouse;
    }
  }

  export class StepAddress extends Step {
    public address: Location;
    protected type = 'ADDRESS';

    constructor(id: number,
                timeWindows: Logistic.TimeWindowLocalDateTime[],
                duration: Logistic.Duration,
                contacts: Logistic.Contact[],
                status: Logistic.Status,
                location: Location,
                planned: Planned,
                completion: Completion
    ) {
      super(id, timeWindows, duration, contacts, status, planned, completion);
      this.address = location;
    }

    getLocation(): Logistic.Location {
      return this.address;
    }

    public static valueOf(i: StepAddress): StepAddress {
      return new StepAddress(i.id, TimeWindowLocalDateTime.valuesOf(i.timeWindows), Duration.valueOf(i.duration), Contact.valuesOf(i.contacts), i.status, Location.valueOf(i.address), Planned.valueOf(i.planned), Completion.valueOf(i.completion))
    }

    getLocationName(): string {
      return this.address.address;
    }
  }

  export class StepWarehouse extends Step {
    public warehouse: Warehouse;

    protected readonly type = 'WAREHOUSE';

    constructor(id: number,
                timeWindows: Logistic.TimeWindowLocalDateTime[],
                duration: Logistic.Duration,
                status: Logistic.Status,
                warehouse: Warehouse,
                planned: Planned,
                completion: Completion
    ) {
      super(id, timeWindows, duration, [], status, planned, completion);
      this.warehouse = warehouse;
    }


    getTimeWindows(): Logistic.TimeWindow<any>[] {
      if (!this.timeWindows) {
        return this.timeWindows;
      } else {
        return this.warehouse.timeWindows;
      }
    }

    getLocation(): Logistic.Location {
      return this.warehouse.location;
    }

    public static valueOf(i: StepWarehouse): StepWarehouse {
      return new StepWarehouse(i.id, TimeWindowLocalDateTime.valuesOf(i.timeWindows), Duration.valueOf(i.duration), i.status, Warehouse.valueOf(i.warehouse), Planned.valueOf(i.planned), Completion.valueOf(i.completion))
    }

    getLocationName(): string {
      return this.warehouse.name;
    }
  }

  export class Completion {
    constructor(public comment: string,
                public gpsCoordinate: GpsCoordinate,
                public time: Instant) {
    }

    public static valueOf(i: Completion): Completion {
      return i ? new Completion(i.comment, GpsCoordinate.valueOf(i.gpsCoordinate), i.time) : null;
    }
  }

  export class Planned {

    constructor(public arrivalTime: string,
                public duration: PlannedDuration) {
    }

    public static valueOf(i: Planned): Planned {
      return i ? new Planned(i.arrivalTime, PlannedDuration.valueOf(i.duration)) : null;
    }
  }

  export enum StepAction {
    PICKUP = 'PICKUP', DELIVERY = 'DELIVERY', JOB = 'JOB', UNKNOWN = 'UNKNOWN'
  }

  export class Contact {

    public static readonly EMPTY = new Contact(null, []);

    constructor(public name: string,
                public phoneNumbers: string[]) {
    }

    public static valueOf(i: Contact) {
      return new Contact(i.name, i.phoneNumbers);
    }

    public static valuesOf(list: Contact[]): Contact[] {
      return list.map(i => this.valueOf(i));
    }
  }

  export class Duration {
    constructor(public setup: number,
                public service: number) {
    }

    public static valueOf(i: Duration) {
      return new Duration(i.setup, i.service);
    }
  }

  export class PlannedDuration extends Duration {

    constructor(setup: number, service: number, public waitingTime: number) {
      super(setup, service);
    }

    public static valueOf(i: PlannedDuration): PlannedDuration {
      return i ? new PlannedDuration(i.setup, i.service, i.waitingTime) : null;
    }
  }

  export class Location {

    public static DEFAULT_RADIUS = 300;

    constructor(
      public id: number,
      public coordinate: GpsCoordinate,
      public radius: number,
      public address: string) {
    }

    public static address(coordinate: GpsCoordinate, addresName: string, radius: number): Location {
      return new Logistic.Location(null, coordinate, radius, addresName)
    }

    public static valueOf(l: Location): Location {
      return new Location(l.id, GpsCoordinate.valueOf(l.coordinate), l.radius, l.address);
    }

    public static fromAddress(address: Address): Location {
      return new Location(null, GpsCoordinate.valueOf(address), Location.DEFAULT_RADIUS, address.name);
    }
  }

  export interface WarehouseCreate {
    name: string,
    location: Location,
    description: string,
    timeWindows: TimeWindowLocalTime[],
    contacts: Contact[],
  }

  export interface WarehouseUpdate extends WarehouseCreate {
    id: number
  }

  export class Warehouse implements Contactable, WarehouseUpdate, NamedArchivable {

    constructor(public id: number,
                public name: string,
                public location: Location,
                public description: string,
                public timeWindows: TimeWindowLocalTime[],
                public contacts: Contact[],
                public archived: boolean) {
    }

    public getName(): string {
      return this.name;
    }

    public static valueOf(i: Warehouse): Warehouse {
      return new Warehouse(i.id, i.name, Location.valueOf(i.location), i.description,
        TimeWindowLocalTime.valuesOf(i.timeWindows), Contact.valuesOf(i.contacts), i.archived)
    }

    public static valuesOf(list: Warehouse[]): Warehouse[] {
      return list.map(i => this.valueOf(i));
    }

    getContact(): Logistic.Contact {
      return this.contacts && this.contacts.length > 0 ? this.contacts[0] : null;
    }

    getContactNumbers(): string[] {
      return this.getContact()?.phoneNumbers || [];
    }
  }

  export interface Contactable {
    /**
     * @return contact or {@code null} if not exists
     */
    getContact(): Contact;

    /**
     * @return numbers list or {@code []} if not exists
     */
    getContactNumbers(): string[];

  }

  export interface CarrierCreate {
    unit: UnitShort,
    description: string,
    type: CarrierType,
    start: GpsCoordinate,
    end: GpsCoordinate,
    speedFactor: number,
    capacity: Capacity,
    skills: Skill[],
    workShift: WorkShift,
    maxTasks: number,
    maxTravelTime: number
  }

  export interface CarrierUpdate extends CarrierCreate {
    id: number;
  }

  export class Carrier implements Skillable, CarrierUpdate, NamedArchivable {
    constructor(public id: number,
                public unit: UnitShort,
                public description: string,
                public type: CarrierType,
                public start: GpsCoordinate,
                public end: GpsCoordinate,
                public speedFactor: number,
                public capacity: Capacity,
                public skills: Skill[],
                public workShift: WorkShift,
                public maxTasks: number,
                public maxTravelTime: number,
                public maxTravelDistance: number,
                public cost: CarrierCost,
                public archived: boolean) {
    }

    getName(): string {
      return this.unit.name;
    }

    public static valueOf(i: Carrier) {
      return new Carrier(i.id, UnitShort.valueOf(i.unit), i.description, i.type,
        GpsCoordinate.valueOf(i.start),
        GpsCoordinate.valueOf(i.end),
        i.speedFactor,
        Capacity.valueOf(i.capacity),
        Skill.valuesOf(i.skills),
        WorkShift.valueOf(i.workShift),
        i.maxTasks,
        i.maxTravelTime,
        i.maxTravelDistance,
        CarrierCost.valueOf(i.cost),
        i.archived
      )
    }

    /**
     * @deprecated The method should not be used
     */
    public getTimeWindow(): TimeWindowLocalTime[] {
      return [this.workShift.timeWindow];
    }

    public static valuesOf(list: Carrier[]): Carrier[] {
      return list.map(c => this.valueOf(c));
    }

    public static blankCarrier(unit: UnitShort): Carrier {
      return new Carrier(
        null,
        unit,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        false
      )
    }
  }

  export class CarrierCost {
    constructor(public fixed: number,
                public perKm: number) {
    }

    public static valueOf(cost: CarrierCost): CarrierCost {
      return cost ? new Logistic.CarrierCost(cost.fixed, cost.perKm) : null;
    }
  }

  export class Capacity implements Measurable {
    constructor(public weight: number,
                public volume: number,
                public length: number,
                public width: number,
                public height: number) {

    }

    public compareTo(other: Capacity): number {
      if (this.weight !== other.weight) {
        return this.weight - other.weight;
      }

      if (this.volume !== other.volume) {
        return this.volume - other.volume;
      }

      if (this.length !== other.length) {
        return this.length - other.length;
      }

      if (this.width !== other.width) {
        return this.width - other.width;
      }

      return this.height - other.height;
    }

    public static valueOf(i: Capacity) {
      return new Capacity(i.weight, i.volume, i.length, i.width, i.height);
    }
  }

  export enum CarrierType {
    CAR = 'CAR', TRUCK = 'TRUCK'
  }

  export abstract class TimeWindow<T extends Temporal> {

    constructor(public from: T,
                public to: T) {
    }

    /**
     * @return seconds
     */
    public abstract getDuration();

    public abstract toTimeWindowLocalTime();
  }

  export class TimeWindowLocalTime extends TimeWindow<LocalTime> {

    contains(other: TimeWindowLocalTime): boolean {
      return this.from.compareTo(other.from) <= 0 && this.to.compareTo(other.to) >= 0;
    }

    equals(other: TimeWindowLocalTime): boolean {
      return this.from.compareTo(other.from) === 0 && this.to.compareTo(other.to) === 0;
    }

    overlapsWith(other: TimeWindowLocalTime): boolean {
      return this.to.isAfter(other.from) && this.from.isBefore(other.to);
    }

    isIntersect(other: TimeWindowLocalTime): boolean {

      // The windows intersect if the latest start is before the earliest end
      return this.from.isBefore(other.to) && this.to.isAfter(other.to);
    }

    mergeWith(other: TimeWindowLocalTime): void {
      this.from = this.from.isBefore(other.from) ? this.from : other.from;
      this.to = this.to.isAfter(other.to) ? this.to : other.to;
    }

    public getDuration() {
      return this.to.toSecondOfDay() - this.from.toSecondOfDay();
    }

    public static FULL_DAY = new TimeWindowLocalTime(LocalTime.MIN, LocalTime.MAX);

    public static valueOf(i: TimeWindow<LocalTime>): TimeWindowLocalTime {
      return new TimeWindowLocalTime(LocalTime.parse(i.from.toString()), LocalTime.parse(i.to.toString()));
    }

    public static valuesOf(list: TimeWindow<LocalTime>[]): TimeWindowLocalTime[] {
      return list.map(i => this.valueOf(i));
    }

    public static parse(from: string, to: string) {
      return new TimeWindowLocalTime(LocalTime.parse(from), LocalTime.parse(to));
    }

    toTimeWindowLocalTime() {
      return this;
    }
  }

  export class TimeWindowLocalDateTime extends TimeWindow<LocalDateTime> {

    public static valueOf(i: TimeWindow<LocalDateTime>): TimeWindowLocalDateTime {
      return new TimeWindowLocalDateTime(LocalDateTime.parse(i.from.toString()), LocalDateTime.parse(i.to.toString()));
    }

    public static valuesOf(list: TimeWindowLocalDateTime[]): TimeWindowLocalDateTime[] {
      return list.map(i => this.valueOf(i));
    }

    public getDuration(): number {
      return this.to.toEpochSecond(ZoneOffset.ofHours(0)) - this.from.toEpochSecond(ZoneOffset.ofHours(0))
    }

    toTimeWindowLocalTime() {
      return new TimeWindowLocalTime(this.from.toLocalTime(), this.to.toLocalTime());
    }
  }

  export class Order {
    constructor(public id: number,
                public name: string,
                public description: string,
                public comment: string,
                public amount: Amount,
                public skills: Skill[],
                public priority: Priority) {
    }

    public static valueOf(i: Order): Order {
      if (i) {
        return new Logistic.Order(i.id, i.name, i.description, i.comment, Amount.valueOf(i.amount), Skill.valuesOf(i.skills), i.priority)
      }
      return null;
    }
  }

  export class Amount implements Measurable {

    constructor(
      public weight: number,
      public volume: number,
      public length: number,
      public width: number,
      public height: number) {
    }

    public static valueOf(i: Amount) {
      return new Amount(i.weight, i.volume, i.length, i.width, i.height);
    }

    public static empty() {
      return new Amount(null, null, null, null, null);
    }
  }

  export interface SkillCreate {
    name: string,
    description: string
  }

  export interface SkillUpdate extends SkillCreate {
    id: number
  }

  export class Skill implements SkillUpdate, NamedArchivable {
    constructor(public id: number,
                public name: string,
                public description: string,
                public archived: boolean) {
    }

    getName(): string {
      return this.name;
    }

    public static valueOf(i: Skill): Skill {
      return new Skill(i.id, i.name, i.description, i.archived);
    }

    public static valuesOf(list: Skill[]): Skill[] {
      return list.map(i => this.valueOf(i));
    }
  }

  export enum Priority {
    LOW = 'LOW', MEDIUM = 'MEDIUM', HIGH = 'HIGH', HIGHEST = 'HIGHEST', CRITICAL = 'CRITICAL'
  }

  export class Optimization {
    constructor(public jobs: Job[],
                public disbandRouteIds: number[],
                public carriers: Carrier[],
                public planningRange: LocalDateTimeRange
    ) {
    }
  }

  export class Solution {
    constructor(public routes: Route[],
                public disbandRoutes: Route[],
                public unassigned: UnassignedJob[]) {
    }

    public static valueOf(i: Solution): Solution {
      return new Solution(Route.valuesOf(i.routes), Route.valuesOf(i.disbandRoutes), UnassignedJob.valuesOf(i.unassigned));
    }
  }

  export class SolutionGrouped {
    constructor(public routes: GroupedRoute[],
                public disbandRoutes: GroupedRoute[],
                public unassigned: UnassignedJob[]) {
    }

    public static valueOf(i: SolutionGrouped): SolutionGrouped {
      return new SolutionGrouped(GroupedRoute.valuesOf(i.routes), GroupedRoute.valuesOf(i.disbandRoutes), UnassignedJob.valuesOf(i.unassigned));
    }
  }

  export class UnassignedJob {
    constructor(public job: Job,
                public cause: string) {
    }

    public static valueOf(i: UnassignedJob) {
      return new UnassignedJob(Job.valueOf(i.job), i.cause);
    }

    public static valuesOf(list: UnassignedJob[]) {
      return list.map(j => this.valueOf(j));
    }
  }

  export class Employment {
    constructor(public workTime,
                public distance,
                public travelTime,
                public stageCount,
                public total) {
    }

    public static valueOf(i: Employment): Employment {
      return new Employment(i.workTime, i.distance, i.travelTime, i.stageCount, i.total);
    }
  }

  export abstract class BaseRoute {

    private static readonly SURROGATE_ID_GENERATOR = new RouteIdGenerator();
    private _surrogateId;

    constructor(
      public id: number,
      public carrier: Carrier,
      public driver: Driver,
      public status: Status,
      public actual: ActualState,
      public deliveryAmount: Amount,
      public pickupAmount: Amount,
      public duration: number,
      public distance: number,
      public waitingTime: number,
      public geometry: string,
      public cost: number,
      public employment: Employment,
      public archived: boolean) {
    }

    // не всегда у есть id у роута можно воспользоваться этим вместо id
    public getSurrogateId(): number {
      if (!this._surrogateId) {
        this._surrogateId = BaseRoute.SURROGATE_ID_GENERATOR.generateRouteId(this);
      }
      return this._surrogateId;
    }

    public getCurrentStage(): Stage | undefined {
      return this.getStages().find(s => s.step.status === Status.CURRENT);
    }

    public getWorkTimeWindows() {
      return new TimeWindowLocalDateTime(this.getStartRouteTime(), this.getEndRouteTime());
    }

    public getStartRouteTime(): LocalDateTime {
      return this.getFirstStage().getArrival();
    }

    public getEndRouteTime(): LocalDateTime {
      return this.getLastStage().getArrival();
    }

    public getOrdersCount(): number {
      const orderIds = this.getStages().filter(s => s.order).map(s => s.order.id);
      return new Set(orderIds).size;
    }

    public abstract getStages(): Stage[];

    public abstract getFirstStage(): Stage;

    public abstract getLastStage(): Stage;
  }

  export class Route extends BaseRoute {
    constructor(
      id: number,
      carrier: Carrier,
      driver: Driver,
      status: Status,
      actual: ActualState,
      deliveryAmount: Amount,
      pickupAmount: Amount,
      duration: number,
      distance: number,
      waitingTime: number,
      geometry: string,
      cost: number,
      employment: Employment,
      archived: boolean,
      public stages: Stage[]) {
      super(id, carrier, driver, status, actual,
        deliveryAmount, pickupAmount,
        duration, distance, waitingTime,
        geometry, cost, employment, archived)
    }

    public override getStages(): Logistic.Stage[] {
      return this.stages;
    }

    public override getFirstStage(): Logistic.Stage {
      return this.stages[0];
    }

    public override getLastStage(): Logistic.Stage {
      return this.stages[this.stages.length - 1];
    }

    public static valueOf(i: Route): Route {
      return new Route(i.id, Carrier.valueOf(i.carrier), Driver.valueOf(i.driver), i.status, ActualState.valueOf(i.actual), Amount.valueOf(i.deliveryAmount), Amount.valueOf(i.pickupAmount), i.duration, i.distance, i.waitingTime, i.geometry, i.cost, i.employment, i.archived, Stage.valuesOf(i.stages));
    }

    public static valuesOf(list: Route[]): Route[] {
      return list.map(r => Route.valueOf(r));
    }

    public static pageableValueOf(o: Page<Logistic.Route>): Page<Logistic.Route> {
      return o ? new PageModel<Logistic.Route>(o.empty, o.first, o.last, o.number, o.numberOfElements, o.totalElements, o.totalPages,
        o.urlParams, Logistic.Route.valuesOf(o.content)) : null;
    }
  }

  export class GroupedRoute extends BaseRoute {

    private flatStages: Stage[];

    constructor(
      id: number,
      carrier: Carrier,
      driver: Driver,
      status: Status,
      actual: ActualState,
      deliveryAmount: Amount,
      pickupAmount: Amount,
      duration: number,
      distance: number,
      waitingTime: number,
      geometry: string,
      cost: number,
      employment: Employment,
      archived: boolean,
      public stageGroups: StageGroup[]) {
      super(id, carrier, driver, status, actual,
        deliveryAmount, pickupAmount,
        duration, distance, waitingTime,
        geometry, cost, employment, archived)
    }

    public override getStages(): Logistic.Stage[] {
      if (!this.flatStages) {
        this.flatStages = this.stageGroups.reduce((acc, group) => acc.concat(group.stages), []);
      }
      return this.flatStages;
    }

    public override getFirstStage(): Logistic.Stage {
      return this.stageGroups[0].getFirst();
    }

    public override getLastStage(): Logistic.Stage {
      return this.stageGroups[this.stageGroups.length - 1].getLast();
    }

    public static valueOf(i: GroupedRoute): GroupedRoute {
      return new GroupedRoute(i.id, Carrier.valueOf(i.carrier), Driver.valueOf(i.driver), i.status, ActualState.valueOf(i.actual), Amount.valueOf(i.deliveryAmount), Amount.valueOf(i.pickupAmount), i.duration, i.distance, i.waitingTime, i.geometry, i.cost, i.employment, i.archived, StageGroup.valuesOf(i.stageGroups));
    }

    public static valuesOf(list: GroupedRoute[]): GroupedRoute[] {
      return list ? list.map(r => GroupedRoute.valueOf(r)) : [];
    }

    public static pageableValueOf(o: Page<Logistic.GroupedRoute>): Page<Logistic.GroupedRoute> {
      return o ? new PageModel<Logistic.GroupedRoute>(o.empty, o.first, o.last, o.number, o.numberOfElements, o.totalElements, o.totalPages,
        o.urlParams, Logistic.GroupedRoute.valuesOf(o.content)) : null;
    }
  }

  enum StageGroupType {
    SINGE_GROUP = "SINGLE_GROUP",
    WAREHOUSE_GROUP = "WAREHOUSE_GROUP",
    LOCATION_GROUP = "LOCATION_GROUP"

  }

  export abstract class StageGroup {
    protected readonly type = null;

    constructor(public stages: Stage[]) {
    }

    public getFirst() {
      return this.stages[0];
    }

    public getLast() {
      return this.stages[this.stages.length - 1];
    }

    // если есть Stage со статусом CURRENT то статус CURRENT
    // если нет CURRENT и все ASSIGNED то assigned
    // иначе определяется по последнему
    public getStatus(): Logistic.Status {
      if (this.stages.some(stage => stage.step.status === Logistic.Status.CURRENT)) {
        return Logistic.Status.CURRENT;
      } else if (this.stages.every(stage => stage.step.status === Logistic.Status.ASSIGNED)) {
        return Logistic.Status.ASSIGNED;
      } else {
        return this.getLast().step.status;
      }
    }

    public getArrival(): LocalDateTime {
      return this.getFirst().getArrival();
    }

    public getCumulativeDistance(): number {
      return this.getFirst().distance;
    }

    public getCumulativeDuration(): number {
      return this.getFirst().duration;
    }

    public totalWaitingTime(): number {
      return this.stages.reduce((sum, stage) => sum + stage.getWaitingTime(), 0);
    }

    public static valuesOf(list: StageGroup[]): StageGroup[] {
      return list.map(i => StageGroup.valueOf(i));
    }

    public static valueOf(i: StageGroup): StageGroup {
      switch (i.type.toString()) {
        case StageGroupType.SINGE_GROUP :
          return SingleStageGroup.valueOf(<SingleStageGroup>i);
        case StageGroupType.WAREHOUSE_GROUP :
          return WarehouseStageGroup.valueOf(<WarehouseStageGroup>i);
        case StageGroupType.LOCATION_GROUP :
          return LocationStageGroup.valueOf(<LocationStageGroup>i);
      }
      throw new Error(`type ${i.type} was not found`)
    }

    public toSingeStageGroup(): SingleStageGroup {
      return <SingleStageGroup><unknown>this;
    }

    public castToSingleStageGroup(): SingleStageGroup | undefined {
      if (this.isSingleStageGroup()) {
        return this.toSingeStageGroup();
      }
      return undefined;
    }

    public toLocationStageGroup(): LocationStageGroup {
      return <LocationStageGroup><unknown>this;
    }

    public toWarehouseStageGroup(): WarehouseStageGroup {
      return <WarehouseStageGroup><unknown>this;
    }

    public isSingleStageGroup(): boolean {
      return this instanceof SingleStageGroup;
    }

    public isWarehouseStageGroup(): boolean {
      return this instanceof WarehouseStageGroup;
    }

    public isLocationStageGroup(): boolean {
      return this instanceof LocationStageGroup;
    }

    public abstract getLocationName(): string;

    public abstract getCoordinate(): GpsCoordinate;
  }

  export class SingleStageGroup extends StageGroup {

    protected readonly type = StageGroupType.SINGE_GROUP;

    constructor(stages: Stage[]) {
      super(stages);
    }

    public get(): Stage {
      return this.stages[0];
    }

    public override getLocationName(): string {
      return this.get().step.getLocationName();
    }

    public override getCoordinate(): GpsCoordinate {
      return this.get().step.getCoordinate();
    }

    public static valueOf(i: SingleStageGroup) {
      return new SingleStageGroup(Stage.valuesOf(i.stages));
    }


  }

  export class WarehouseStageGroup extends StageGroup {

    protected readonly type = StageGroupType.WAREHOUSE_GROUP;

    constructor(stages: Stage[],
                public warehouse: Warehouse) {
      super(stages);
    }

    public override getLocationName(): string {
      return this.warehouse.name;
    }

    public override getCoordinate(): GpsCoordinate {
      return this.warehouse.location.coordinate;
    }

    public static valueOf(i: WarehouseStageGroup) {
      return new WarehouseStageGroup(Stage.valuesOf(i.stages), Warehouse.valueOf(i.warehouse));
    }
  }

  export class LocationStageGroup extends StageGroup {

    protected readonly type = StageGroupType.LOCATION_GROUP;

    constructor(public stages: Stage[],
                public location: Location) {
      super(stages);
    }

    public getLocationName(): string {
      return this.location.address;
    }

    public override getCoordinate(): GpsCoordinate {
      return this.location.coordinate;
    }

    public static valueOf(i: LocationStageGroup) {
      return new LocationStageGroup(Stage.valuesOf(i.stages), Location.valueOf(i.location));
    }
  }

  export class ActualState {
    constructor(
      public startTime: string,
      public startOdo: number,
      public endTime: string,
      public endOdo: number,
      public mileage: number) {
    }

    public getDuration() {
      let endBoundTime = this.endTime ? this.endTime : DayjsUtil.instant().toISOString();
      return DurationConverter.stringDatetimeToSecondsDuration([this.startTime, endBoundTime]);
    }

    public static valueOf(o: ActualState): ActualState {
      return new ActualState(o.startTime, o.startOdo, o.endTime, o.endOdo, o.mileage);
    }
  }

  export class Stage {
    constructor(
      public step: Step,
      public order: Order,
      public action: StageAction,
      public load: Amount,
      public distance: number,
      public duration: number,
    ) {
    }

    public getDistanceKilometers(scale: number = 1): string {
      return (this.distance / 1000).toFixed(scale);
    }

    public static valueOf(i: Stage): Stage {
      return new Stage(Step.valueOf(i.step), Order.valueOf(i.order), i.action, Amount.valueOf(i.load), i.distance, i.duration);
    }

    public getArrival(): LocalDateTime {
      return this.step.planned.arrivalTime ? LocalDateTime.parse(this.step.planned.arrivalTime, DateTimeFormatter.ISO_LOCAL_DATE_TIME) : null;
    }

    public getWaitingTime(): number {
      return this.step.planned.duration.waitingTime;
    }

    public getArrivalFormatted(formatter: DateTimeFormatter = DateTimeFormatter.ofPattern("dd.MM hh:mm")): string {
      return this.getArrival().format(formatter);
    }

    public static valuesOf(list: Stage[]): Stage[] {
      return list.map(s => Stage.valueOf(s));
    }
  }

  export class StepTransit extends StepAddress {

    protected readonly type = 'TRANSIT';

    constructor(id: number,
                timeWindows: Logistic.TimeWindowLocalDateTime[],
                duration: Logistic.Duration,
                contacts: Logistic.Contact[],
                status: Logistic.Status,
                location: Location,
                planned: Planned,
                completion: Completion) {
      super(id, timeWindows, duration, contacts, status, location, planned, completion);
    }

    public static valueOf(i: StepTransit): StepTransit {
      return new StepTransit(i.id, TimeWindowLocalDateTime.valuesOf(i.timeWindows),
        Duration.valueOf(i.duration), Contact.valuesOf(i.contacts), i.status, Location.valueOf(i.address), Planned.valueOf(i.planned), Completion.valueOf(i.completion))
    }


    getLocationName(): string {
      return this.address.address;
    }
  }

  export enum StageAction {
    PICKUP = 'PICKUP', DELIVERY = 'DELIVERY', JOB = 'JOB', START = 'START', END = 'END'
  }

  export class LocalDateTimeRange implements LocalTimeRangeI {
    public constructor(public from: LocalDateTime,
                       public to: LocalDateTime) {
    }

    public offsetDays(offset: number): LocalDateTimeRange {
      return new LocalDateTimeRange(
        this.from.plusDays(offset),
        this.to.plusDays(offset)
      );
    }
  }

  export interface Skillable {
    skills: Skill[];
  }

  export interface Measurable {
    /*** kilograms */
    weight: number | null,

    /*** m3 */
    volume: number | null,

    /*** meters */
    length: number | null,

    /*** meters */
    width: number | null,

    /*** meters */
    height: number | null,
  }

  export class WorkShift {

    public static readonly FULL_DAY = new WorkShift(TimeWindowLocalTime.FULL_DAY, []);

    constructor(public timeWindow: TimeWindowLocalTime,
                public breaks: Break[]) {
    }

    public static valueOf(i: WorkShift): WorkShift {
      return new Logistic.WorkShift(TimeWindowLocalTime.valueOf(i.timeWindow), Logistic.Break.valuesOf(i.breaks))
    }

    public static valuesOf(list: WorkShift[]): WorkShift[] {
      return list.map(v => WorkShift.valueOf(v));
    }
  }

  export class Break {
    constructor(public description: string,
                public timeWindows: TimeWindowLocalTime[],
                public service: number) {
    }

    public static valueOf(i: Break): Break {
      return new Logistic.Break(i.description, TimeWindowLocalTime.valuesOf(i.timeWindows), i.service);
    }

    public static valuesOf(list: Break[]): Break[] {
      return list.map(v => Break.valueOf(v));
    }
  }
}
