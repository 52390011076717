import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterOperation } from '../modelinterfaces/enums/filter-operation';
import { PageParam } from '../modelinterfaces/enums/page-param';
import { NotificationCount } from '../modelinterfaces/notification-count.model';
import { NotificationFilters } from '../modelinterfaces/notification-filters.model';
import { Notification } from '../modelinterfaces/notification.model';
import { Page } from '../modelinterfaces/page.model';
import { HttpParamsUtil } from '../utils/http-params-util';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getPage(unitId: number,
                 lang: string,
                 pageIndex: number,
                 pageSize: number,
                 sort: string,
                 filters: NotificationFilters): Observable<Page<Notification>> {
    const url = `${this.environmentHelper.urlV2}/notification`;
    const params = this.createParams(unitId, lang, pageIndex, pageSize, sort, filters);
    return this.http.get<Page<Notification>>(url, {params: params}).pipe(
      map(page => Notification.pageableValueOf(page))
    );
  }

  public getById(notificationId: number, lang: string): Observable<Notification> {
    const url = `${this.environmentHelper.urlV2}/notification/${notificationId}`;
    return this.http.get<Notification>(url, {params: HttpParamsUtil.createByLang(lang)}).pipe(
      map(obj => Notification.valueOf(obj))
    );
  }

  private createParams(unitId: number,
                       lang: string,
                       pageIndex: number,
                       pageSize: number,
                       sort: string,
                       filters: NotificationFilters): HttpParams {
    let params = new HttpParams();
    Object.keys(filters).forEach(key => {
      if (filters[key].paramValue) {
        params = params.set(filters[key].param, filters[key].paramValue);
      }
    });
    if (unitId) {
      params = params.set('unitIdFilter', FilterOperation.EQUALS + unitId.toString());
    }
    params = params.set('lang', lang);
    params = params.set(PageParam.PAGE, String(pageIndex));
    params = params.set(PageParam.SIZE, String(pageSize));
    params = params.set(PageParam.SORT, sort);
    return params;
  }

  public getCounts(): Observable<NotificationCount> {
    const url = `${this.environmentHelper.urlV2}/notificationCounts`;
    return this.http.get<NotificationCount>(url).pipe(
      map(obj => NotificationCount.valueOf(obj))
    );
  }

  public setNotificationRead(notificationIdList: number[]): Observable<number[]> {
    const url = `${this.environmentHelper.urlV2}/notification/read`;
    return this.http.patch<number[]>(url, notificationIdList);
  }

  public setNotificationReadAll(unitId: number): Observable<number[]> {
    const url = `${this.environmentHelper.urlV2}/notification/read`;

    let params = new HttpParams();
    params = params.set('unitId', unitId.toString());

    return this.http.patch<number[]>(url, null, {params: params});
  }

  public setNotificationUnRead(notificationIdList: number[]): Observable<number[]> {
    const url = `${this.environmentHelper.urlV2}/notification/unRead`;
    return this.http.patch<number[]>(url, notificationIdList);
  }
}
