import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import {EnvironmentHelper} from './environment-helper';
import {UnitStateStorage} from '../modelinterfaces/unit-state-storage.model';

@Injectable({
  providedIn: 'root'
})

export class UnitStateStorageService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getData(): Observable<UnitStateStorage> {
    const url = `${this.environmentHelper.urlV2}/unitStateStorage`;
    return this.http.get<UnitStateStorage>(url).pipe(
      map(state => UnitStateStorage.valueOf(state))
    );
  }
}
