import CircleStyle, {Options} from "ol/style/Circle";

export interface OlCircleStyleOpts extends Options {

}

export class OlCircleStyleFactory {
  public static create(opts: OlCircleStyleOpts) {
    return new CircleStyle(opts)
  }
}``
