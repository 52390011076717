import TextStyle, {Options} from "ol/style/Text";

export interface OlTextStyleOpts extends Options {

}

export class OlTextStyleFactory {
  public static create(opts: OlTextStyleOpts) {
    return new TextStyle(opts);
  }
}
