import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

export enum GeofenceMode {
  LIST = 'list',
  EDIT = 'edit',
  ADD = 'add'
}

@Injectable({providedIn: 'root'})

export class GeofenceManagerModeService {

  private currentModeSource = new BehaviorSubject<GeofenceMode>(GeofenceMode.LIST);
  private currentMode$: Observable<GeofenceMode> = this.currentModeSource.asObservable();

  public changeState(m: GeofenceMode): void {
    this.currentModeSource.next(m);
  }

  public state$(): Observable<GeofenceMode> {
    return this.currentMode$;
  }
}
