import { Injectable } from '@angular/core';
import { Coordinate } from 'ol/coordinate';
import Feature from 'ol/Feature';
import GeometryType from 'ol/geom/GeometryType';
import Draw from 'ol/interaction/Draw';
import Modify from 'ol/interaction/Modify';
import Snap from 'ol/interaction/Snap';
import VectorLayer from 'ol/layer/Vector';
import OlMap from 'ol/Map';
import { transform } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { AppColor } from '../../../../../common-module/src/lib/app-enums/app-color';
import { Tab } from '../../../../../common-module/src/lib/app-enums/tab';
import { Geofence } from '../../../../../common-module/src/lib/modelinterfaces/geofence.model';
import { GpsCoordinate } from '../../../../../common-module/src/lib/modelinterfaces/gps-coordinate.model';
import { LayerName } from '../constants/enums/layer-name';
import { ZIndexLayer } from '../constants/z-index';
import { LayerUtil } from '../map-utils/layer.util';
import { OlFeature } from '../map-utils/OlFeature';
import { OlStyle } from '../map-utils/OlStyle';
import { GeofenceListService } from './geofence-list.service';

interface GeofenceTabSources<T> {
  [Tab.MAP]: T;
  [Tab.REPORT]: T;
  [Tab.NOTIFICATION]: T;
}

@Injectable({
  providedIn: 'root'
})

export class DrawGeofenceService {

  private readonly GEOFENCE_OPACITY = 0.3;

  private currentColor: AppColor = AppColor.GREEN;
  private draw: Draw;
  private map: OlMap;
  private modify: Modify;
  private snap: Snap;
  private sourceDraw: VectorSource;
  private vectorDraw: VectorLayer;
  private sourceGeofences: GeofenceTabSources<VectorSource> = {
    [Tab.MAP]: null,
    [Tab.REPORT]: null,
    [Tab.NOTIFICATION]: null
  };
  private vectorGeofences: GeofenceTabSources<VectorLayer> = {
    [Tab.MAP]: null,
    [Tab.REPORT]: null,
    [Tab.NOTIFICATION]: null
  };

  constructor(private geofenceListService: GeofenceListService) {
  }

  public initGlobalMap(map: OlMap): void {
    this.map = map;
    this.init(map, Tab.MAP);
  }

  public init(map: OlMap, tab: Tab): void {
    this.createGeofenceDraw(map, tab);
    this.geofenceListService.getSelectedList(tab).forEach(g => this.show(g, map, tab));
  }

  private createGeofenceDraw(map: OlMap, tab): void {
    if (!map.getLayers().getArray().some(layer => layer.get('name') === LayerName.GEOFENCES)) {
      this.sourceGeofences[tab] = new VectorSource();
      this.vectorGeofences[tab] = new VectorLayer({
          source: this.sourceGeofences[tab]
        }
      );
      this.vectorGeofences[tab].set('name', LayerName.GEOFENCES);
      this.vectorGeofences[tab].setZIndex(ZIndexLayer.GEOFENCE);
      map.addLayer(this.vectorGeofences[tab]);
    }
  }

  private createSourceDraw(): void {
    if (!this.map.getLayers().getArray().some(layer => layer.get('name') === LayerName.DRAW_GEOFENCE)) {
      this.sourceDraw = new VectorSource();
      this.vectorDraw = new VectorLayer();
      this.vectorDraw.setSource(this.sourceDraw);
      this.vectorDraw.set('name', LayerName.DRAW_GEOFENCE);
      this.vectorDraw.setZIndex(ZIndexLayer.GEOFENCE_DRAW);
      this.map.addLayer(this.vectorDraw);
    }
  }

  public show(g: Geofence, map: OlMap = this.map, tab = Tab.MAP): void {
    if (!g) {
      return;
    }
    this.geofenceListService.addSelectedGeofenceId(g.id, tab);
    const f: Feature = OlFeature.createGeofencePolygon(g, this.GEOFENCE_OPACITY);
    if (!this.isExistOnMap(g, tab)) {
      this.sourceGeofences[tab].addFeature(f);
    }
    this.centerMap(map, f);
  }

  private isExistOnMap(g: Geofence, tab = Tab.MAP): boolean {
    return !!this.sourceGeofences[tab].getFeatures().find(f => f.get('name') === g.name);
  }

  public hide(g: Geofence, tab = Tab.MAP): void {
    this.geofenceListService.removeSelectedGeofenceId(g.id, tab);
    const feature = this.sourceGeofences[tab].getFeatures().find(f => f.get('name') === g.name);
    if (feature) {
      this.sourceGeofences[tab].removeFeature(feature);
    }
  }

  public edit(g: Geofence): void {
    this.hide(g);
    const f: Feature = OlFeature.createGeofencePolygon(g, this.GEOFENCE_OPACITY);
    this.createSourceDraw();
    this.editFeature(f, g.color);
    this.centerMap(this.map, f);
  }

  // isNew = true if drew a new geofence
  private editFeature(f: Feature, color: string, isNew: boolean = false) {
    f.setStyle(OlStyle.createForDashedGeofencePolygon(color, this.GEOFENCE_OPACITY));
    if (!isNew) {
      this.sourceDraw.addFeature(f);
    }
    if (this.modify) {
      this.map.removeInteraction(this.modify);
    }
    this.modify = new Modify({source: this.sourceDraw});
    this.map.addInteraction(this.modify);
  }

  public drawPolygon(): void {
    this.createSourceDraw();
    this.map.removeInteraction(this.draw);
    this.map.removeInteraction(this.snap);
    this.draw = new Draw({
      source: this.sourceDraw,
      type: GeometryType.POLYGON
    });
    this.map.addInteraction(this.draw);
    this.snap = new Snap({source: this.sourceDraw});
    this.map.addInteraction(this.snap);
    this.sourceDraw.clear();

    this.draw.on('drawend', (e) => {
      this.map.removeInteraction(this.snap);
      this.map.removeInteraction(this.draw);
      this.editFeature(e.feature, this.currentColor, true);
    });
  }

  public stopEdit(): GpsCoordinate[] {
    const f: Feature = this.sourceDraw.getFeatures()[0];
    if (!f) {
      return null;
    }
    const coordinates = f.get('geometry').getCoordinates();
    this.sourceDraw.clear();
    this.map.removeInteraction(this.modify);
    return this.toGpsCoordinates(coordinates[0]);
  }

  private toGpsCoordinates(coordinates: Coordinate[]): GpsCoordinate[] {
    const gps = [];
    for (const c of coordinates) {
      const lonlat = transform(c, 'EPSG:3857', 'EPSG:4326');
      gps.push(new GpsCoordinate(lonlat[1], lonlat[0]));
    }
    return gps;
  }

  private centerMap(map: OlMap, f: Feature) {
    map.getView().fit(f.getGeometry().getExtent());
    map.getView().setZoom(map.getView().getZoom() - 1);
  }

  public changeColor(color: AppColor): void {
    this.currentColor = color;
    if (this.sourceDraw.getFeatures()[0]) {
      this.sourceDraw.getFeatures()[0].setStyle(OlStyle.createForDashedGeofencePolygon(color, this.GEOFENCE_OPACITY));
    }
  }

  public cancelAll(): void {
    if (this.sourceDraw) {
      this.sourceDraw.clear();
      LayerUtil.clear(this.map, LayerName.DRAW_GEOFENCE);
    }
    this.currentColor = AppColor.GREEN;
    this.map.removeInteraction(this.modify);
    this.map.removeInteraction(this.draw);
    this.map.removeInteraction(this.snap);
    this.map.updateSize();
  }
}
