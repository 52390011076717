import {Logistic} from "../../../../../../../../../../common-module/src/lib/modelinterfaces/logistic.model";
import GroupedRoute = Logistic.GroupedRoute;
import BaseRoute = Logistic.BaseRoute;

export class RouteIdGenerator {

  private idGenerator = 0;
  private generatedIdMap = new Map<BaseRoute, number>();

  public generateRouteId(route: BaseRoute) {
    if (route.id) {
      return route.id;
    } else if (this.generatedIdMap.has(route)) {
      return this.generatedIdMap.get(route);
    } else {
      this.idGenerator++;
      this.generatedIdMap.set(route, this.idGenerator);
      return this.idGenerator;
    }
  }
}
