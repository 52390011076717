import {OlStrokeFactory} from "../core/ol/ol-stroke.factory";
import {AppColor} from "../../../../../../../common-module/src/lib/app-enums/app-color";
import {OlStyleFactory} from "../core/ol/style/ol-style.factory";
import Style from "ol/style/Style";
import {StyleFactory, StyleOpts} from "../core/app/style.factory";

export interface OlArrowOpts extends StyleOpts {
  // primary
  color?: AppColor;

  //0.6
  width?: number;

  // rgba(0, 0, 0, 0)
  detectionColor?: string;

  // 3
  detectionWidth?: number;

  //undefined
  zIndex?: number;
}

export class ArrowStyleFactory extends StyleFactory<OlArrowOpts> {

  private static DEFAULT_COLOR = AppColor.PRIMARY;
  private static DEFAULT_WIDTH = 0.6;

  private static DEFAULT_DETECTION_COLOR = 'rgba(0, 0, 0, 0)';
  private static DEFAULT_DETECTION_WIDTH = 4;

  public doCreate(opts: OlArrowOpts): Style[] {
    const detectionStyle = this.createDetectionStyle(opts);
    const lineStyle = this.createLineStyle(opts);
    return [detectionStyle, lineStyle];
  }

  public createLineStyle(opts: OlArrowOpts) {
    const stroke = OlStrokeFactory.create({
      color: opts.color ?? ArrowStyleFactory.DEFAULT_COLOR,
      width: opts.width ?? ArrowStyleFactory.DEFAULT_WIDTH
    })
    return OlStyleFactory.create({
      stroke,
      zIndex: opts.zIndex
    })
  }

  public createDetectionStyle(opts: OlArrowOpts) {
    const stroke = OlStrokeFactory.create({
      color: opts.detectionColor ?? ArrowStyleFactory.DEFAULT_DETECTION_COLOR,
      width: opts.detectionWidth ?? ArrowStyleFactory.DEFAULT_DETECTION_WIDTH,
    })
    return OlStyleFactory.create({
      stroke,
      zIndex: opts.zIndex
    })
  }
}
