<form [formGroup]="editForm" *ngIf="editForm">
  <div class="form-container vehicle">
    <mat-form-field>
      <mat-label>{{ 'term.adjustment-factor' | translate }}</mat-label>
      <input matInput
             type="number"
             step="0.01"
             formControlName="{{ control.MILEAGE_FACTOR }}"
             matTooltip="{{ 'message.info.change-factor' | translate }}">
      <mat-error *ngIf="formControl[control.MILEAGE_FACTOR].errors?.required">
        {{ 'message.error.required-field' | translate }}
      </mat-error>
      <mat-error *ngIf="formControl[control.MILEAGE_FACTOR].errors?.min">
        {{ 'message.error.min-value' | translate: {min: formControl[control.MILEAGE_FACTOR].errors.min.min} }}
      </mat-error>
      <mat-error *ngIf="formControl[control.MILEAGE_FACTOR].errors?.max">
        {{ 'message.error.max-value' | translate: {max: formControl[control.MILEAGE_FACTOR].errors.max.max} }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'term.vehicle-odometer-reading' | translate }}, {{ 'uom.km' | translate }}</mat-label>
      <input matInput
             type="number"
             formControlName="{{ control.VEHICLE_ODOMETER }}"
             matTooltip="{{ 'message.tooltip.enter-current-odometer-value' | translate }}">
    </mat-form-field>
  </div>
</form>

<ng-content></ng-content> <!-- project actionButtons -->
