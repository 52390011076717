import {Pipe, PipeTransform} from '@angular/core';
import {DayjsUtil} from "../dayjs.util";

@Pipe({
  name: 'timeByHalfHour',
  standalone: true
})

export class TimeByHalfHourPipe implements PipeTransform {

  transform(halfHour: number): string {
    return DayjsUtil.utc(halfHour * 1000).format('HH:mm');
  }
}
