export class FilterParam {

  public value: any;
  public paramValue: string;

  private readonly _param: string;

  constructor(param: string) {
    this.value = null;
    this.paramValue = '';
    this._param = param;
  }

  get param(): string {
    return this._param;
  }
}
