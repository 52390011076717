import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppMessageService } from '../../../../../../../../common-module/src/lib/app-services/app-message.service';
import { UiSpinnerService } from '../../../../../../../../common-module/src/lib/app-services/ui-spinner.service';
import { EcoDrivingCriterionService } from '../../../../../../../../common-module/src/lib/services/eco-driving-criterion.service';
import { EcoDrivingCriterion } from '../../../../../../../../common-module/src/lib/modelinterfaces/eco-driving-criterion.model';

@Component({
  selector: 'app-delete-criterion-dialog',
  templateUrl: './delete-criterion-dialog.component.html',
  styleUrls: ['../../../../../../../../common-module/src/lib/app-styles/dialog-common.scss']
})

export class DeleteCriterionDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { criterion: EcoDrivingCriterion },
              private appMessageService: AppMessageService,
              private dialogRef: MatDialogRef<DeleteCriterionDialogComponent>,
              private ecoDrivingCriterionService: EcoDrivingCriterionService,
              private uiSpinnerService: UiSpinnerService) {
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onDelete(): void {
    this.uiSpinnerService.show();
    this.ecoDrivingCriterionService.delete(this.data.criterion.id).subscribe(
      () => {
        this.uiSpinnerService.stop();
        this.appMessageService.openSnackBar('message.info.changes-saved');
        this.dialogRef.close(true);
      }
    );
  }
}
