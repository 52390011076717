import {Driver} from './driver.model';
import {UnitShort} from './unit-short.model';
import {Unit} from './unit.model';
import {FullName} from "./full-name.model";

export class DriverView extends Driver {

  constructor(public id: number,
              public name: FullName,
              public phoneNumber: string,
              public avatarUrl: string,
              public unit: UnitShort) {
    super(id, name, phoneNumber, avatarUrl)
  }

  public static valueOf(o: DriverView): DriverView {
    return o ? new DriverView(o.id, FullName.valueOf(o.name), o.phoneNumber, o.avatarUrl, UnitShort.valueOf(o.unit)) : null;
  }

  public static valuesOf(list: DriverView[]): DriverView[] {
    return list ? list.map(o => DriverView.valueOf(o)) : [];
  }
}
