import {Component} from '@angular/core';
import {UrlImage} from '../../constants/url-image';

@Component({
  selector: 'app-check-mobile-device',
  templateUrl: './check-mobile-device.component.html',
  styleUrls: ['./check-mobile-device.component.scss']
})

export class CheckMobileDeviceComponent {

  public readonly LOGO_ICON = UrlImage.LOGO_WHITE;
}
