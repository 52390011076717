import {Select} from 'ol/interaction';
import {pointerMove} from 'ol/events/condition';

export class OlSelect {

  public static buildSelectRoute(): Select {
    return new Select({
      condition: pointerMove,
      filter: (feature) => feature.get('type') === 'route'
    });
  }

  public static buildSelectCheckPoints(): Select {
    return new Select({
      condition: pointerMove,
      filter: (feature) => feature.get('type') === 'point' || feature.get('type') === 'checkPoint'
    });
  }
}
