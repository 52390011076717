import { GpsCoordinate } from './gps-coordinate.model';

export class PointMarker {

  constructor(public name: string,
              public type: string,
              public gpsCoordinate: GpsCoordinate,
              public params: { [p: string]: string }) {
  }

  public static valueOf(o: PointMarker): PointMarker {
    return o ? new PointMarker(o.name, o.type, GpsCoordinate.valueOf(o.gpsCoordinate), o.params) : null;
  }

  public static valuesOf(list: PointMarker[]): PointMarker[] {
    return list ? list.map(o => PointMarker.valueOf(o)) : [];
  }
}
