<div class="main-container"
     [ngClass]="{'height-auto': !isExpanded || (this.service.state$ | async) === MultiTrackToolState.START_DRAWING || (this.service.state$ | async)  === MultiTrackToolState.END_DRAWING}">

  <header class="header-container">
    <div class="title-show-more-less">
      @if (isExpanded) {
        <app-icon-button-white icon="expand_less" (action)="toggleExpand(false)"></app-icon-button-white>
      } @else {
        <app-icon-button-white icon="expand_more" (action)="toggleExpand(true)"></app-icon-button-white>
      }
    </div>

    <div class="title-box">
      <h1 class="title"> Поиск треков </h1>
    </div>
    <div class="title-close">
      <app-icon-button-white icon="clear" (action)="onClose()"></app-icon-button-white>
    </div>
  </header>

  @if ((this.service.state$ | async) === MultiTrackToolState.TRACKS && isExpanded) {
    <section class="toolbar-container">
      <app-show-hide-button class="margin-top-12"
                            [visibleCondition]="isShowAllTracks"
                            [matTooltip]="'Показать/Скрыть все треки'"
                            (hide)="toggleShowAll(false)"
                            (show)="toggleShowAll(true)">
      </app-show-hide-button>
      <app-search [setDebounce]="true"
                  [debounceIntervalMs]="500"
                  (searchChange)="onSearchChange($event)">
      </app-search>

      <app-icon-button-primary icon="sort"
                               [matMenuTriggerFor]="menu"
                               matTooltip="Cортировка">
      </app-icon-button-primary>

      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onChangeSort('name-asc')">
          <mat-icon *ngIf="currentSort === 'name-asc'" class="selected-icon">check</mat-icon>
          <span>По имени (Возрастание)</span>
        </button>
        <button mat-menu-item (click)="onChangeSort('name-desc')">
          <mat-icon *ngIf="currentSort === 'name-desc'" class="selected-icon">check</mat-icon>
          <span>По имени (Убывание)</span>
        </button>
        <button mat-menu-item (click)="onChangeSort('mileage-asc')">
          <mat-icon *ngIf="currentSort === 'mileage-asc'" class="selected-icon">check</mat-icon>
          <span>Пробег (Возрастание)</span>
        </button>
        <button mat-menu-item (click)="onChangeSort('mileage-desc')">
          <mat-icon *ngIf="currentSort === 'mileage-desc'" class="selected-icon">check</mat-icon>
          <span>Пробег (Убывание)</span>
        </button>
      </mat-menu>
    </section>
  }

  <main class="content-container" [hidden]="!isExpanded">
    @if ((this.service.state$ | async) === 'start-drawing' || (this.service.state$ | async) === 'end-drawing') {
      <div class="drawing-mode-container">

        @if ((service.state$ | async) === MultiTrackToolState.START_DRAWING) {
          <div class="flex flex-v-center flex-gap4">
            <div>
              <mat-icon class="text-divider-color">pending</mat-icon>
            </div>
            <div>
              Выделите область на карте, чтобы найти треки поездок выбранных объектов за указанный период времени.
            </div>
          </div>
        } @else {
          @if (service.geometry) {
            <div class="flex flex-v-center flex-gap4">
              <mat-icon class="text-green">check_circle</mat-icon>
              <a class="pointer text-primary-color text-underline" (click)="service.centerGeometry()"> Область поиска
                выбрана </a>
            </div>
            <div>
              При построении треков вы увидете только те которые пересекают область поиска
            </div>
          }
        }

      </div>
    } @else {
      <div class="flex flex-v-center flex-gap4">
        @if (service.geometry) {
          @if (showSearchArea) {
            <app-show-hide-button [visibleCondition]="showSearchArea"
                                  (hide)="service.hideSearchArea(); showSearchArea = false"
                                  (show)="service.showSearchArea(); showSearchArea = true">
            </app-show-hide-button>
          }
          <a class="pointer text-primary-color text-underline" (click)="service.centerGeometry()"> Область поиска </a>
        }

      </div>
      <mat-accordion>
        @for (item of filteredData; let idx = $index; track item.unit.id) {
          <mat-expansion-panel class="mat-elevation-z1" (opened)="openedUnitId = item.unit.id">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="panel-title">
                  <app-show-hide-button [visibleCondition]="item.visible"
                                        [matTooltip]="'Показать/Скрыть все треки для: ' + item.unit.name"
                                        (hide)="toggleVisibilityUnit(item, false, $event)"
                                        (show)="toggleVisibilityUnit(item, true, $event)">
                  </app-show-hide-button>
                  {{ item.unit.name }}
                </div>
              </mat-panel-title>

              <mat-panel-description>
                <div class="panel-description-container">
                  <mat-icon [style]="{color: service.getTrackColor(item.unit.id)}"> bookmark</mat-icon>
                  <div class="flex flex-direction-column">
                    <div>
                      Треки ({{ item.tracks.length }})
                    </div>
                    <div>
                      {{ item.totalMileage.corrected | sensorValue:2 }} км
                    </div>
                  </div>
                </div>
              </mat-panel-description>

            </mat-expansion-panel-header>

            @if (openedUnitId === item.unit.id) {
              <div class="expansion-panel-container">
                <div class="expansion-panel-item increase-height background-primary text-white"
                     (click)="selectAll(item)">
                  <mat-icon class="text-white">route</mat-icon>
                  {{ item.tracks[0].track.buildOptions.startTime | utcDateTz: 'browser':'DD.MM HH:mm' }}
                  -
                  {{ item.tracks[item.tracks.length - 1].track.buildOptions.finishTime | utcDateTz: 'browser':'DD.MM HH:mm' }}
                  Трек полностью
                </div>
                <mat-divider></mat-divider>
                @for (trackData of item.tracks; let idx = $index; track trackData.track.buildOptions.startTime) {
                  <div class="expansion-panel-item"
                       [ngClass]="{'selected-track': selectedTracks.has(trackData.track)}"
                       (click)="select(trackData)">
                    {{ idx + 1 }}.
                    <app-show-hide-button [visibleCondition]="trackData.visible"
                                          [matTooltip]="'Показать/Скрыть трек'"
                                          (hide)="toggleVisibilityUnitTrack(trackData, false, $event)"
                                          (show)="toggleVisibilityUnitTrack(trackData, true, $event)">
                    </app-show-hide-button>


                    {{ trackData.track.trips[0].startDatetime |  utcDateTz: 'browser':'DD.MM HH:mm' }}
                    -
                    {{ trackData.track.trips[trackData.track.trips.length - 1].finishDatetime |  utcDateTz: 'browser':'DD.MM HH:mm' }}
                    ,

                    {{ trackData.track.mileage.corrected | sensorValue: 2 }} км
                  </div>
                  <mat-divider></mat-divider>
                } @empty {
                  no tracks
                }
              </div>
            }
          </mat-expansion-panel>
        } @empty {
          <li>нет треков для отображения.</li>
        }
      </mat-accordion>
    }
  </main>

  @if ((this.service.state$ | async) === MultiTrackToolState.TRACKS) {
    <footer class="footer">
      <div>
        <mat-icon class="text-orange"
                  matTooltip="Внимание: отображаются только треки завершенных поездок, которые пересекаются с
                  выбранным вами временным интервалом, даже если поездка не полностью укладывается в него.
                  Треки поездок, которые находятся в процессе, не отображаются.
                  Отображение треков работает для поездок совершенных после 15.11.2024">
          info
        </mat-icon>
      </div>
      <div>
        Всего:
      </div>
      <div>
        Автомобилей {{ data.length }},
      </div>
      <div>
        Пробег {{ totalMileage.toFixed(2) }} км
      </div>
    </footer>
  }

</div>
