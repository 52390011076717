<div class="container">
  <header class="header">
    <p>{{ 'message.info.use-mobile-app' | translate }}</p>

    <div class="app-name">
      <img class="logo" [src]="LOGO_ICON" alt="A">
      <span class="name">{{ 'term.aurora-monitoring' | translate }}</span>
    </div>
  </header>

  <mat-dialog-actions class="container-buttons">
    <button
      mat-raised-button
      [mat-dialog-close]="'disagree'"
    >
      {{ 'action.cancel-download' | translate }}
    </button>

    <button
      mat-raised-button
      class="button-submit"
      [mat-dialog-close]="'agree'"
    >
      {{ 'action.download-app' | translate }}
    </button>
  </mat-dialog-actions>
</div>
