export class OdometerRange {

  constructor(public start: number,
              public finish: number,
              public mileage: number) {
  }

  public static valueOf(i: OdometerRange): OdometerRange {
    return i ? new OdometerRange(i.start, i.finish, i.mileage) : null;
  }
}
