import {DurationConverter} from '../utils/duration-converter';
import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'secToDuration',
  standalone: true
})
export class SecToStringDurationPipe implements PipeTransform {

  transform(value: number, translateService: TranslateService): string {
    return DurationConverter.secondsToStringDuration(value, translateService);
  }
}
