import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Tab } from '../../../../../common-module/src/lib/app-enums/tab';
import { UnitShort } from '../../../../../common-module/src/lib/modelinterfaces/unit-short.model';
import { UnitDataService } from './unit-data.service';
import { UnitGroupsService } from './unit-groups.service';

@Injectable({
  providedIn: 'root'
})

export class SelectedService {

  private _selectedUnitSource = new BehaviorSubject<UnitShort>(null);
  private _lastSelectedUnit$ = this._selectedUnitSource.asObservable();

  private _selectedUnitIdsSource = new BehaviorSubject<Set<number>>(new Set());
  private _selectedUnitIdsSet$ = this._selectedUnitIdsSource.asObservable();
  private _selectedUnitIdsSet: Set<number> = new Set();

  private _selectedUserIdsSource = new BehaviorSubject<number[]>([]);
  private _selectedUserIds$ = this._selectedUserIdsSource.asObservable();
  private _selectedUserIdsList: number[] = [];

  private _isAvailableUnitSource = new BehaviorSubject<boolean>(false);
  private _isAvailableUnit$ = this._isAvailableUnitSource.asObservable();

  private _isClickOnMap = false;

  constructor(private router: Router,
              private unitDataService: UnitDataService,
              private unitGroupsService: UnitGroupsService) {
  }

  get lastSelectedUnit$(): Observable<UnitShort> {
    return this._lastSelectedUnit$;
  }

  get isAvailableUnit$(): Observable<boolean> {
    return this._isAvailableUnit$;
  }

  get isClickOnMap(): boolean {
    return this._isClickOnMap;
  }

  get selectedUnitIdsSet$(): Observable<Set<number>> {
    return this._selectedUnitIdsSet$;
  }

  get selectedUserIds$(): Observable<number[]> {
    return this._selectedUserIds$;
  }

  public getInstantUnit(): UnitShort {
    return this._selectedUnitSource.getValue();
  }

  public getInstantUnitIdsSet(): Set<number> {
    return this._selectedUnitIdsSource.getValue();
  }

  public getInstantIsAvailableUnit(): boolean {
    return this._isAvailableUnitSource.getValue();
  }

  public getInstantSelectedUserIds(): number[] {
    return this._selectedUserIdsSource.getValue();
  }

  public changeLastSelectedUnit(unit: UnitShort, isClickOnMap: boolean = false): void {
    const urlSegment = this.router.parseUrl(this.router.url).root.children.primary?.segments[0]?.toString() as Tab;
    if (urlSegment !== Tab.PUBLIC) {
      this.unitDataService.getSelectedUnitData(unit?.id);
    }
    if (urlSegment === Tab.REPORT || urlSegment === Tab.CHART) {
      this.router.navigate([Tab.MAP]).then();
    }
    this._isClickOnMap = isClickOnMap;
    if (isClickOnMap) {
      this.removeSelectedUnitId();
    }
    this._selectedUnitSource.next(unit);
    if (unit) {
      this.appendSelectedUnitIds([unit.id]);
      this._isAvailableUnitSource.next(this.unitGroupsService.getIsAvailableUnit(unit.id));
    }
  }

  public appendSelectedUser(userId: number): void {
    this._selectedUserIdsList.push(userId);
    this._selectedUserIdsSource.next(this._selectedUserIdsList);
  }

  public removeSelectedUser(userId: number): void {
    this._selectedUserIdsList = this._selectedUserIdsList.filter(el => el !== userId);
    this._selectedUserIdsSource.next(this._selectedUserIdsList);
  }

  public appendSelectedUnitIds(ids: number[] = []): void {
    ids.forEach(el => this._selectedUnitIdsSet.add(el));
    this._selectedUnitIdsSource.next(this._selectedUnitIdsSet);
  }

  public removeSelectedUnitId(unitId: number = null): void {
    unitId ? this._selectedUnitIdsSet.delete(unitId) : this._selectedUnitIdsSet.clear();
    this._selectedUnitIdsSource.next(this._selectedUnitIdsSet);
  }

  public destroy(): void {
    this._selectedUnitSource.next(null);
    this._selectedUnitIdsSource.next(new Set());
    this._selectedUserIdsSource.next([]);
    this.unitDataService.getSelectedUnitData(null);
  }
}
