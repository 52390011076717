import { MetaData } from './meta-data.model';

export class MetaTableData {

  constructor(public tableMetaData: Map<string, MetaData>) {
    this.tableMetaData = new Map();
    Object.keys(tableMetaData).forEach(key => {
      this.tableMetaData.set(key, MetaData.valueOf(tableMetaData[key]));
    });
  }

  public static valueOf(i: MetaTableData): MetaTableData {
    return i ? new MetaTableData(i.tableMetaData) : null;
  }
}
