import {Component, Inject, OnInit} from '@angular/core';
import {DialogComponent} from "../../../../../../shared/components/dialogs/base-dialog/base-dialog.component";
import {
  ColumnSettings,
  ReportSettings
} from "../../../../../../../../../common-module/src/lib/modelinterfaces/report-settings.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ReportSettingsStorageService} from "../../../../../../shared/services/report-settings-storage.service";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-report-settings-constructor-dialog',
  templateUrl: './report-settings-constructor-dialog.component.html',
  styleUrl: './report-settings-constructor-dialog.component.scss'
})
export class ReportSettingsConstructorDialogComponent extends DialogComponent.Base<string> implements OnInit {

  reportName: string;
  reportSettings: ReportSettings;

  visibleColumns: ColumnSettings[] = [];
  unVisibleColumns: ColumnSettings[] = [];

  savingSpinner = false;

  constructor(public dialogRef: MatDialogRef<ReportSettingsConstructorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: string,
              private settingStorage: ReportSettingsStorageService) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
    this.reportName = this.data;
    this.reportSettings = this.settingStorage.get(this.reportName);
    this.reportSettings.columns.forEach(c => {
      if (c.visible) {
        this.visibleColumns.push(c);
      } else {
        this.unVisibleColumns.push(c);
      }
    })
  }

  drop(event: CdkDragDrop<ColumnSettings[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  onSave() {
    this.savingSpinner = true;
    const result: ColumnSettings[] = [];
    this.visibleColumns.forEach(col => {
      col.visible = true;
      result.push(col);
    });
    this.unVisibleColumns.forEach(col => {
      col.visible = false;
      result.push(col);
    });
    const copy: ReportSettings = JSON.parse(JSON.stringify(this.reportSettings));
    copy.columns = result;
    this.settingStorage.save(copy).subscribe(() => this.closeOnSave(), () => {}, () => this.savingSpinner = false);
  }
}
