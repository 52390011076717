import {OlFeature} from "./OlFeature";
import {
  JobPointType
} from "../../system/logistic/logistic/logistic-order-list/job-dialog/impl/abstract-job-dialog/map-manager/job-feature";
import {AppColor} from "../../../../../common-module/src/lib/app-enums/app-color";
import {Logistic} from "../../../../../common-module/src/lib/modelinterfaces/logistic.model";
import VectorLayer from "ol/layer/Vector";
import OlMap from "ol/Map";
import {OlVectorLayer} from "./OlVectorLayer";
import {LayerName} from "../constants/enums/layer-name";
import {OlDescriptionOverlay} from "./OlDescriptionOverlay";
import {LogisticTimeWindowUtil} from "../../system/logistic/common/utils/logistic-time-window.util";
import Warehouse = Logistic.Warehouse;

export class WarehouseMapUtil {

  public static createLayer(map: OlMap, warehouses: Warehouse[], color: AppColor): VectorLayer {
    const layer = OlVectorLayer.createLayerOnMap(map, LayerName.WAREHOUSES);
    this.show(warehouses, layer, color);
    return layer;
  }

  private static show(warehouses: Warehouse[], layer: VectorLayer, color: AppColor, archiveColor: AppColor = AppColor.ORANGE) {
    warehouses.forEach(w => {
      const iconColor = w.archived ? archiveColor : color;
      const labelColor = w.archived ? archiveColor : color;
      let feature = OlFeature.createLogisticFeature(w.location.coordinate, JobPointType.WAREHOUSE, iconColor, w.name, labelColor);
      feature.set('value', w);
      OlDescriptionOverlay.setDescription(feature,
        `
            <p style="color: ${archiveColor}"><b>${w.archived ? 'В архиве' : ''}</b></p>
            <p><b>${w.name}</b></p>
            <p><b>Описание:</b> ${w.description}</p>
            <p><b>Адрес:</b> ${w.location.address}</p>
            <p><b>Время работы:</b> ${LogisticTimeWindowUtil.toShortString(w.timeWindows)}</p>
        `);
      layer.getSource().addFeature(feature);
    });
  }
}
