import {BaseMap, BaseMapConfig} from "./base.map";
import OlMap from "ol/Map";
import {Overlay} from "ol";
import {OlDescriptionOverlay} from "../../../map-utils/OlDescriptionOverlay";
import {Point} from "ol/geom";
import {FeatureLike} from "ol/Feature";

export interface PopupMapConfig extends BaseMapConfig {
  popupDivId?: string;
  popupClass?: string;
}

export class PopupMap extends BaseMap {

  public static readonly DESCRIPTION_FEATURE_PROPERTY = "description"

  public static readonly DEFAULT_MAP_POPUP_DIV_ID = 'map-popup';

  protected readonly descriptionPopup: Overlay;

  private hoveredFeature: FeatureLike;

  constructor(map: OlMap, config?: PopupMapConfig) {
    super(map, config);
    const popupDivId = config?.popupDivId ?? PopupMap.DEFAULT_MAP_POPUP_DIV_ID;
    this.descriptionPopup = this.initFeaturePopup(map, popupDivId);
    this.initFeatureTracking(map);

  }

  protected initFeatureTracking(map: OlMap): void {
    map.on('pointermove', (e) => {
      let currentFeature = null;

      map.forEachFeatureAtPixel(e.pixel, (f) => {
        if (this.isHoverTrackingFeature(f)) {
          currentFeature = f;
          return true;
        }
        return false;
      });
      if (currentFeature !== this.hoveredFeature) {
        if (this.hoveredFeature) {
          this.onFeatureOut(this.hoveredFeature);
        }
        if (currentFeature) {
          this.onFeatureIn(currentFeature);
        }
        this.hoveredFeature = currentFeature;
      }
    });
  }

  protected isHoverTrackingFeature(feature: FeatureLike): boolean {
    return true;
  }

  protected onFeatureIn(feature: FeatureLike) {

  }

  protected onFeatureOut(feature: FeatureLike) {

  }

  protected initFeaturePopup(map: OlMap, popupId: string): Overlay {
    const popup = OlDescriptionOverlay.createPopup(document.getElementById(popupId), this.popupOffset());
    map.addOverlay(popup);
    map.on('pointermove', (e) => {
      popup.setPosition(undefined); // hide popup if not on Feature
      map.forEachFeatureAtPixel(e.pixel, f => {
        const customPopup = this.createCustomPopup(f);
        if (f.get(PopupMap.DESCRIPTION_FEATURE_PROPERTY) || customPopup) {
          document.getElementById(popupId).innerHTML = customPopup ? customPopup : `<p> ${f.get(PopupMap.DESCRIPTION_FEATURE_PROPERTY)} </p>`;
          if (f.getGeometry() instanceof Point) {
            popup.setPosition((<Point>f.getGeometry()).getCoordinates());
          } else {
            popup.setPosition(e.coordinate);
          }
        }
      });
    });
    return popup;
  }

  protected popupOffset(): [number, number] {
    return [-30, 10]
  }

  protected createCustomPopup(feature: FeatureLike): string {
    return null;
  }
}
