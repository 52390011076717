import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TimeZoneService} from "../../../services/time-zone.service";
import {
  comparisonId as comparisonIdUtility
} from "../../../utils/comparison-function.util";
import {ReactiveFormsModule, UntypedFormControl} from "@angular/forms";
import {debounceTime, distinctUntilChanged, map, startWith, switchMap} from "rxjs/operators";
import {TimeZone} from "../../../modelinterfaces/time-zone.model";
import {BehaviorSubject, Observable, ReplaySubject} from "rxjs";
import {MatFormField} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {MatOption, MatSelect} from "@angular/material/select";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {AsyncPipe, NgForOf} from "@angular/common";
import {MatInputModule} from "@angular/material/input";

@Component({
  selector: 'app-select-timezone',
  standalone: true,
  imports: [
    MatFormField,
    MatIcon,
    TranslateModule,
    MatSelect,
    ReactiveFormsModule,
    MatOption,
    NgxMatSelectSearchModule,
    NgForOf,
    AsyncPipe,
    MatInputModule
  ],
  templateUrl: './select-timezone.component.html',
  styleUrls: ['./select-timezone.component.scss']
})
export class SelectTimezoneComponent implements OnInit {

  @Input() timezone: TimeZone;
  @Output() timezoneChange = new EventEmitter<TimeZone>();

  comparisonId = comparisonIdUtility;
  control = new UntypedFormControl();
  $filteredTimeZones: Observable<TimeZone[]>;
  private searchTerms = new ReplaySubject<string>(1);
  private allTimeZones = new BehaviorSubject<TimeZone[]>([]);

  constructor(private timezoneService: TimeZoneService) {
  }

  ngOnInit(): void {
    this.control.setValue(this.timezone);
    this.initFilter();
  }

  private initFilter() {
    this.$filteredTimeZones = this.searchTerms.pipe(
      startWith(''),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(term => this.filterTimeZones(term)),
    );
    this.timezoneService.getList().subscribe(timezones => {
      this.allTimeZones.next(timezones);
      this.searchTerms.next('');
    });
  }

  private filterTimeZones(term: string): Observable<TimeZone[]> {
    return this.allTimeZones.pipe(
      map(timezones => timezones.filter(timezone => timezone.description.toLowerCase().includes(term.toLowerCase())))
    );
  }

  public filterOptions(searchTerm: string): void {
    this.searchTerms.next(searchTerm);
  }

  public clearFilterOptions(): void {
    this.searchTerms.next('');
  }
}
