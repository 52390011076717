import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogComponent} from "./dialog.component";
import {DialogActionsDirective} from "./dialog-actions.directive";
import {DialogContentDirective} from "./dialog-content.directive";
import {DialogStickyFooterDirective} from "./dialog-sticky-footer.directive";
import {DialogStickyHeaderDirective} from "./dialog-sticky-header.directive";
import {AurDialogModule} from "ngx-aur-mat-dialog";
import {DialogTitleDirective} from "./dialog-title.directive";


@NgModule({
  declarations: [
    DialogComponent,
    DialogActionsDirective,
    DialogContentDirective,
    DialogStickyFooterDirective,
    DialogStickyHeaderDirective,
    DialogTitleDirective
  ],
  imports: [
    CommonModule,
    AurDialogModule,
  ],
  exports: [
    DialogComponent,
    DialogActionsDirective,
    DialogContentDirective,
    DialogStickyFooterDirective,
    DialogStickyHeaderDirective,
    DialogTitleDirective
  ]
})
export class AppDialogModule {
}
