import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { Driver } from '../../../../../../common-module/src/lib/modelinterfaces/driver.model';
import { UrlImage } from '../../constants/url-image';
import { DriverManagementService } from '../../services/driver-management.service';

@Component({
  selector: 'app-current-driver',
  templateUrl: './current-driver.component.html',
  styleUrls: ['./current-driver.component.scss', '../../../../../../common-module/src/lib/app-styles/dialog-common.scss']
})

export class CurrentDriverComponent implements OnInit {

  @Input() isCarousel = false;
  @Input() unitId: number;

  public readonly DEFAULT_AVATAR = UrlImage.DEFAULT_AVATAR;

  public driver$: Observable<Driver>;

  constructor(private driverManagementService: DriverManagementService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['unitId']) {
      this.driverManagementService.changeDriverFromUnitState(this.unitId);
    }
  }

  ngOnInit(): void {
    this.driver$ = this.driverManagementService.currentDriver$;
  }

  public editDriver(): void {
    this.driverManagementService.editDriver();
  }

  public removeDriver(): void {
    this.driverManagementService.removeDriver();
  }

  public assignDriver(): void {
    this.driverManagementService.openAssignDriverTable();
  }
}
