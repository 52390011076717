import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceCommand } from '../modelinterfaces/device-command.model';
import { PageParam } from '../modelinterfaces/enums/page-param';
import { Page } from '../modelinterfaces/page.model';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class DeviceCommandService {

  private params: HttpParams = new HttpParams();
  private readonly url = `${this.environmentHelper.urlV2}/deviceCommand/unit/`;

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getList(unitId: number, page: number, pageSize: number, sort: string): Observable<Page<DeviceCommand>> {
    this.setParams(page, PageParam.PAGE, String(page));
    this.setParams(pageSize, PageParam.SIZE, String(pageSize));
    this.setParams(sort, PageParam.SORT, String(sort));
    const params = this.params;
    return this.http.get<Page<DeviceCommand>>(this.url + unitId, {params: params}).pipe(
      map(page => DeviceCommand.pageableValueOf(page))
    );
  }

  public sendCommand(unitId: number, command: DeviceCommand): Observable<DeviceCommand> {
    return this.http.post<DeviceCommand>(this.url + unitId, command).pipe(
      map(obj => DeviceCommand.valueOf(obj))
    );
  }

  private setParams(param, name, value) {
    if (param !== null && param !== undefined) {
      this.params = this.params.set(name, value);
    }
  }
}
