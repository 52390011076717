<app-dialog [title]="('term.notification' | translate) + ': ' +   data.source.message">

  <div class="app-dialog-content-column" appDialogContent>

    <ng-container *ngIf="!notificationDetails; else detailsInfo">
      <div class="info-block">
        <table class="table-info">
          <ng-container [ngTemplateOutlet]="unitName"></ng-container>
          <ng-container [ngTemplateOutlet]="description"></ng-container>
        </table>
      </div>

      <button *ngIf="!notificationDetails" class="app-button primary" mat-button (click)="showDetailsInfo()">
        {{ 'message.tooltip.show-details' | translate }}
      </button>
    </ng-container>

    <ng-template #detailsInfo>
      <app-notification-details
        [notificationDetails]="notificationDetails"
        [timeZone]="timeZone$ | async"
        [notificationDuration]="notificationDuration">
        <ng-container [ngTemplateOutlet]="unitName" slot="unit-name"></ng-container>
        <ng-container [ngTemplateOutlet]="description" slot="description"></ng-container>
      </app-notification-details>
    </ng-template>

    <ng-template #unitName>
      <tr>
        <td class="left-column">{{ 'term.unit' | translate }}</td>
        <td class="right-column">{{ data.unit.name }}</td>
      </tr>
    </ng-template>

    <ng-template #description>
      <tr>
        <td class="left-column">{{ 'term.time-range' | translate }}</td>
        <td class="right-column">
          {{ data.startTime | utcDateTz: (timeZone$ | async) }} - {{
            data.finishTime ? (data.finishTime | utcDateTz:
              (timeZone$ | async)) : '...'
          }}
        </td>
      </tr>
      <tr>
        <td class="left-column">{{ 'term.duration' | translate }}</td>
        <td class="right-column">{{ notificationDuration }}</td>
      </tr>
      <tr>
        <td class="left-column">{{ 'term.message' | translate }}</td>
        <td class="right-column">{{ data.source.message }}</td>
      </tr>
      <tr>
        <td class="left-column">
          <span>{{ 'term.rule' | translate }}</span>

          <button mat-icon-button class="small-button" (click)="onOpenRuleDialog($event)">
            <mat-icon>create</mat-icon>
          </button>
        </td>
        <td class="right-column">{{ data.source.name }}</td>
      </tr>
      <tr>
        <td class="left-column">{{ 'term.created-time' | translate }}</td>
        <td class="right-column">{{ data.createdTime | utcDateTz: (timeZone$ | async) }}</td>
      </tr>
    </ng-template>
  </div>
</app-dialog>
