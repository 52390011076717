import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SensorChart } from '../modelinterfaces/sensor-chart.model';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class SensorChartService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getByUnitId(unitId: number, startTime: string, finishTime: string, sensorIds: number[], lang: string): Observable<SensorChart> {
    const url = `${this.environmentHelper.urlV2}/sensorChart/unit/${unitId}`;
    let params = new HttpParams();
    params = params.set('startTime', startTime);
    params = params.set('finishTime', finishTime);
    if (sensorIds) {
      params = params.set('sensorIds', sensorIds.join(','));
    }
    params = params.set('lang', lang);
    return this.http.get<SensorChart>(url, {params: params}).pipe(
      map(obj => SensorChart.valueOf(obj))
    );
  }
}
