import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Camera } from '../modelinterfaces/camera.model';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class CameraService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getData(unitId: number): Observable<Camera[]> {
    const url = `${this.environmentHelper.urlDvr}/camera?unitId=${unitId}`;
    return this.http.get<Camera[]>(url).pipe(
      map(cameraList => Camera.valuesOf(cameraList))
    );
  }

  public update(camera: Camera): Observable<Camera> {
    const url = `${this.environmentHelper.urlDvr}/camera/${camera.id}`;
    return this.http.put<Camera>(url, camera).pipe(
      map(camera => Camera.valueOf(camera))
    );
  }
}
