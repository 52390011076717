<form [formGroup]="form" class="filter-menu" (click)="$event.stopPropagation()">
  <div class="box-inputs">
    <mat-form-field [floatLabel]="'always'" [subscriptSizing]="'dynamic'">

      <input matInput
             #startInput
             (keydown)="onKey($event)"
             type="number"
             min="{{ minValue }}"
             max="{{ maxValue }}"
             step="{{ step }}"
             [placeholder]="'preposition.from' | translate"
             [matTooltip]="
                 form.controls[controls.START].errors?.max ?
                 ('message.error.max-value' | translate:
                 {max: form.controls[controls.START].errors?.max.max}) :
                 form.controls[controls.START].errors?.min ?
                 ('message.error.min-value' | translate:
                 {min: form.controls[controls.START].errors?.min.min}) :
                 null"
             [formControlName]="controls.START"/>

    </mat-form-field>

    <span class="extra-text">-</span>

    <mat-form-field [floatLabel]="'always'" [subscriptSizing]="'dynamic'">
      <input matInput
             #endInput
             type="number"
             min="{{ minValue }}"
             max="{{ maxValue }}"
             step="{{ step }}"
             (keydown.backspace)="onBackspace($event, startInput, endInput)"
             [formControlName]="controls.END"
             [placeholder]="'preposition.to' | translate"/>
    </mat-form-field>

    <span class="extra-text">{{ uom }}</span>
  </div>

  <div *ngIf="form.errors?.rangeInvalid" class="margin-left-20 text-red">
    Неверный диапазон
  </div>
</form>
