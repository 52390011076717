export class Mileage {

  constructor(public value: number,
              public corrected: number,
              public factor: number) {
  }

  public static valueOf(i: Mileage): Mileage {
    return i ? new Mileage(i.value, i.corrected, i.factor) : null;
  }
}
