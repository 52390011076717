<mat-expansion-panel *ngIf="!(unitCount === 0 && (searchStr || filter !== 'all'))"
                     class="panel mat-elevation-z0"
                     (afterExpand)="expand()"
                     (afterCollapse)="collapse()"
>
  <mat-expansion-panel-header>
    <mat-panel-title
      class="panel-title"
      [style.font-weight]="selectedUnitIdListByUser.length > 0 ? 'bolder' : 'normal'"
    >
      <mat-icon>person</mat-icon>
      <span>&nbsp;&nbsp;{{ user.name }}:&nbsp;&nbsp;</span>
      <span matTooltip="{{ 'message.tooltip.selected-in-total' | translate }}">
        {{ selectedUnitIdListByUser.length > 0 ? selectedUnitIdListByUser.length + ' / ' : '' }}{{ unitCount }}
      </span>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <mat-expansion-panel class="sub-panel mat-elevation-z0"
                       [togglePosition]="'before'"
                       [expanded]="searchStr || filter !== 'all'"
  >
    <mat-expansion-panel-header class="header">
      <mat-panel-title class="panel-title">
        <span>&nbsp;&nbsp;{{ 'term.all-units' | translate }}:&nbsp;&nbsp;</span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <app-unit-list [units]="unitsByUserFiltered"
    ></app-unit-list>
  </mat-expansion-panel>

  <div *ngFor="let group of user.groups">
    <mat-expansion-panel class="sub-panel mat-elevation-z0"
                         [togglePosition]="'before'"
                         [expanded]="searchStr || filter !== 'all'"
    >
      <mat-expansion-panel-header class="header">
        <mat-panel-title class="panel-title">
          <mat-icon [ngStyle]="{'color': group.color }">label</mat-icon>
          <span>&nbsp;&nbsp;{{ group.name }}:&nbsp;&nbsp;{{ getCountSelectedGroupUnits(group.units) }} {{ filterUnits(group.units).length }}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <app-unit-list [units]="filterUnits(group.units)"
      ></app-unit-list>
    </mat-expansion-panel>
  </div>
</mat-expansion-panel>
