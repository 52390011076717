import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {Orientation} from '../../constants/enums/orientation';

@Component({
  selector: 'app-print-button',
  templateUrl: './print-button.component.html',
  styleUrls: ['./print-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintButtonComponent {

  @Input() showSpinner = false;

  @Output() printChange = new EventEmitter<Orientation>();

  public orientation = Orientation;

  constructor() {
  }

  public print(orientation: Orientation): void {
    this.showSpinner = true;
    this.printChange.emit(orientation);
  }
}
