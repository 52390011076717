export class NotificationActionForm {

  constructor(public push: boolean,
              public telegram: boolean,
              public activateCommand: boolean,
              public deactivateCommand: boolean) {
  }

  public static valueOf(o: NotificationActionForm): NotificationActionForm {
    return o ? new NotificationActionForm(o.push, o.telegram, o.activateCommand, o.deactivateCommand) : null;
  }
}
