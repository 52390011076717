import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UnitGroupStorage } from '../../../../../common-module/src/lib/modelinterfaces/unit-group-storage.model';
import { UnitShort } from '../../../../../common-module/src/lib/modelinterfaces/unit-short.model';
import { UserUnitGroups } from '../../../../../common-module/src/lib/modelinterfaces/user-unit-groups.model';
import { UnitGroupStorageService } from '../../../../../common-module/src/lib/services/unit-group-storage.service';
import { UnitShortService } from '../../../../../common-module/src/lib/services/unit-short.service';

@Injectable({
  providedIn: 'root'
})

export class UnitGroupsService {

  private _unitGroupsSource = new BehaviorSubject<UnitGroupStorage>(null);
  private _unitGroups$: Observable<UnitGroupStorage> = this._unitGroupsSource.asObservable();

  private _allUnitsSource = new BehaviorSubject<UnitShort[]>(null);
  private _allUnits$: Observable<UnitShort[]> = this._allUnitsSource.asObservable();

  constructor(private unitGroupStorageService: UnitGroupStorageService,
              private unitShortService: UnitShortService) {
  }

  get unitGroups$(): Observable<UnitGroupStorage> {
    return this._unitGroups$;
  }

  get allUnits$(): Observable<UnitShort[]> {
    return this._allUnits$;
  }

  public init(): void {
    this.unitGroupStorageService.getData().subscribe(data => {
        this._unitGroupsSource.next(data);
        this._allUnitsSource.next(data.all);
      }
    );
  }

  public initWithOutGroups(): void {
    this.unitShortService.getData().subscribe(r => {
        this._allUnitsSource.next(r);
      }
    );
  }

  public getInstant(): UnitGroupStorage {
    return this._unitGroupsSource.getValue();
  }

  public getInstantWithoutGroups(): UnitShort[] {
    return this._allUnitsSource.getValue();
  }

  public getUnitListByUser(user: UserUnitGroups): UnitShort[] {
    return user.shared.concat(user.owned);
  }

  public getUnitById(unitId: number): UnitShort {
    return this.getInstantWithoutGroups().find(el => el.id === unitId);
  }

  public getIsAvailableUnit(unitId: number): boolean {
    if (!this.getInstant()) { // use public interface - default: all units available
      return true;
    }
    return this.getInstant().grouped.some(el => el.owned.some(unit => unit.id === unitId));
  }

  public destroy(): void {
    this._unitGroupsSource.next(null);
  }
}
