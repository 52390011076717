import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Mechanism } from '../modelinterfaces/mechanism.model';
import { Mode } from '../modelinterfaces/mode.model';
import { HttpParamsUtil } from '../utils/http-params-util';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class MechanismService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getList(unitId: number, lang: string): Observable<Mechanism[]> {
    const url = `${this.environmentHelper.urlV2}/mechanism`;
    let params = HttpParamsUtil.createByLang(lang);
    params = params.set('unitId', unitId);
    return this.http.get<Mechanism[]>(url, {params: params}).pipe(
      map(list => Mechanism.valuesOf(list))
    );
  }

  public save(unitId: number, mechanism: Mechanism, lang: string): Observable<Mechanism> {
    const url = `${this.environmentHelper.urlV2}/mechanism/unit/${unitId}`;
    return this.http.post<Mechanism>(url, mechanism, {params: HttpParamsUtil.createByLang(lang)}).pipe(
      map(obj => Mechanism.valueOf(obj))
    );
  }

  public changeName(mechanismId: number, name: string, lang: string): Observable<Mechanism> {
    const url = `${this.environmentHelper.urlV2}/mechanism/${mechanismId}/name`;
    return this.http.patch<Mechanism>(url, {name: name}, {params: HttpParamsUtil.createByLang(lang)}).pipe(
      map(obj => Mechanism.valueOf(obj))
    );
  }

  public updateEngineHoursNorm(mechanismId: number, norm: number, lang: string): Observable<Mechanism> {
    const url = `${this.environmentHelper.urlV2}/mechanism/${mechanismId}/engineHoursNorm`;
    return this.http.patch<Mechanism>(url, {engineHoursNorm: norm}, {params: HttpParamsUtil.createByLang(lang)}).pipe(
      map(obj => Mechanism.valueOf(obj))
    );
  }

  public delete(mechanismId: number): Observable<void> {
    const url = `${this.environmentHelper.urlV2}/mechanism/${mechanismId}`;
    return this.http.delete<void>(url);
  }

  public addMode(mechanismId: number, mode: Mode, lang: string): Observable<Mechanism> {
    const url = `${this.environmentHelper.urlV2}/mechanism/${mechanismId}/mechanismMode`;
    return this.http.post<Mechanism>(url, mode, {params: HttpParamsUtil.createByLang(lang)}).pipe(
      map(obj => Mechanism.valueOf(obj))
    );
  }

  public deleteMode(mechanismId: number, modeId: number, lang: string): Observable<Mechanism> {
    const url = `${this.environmentHelper.urlV2}/mechanism/${mechanismId}/mechanismMode/${modeId}`;
    return this.http.delete<Mechanism>(url, {params: HttpParamsUtil.createByLang(lang)}).pipe(
      map(obj => Mechanism.valueOf(obj))
    );
  }
}
