import { CalcOperations } from '../utils/calc-operations';
import { UnitOfMeasure } from './unit-of-measure.model';

export class ParameterSource {

  constructor(public name: string,
              public nameTranslated: string,
              public value: string,
              public formatted: string,
              public unitOfMeasure: UnitOfMeasure,
              public visible: boolean) {
  }

  public static valueOf(i: ParameterSource): ParameterSource {
    return i ? new ParameterSource(i.name, i.nameTranslated, i.value, i.formatted, i.unitOfMeasure, i.visible)
      : null;
  }

  public static valuesOf(list: ParameterSource[]): ParameterSource[] {
    return list ? list.map(o => ParameterSource.valueOf(o)) : [];
  }

  public static createByName(name: string, parameterToken: string = ''): ParameterSource {
    const formatted = CalcOperations.startSensorToken + parameterToken + name + CalcOperations.endSensorToken;
    return name ? new ParameterSource(name, name, null, formatted, null, true) : null;
  }
}
