import {Component, Input} from '@angular/core';
import {LocalDate} from "@js-joda/core";

@Component({
  selector: 'app-expire-icon',
  templateUrl: './expire-icon.component.html',
  styleUrls: ['./expire-icon.component.scss']
})
export class ExpireIconComponent {

  private static readonly WARNING_THRESHOLD_DAYS = 30;

  status: "expired" | "expiring" | "ok"
  days: number;

  @Input() set date(date: LocalDate) {
    if (date) {
      this.init(date);
    }
  }

  @Input() set dateString(date: string) {
    if (date) {
      this.date = LocalDate.parse(date);
      return;
    }
    this.status = null;
  }

  private init(date: LocalDate) {
    const differenceDays = date.toEpochDay() - LocalDate.now().toEpochDay();

    if (differenceDays > 0 && differenceDays > ExpireIconComponent.WARNING_THRESHOLD_DAYS) {
      this.status = 'ok';
    } else if (differenceDays > 0 && differenceDays < ExpireIconComponent.WARNING_THRESHOLD_DAYS) {
      this.status = 'expiring';
    } else if (differenceDays <= 0) {
      this.status = 'expired';
    }
    this.days = Math.abs(differenceDays);
  }
}
