export class NoticeUtil {

  public static transformCount(value: number): string {
    if (!value) {
      return null;
    }
    if (value > 99) {
      return '99+';
    }
    return value.toString();
  }
}
