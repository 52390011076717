import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnitVideoShort } from '../modelinterfaces/unit-video-short.model';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class UnitWithCameraService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getUnitListWithCameras(): Observable<UnitVideoShort[]> {
    const url = `${this.environmentHelper.urlDvr}/unit`;
    return this.http.get<UnitVideoShort[]>(url).pipe(
      map(list => UnitVideoShort.valuesOf(list))
    );
  }
}
