import { DvrContentType } from './enums/dvr-content-type';

export class ContentType {

  constructor(public contentType: DvrContentType,
              public translated: string) {
  }

  public static valueOf(o: ContentType): ContentType {
    return o ? new ContentType(o.contentType, o.translated) : null;
  }

  public static valuesOf(list: ContentType[]): ContentType[] {
    return list ? list.map(o => ContentType.valueOf(o)) : [];
  }
}
