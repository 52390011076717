import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'fileSize',
  standalone: true,
})

export class FileSizePipe implements PipeTransform {

  private readonly KILOBYTES = 'Kb';
  private readonly MEGABYTES = 'Mb';
  private readonly GIGABYTES = 'Gb';

  transform(size: number): string {
    let result: string;
    switch (true) {
      case size < Math.pow(1024, 2):
        result = (Math.round(size / Math.pow(1024, 1) * 10) / 10) + this.KILOBYTES;
        break;
      case size < Math.pow(1024, 3):
        result = (Math.round(size / Math.pow(1024, 2) * 10) / 10) + this.MEGABYTES;
        break;
      case size < Math.pow(1024, 4):
        result = (Math.round(size / Math.pow(1024, 3) * 10) / 10) + this.GIGABYTES;
        break;
      default:
        result = size.toString();
    }
    return result;
  }
}
