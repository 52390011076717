import {asArray, Color} from 'ol/color';

export class ColorUtil {

  public static hexToRgbModelColor(hex: string, opacity?: number): Color {
    const color = asArray(hex);
    if (opacity) {
      color[3] = opacity;
    }
    return color;
  }

  public static hexToRgbaString(hex: string, opacity?: number): string {
    hex = hex.replace('#','');
    const r = parseInt(hex.substring(0,2), 16);
    const g = parseInt(hex.substring(2,4), 16);
    const b = parseInt(hex.substring(4,6), 16);
    if (!(opacity < 1 && opacity > 0)) {
      opacity = 1;
    }
    return `rgba(${r},${g},${b},${opacity})`;
  }
}
