import {Injectable} from '@angular/core';
import { SensorChartExt } from 'projects/common-module/src/lib/modelinterfaces/sensor-chart-ext.model';
import {BehaviorSubject, Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class ChartDataService {

  private _chartSource = new BehaviorSubject<SensorChartExt>(null);
  private _chart$: Observable<SensorChartExt> = this._chartSource.asObservable();

  private cloneChartSource: SensorChartExt; // for totally remove EChartsInstance and save receive SensorChartData

  get chart$(): Observable<SensorChartExt> {
    return this._chart$;
  }

  public init(): void {
    if (!this._chartSource.getValue() && this.cloneChartSource) {
      this._chartSource.next(this.cloneChartSource);
    }
  }

  public change(sensorChart: SensorChartExt): void {
    this.cloneChartSource = this._chartSource.getValue();
    this._chartSource.next(sensorChart);
  }

  public destroy(): void {
    this._chartSource.next(null);
  }
}
