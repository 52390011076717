<div class="flex flex-v-center flex-gap4">

  <div class="additional-info" *ngIf="warningText?.length > 0">
    <mat-icon class="icon text-orange opacity50" [ngClass]="{'clear-opacity': highlight}" [matTooltip]="warningText"
              matTooltipClass="big-multiline-tooltip">
      warning
    </mat-icon>
  </div>

  <div class="additional-info flex flex-v-center" *ngIf="alertText?.length> 0">
    <mat-icon class="icon text-red opacity50" [ngClass]="{'clear-opacity': highlight}" [matTooltip]="alertText"
              matTooltipClass="big-multiline-tooltip">
      error
    </mat-icon>
  </div>

  <div class="additional-info flex flex-v-center" [ngClass]="{'highlight': highlight}" *ngIf="secondsAgo > 0"
       [matTooltip]="'term.last-message'|translate"
       [matTooltipShowDelay]="600">

    <mat-icon class="icon">timer</mat-icon>
    <div>{{ secondsAgo | timeFromNowText }}</div>

  </div>

  <div class="additional-info flex flex-v-center"
       *ngIf="speed > STOP_SPEED"
       [ngClass]="{'highlight': highlight}"
       [matTooltip]="'term.speed'|translate"
       [matTooltipShowDelay]="600">

    <mat-icon class="icon">speed</mat-icon>
    <span>{{ speed }} {{ 'uom.km-h' | translate }}</span>

  </div>

  <div class="additional-info" [ngClass]="{'highlight': highlight}" *ngIf="driver">
    <app-driver-with-avatar [driver]="driver">
    </app-driver-with-avatar>
  </div>
</div>
