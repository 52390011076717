import {Logistic} from "../../../../../../../common-module/src/lib/modelinterfaces/logistic.model";
import Skill = Logistic.Skill;
import Skillable = Logistic.Skillable;

export class LogisticSkillUtil {
  public static getUnique(skillables: Skillable[]): Skill[] {
    if (!skillables) {
      return [];
    }
    let skills = [];
    for (const s of skillables) {
      skills = skills.concat(s.skills);
    }
    return this.getUniqueInternal(skills);
  }

  private static getUniqueInternal(skills: Skill[]): Skill[] {
    const unique = new Map();
    for (const skill of skills) {
      unique.set(skill.id, skill);
    }
    return Array.from(unique.values());
  }

  /**
   * @param arrayA основной массив
   * @param arrayB проверяемый массив
   * @return элементы из {@param arrayA} которые не представлены в {@param arrayB}
   */
  public static getDiff(arrayA: Skillable[], arrayB: Skillable[]) {
    const a = this.getUnique(arrayA);
    const b = this.getUnique(arrayB);
    return a.filter(x => !b.some(y => y.id === x.id));
  }

  public static toString(skills: Logistic.Skill[]){
    return skills.map(s => s.name).sort().join(", ")
  }
}
