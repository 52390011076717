import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LocalStorageKey} from '../../../../../../../common-module/src/lib/app-enums/local-storage-key';
import {SensorLastValue} from '../../../../../../../common-module/src/lib/modelinterfaces/sensor-last-value.model';
import {SensorListComponent} from "./sensor-list/sensor-list.component";

@Component({
  selector: 'app-chart-dialog',
  templateUrl: './chart-dialog.component.html',
  styleUrls: ['./chart-dialog.component.scss', '../../../../../../../common-module/src/lib/app-styles/dialog-common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ChartDialogComponent implements OnInit {

  @ViewChild('list', {static: false}) sensorList: SensorListComponent;

  lastSelectedSensorNames: string[] = [];

  selectedSensors: SensorLastValue[] = [];

  selectionAllState: 'none' | 'all' | 'intermediate';

  constructor(@Inject(MAT_DIALOG_DATA) public data: { sensors: SensorLastValue[] },
              private dialogRef: MatDialogRef<ChartDialogComponent>) {
  }

  ngOnInit(): void {
    this.lastSelectedSensorNames = JSON.parse(localStorage.getItem(LocalStorageKey.SENSORS_LAST_SELECTED)) ?? [];
    this.selectedSensors = this.data.sensors.filter(s => this.lastSelectedSensorNames.includes(s.name));
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onSubmit(): void {
    this.dialogRef.close({sensorIds: this.selectedSensors.map(s => s.id)});
  }

  onSelectedSensorsChange($event: SensorLastValue[]) {
    this.updateLastSelectedSensorName($event)
  }

  private updateLastSelectedSensorName(selectedSensors: SensorLastValue[]): void {
    localStorage.setItem(
      LocalStorageKey.SENSORS_LAST_SELECTED,
      JSON.stringify(selectedSensors.map(s => s.name))
    );
  }

  toggleAllSelection() {
    this.sensorList.toggleSelection();
  }

  onSelectionStateChange($event: "none" | "all" | "intermediate") {
    this.selectionAllState = $event;
  }
}
