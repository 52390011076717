<div [style.width.px]="width" class="card"
     [ngClass]="{'card_le_l': (viewBreakpointService.size$ | async).less('l')}">
  <h4>
    <span>{{ 'term.object-status' | translate }}</span>
  </h4>

  <p>
    <ng-container *ngIf="unitData.lastMotionState.state === 'GO'; else stop">
      <span class="title">{{ 'term.in-movement' | translate }}:</span>
    </ng-container>
    <ng-template #stop>
      <span class="title">{{ 'term.without-movement' | translate }}: </span>
    </ng-template>
    <span class="text">
      {{ unitData.lastMotionState.secondsAgo | secToDuration : translateService }}
    </span>
  </p>

  <p>
    <span class="title">{{ 'term.gps-odometer' | translate }}: </span>
    <span class="text">
      {{ unitData.gpsOdometer.corrected | sensorValue: 1 }} {{ 'uom.km' | translate }}
    </span>
  </p>

  <p>
    <span class="title">{{ sensorGpsSpeed?.nameTranslated }}: </span>
    <span class="text">
      {{ sensorGpsSpeed?.value | sensorValue: 1 }} {{ sensorGpsSpeed?.unitOfMeasure.nameTranslated }}
    </span>
  </p>
  <p *ngIf="sensorOnBoardVoltage">
    <span class="title">{{ sensorOnBoardVoltage?.nameTranslated }}: </span>
    <span class="text">
      <span>{{ sensorOnBoardVoltage?.value | sensorValue: 1 }} </span>
      <span>{{ sensorOnBoardVoltage?.unitOfMeasure.nameTranslated }}</span>
    </span>
  </p>
</div>
