<div class="criterion-header">
  <div class="processing">
    <button mat-flat-button class="add-button" [disabled]="!!criterionForm" (click)="buildForm()">
      <mat-icon>add</mat-icon>
      <span>{{ 'action.add-criterion' | translate }}</span>
    </button>

    <button
      mat-icon-button
      class="action-button"
      [matTooltip]="'action.save' | translate"
      [disabled]="!(dataSource?.data?.length > 0)"
      (click)="saveCriterionListInFile()">
      <mat-icon>cloud_download</mat-icon>
    </button>

    <span class="warning-message"
          *ngIf="!isFullAvailableCriterionTypes">{{ 'eco-driving.tracker-not-transmit' | translate }}</span>
  </div>


  <div *ngIf="!(dataSource?.data?.length > 0)" class="box-imports">
    <div class="processing">
      <span class="title">{{ 'eco-driving.import-from-preset' | translate }}:</span>

      <mat-form-field subscriptSizing="dynamic">
        <mat-label>{{ 'eco-driving.preset' | translate }}</mat-label>
        <mat-select [(value)]="selectedVehicleType">
          <mat-option
            class="tab-item"
            *ngFor="let vehicle of VEHICLE_TYPES"
            [value]="vehicle">
            {{ 'vehicle.' + vehicle | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button
        mat-flat-button
        class="add-button"
        [disabled]="dataSource?.data?.length > 0"
        (click)="onImportCriterionListFromTemplate(selectedVehicleType)">
        {{ 'action.apply' | translate }}
      </button>
    </div>

    <div class="processing import-from">
      <span>{{ 'eco-driving.import-from-unit' | translate }}:</span>

      <button mat-flat-button
              class="add-button"
              (click)="onImportCriterionListFromAnotherUnit()">
        <span>{{ 'action.import' | translate }}</span>
      </button>
    </div>

    <div class="processing import-from">
      <span>{{ 'action.import-from-file' | translate }}:</span>

      <button mat-flat-button
              class="add-button"
      >
        <label for="file">
          <span>{{ 'action.import' | translate }}</span>
        </label>

        <input type="file"
               class="not-show"
               id="file"
               accept=".aur"
               (change)="onImportCriterionListFromFile($event.target)"/>
      </button>
    </div>
  </div>
</div>

<div class="criterion-container">
  <form [formGroup]="criterionForm" *ngIf="criterionForm">
    <div class="wrapper-form">
      <div class="dialog-title">
        <span *ngIf="!currentCriterionId">{{ 'action.add-criterion' | translate }}</span>
        <span *ngIf="currentCriterionId">{{ 'action.edit-criterion' | translate }}</span>
      </div>
      <div class="separate-columns padding-top-8">
        <div class="form-container vehicle">
          <div class="fields-in-row">
            <mat-form-field subscriptSizing="dynamic">
              <mat-label>{{ 'term.name' | translate }}</mat-label>
              <input matInput
                     [formControlName]="control.NAME">
              <mat-error *ngIf="formControl[control.NAME].errors?.required">
                {{ 'message.error.required-field' | translate }}
              </mat-error>
            </mat-form-field>

            <div class="decrease-width">
              <app-color-picker-input [formControlName]="control.COLOR">
              </app-color-picker-input>
            </div>
          </div>

          <div class="fields-in-row">
            <mat-form-field subscriptSizing="dynamic">
              <mat-label> {{ 'eco-driving.criterion' | translate }}</mat-label>
              <mat-select [formControlName]="control.CRITERION">
                <mat-option class="tab-item"
                            *ngFor="let criterion of criterionType | keyvalue"
                            [value]="criterion.key">
                  {{ 'eco-driving.criterion-type.' + criterion.value.toLowerCase() | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-icon class="warning-icon" *ngIf="!isAvailable(formValue[control.CRITERION])"
                      [matTooltip]="'eco-driving.data-not-available' | translate">
              warning
            </mat-icon>
          </div>


          <mat-form-field subscriptSizing="dynamic" *ngIf="formValue[control.CRITERION] === criterionType.CUSTOM">
            <mat-label>{{ 'term.sensor' | translate }}</mat-label>
            <mat-select [formControlName]="control.SENSOR">

              <mat-option
                class="tab-item"
                *ngFor="let sensor of sensors"
                [value]="sensor">
                {{ sensor.nameTranslated }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="fields-in-row">
            <mat-form-field subscriptSizing="dynamic">
              <mat-label>{{ 'eco-driving.minimal-value' | translate }}</mat-label>
              <input matInput
                     type="number"
                     step="0.1"
                     [formControlName]="control.MIN_VALUE">
              <mat-error *ngIf="formControl[control.MIN_VALUE].errors?.required">
                {{ 'message.error.required-field' | translate }}
              </mat-error>
              <mat-error *ngIf="formControl[control.MIN_VALUE].errors?.min">
                {{ 'message.error.min-value' | translate: {min: formControl[control.MIN_VALUE].errors.min.min} }}
              </mat-error>
              <mat-error *ngIf="formControl[control.MIN_VALUE].errors?.max">
                {{ 'message.error.max-value' | translate: {max: formControl[control.MIN_VALUE].errors.max.max} }}
              </mat-error>
            </mat-form-field>

            <mat-form-field subscriptSizing="dynamic">
              <mat-label> {{ 'eco-driving.maximum-value' | translate }}</mat-label>
              <input matInput
                     type="number"
                     step="0.1"
                     [formControlName]="control.MAX_VALUE">
              <mat-error *ngIf="formControl[control.MAX_VALUE].errors?.required">
                {{ 'message.error.required-field' | translate }}
              </mat-error>
              <mat-error *ngIf="formControl[control.MAX_VALUE].errors?.min">
                {{ 'message.error.min-value' | translate: {min: formControl[control.MAX_VALUE].errors.min.min} }}
              </mat-error>
              <mat-error *ngIf="formControl[control.MAX_VALUE].errors?.max">
                {{ 'message.error.max-value' | translate: {max: formControl[control.MAX_VALUE].errors.max.max} }}
              </mat-error>
            </mat-form-field>

          </div>

          <div class="fields-in-row">
            <mat-form-field subscriptSizing="dynamic">
              <mat-label> {{ 'eco-driving.penalty' | translate }}</mat-label>
              <input matInput
                     type="number"
                     [formControlName]="control.PENALTY">
              <mat-error *ngIf="formControl[control.PENALTY].errors?.required">
                {{ 'message.error.required-field' | translate }}
              </mat-error>
              <mat-error *ngIf="formControl[control.PENALTY].errors?.min">
                {{ 'message.error.min-value' | translate: {min: formControl[control.PENALTY].errors.min.min} }}
              </mat-error>
              <mat-error *ngIf="formControl[control.PENALTY].errors?.max">
                {{ 'message.error.max-value' | translate: {max: formControl[control.PENALTY].errors.max.max} }}
              </mat-error>
            </mat-form-field>

            <mat-slider [ngClass]="currentPenaltySliderClass"
                        [max]="PENALTY_LIMIT"
                        [min]="0"
                        [step]="1">
              <input matSliderThumb [value]="formValue[control.PENALTY]"
                     (valueChange)="onChangePenalty($event)">
            </mat-slider>
          </div>
        </div>


        <div class="form-container vehicle">
          <div class="fields-in-row">
            <mat-form-field subscriptSizing="dynamic">
              <mat-label> {{ 'eco-driving.min-duration' | translate }}</mat-label>
              <input matInput
                     type="number"
                     step="0.1"
                     [formControlName]="control.MIN_DURATION">
              <span matSuffix class="suffix-margin">{{ 'uom.sec' | translate }}</span>
              <mat-error *ngIf="formControl[control.MIN_DURATION].errors?.required">
                {{ 'message.error.required-field' | translate }}
              </mat-error>
              <mat-error *ngIf="formControl[control.MIN_DURATION].errors?.min">
                {{ 'message.error.min-value' | translate: {min: formControl[control.MIN_DURATION].errors.min.min} }}
              </mat-error>
              <mat-error *ngIf="formControl[control.MIN_DURATION].errors?.max">
                {{ 'message.error.max-value' | translate: {max: formControl[control.MIN_DURATION].errors.max.max} }}
              </mat-error>
            </mat-form-field>

            <mat-form-field subscriptSizing="dynamic">
              <mat-label> {{ 'eco-driving.max-duration' | translate }}</mat-label>
              <input matInput
                     type="number"
                     step="0.1"
                     [formControlName]="control.MAX_DURATION">
              <span matSuffix class="suffix-margin">{{ 'uom.sec' | translate }}</span>
              <mat-error *ngIf="formControl[control.MAX_DURATION].errors?.required">
                {{ 'message.error.required-field' | translate }}
              </mat-error>
              <mat-error *ngIf="formControl[control.MAX_DURATION].errors?.min">
                {{ 'message.error.min-value' | translate: {min: formControl[control.MAX_DURATION].errors.min.min} }}
              </mat-error>
              <mat-error *ngIf="formControl[control.MAX_DURATION].errors?.max">
                {{ 'message.error.max-value' | translate: {max: formControl[control.MAX_DURATION].errors.max.max} }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="fields-in-row">
            <mat-form-field subscriptSizing="dynamic">
              <mat-label>{{ 'eco-driving.min-speed' | translate }}</mat-label>
              <input matInput
                     type="number"
                     step="0.1"
                     [formControlName]="control.MIN_SPEED">
              <span matSuffix class="suffix-margin">{{ 'uom.km-h' | translate }}</span>
              <mat-error *ngIf="formControl[control.MIN_SPEED].errors?.required">
                {{ 'message.error.required-field' | translate }}
              </mat-error>
              <mat-error *ngIf="formControl[control.MIN_SPEED].errors?.min">
                {{ 'message.error.min-value' | translate: {min: formControl[control.MIN_SPEED].errors.min.min} }}
              </mat-error>
              <mat-error *ngIf="formControl[control.MIN_SPEED].errors?.max">
                {{ 'message.error.max-value' | translate: {max: formControl[control.MIN_SPEED].errors.max.max} }}
              </mat-error>
            </mat-form-field>

            <mat-form-field subscriptSizing="dynamic">
              <mat-label>{{ 'eco-driving.max-speed' | translate }}</mat-label>
              <input matInput
                     type="number"
                     step="0.1"
                     [formControlName]="control.MAX_SPEED">
              <span matSuffix class="suffix-margin">{{ 'uom.km-h' | translate }}</span>
              <mat-error *ngIf="formControl[control.MAX_SPEED].errors?.required">
                {{ 'message.error.required-field' | translate }}
              </mat-error>
              <mat-error *ngIf="formControl[control.MAX_SPEED].errors?.min">
                {{ 'message.error.min-value' | translate: {min: formControl[control.MAX_SPEED].errors.min.min} }}
              </mat-error>
              <mat-error *ngIf="formControl[control.MAX_SPEED].errors?.max">
                {{ 'message.error.max-value' | translate: {max: formControl[control.MAX_SPEED].errors.max.max} }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="fields-in-row">
            <mat-form-field subscriptSizing="dynamic">
              <mat-label>{{ 'eco-driving.validator' | translate }}</mat-label>
              <mat-select
                class=""
                [formControlName]="control.VALIDATOR">
                <mat-option
                  class="tab-item"
                  *ngFor="let sensor of sensors"
                  [value]="sensor">
                  {{ sensor.nameTranslated }}
                </mat-option>
              </mat-select>

              <span matSuffix class="suffix-margin" *ngIf="showValidatorSpinner">
                <mat-icon>
                  <mat-spinner diameter="20"></mat-spinner>
                </mat-icon>
              </span>
            </mat-form-field>

            <mat-checkbox
              labelPosition="after"
              [formControlName]="control.MULTIPLIER">
              {{ 'eco-driving.multiplier' | translate }}
            </mat-checkbox>
          </div>

          <div class="fields-in-row">

          </div>
        </div>
      </div>

      <div class="margin-top-4">
        <app-dialog-actions-cancel-save (cancel)="onCancelForm()"
                                        (save)="onSubmitForm()"
                                        [saveDisable]="criterionForm.invalid || criterionForm.pristine">

        </app-dialog-actions-cancel-save>
      </div>
    </div>
  </form>

  <div class="box-table">
    <table
      mat-table
      *ngIf="dataSource?.data.length > 0"
      [dataSource]="dataSource">
      <ng-container matColumnDef="{{ displayedColumns[0] }}">
        <th class="title" mat-header-cell *matHeaderCellDef>{{ 'term.name' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div class="wrapper-name">
            <mat-icon *ngIf="asCriterion(element).color; else iconNoColor" [style.color]="asCriterion(element).color">
              label
            </mat-icon>

            <div class="wrapper-details">
              <span>{{ asCriterion(element).name }}</span>
              <span *ngIf="!availableCriterionTypes.includes(asCriterion(element).type)" class="warning-message">
              {{ 'eco-driving.data-not-available' | translate }}
            </span>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="{{ displayedColumns[1] }}">
        <th class="title" mat-header-cell *matHeaderCellDef>{{ 'eco-driving.' + displayedColumns[1] | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ 'eco-driving.criterion-type.' + asCriterion(element).type.toLocaleLowerCase() | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="{{ displayedColumns[2] }}">
        <th class="title" mat-header-cell *matHeaderCellDef>{{ 'eco-driving.' + displayedColumns[2] | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ asCriterion(element).valueRange.min | sensorValue: 2 }}</td>
      </ng-container>

      <ng-container matColumnDef="{{ displayedColumns[3] }}">
        <th class="title" mat-header-cell *matHeaderCellDef>{{ 'eco-driving.' + displayedColumns[3] | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ asCriterion(element).valueRange.max | sensorValue: 2 }}</td>
      </ng-container>

      <ng-container matColumnDef="{{ displayedColumns[4] }}">
        <th class="title" mat-header-cell *matHeaderCellDef>{{ 'eco-driving.' + displayedColumns[4] | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ asCriterion(element).penalty }}</td>
      </ng-container>

      <ng-container matColumnDef="{{ displayedColumns[5] }}">
        <th class="title" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            class="icon-button copy"
            (click)="onCopyCriterion($event, asCriterion(element))"
            matTooltip="{{ 'action.copy' | translate }}">
            <mat-icon>content_copy</mat-icon>
          </button>

          <button mat-icon-button
                  class="icon-button delete"
                  (click)="onDeleteCriterion($event, asCriterion(element))"
                  matTooltip="{{ 'action.delete' | translate }}">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="editCriterion(asCriterion(row))"></tr>
    </table>
  </div>
</div>

<ng-template #iconNoColor>
  <mat-icon class="icon-no-color">label</mat-icon>
</ng-template>
