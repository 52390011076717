import { FilterParam } from '../app-models/filter-param.model';
import { NotificationFilter } from './enums/notification-filter';

export class NotificationFilters {

  public startTime: FilterParam;
  public source: FilterParam;
  public state: FilterParam;
  public status: FilterParam;

  constructor() {
    this.startTime = new FilterParam(NotificationFilter.TIME);
    this.source = new FilterParam(NotificationFilter.SOURCE);
    this.state = new FilterParam(NotificationFilter.STATE);
    this.status = new FilterParam(NotificationFilter.STATUS);
  }
}
