<app-dialog [title]="('term.public-link' | translate)">

  <div class="content" appDialogContent>
    @if (!link) {
      <div>Сгенерируйте ссылку для общего доступа к выбранным объектам на карте.
        Она будет активна в выбранный вами период и позволит другим людям просматривать их местоположение без входа в
        систему.
      </div>
    }
    <div matTooltipClass="big-multiline-tooltip"
         [matTooltip]="selectedUnitNames"
         class="text-primary-color">
      <b>Выбрано объектов: {{ selectedUnitsCount }}</b>
    </div>

    @if (!link) {
      <div class="date-picker-content">
        <ngx-daterangepicker-material class="ngx-daterangepicker-disable-shadow"
                                      [timePicker]="true"
                                      [lockStartDate]="true"
                                      [timePicker24Hour]="true"
                                      [minDate]="minDate"
                                      [linkedCalendars]="true"
                                      [autoApply]="true"
                                      (choosedDate)="changeDateTimeRange($event)">
        </ngx-daterangepicker-material>
      </div>
    } @else {
      <div class="box-link">
        <div class="link">
          <mat-form-field>
            <mat-label>{{ 'term.link' | translate }}</mat-label>
            <input matInput
                   [value]="link"
                   readonly/>
          </mat-form-field>
        </div>

        <div class="message">
          {{
            'message.info.anyone-can-view-location' | translate:
              {time: dateTimeRange.endDate.toISOString() | utcDateTz: (authUser$ | async)?.timeZone.name: 'DD.MM.YYYY HH:mm'}
          }}
        </div>
      </div>
    }
  </div>

  <ng-container appDialogActions>
    @if (!link) {
      <app-button-cancel (cancel)="onClose()">
      </app-button-cancel>

      <app-button-primary [text]="'action.generate' | translate"
                          [disabled]="!!link"
                          (action)="onGenerateLink()">
      </app-button-primary>
    } @else {
      <app-button-close></app-button-close>
      <app-button-primary [text]="'Скопировать ссылку'"
                          (action)="copyToClipboard()">
      </app-button-primary>
    }

  </ng-container>

</app-dialog>
