<mat-form-field>
  <mat-label>Базовая дата</mat-label>
  <input matInput [matDatepicker]="datepicker" [formControl]="control">
  <mat-hint>ДД.MM.ГГГГ</mat-hint>
  <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
  <mat-datepicker #datepicker>
    <mat-datepicker-actions>
      <app-button-cancel matDatepickerCancel [shouldCloseDialog]="false"></app-button-cancel>
      <app-button-apply matDatepickerApply class="margin-left-8"></app-button-apply>
    </mat-datepicker-actions>
  </mat-datepicker>
</mat-form-field>
