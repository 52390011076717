import { TypeLog } from './enums/type-log';

export class ImportLog {

  constructor(public level: TypeLog,
              public message: string,
              public messageTranslated: string,
              public params: string[]) {
  }

  public static valueOf(o: ImportLog): ImportLog {
    return o ? new ImportLog(o.level, o.message, o.messageTranslated, o.params) : null;
  }

  public static valuesOf(list: ImportLog[]): ImportLog[] {
    return list ? list.map(o => ImportLog.valueOf(o)) : [];
  }
}
