import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {UnitShort} from '../../../../../../common-module/src/lib/modelinterfaces/unit-short.model';
import {User} from '../../../../../../common-module/src/lib/modelinterfaces/user.model';

import {SelectedService} from '../../../shared/services/selected.service';
import {UnitGroupsService} from '../../../shared/services/unit-groups.service';
import {PublicAccess} from '../../../../../../common-module/src/lib/modelinterfaces/public-access.model';
import {PublicAccessService} from '../../../../../../common-module/src/lib/services/public-access.service';
import {UiSpinnerService} from '../../../../../../common-module/src/lib/app-services/ui-spinner.service';
import {AppMessageService} from '../../../../../../common-module/src/lib/app-services/app-message.service';
import {AuthUserService} from '../../../../../../common-module/src/lib/app-services/auth-user.service';
import {DateRangeI} from '../../../../../../common-module/src/lib/app-interfaces/date-range-i';
import {DayjsUtil} from "../../../../../../common-module/src/lib/dayjs.util";
import {Dayjs} from "dayjs";
import {BaseUnsubscribeComponent} from "../../../shared/components/base-unsubscribe.component";


@Component({
  selector: 'app-public-link-dialog',
  templateUrl: './public-link-dialog.component.html',
  styleUrls: ['./public-link-dialog.component.scss', '../../../../../../common-module/src/lib/app-styles/dialog-common.scss']
})

export class PublicLinkDialogComponent extends BaseUnsubscribeComponent implements OnInit {

  public authUser$: Observable<User>;
  public dateTimeRange: DateRangeI;
  public isActiveGenerateButton = true;
  public link = '';
  public minDate: Dayjs;
  public selectedUnitsCount = 0;
  public selectedUnitNames = '';

  constructor(private appMessageService: AppMessageService,
              private authUserService: AuthUserService,
              private dialogRef: MatDialogRef<PublicLinkDialogComponent>,
              private publicAccessService: PublicAccessService,
              private selectedService: SelectedService,
              private uiSpinnerService: UiSpinnerService,
              private unitGroupsService: UnitGroupsService) {
    super();
  }

  ngOnInit(): void {
    this.dateTimeRange = this.getRange(30);
    this.minDate = DayjsUtil.instant().startOf('day');
    this.authUser$ = this.authUserService.currentUser$;
    this.selectedService.selectedUnitIdsSet$.pipe(
      map(idSet => this.getUnitListByIdSet(idSet)),
      takeUntil(this.destroy)
    ).subscribe(units => {
      this.selectedUnitsCount = units.length;
      this.selectedUnitNames = units.map(u => u.name).join('\n');
    })
  }

  private getRange(daysAmount: number): DateRangeI {
    return {
      startDate: DayjsUtil.instant().startOf('day'),
      endDate: DayjsUtil.instant().add(daysAmount, 'day').endOf('day')
    };
  }

  private getUnitListByIdSet(idSet: Set<number>): UnitShort[] {
    const units = [];
    idSet.forEach(id => {
      const unit = this.unitGroupsService.getInstantWithoutGroups().find(el => el.id === id);
      if (unit) {
        units.push(unit);
      }
    });
    return units;
  }

  public changeDateTimeRange(timeRange: object): void {
    this.dateTimeRange = {startDate: timeRange['startDate'], endDate: timeRange['endDate']};
    this.link = '';
    this.isActiveGenerateButton = true;
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public onGenerateLink(): void {
    const publicAccessSettings = new PublicAccess(
      0,
      this.dateTimeRange.endDate.toISOString(),
      null,
      this.getUnitListByIdSet(this.selectedService.getInstantUnitIdsSet()),
      null
    );
    this.uiSpinnerService.show();
    this.publicAccessService.getPublicAccessSettings(publicAccessSettings).subscribe(
      settings => {
        this.link = settings.url;
        this.uiSpinnerService.stop();
        this.isActiveGenerateButton = false;
      }
    );
  }

  public copyToClipboard(): void {
    this.appMessageService.copyToClipBoard(this.link);
  }
}
