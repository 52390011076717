import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { saveAs as importedSaveAs } from 'file-saver';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageKey } from '../../../../../common-module/src/lib/app-enums/local-storage-key';
import { Tab } from '../../../../../common-module/src/lib/app-enums/tab';
import { UiSpinnerService } from '../../../../../common-module/src/lib/app-services/ui-spinner.service';
import { Page } from '../../../../../common-module/src/lib/modelinterfaces/page.model';
import { ReportMeta } from '../../../../../common-module/src/lib/modelinterfaces/report-meta.model';
import { Report } from '../../../../../common-module/src/lib/modelinterfaces/report.model';
import { ReportService } from '../../../../../common-module/src/lib/services/report.service';

@Injectable({
  providedIn: 'root'
})

export class ReportDownloadService {

  public readonly AVAILABLE_TYPES = ['pdf', 'xlsx'];
  public readonly REPORT_LIST_SIZE = 15;

  private _currentReportsSource = new BehaviorSubject<Report[]>(null);
  private _currentReports$ = this._currentReportsSource.asObservable();

  private _pageReportListSource = new BehaviorSubject<Page<ReportMeta>>(null);
  private _pageReportList$: Observable<Page<ReportMeta>> = this._pageReportListSource.asObservable();

  private _loadingStateSource = new BehaviorSubject<boolean>(false);
  private _loadingState$ = this._loadingStateSource.asObservable();

  constructor(private reportService: ReportService,
              private router: Router,
              private uiSpinnerService: UiSpinnerService) {
  }

  get currentReports$(): Observable<Report[]> {
    return this._currentReports$;
  }

  get pageReportList$(): Observable<Page<ReportMeta>> {
    return this._pageReportList$;
  }

  get loadingState$(): Observable<boolean> {
    return this._loadingState$;
  }

  public getReportList(currentPage: number, size: number = this.REPORT_LIST_SIZE): void {
    this.uiSpinnerService.show();
    this.reportService.getAvailableReportMetaPages(currentPage, size).subscribe(
      page => {
        if (page) {
          this._pageReportListSource.next(page);
        }
        this.uiSpinnerService.stop();
      }
    );
  }

  public getReportFromCache(reportIds: number[]): void {
    this.uiSpinnerService.show();
    this.reportService.getReportFromCache(reportIds).subscribe(
      reports => {
        this.changeReport(reports);
        this.uiSpinnerService.stop();
      }
    );
  }

  public changeReport(reports: Report[]): void {
    this._currentReportsSource.next(reports);
    if (reports?.length > 0) {
      this.router.navigate([Tab.REPORT + '/group/' + reports[0].buildInfo.groupId]).then();
    } else {
      this.router.navigate([Tab.REPORT + '/list']).then();
    }
  }

  public getInstantReports(): Report[] {
    return this._currentReportsSource.getValue();
  }

  public setLastSelectedFileType(t: string): void {
    t = this.checkFileType(t);
    window.localStorage.setItem(LocalStorageKey.REPORT_TYPE, t);
  }

  public checkFileType(type: string): string {
    return this.AVAILABLE_TYPES.some(el => el === type) ? type : this.AVAILABLE_TYPES[0];
  }

  public getLastSelectedFileType(): string {
    const r = window.localStorage.getItem(LocalStorageKey.REPORT_TYPE);
    return r ? r : this.AVAILABLE_TYPES[0];
  }

  public download(reportIds: number[], type: string, reportName: string, unitName: string = ''): void {
    type = this.checkFileType(type);
    this.changeLoadingState(true);
    this.reportService.download(reportIds, type).subscribe(
      (response) => {
        const fileOfBlob = new File([response.body], this.buildFileName(reportIds, type, reportName, unitName));
        importedSaveAs(fileOfBlob);
        this.changeLoadingState(false);
      },
      error => {
        this.changeLoadingState(false);
        throw error;
      }
    );
  }

  public getReportIds(reports: Report[]): number[] {
    return reports.map(el => el.id);
  }

  private changeLoadingState(state: boolean) {
    this._loadingStateSource.next(state);
  }

  private buildFileName(reportIds: number[], type: string, reportName: string, unitName: string = ''): string {
    if (reportIds.length > 1) {
      return `${reportIds.length} ${reportName}.${type}`;
    }
    return `${unitName} ${reportName}.${type}`;
  }

  public destroy(): void {
    this._currentReportsSource.next(null);
  }
}
