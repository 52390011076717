<cmn-common-filter [name]="name"
                   [tooltip]="tooltip"
                   [viewValue]="viewValue"
                   [showApplyButton]="false"
                   (open)="onOpen()"
                   (clear)="onClear()">

  <ngx-daterangepicker-material class="ngx-daterangepicker-disable-shadow"
                                *ngIf="dateRangeLimits; else withoutLimits"
                                [timePicker]="true"
                                [timePicker24Hour]="true"
                                [locale]="LOCALE"
                                [startDate]="filter.startDate"
                                [endDate]="filter.endDate"
                                [minDate]="dateRangeLimits?.startDate"
                                [maxDate]="dateRangeLimits?.endDate"
                                [linkedCalendars]="true"
                                (choosedDate)="onChange($event)">
  </ngx-daterangepicker-material>

  <ng-template #withoutLimits>
    <ngx-daterangepicker-material class="ngx-daterangepicker-disable-shadow"
                                  [timePicker]="true"
                                  [timePicker24Hour]="true"
                                  [locale]="LOCALE"
                                  [linkedCalendars]="true"
                                  (choosedDate)="onChange($event)">
    </ngx-daterangepicker-material>
  </ng-template>

</cmn-common-filter>
