import {Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {NgIf} from "@angular/common";
import {MatInputModule} from "@angular/material/input";

@Component({
  selector: 'app-search',
  standalone: true,
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  templateUrl: './search.component.html',
  imports: [
    MatInputModule,
    MatFormFieldModule,
    TranslateModule,
    MatIconModule,
    NgIf,
  ],
  styleUrls: ['./search.component.scss']
})

export class SearchComponent implements OnInit {

  @Input() setDebounce = false;
  @Input() debounceIntervalMs = 700;
  @Input() value = '';

  @Output() searchChange = new EventEmitter<string>();

  public searchValue = '';

  private searchSource = new BehaviorSubject('');
  private subscription: Subscription;

  constructor() {
  }

  ngOnInit(): void {
    this.subscription = this.searchSource.pipe(
      debounceTime(this.setDebounce ? this.debounceIntervalMs : 0),
    )
      .subscribe(searchString => {
        this.searchValue = searchString;
        this.searchChange.emit(this.searchValue);
      });
  }

  public onChangeSearch($event: Event): void {
    this.searchSource.next(($event.target as HTMLInputElement).value.toLowerCase());
  }

  public clearSearch(): void {
    this.searchSource.next('');
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
