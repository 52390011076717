<header class="box-header">
  <span>{{ title }}</span>

  <ng-container *ngIf="!showSpinner; else spinner">
    <app-icon-button-primary [disabled]="disableEdit"
                             [icon]="editIcon"
                             (action)="edit.emit($event)">
    </app-icon-button-primary>

    <app-icon-button-delete *ngIf="!hideDelete"
                            [disabled]="disableDelete"
                            [matTooltip]="'action.delete' | translate"
                            (delete)="delete.emit($event)">
    </app-icon-button-delete>
  </ng-container>

  <ng-template #spinner>
    <mat-icon class="header-icon">
      <mat-spinner diameter="20"></mat-spinner>
    </mat-icon>
  </ng-template>
</header>
