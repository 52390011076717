import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-slide-panel-animated',
  templateUrl: './slide-panel-animated.component.html',
  styleUrls: ['./slide-panel-animated.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translateX(0%)',
        display: 'block'
      })),
      state('out', style({
        transform: 'translateX(-100%)', // Изменено с 100% на -100%
        display: 'none' // Скрытие элемента при состоянии 'out'
      })),
      transition('in => out', animate('200ms ease-in-out')),
      transition('out => in', animate('200ms ease-in-out'))
    ]),
  ]
})
export class SlidePanelAnimatedComponent implements OnChanges {

  @Input() isOpen: boolean = false;
  panelState: 'out' | 'in' = 'out';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isOpen) {
      this.panelState = this.isOpen ? 'in' : 'out';
    }
  }
}
