import {Feature} from "ol";
import {FeatureLike} from "ol/Feature";

export class OlFeatureProps {

  public static PROPERTY_VALUE = 'value';

  public static set(f: Feature, value: any): void {
    f.set(this.PROPERTY_VALUE, value);
  }

  public static get(f: FeatureLike): any {
    return f.get(this.PROPERTY_VALUE);
  }
}
