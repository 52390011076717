import { GpsCoordinate } from './gps-coordinate.model';

export class GpsState {

  constructor(public coordinate: GpsCoordinate,
              public course: number,
              public speed: number,
              public altitude: number) {
  }

  public static valueOf(i: GpsState): GpsState {
    return i ? new GpsState(GpsCoordinate.valueOf(i.coordinate), i.course, i.speed, i.altitude) : null;
  }
}
