import {Camera} from './camera.model';
import {DvrFileType} from './enums/dvr-file-type';
import {PageModel} from './page-model.model';
import {Page} from './page.model';

export class DvrFileTrackerState {
  constructor(public readonly status: DvrFileTrackerStatus,
              public readonly size: number) {
  }

  public static valueOf(i: DvrFileTrackerState): DvrFileTrackerState {
    return new DvrFileTrackerState(i.status, i.size);
  }
}

export enum DvrFileTrackerStatus {
  NONE = 'NONE', AVAILABLE = 'AVAILABLE'
}

export class DvrFileFtpState {
  constructor(public readonly status: DvrFileFtpStatus,
              public readonly link: string,
              public readonly size: number) {
  }

  public static valueOf(i: DvrFileFtpState): DvrFileFtpState {
    return new DvrFileFtpState(i.status, i.link, i.size);
  }
}

export enum DvrFileFtpStatus {
  NONE = 'NONE',
  PENDED = 'PENDED',
  UPLOADING = 'UPLOADING',
  UPLOADED = 'UPLOADED',
  FAIL = 'FAIL',
  DECODING_PENDED = "DECODING_PENDED",
  DECODING_START = "DECODING_START",
}


export class DvrFile {

  private static readonly UPLOADING_FTP_STATUSES = new Set([DvrFileFtpStatus.UPLOADING, DvrFileFtpStatus.DECODING_PENDED, DvrFileFtpStatus.DECODING_START])

  constructor(public readonly name: string,
              public readonly camera: Camera,
              public readonly startTime: string,
              public readonly endTime: string,
              public readonly fileType: DvrFileType,
              public readonly trackerState: DvrFileTrackerState,
              public readonly ftpState: DvrFileFtpState) {
  }

  public get isNotUploadedToFtp(): boolean {
    return this.trackerState.status === DvrFileTrackerStatus.AVAILABLE && this.ftpState.status === DvrFileFtpStatus.NONE;
  }

  public get isUploadingToFtp(): boolean {
    return DvrFile.UPLOADING_FTP_STATUSES.has(this.ftpState.status);
  }

  public get isUploaded(): boolean {
    return this.ftpState.status === DvrFileFtpStatus.UPLOADED;
  }


  public static valueOf(o: DvrFile): DvrFile {
    return o ?
      new DvrFile(o.name, Camera.valueOf(o.camera), o.startTime, o.endTime, o.fileType, DvrFileTrackerState.valueOf(o.trackerState), DvrFileFtpState.valueOf(o.ftpState))
      : null;
  }

  public static valuesOf(list: DvrFile[]): DvrFile[] {
    return list ? list.map(o => DvrFile.valueOf(o)) : [];
  }

  public static pageableValueOf(o: Page<DvrFile>): Page<DvrFile> {
    return o ? new PageModel<DvrFile>(o.empty, o.first, o.last, o.number, o.numberOfElements, o.totalElements, o.totalPages,
      o.urlParams, DvrFile.valuesOf(o.content)) : null;
  }
}
