import Stroke, {Options} from "ol/style/Stroke";

export interface OlStrokeOpts extends Options {
}

export class OlStrokeFactory {
  public static create(opts: OlStrokeOpts) {
    return new Stroke(opts)
  }
}
