import { FilterParam } from './filter-param.model';

export class DvrFilters {

  public time: FilterParam;
  public cameraIds: FilterParam;
  public contentType: FilterParam;
  public fileType: FilterParam;
  public status: FilterParam;

  constructor() {
    this.time = new FilterParam('timeFilter');
    this.cameraIds = new FilterParam('cameraIdFilter');
    this.contentType = new FilterParam('contentTypeFilter');
    this.fileType = new FilterParam('fileTypeFilter');
    this.status = new FilterParam('statusFilter');
  }
}
