import {Injectable} from '@angular/core';
import Point from 'ol/geom/Point';
import {Layer} from 'ol/layer';
import OlMap from 'ol/Map';
import {BehaviorSubject, Observable} from 'rxjs';
import {AppColor} from '../../../../../common-module/src/lib/app-enums/app-color';
import {DvrFilters} from "../../../../../common-module/src/lib/app-models/dvr-filters.models";
import {AuthUserService} from '../../../../../common-module/src/lib/app-services/auth-user.service';
import {GpsCoordinate} from '../../../../../common-module/src/lib/modelinterfaces/gps-coordinate.model';
import {Track} from '../../../../../common-module/src/lib/modelinterfaces/track.model';
import {DvrFileShortService} from '../../../../../common-module/src/lib/services/dvr-file-short.service';
import {LayerName} from '../constants/enums/layer-name';
import {UrlImage} from '../constants/url-image';
import {ZIndexLayer} from '../constants/z-index';
import {CenterMapUtil} from '../map-utils/center-map.util';
import {LayerUtil} from '../map-utils/layer.util';
import {OlCoordinate} from '../map-utils/OlCoordinate';
import {OlFeature} from '../map-utils/OlFeature';
import {OlStyle} from '../map-utils/OlStyle';
import {OlVectorLayer} from '../map-utils/OlVectorLayer';
import {OlTrackBuildService} from './ol-track-build.service';
import {
  SensorRanges
} from "../../system/main/toolbar/track-options/track-sensor-options-dialog/track-sensor-options-dialog.component";
import {TrackLayerSensorRangesService} from "./track-layer-sensor-ranges.service";

export interface TrackOptions {
  isShowParkings: boolean;
  isShowTrips: boolean;
  isShowPoints: boolean;
  isStartFinish: boolean;
  maxSpeed: number;
  minDurationOverSpeed: number;
  sensorRanges: SensorRanges[]
}

@Injectable({
  providedIn: 'root'
})

export class TrackLayerService {

  private readonly MARKER_STYLE_SELECTED_POSITION = OlStyle.createForIconWithAnchor(UrlImage.POSITION, 0.5, 23);
  private readonly MARKER_STYLE_SELECTED_PARKING = OlStyle.createForIcon(UrlImage.TRACK_SELECTED_PARKING);

  private currentTrackSource = new BehaviorSubject<Track>(null);
  private loadingVideoPointsSource = new BehaviorSubject<boolean>(false);
  private trackOptionsSource = new BehaviorSubject<TrackOptions>(null);

  constructor(private authUserService: AuthUserService,
              private dvrFileShortService: DvrFileShortService,
              private sensorRangeLayer: TrackLayerSensorRangesService,
              private olTrackBuildService: OlTrackBuildService) {
  }

  get track$(): Observable<Track> {
    return this.currentTrackSource.asObservable();
  }

  get loadingVideoPoints$(): Observable<boolean> {
    return this.loadingVideoPointsSource.asObservable();
  }

  get trackOptions$(): Observable<TrackOptions> {
    return this.trackOptionsSource.asObservable();
  }

  public changeTrackOptions(o: TrackOptions): void {
    this.trackOptionsSource.next(o);
  }

  public changeCurrentTrack(track: Track): void {
    this.currentTrackSource.next(track);
  }

  public instantTrack(): Track {
    return this.currentTrackSource.getValue();
  }

  public instantTrackOptions(): TrackOptions {
    return this.trackOptionsSource.getValue();
  }

  public show(map: OlMap, track: Track = this.instantTrack()): void {
    if (!track || (track.storage.sensorDataList.length === 0 && track.parkings?.length === 0)) {
      return;
    }

    LayerUtil.clear(map, LayerName.TRACK, LayerName.POINTS, LayerName.VIDEO, LayerName.SENSOR_RANGES_1, LayerName.SENSOR_RANGES_2);

    this.olTrackBuildService.buildTrackVectorLayer(track, LayerName.TRACK, this.instantTrackOptions(), this.timeZone())
      .forEach(layer => {
        if (layer) {
          map.addLayer(layer);
        }
      });
    this.showHighlightPoints(map);
    if (this.instantTrackOptions().maxSpeed !== 0) {
      this.highlightOverMaxSpeedTrip(
        map,
        [this.instantTrackOptions().maxSpeed, 5000],
        this.instantTrackOptions().minDurationOverSpeed,
      );
    }
    this.sensorRangeLayer.showSensorRanges(map, track, this.instantTrackOptions().sensorRanges || [])

    CenterMapUtil.center(track, map, {padding: CenterMapUtil.MAP_PADDING_TRACK_MAIN});
  }

  private showHighlightPoints(map: OlMap): void {
    if (this.instantTrackOptions().isShowPoints) {
      const layerPoint = this.olTrackBuildService.buildTrackPointVectorLayer(this.instantTrack(), LayerName.POINTS);
      map.addLayer(layerPoint);
    } else {
      LayerUtil.clear(map, LayerName.POINTS);
    }
  }

  public showVideoPoints(filters: DvrFilters, map: OlMap, track: Track = this.instantTrack()): void {
    if (!track || (track.storage.sensorDataList.length === 0 && track.parkings?.length === 0)) {
      return;
    }
    LayerUtil.clear(map, LayerName.VIDEO);
    if (Object.values(filters).some(filter => !!filter.value)) {
      this.loadingVideoPointsSource.next(true);
      this.dvrFileShortService.getList(this.instantTrack().buildOptions.unit.id, filters).subscribe(
        fileList => {
          const layerVideoPoints =
            this.olTrackBuildService.buildTrackVideoVectorLayer(
              this.instantTrack(),
              fileList,
              LayerName.VIDEO,
              this.timeZone()
            );
          map.addLayer(layerVideoPoints);
          this.loadingVideoPointsSource.next(false);
        },
        error => {
          this.loadingVideoPointsSource.next(false);
          throw error;
        }
      );
    }
  }

  public highlightTrip(index: number, map: OlMap): void {
    if (index !== -1) {
      const track = this.instantTrack();
      LayerUtil.clear(map, LayerName.SELECTED_TRIP, LayerName.SELECTED_SPEED_RANGE);
      const trackLayer = this.olTrackBuildService.buildTrackVectorLayerSpecifyTripWithColor(
        track, LayerName.SELECTED_TRIP, index, AppColor.RED, this.timeZone());
      map.addLayer(trackLayer);
      //padding top 350 is the trackChart height
      CenterMapUtil.center((track.getSensorDataListForTrip(track.trips[index]).sensorDataList), map, {
        padding: {
          top: 350,
          right: 50,
          bottom: 170,
          left: 100
        }
      });
    } else {
      LayerUtil.clear(map, LayerName.SELECTED_TRIP);
    }
  }

  public highlightTripBySelectedVideo(startTime: string, finishTime: string, map: OlMap): void {
    const trackLayer = this.olTrackBuildService.buildTripBySelectedVideo(
      this.instantTrack(), LayerName.SELECTED_VIDEO_TRIP, startTime, finishTime, AppColor.TRACK_GREEN
    );
    if (trackLayer) {
      map.addLayer(trackLayer);
    }
  }

  public highlightParking(index: number, map: OlMap): void {
    if (index !== -1) {
      const track = this.instantTrack();
      CenterMapUtil.center(track.parkings[index].getCoordinate(), map, {zoom: map.getView().getZoom()});
      this.showMarkerAtLayer(OlCoordinate.createFromGpsCoordinate(
          track.parkings[index].getCoordinate()),
        this.MARKER_STYLE_SELECTED_PARKING,
        LayerName.SELECTED_PARKING,
        map
      );
    } else {
      LayerUtil.clear(map, LayerName.SELECTED_PARKING);
    }
  }

  public highlightPosition(gpsCoordinate: GpsCoordinate, map: OlMap): void {
    if (gpsCoordinate.latitude === 0) {
      LayerUtil.clear(map, LayerName.SELECTED_POSITION);
      return;
    }
    CenterMapUtil.center(gpsCoordinate, map, {zoom: map.getView().getZoom()});

    this.showMarkerAtLayer(
      OlCoordinate.createFromGpsCoordinate(gpsCoordinate),
      this.MARKER_STYLE_SELECTED_POSITION,
      LayerName.SELECTED_POSITION,
      map
    );
  }

  // event from speed-range-color-chart
  private highlightOverMaxSpeedTrip(map: OlMap, speedRange: number[], duration: number = 0): void {
    LayerUtil.clear(map, LayerName.SELECTED_TRIP, LayerName.SELECTED_SPEED_RANGE);
    if (speedRange[0]) {
      map.addLayer(
        this.olTrackBuildService.buildTrackVectorLayerForSpecifiedSpeed(
          this.instantTrack(), LayerName.SELECTED_SPEED_RANGE, AppColor.RED, speedRange, duration, this.timeZone()));
    }
  }

  private showMarkerAtLayer(coordinate, markerStyle, layerName: LayerName, map: OlMap): void {
    LayerUtil.clear(map, layerName);
    const marker = OlFeature.createMarkerWithStyle(markerStyle, new Point(coordinate));
    const l: Layer = OlVectorLayer.createVectorLayerWithFeatures(marker, layerName);
    l.setZIndex(ZIndexLayer.TRACK_MARKER);
    map.addLayer(l);
  }

  private timeZone(): string {
    return this.authUserService.getInstantTimeZone();
  }

  public destroy(): void {
    this.changeCurrentTrack(null);
    this.changeTrackOptions(null);
  }
}
