import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Feature } from "ol";
import OlMap from 'ol/Map';
import { Icon, Style } from "ol/style";
import { DvrFileService } from '../../../../../common-module/src/lib/services/dvr-file.service';
import { OlStyle } from "../map-utils/OlStyle";
import { SelectedService } from './selected.service';
import { UnitGroupsService } from './unit-groups.service';
import { VideoService } from './video.service';

@Injectable({
  providedIn: 'root'
})

export class MapOnClickService {

  private selectedVideoPointFeature: Feature;

  constructor(private dvrFileService: DvrFileService,
              private selectedService: SelectedService,
              private translateService: TranslateService,
              private unitGroupsService: UnitGroupsService,
              private videoService: VideoService) {
  }

  public init(map: OlMap): void {
    map.on('click', (e) => {
      map.forEachFeatureAtPixel(e.pixel, (f) => {
        const features = f.getProperties().features;
        if (features?.length !== 1) {
          return;
        }
        const properties = features[0].getProperties();
        if (properties.hasOwnProperty('id') && features[0].getProperties().id > 0) {
          this.changeSelectedUnit(properties);
        }
        if (properties.hasOwnProperty('type') && properties.type === 'videoPoint') {
          this.cancelSelectedVideoPointFeature();
          if (this.selectedVideoPointFeature) {
            this.selectedVideoPointFeature.setStyle([OlStyle.createForVideoPoint(), this.getIconStyle(this.selectedVideoPointFeature)]);
          }
          this.selectedVideoPointFeature = features[0];
          features[0].setStyle([OlStyle.createForSelectedVideoPoint(), this.getIconStyle(features[0])]);
          this.handlingVideoPoint(properties);
        }
      });
    });
  }

  private changeSelectedUnit(properties: { [p: string]: any }): void {
    const unit = this.unitGroupsService.getUnitById(properties.id);
    this.selectedService.changeLastSelectedUnit(unit, true);
  }

  private getIconStyle(feature: Feature): Style {
    if ((feature.getStyle() as Style[])?.length > 0) {
      return (feature.getStyle() as Style[]).find(style => style.getImage() instanceof Icon);
    }
    return null;
  }

  private handlingVideoPoint(properties: { [p: string]: any }): void {
    this.dvrFileService.getFileByNameAndCameraId(properties?.cameraId, properties?.name, this.translateService.currentLang).subscribe(
      file => {
        this.videoService.changeSelectedCard(file);
        this.videoService.openPlayerInSeparateWindow();
      }
    );
  }

  public cancelSelectedVideoPointFeature(): void {
    if (this.selectedVideoPointFeature) {
      this.selectedVideoPointFeature.setStyle([OlStyle.createForVideoPoint(), this.getIconStyle(this.selectedVideoPointFeature)]);
    }
  }
}
