import OlMap from 'ol/Map';
import {LayerName} from '../constants/enums/layer-name';
import VectorLayer from "ol/layer/Vector";

export class LayerUtil {

  public static clear(map: OlMap, ...layerNames: LayerName[]): void {
    if (!map) {
      return;
    }
    for (let i = 0; i < layerNames.length; i++) {
      for (let j = 0; j < map.getLayers().getArray().length; j++) {
        if (map.getLayers().getArray()[j].get('name') === layerNames[i]) {
          map.removeLayer(map.getLayers().getArray()[j]);
          j--;
        }
      }
    }
    map.updateSize();
  }

  public static remove(map: OlMap, ...layers: VectorLayer[]): void {
    if (!map || !layers) {
      return;
    }
    layers.forEach(l => {
        if (l)
          map.removeLayer(l);
      }
    )
  }
}
