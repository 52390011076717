<header class="dialog-title">
  <span>{{ 'term.vehicle-card' | translate }}: </span>
  <span>{{ data.unitData.unit.name }}</span>
</header>

<mat-dialog-content>
  <app-vehicle-form [unitData]="data.unitData" [fields]="data.fields">
  </app-vehicle-form>
</mat-dialog-content>

<div class="dialog-box-buttons">
  <button mat-button class="button" (click)="onCancel()">
    {{ 'action.cancel' | translate }}
  </button>

  <button mat-button class="button major"
          (click)="onSubmit()"
          [disabled]="!(form$ | async) || !(form$ | async).dirty || !(form$ | async).valid"
  >
    <span>{{ 'action.save' | translate }}</span>
  </button>
</div>
