export enum SensorTypeName {
  DEFAULT = 'DEFAULT',
  ENGINE_RPM_METER = 'ENGINE_RPM_METER',
  COOLANT_TEMPERATURE = 'COOLANT_TEMPERATURE',
  IGNITION = 'IGNITION',
  GSM_STRENGTH = 'GSM_STRENGTH',
  DEVICE_BATTERY = 'DEVICE_BATTERY',
  ONBOARD_VOLTAGE = 'ONBOARD_VOLTAGE',
  FUEL_TANK_LEVEL = 'FUEL_TANK_LEVEL',
  FUEL_LEVEL = 'FUEL_LEVEL',
  FUEL_FLOW_METER_INSTANT = 'FUEL_FLOW_METER_INSTANT',
  FUEL_FLOW_METER_ABSOLUTE = 'FUEL_FLOW_METER_ABSOLUTE',
  TEMPERATURE_SENSOR = 'TEMPERATURE_SENSOR',
  DISCRETE = 'DISCRETE',
  ANALOG = 'ANALOG',
  AXLE_LOAD = 'AXLE_LOAD',
  TOTAL_LOAD = 'TOTAL_LOAD',
}
