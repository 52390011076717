import {Injectable} from "@angular/core";
import {
  AbstractMapFactory
} from "../../../../../../shared/aur-map-creator/map/wrapper/map-wrapper-factory/abstract.map-factory";
import {WarehouseMapConfig} from "../../../../../../shared/maps/map/warehouse-map/warehouses.map";
import {TileLayerService} from "../../../../../../shared/services/tile-layer.service";
import {OpenLayerMapService} from "../../../../../../shared/services/open-layer-map.service";
import {BaseMapConfig} from "../../../../../../shared/aur-map-creator/map/wrapper/base.map";
import {MileageClassifierScannedMap} from "./mileage-classifier-scanned.map";

@Injectable()
export class MileageClassifierScannedMapFactory extends AbstractMapFactory<BaseMapConfig, MileageClassifierScannedMap> {

  constructor(tileLayerService: TileLayerService,
              openLayerMapService: OpenLayerMapService) {
    super(tileLayerService, openLayerMapService)
  }
  public override create(config: WarehouseMapConfig) {
    return new MileageClassifierScannedMap(super.createOlMap(config), config);
  }
}
