import {BaseUnsubscribeComponent} from "../../base-unsubscribe.component";
import {MatDialogRef} from "@angular/material/dialog";
import {DialogResult, DialogResultData} from "./dialog-result.model";

export namespace DialogComponent {

  export class Base<DATA> extends BaseUnsubscribeComponent {
    readonly dialogRef: MatDialogRef<any>;
    readonly data: DATA

    constructor(dialogRef: MatDialogRef<any>,
                data: DATA) {
      super();
      this.dialogRef = dialogRef;
      this.data = data;
    }

    public closeOnSave(): void {
      const result: DialogResult = {isCancel: false}
      this.dialogRef.close(result);
    }

    public closeOnCancel(): void {
      const result: DialogResult = {isCancel: true}
      this.dialogRef.close(result);
    }

  }

  export class Outputable<INPUT, OUTPUT> extends Base<INPUT> {

    override closeOnSave(outputData?: OUTPUT): void {
      const result: DialogResultData<OUTPUT> = {isCancel: false, data: outputData}
      this.dialogRef.close(result);
    }

    closeOnSaveOutput(outputData: OUTPUT) {
      this.closeOnSave(outputData);
    }
  }

}
