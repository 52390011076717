export class DayTime {

  /**
   * {@link weekday} starts with 1;
   * {@link time} format 'HH:mm:ss';
   */
  constructor(
    public weekday: number,
    public time: string,
  ) {
  }

  public static valueOf(o: DayTime): DayTime {
    return o ? new DayTime(o.weekday, o.time) : null;
  }
}
