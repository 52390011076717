import {ChangeDetectionStrategy, Component, OnDestroy} from '@angular/core';
import {CheckDeviceService} from './shared/components/check-mobile-device/check-device.service';
import {ViewLanguageService} from '../../../common-module/src/lib/app-services/view-language.service';
import {AppCustomizationService} from "../../../common-module/src/lib/app-services/app-customization.service";
import {AuthUserService} from "../../../common-module/src/lib/app-services/auth-user.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppComponent implements OnDestroy {

  constructor(private checkDeviceService: CheckDeviceService,
              private authUserService: AuthUserService,
              private appCustomizationService: AppCustomizationService,
              private viewLanguageService: ViewLanguageService) {
  }

  ngOnInit(): void {
    this.checkDeviceService.openDialog();
    this.viewLanguageService.initCurrentLanguage();
  }

  ngOnDestroy() {
    this.appCustomizationService.destroy();
    this.authUserService.destroy();
  }
}
