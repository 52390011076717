<div class="wrapper" [ngClass]="{toolbar_options_le_l: (size$ | async).less('l')}">

  <button mat-button [matMenuTriggerFor]="reportMenu" class="report-selector app-button stroked"
          matTooltip="{{ 'term.report' | translate }}:
    {{ getNameTranslatedByReportName(getLastSelectedReportOptions().reportName) }}">
    {{ getNameTranslatedByReportName(getLastSelectedReportOptions().reportName) }}
    <mat-icon iconPositionEnd>expand_more</mat-icon>
  </button>
  <mat-menu #reportMenu>
    <button mat-menu-item
            class="report-name"
            *ngFor="let reportName of reportNames"
            (click)="onChangeReport(reportName)"
            [matTooltip]="reportName"
            [matTooltipShowDelay]="1000">
      {{ reportName }}
    </button>
  </mat-menu>


  <button
    mat-icon-button
    class="app-icon-button primary"
    *ngIf="(size$ | async).more('m')"
    [matMenuTriggerFor]="menu"
    (menuOpened)="menuOpened = true"
    (menuClosed)="menuOpened = false"
    matTooltip="{{ 'message.tooltip.report-customization' | translate }}">
    <mat-icon>settings</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <ng-container *ngIf="menuOpened">
      <ng-container *ngTemplateOutlet="appReportSelectedOptions"></ng-container>
    </ng-container>
  </mat-menu>

  <div *ngIf="(size$ | async).less('l')">
    <ng-container *ngTemplateOutlet="appReportSelectedOptions"></ng-container>
  </div>
</div>

<ng-template #appReportSelectedOptions>
  <app-report-selected-options
    *ngIf="reportOptions"
    [availableTypes]="availableTypes"
    [hasGeofences]="hasGeofences"
    [lastSelectedOptions]="getLastSelectedReportOptions()"
    (updatedOptions)="receiveChangedOptions($event)">
  </app-report-selected-options>
</ng-template>
