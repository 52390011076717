export class TripSettings {

  constructor(
    public maxTripMessageTimeout: number=300,
    public minParkingSeconds: number,
    public minTripDetectionSpeed: number=0,
    public minTripMeters: number=0,
    public minTripSeconds: number=0,
    public urbanThresholdSpeed: number=75) {
  }

  public static valueOf(i: TripSettings): TripSettings {
    return i ?
      new TripSettings(i.maxTripMessageTimeout, i.minParkingSeconds, i.minTripDetectionSpeed, i.minTripMeters, i.minTripSeconds, i.urbanThresholdSpeed) : null;
  }
}
