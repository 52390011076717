import { BuildOptions } from './build-options.model';
import { ChartSegment } from './chart-segment.model';

export class SensorChartExt {

  constructor(public buildOptions: BuildOptions,
              public keys: string[],
              public segments: ChartSegment[]) {
  }

  public static valueOf(i: SensorChartExt): SensorChartExt {
    return i ? new SensorChartExt(BuildOptions.valueOf(i.buildOptions), i.keys, ChartSegment.valuesOf(i.segments)) : null;
  }
}
