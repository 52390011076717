import {SelectionModel} from '@angular/cdk/collections';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatListOption, MatSelectionList} from '@angular/material/list';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {Tab} from '../../../../../../../../common-module/src/lib/app-enums/tab';
import {ReportType} from '../../../../../../../../common-module/src/lib/modelinterfaces/enums/report-type';
import {GeofenceListService} from '../../../../../shared/services/geofence-list.service';
import {ReportBuildOptions} from '../report-options.component';
import {LocalStorageKey} from "../../../../../../../../common-module/src/lib/app-enums/local-storage-key";
import {AppMessageService} from "../../../../../../../../common-module/src/lib/app-services/app-message.service";
import {
  ReportSettingsConstructorDialogOpener
} from "./report-settings-constructor-dialog/report-settings-constructor-dialog.opener";

@Component({
  selector: 'app-report-selected-options',
  templateUrl: './report-selected-options.component.html',
  styleUrls: ['./report-selected-options.component.scss']
})

export class ReportSelectedOptionsComponent implements OnChanges, OnInit {

  @Input() availableTypes: string[] = [];
  @Input() hasGeofences = false;
  @Input() lastSelectedOptions: ReportBuildOptions;

  @Output() updatedOptions = new EventEmitter<ReportBuildOptions>();

  public isSelectedGeofences = false;
  public reportType = ReportType;

  @ViewChild(MatSelectionList, {static: true}) private selectionList: MatSelectionList;

  constructor(private geofenceListService: GeofenceListService,
              private appMessageService: AppMessageService,
              private reportSettingsConstructorDialogOpener: ReportSettingsConstructorDialogOpener) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['availableTypes'] && this.lastSelectedOptions) {
      if (!this.isAvailable(this.lastSelectedOptions.type)) {
        this.lastSelectedOptions.type = null;
        this.updatedOptions.emit(this.lastSelectedOptions);
      }
    }
  }

  ngOnInit(): void {
    this.selectionList.selectedOptions = new SelectionModel<MatListOption>(false);
    this.isSelectedGeofences = this.geofenceListService.getSelectedList(Tab.REPORT)?.length > 0;
  }

  public isAvailable(currentType: ReportType): boolean {
    return this.availableTypes.some(type => type === currentType);
  }

  public isSelected(currentType: ReportType): boolean {
    return this.lastSelectedOptions.type === currentType && this.isAvailable(currentType);
  }

  public onChangeType(selected: MatListOption[]): void {
    this.lastSelectedOptions.type = (selected.length > 0) ? selected[0].value : null;
    this.updatedOptions.emit(this.lastSelectedOptions);
  }

  public onToggleUniteTables($event: MatSlideToggleChange): void {
    this.lastSelectedOptions.uniteTables = $event.checked;
    this.updatedOptions.emit(this.lastSelectedOptions);
  }

  public onToggleSelectGeofences($event: MatSlideToggleChange): void {
    this.isSelectedGeofences = $event.checked;
  }

  openReportConstructorDialog() {
    const options: ReportBuildOptions = JSON.parse(window.localStorage.getItem(LocalStorageKey.REPORT_OPTIONS));
    if (!options) {
      this.appMessageService.openSnackBar("Отчет не выбран", true)
    } else {
      this.reportSettingsConstructorDialogOpener.open({data: options.reportName})
    }
  }
}
