<div [style.padding.px]="8">
  <app-driver-form
    *ngIf="(currentDriverState$ | async) === driverState.ADD || (currentDriverState$ | async) === driverState.EDIT">
  </app-driver-form>

  <app-select-avatar *ngIf="(currentDriverState$ | async) === driverState.AVATAR"></app-select-avatar>

  <app-driver-list
    *ngIf="(currentDriverState$ | async) === driverState.ASSIGN || (currentDriverState$ | async) === driverState.LIST">
  </app-driver-list>

  <app-driver-history *ngIf="(currentDriverState$ | async) === driverState.HISTORY"></app-driver-history>
</div>

