import {Logistic} from "../../../../../../../common-module/src/lib/modelinterfaces/logistic.model";
import {AppColor} from "../../../../../../../common-module/src/lib/app-enums/app-color";
import SolutionStage = Logistic.Stage;
import StageGroup = Logistic.StageGroup;

export class LogisticStageActionUtil {

  private static iconMap = new Map([
    [Logistic.StageAction.START, 'flight_takeoff'],
    [Logistic.StageAction.END, 'flight_land'],
    [Logistic.StageAction.PICKUP, 'file_download'],
    [Logistic.StageAction.DELIVERY, 'file_upload'],
    [Logistic.StageAction.JOB, 'engineering'],
  ]);

  public static icon(stage: SolutionStage) {
    return this.iconMap.get(stage.action) || 'engineering';
  }

  public static iconStageGroup(stageGroup: Logistic.StageGroup) {
    if (stageGroup.isWarehouseStageGroup()) {
      return 'warehouse'
    }
    if (stageGroup.isLocationStageGroup()) {
      return 'add_location_alt'
    }
    const stage = stageGroup.toSingeStageGroup().get();
    return this.icon(stage);
  }

  private static tooltipMap = new Map([
    [Logistic.StageAction.START, 'Начало маршрута'],
    [Logistic.StageAction.END, 'Конец маршрута'],
    [Logistic.StageAction.PICKUP, 'Загрузка'],
    [Logistic.StageAction.DELIVERY, 'Выгрузка'],
    [Logistic.StageAction.JOB, 'Выполнение работы'],
  ]);

  public static tooltip(stage: SolutionStage) {
    return this.tooltipMap.get(stage.action) || 'Неизвестное действие';
  }

  public static tooltipStageGroup(stageGroup: StageGroup): string {
    if (stageGroup.isWarehouseStageGroup()) {
      return 'Склад'
    }
    if (stageGroup.isLocationStageGroup()) {
      return 'Группа адресов'
    }
    const stage = stageGroup.toSingeStageGroup().get();
    return this.tooltip(stage);
  }

  private static statusColorMap = new Map<Logistic.Status, AppColor>([
    [Logistic.Status.NOT_ASSIGNED, AppColor.PRIMARY_LIGHT],
    [Logistic.Status.ASSIGNED, AppColor.PRIMARY_LIGHT],
    [Logistic.Status.CURRENT, AppColor.PRIMARY],
    [Logistic.Status.CANCELLED, AppColor.RED],
    [Logistic.Status.COMPLETED, AppColor.GREEN],
    [Logistic.Status.COMPLETED_LATE, AppColor.ORANGE],
  ]);

  public static wrapperColor(stage: SolutionStage): AppColor {
    return this.statusColorMap.get(stage.step.status) || AppColor.GRAY;
  }

  public static wrapperColorStageGroup(stageGroup: StageGroup) {
    return this.statusColorMap.get(stageGroup.getStatus()) || AppColor.GRAY
  }
}
