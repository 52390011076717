<cmn-common-filter [name]="name" [tooltip]="tooltip" [viewValue]="viewValue" [disableApply]="false"
                   (apply)="onApply()" (clear)="onClear()">
  <app-search *ngIf="enableSearch" (searchChange)="searchIn = $event"></app-search>

  <div class="box-select-all" (click)="onToggleAllChange($event)">
    <span>{{ 'action.select-all' | translate }}</span>
    <mat-checkbox [checked]="selection.selected.length === itemList.length"
                  [indeterminate]="selection.selected.length > 0 && selection.selected.length < itemList.length">
    </mat-checkbox>
  </div>

  <div class="checkbox-item-list">
    <mat-selection-list class="selection-list" [multiple]="isMultipleSelection">
      <mat-list-option *ngFor="let item of itemList"
                       [selected]="selection.isSelected(item)"
                       (click)="selection.toggle(item); $event.stopPropagation()"
                       [ngClass]="{'hide-option': !item.name.toLowerCase().includes(searchIn)}">

        <ng-container *ngIf="itemTemplate; else defaultItemTemplate">
          <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
        </ng-container>

        <!-- Default template if itemTemplate is not provided -->
        <ng-template #defaultItemTemplate>
          {{ item.name }}
        </ng-template>

      </mat-list-option>
    </mat-selection-list>
  </div>
</cmn-common-filter>
