export class ParameterizedMessage {
  constructor(public code: string,
              public params: object) {
  }

  public static valueOf(m: ParameterizedMessage): ParameterizedMessage {
    return new ParameterizedMessage(m.code, m.params);
  }

  public static valuesOf(messages: ParameterizedMessage[]): ParameterizedMessage[] {
    return messages.map(m => this.valueOf(m));
  }

}
