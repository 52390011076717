import { PageModel } from './page-model.model';
import { Page } from './page.model';
import { UnitShort } from './unit-short.model';

export class ReportMeta {

  constructor(public reportIds: number[],
              public name: string,
              public startTime: string,
              public finishTime: string,
              public created: string,
              public units: UnitShort[]) {
  }

  public static valueOf(o: ReportMeta): ReportMeta {
    return o ? new ReportMeta(o.reportIds, o.name, o.startTime, o.finishTime, o.created, o.units) : null;
  }

  public static valuesOf(list: ReportMeta[]): ReportMeta[] {
    return list ? list.map(o => ReportMeta.valueOf(o)) : [];
  }

  public static pageableValueOf(o: Page<ReportMeta>): Page<ReportMeta> {
    return o ? new PageModel<ReportMeta>(o.empty, o.first, o.last, o.number, o.numberOfElements, o.totalElements, o.totalPages,
      o.urlParams, ReportMeta.valuesOf(o.content)) : null;
  }
}
