import {Address} from './address.model';
import {SensorName} from './enums/sensor-name';
import {SensorTypeName} from './enums/sensor-type-name';
import {GpsOdometer} from './gps-odometer.model';
import {LastMotionState} from './last-motion-state.model';
import {SensorLastValue} from './sensor-last-value.model';
import {UnitState} from './unit-state.model';
import {Unit} from './unit.model';
import {Vehicle} from './vehicle.model';

export class UnitAdditionalData {

  constructor(public unit: Unit,
              public address: Address,
              public unitState: UnitState,
              public gpsOdometer: GpsOdometer,
              public vehicle: Vehicle,
              public lastMotionState: LastMotionState,
              public sensorLastValue: SensorLastValue[]) {
  }

  public static valueOf(i: UnitAdditionalData): UnitAdditionalData {
    return i ? new UnitAdditionalData(Unit.valueOf(i.unit), Address.valueOf(i.address), UnitState.valueOf(i.unitState),
      GpsOdometer.valueOf(i.gpsOdometer), Vehicle.valueOf(i.vehicle), LastMotionState.valueOf(i.lastMotionState),
      SensorLastValue.valuesOf(i.sensorLastValue)) : null;
  }

  public getVisibleSensors(): SensorLastValue[] {
    return this.sensorLastValue.filter(s => s.visible);
  }

  /**
   * @return first found sensor by @param type
   */
  public getSensorLastValueByType(type: SensorTypeName): SensorLastValue {
    const list = this.sensorLastValue.filter(s => s.type === type);
    if (list.length === 0) {
      return null;
    }
    return list[0];
  }

  /**
   * @return first found sensor by @param name
   */
  public getSensorLastValueByName(name: SensorName): SensorLastValue {
    const list = this.sensorLastValue.filter(s => s.name === name);
    if (list.length === 0) {
      return null;
    }
    return list[0];
  }

  public getAddress(): string {
    return this?.address?.name ? this.address.name : '';
  }

  public getAltitude(): number {
    return this?.unitState?.gpsState?.altitude;
  }

  public getStatus(): string {
    return this?.unitState?.connectionState?.status;
  }

  public getSecondsAgo(): number {
    return this?.unitState?.connectionState?.secondsAgo;
  }

  public getTime(): string {
    return this?.unitState?.connectionState?.time;
  }

  public getSensorsIdsByNames(sensorNames: string[]): number[] {
    const sensorNamesSet = new Set(sensorNames);
    return this.sensorLastValue.filter(s => sensorNamesSet.has(s.name)).map(s => s.id);
  }
}
