import {StepFeatureWrapper} from "../step-feature/step-feature.wrapper";
import {Logistic} from "../../../../../../../../../common-module/src/lib/modelinterfaces/logistic.model";
import {ShipmentArrowFeatureWrapper} from "./job-arrow-feature/shipment-arrow-feature.wrapper";
import {AbstractJobCompositeFeatureWrapper} from "./abstract-job-composite-feature.wrapper";
import Job = Logistic.Job;


export class JobFeatureWrapper extends AbstractJobCompositeFeatureWrapper {
  constructor(private _delivery: StepFeatureWrapper, job: Job) {
    super(job, [_delivery]);
  }

  public getDelivery(): StepFeatureWrapper {
    return this._delivery;
  }
}

export class ShipmentFeatureWrapper extends JobFeatureWrapper {
  constructor(private _pickup: StepFeatureWrapper,
              _delivery: StepFeatureWrapper,
              private _arrow: ShipmentArrowFeatureWrapper,
              job: Job) {
    super(_delivery, job);
    super.setWrappers([_pickup, _delivery, _arrow]);
  }
}
