import { TranslateService } from '@ngx-translate/core';
import { Address } from './address.model';
import { GpsCoordinate } from './gps-coordinate.model';
import { DurationConverter } from '../utils/duration-converter';

export class Parking {

  constructor(public startDatetime: string, // UTC
              public finishDatetime: string, // UTC
              public duration: number, // seconds
              public address: Address) {
  }

  public static valueOf(i: Parking): Parking {
    return i ? new Parking(i.startDatetime, i.finishDatetime, i.duration, Address.valueOf(i.address)) : null;
  }

  public static valuesOf(list: Parking[]): Parking[] {
    return list ? list.map(o => Parking.valueOf(o)) : [];
  }

  // return duration in string
  public getDuration(translateService: TranslateService): string {
    return DurationConverter.secondsToStringDuration(this.duration, translateService);
  }

  public getCoordinate(): GpsCoordinate {
    return new GpsCoordinate(this.address.latitude, this.address.longitude);
  }
}
