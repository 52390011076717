<div class="wrapper">
  <header class="header">
    <ng-container>
      <span class="title">{{ 'term.name' | translate }}:&nbsp;</span>
      <span>{{ (selectedCard$ | async)?.name }}</span>
    </ng-container>

    <ng-content></ng-content>

    <button mat-icon-button (click)="closeShowContent()">
      <mat-icon>close</mat-icon>
    </button>
  </header>

  <div class="player loading-box"
       *ngIf="(selectedCard$ | async)?.isNotUploadedToFtp ||
       (selectedCard$ | async)?.ftpState.status === dvrFileStatus.UPLOADING"
  >
    <div class="loading-button" (click)="requestFile()" *ngIf="(selectedCard$ | async)?.isNotUploadedToFtp">
      <span>{{ 'action.download-from-device' | translate }}</span>
      <mat-icon class="load-icon">send</mat-icon>
    </div>

    <div class="loading-button without-pointer" *ngIf="(selectedCard$ | async)?.isNotUploadedToFtp">
      <span>{{ 'video.downloading' | translate }}</span>
      <mat-spinner class="load-icon" diameter="20"></mat-spinner>
    </div>
  </div>

  <vg-player class="player"
             *ngIf="(selectedCard$ | async)?.fileType === dvrFileType.VIDEO && (selectedCard$ | async)?.isUploaded"
             (onPlayerReady)="onPlayerReady($event)">
    <vg-overlay-play></vg-overlay-play>

    <vg-buffering></vg-buffering>

    <vg-scrub-bar class="progress-bar">
      <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
      <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
    </vg-scrub-bar>

    <vg-controls>
      <vg-play-pause></vg-play-pause>

      <vg-playback-button [playbackValues]="[ '0.5', '1.0', '1.5', '2.0', '4.0' ]"></vg-playback-button>

      <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

      <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>

      <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>

      <vg-track-selector></vg-track-selector>

      <vg-mute></vg-mute>

      <vg-volume></vg-volume>

      <vg-fullscreen></vg-fullscreen>
    </vg-controls>

    <video #media
           [vgMedia]="asMedia(media)"
           preload="auto"
           [src]="showUrl$ | async"
           autoplay>
      <source src="" type="video/mp4">
    </video>
  </vg-player>

  <div class="box-image" *ngIf="(selectedCard$ | async)?.fileType === dvrFileType.IMAGE" #boxImage>
    <img class="img-camera" [src]="downloadUrl$ | async" alt="{{ 'video.image' | translate }}">

    <button mat-icon-button class="resize-icon" (click)="toggleFullScreen(boxImage)">
      <mat-icon *ngIf="!isFullScreen">fullscreen</mat-icon>
      <mat-icon *ngIf="isFullScreen">fullscreen_exit</mat-icon>
    </button>
  </div>

  <div class="box-info-grid">
    <div class="item">
      <span class="title">{{ 'video.camera' | translate }}:&nbsp;</span>
      <span>{{ (selectedCard$ | async)?.camera.name | capitalize }}</span>
    </div>
    <div class="item">
      <span class="title">{{ 'video.record' | translate }}:&nbsp;</span>
    </div>
    <div class="item">
      <span class="title">{{ 'term.time' | translate }}:&nbsp;</span>
      <span>
      {{ (selectedCard$ | async)?.startTime ? ((selectedCard$ | async)?.startTime | utcDateTz : (timeZone$ | async)) : "" }}
    </span>
    </div>
    <div class="item size" *ngIf="(selectedCard$ | async)?.ftpState.status === dvrFileStatus.UPLOADED">
      <span>{{ (selectedCard$ | async)?.ftpState.size | fileSize }}</span>
      <a class="download-button" [href]="downloadUrl$ | async">
        <mat-icon>get_app</mat-icon>
      </a>
      <button mat-icon-button
              *ngIf="!isSeparateWindow"
              class="track-button"
              matTooltip="{{ 'action.show-track-on-map' | translate }}"
              (click)="onOpenTrack()">
        <mat-icon>map</mat-icon>
      </button>
    </div>
  </div>
</div>
