import {Component, EventEmitter, Output} from '@angular/core';
import {ActionAbstractButtonComponent} from "../../../common/concrete-button/action-abstract-button.component";

@Component({
  selector: 'app-icon-button-revert',
  templateUrl: './icon-button-revert.component.html',
})
export class IconButtonRevertComponent extends ActionAbstractButtonComponent {
  @Output() revert = new EventEmitter<MouseEvent>();

  onAction(event: MouseEvent): void {
    this.revert.emit(event);
  }
}
