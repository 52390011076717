export class TranslatedString {

  constructor(public value: string,
              public translated: string) {
  }

  public static valueOf(i: TranslatedString): TranslatedString {
    return i ? new TranslatedString(i.value, i.translated) : null;
  }

  public static valuesOf(list: TranslatedString[]): TranslatedString[] {
    return list ? list.map(o => TranslatedString.valueOf(o)) : [];
  }
}
