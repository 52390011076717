import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Feature} from 'ol';
import OlMap from 'ol/Map';

import {UnitStateStorage} from '../../../../../common-module/src/lib/modelinterfaces/unit-state-storage.model';
import {LimitedList} from '../utils/limited-list';
import {LayerName} from '../constants/enums/layer-name';
import {OlCoordinate} from '../map-utils/OlCoordinate';
import {OlVectorLayer} from '../map-utils/OlVectorLayer';
import {ZIndexLayer} from '../constants/z-index';
import {SelectedService} from './selected.service';
import {UnitState} from '../../../../../common-module/src/lib/modelinterfaces/unit-state.model';
import {OlFeature} from '../map-utils/OlFeature';
import {LocalStorageKey} from '../../../../../common-module/src/lib/app-enums/local-storage-key';
import {ShowTrace} from '../constants/enums/show-trace';
import {LayerUtil} from '../map-utils/layer.util';

@Injectable({
  providedIn: 'root'
})

export class UnitTraceService {

  public showTraceSource = new BehaviorSubject<ShowTrace>(null);
  public showTrace$: Observable<ShowTrace> = this.showTraceSource.asObservable();

  private unitsLastStates: LimitedList<Map<number, UnitState>> = new LimitedList();

  constructor(private selectedService: SelectedService) {
  }

  public init(): void {
    if (this.showTraceSource.getValue() !== null) {
      return;
    }
    localStorage.getItem(LocalStorageKey.SHOW_TRACE) ?
      this.showTraceSource.next(localStorage.getItem(LocalStorageKey.SHOW_TRACE) as ShowTrace) : this.showTraceSource.next(ShowTrace.NO);
  }

  public changeTrace(map: OlMap, state: ShowTrace): void {
    localStorage.setItem(LocalStorageKey.SHOW_TRACE, state);
    this.showTraceSource.next(state);
    this.showTraceOnMap(map);
  }

  public addData(map: OlMap, storage: UnitStateStorage): void {
    this.unitsLastStates.append(storage.data);
    if (this.showTraceSource.getValue()) {
      this.showTraceOnMap(map);
    }
  }

  public showTraceOnMap(map: OlMap): void {
    const state = this.showTraceSource.getValue();
    LayerUtil.clear(map, LayerName.TRACE);
    if (state === ShowTrace.NO) {
      return;
    }

    const unitsLastStateList = this.unitsLastStates.toArray();
    const traceFeatureList: Feature[] = [];
    if (state === ShowTrace.SELECTED_UNITS) {
      const unitIdsSet = this.selectedService.getInstantUnitIdsSet();
      unitIdsSet.forEach(value => traceFeatureList.push(this.createFeatureByUnitId(unitsLastStateList, value)));
    }

    if (state === ShowTrace.ALL) {
      Array.from(unitsLastStateList[0].keys()).forEach(key => traceFeatureList.push(this.createFeatureByUnitId(unitsLastStateList, key)));
    }

    const traceLayer = OlVectorLayer.createVectorLayerWithFeatures(traceFeatureList.filter(el => !!el), LayerName.TRACE);
    traceLayer.setZIndex(ZIndexLayer.TRACK_SELECTED_TRIP);
    map.addLayer(traceLayer);
  }

  private createFeatureByUnitId(unitsLastStateList: Map<number, UnitState>[], unitId: number): Feature {
    const unitLastData = unitsLastStateList.map(el => el?.get(unitId));
    const coords = [];
    unitLastData.forEach(el => {
      if (el?.gpsState?.coordinate) {
        coords.push(OlCoordinate.createFromGpsCoordinate(el.gpsState.coordinate));
      }
    });
    return OlFeature.createTrace(coords);
  }
}
