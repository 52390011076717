import {UrlImage} from '../constants/url-image';
import { AppColor } from '../../../../../common-module/src/lib/app-enums/app-color';

export class TrackColor {

  public static getColorTrackArrow(color: AppColor): UrlImage {
    return TrackColor.initColorMap().get(color);
  }

  private static initColorMap(): Map<AppColor, UrlImage> {
    const result: Map<AppColor, UrlImage> = new Map();
    result.set(AppColor.PRIMARY, UrlImage.TRACK_ARROW_BLUE);
    result.set(AppColor.RED, UrlImage.TRACK_ARROW_RED);
    result.set(AppColor.ORANGE, UrlImage.TRACK_ARROW_ORANGE);
    result.set(AppColor.ACCENT, UrlImage.TRACK_ARROW_ACCENT);
    result.set(AppColor.MAUVE, UrlImage.TRACK_ARROW_MAUVE);
    result.set(AppColor.BLACK, UrlImage.TRACK_ARROW_BLACK);
    result.set(AppColor.GRAY, UrlImage.TRACK_ARROW_GRAY);
    result.set(AppColor.PURPLE, UrlImage.TRACK_ARROW_PURPLE);
    result.set(AppColor.TRACK_GREEN, UrlImage.TRACK_ARROW_GREEN);
    result.set(AppColor.BROWN, UrlImage.TRACK_ARROW_BROWN);
    return result;
  }
}
