import { Injectable } from '@angular/core';
import { UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { BehaviorSubject, Observable } from "rxjs";
import { GlobalStyleClass } from "../../../../../common-module/src/lib/app-enums/global-style-class.enum";
import { ConfirmDialogComponent } from "../../system/main/vehicle/confirm-dialog/confirm-dialog.component";

@Injectable({
  providedIn: 'root'
})

export class VehicleManagerService {

  private _currentForms = new BehaviorSubject<UntypedFormGroup[]>([]);
  private _isCancelForms = new BehaviorSubject<boolean>(false);
  private _isSubmitForms = new BehaviorSubject<boolean>(false);
  private _tabSelectedIndex = new BehaviorSubject<number>(0);

  constructor(private dialog: MatDialog) {
  }

  get currentForms$(): Observable<UntypedFormGroup[]> {
    return this._currentForms.asObservable();
  }

  get isCancelForms$(): Observable<boolean> {
    return this._isCancelForms.asObservable();
  }

  get isSubmitForms$(): Observable<boolean> {
    return this._isSubmitForms.asObservable();
  }

  get tabSelectedIndex$(): Observable<number> {
    return this._tabSelectedIndex.asObservable();
  }

  public changeForms(forms: UntypedFormGroup[]): void {
    this._isCancelForms.next(false);
    this._isSubmitForms.next(false);
    this._currentForms.next(forms);
  }

  public changeTabIndex(index: number): void {
    if (this.instantTabSelectedIndex() === index) {
      return;
    }
    if (!this._currentForms.getValue().some(form => form?.dirty)) {
      this._tabSelectedIndex.next(index);
      return;
    }
    this.openConfirmDialog(index);
  }

  private openConfirmDialog(index: number): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: [GlobalStyleClass.DIALOG_SIZE_LIMIT],
      autoFocus: false,
      data: {
        isValid: this._currentForms.getValue().every(form => !form || form.valid)
      }
    });
    dialogRef.afterClosed().subscribe((r) => {
      if (!r) {
        return;
      }
      if (!r.isSave) {
        this._isCancelForms.next(true);
        this._tabSelectedIndex.next(index);
        return;
      }
      if (r.isSave) {
        this._isSubmitForms.next(true);
        this._tabSelectedIndex.next(index);
        return;
      }
    });
  }

  public onCancelForms(index: number = null): void {
    if (index !== null) {
      this._tabSelectedIndex.next(index);
    }
    this._isCancelForms.next(true);
  }

  public onSubmitForms(index: number = null): void {
    if (index !== null) {
      this._tabSelectedIndex.next(index);
    }
    this._isSubmitForms.next(true);
  }

  public instantTabSelectedIndex(): number {
    return this._tabSelectedIndex.getValue();
  }
}
