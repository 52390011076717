import { Directive } from '@angular/core';

@Directive({
  selector: '[appDialogContent]',
})
export class DialogContentDirective {

  constructor() { }

}
