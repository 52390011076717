import { ConfigType } from './enums/config-type';
import { RequiredType } from './enums/required-type';
import { UnitOfMeasure } from './unit-of-measure.model';

export class NotificationSourceBase {

  constructor(public name: string,
              public nameTranslated: string,
              public requiredType: RequiredType,
              public configType: ConfigType,
              public unitOfMeasure: UnitOfMeasure) {
  }

  public static valueOf(i: NotificationSourceBase): NotificationSourceBase {
    return i ? new NotificationSourceBase(i.name, i.nameTranslated, i.requiredType, i.configType, i.unitOfMeasure)
      : null;
  }

  public static valuesOf(list: NotificationSourceBase[]): NotificationSourceBase[] {
    return list ? list.map(o => NotificationSourceBase.valueOf(o)) : [];
  }
}
