@if (shouldCloseDialog) {
  <app-button-minor (action)="onAction($event)"
                    [disabled]="disabled"
                    [showSpinner]="showSpinner"
                    [text]="'action.cancel' | translate"
                    matDialogClose>
  </app-button-minor>
}
@else {
  <app-button-minor (action)="onAction($event)"
                    [disabled]="disabled"
                    [showSpinner]="showSpinner"
                    [text]="'action.cancel' | translate">
  </app-button-minor>
}


