import {GpsCoordinate} from "./gps-coordinate.model";

export class Address extends GpsCoordinate {

  constructor(latitude: number,
              longitude: number,
              public name: string) {
    super(latitude, longitude)
  }

  public getGpsCoordinate() {
    return new GpsCoordinate(this.latitude, this.longitude);
  }

  public static valueOf(i: Address): Address {
    return i ? new Address(i.latitude, i.longitude, i.name) : null;
  }

  public equals(other: Address): boolean {
    return super.equals(other) && this.name === other.name;
  }
}
