import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MapInteractionsService} from "../../../../shared/services/map-interactions.service";
import {ToolbarService} from "../../../../shared/services/toolbar.service";

import {VideoService} from '../../../../shared/services/video.service';
import {DvrFilters} from '../../../../../../../common-module/src/lib/app-models/dvr-filters.models';

@Component({
  selector: 'app-filter-video-dialog',
  templateUrl: './filter-video-dialog.component.html',
  styleUrls: ['./filter-video-dialog.component.scss', '../../../../../../../common-module/src/lib/app-styles/dialog-common.scss']
})

export class FilterVideoDialogComponent {

  public isResetAll = false;

  private filters: DvrFilters;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { isTrackOptions: boolean },
              private dialogRef: MatDialogRef<FilterVideoDialogComponent>,
              private mapInteractionsService: MapInteractionsService,
              private toolbarService: ToolbarService,
              private videoService: VideoService) {
  }

  public onReceiveFilter(event: DvrFilters): void {
    setTimeout(() => this.isResetAll = false, 100);
    this.filters = event;
  }

  public onResetAll(): void {
    setTimeout(() => this.isResetAll = true, 100);
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onSubmit(): void {
    this.dialogRef.close();
    if (this.data?.isTrackOptions) {
      this.toolbarService.changeTrackVideoPointsFilterParams(this.filters);
      this.mapInteractionsService.changeVideoPoints(this.filters);
      return;
    }
    this.videoService.changeFilters(this.filters);
  }
}
