export class ReportSettings {
  constructor(public id: number,
              public name: string,
              public nameTranslated: string,
              public columns: ColumnSettings[]) {
  }

  public static valueOf(i: ReportSettings): ReportSettings {
    return new ReportSettings(i.id, i.name, i.nameTranslated, ColumnSettings.valuesOf(i.columns));
  }

  public static valuesOf(list: ReportSettings[]): ReportSettings[] {
    return list.map(item => ReportSettings.valueOf(item));
  }

  public getDisplayedColumns(): string[] {
    return this.columns.filter(col => col.visible).map(col => col.name);
  }
}

export class ColumnSettings {

  constructor(public id: number,
              public name: string,
              public nameTranslated: string,
              public visible: boolean) {
  }

  public static valueOf(item: ColumnSettings): ColumnSettings {
    return new ColumnSettings(item.id, item.name, item.nameTranslated, item.visible);
  }

  public static valuesOf(list: ColumnSettings[]): ColumnSettings[] {
    return list.map(item => ColumnSettings.valueOf(item));
  }
}
