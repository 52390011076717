<cmn-common-filter [name]="name"
                   [tooltip]="tooltip"
                   [viewValue]="viewValue"
                   [disableApply]="form?.invalid"
                   (apply)="onApply()"
                   (open)="onOpen()"
                   (clear)="onClear()">

  <form *ngIf="form" [formGroup]="form" class="filter-menu" (click)="$event.stopPropagation()">
    <div class="box-inputs">
      <mat-form-field [floatLabel]="'always'">
        <input matInput
               #startInput
               type="number"
               [min]="minValue"
               [max]="maxValue"
               [step]="step"
               (keydown.enter)="onApply()"
               [formControlName]="controls.VALUE"/>
        <span matTextSuffix class="extra-text">{{ uom }}</span>
      </mat-form-field>
    </div>
  </form>

</cmn-common-filter>
