<form [formGroup]="form">
  <mat-form-field appearance="outline">
    <mat-label>{{name ? name : 'Длительность'}}</mat-label>
    <div class="time-inputs">
      <input matInput type="number" min="0" placeholder="ЧЧ"
             [formControlName]="control.HOURS"
             (click)="stopClickPropagation($event)"
             (blur)="setDefaultIfEmpty(control.HOURS)">
      <span matSuffix>ч</span>
      <span>:</span>
      <input matInput type="number" min="0" placeholder="ММ"
             [formControlName]="control.MINUTES"
             (click)="stopClickPropagation($event)"
             (blur)="setDefaultIfEmpty(control.MINUTES)">
      <span matSuffix>мин</span>
    </div>
  </mat-form-field>
</form>
