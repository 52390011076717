import {Logistic} from "../../../../../../../../common-module/src/lib/modelinterfaces/logistic.model";
import {LogisticTimeWindowUtil} from "../../../../../system/logistic/common/utils/logistic-time-window.util";
import {LogisticAmountUtil} from "../../../../../system/logistic/common/utils/logistic-amount.util";
import {LogisticSkillUtil} from "../../../../../system/logistic/common/utils/logistic-skill.util";

export class JobManagerMapPopupFactory {

  public static create(step: Logistic.Step, job: Logistic.Job) {

    return `
      <p><b>${job.order.name}</b> </p>
      <p> ${step.getLocationName()} </p>
      <p> ${job.order.description} </p>
      <p>${LogisticAmountUtil.toString(job.order.amount, '')}</p>
      <p> ${step.getContact()?.name ?? ''}</p>
      <p> ${LogisticTimeWindowUtil.formatTimeWindow(step.getTimeWindows()[0], true)}</p>
      <p> <b>Требования:</b> ${LogisticSkillUtil.toString(job.skills)}</p>
      `;
  }

}
