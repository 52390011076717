import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators} from "@angular/forms";
import {
  AbstractControlValueAccessor
} from "../../../../system/logistic/common/components/inputs/abstract-input/abstract-control-value.accessor";
import {MatFormFieldAppearance} from "@angular/material/form-field";

enum Control {
  PHONE_NUMBER = 'phone-number'
}

@Component({
  selector: 'app-any-phone-number-input',
  templateUrl: './any-phone-number-input.component.html',
  styleUrls: ['./any-phone-number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AnyPhoneNumberInputComponent),
      multi: true
    }
  ]
})
export class AnyPhoneNumberInputComponent extends AbstractControlValueAccessor<string> {
  controls = Control;
  @Input() label: string;
  @Input() appearance: MatFormFieldAppearance = 'fill';

  constructor() {
    super(
      new UntypedFormGroup({
        [Control.PHONE_NUMBER]: new UntypedFormControl(null)
      })
    );
  }

  /**
   * @return {@code null} если телефон некорректен {@code ''} если input пустой или возвращает введенный номер телефона
   */
  protected convertFormToValue(): string {
    if (this.form.value[Control.PHONE_NUMBER]) {
      const value = (<string>this.form.value[Control.PHONE_NUMBER]).trim();
      return value.length !== 0 ? value : null;
    } else {
      return null;
    }
  }

  protected convertValueToForm(value: string): { [p: string]: any } {
    return {[Control.PHONE_NUMBER]: value};
  }
}
