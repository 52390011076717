<cmn-common-filter [name]="name"
                   [tooltip]="tooltip"
                   [viewValue]="viewValue"
                   [disableApply]="form?.invalid"
                   (clear)="clearFilter()"
                   (open)="onOpen()"
                   (apply)="onApply()">

  <form *ngIf="form" [formGroup]="form" class="filter-menu" (click)="$event.stopPropagation()">
    <div class="box-inputs">
      <mat-form-field [floatLabel]="'always'">
        <input matInput
               type="text"
               #searchInput
               [placeholder]="'action.search' | translate"
               (keydown.enter)="onApply()"
               [formControlName]="controls.SEARCH"/>
      </mat-form-field>
    </div>
  </form>

</cmn-common-filter>
