<div [style.width.px]="width" class="card"
     [ngClass]="{'card_le_l': (size$ | async).less('l')}">
  <h4>
    <span class="unit-name" [matTooltip]="unitData?.unit.name">{{ unitData?.unit.name }}</span>
    <mat-icon (click)="onEdit()" class="header-icon-button text-primary-color pointer">edit</mat-icon>
  </h4>

  @if (unitData?.unit.trackerCertificationExpiry) {
    <div class="flex flex-v-center flex-gap4 margin-left-8 pointer" (click)="onEditCertificate()">
      <div class="title">Сертификат:</div>
      <app-certificate-expire [certificateExpire]="unitData!.unit.trackerCertificationExpiry" [shortView]="true">
      </app-certificate-expire>
    </div>
  }

  <p>
    <span class="title">{{ 'unit.device-model' | translate }}: </span>
    <span class="text">{{ unitData?.unit.device.model.brand.name }} {{ unitData?.unit.device.model.name }}</span>
  </p>
  <p>
    <span class="title">{{ 'unit.protocol' | translate }}: </span>
    <span class="text">{{ unitData?.unit.device.protocol.name }}</span>
  </p>
  <p>
    <span class="title">{{ 'unit.IMEI' | translate }}: </span>
    <span class="text">{{ unitData?.unit.device.imei }}</span>
  </p>
  <p>
    <span class="title">{{ 'unit.phone-number' | translate }}: </span>
    <span class="text">{{ unitData?.unit.device.phoneNumber }}</span>
  </p>
  <p>
    <span class="title">{{ 'term.owner' | translate }}: </span>
    <span class="text">{{ unitData?.unit.owner.name }}</span>
  </p>
  <p>
    <span class="title">{{ 'term.id' | translate }}: </span>
    <span class="text">{{ unitData?.unit.id }}</span>
  </p>

  <button mat-button class="app-button primary" (click)="onSendCommand()">
    <span>{{ 'action.send-command' | translate }}</span>
  </button>
</div>
