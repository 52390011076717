import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MatListOption, MatSelectionList} from '@angular/material/list';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, take, takeUntil} from 'rxjs/operators';

import {ConnectionStatus} from '../../../../../../common-module/src/lib/modelinterfaces/enums/connection-status';
import {UnitShort} from '../../../../../../common-module/src/lib/modelinterfaces/unit-short.model';
import {NotificationUnread} from '../../../../../../common-module/src/lib/modelinterfaces/notification-unread.model';
import {UnitStateStorage} from '../../../../../../common-module/src/lib/modelinterfaces/unit-state-storage.model';
import {AuthUserService} from '../../../../../../common-module/src/lib/app-services/auth-user.service';
import {NotificationManagerService} from '../../../shared/services/notification-manager.service';
import {SelectedService} from '../../../shared/services/selected.service';
import {UnitGroupsService} from '../../../shared/services/unit-groups.service';
import {UnitStateService} from '../../../shared/services/unit-state.service';
import {UnitStateConst} from "../../../shared/utils/unit-state.const";
import {BaseUnsubscribeComponent} from "../../../shared/components/base-unsubscribe.component";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {UnitGroupView} from "../../../../../../common-module/src/lib/modelinterfaces/user-unit-groups.model";

@Component({
  selector: 'app-unit-list',
  templateUrl: './unit-list.component.html',
  styleUrls: ['./unit-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class UnitListComponent extends BaseUnsubscribeComponent implements OnInit, OnChanges {

  @ViewChild('unitSelectionList') unitSelectionListElem: MatSelectionList;

  @Input() isSplitByUsers = true;
  @Input() isPublicInterface = false;
  @Input() units: UnitShort[] = [];
  @Input() unitGroupViews = new Map<number, UnitGroupView>();

  hoveredUnit: UnitShort;

  unitIdSet = new Set<number>();
  selectedUnitIds = new Set<number>();

  public connectionStatus = ConnectionStatus;
  public isSelectAll$ = new BehaviorSubject<boolean>(false);
  public selectedUnitIdList$: Observable<number[]>;
  public stopSpeed: number;
  public timeZone$: Observable<string>;
  public unitsState$: Observable<UnitStateStorage>;
  public noticeDataCount$: Observable<Map<number, NotificationUnread>>;

  constructor(private authUserService: AuthUserService,
              private notificationManagerService: NotificationManagerService,
              private selectedService: SelectedService,
              private unitGroupsService: UnitGroupsService,
              private unitStateService: UnitStateService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.units) {
      this.unitIdSet = new Set(this.units.map(unit => unit.id));
      this.updateSelectedUnitIds();
    }
  }

  ngOnInit(): void {
    this.timeZone$ = this.authUserService.currentUser$.pipe(map(user => user?.getUserTimeZone()));
    this.unitsState$ = this.unitStateService.unitsStateWithCheck$.pipe(map(data => data?.unitsState));
    this.selectedUnitIdList$ = this.selectedService.selectedUnitIdsSet$.pipe(map(set => Array.from(set)));
    this.selectedService.selectedUnitIdsSet$.pipe(takeUntil(this.destroy))
      .subscribe(set => {
        if (set.size > 0 && this.units.every(el => set.has(el.id))) {
          this.isSelectAll$.next(true);
        } else {
          this.isSelectAll$.next(false);
        }
      });
    this.noticeDataCount$ = this.notificationManagerService.noticeCount$.pipe(map(counts => counts?.data));
    this.stopSpeed = UnitStateConst.STOP_SPEED;
  }

  public checkListOption(listOption: MatListOption, $event: MouseEvent): void {
    if (this.isNotCheckboxClick($event)) {
      this.selectedService.removeSelectedUnitId();
    }
    if (listOption.selected) {
      this.selectedService.changeLastSelectedUnit(this.unitGroupsService.getUnitById(listOption.value));
      this.selectedService.appendSelectedUnitIds([listOption.value]);
    } else {
      this.selectedService.removeSelectedUnitId(listOption.value);
      this.selectedService.changeLastSelectedUnit(null);
    }
    this.updateSelectedUnitIds();
  }

  private isNotCheckboxClick($event: MouseEvent): boolean {
    return !($event.target as HTMLElement).className.includes('mdc-checkbox');
  }

  public helperSelection($event: MatCheckboxChange): void {
    $event.checked ? this.selectAllUnits() : this.deselectAllUnits();
  }

  private selectAllUnits(): void {
    this.unitSelectionListElem.selectAll();
    const ids = this.unitSelectionListElem.selectedOptions.selected.map(item => item.value);
    this.selectedService.appendSelectedUnitIds(ids);
    this.selectedService.changeLastSelectedUnit(this.units[this.units.length - 1]);
    this.updateSelectedUnitIds();
  }

  private deselectAllUnits(): void {
    this.selectedService.removeSelectedUnitId();
    this.unitSelectionListElem.deselectAll();
    this.selectedService.changeLastSelectedUnit(null);
    this.updateSelectedUnitIds();
  }

  trackById(index: number, item: any): any {
    return item.id;
  }

  private updateSelectedUnitIds() {
    this.selectedService.selectedUnitIdsSet$.pipe(take(1)).subscribe(unitIdSet => {
      this.selectedUnitIds = new Set([...unitIdSet].filter(unitId => this.unitIdSet.has(unitId)));
    });
  }
}
