import {Notification} from './notification.model';

export class NotificationWithNew {

  constructor(public notification: Notification,
              public isNew: boolean) {
  }

  public static valueOf(i: NotificationWithNew): NotificationWithNew {
    return i ? new NotificationWithNew(Notification.valueOf(i.notification), i.isNew) : null;
  }
}
