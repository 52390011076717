<div [style.width.px]="width" class="card"
     [ngClass]="{'card_le_l': (viewBreakpointService.size$ | async).less('l')}">
  <h4>
    <span>{{ 'term.location' | translate }}</span>
  </h4>

  <p>
    <span class="title">{{ 'term.address' | translate }}: </span>
    <span class="text">{{ unitData.getAddress() }}</span>
  </p>
  <p>
    <span class="title">{{ sensorGpsSatellites?.nameTranslated }}: </span>
    <span class="text">
      <span>{{ sensorGpsSatellites?.value | sensorValue: 0 }} </span>
      <span>{{ sensorGpsSatellites?.unitOfMeasure?.nameTranslated }}</span>
    </span>
  </p>
  <p>
    <span class="title">{{ 'term.longitude' | translate }}: </span>
    <span class="text">{{ unitData.address ? unitData.address.longitude : ''}}</span>
  </p>
  <p>
    <span class="title">{{ 'term.latitude' | translate }}: </span>
    <span class="text">{{ unitData.address ? unitData.address.latitude : ''}}</span>
  </p>
  <p>
    <span class="title">{{ 'term.altitude' | translate }}: </span>
    <span class="text">{{ unitData.getAltitude() >= 0 ? unitData.getAltitude() : '-'}} {{ 'uom.m' | translate }}</span>
  </p>
</div>
