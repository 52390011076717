import Icon, {Options} from "ol/style/Icon";

export interface OlIconOpts extends Options {

}

export class OlIconFactory {
  public static create(opts: OlIconOpts) {
    return new Icon(opts);
  }
}
