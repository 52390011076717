
export class AuthResponse {
  constructor(
    public token: string
  ) {}

  public static valueOf(o: AuthResponse): AuthResponse {
    return o ? new AuthResponse(o.token) : null;
  }
}
