import {UnitGroup} from './unit-group.model';
import {UnitShort} from './unit-short.model';
import {UserShort} from './user-short.model';

export interface UnitGroupView {
  groupId: number;
  groupName: string;
  groupColor: string;
}

export class UserUnitGroups extends UserShort {

  constructor(public id: number,
              public name: string,
              public owned: UnitShort[],
              public shared: UnitShort[],
              public groups: UnitGroup[]) {
    super(id, name);
  }

  public getUnitGroupViews(): Map<number, UnitGroupView> {
    const unitGroupView = new Map<number, UnitGroupView>();
    for (const group of this.groups) {
      for (const unit of group.units) {
        unitGroupView.set(unit.id, {groupName: group.name, groupColor: group.color, groupId: group.id})
      }
    }
    return unitGroupView;
  }

  public static valueOf(o: UserUnitGroups): UserUnitGroups {
    return o ? new UserUnitGroups(
      o.id,
      o.name,
      o.owned,
      o.shared,
      UnitGroup.valuesOf(o.groups)) : null;
  }

  public static valuesOf(list: UserUnitGroups[]): UserUnitGroups[] {
    return list ? list.map(o => UserUnitGroups.valueOf(o)) : [];
  }

  public addGroup(group: UnitGroup): UnitGroup[] {
    this.groups.push(group);
    return this.groups;
  }

  public editGroup(group: UnitGroup): UnitGroup[] {
    const index = this.groups.findIndex(el => el.id === group.id);
    this.groups[index] = group;
    return this.groups;
  }

  public deleteGroup(groupId: number): UnitGroup[] {
    return this.groups.filter(el => el.id !== groupId);
  }
}
