import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {merge, Observable, of, Subject} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import {AppMessageService} from '../../../../../common-module/src/lib/app-services/app-message.service';
import {
  ViewBreakpointService,
  ViewSize
} from '../../../../../common-module/src/lib/app-services/view-breakpoint.service';
import {UnitShort} from '../../../../../common-module/src/lib/modelinterfaces/unit-short.model';
import {FooterButtonsService} from '../../shared/services/footer-buttons.service';
import {ToolbarService} from '../../shared/services/toolbar.service';
import {SelectedService} from '../../shared/services/selected.service';
import {ToolbarComponent} from '../main/toolbar/toolbar.component';
import {UnitInfoCarouselComponent} from '../main/unit-info-carousel/unit-info-carousel.component';
import {Tab} from '../../../../../common-module/src/lib/app-enums/tab';
import {NavigationEnd, Router} from '@angular/router';
import {GlobalStyleClass} from '../../../../../common-module/src/lib/app-enums/global-style-class.enum';
import {DateRangeI} from '../../../../../common-module/src/lib/app-interfaces/date-range-i';
import {UnitInfoCarouselDialogComponent} from "./unit-info-carousel-dialog/unit-info-carousel-dialog.component";
import {ToolbarDialogComponent} from "./toolbar-dialog/toolbar-dialog.component";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FooterComponent implements OnInit, OnDestroy {

  public size$: Observable<ViewSize>;
  public isSideNavOpen$: Observable<boolean>;
  public isToolbarOpen = false;
  public isUnitInfoOpen = false;
  public lastSelectedUnit: UnitShort;
  public tab$: Observable<Tab>;
  public tabName = Tab;

  private dateTimeRange: DateRangeI;
  private dialogRef: MatDialogRef<any>;
  private componentDestroyed = new Subject<boolean>();

  constructor(private appUserMessageService: AppMessageService,
              private cdr: ChangeDetectorRef,
              private dialog: MatDialog,
              private footerButtonsService: FooterButtonsService,
              private router: Router,
              private selectedService: SelectedService,
              private toolbarService: ToolbarService,
              private viewBreakpointService: ViewBreakpointService) {
    this.tab$ = merge(
      of(this.router.parseUrl(this.router.url).root.children.primary?.segments[0]?.toString() as Tab),
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url.split('/')[1] as Tab)));
  }

  ngOnInit(): void {
    this.size$ = this.viewBreakpointService.size$;
    this.isSideNavOpen$ = this.footerButtonsService.currentIsOpenSideNav$;
    this.selectedService.lastSelectedUnit$
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(u => this.lastSelectedUnit = u);
    this.toolbarService.state$
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(s => {
        if (this.dialogRef && s.closeDialog) {
          this.dialogRef.close();
        }
      });
    console.log(this.isUnitInfoOpen, this.isToolbarOpen)
  }

  public openToolbarDialog(): void {
    if (!this.lastSelectedUnit) {
      this.footerButtonsService.changeIsOpenSideNav(true);
      this.appUserMessageService.openSnackBar(`action.select-unit`, true);
      return;
    }
    this.dialogRef = this.dialog.open(ToolbarDialogComponent, {
      height: '90vh',
      minHeight: '90vh',
      width: '95vw',
      minWidth: '95vw',
      autoFocus: false,
      data: {
        dateTimeRange: this.dateTimeRange
      }
    });
    this.isToolbarOpen = true;
    this.observeSize(this.dialogRef);
    this.dialogRef.afterClosed().subscribe(range => {
      this.isToolbarOpen = false;
      this.dateTimeRange = range;
      this.cdr.detectChanges();
    });
  }

  public openUnitInfoDialog(): void {
    const dialogRef = this.dialog.open(UnitInfoCarouselDialogComponent, {
      height: '90vh',
      minHeight: '90vh',
    });

    this.isUnitInfoOpen = true;
    this.observeSize(dialogRef);
    dialogRef.afterClosed().subscribe(() => {
      this.isUnitInfoOpen = false;
      this.cdr.detectChanges();
    });
  }

  private observeSize(dialogRef): void {
    this.size$.subscribe(s => {
      if (s.more('m')) {
        dialogRef.close();
      }
    });
  }

  public toggleSidenav(): void {
    this.footerButtonsService.toggleIsOpenSideNav();
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
  }
}
