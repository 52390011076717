<app-dialog [title]="'term.users' | translate">

  <ng-container appDialogContent>
    <p class="selected-number">
      <span>{{ 'message.info.selected-count' | translate: {count: '(' + selectedUserNames.length + ')'} }}</span>
    </p>

    <mat-selection-list class="selection-list" #userList>
      <mat-list-option #allSelected (click)="toggleAllSelection()" class="select-all">
        {{ 'action.select-all' | translate }}:
      </mat-list-option>

      <div class="options">
        <div *ngFor="let userName of data.userNameList">
          <mat-list-option
            value="{{ userName }}"
            (click)="handleSelection()"
            [selected]="selectedUserNames.includes(userName)">
            <p>
              <span>{{ userName }}</span>
            </p>
          </mat-list-option>
        </div>
      </div>
    </mat-selection-list>
  </ng-container>

  <ng-container appDialogActions>
    <app-button-save (save)="onClose()">
    </app-button-save>
  </ng-container>
</app-dialog>
