import { Device } from './device.model';
import { Driver } from './driver.model';
import { UnitStatus } from './enums/unit-status';
import { TripSettings } from './trip-settings.model';
import { UnitShort } from "./unit-short.model";
import { UserShort } from './user-short.model';

export class Unit extends UnitShort {

  constructor(
    public readonly id: number,
    public name: string,
    public color: string,
    public device: Device,
    public owner: UserShort,
    public dataStorageTimeDays: number,
    public status: UnitStatus,
    public tripSettings: TripSettings,
    public driver: Driver,
    public customFields: {[p: string]: string}
  ) {
    super(id, name, color, status);
  }

  public static valueOf(o: Unit): Unit {
    return o ? new Unit(o.id, o.name, o.color, o.device, UserShort.valueOf(o.owner),
      o.dataStorageTimeDays, o.status, TripSettings.valueOf(o.tripSettings), Driver.valueOf(o.driver), o.customFields) : null;
  }

  public static valuesOf(list: Unit[]): Unit[] {
    return list ? list.map(o => Unit.valueOf(o)) : [];
  }

  public customFieldsToString(): string {
    let fieldsInString = '';
    for (const property in this.customFields) {
      fieldsInString += property + ': ' + this.customFields[property] + ', ';
    }
    return fieldsInString.substr(0, fieldsInString.length - 2); // remove comma in the end of the string
  }
}
