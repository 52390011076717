import { CriterionType } from './enums/criterion-type';
import { MinMaxRange } from './min-max-range.model';
import { Sensor } from './sensor.model';

export class EcoDrivingCriterion {

  constructor(public id: number,
              public name: string,
              public type: CriterionType,
              public sensor: Sensor,
              public valueRange: MinMaxRange,
              public durationRange: MinMaxRange,
              public speedRange: MinMaxRange,
              public validatingSensor: Sensor,
              public penalty: number,
              public multiplier: boolean,
              public color: string,
              public createdTime: string) {
  }

  public static valueOf(o: EcoDrivingCriterion): EcoDrivingCriterion {
    return o ? new EcoDrivingCriterion(o.id, o.name, o.type, Sensor.valueOf(o.sensor), MinMaxRange.valueOf(o.valueRange),
      MinMaxRange.valueOf(o.durationRange),
      MinMaxRange.valueOf(o.speedRange), Sensor.valueOf(o.validatingSensor), o.penalty, o.multiplier, o.color, o.createdTime) : null;
  }

  public static valuesOf(list: EcoDrivingCriterion[]): EcoDrivingCriterion[] {
    return list ? list.map(o => EcoDrivingCriterion.valueOf(o)) : [];
  }
}
