import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as echarts from 'echarts';
import {ECharts, EChartsOption} from 'echarts';
import {OptionDataValue} from 'echarts/types/src/util/types';
import {AppColor} from '../../../../../../common-module/src/lib/app-enums/app-color';
import {GpsCoordinate} from '../../../../../../common-module/src/lib/modelinterfaces/gps-coordinate.model';
import {SensorDataStorage} from '../../../../../../common-module/src/lib/modelinterfaces/sensor-data-storage.model';
import {DateConverter} from '../../../../../../common-module/src/lib/utils/date-converter';
import {SensorUtils} from '../../../../../../common-module/src/lib/utils/sensor-utils';
import {AuthUserService} from '../../../../../../common-module/src/lib/app-services/auth-user.service';
import {TrackLayerService} from "../../services/track-layer.service";

@Component({
  selector: 'app-speed-chart',
  standalone: true,
  templateUrl: './speed-chart.component.html',
  styleUrls: ['./speed-chart.component.scss']
})

export class SpeedChartComponent implements OnChanges, OnDestroy {

  @Input() sensorDataStorage: SensorDataStorage;

  @Output() selectCoordinateEvent = new EventEmitter<GpsCoordinate>();

  private chartInstance: ECharts;

  constructor(private authUserService: AuthUserService,
              private trackLayerService: TrackLayerService,
              private translate: TranslateService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sensorDataStorage'] && this.sensorDataStorage) {
      this.createEcharts();
      this.buildGraph();
    }
  }

  private createEcharts(): void {
    this.chartInstance = echarts.init(document.getElementById('speed-e-charts'));

    setTimeout(() => {
      if (this.chartInstance) {
        this.chartInstance.resize();
      }
    }, 100);
  }

  private buildGraph(): void {
    const chartData = this.buildChartData();

    const selected: { [key: string]: boolean } = {};

    const speedSensor = this.sensorDataStorage.sensors.find(s => s.name === 'gps-speed');
    const enableSensorNames = new Set([speedSensor.nameTranslated]);
    this.trackLayerService.getInstantTrackOptions()?.sensorRanges?.map(r => r.sensorName).forEach(sn => enableSensorNames.add(sn));

    chartData.forEach(series => {
      selected[series.name] = enableSensorNames.has(series.name);
    });

    const chartOption: EChartsOption = {
      legend: {
        type: 'scroll',
        top: 0,
        data: this.sensorDataStorage.sensors.map(sensor => sensor.nameTranslated),
        selected
      },
      toolbox: {
        show: true,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
          dataZoom: {
            title: {
              zoom: this.translate.instant('action.zoom'),
              back: this.translate.instant('action.back')
            },
            emphasis: {
              iconStyle: {
                textPosition: 'left'
              }
            },
            yAxisIndex: 'none'
          },
          restore: {
            title: this.translate.instant('action.reset'),
            emphasis: {
              iconStyle: {
                textPosition: 'left'
              }
            },
          },
          saveAsImage: {
            show: true,
            title: this.translate.instant('action.download'),
            emphasis: {
              iconStyle: {
                textPosition: 'left'
              }
            },
          }
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
          snap: true,
          animation: false,
          label: {
            backgroundColor: AppColor.GRAY,
            formatter: (params) => {
              if ((params?.seriesData[0]?.data as OptionDataValue[]).length > 0) {
                const time = params.seriesData[0].data[0];
                this.sendCoordinateToMap(time);
                return DateConverter.utcDateTimeToTzDateString(time, this.authUserService.getInstantTimeZone());
              }
              return '';
            },
          }
        },
      },
      grid: {
        top: 25,
        bottom: 30,
        left: 5,
        right: 25,
        containLabel: true
      },
      dataZoom: [
        {
          type: 'inside',
          filterMode: 'none'
        },
        {
          type: 'slider',
          moveHandleSize: 0,
          filterMode: 'none',
          bottom: 0
        }
      ],
      xAxis: {
        type: 'time',
        silent: true,
        minInterval: 1000 * 60,
        axisLabel: {
          formatter: {
            month: '{yyyy}-{MM}-{dd}',
            day: '{d}',
            hour: '{HH}:{mm}',
            minute: '{HH}:{mm}',
            second: '{HH}:{mm}:{ss}',
            millisecond: '{HH}:{mm}:{ss}'
          }
        }
      },
      yAxis: [{
        type: 'value',
        silent: true,
        splitLine: {
          show: true,
          lineStyle: {
            color: AppColor.GRAY,
            type: 'dotted'
          }
        }
      }],
      series: chartData
    };
    this.chartInstance.setOption(chartOption, true);
  }

  public buildChartData(): Array<any> {
    const chartDataArray: Array<any> = [];

    // Iterate over all sensors in sensorDataStorage
    for (const sensor of this.sensorDataStorage.sensors) {
      const chartSensorData: any = {
        name: sensor.nameTranslated,
        type: 'line',
        data: [],
        showSymbol: false,
      };

      // Populate data for the current sensor
      for (const sensorSpeed of this.sensorDataStorage.sensorDataList) {
        const time = DateConverter.utcDatetimeToTzDate(
          sensorSpeed.time,
          this.authUserService.getInstantTimeZone()
        );

        let value = sensorSpeed.sensorRecords.get(sensor?.id);

        if (value?.toString() === 'NaN') {
          value = undefined;
        }

        chartSensorData.data.push([time, SensorUtils.toValue(value, 2)]);
      }

      chartDataArray.push(chartSensorData);
    }

    return chartDataArray;
  }

  private sendCoordinateToMap(time: Date): void {
    this.selectCoordinateEvent.emit(
      this.sensorDataStorage.sensorDataList[this.sensorDataStorage.searchIndexByDatetime(time.toISOString())]?.getGpsCoordinate()
    );
  }

  ngOnDestroy(): void {
    if (this.chartInstance) {
      this.chartInstance.dispose();
      this.chartInstance = null;
    }
  }
}
