<div [style.width.px]="width" class="card"
     [ngClass]="{'card_le_l': (viewBreakpointService.size$ | async).less('l')}">
  <h4 class="status">
    <ng-container *ngIf="unitData.getStatus() === connectionStatus.ONLINE">
      <span>{{ 'filter.online' | translate }}</span>
      <span class="circle online"></span>
    </ng-container>
    <ng-container *ngIf="unitData.getStatus() === connectionStatus.OFFLINE">
      <span>{{ 'term.connection-lost' | translate }}</span>
      <span class="circle offline"></span>
    </ng-container>
    <ng-container *ngIf="unitData.getStatus() === connectionStatus.NOT_VALID">
      <span>{{ 'message.error.not-valid' | translate }}</span>
      <span class="circle not-valid"></span>
    </ng-container>
    <ng-container *ngIf="unitData.getStatus() === connectionStatus.NO_DATA || !unitData.getStatus()">
      <span>{{ 'message.error.no-data' | translate }}</span>
      <span class="circle"></span>
    </ng-container>
  </h4>

  <ng-container *ngIf="unitData.getTime()">
    <p class="title">{{ 'term.last-update' | translate }}:</p>
    <p>
      <span>{{ 'time.ago' | translate:{time: unitData.getSecondsAgo() | secToDuration : translateService} }}</span>
    </p>
    <p>
      <span>{{ unitData.getTime() | utcDateTz: timeZone }}</span>
    </p>
  </ng-container>

  <p *ngIf="sensorGsmLevel">
    <span class="title">{{ sensorGsmLevel.nameTranslated }}: </span>
    <span class="text">
      <span>{{ sensorGsmLevel.value | sensorValue: 1  }} </span>
      <span>{{ sensorGsmLevel.unitOfMeasure.nameTranslated }}</span>
    </span>
  </p>
  <p *ngIf="sensorBatteryLevel">
    <span class="title">{{ sensorBatteryLevel.nameTranslated }}: </span>
    <span class="text">
      <span>{{ sensorBatteryLevel.value | sensorValue: 1 }} </span>
      <span>{{ sensorBatteryLevel.unitOfMeasure.nameTranslated }}</span>
    </span>
  </p>
</div>

