import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UnitVideoShort } from '../../../../../common-module/src/lib/modelinterfaces/unit-video-short.model';

@Injectable({
  providedIn: 'root'
})

export class UnitWithCamerasBiDvrService {

  private unitListWithCameras = new BehaviorSubject<UnitVideoShort[]>(null);
  private _unitListWithCameras$: Observable<UnitVideoShort[]> = this.unitListWithCameras.asObservable();
  private errorBiDvr = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  get unitListWithCameras$(): Observable<UnitVideoShort[]> {
    return this._unitListWithCameras$;
  }

  public changeList(list: UnitVideoShort[]): void {
    this.unitListWithCameras.next(list);
  }

  public changeStatus(isError: boolean): void {
    this.errorBiDvr.next(isError);
  }

  public getInstantListWithCameras(): UnitVideoShort[] {
    return this.unitListWithCameras.getValue();
  }

  public getInstantErrorBiDvr(): boolean {
    return this.errorBiDvr.getValue();
  }

  public destroy(): void {
    this.unitListWithCameras.next([]);
    this.errorBiDvr.next(false);
  }
}
