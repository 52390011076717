import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header.component';
import {ChangePasswordDialogComponent} from './change-password-dialog/change-password-dialog.component';
import {AppCommonModule} from '../../../../../common-module/src/lib/app-common.module';
import {PublicLinksDialogComponent} from './public-links-dialog/public-links-dialog.component';
import {InformingDialogComponent} from './informing-dialog/informing-dialog.component';
import {SharedModule} from '../../shared/shared.module';
import {ErrorStateMatcher, ShowOnDirtyErrorStateMatcher} from '@angular/material/core';
import {AppDialogModule} from "../../../../../common-module/src/lib/dialog/dialog/app-dialog.module";
import {
  DialogActionsCancelSaveComponent
} from "../../../../../common-module/src/lib/dialog/dialog-save/dialog-actions-cancel-save/dialog-actions-cancel-save.component";
import {TranslateModule} from "@ngx-translate/core";
import {MatTableModule} from "@angular/material/table";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {TimestampTimezoneConvertPipe} from "../../../../../common-module/src/lib/pipes/timestamp-timezone-convert.pipe";
import {MatButtonModule} from "@angular/material/button";
import {MatListModule} from "@angular/material/list";
import {MatMenuModule} from "@angular/material/menu";
import {AppButtonModule} from "../../../../../common-module/src/lib/buttons/app-button.module";
import {MatBadgeModule} from "@angular/material/badge";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {LogoTitleComponent} from "../../../../../common-module/src/lib/app-components/logo-header/logo-title.component";
import {MatToolbarModule} from "@angular/material/toolbar";
import {LogisticLogoTitleComponent} from "../../shared/components/logistic-logo-title/logistic-logo-title.component";
import {
  ChangeLanguageComponent
} from "../../../../../common-module/src/lib/app-components/change-language/change-language.component";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatInputModule} from "@angular/material/input";
import {TelegramMessageComponent} from "../../shared/components/telegram-message/telegram-message.component";

@NgModule({
  declarations: [
    HeaderComponent,
    ChangePasswordDialogComponent,
    PublicLinksDialogComponent,
    InformingDialogComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    SharedModule,
    AppDialogModule,
    DialogActionsCancelSaveComponent,
    TranslateModule,
    MatTableModule,
    MatIconModule,
    MatTooltipModule,
    TimestampTimezoneConvertPipe,
    MatButtonModule,
    MatListModule,
    MatMenuModule,
    AppButtonModule,
    MatIconModule,
    MatBadgeModule,
    MatButtonToggleModule,
    LogoTitleComponent,
    MatToolbarModule,
    LogisticLogoTitleComponent,
    ChangeLanguageComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatInputModule,
    TelegramMessageComponent
  ],
  providers: [{provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher}],
  bootstrap: [],
  exports: [
    HeaderComponent
  ]
})

export class HeaderModule {
}
