@if (visibility) {
  <app-icon-button-primary [icon]="'visibility'"
                           [disabled]="disabled"
                           [matTooltip]="'Скрыть'"
                           [showSpinner]="showSpinner"
                           (action)="visibility= false; onAction($event)">
  </app-icon-button-primary>
} @else {
  <app-icon-button-primary [icon]="'visibility_off'"
                           [disabled]="disabled"
                           [matTooltip]="'Показать'"
                           [showSpinner]="showSpinner"
                           (action)="visibility= true; onAction($event)">
  </app-icon-button-primary>
}
