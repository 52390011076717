import { Component, Input } from '@angular/core';
import { ViewBreakpointService } from '../../../../../../../../common-module/src/lib/app-services/view-breakpoint.service';
import { UnitAdditionalData } from '../../../../../../../../common-module/src/lib/modelinterfaces/unit-additional-data.model';

@Component({
  selector: 'app-driver-card',
  templateUrl: './driver-card.component.html',
  styleUrls: ['../carousel-card-styles.component.scss']
})

export class DriverCardComponent {

  @Input() width: number;
  @Input() unitData: UnitAdditionalData;

  constructor(public viewBreakpointService: ViewBreakpointService) {
  }
}
