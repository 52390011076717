import {Driver} from './driver.model';
import {GpsCoordinate} from './gps-coordinate.model';
import {UnitState} from './unit-state.model';
import {Logistic} from "./logistic.model";
import Carrier = Logistic.Carrier;
import {ParameterizedMessage} from "./paramitized-message.model";
import {UnitStateService} from "../../../../user-portal/src/app/shared/services/unit-state.service";
import {Instant} from "@js-joda/core";
import {LocalDateTimeUtil} from "../utils/local-date-time.util";

export class UnitStateStorage {

  public static EMPTY() {
    return new UnitStateStorage('', new Map());
  }

  constructor(public created: string,
              public data: Map<number, UnitState>) {
    this.data = new Map();
    Object.keys(data).forEach(key => {
      this.data.set(Number(key), UnitState.valueOf(data[key]));
    });
  }

  public static valueOf(i: UnitStateStorage): UnitStateStorage {
    return i ? new UnitStateStorage(i.created, i.data) : null;
  }

  public get(unitId: number): UnitState {
    return this.data.get(unitId);
  }

  public getCourse(unitId: number): number {
    return this.data.get(unitId)?.gpsState?.course;
  }

  public getSpeed(unitId: number): number {
    return this.data.get(unitId)?.gpsState?.speed;
  }

  public getCoordinate(unitId: number): GpsCoordinate {
    return this.data.get(unitId)?.gpsState?.coordinate;
  }

  public getCoordinateCarrier(carrier: Carrier): GpsCoordinate {
    return this.getCoordinate(carrier.unit.id);
  }

  public getCoordinateList(): GpsCoordinate[] {
    const coordList = [];
    this.data.forEach(state => {
      if (state?.gpsState?.coordinate) {
        coordList.push(state.gpsState.coordinate);
      }
    });
    return coordList;
  }

  public isLastCoordinateValid(unitId: number): boolean {
    return !!this.data.get(unitId)?.gpsState?.coordinate;
  }

  public getStatus(unitId: number): string {
    return this.data.get(unitId)?.connectionState?.status;
  }

  public getSecondsAgo(unitId: number): number {
    return this.data.get(unitId)?.connectionState?.secondsAgo;
  }

  public getTime(unitId: number): string {
    return this.data.get(unitId)?.connectionState?.time;
  }

  public getFormattedLocalTime(unitId: number, pattern: string = 'dd.MM.yyyy HH:mm:ss'): string | null {
    const instantStr = this.data.get(unitId)?.connectionState?.time;
    if (instantStr) {
      let instant = Instant.parse(instantStr);
      return LocalDateTimeUtil.toFormattedLocalDatetime(instant, pattern);
    } else {
      return null;
    }
  }

  public getDriver(unitId: number): Driver {
    return this.data.get(unitId)?.driver;
  }

  public getAvatar(unitId: number): string {
    return this.data.get(unitId)?.driver?.avatarUrl;
  }

  public getDriverName(unitId: number): string {
    return this.data.get(unitId)?.driver?.getShortName();
  }

  public getState(unitId: number): UnitState {
    return this.data.get(unitId);
  }

  public getWarnings(unitId: number): ParameterizedMessage[] {
    return this.getState(unitId)?.warnings || [];
  }

  public getAlerts(unitId: number): ParameterizedMessage[] {
    return this.getState(unitId)?.alerts || [];
  }
}
