import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {MapTool} from 'projects/user-portal/src/app/shared/constants/enums/map-tool';
import {MapSettings} from '../constants/enums/map-settings';

@Injectable({
  providedIn: 'root'
})

export class MapToolsService {

  private zoomSource = new BehaviorSubject<number>(null);
  public zoom$: Observable<MapTool> = this.zoomSource.asObservable();

  private previousZoom: number;

  private stateSource = new BehaviorSubject<MapTool>(MapTool.DEFAULT);
  public state$: Observable<MapTool> = this.stateSource.asObservable();

  constructor() {
  }

  public changeState(state: MapTool): void {
    this.stateSource.next(state);
  }

  public changeZoom(zoom: number): void {
    this.zoomSource.next(zoom);
  }

  public instantZoom(): number {
    return this.zoomSource.getValue();
  }

  public saveZoom(): void {
    this.previousZoom = this.instantZoom();
  }

  public restoreMapZoom(): void {
    this.zoomSource.next(this.previousZoom || MapSettings.DEFAULT_ZOOM);
  }

  public toggleSearchAddress(): void {
    if (this.stateSource.getValue() === MapTool.SEARCH_ADDRESS) {
      this.stateSource.next(MapTool.DEFAULT);
    } else {
      this.stateSource.next(MapTool.SEARCH_ADDRESS);
    }
  }

  public destroy(): void {
    this.stateSource.next(MapTool.DEFAULT);
  }
}
