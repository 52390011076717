import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ViewBreakpointService } from '../../../../../../../../common-module/src/lib/app-services/view-breakpoint.service';
import { SensorName } from '../../../../../../../../common-module/src/lib/modelinterfaces/enums/sensor-name';
import { SensorTypeName } from '../../../../../../../../common-module/src/lib/modelinterfaces/enums/sensor-type-name';
import { SensorLastValue } from '../../../../../../../../common-module/src/lib/modelinterfaces/sensor-last-value.model';
import { UnitAdditionalData } from '../../../../../../../../common-module/src/lib/modelinterfaces/unit-additional-data.model';

@Component({
  selector: 'app-unit-status-card',
  templateUrl: './unit-status-card.component.html',
  styleUrls: ['../carousel-card-styles.component.scss']
})

export class UnitStatusCardComponent implements OnChanges {

  @Input() width: number;
  @Input() unitData: UnitAdditionalData;

  public sensorGpsSpeed: SensorLastValue;
  public sensorOnBoardVoltage: SensorLastValue;

  constructor(public viewBreakpointService: ViewBreakpointService,
              public translateService: TranslateService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['unitData']) {
      this.sensorGpsSpeed = this.unitData.getSensorLastValueByName(SensorName.SPEED);
      this.sensorOnBoardVoltage = this.unitData.getSensorLastValueByType(SensorTypeName.ONBOARD_VOLTAGE);
    }
  }
}
