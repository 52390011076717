export enum FilterOperation {
  BETWEEN = 'btn#',
  EQUALS = 'eq#',
  NOT_EQUAL = 'neq#',
  IN = 'in#',
  NOT_IN = 'nin#',
  LIKE = 'like#',
  GREATER_THAN = 'gt#',
  GREATER_THAN_OR_EQUAL_TO = 'gte#',
  LESS_THAN = 'lt#',
  LESSTHAN_OR_EQUAL_TO = 'lte#',
  IS_NULL = 'en#',
}
