import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import Point from 'ol/geom/Point';
import {Layer} from 'ol/layer';
import OlMap from 'ol/Map';
import {GpsCoordinate} from '../../../../../../common-module/src/lib/modelinterfaces/gps-coordinate.model';
import {Track} from '../../../../../../common-module/src/lib/modelinterfaces/track.model';
import {LayerName} from '../../../shared/constants/enums/layer-name';
import {MapSettings} from '../../../shared/constants/enums/map-settings';
import {Orientation} from '../../../shared/constants/enums/orientation';
import {UrlImage} from '../../../shared/constants/url-image';
import {ZIndexLayer} from '../../../shared/constants/z-index';
import {CenterMapUtil} from '../../../shared/map-utils/center-map.util';
import {OlCoordinate} from '../../../shared/map-utils/OlCoordinate';
import {OlFeature} from '../../../shared/map-utils/OlFeature';
import {OlVectorLayer} from '../../../shared/map-utils/OlVectorLayer';
import {AuthUserService} from '../../../../../../common-module/src/lib/app-services/auth-user.service';
import {OlTrackBuildService} from '../../../shared/services/ol-track-build.service';
import {OpenLayerMapService} from '../../../shared/services/open-layer-map.service';
import {PrintToPdfService} from '../../../shared/services/print-to-pdf.service';
import {TileLayerService} from '../../../shared/services/tile-layer.service';
import {DayjsUtil} from "../../../../../../common-module/src/lib/dayjs.util";
import {takeUntil} from "rxjs/operators";
import {BaseUnsubscribeComponent} from "../../../shared/components/base-unsubscribe.component";

export interface MapDialogData {
  unitName: string;
  tableData: string[];
  titleData: object;
  displayedColumns: string[];
  track: Track;
  point: GpsCoordinate;
  showStartFinishMarkers: boolean;
  showParkingMarkers: boolean;
}

@Component({
  selector: 'app-map-dialog',
  templateUrl: './map-dialog.component.html',
  styleUrls: ['./map-dialog.component.scss']
})

export class MapDialogComponent extends BaseUnsubscribeComponent implements OnInit, OnDestroy {

  private readonly FILE_TITLE = 'file-title';
  public map: OlMap;

  showSpinner = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: MapDialogData,
              private authUserService: AuthUserService,
              private openLayerMapService: OpenLayerMapService,
              private olTrackBuildService: OlTrackBuildService,
              private printService: PrintToPdfService,
              private tileLayerService: TileLayerService) {
    super();
  }

  ngOnInit(): void {
    const layer: Layer[] = this.tileLayerService.buildCurrentSelectedTile();
    this.map = this.openLayerMapService.create(layer, 'map-dialog', MapSettings.DIALOG_ZOOM);
    this.map.getViewport().style.position = 'absolute'; // for Chrome 49
    if (this.data.track) {
      if (this.data.track.storage.sensorDataList.length > 0) {
        const trackLayers = this.olTrackBuildService.buildTrackVectorLayer(
          this.data.track,
          LayerName.TRACK_MAP_DIALOG,
          {
            isStartFinish: this.data.showStartFinishMarkers,
            isShowParkings: this.data.showParkingMarkers,
            isShowTrips: false,
            isShowPoints: false,
            maxSpeed: 0,
            minDurationOverSpeed: 0,
            sensorRanges: []
          },
          this.authUserService.getInstantTimeZone()
        );
        for (const l of trackLayers) {
          this.map.addLayer(l);
        }
        CenterMapUtil.center(this.data.track, this.map, {padding: {top: 50, right: 50, bottom: 120, left: 50}});
      } else {
        // получен трек, но он пустой, то отобразить место стоянки
        const gpsCoordinate: GpsCoordinate = this.data.track.parkings[0].getCoordinate();
        const marker = OlFeature.createMarker(UrlImage.POSITION, new Point(OlCoordinate.createFromGpsCoordinate(gpsCoordinate)));
        const layerPoint = OlVectorLayer.createVectorLayerWithFeatures(marker, LayerName.SELECTED_POSITION_MAP_DIALOG);
        layerPoint.setZIndex(ZIndexLayer.TRACK_MARKER);
        this.map.addLayer(layerPoint);
        CenterMapUtil.center(gpsCoordinate, this.map, {zoom: 12});
      }
    }
    if (this.data.point) {
      const marker = OlFeature.createMarker(UrlImage.POSITION, new Point(OlCoordinate.createFromGpsCoordinate(this.data.point)));
      const layerPoint = OlVectorLayer.createVectorLayerWithFeatures(marker, LayerName.SELECTED_POSITION_MAP_DIALOG);
      layerPoint.setZIndex(ZIndexLayer.TRACK_MARKER);
      this.map.addLayer(layerPoint);
      CenterMapUtil.center(this.data.point, this.map, {zoom: 12});
    }
  }

  public onReceivePrintOrientation(orientation: Orientation): void {
    this.showSpinner = true;
    this.printService.printDialog(this.FILE_TITLE, this.map, this.data.track, orientation, 6, this.data.unitName)
      .pipe(takeUntil(this.destroy))
      .subscribe(inProgress => this.showSpinner = inProgress)
  }

  ngOnDestroy(): void {
    this.map = undefined;
    this.data = undefined;
  }
}
