<div class="container-colors">
  <button
    class="button"
    *ngFor="let color of collectionColors"
    [style.background-color]="color"
    [value]="color"
    (click)="onChangeColor(color)"
    mat-button
  ></button>
</div>
