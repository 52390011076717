import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppCommonModule} from '../../../../../../common-module/src/lib/app-common.module';
import {MapButtonsComponent} from './map-buttons/map-buttons.component';
import {MapConditionsComponent} from './map-conditions/map-conditions.component';
import {MeasureManagerComponent} from './measure-manager/measure-manager.component';
import {WeatherLegendComponent} from './map-buttons/weather-legend/weather-legend.component';
import {MatMenuModule} from "@angular/material/menu";
import {TranslateModule} from "@ngx-translate/core";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatIconModule} from "@angular/material/icon";
import {NgxDaterangepickerMd} from "ngx-daterangepicker-material-no-utc";
import {
  MultiTrackToolModule
} from "./trip-track-geometry-intersection-tool/multi-track-tool.module";

@NgModule({
  declarations: [
    MapButtonsComponent,
    MapConditionsComponent,
    MeasureManagerComponent,
    WeatherLegendComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    NgxDaterangepickerMd,
    MultiTrackToolModule,
    AppCommonModule,
    MatMenuModule,
    TranslateModule,
    MatTooltipModule,
    MatIconModule
  ],
  providers: [],
  bootstrap: [],
  exports: [
    MapButtonsComponent,
    MapConditionsComponent,
    MeasureManagerComponent,
    WeatherLegendComponent
  ]
})

export class MapToolsModule {
}
