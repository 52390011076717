import Style, {Options} from "ol/style/Style";

export interface OlStyleOpts extends Options {
}

export class OlStyleFactory {
  public static create(opts: OlStyleOpts): Style {
    return new Style(opts);
  }
}
