import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppCommonModule} from '../../../../../../common-module/src/lib/app-common.module';
import {MapButtonsComponent} from './map-buttons/map-buttons.component';
import {MapConditionsComponent} from './map-conditions/map-conditions.component';
import {MeasureManagerComponent} from './measure-manager/measure-manager.component';
import {WeatherLegendComponent} from './map-buttons/weather-legend/weather-legend.component';

@NgModule({
  declarations: [
    MapButtonsComponent,
    MapConditionsComponent,
    MeasureManagerComponent,
    WeatherLegendComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule
  ],
  providers: [],
  bootstrap: [],
  exports: [
    MapButtonsComponent,
    MapConditionsComponent,
    MeasureManagerComponent,
    WeatherLegendComponent
  ]
})

export class MapToolsModule {
}
