<mat-form-field>
  <mat-icon matPrefix color="primary">update</mat-icon>
  <mat-label> {{ 'action.select-timezone' | translate }}</mat-label>
  <mat-select
    [formControl]="control"
    [compareWith]="comparisonId"
    (opened)="clearFilterOptions()"
    (selectionChange)="timezoneChange.emit($event.value)">
    <mat-option>
      <ngx-mat-select-search (input)="filterOptions($any($event.target).value)"
                             [placeholderLabel]="'action.search' | translate"
                             [noEntriesFoundLabel]="'message.error.nothing-found' | translate">
        <mat-icon class="icon-clear" ngxMatSelectSearchClear (click)="clearFilterOptions()">cancel</mat-icon>
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let tz of $filteredTimeZones| async" [value]="tz">{{ tz.description }}</mat-option>
  </mat-select>

</mat-form-field>


