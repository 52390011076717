import {BaseUnsubscribeComponent} from "../../components/base-unsubscribe.component";
import {Component} from "@angular/core";
import {BaseMap} from "../../aur-map-creator/map/wrapper/base.map";
import {PopupMap} from "../../aur-map-creator/map/wrapper/popup.map";

@Component({template: ''})
export class AbstractMapComponent extends BaseUnsubscribeComponent {

  private static mapDivIdCounter = 0;
  private static popupDivIdCounter = 0;
  readonly mapDivId;
  readonly popupDivId;

  constructor() {
    super();
    this.mapDivId = BaseMap.DEFAULT_MAP_DIV_ID + AbstractMapComponent.mapDivIdCounter++;
    this.popupDivId = PopupMap.DEFAULT_MAP_POPUP_DIV_ID + AbstractMapComponent.popupDivIdCounter++;
  }
}
