import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MapDialogComponent} from './map-dialog.component';
import { AppCommonModule } from 'projects/common-module/src/lib/app-common.module';
import {MapToolsModule} from '../map/map-tools.module';
import {SharedModule} from '../../../shared/shared.module';
import {AppDialogModule} from "../../../../../../common-module/src/lib/dialog/dialog/app-dialog.module";
import {TranslateModule} from "@ngx-translate/core";
import {PrintButtonComponent} from "../../../shared/components/print-button/print-button.component";
import {MatTableModule} from "@angular/material/table";
import {CapitalizePipe} from "../../../../../../common-module/src/lib/pipes/capitalize.pipe";

@NgModule({
  declarations: [
    MapDialogComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    MapToolsModule,
    SharedModule,
    AppDialogModule,
    TranslateModule,
    PrintButtonComponent,
    MatTableModule,
    CapitalizePipe
  ],
  providers: [],
  bootstrap: [],
  exports: []
})

export class MapDialogModule {
}
