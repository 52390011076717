<app-header></app-header>

<mat-sidenav-container class="sidenav-container" [hasBackdrop]="false"
                       [ngClass]="{'height_le_l': (size$ | async).less('l')}">
  <mat-sidenav #sidenav
               [autoFocus]="false"
               [mode]="(size$ | async)?.less('l') ? 'over' : 'side'"
               [ngClass]="{'background_le_l': (size$ | async).less('l')}"
               [(opened)]="opened"
                class="sidenav">
    <app-side-nav [showNotificationPanel]="!isPublicInterface"></app-side-nav>
  </mat-sidenav>

  <mat-sidenav-content class="sidenav-content">
    <div class="toggle-arrow" *ngIf="(size$ | async).more('m')" (click)="sidenav.toggle(); updateMap()">
      <mat-icon *ngIf="opened">keyboard_arrow_left</mat-icon>
      <mat-icon *ngIf="!opened">keyboard_arrow_right</mat-icon>
    </div>

    <router-outlet *ngIf="loadingComplete"></router-outlet>

  </mat-sidenav-content>
</mat-sidenav-container>

<app-footer></app-footer>
