import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
// import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MatDatepicker} from '@angular/material/datepicker';
import {TranslateService} from "@ngx-translate/core";
import {Subject} from 'rxjs';
import {takeUntil} from "rxjs/operators";
import {
  DatePickerHeaderComponent
} from '../../../../../../../common-module/src/lib/app-components/date-picker-header/date-picker-header.component';
import {LocalStorageKey} from "../../../../../../../common-module/src/lib/app-enums/local-storage-key";
import {AppMessageService} from '../../../../../../../common-module/src/lib/app-services/app-message.service';
import {UiSpinnerService} from "../../../../../../../common-module/src/lib/app-services/ui-spinner.service";
import {CalendarFormat} from '../../../../../../../common-module/src/lib/constants/calendar-format';
import {
  MileageNormConsumption
} from '../../../../../../../common-module/src/lib/modelinterfaces/mileage-norm-consumption.model';
import {Mode} from '../../../../../../../common-module/src/lib/modelinterfaces/mode.model';
import {MechanismModeService} from "../../../../../../../common-module/src/lib/services/mechanism-mode.service";
import {MechanismService} from "../../../../../../../common-module/src/lib/services/mechanism.service";
import {
  MileageNormConsumptionService
} from '../../../../../../../common-module/src/lib/services/mileage-norm-consumption.service';
import {AppRegexp} from "../../../../../../../common-module/src/lib/utils/app-regexp";
import {DateConverter} from "../../../../../../../common-module/src/lib/utils/date-converter";
import {Disable29February} from '../../../../../../../common-module/src/lib/utils/disable29-february';
import {VehicleManagerService} from "../../../../shared/services/vehicle-manager.service";
import {TripSettings} from "../../../../../../../common-module/src/lib/modelinterfaces/trip-settings.model";
import {UnitService} from "../../../../../../../common-module/src/lib/services/unit.service";
import {MatDialog} from "@angular/material/dialog";
import {
  MileageClassifierScannedDialogComponent
} from "./mileage-classifier-scanned-dialog/mileage-classifier-scanned-dialog.component";

enum MileageNormControl {
  URBAN_THRESHOLD_SPEED = 'urban-threshold-speed',
  WINTER_START = 'winter-start',
  WINTER_END = 'winter-end',
  SUMMER_URBAN = 'summer-urban',
  SUMMER_COUNTRY = 'summer-country',
  WINTER_URBAN = 'winter-urban',
  WINTER_COUNTRY = 'winter-country',
}

@Component({
  selector: 'app-norm-consumption-forms',
  templateUrl: './norm-consumption-forms.component.html',
  styleUrls: ['../../../../../../../common-module/src/lib/app-styles/dialog-common.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: CalendarFormat.Month}
  ]
})

export class NormConsumptionFormsComponent implements OnChanges, OnInit, OnDestroy {

  private readonly MAX_NORM_CONSUMPTION = 200;
  private readonly MAX_MECHANISM_FUEL_RATE = 10000;
  private readonly VALIDATORS_MILEAGE_NORM = [Validators.required, Validators.min(0), Validators.max(this.MAX_NORM_CONSUMPTION)];

  @Input() unitId: number;
  @Input() settings: TripSettings;

  public dateFilter = Disable29February.filter;
  public datePickerHeader = DatePickerHeaderComponent;
  public mileageNormForm: UntypedFormGroup;
  public modesForm: UntypedFormGroup;
  public mechanismModes: Mode[];
  public mileageNormConsumption: MileageNormConsumption;

  private destroyed = new Subject<boolean>();

  constructor(private appMessageService: AppMessageService,
              private dateAdapter: DateAdapter<Date>,
              private mechanismService: MechanismService,
              private unitService: UnitService,
              private mechanismModeService: MechanismModeService,
              private mileageNormConsumptionService: MileageNormConsumptionService,
              private translateService: TranslateService,
              private uiSpinnerService: UiSpinnerService,
              private dialog: MatDialog,
              private vehicleManagerService: VehicleManagerService) {
    this.dateAdapter.setLocale(localStorage.getItem(LocalStorageKey.LANGUAGE));
  }

  get mileageFormControl(): { [p: string]: AbstractControl } {
    return this.mileageNormForm.controls;
  }

  get mileageFormValue(): any {
    return this.mileageNormForm.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['unitId'] && this.unitId) {
      this.getMileageNormConsumption(true);
      this.getPerHourNormConsumption(true);
    }
  }

  ngOnInit(): void {
    this.vehicleManagerService.isCancelForms$
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        isCancel => {
          if (isCancel && this.vehicleManagerService.instantTabSelectedIndex() === 2) {
            this.buildMileageNormForm();
            this.buildModesForm();
          }
        }
      )

    this.vehicleManagerService.isSubmitForms$
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        isSubmit => {
          if (isSubmit && this.vehicleManagerService.instantTabSelectedIndex() === 2) {
            this.onSubmit();
          }
        }
      )
  }

  private getMileageNormConsumption(isForceBuild = false): void {
    if (isForceBuild) {
      this.mileageNormConsumption = null;
      this.mileageNormForm = null;
    }
    if (this.mileageNormConsumption) {
      this.buildMileageNormForm();
      return;
    }
    this.uiSpinnerService.show();
    this.mileageNormConsumptionService.getByUnitId(this.unitId)
      .subscribe(
        value => {
          this.mileageNormConsumption = value;
          this.buildMileageNormForm();
          this.uiSpinnerService.stop();
        }
      );
  }

  private getPerHourNormConsumption(isForceBuild = false): void {
    if (isForceBuild) {
      this.mechanismModes = null;
      this.modesForm = null;
    }
    if (this.mechanismModes) {
      this.buildModesForm();
      return;
    }
    this.uiSpinnerService.show();
    this.mechanismService.getList(this.unitId, this.translateService.currentLang)
      .subscribe(
        list => {
          this.mechanismModes = list[0]?.modes || [];
          this.buildModesForm();
          this.uiSpinnerService.stop();
        }
      );
  }

  private buildMileageNormForm(): void {
    this.mileageNormForm = new UntypedFormGroup({
      [MileageNormControl.URBAN_THRESHOLD_SPEED]: new UntypedFormControl(this.settings.urbanThresholdSpeed, [Validators.required, Validators.min(0)]),

      [MileageNormControl.WINTER_START]: new UntypedFormControl(this.mileageNormConsumption?.winterStartDate, [Validators.required]),
      [MileageNormControl.WINTER_END]: new UntypedFormControl(this.mileageNormConsumption?.winterEndDate, [Validators.required]),

      [MileageNormControl.WINTER_URBAN]: new UntypedFormControl(this.mileageNormConsumption?.getWinterConsumptionMileageUrbanLiters(1), this.VALIDATORS_MILEAGE_NORM),
      [MileageNormControl.WINTER_COUNTRY]: new UntypedFormControl(this.mileageNormConsumption?.getWinterConsumptionMileageCountryLiters(1), this.VALIDATORS_MILEAGE_NORM),

      [MileageNormControl.SUMMER_URBAN]: new UntypedFormControl(this.mileageNormConsumption?.getSummerConsumptionMileageUrbanLiters(1), this.VALIDATORS_MILEAGE_NORM),
      [MileageNormControl.SUMMER_COUNTRY]: new UntypedFormControl(this.mileageNormConsumption?.getSummerConsumptionMileageCountryLiters(1), this.VALIDATORS_MILEAGE_NORM)
    });
    this.mileageNormForm.valueChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.vehicleManagerService.changeForms([this.mileageNormForm, this.modesForm]);
      });
    this.vehicleManagerService.changeForms([this.mileageNormForm, this.modesForm]);
  }

  private buildModesForm(): void {
    if (this.mechanismModes?.length > 0) {
      this.modesForm = new UntypedFormGroup({
        modes: new UntypedFormArray(this.mechanismModes.map(mode => {
            return new UntypedFormControl(mode.fuelRate, [
              Validators.pattern(AppRegexp.ONLY_DIGITS_WITH_ONE_DECIMAL),
              Validators.min(0),
              Validators.max(this.MAX_MECHANISM_FUEL_RATE)
            ])
          })
        )
      });
      this.modesForm.valueChanges
        .pipe(takeUntil(this.destroyed))
        .subscribe(() => {
          this.vehicleManagerService.changeForms([this.mileageNormForm, this.modesForm]);
        });
      this.vehicleManagerService.changeForms([this.mileageNormForm, this.modesForm]);
    }
  }

  public getModesControls(): AbstractControl[] {
    return (this.modesForm.get('modes') as UntypedFormArray).controls;
  }

  public onCancel(): void {
    this.buildMileageNormForm();
    this.buildModesForm();
  }

  public onSubmit(): void {
    this.submitMileageNorm();
    this.submitModes();
  }

  private submitMileageNorm(): void {
    if (!this.mileageNormForm || !this.mileageNormForm.dirty || !this.mileageNormForm.valid) {
      return;
    }
    const newNormConsumption = new MileageNormConsumption(
      this.mileageNormConsumption?.id,
      this.unitId,
      DateConverter.toDateString(new Date(this.mileageFormValue[MileageNormControl.WINTER_START].toString())),
      DateConverter.toDateString(new Date(this.mileageFormValue[MileageNormControl.WINTER_END].toString())),
      this.toMiliLiters(this.mileageFormValue[MileageNormControl.SUMMER_URBAN]),
      this.toMiliLiters(this.mileageFormValue[MileageNormControl.SUMMER_COUNTRY]),
      0,
      this.toMiliLiters(this.mileageFormValue[MileageNormControl.WINTER_URBAN]),
      this.toMiliLiters(this.mileageFormValue[MileageNormControl.WINTER_COUNTRY]),
      0,
    );
    this.uiSpinnerService.show();
    this.mileageNormConsumptionService.update(this.unitId, newNormConsumption).subscribe(
      norm => {
        this.mileageNormConsumption = norm;
        this.appMessageService.openSnackBar('message.info.changes-saved');
        this.mileageNormForm.markAsPristine();
        this.vehicleManagerService.changeForms([this.mileageNormForm, this.modesForm]);
        this.uiSpinnerService.stop();
      }
    );
    if (this.mileageNormForm.controls[MileageNormControl.URBAN_THRESHOLD_SPEED].dirty) {
      this.unitService.changeUrbanThresholdSpeed(this.unitId, this.mileageFormValue[MileageNormControl.URBAN_THRESHOLD_SPEED])
        .subscribe(unit => {
            this.mileageFormControl[MileageNormControl.URBAN_THRESHOLD_SPEED].setValue(unit.tripSettings.urbanThresholdSpeed);
            this.settings = unit.tripSettings;
          this.mileageNormForm.markAsPristine();
          }
        )
    }
  }

  private toMiliLiters(liters: number) {
    return liters * 1000;
  }

  private submitModes(): void {
    if (!this.modesForm || !this.modesForm.dirty || !this.modesForm.valid) {
      return;
    }
    this.uiSpinnerService.show();
    const modeControls = (this.modesForm.get('modes') as UntypedFormArray).controls;

    modeControls.forEach(
      (control, index) => {
        if (control.dirty && control.valid) {
          this.mechanismModeService.changeFuelRate(this.mechanismModes[index].id, control.value, this.translateService.currentLang)
            .subscribe(
              mode => {
                this.mechanismModes = this.mechanismModes.map(item => {
                  if (item.id === mode.id) {
                    return mode;
                  }
                  return item;
                });
                this.saveModesChanges(modeControls.length, index);
              }
            )
        } else {
          this.saveModesChanges(modeControls.length, index);
        }
      }
    )
  }

  private saveModesChanges(modeControlsLength: number, index: number): void {
    if (modeControlsLength - 1 === index) {
      this.modesForm.markAsPristine();
      this.vehicleManagerService.changeForms([this.mileageNormForm, this.modesForm]);
      this.appMessageService.openSnackBar(`message.info.changes-saved`);
      this.uiSpinnerService.stop();
    }
  }

  public asPicker(picker: MatDatepicker<any>): MatDatepicker<any> {
    return picker;
  }

  ngOnDestroy(): void {
    this.destroyed.next(null);
    this.destroyed.complete();
  }

  protected readonly MileageNormControl = MileageNormControl;

  openScannedCityInfo() {
    this.dialog.open(MileageClassifierScannedDialogComponent, {
      width: '90vw',
      minWidth: '90vw',
      height: '90vh',
      minHeight: '90vh',
    })
  }
}
