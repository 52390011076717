<div class="flex flex-direction-column height-100-percent">
  <app-search (searchChange)="onSearchChange($event)"></app-search>

  <div class="flex flex-v-center flex-justify-end align">
    <label for="selectAllCheckbox">{{ selectionModel.selected.length }}
      / {{ all.length }} {{ 'action.select-all' | translate }}</label>
    <mat-checkbox id="selectAllCheckbox" [labelPosition]="'after'"
                  [indeterminate]="selectionModel.selected.length > 0 && selectionModel.selected.length !== all.length"
                  (change)="toggleAllSelections($event.checked)">
    </mat-checkbox>
  </div>
  <mat-selection-list #unitSelectionList [multiple]="multiple" [style.height]="height" class="overflow-auto">
    <mat-list-option *ngFor="let wrapper of filtered" [value]="wrapper"
                     [selected]="wrapper.isSelected"
                     (click)="toggleSelection(wrapper)"
                     [matTooltip]="wrapper.value.name"
                     [matTooltipShowDelay]="1000">
      {{ wrapper.value.name }}
    </mat-list-option>
  </mat-selection-list>
</div>
