import {Logistic} from "../../../../../../common-module/src/lib/modelinterfaces/logistic.model";


export enum LayerName {
  UNITS = 'units',
  ANIMATION = 'animation',
  TILE = 'tile',
  WEATHER = 'weather',
  TRACE = 'trace',
  TRACK = 'track',
  TRACK_MAP_DIALOG = 'trackMapDialog',
  HIGHLIGHTED_TRACK = 'highLightedTrack',
  LOCATION = 'location',
  SELECTED_POSITION = 'selectedPosition',
  SELECTED_POSITION_MAP_DIALOG = 'selectedPositionMapDialog',
  SELECTED_PARKING = 'selectedParking',
  SELECTED_TRIP = 'selectedTrack',
  SELECTED_VIDEO_TRIP = 'selectedVideoTrip',
  SELECTED_SPEED_RANGE = 'selectedSpeedRange',
  POINTS = 'points',
  CHECK_POINTS = 'checkPoints',
  VIDEO = 'video',
  CENTER_MAP = 'centerMap',
  GEOFENCES = 'geofences',
  DRAW_GEOFENCE = 'drawGeofence',
  MEASURING = 'measuring',
  WAREHOUSES = 'warehouses',
  JOBS = 'jobs',
  PLANNING_CARRIERS = 'planningCarriers',
  SENSOR_RANGES_1 = 'sensor-ranges-1',
  SENSOR_RANGES_2 = 'sensor-ranges-2'
}
