import { Directive } from '@angular/core';

@Directive({
  selector: '[cmnDialogStickyFooter]',
})
export class DialogStickyFooterDirective {

  constructor() { }

}
