import { Component, Inject, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageKey } from "../../../../../../../../common-module/src/lib/app-enums/local-storage-key";
import { DateRangeI } from '../../../../../../../../common-module/src/lib/app-interfaces/date-range-i';
import { SensorCalculatorSource } from '../../../../../../../../common-module/src/lib/modelinterfaces/sensor-calculator-source.model';
import { SensorCalculatorService } from '../../../../../../../../common-module/src/lib/services/sensor-calculator.service';
import { SensorChartExtService } from '../../../../../../../../common-module/src/lib/services/sensor-chart-ext.service';

@Component({
  selector: 'app-build-chart-dialog',
  templateUrl: './build-chart-dialog.component.html',
  styleUrls: ['./build-chart-dialog.component.scss', '../../../../../../../../common-module/src/lib/app-styles/dialog-common.scss']
})

export class BuildChartDialogComponent implements OnInit {

  public selectedParamNameList: string[] = [];
  public selectedSensorIdList: number[] = [];
  public selectedVirtualNameList: string[] = [];
  public sensorStorage: SensorCalculatorSource;
  public showSpinner = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { unitId: number; dateRange: DateRangeI },
              private sensorChartExtService: SensorChartExtService,
              private dialogRef: MatDialogRef<BuildChartDialogComponent>,
              private sensorCalculatorService: SensorCalculatorService) {
  }

  ngOnInit(): void {
    this.selectedSensorIdList = JSON.parse(localStorage.getItem(LocalStorageKey.SENSORS_LAST_SELECTED)) || [];
    this.selectedParamNameList = JSON.parse(localStorage.getItem(LocalStorageKey.PARAMETERS_LAST_SELECTED)) || [];
    this.selectedVirtualNameList = JSON.parse(localStorage.getItem(LocalStorageKey.VIRTUAL_LAST_SELECTED)) || [];
    this.sensorCalculatorService.getByUnitId(this.data.unitId).subscribe(res => {
        this.sensorStorage = res;
        this.selectedSensorIdList = this.selectedSensorIdList.filter(id => this.sensorStorage.sensors.some(item => item.id === id));
        this.selectedParamNameList =
          this.selectedParamNameList.filter(name => this.sensorStorage.parameters.some(item => item.name === name));
        this.selectedVirtualNameList =
          this.selectedVirtualNameList.filter(name => this.sensorStorage.virtual.some(item => item.name === name));
        this.showSpinner = false;
      },
      error => {
        this.showSpinner = false;
        throw error;
      });
  }

  public comparisonId = (option: any, value: any): boolean => {
    if (!option || !value) {
      return false;
    }
    return option.id === value.id;
  };

  public onToggleSelectAllSensors(checkbox: MatCheckboxChange): void {
    this.selectedSensorIdList = checkbox.checked ? this.sensorStorage.sensors.map(sensor => sensor.id) : [];
  }

  public onToggleSelectAllParams(checkbox: MatCheckboxChange): void {
    this.selectedParamNameList = checkbox.checked ? this.sensorStorage.parameters.map(param => param.name) : [];
  }

  public onToggleSelectAllVirtual(checkbox: MatCheckboxChange): void {
    this.selectedVirtualNameList = checkbox.checked ? this.sensorStorage.virtual.map(virtual => virtual.name) : [];
  }

  public onChangeSelectedSensorIdList(selection: MatSelectionListChange): void {
    this.selectedSensorIdList = selection.source.selectedOptions.selected.map(option => option.value.id);
  }

  public onChangeSelectedParamNameList(selection: MatSelectionListChange): void {
    this.selectedParamNameList = selection.source.selectedOptions.selected.map(option => option.value.name);
  }

  public onChangeSelectedVirtualNameList(selection: MatSelectionListChange): void {
    this.selectedVirtualNameList = selection.source.selectedOptions.selected.map(option => option.value.name);
  }

  public isSelectedSensor(sensorId: number): boolean {
    return this.selectedSensorIdList.includes(sensorId);
  }

  public isSelectedParameter(list: string[], paramName: string): boolean {
    return list.includes(paramName);
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onSubmit(): void {
    this.setLastSelectedOptions();
    this.showSpinner = true;
    this.sensorChartExtService.getByUnitId(
      this.data.unitId,
      this.data.dateRange.startDate.toISOString(),
      this.data.dateRange.endDate.toISOString(),
      this.selectedSensorIdList,
      this.selectedParamNameList,
      this.selectedVirtualNameList,
    ).subscribe(sensorChart => {
        this.showSpinner = false;
        this.dialogRef.close({sensorChart: sensorChart});
      },
      error => {
        this.showSpinner = false;
        throw error;
      });
  }

  private setLastSelectedOptions(): void {
    localStorage.setItem(LocalStorageKey.SENSORS_LAST_SELECTED, JSON.stringify(this.selectedSensorIdList));
    localStorage.setItem(LocalStorageKey.PARAMETERS_LAST_SELECTED, JSON.stringify(this.selectedParamNameList));
    localStorage.setItem(LocalStorageKey.VIRTUAL_LAST_SELECTED, JSON.stringify(this.selectedVirtualNameList));
  }
}
