import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { GpsCoordinate } from '../../../../../common-module/src/lib/modelinterfaces/gps-coordinate.model';
import { UrlImage } from '../constants/url-image';
import { OlCoordinate } from './OlCoordinate';
import { OlFeature } from './OlFeature';
import { OlStyle } from './OlStyle';
import {Unit} from "../../../../../common-module/src/lib/modelinterfaces/unit.model";
import {UnitState} from "../../../../../common-module/src/lib/modelinterfaces/unit-state.model";
import {UnitStateConst} from "../utils/unit-state.const";
import {UnitShort} from "../../../../../common-module/src/lib/modelinterfaces/unit-short.model";

export class UnitIconBuilder {

  public static build(unit: UnitShort, state: UnitState, ): Feature {
    const point = new Point(OlCoordinate.createFromGpsCoordinate(state.gpsState.coordinate));
    if (state.gpsState.speed <= UnitStateConst.STOP_SPEED) {
      return OlFeature.createIcon(point, unit.name, OlStyle.createForIconWithAnchor(UrlImage.UNIT_PARKING, 0.5, 20, 0.8),
        unit.color, unit.id);
    } else {
      return OlFeature.createRotatedIcon(point, unit.name, state.gpsState.course, UrlImage.UNIT_MOVING, unit.color, unit.id);
    }
  }
}
