import {ScheduleRange} from "../../../../../../../../../common-module/src/lib/modelinterfaces/schedule-range.model";
import {RangeDayTime} from "../../../../../../../../../common-module/src/lib/modelinterfaces/range-day-time.model";
import {DayTime} from "../../../../../../../../../common-module/src/lib/modelinterfaces/day-time.model";
import {HHMMSS} from "../../../../../../shared/utils/h-h-m-m-s-s";
import {TimeTableSerialIterator} from "./TimeTableIterator";
import {TimeCellI, TimeTable} from "./TimeTable";

export class Range<T> {
  constructor(public start: T,
              public end: T) {
  }
}

export abstract class ScheduleFactory {

  /***
   * Convert {@param timeTable} to {@link ScheduleFactory}
   */
  public static create(timeTable: TimeTable): ScheduleRange {
    let rangeDayTimes = ScheduleFactory.getSelectedRanges(timeTable).map(p => this.toRangeDayTime(p));
    return new ScheduleRange(rangeDayTimes);
  }

  private static toRangeDayTime(pair: Range<TimeCellI>): RangeDayTime {
    return new RangeDayTime(this.toDayTime(pair.start, pair.start.timeFrom), this.toDayTime(pair.end, pair.end.timeTo));
  }

  private static toDayTime(cell: TimeCellI, time: number): DayTime {
    return new DayTime(cell.weekday + 1, HHMMSS.toString(time))
  }

  /**
   * Последовательно обходит все ячейки таблицы и образует массив пар ячеек между которыми находятся выделенные ячейки
   */
  private static getSelectedRanges(timeTable: TimeTable): Range<TimeCellI>[] {
    const iterator = new TimeTableSerialIterator(timeTable);
    const result: Range<TimeCellI>[] = [];
    while (iterator.hasNext()) {
      let current = iterator.next();
      if (current.isSelected) {
        let startRange = current;
        let prev = current;
        while (iterator.hasNext() && current.isSelected) {
          prev = current;
          current = iterator.next();
        }
        if (current.isSelected) {
          result.push(new Range<TimeCellI>(startRange, current));
        } else {
          result.push(new Range<TimeCellI>(startRange, prev));
        }
      }
    }
    return result;
  }
}
