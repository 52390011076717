import {StepFeatureWrapperFactory} from "../step-feature/step-feature-wrapper.factory";
import {Logistic} from "../../../../../../../../../common-module/src/lib/modelinterfaces/logistic.model";
import {JobFeatureWrapper, ShipmentFeatureWrapper} from "./shipment-feature.wrapper";
import {OlStepStyleOpts} from "../step-feature/step-style.factory";
import {ArrowFeatureWrapperFactory} from "../../../../../aur-map-creator/feature/arrow-feature/arrow-feature-wrapper.factory";
import {ArrowStyleFactory} from "../../../../../aur-map-creator/feature/arrow-feature/arrow-style.factory";
import {StepFeatureWrapper} from "../step-feature/step-feature.wrapper";
import {
  ShipmentArrowFeatureWrapperFactory,
  ShipmentOlArrowOpts
} from "./job-arrow-feature/shipment-arrow-feature-wrapper.factory";

export interface JobFeatureWrapperOpts {
  // true
  showWarehouse: boolean;
}

export class JobFeatureWrapperFactory {

  private static readonly DEFAULT_SHOW_WAREHOUSE = true;

  protected stepFeatureFactory = new StepFeatureWrapperFactory();
  protected arrowFeatureFactory = new ShipmentArrowFeatureWrapperFactory(new ArrowFeatureWrapperFactory(new ArrowStyleFactory()));

  protected stepOpts: OlStepStyleOpts = {};
  protected arrowOpts: ShipmentOlArrowOpts = {};

  public create(job: Logistic.Job, opts?: JobFeatureWrapperOpts): JobFeatureWrapper {
    const showWarehouse = opts?.showWarehouse ?? JobFeatureWrapperFactory.DEFAULT_SHOW_WAREHOUSE;
    if (job.isJob()) {
      const delivery = this.stepFeatureFactory.create(job.delivery, job, this.stepOpts);
      return new JobFeatureWrapper(delivery, job);
    }
    const shipment = job.toShipment();
    const pickup = this.maybeCreateStep(shipment.pickup, job, showWarehouse);
    const delivery = this.maybeCreateStep(shipment.delivery, job, showWarehouse);
    const arrow = this.arrowFeatureFactory.create(shipment, this.arrowOpts);
    return new ShipmentFeatureWrapper(pickup, delivery, arrow, shipment);
  }

  protected maybeCreateStep(step: Logistic.Step, job: Logistic.Job, showWarehouse: boolean): StepFeatureWrapper | null {
    if (!step.isStepWarehouse() || (step.isStepWarehouse() && showWarehouse)) {
      return this.stepFeatureFactory.create(step, job, this.stepOpts);
    }
    return null;
  }
}
