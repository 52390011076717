import LineString from 'ol/geom/LineString';
import {Coordinate} from 'ol/coordinate';
import {OlCoordinate} from "./OlCoordinate";
import {GpsCoordinate} from "../../../../../common-module/src/lib/modelinterfaces/gps-coordinate.model";

export class OlLineString {

  public static build(coordinates: Coordinate[]): LineString {
    return new LineString(coordinates);
  }

  public static fromPoints(from: GpsCoordinate, to: GpsCoordinate): LineString {
    const coordinates = [OlCoordinate.createFromGpsCoordinate(from), OlCoordinate.createFromGpsCoordinate(to)];
    return OlLineString.build(coordinates);
  }
}
