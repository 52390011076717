import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MultiTrackToolComponent} from "./multi-track-tool.component";
import {AppButtonModule} from "../../../../../../../common-module/src/lib/buttons/app-button.module";
import {MaterialModule} from "../../../../../../../common-module/src/lib/material.module";
import {SensorValuePipe} from "../../../../../../../common-module/src/lib/pipes/sensor-value.pipe";
import {
  TimestampTimezoneConvertPipe
} from "../../../../../../../common-module/src/lib/pipes/timestamp-timezone-convert.pipe";
import {MatIconModule} from "@angular/material/icon";
import {MatDividerModule} from "@angular/material/divider";
import {ShowHideButtonComponent} from "./show-hide-button/show-hide-button.component";
import {SearchComponent} from "../../../../../../../common-module/src/lib/app-components/search/search.component";



@NgModule({
  declarations: [
    MultiTrackToolComponent,
  ],
  imports: [
    CommonModule,
    AppButtonModule,
    MaterialModule,
    SensorValuePipe,
    TimestampTimezoneConvertPipe,
    MatIconModule,
    MatDividerModule,
    SensorValuePipe,
    ShowHideButtonComponent,
    SearchComponent
  ],
  exports: [
    MultiTrackToolComponent
  ]
})
export class MultiTrackToolModule { }
