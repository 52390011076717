import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ScheduleRange} from '../../../../../../../../../common-module/src/lib/modelinterfaces/schedule-range.model';
import {TimeCellI, TimeTable} from "./TimeTable";
import {TimeTableFactory} from "./TimeTableFactory";


/**
 * При инициализации компонента создается {@link ControlScheduleComponent#timeTable}
 * и инициализируется первоначальными данными из {@link ControlScheduleComponent#schedule}
 * при помощи {@link TimeTableFactory}
 *
 * далее пользователь нажимает на ячейку и перемещая курсор  выделяет нужные ячейки
 * формируется прямоугольник между {@link ControlScheduleComponent#startCell} и текущей ячейкой
 * с помощью {@link TimeTableRectangleIterator} итерируемся по этому прямоугольнику и отмечаем ячейки
 *
 * когда пользователь отпускает левую кнопку мыши генерируем новый {@link ControlScheduleComponent#schedule}
 * с помощью {@link ScheduleFactory}
 *
 */
@Component({
  selector: 'app-control-schedule',
  templateUrl: './control-schedule.component.html',
  styleUrls: ['./control-schedule.component.scss']
})
export class ControlScheduleComponent implements OnChanges {

  private readonly TIME_STEP = 30 * 60;

  @Input() schedule: ScheduleRange;
  @Output() scheduleChanged = new EventEmitter<ScheduleRange>();

  /**
   * Хранит состояние выбранных ячеек
   */
  public timeTable: TimeTable;

  private isPointerDown = false;

  /**
   * первая выбранная ячейка
   */
  private startCell: TimeCellI;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['schedule'] && changes['schedule'].isFirstChange()) {
      this.timeTable = TimeTableFactory.create(this.schedule);
    }
  }

  public onPointerDown($event: Event, cell: TimeCellI): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.isPointerDown = true;
    cell.isSelected = !cell.isSelected;
    this.startCell = Object.assign({}, cell);
  }

  public onPointerOver($event: Event, cell: TimeCellI): void {
    $event.stopPropagation();
    $event.preventDefault();
    if (this.isPointerDown) {
      this.timeTable.setSelectedBetween(this.startCell, cell, this.startCell.isSelected)
    }
  }

  public onPointerUp($event: Event): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.isPointerDown = false;
    this.startCell = null;
    this.schedule = this.timeTable.toSchedule();
    this.scheduleChanged.emit(this.schedule);
  }

  public isShowTime(halfHour: number): boolean {
    return halfHour % (this.TIME_STEP * 6) === 0;
  }
}
