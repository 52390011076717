<app-dialog [title]="'eco-driving.import-criteria-from-unit' | translate">

  <ng-container appDialogContent>
    <app-search (searchChange)="searchIn = $event"></app-search>

    <div class="form-container">
      <mat-selection-list class="radio-group"
                          #unitList
                          [multiple]="false"
                          [(ngModel)]="selectedUnit">
        <mat-list-option *ngFor="let unit of unitList$ | async"
                         class="radio-option"
                         [value]="unit"
                         [ngClass]="{'hide-option': !unit.name.toLowerCase().includes(searchIn)}">
          {{ unit.name }}
        </mat-list-option>
      </mat-selection-list>
    </div>
  </ng-container>

  <ng-container appDialogActions>
    <app-button-cancel (cancel)="onCancel()"></app-button-cancel>
    <app-button-primary (action)="onSubmit()"
                               [text]="'action.import' | translate"
                               [disabled]="!selectedUnit">
    </app-button-primary>
  </ng-container>

</app-dialog>


