import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EcoDrivingCriterionView } from '../modelinterfaces/eco-driving-criterion-view.model';
import { HttpParamsUtil } from '../utils/http-params-util';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class EcoDrivingCriterionViewService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getList(unitId: number, lang: string): Observable<EcoDrivingCriterionView> {
    const url = `${this.environmentHelper.urlV2}/ecoDrivingCriterionView/unit/${unitId}`;

    let params = HttpParamsUtil.createByLang(lang);

    return this.http.get<EcoDrivingCriterionView>(url, {params: params}).pipe(
      map(obj => EcoDrivingCriterionView.valueOf(obj))
    );
  }
}
