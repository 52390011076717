import OlMap from "ol/Map";
import {AppColor} from "../../../../../../../../common-module/src/lib/app-enums/app-color";
import {Track} from "../../../../../../../../common-module/src/lib/modelinterfaces/track.model";
import {OlVectorLayer} from "../../../../../shared/map-utils/OlVectorLayer";
import VectorLayer from "ol/layer/Vector";
import {LayerUtil} from "../../../../../shared/map-utils/layer.util";
import {TrackFeatureWrapper} from "./track-feature.model";
import Feature from "ol/Feature";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {Extent} from "ol/extent";
import {CenterMapUtil} from "../../../../../shared/map-utils/center-map.util";

class UnitTracksLayerWrapper {

  constructor(public readonly layer: VectorLayer,
              public readonly trackFeaturesWrappers: TrackFeatureWrapper[]) {
  }

  public getExtent(): Extent {
    return this.layer.getExtent();
  }

  public setOpacity(value: number) {
    this.layer.setOpacity(value);
  }

  public hideTrack(track: Track): boolean {
    const trackFeatureWrapper = this.findTrackWrapper(track);
    if (trackFeatureWrapper) {
      this.removeFeatures(trackFeatureWrapper)
      return true;
    }
    return false;
  }

  public showTrack(track: Track): boolean {
    const trackFeatureWrapper = this.findTrackWrapper(track);
    if (trackFeatureWrapper) {
      this.removeFeatures(trackFeatureWrapper);
      this.layer.getSource().addFeature(trackFeatureWrapper.feature);
      return true;
    }
    return false;
  }

  private removeFeatures(trackFeatureWrapper: TrackFeatureWrapper) {
    if (this.layer.getSource().hasFeature(trackFeatureWrapper.feature)) {
      this.layer.getSource().removeFeature(trackFeatureWrapper.feature)
    }
  }

  private findTrackWrapper(track: Track): TrackFeatureWrapper | undefined {
    return this.trackFeaturesWrappers.find(w => w.track === track);
  }
}

export class TracksMapHandler {

  private static readonly TRACK_COLOR_COLLECTION: AppColor[] = [
    AppColor.RED, AppColor.GREEN, AppColor.BLUE, AppColor.BLACK, AppColor.AQUA, AppColor.PURPLE,
    AppColor.BROWN, AppColor.TEAL, AppColor.LIME
  ]

  private readonly _trackLayers = new Map<number, UnitTracksLayerWrapper>();
  private readonly _trackColors = new Map<number, AppColor>();

  private clickEventHandler: any;
  private hoverEventHandler: any;

  private previousHoveredFeature: any = null;

  private _clickedOnMap = new Subject<Track | null>();

  public getTrackColor(unitId: number): AppColor {
    return this._trackColors.get(unitId);
  }

  constructor(private _map: OlMap) {
  }

  public get clickedOnMap(): Observable<Track> {
    return this._clickedOnMap.asObservable();
  }

  public setOpacityAllLayers(opacity: number) {
    this._trackLayers.forEach(l => l.setOpacity(opacity));
  }

  public toggleVisibilityForTrack(track: Track, visible: boolean): void {
    for (const layerWrapper of this._trackLayers.values()) {
      const result = visible ? layerWrapper.showTrack(track) : layerWrapper.hideTrack(track);
      if (result) {
        break;
      }
    }
  }

  public showTracks(tracks: Track[]) {
    // key unitId
    const trackMap = new Map<number, TrackFeatureWrapper[]>();

    tracks.filter(t => t.trips.some(t => !!t.trackGeometry))
      .forEach(t => {
      const trackFeature = new TrackFeatureWrapper(t, {color: this.getColor(t), width: 3});
      const unitId = t.getUnitId();
      if (!trackMap.has(unitId)) {
        trackMap.set(unitId, []);
      }
      trackMap.get(unitId)!.push(trackFeature);
    });
    this.show(trackMap);
    this.handleClickOnTrack();
    this.handleHoverOnTrack();
  }

  private handleClickOnTrack() {
    // Сохраняем ссылку на обработчик
    this.clickEventHandler = evt => {
      this._map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
        let value = TrackFeatureWrapper.getValue(feature);
        if (value) {
          this._clickedOnMap.next(value);
          return true;
        } else {
          return false;
        }
      });
    };

    this._map.on('click', this.clickEventHandler);
  }

  private handleHoverOnTrack(): void {
    this.hoverEventHandler = evt => {
      const pixel = evt.pixel;

      const feature: Feature = <Feature>this._map.forEachFeatureAtPixel(pixel, (f, layer) => {
        return layer?.get('name') === 'track-preview' ? f : null;
      });

      // Если курсор ушел с предыдущей feature
      if (this.previousHoveredFeature && this.previousHoveredFeature !== feature) {
        this.previousHoveredFeature.setStyle(this.previousHoveredFeature.get('originalStyle') || this.previousHoveredFeature.getStyle());
        this.previousHoveredFeature = null;
      }

      // Если есть новая feature под курсором
      if (feature) {
        this.previousHoveredFeature = feature;

        if (feature.get('hoverStyle')) {
          feature.setStyle(feature.get('hoverStyle'));
        }
      }
    };

    this._map.on('pointermove', this.hoverEventHandler);
  }

  private show(opts: Map<number, TrackFeatureWrapper[]>): void {
    opts.forEach((trackFeatures, unitId) => {
      const features: Feature[] = trackFeatures.map(tf => tf.feature);
      const layer = OlVectorLayer.createVectorLayerWithFeatures(features, 'track-preview');
      this._trackLayers.set(unitId, new UnitTracksLayerWrapper(layer, trackFeatures));
      this._map.addLayer(layer);
    });
  }

  private getColor(track: Track): AppColor {
    const unitId = track.getUnitId();
    if (!this._trackColors.has(unitId)) {
      const colorIndex = this._trackColors.size % TracksMapHandler.TRACK_COLOR_COLLECTION.length;
      const color = TracksMapHandler.TRACK_COLOR_COLLECTION[colorIndex];
      this._trackColors.set(unitId, color);
    }
    return this._trackColors.get(unitId) || AppColor.BLUE;
  }

  clear() {
    Array.from(this._trackLayers.values()).forEach(v => {
      LayerUtil.remove(this._map, v.layer);
    })
    this._trackLayers.clear();
    if (this.clickEventHandler) {
      this._map.un('click', this.clickEventHandler);
      this.clickEventHandler = null;
    }
    if (this.hoverEventHandler) {
      this._map.un('pointermove', this.hoverEventHandler);
      this.hoverEventHandler = null;
    }
  }

  public centerAll() {
    const layers = Array.from(this._trackLayers.values()).map(l => l.layer)
      .filter(layer => layer.getSource().getFeatures().length > 0);
    if (layers.length > 0) {
      CenterMapUtil.center(layers, this._map, {padding: {right: 450}})
    }
  }
}
