import { NotificationUnread } from './notification-unread.model';

export class NotificationCount {

  constructor(public created: string,
              public data: Map<number, NotificationUnread>,
              public sum: number) {
    this.data = new Map();
    Object.keys(data).forEach(key => {
      this.data.set(Number(key), NotificationUnread.valueOf(data[key]));
    });
  }

  public static valueOf(i: NotificationCount): NotificationCount {
    return i ? new NotificationCount(i.created, i.data, i.sum) : null;
  }
}
