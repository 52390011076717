import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GpsOdometer } from '../modelinterfaces/gps-odometer.model';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class GpsOdometerService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getByUnitId(unitId: number): Observable<GpsOdometer> {
    const url = `${this.environmentHelper.urlV2}/gpsOdometer/unit/${unitId}`;
    return this.http.get<GpsOdometer>(url).pipe(
      map(obj => GpsOdometer.valueOf(obj))
    );
  }

  public updateFactor(unitId: number, factor: number): Observable<GpsOdometer> {
    const url = `${this.environmentHelper.urlV2}/gpsOdometer/unit/${unitId}/factor`;
    return this.http.patch<GpsOdometer>(url, {mileageFactor: factor}).pipe(
      map(obj => GpsOdometer.valueOf(obj))
    );
  }

  public updateOdometerValue(unitId: number, value: number): Observable<GpsOdometer> {
    const url = `${this.environmentHelper.urlV2}/gpsOdometer/unit/${unitId}/odometer`;
    return this.http.patch<GpsOdometer>(url, {expected: value}).pipe(
      map(obj => GpsOdometer.valueOf(obj))
    );
  }
}
