import {Injectable} from '@angular/core';
import {jsPDF} from 'jspdf';
import OlMap from 'ol/Map';
import {Size} from 'ol/size';
import html2canvas from 'html2canvas';
import {Track} from '../../../../../common-module/src/lib/modelinterfaces/track.model';
import {AuthUserService} from '../../../../../common-module/src/lib/app-services/auth-user.service';
import {Orientation} from '../constants/enums/orientation';
import {Feature} from 'ol';
import {Layer} from 'ol/layer';
import {LayerName} from '../constants/enums/layer-name';
import VectorSource from 'ol/source/Vector';
import {OlFeature} from '../map-utils/OlFeature';
import Point from 'ol/geom/Point';
import {OlClassName} from '../constants/enums/ol-class-name';
import {OlStyle} from '../map-utils/OlStyle';
import {BehaviorSubject, Observable, Subject} from "rxjs";

@Injectable({providedIn: 'root'})

export class PrintToPdfService {

  private readonly PAPER_A4_SIZE = [277, 190];

  constructor(private authUserService: AuthUserService) {
  }

  public printMap(elemId: string, map: OlMap, track: Track, name: string = 'map'): void {
    const inProgress = new BehaviorSubject(true);
    name = this.getFileName(track, name);
    const pdf: jsPDF = new jsPDF(Orientation.LANDSCAPE, undefined, 'A4');
    const elem = document.getElementById(elemId);
    if (elem) {
      elem.insertAdjacentHTML('afterbegin', '<h3 class="printed-title">' + this.getFileName(track, '') + '</h3>');
      html2canvas(elem).then(canvas => {
        const data = canvas.toDataURL();
        pdf.addImage(data, 'JPEG', 10, 10, this.PAPER_A4_SIZE[0], 5);
        this.printElementMap(map, name, pdf, Orientation.LANDSCAPE, inProgress, 5,);
        elem.innerHTML = '';
      });
      return;
    }
    this.printElementMap(map, name, pdf, Orientation.LANDSCAPE, inProgress);
  }

  public printDialog(elemId: string, map: OlMap, track: Track, orientation: Orientation, ratio: number, name: string = 'map'): Observable<boolean> {
    const inProgress = new BehaviorSubject(true);
    const elem = document.getElementById(elemId);
    const dim = [].concat(this.PAPER_A4_SIZE);

    if (orientation === Orientation.PORTRAIT) {
      dim.reverse();
      dim[1] = this.calcPrintSize(elem.offsetHeight, elem.offsetWidth, dim[1], dim[0]);
    } else {
      dim[1] = dim[1] / ratio;
      dim[0] = this.calcPrintSize(elem.offsetWidth, elem.offsetHeight, dim[0], dim[1]);
    }
    html2canvas(elem).then(canvas => {
      const data = canvas.toDataURL();
      const pdf: jsPDF = new jsPDF({
        orientation: orientation
      });
      pdf.addImage(data, 'JPEG', 10, 10, dim[0], dim[1]);
      name = this.getFileName(track, name);
      this.printElementMap(map, name, pdf, orientation, inProgress, dim[1]);
    });
    return inProgress.asObservable();
  }

  private printElementMap(map: OlMap, name: string, pdf: jsPDF, orientation: Orientation, inProgress: BehaviorSubject<boolean>, elementHeight: number = 0): void {
    const measuringLayer: Layer = this.getMeasuringLayer(map);
    let measuringResultFeatures: Feature[] = [];
    if (measuringLayer) {
      measuringResultFeatures = this.getMeasuringResultFeatures(map, measuringLayer);
      (measuringLayer.getSource() as VectorSource).addFeatures(measuringResultFeatures);
    }

    const viewResolution = map.getView().getResolution();
    const size = map.getSize();
    const extent = map.getView().calculateExtent(size);

    map.once('rendercomplete', () => {
      const mapCanvas = document.createElement('canvas');
      mapCanvas.width = size[0];
      mapCanvas.height = size[1];
      const mapContext = mapCanvas.getContext('2d');

      Array.prototype.forEach.call(
        document.querySelectorAll('.ol-layer canvas'),
        (canvas) => {
          if (canvas.width > 0) {
            mapContext.drawImage(canvas, 0, 0, mapCanvas.width, mapCanvas.height);
          }
        }
      );
      const data = mapCanvas.toDataURL('image/jpeg');
      if (orientation === Orientation.PORTRAIT) {
        pdf.addImage(data, 'JPEG', 10, (10 + elementHeight), this.PAPER_A4_SIZE[1], this.PAPER_A4_SIZE[0] - elementHeight);
      } else {
        pdf.addImage(data, 'JPEG', 10, (10 + elementHeight), this.PAPER_A4_SIZE[0], this.PAPER_A4_SIZE[1] - elementHeight);
      }
      pdf.save(`${name}.pdf`);
      map.setSize(size);
      map.getView().setResolution(viewResolution);

      if (measuringLayer && measuringResultFeatures.length > 0) {
        measuringResultFeatures.forEach(feature => {
          (measuringLayer.getSource() as VectorSource).removeFeature(feature);
        });
      }
      inProgress.next(false)
    });

    const printSize: Size = [Math.round(this.PAPER_A4_SIZE[0] * 4), Math.round(this.PAPER_A4_SIZE[1] * 4)];
    if (orientation === Orientation.PORTRAIT) {
      printSize.reverse();
    }
    map.setSize(printSize);
    map.getView().fit(extent, {size: printSize});
  }

  private getFileName(track: Track, name: string): string {
    const tz = this.authUserService.getInstantTimeZone();
    if (track) {
      name += `${track.getUnitName()} ${track.getStartTrackDatetimeTZ(tz)} - ${track.getFinishTrackDatetimeTZ(tz)}`;
    }
    return name;
  }

  private calcPrintSize(elementSize1: number, elementSize2: number, paperSize1: number, paperSize2: number): number {
    const size = elementSize1 / (elementSize2 / paperSize2);
    if (size < paperSize1) {
      return size;
    } else {
      return paperSize1;
    }
  }

  private getMeasuringLayer(map: OlMap): Layer {
    return (map.getLayers().getArray() as Layer[]).find(layer => layer.get('name') === LayerName.MEASURING);
  }

  private getMeasuringResultFeatures(map: OlMap, measuringLayer: Layer): Feature[] {
    if (!measuringLayer) {
      return [];
    }
    const measuringResultFeatures: Feature[] = [];
    map.getOverlays().forEach(overlay => {
      if (overlay.getElement().className.includes(OlClassName.TOOLTIP_STATIC)) {
        const point = new Point(overlay.getPosition());
        measuringResultFeatures.push(
          OlFeature.createIcon(
            point,
            overlay.getElement().innerText,
            OlStyle.createForMeasuringResultIcon(),
            null,
            0)
        );
      }
    });
    return measuringResultFeatures;
  }
}
