import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class RouteService {

  private cancelledRouteSource = new BehaviorSubject<string>(null);
  public cancelledRoute$: Observable<string>;

  constructor() { }

  public change(route: string): void {
    if (route === this.cancelledRouteSource.getValue()) {
      this.cancelledRouteSource.next(null);
    } else {
      this.cancelledRouteSource.next(route);
    }
  }

  public instant(): string {
    return this.cancelledRouteSource.getValue();
  }
}
