import {Injectable} from '@angular/core';
import {combineLatest} from 'rxjs';
import OlMap from 'ol/Map';
import Point from 'ol/geom/Point';
import {Select} from 'ol/interaction';
import Style from 'ol/style/Style';
import {LineString} from 'ol/geom';
import {Feature} from 'ol';
import {TranslateService} from '@ngx-translate/core';
import {DvrFilters} from "../../../../../common-module/src/lib/app-models/dvr-filters.models";
import { Sensor } from '../../../../../common-module/src/lib/modelinterfaces/sensor.model';
import {Track} from '../../../../../common-module/src/lib/modelinterfaces/track.model';
import {SensorData} from '../../../../../common-module/src/lib/modelinterfaces/sensor-data.model';
import {LayerName} from '../constants/enums/layer-name';
import {SensorChartService} from '../../../../../common-module/src/lib/services/sensor-chart.service';
import {OlSelect} from '../map-utils/OlSelect';
import {OlDescriptionOverlay} from '../map-utils/OlDescriptionOverlay';
import {TrackLayerService, TrackOptions} from './track-layer.service';
import {OlTrackBuildService} from './ol-track-build.service';
import {AuthUserService} from '../../../../../common-module/src/lib/app-services/auth-user.service';
import {LayerUtil} from '../map-utils/layer.util';
import {OlStyle} from '../map-utils/OlStyle';
import {MapAnimationTripService} from './map-animation-trip.service';

@Injectable({
  providedIn: 'root'
})

export class MapInteractionsService {

  private readonly INDENT_BY_CHECK_POINTS = 59;

  private map: OlMap;
  private selectCheckPoints: Select;
  private _trackOptions: TrackOptions;
  private requestMessages = false;
  private selectRoute: Select;
  private sensors: Sensor[] = [];
  private sensorDataMap: Map<number, SensorData> = new Map();
  private _track: Track;

  constructor(private authUserService: AuthUserService,
              private mapAnimationTripService: MapAnimationTripService,
              private olTrackBuildService: OlTrackBuildService,
              private sensorChartService: SensorChartService,
              private trackLayerService: TrackLayerService,
              private translateService: TranslateService) {
  }

  public initMapOptions(map: OlMap): void {
    this.map = map;

    this.selectRoute = OlSelect.buildSelectRoute();
    this.useStyleModelInsteadStyleFunctionModel(this.selectRoute);
    this.selectCheckPoints = OlSelect.buildSelectCheckPoints();
    this.useStyleModelInsteadStyleFunctionModel(this.selectCheckPoints);

    this.trackInteraction();

    const basicContent = document.getElementById('basic-popup-content');
    const pointContent = document.getElementById('point-popup-content');

    const basicPopup = OlDescriptionOverlay.createPopup(document.getElementById('basic-popup'), [-160, -20]);
    const pointPopup = OlDescriptionOverlay.createPopup(document.getElementById('point-popup'), [60, -20]);

    this.onPointerMove(basicContent, basicPopup, pointContent, pointPopup);

    this.selectedRoute();
    this.selectedCheckPoints();
  }

  private useStyleModelInsteadStyleFunctionModel(select: Select): void {
    // @ts-ignore
    select.style_ = false;
  }

  private trackInteraction(): void {
    combineLatest([this.trackLayerService.trackOptions$, this.trackLayerService.track$])
      .subscribe(options => {
        this._trackOptions = options[0];
        this._track = options[1];
        this.mapAnimationTripService.removeAnimationTrip();

        if (this._track) {
          this._trackOptions.isShowTrips ? this.map.addInteraction(this.selectRoute) : this.map.removeInteraction(this.selectRoute);
          this._trackOptions.isShowPoints ?
            this.map.addInteraction(this.selectCheckPoints) : this.map.removeInteraction(this.selectCheckPoints);
        } else {
          this.map.removeInteraction(this.selectCheckPoints);
          this.map.removeInteraction(this.selectRoute);
        }

        if (this._trackOptions) {
          LayerUtil.clear(this.map, LayerName.CHECK_POINTS);
        }
      });
  }

  private onPointerMove(basicContent, basicPopup, pointContent, pointPopup): void {
    this.map.on('pointermove', (event) => {
      if (event.dragging) {
        return;
      }
      this.map.getTargetElement().style.cursor = '';
      const features = this.map.getFeaturesAtPixel(event.pixel,
        {
          layerFilter: (layer) => layer.get('name') !== LayerName.CENTER_MAP && layer.get('name') !== LayerName.GEOFENCES
        });
      if (features && features.length >= 1) {
        this.map.getTargetElement().style.cursor = 'pointer';
        let coordsRoute: Array<number[]>;
        let coordsPoint: number[];
        let isOverlayCreateByDescription = false;
        const track = this.trackLayerService.instantTrack();
        features.forEach(el => {
          if (el.get('type') === 'point') {
            coordsPoint = (el.getGeometry() as Point).getCoordinates();
          }
          if (el.get('type') === 'route') {
            coordsRoute = (el.getGeometry() as LineString).getCoordinates();
          }
          if (el.get('type') === 'checkPoint') {
            const coordsPointerMovePoint = (el.getGeometry() as LineString).getCoordinates();
            this.showFeaturePopup(
              pointContent,
              pointPopup,
              this.olTrackBuildService.buildPointerMovePointDescription(
                track,
                this.sensorDataMap,
                this.sensors,
                Number(coordsPointerMovePoint[2]),
                this.authUserService.getInstantTimeZone()
              ),
              event.coordinate
            );
            this.map.addOverlay(pointPopup);
          }
          if (el.get('description') && !isOverlayCreateByDescription) {
            this.showFeaturePopup(basicContent, basicPopup, el.get('description'), event.coordinate);
            this.map.addOverlay(basicPopup);
            isOverlayCreateByDescription = true;
          }

          if (coordsPoint && coordsRoute && this._trackOptions.isShowPoints) {
            LayerUtil.clear(this.map, LayerName.CHECK_POINTS);
            const indexes = this.getFirstAndLastIndexes(track.storage.sensorDataList.length, coordsPoint[2]);
            this.getMessagesByCoordRouteIndex(track, indexes, coordsPoint[2]);
            const layerCheckPoints = this.olTrackBuildService.buildTrackCheckPointsVectorLayer(track, coordsRoute, indexes);
            this.map.addLayer(layerCheckPoints);
          }

          // show popup for selected cluster feature
          if (el.get('features') && el.get('features').length > 0) {
            const popupMessage = this.createPopupMessageForClusterFeature(el.getProperties().features);
            this.showFeaturePopup(basicContent, basicPopup, popupMessage, event.coordinate);
            this.map.addOverlay(basicPopup);
          }
        });
      } else {
        this.map.removeOverlay(basicPopup);
        this.map.removeOverlay(pointPopup);
      }
    });
  }

  private createPopupMessageForClusterFeature(features: Feature[]): string {
    if (features.length === 1 && features[0].get('description')) {
      return features[0].get('description');
    }
    if (features.length > 0) {
      let i = 0;
      let popupMessage = '';
      for (const v of features) {
        i++;
        popupMessage += `<p class="description">${v.get('name')}</p>`;
        if (i === 11) {
          popupMessage += `<p class="description"><b>+ ..(${features.length - i + 1})</b></p>`;
          break;
        }
      }
      return popupMessage;
    }
    return null;
  }

  private getFirstAndLastIndexes(trackLength: number, index: number): [number, number] {
    let lastIndex = index + this.INDENT_BY_CHECK_POINTS;
    let firstIndex = index - this.INDENT_BY_CHECK_POINTS;
    if (lastIndex > trackLength - 1) {
      lastIndex = trackLength - 1;
    }
    if (firstIndex < 0) {
      firstIndex = 0;
    }
    return [firstIndex, lastIndex];
  }

  private getMessagesByCoordRouteIndex(track: Track, indexes: [number, number], currentPoint: number): void {
    const sensorData = track.storage.sensorDataList;
    if (this.requestMessages || this.sensorDataMap.has(sensorData[currentPoint].messageId)) {
      return;
    }
    this.requestMessages = true;
    this.sensorChartService.getByUnitId(
      track.buildOptions.unit.id,
      sensorData[indexes[0]].time,
      sensorData[indexes[1]].time,
      null,
      this.translateService.currentLang)
      .subscribe(
        res => {
          this.sensors = res.storage.sensors;
          SensorData.createMapValuesOf(res.storage.sensorDataList)
            .forEach((value, key) => this.sensorDataMap.set(key, value));
          this.requestMessages = false;
        },
        error => {
          this.requestMessages = false;
          throw error;
        }
      );
  }

  private showFeaturePopup(content, popup, popupMessage, coordinate): void {
    content.innerHTML = `<code>${popupMessage}</code>`;
    popup.setPosition(coordinate);
  }

  private selectedRoute(): void {
    let currentStyleRoute: Style;
    this.selectRoute.on('select', (evt) => {
      evt.deselected.forEach(el => {
        if (el.get('type') === 'route' && this._trackOptions.isShowTrips) {
          el.setStyle(currentStyleRoute);
        }
      });
      evt.selected.forEach(el => {
        if (el.get('type') === 'route' && this._trackOptions.isShowTrips) {
          currentStyleRoute = el.getStyle() as Style;
          el.setStyle(OlStyle.createForSelectedRoute());
        }
      });
    });
  }

  private selectedCheckPoints(): void {
    let currentStyleCheckPoint: Style;
    this.selectCheckPoints.on('select', (evt) => {
      evt.deselected.forEach(el => {
        if (el.get('type') === 'checkPoint') {
          el.setStyle(currentStyleCheckPoint);
        }
      });
      evt.selected.forEach(el => {
        if (el.get('type') === 'checkPoint' && this._trackOptions.isShowPoints) {
          currentStyleCheckPoint = el.getStyle() as Style;
          el.setStyle(OlStyle.createForSelectedCheckPoint());
        }
      });
    });
  }

  public changeVideoPoints(filters: DvrFilters): void {
    this.trackLayerService.showVideoPoints(filters, this.map);
  }
}
