import { Sensor } from './sensor.model';

export class Mode {

  constructor(
    public id: number,
    public name: string,
    public fuelRate: number,
    public engineHoursRate: number,
    public sensor: Sensor) {
  }

  public static valueOf(i: Mode): Mode {
    return i ? new Mode(i.id, i.name, i.fuelRate, i.engineHoursRate, Sensor.valueOf(i.sensor)) : null;
  }

  public static valuesOf(list: Mode[]): Mode[] {
    return list ? list.map(o => Mode.valueOf(o)) : [];
  }
}
