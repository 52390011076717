<aur-dialog [title]="title"
            [showSpinner]="showSpinner"
            [showActions]="showDialogActions">

  <ng-container aurDialogStickyHeader>
    <ng-content select="[appDialogStickyHeader]"></ng-content>
  </ng-container>

  <ng-container aurDialogContent>
    <ng-content select="[appDialogContent]"></ng-content>
  </ng-container>

  <ng-container aurDialogStickyFooter>
    <ng-content select="[appDialogStickyFooter]"></ng-content>
  </ng-container>

  <ng-container aurDialogActions>
    <ng-content select="[appDialogActions]"></ng-content>
  </ng-container>
</aur-dialog>
