<cmn-common-filter [name]="name"
                   [tooltip]="tooltip"
                   [viewValue]="viewValue"
                   [disableApply]="form?.invalid"
                   (apply)="onApply()"
                   (open)="onOpen()"
                   (clear)="onClear()">

  <form *ngIf="form" [formGroup]="form" class="filter-menu" (click)="$event.stopPropagation()">
    <cmn-filter-number-range-input [formControlName]="controls.NUMBER_RANGE"
                                   [minValue]="minValue"
                                   [maxValue]="maxValue"
                                   [uom]="uom"
                                   #numberRangeInput>
    </cmn-filter-number-range-input>
  </form>

</cmn-common-filter>
