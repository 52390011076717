import {AbstractMapFactory} from "../abstract.map-factory";
import {WarehouseMapConfig, WarehousesMap} from "../../../../../maps/map/warehouse-map/warehouses.map";
import {Injectable} from "@angular/core";
import {TileLayerService} from "../../../../../services/tile-layer.service";
import {OpenLayerMapService} from "../../../../../services/open-layer-map.service";


@Injectable({
  providedIn: 'root'
})
export class WarehouseMapFactory extends AbstractMapFactory<WarehouseMapConfig, WarehousesMap> {
  constructor(tileLayerService: TileLayerService,
              openLayerMapService: OpenLayerMapService) {
    super(tileLayerService, openLayerMapService)
  }
  public override create(config: WarehouseMapConfig) {
    return new WarehousesMap(super.createOlMap(config), config);
  }
}
