import {DateConverter} from '../utils/date-converter';
import {GpsCoordinate} from './gps-coordinate.model';
import {SensorData} from './sensor-data.model';
import {Sensor} from './sensor.model';
import {LineString} from "ol/geom";
import {OlLineString} from "../../../../user-portal/src/app/shared/map-utils/OlLineString";
import {OlCoordinate} from "../../../../user-portal/src/app/shared/map-utils/OlCoordinate";
import {BoundBoxed} from "./bound-boxed.model";

export class SensorDataStorage implements BoundBoxed {

  public boundBox: LineString;

  constructor(public sensors: Sensor[],
              public sensorDataList: SensorData[]) {
    this.boundBox = this.calcBoundBox(sensorDataList);
  }

  public static valueOf(i: SensorDataStorage): SensorDataStorage {
    return i ? new SensorDataStorage(Sensor.valuesOf(i.sensors), SensorData.valuesOf(i.sensorDataList)) : null;
  }

  public getFirstGpsCoordinate(): GpsCoordinate {
    return this.sensorDataList[0].getGpsCoordinate();
  }

  public getLastGpsCoordinate(): GpsCoordinate {
    return this.sensorDataList[this.sensorDataList.length - 1].getGpsCoordinate();
  }

  public getPartOfSensorDataList(startIndex: number, finishIndex: number): SensorData[] {
    return this.sensorDataList.slice(startIndex, finishIndex);
  }

  // find exactly or nearest index
  public searchIndexByDatetime(searchedDatetime: string): number {
    const searchedTimestamp = new Date(searchedDatetime).getTime();
    let i = 0;
    let j = this.sensorDataList.length - 1;
    let k;
    while (i <= j) {
      k = Math.floor((i + j) / 2);
      const currentTimestamp = new Date(this.sensorDataList[k].time).getTime();
      if (searchedTimestamp === currentTimestamp) {
        return k;
      } else {
        if (searchedTimestamp < currentTimestamp) {
          j = k - 1;
        } else {
          i = k + 1;
        }
      }
    }
    return k;
  }

  public getSensorIdByName(sensorName: string): number {
    for (const sensor of this.sensors) {
      if (sensor.name === sensorName) {
        return sensor.id;
      }
    }
    return null;
  }

  public getFirstDatetimeInTz(timezone: string, format: string = 'DD.MM.YYYY HH:mm:ss'): string {
    return DateConverter.utcDateTimeToTzDateString(this.sensorDataList[0].time, timezone, format);
  }

  public getLastDatetimeInTz(timezone: string, format: string = 'DD.MM.YYYY HH:mm:ss'): string {
    return DateConverter.utcDateTimeToTzDateString(this.sensorDataList[this.sensorDataList.length - 1].time, timezone, format);
  }

  public calcBoundBox(data: SensorData[]): LineString {
    if (data.length == 0) {
      return null;
    }
    let minLat = data[0].getGpsCoordinate().latitude;
    let minLng = data[0].getGpsCoordinate().longitude;
    let maxLat = data[0].getGpsCoordinate().latitude;
    let maxLng = data[0].getGpsCoordinate().longitude;

    for (const d of data) {
      minLat = Math.min(minLat, d.getGpsCoordinate().latitude);
      minLng = Math.min(minLng, d.getGpsCoordinate().longitude);
      maxLat = Math.max(maxLat, d.getGpsCoordinate().latitude);
      maxLng = Math.max(maxLng, d.getGpsCoordinate().longitude);
    }

    return OlLineString.build([
      OlCoordinate.create(minLng, minLat),
      OlCoordinate.create(maxLng, minLat),
      OlCoordinate.create(maxLng, maxLat),
      OlCoordinate.create(minLng, maxLat),
      OlCoordinate.create(minLng, minLat)
    ])
  }
}
