import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AppButtonModule} from "../../../../../../../../common-module/src/lib/buttons/app-button.module";


@Component({
  selector: 'app-show-hide-button',
  standalone: true,
  imports: [
    AppButtonModule
  ],
  templateUrl: './show-hide-button.component.html',
  styleUrl: './show-hide-button.component.scss'
})
export class ShowHideButtonComponent {

  @Input() visibleCondition = true;

  @Output() hide = new EventEmitter<MouseEvent>();
  @Output() show = new EventEmitter<MouseEvent>();
}
