<header class="dialog-title">{{ 'titles.delete-geofence' | translate:{geofenceName: data.geofence.name} }}</header>

<mat-list class="rule-list">
  <mat-list-item *ngFor="let rule of data.ruleList">{{ rule.name }}</mat-list-item>
</mat-list>

<mat-checkbox class="checkbox" [(ngModel)]="disabled">
  {{ 'term.read-and-accept' | translate }}
</mat-checkbox>

<div class="dialog-box-buttons">
  <button mat-button class="button" (click)="onCancel()">
    {{ 'action.cancel' | translate }}
  </button>

  <button mat-button class="button delete" (click)="onSubmit(data.geofence)" [disabled]="!disabled">
    {{ 'action.delete' | translate }}
  </button>
</div>
