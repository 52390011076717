import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Vehicle } from '../modelinterfaces/vehicle.model';
import { HttpParamsUtil } from '../utils/http-params-util';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class VehicleService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  update(unitId: number, lang: string, vehicle: Vehicle): Observable<Vehicle> {
    const url = `${this.environmentHelper.urlV2}/vehicle/unit/${unitId}`;
    return this.http.put<Vehicle>(url, vehicle, {params: HttpParamsUtil.createByLang(lang)}).pipe(
      map(obj => Vehicle.valueOf(obj))
    );
  }
}
