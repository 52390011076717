import {AbstractMapFactory} from "../../../../aur-map-creator/map/wrapper/map-wrapper-factory/abstract.map-factory";
import {JobManagerMap, JobsManagerMapConfig} from "./job-manager.map";
import {Injectable} from "@angular/core";
import {TileLayerService} from "../../../../services/tile-layer.service";
import {OpenLayerMapService} from "../../../../services/open-layer-map.service";


@Injectable({
  providedIn: 'root'
})
export class JobManagerMapFactory extends AbstractMapFactory<JobsManagerMapConfig, JobManagerMap> {

  constructor(tileLayerService: TileLayerService,
              openLayerMapService: OpenLayerMapService) {
    super(tileLayerService, openLayerMapService)
  }

  public override create(config: JobsManagerMapConfig) {
    return new JobManagerMap(super.createOlMap(config), config);
  }
}
