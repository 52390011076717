import {Feature} from "ol";
import LineString from "ol/geom/LineString";
import {Logistic} from "../../../../../../../../../../common-module/src/lib/modelinterfaces/logistic.model";
import {
  AbstractJobFeatureWrapper,
  JobFeatureHighlightOpts,
  JobFeatureSelectOpts
} from "../../abstract-job-feature.wrapper";
import {ArrowStyleFactory} from "../../../../../../aur-map-creator/feature/arrow-feature/arrow-style.factory";
import {ShipmentOlArrowOpts} from "./shipment-arrow-feature-wrapper.factory";

export class ShipmentArrowFeatureWrapper extends AbstractJobFeatureWrapper<LineString, ArrowStyleFactory, ShipmentOlArrowOpts> {
  constructor(feature: Feature<LineString>,
              styleFactory: ArrowStyleFactory,
              sourceOpts: ShipmentOlArrowOpts,
              shipment: Logistic.Shipment) {
    super(feature, styleFactory, sourceOpts, shipment);
  }

  protected override createSelectStyleOpts(opts: JobFeatureSelectOpts) {
    let styleOpts = this.getSourceOpts();
    styleOpts.color = opts.fill;
    styleOpts.width = 1.5;
    return styleOpts;
  }

  protected override createHighlightStyleOpts(opts: JobFeatureHighlightOpts): ShipmentOlArrowOpts {
    let styleOpts = this.getSourceOpts();
    styleOpts.color = opts.fill;
    styleOpts.width = opts.lineWidth;
    styleOpts.zIndex = opts.zIndex;
    return styleOpts;
  }
}
