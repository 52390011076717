<app-ol-map [mapDivId]="mapDivId"
            [popupDivId]="popupDivId">
  <div olMapButtonBlock class="flex flex-direction-column flex-gap8">

    <div class="flex flex-gap8">
      <button mat-mini-fab color="primary"
              (click)="startGroupSelection()"
              *ngIf="groupSelectState === selectionStateEnum.NONE; else cancelGroupSelectioButton"
              matTooltip="Активировать режим выделения: нарисуйте зону на карте для выбора/отмены заявок внутри неё"
              matTooltipPosition="right">
        <mat-icon>tab_unselected</mat-icon>
      </button>

      <ng-template #cancelGroupSelectioButton>
        <button mat-mini-fab class="red-fab" (click)="cancelGroupSelection()"
                matTooltip="Отменить все изменения">
          <mat-icon>cancel</mat-icon>
        </button>
      </ng-template>


      <app-slide-panel-animated [isOpen]="groupSelectState !== selectionStateEnum.NONE">
        <div class="flex">
          <div class="group-selection-counter flex flex-v-center"
               matTooltip="Чтобы выделить заявки, обведите интересующую область на карте, кликая по ней">
            <span>Выделено заявок: {{ groupSelectionCount | async }}</span>
          </div>

          <button mat-mini-fab class="green-fab" (click)="uncheckGroupSelection()"
                  matTooltip="Снять отметки с выделенных заявок">
            <mat-icon>check_box_outline_blank</mat-icon>
          </button>

          <button mat-mini-fab class="green-fab" (click)="checkGroupSelection()"
                  matTooltip="Отметить выделенные заявки">
            <mat-icon>check_box</mat-icon>
          </button>
        </div>
      </app-slide-panel-animated>
    </div>
    <button mat-mini-fab color="primary" (click)="centerAllJobs()"
            matTooltip="Отцентрировать заявки"
            matTooltipPosition="right">
      <mat-icon [class.rotate]="rotatingAnimationState === 'rotate'">center_focus_strong</mat-icon>
    </button>
  </div>
</app-ol-map>

