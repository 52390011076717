import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Driver } from '../../../../../../../common-module/src/lib/modelinterfaces/driver.model';
import { UnitShort } from '../../../../../../../common-module/src/lib/modelinterfaces/unit-short.model';

@Component({
  selector: 'app-remove-driver-dialog',
  templateUrl: './remove-driver-dialog.component.html',
  styleUrls: ['./remove-driver-dialog.component.scss', '../../../../../../../common-module/src/lib/app-styles/dialog-common.scss']
})

export class RemoveDriverDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { driver: Driver, unit: UnitShort },
              private dialogRef: MatDialogRef<RemoveDriverDialogComponent>) {
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onRemove(): void {
    this.dialogRef.close(true);
  }
}
