<app-dialog [title]="'term.informing' | translate">

  <div class="app-font" appDialogContent>

    <h4> {{ 'term.telegram-informing' | translate }}:</h4>

    <div class="flex flex-direction-column border-color-divider padding-4" *ngIf="telegramChatList">

      <div *ngFor="let chat of telegramChatList">

        <div class="flex flex-v-center flex-justify-space-between">
          <div>
            <span>{{ chat.telegramUser.firstName }}&nbsp;</span>
            <span *ngIf="chat.telegramUser.lastName">{{ chat.telegramUser.lastName }}&nbsp;</span>
            <span *ngIf="chat.telegramUser.username">{{ chat.telegramUser.username }}</span>
          </div>

          <div class="flex flex-v-center">
            <button *ngIf="!chat.activated"
                    mat-icon-button
                    class="app-icon-button green"
                    [matTooltip]="'action.activate' | translate"
                    (click)="onActivateChat(chat.id)">
              <mat-icon>play_arrow</mat-icon>
            </button>

            <button mat-icon-button
                    class="app-icon-button red"
                    (click)="onDeleteChat(chat.id)"
                    matTooltip="{{ 'action.delete' | translate }}">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
        <mat-divider></mat-divider>

      </div>
    </div>

    <app-telegram-message></app-telegram-message>

  </div>

</app-dialog>
