import {ArrowFeatureWrapperFactory} from "../../../../../../aur-map-creator/feature/arrow-feature/arrow-feature-wrapper.factory";
import {Logistic} from "../../../../../../../../../../common-module/src/lib/modelinterfaces/logistic.model";
import {OlArrowOpts} from "../../../../../../aur-map-creator/feature/arrow-feature/arrow-style.factory";
import {ShipmentArrowFeatureWrapper} from "./shipment-arrow-feature.wrapper";

export interface ShipmentOlArrowOpts extends OlArrowOpts {

}

export class ShipmentArrowFeatureWrapperFactory {

  constructor(public readonly factory: ArrowFeatureWrapperFactory) {
  }

  public create(shipment: Logistic.Shipment, opts?: ShipmentOlArrowOpts): ShipmentArrowFeatureWrapper {
    let arrowFeatureWrapper = this.factory.create(shipment.pickup.getCoordinate(), shipment.delivery.getCoordinate(), opts);
    return new ShipmentArrowFeatureWrapper(arrowFeatureWrapper.feature, this.factory.arrowStyleFactory, opts, shipment);
  }

}
