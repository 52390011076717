import { CommandTemplateShort } from "./command-template-short.model";
import { CommandStatus } from "./enums/command-status";
import { PageModel } from './page-model.model';
import { Page } from './page.model';

export class DeviceCommand {

  constructor(
    public readonly id: number,
    public status: CommandStatus,
    public actualTime: string,
    public commandType: string,
    public createdTime: string,
    public providerType: string,
    public text: string,
    public lifeTimeout: number,
    public template: CommandTemplateShort) {
  }

  public static valueOf(o: DeviceCommand): DeviceCommand {
    return o ? new DeviceCommand(o.id, o.status, o.actualTime, o.commandType, o.createdTime,
      o.providerType, o.text, o.lifeTimeout, CommandTemplateShort.valueOf(o.template)) : null;
  }

  public static valuesOf(list: DeviceCommand[]): DeviceCommand[] {
    return list ? list.map(o => DeviceCommand.valueOf(o)) : [];
  }

  public static pageableValueOf(o: Page<DeviceCommand>): Page<DeviceCommand> {
    return o ? new PageModel<DeviceCommand>(o.empty, o.first, o.last, o.number, o.numberOfElements, o.totalElements, o.totalPages,
      o.urlParams, DeviceCommand.valuesOf(o.content)) : null;
  }
}
