<div [ngSwitch]="true" class="box-icon flex flex-direction-column">

  <div class="badge" *ngIf="unreadNotificationCount"
       [ngClass]="{'badge-highlighted': highlight}"
       [matTooltip]="'Новых уведомлений: ' + unreadNotificationCount">
    {{ unreadNotificationCountView }}
  </div>

  <ng-container *ngIf="unitStatus !== unitStatusEnum.ACTIVE">

    <ng-template [ngSwitchCase]="unitStatus === unitStatusEnum.DISABLED">
      <mat-icon matTooltip="{{ 'user.blocked' | translate }}">remove_circle_outline</mat-icon>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="unitStatus === unitStatusEnum.ACTIVE">
    <ng-template [ngSwitchCase]="connectionStatus === connectionStatusEnum.NOT_VALID">
      <mat-icon class="text-map-basic-color" matTooltip="{{ 'message.error.not-valid' | translate }}">lens</mat-icon>
    </ng-template>

    <ng-template
      [ngSwitchCase]="connectionStatus === connectionStatusEnum.ONLINE || connectionStatus === connectionStatusEnum.OFFLINE">

            <span [ngClass]="{'text-green': connectionStatus === connectionStatusEnum.ONLINE,
                              'text-red': connectionStatus === connectionStatusEnum.OFFLINE,
                              'text-divider-color': connectionStatus === connectionStatusEnum.OFFLINE && secondsAgo > OFFLINE_EXCEEDED_TIME}">

            <mat-icon *ngIf="speed > STOP_SPEED; else parkingIcon"
                      [ngStyle]="{'transform': 'rotate(' + course + 'deg)'}"
                      matTooltip="{{ time | utcDateTz: timeZone }}">
              navigation
            </mat-icon>

              <ng-template #parkingIcon>
                <mat-icon matTooltip="{{time | utcDateTz: timeZone }}">
                  local_parking
                </mat-icon>
              </ng-template>

            </span>
    </ng-template>

    <ng-template ngSwitchDefault>
      <mat-icon class="text-divider-color" matTooltip="{{ 'message.error.no-data' | translate }}">lens</mat-icon>
    </ng-template>
  </ng-container>
</div>
