<mat-toolbar class="header notranslate">

  <app-logo-title></app-logo-title>

  <!--  <div class="group">-->

  <!--    <img [src]="(customization$ | async)?.logoUrl"-->
  <!--         class="logo"-->
  <!--         [ngClass]="{logo_eq_s: (size$ | async).equal('s')}">-->

  <!--    <p *ngIf="(size$ | async).more('s')">-->
  <!--      <span class="logo-title">-->
  <!--        {{ (customization$ | async)?.title || '' }}&nbsp;-->
  <!--      </span>-->
  <!--      <span class="badge">{{ header ? header : '' }}</span>-->
  <!--    </p>-->

  <!--  </div>-->

  <ng-container [ngTemplateOutlet]="templateMiddle"></ng-container>

  <div *ngIf="!templateMiddle && (tab$ | async) !== tabName.PUBLIC"
       class="tab-selector"
       [ngClass]="{buttons_le_l: (size$ | async).less('l')}">

    <mat-button-toggle-group class="toggle-group-buttons"
                             [hideSingleSelectionIndicator]="true"
                             [value]="tab$ | async">

      <mat-button-toggle class="toggle-button"
                         *ngFor="let headerButton of headerButtons"
                         [ngClass]="{button_eq_s: (size$ | async).equal('s')}"
                         [value]="headerButton.value"
                         (click)="onSelectorChange(headerButton)">
        <span>
          <mat-icon
            [matBadge]="noticeSum$ | async"
            [matBadgeHidden]="headerButton.value !== tabName.NOTIFICATION"
            [matBadgeOverlap]="true"
            [matBadgeColor]="'accent'"
            [matBadgeSize]="'small'">
            {{ headerButton.icon }}
          </mat-icon>
        </span>

        <span *ngIf="(size$ | async).more('l')"
              [ngClass]="{'reduce-text': headerButton.value === tabName.ECO_DRIVING}">
          {{ (headerButton.text | translate) | titlecase }}
        </span>

      </mat-button-toggle>
    </mat-button-toggle-group>

    <mat-menu #menu="matMenu" class="mobile-menu">
      <button mat-menu-item class="app-menu-item primary" *ngFor="let headerButton of headerButtons"
              (click)="onSelectorChange(headerButton)">
        <mat-icon [matBadge]="noticeSum$ | async"
                  [matBadgeHidden]="headerButton.value !== tabName.NOTIFICATION"
                  [matBadgeOverlap]="true"
                  [matBadgeColor]="'accent'"
                  [matBadgeSize]="'small'">
          {{ headerButton.icon }}
        </mat-icon>
        {{ (headerButton.text | translate) | titlecase }}
      </button>
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="menu" class="mobile-menu app-button stroked white-primary">
      <mat-icon [matBadge]="noticeSum$ | async"
                [matBadgeHidden]="selectTab.value !== tabName.NOTIFICATION"
                [matBadgeOverlap]="true"
                [matBadgeColor]="'accent'"
                [matBadgeSize]="'small'">{{ selectTab.icon }}</mat-icon>
      {{ (selectTab.text | translate) | titlecase }}
    </button>
  </div>

  <ng-container [ngTemplateOutlet]="templateRight"></ng-container>

  <div *ngIf="!templateRight && (tab$ | async) !== tabName.PUBLIC" class="authentication">

    <app-icon-button-white icon="apps"
                           matTooltip="Приложения"
                           [matMenuTriggerFor]="appsMenu">
    </app-icon-button-white>

    <span *ngIf="(size$ | async).equal('m') || (size$ | async).equal('xl')">{{ userName$ | async }}</span>
    <button mat-icon-button [matMenuTriggerFor]="menuHeader">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>

  <div *ngIf="(tab$ | async) === tabName.PUBLIC" class="authentication">
    <cmn-change-language></cmn-change-language>
  </div>
</mat-toolbar>

<mat-menu #menuHeader="matMenu">
  <h3 *ngIf="!((size$ | async).equal('m') || (size$ | async).equal('xl'))" class="user-name">
    {{ userName$ | async }}
  </h3>

  <button mat-menu-item (click)="onOpenSettingDialog()" class="button-action">
    <mat-icon>settings</mat-icon>
    <span>{{ 'term.settings' | translate }}</span>
  </button>

  <button mat-menu-item (click)="onOpenPublicLinksDialog()" class="button-action">
    <mat-icon>share</mat-icon>
    <span>{{ 'term.public-links' | translate }}</span>
  </button>

  <button mat-menu-item (click)="onOpenInformingDialog()" class="button-action">
    <mat-icon>info</mat-icon>
    <span>{{ 'term.informing' | translate }}</span>
  </button>

  <button mat-menu-item (click)="onOpenCommandTemplates()" class="button-action">
    <mat-icon>send</mat-icon>
    <span>{{ 'term.command-templates' | translate }}</span>
  </button>

  <button mat-menu-item (click)="onOpenChangePasswordDialog()" class="button-action">
    <mat-icon>vpn_key</mat-icon>
    <span>{{ 'action.change-password' | translate }}</span>
  </button>

  <button mat-menu-item (click)="onOpenAboutProgram()" class="button-action">
    <mat-icon>event_note</mat-icon>
    <span>{{ 'version.about' | translate }}</span>
  </button>

  <button mat-menu-item (click)="onOpenWikiUrl()" class="button-action">
    <mat-icon>help</mat-icon>
    <span>{{ 'term.help' | translate }}</span>
  </button>

  <button mat-menu-item (click)="onExit()" class="button-action">
    <mat-icon>exit_to_app</mat-icon>
    <span>{{ 'term.exit' | translate }}</span>
  </button>
</mat-menu>

<mat-menu #appsMenu="matMenu">
  <button mat-button class="app-button primary margin-8">
    <app-logistic-logo-title (click)="onLogistic()"></app-logistic-logo-title>
  </button>
</mat-menu>
